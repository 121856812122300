import { useEffect, useRef } from "react";
import moment from 'moment'

export const UseInterval = (callback, delay) => {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    const tick = () => {
      savedCallback.current();
    };
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
};



export const dateFormate = (date,format) => {
  return  moment
  .unix(date / 1000)
  .format(format)
};