import React, { useEffect, useRef } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

const SBLICoverageLoader = ({ flag, time, callback }) => {
  const loaderRef = useRef(null);

  useEffect(() => {
    let i = 0;
    const elem = document.getElementById("myProgressBar");
    const secondsTimer = setInterval(
      () => {
        if (loaderRef.current) {
          if (i < 100) {
            i += time ? Math.round(100 / time) : 1;
            loaderRef.current.innerText = i > 100 ? "100%" : i + "%";
            elem.style.width = i > 100 ? "100%" : i + "%";
          }
          if (i >= 100) {
            callback();
          }
        }
      },
      time ? 1000 : 50
    );
    return () => clearInterval(secondsTimer);
  }, [time]);

  return (
    <div className="sbli-coverage-loader">
      <div className="sbli-coverage-progress">
        <div>
          <h3 className="text-white">Underwriting in progress</h3>
          <p>We are processing your details</p>
          <div className="d-flex align-items-center mt-2">
            <div className="coverage-loader">
              <div className="sbli-cv-progress" id="myProgressBar"></div>
            </div>
            <span className="percentage ml-3" ref={loaderRef}></span>
          </div>

          <div className="mt-5 pt-4">
            <span className="font-weight-bold">What happens next?</span>
            <span className="d-block">
              After underwriting is complete you will see your final decision
              and coverage amount.
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {})(withRouter(SBLICoverageLoader));
