import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams, withRouter } from "react-router-dom";
import { commonApiCall, getJwt } from "../../../redux/actions";
import { API_URL_UNDERWRITING } from "../../../config/configs";
import { showErrorToast } from "../../../utils";
import OccupationDetail from "./assurity-underwriting/occupation-detail";
import ProposedInsured from "./assurity-underwriting/proposed-insured";
import GeneralSection from "./assurity-underwriting/general-section-underwriting";
import HealthSection from "./assurity-underwriting/health-section-underwriting";
import CovidQuestion from "./assurity-underwriting/covid-questions";

const AssurityUnderWriting = ({ t, underwriting, commonApiCall, ...props }) => {
  const [loading, setLoading] = useState(false);
  const [categoryList, setCategoryList] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState(null);

  const currentUnderWriting = new URLSearchParams(window.location.search).get(
    "underwriting"
  );

  useEffect(() => {
    onSubmit();
    window.scrollTo(0,0);
  }, [window.location.search]);

  /* function capitalize(input) {
    var words = input.split(" ");
    var CapitalizedWords = [];
    words.forEach((element) => {
      CapitalizedWords.push(
        element[0].toUpperCase() +
          element.slice(1, element.length).toLowerCase()
      );
    });
    return CapitalizedWords.join(" ");
  } */
  const onSubmit = async () => {
    setLoading(true);
    try {
      const isAuthorized = getJwt() ? true : false;
      const apiEndPoint =
        API_URL_UNDERWRITING +
        "pages?applicationId=" +
        underwriting?.applicationId;
      const response = await commonApiCall(
        apiEndPoint,
        "post",
        {},
        null,
        isAuthorized
      );
      if (response.status.code === 200) {
        const data = response.data ? JSON.parse(response.data) : false;

        if (data) {
          // setCategoryList(data.PageReferences);
          onGetUnderWritingCase(currentUnderWriting).then((response) => {
            setCurrentQuestion(response);
          }).then(() => {
            setLoading(false);
          });
        }
      }
    } catch (err) {
      showErrorToast(err);
    }
  };

  const onGetUnderWritingCase = async (type) => {
    try {
      let request = {};
      request.pageName = type;
      const isAuthorized = getJwt() ? true : false;
      const apiEndPoint =
        API_URL_UNDERWRITING +
        "baseQuestions?applicationId=" +
        underwriting?.applicationId +
        "&pageName=" +
        type;
      const response = await commonApiCall(
        apiEndPoint,
        "post",
        {},
        null,
        isAuthorized
      );
      if (response.status.code === 200) {
        const data = response.data ? JSON.parse(response.data) : false;
        if (data) {
          return data.BaseQuestions;
        }
        else {
          return null
        }
      }
    } catch (err) {
      showErrorToast(err);
    }
  };

  const getUnderwritingComponent = () => {
    switch (currentUnderWriting) {
      case "OCCUPATION_DETAILS":
        return (
          <OccupationDetail
            baseLoading={loading}
            baseQuestion={currentQuestion}
          />
        );
      case "PROPOSED_INSURED":
        return (
          <ProposedInsured
            baseLoading={loading}
            baseQuestion={currentQuestion}
          />
        );
      case "GENERAL_SECTION_1":
        return (
          <GeneralSection
            baseLoading={loading}
            baseQuestion={currentQuestion}
            onGetUnderWritingCase={onGetUnderWritingCase}
            t={t}
          />
        );
      case "GENERAL_SECTION_2":
        return (
          <GeneralSection
            baseLoading={loading}
            baseQuestion={currentQuestion}
            onGetUnderWritingCase={onGetUnderWritingCase}
            t={t}
          />
        );
      case "HEALTH_SECTION_1":
        return (
          <HealthSection
            baseLoading={loading}
            baseQuestion={currentQuestion}
            onGetUnderWritingCase={onGetUnderWritingCase}
            t={t}
          />
        );
      case "HEALTH_SECTION_2":
        return (
          <HealthSection
            baseLoading={loading}
            baseQuestion={currentQuestion}
            onGetUnderWritingCase={onGetUnderWritingCase}
            t={t}
          />
        );
      case "HEALTH_SECTION_3":
        return (
          <HealthSection
            baseLoading={loading}
            baseQuestion={currentQuestion}
            onGetUnderWritingCase={onGetUnderWritingCase}
            t={t}
          />
        );
      case "HEALTH_SECTION_4":
        return (
          <HealthSection
            baseLoading={loading}
            baseQuestion={currentQuestion}
            onGetUnderWritingCase={onGetUnderWritingCase}
            t={t}
          />
        );
      case "COVID_QUESTIONS":
        return (
          <CovidQuestion
            baseLoading={loading}
            baseQuestion={currentQuestion}
            t={t}
          />
        );

      default:
        return "error";
    }
  };

  return (
    <div className="assurity-underwriting-container">
      {/* <ul className="progressbar mt-5">
        {categoryList.map((item, index) => {
          return (
            <li
              key={index}
              className={
                item.className +
                " " +
                (currentUnderWriting === item.Name ? "focus" : "")
              }
            >
              <span className="text-capitalize">
                {" "}
                {capitalize(item.Name.replaceAll("_", " "))}
              </span>
            </li>
          );
        })}
      </ul> */}
      <div className="assurity-question mt-5">
        {" "}
        {loading ? (
          <div className="baseLoading">
            <h2 className="text-center">
              Please wait while we're fetching data
            </h2>
          </div>
        ) : (currentQuestion && currentQuestion.length > 0) ||
          currentUnderWriting === "COVID_QUESTIONS" ? (
          getUnderwritingComponent()
        ) : (
          <div className="baseLoading">
            <h2 className="text-center">No Data...please try again</h2>
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  underwriting: state.underwriting.underwriting,
});
export default connect(mapStateToProps, {
  commonApiCall,
})(withRouter(AssurityUnderWriting));
