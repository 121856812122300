import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

export const UnderProcessLoader = () => {
  return (
    <div className="underprocess-loader skeleton-loader">
      <SkeletonTheme>
        <div className="d-flex-between mt-3">
          <div className="w-20" style={{ lineHeight: 1 }}>
            <Skeleton count={1} height={36} />
          </div>
          <div className="w-60" style={{ lineHeight: 1 }}>
            <Skeleton count={1} height={36} />
          </div>
          <div className="w-20" style={{ lineHeight: 1 }}>
            <Skeleton count={1} height={36} />
          </div>
          <div className="w-100 mt-3" style={{ lineHeight: 1 }}>
            <Skeleton count={1} height={1} />
          </div>
        </div>
        <div className="d-flex-between">
          <div className="w-20" style={{ lineHeight: 1 }}>
            <Skeleton count={1} height={36} />
          </div>
          <div className="w-60" style={{ lineHeight: 1 }}>
            <Skeleton count={1} height={36} />
          </div>
          <div className="w-20" style={{ lineHeight: 1 }}>
            <Skeleton count={1} height={36} />
          </div>
          <div className="w-100 mt-3" style={{ lineHeight: 1 }}>
            <Skeleton count={1} height={1} />
          </div>
        </div>
        <div className="d-flex-between">
          <div className="w-20" style={{ lineHeight: 1 }}>
            <Skeleton count={1} height={36} />
          </div>
          <div className="w-60" style={{ lineHeight: 1 }}>
            <Skeleton count={1} height={36} />
          </div>
          <div className="w-20" style={{ lineHeight: 1 }}>
            <Skeleton count={1} height={36} />
          </div>
          <div className="w-100 mt-3" style={{ lineHeight: 1 }}>
            <Skeleton count={1} height={1} />
          </div>
        </div>
        <div className="d-flex-between">
          <div className="w-20" style={{ lineHeight: 1 }}>
            <Skeleton count={1} height={36} />
          </div>
          <div className="w-60" style={{ lineHeight: 1 }}>
            <Skeleton count={1} height={36} />
          </div>
          <div className="w-20" style={{ lineHeight: 1 }}>
            <Skeleton count={1} height={36} />
          </div>
          <div className="w-100 mt-3" style={{ lineHeight: 1 }}>
            <Skeleton count={1} height={1} />
          </div>
        </div>
        <div className="d-flex-between">
          <div className="w-20" style={{ lineHeight: 1 }}>
            <Skeleton count={1} height={36} />
          </div>
          <div className="w-60" style={{ lineHeight: 1 }}>
            <Skeleton count={1} height={36} />
          </div>
          <div className="w-20" style={{ lineHeight: 1 }}>
            <Skeleton count={1} height={36} />
          </div>
          <div className="w-100 mt-3" style={{ lineHeight: 1 }}>
            <Skeleton count={1} height={1} />
          </div>
        </div>
      </SkeletonTheme>
    </div>
  );
};
