import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  commonApiCall,
  getJwt,
  setSbliProgressCount,
} from "../../redux/actions";
import {
  showErrorToast,
  showSuccessToast,
  UnderWritingRoutes,
} from "../../utils";
import { API_URL_UNDERWRITING } from "../../config/configs";
import { Modal } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";

const SBLITerms = ({
  underwriting,
  commonApiCall,
  setSbliProgressCount,
  progressCount,
  ...props
}) => {
  const { applicationId } = underwriting;

  const [loading, setLoading] = useState(false);
  const [documents, setDocument] = useState([]);
  const [show, setShow] = useState(false);
  const [currentDocument, setCurrentDocument] = useState(null);

  const onTermsAgree = async () => {
    setLoading(true);
    try {
      const request = {
        applicationId: applicationId,
        timestamp: new Date().toLocaleString(),
      };
      const isAuthorized = getJwt() ? true : false;
      const apiEndPoint = API_URL_UNDERWRITING + "submitCompliance";
      const response = await commonApiCall(
        apiEndPoint,
        "post",
        request,
        null,
        isAuthorized
      );
      if (response.status.code === 200) {
        showSuccessToast(response.data.msgText);
        setSbliProgressCount(progressCount + 1);
        props.history.push(UnderWritingRoutes.BENEFICIARY);
        // saveUnderwriteDetails(underwriting?.clientUserId);
        // saveUnderwriting();
      } else {
        showErrorToast(response.status.message);
      }
    } catch (err) {
      showErrorToast("something went wrong");
    }
    setLoading(false);
  };

  const getDocuments = async () => {
    setLoading(true);
    try {
      let response = await commonApiCall(
        `${API_URL_UNDERWRITING}getDocument?applicationId=${underwriting?.applicationId}`,
        "get",
        {},
        ""
      );

      console.log(response);
      if (response.status.code === 200 || response.status.code === 205) {
        const data = JSON.parse(response.data.formsJson);

        if (data && data.length > 0) {
          setDocument(data);
          setCurrentDocument(data[4]["912"]);
        }
      } else {
        showErrorToast(response.status.message);
      }
    } catch (err) {
      showErrorToast(err);
    }
    setLoading(false);
  };

  useEffect(() => {
    getDocuments();
  }, []);

  const handleDocument = (e, doc) => {
    e.preventDefault();
    const current = documents.filter((x) => Object.keys(x)[0] === doc);

    setCurrentDocument(current[0][doc]);
    setShow(true);
  };

  return (
    <div className="sbli-terms-container mt-4">
      <h3 className="text-center mb-5">Terms and Conditions</h3>
      <p className="mb-3">
        By clicking below, I agree to these terms and conditions.
      </p>
      <p>
        By reviewing and agreeing to the following authorizations and
        disclosures, you allow us to contact any physicians, hospitals,
        pharmacies, pharmacy benefit managers, consumer reporting agencies or
        any other entity that possesses prescription, medical, financial or
        other information about you for the purpose of evaluating your
        eligibility for insurance.
      </p>
      <p>
        {" "}
        Your authorization overrides any restrictions that you may have in place
        with any entity regarding the release of your information. Any
        information gathered will not be disclosed without your approval unless
        required or permitted by law, in which case it may not be protected
        under privacy rules. Your authorization remains valid for 24 months from
        this date forward and may be revoked by sending written notice to us.
      </p>

      {!loading ? (
        documents.length > 0 && (
          <React.Fragment>
            <a
              href="#"
              onClick={(e) => handleDocument(e, "905")}
              className="d-block mb-2 mt-4"
            >
              HIPAA Authorization to Collect and Disclose Information
            </a>
            <a
              href="#"
              className="d-block mb-2"
              onClick={(e) => handleDocument(e, "904")}
            >
              Notice of Information Practices
            </a>
            <a
              href="#"
              onClick={(e) => handleDocument(e, "906")}
              className="d-block mb-2"
            >
              Privacy Policy
            </a>
            <a
              href="#"
              className="d-block mb-2"
              onClick={(e) => handleDocument(e, "911")}
            >
              {" "}
              Policy E-Application and E-Delivery Authorization and Disclosure
            </a>
            <a
              href="#"
              onClick={(e) => handleDocument(e, "912")}
              className="d-block mb-2"
            >
              Customer Notice
            </a>
          </React.Fragment>
        )
      ) : (
        <Skeleton
          style={{ display: "block", marginBottom: 10 }}
          width={"100%"}
          height={30}
          count={6}
        />
      )}
      <div className="w-100 my-4 text-center">
        <button className="btn btn-default px-5" onClick={onTermsAgree}>
          {loading ? "Processing..." : "I Agree"}
        </button>
      </div>
      {/* <div className="w-100 text-center">
        <button
          className="btn btn-link"
          onClick={() => props.history.push("/sbli-user-detail")}
        >
          Back
        </button>
      </div> */}

      <Modal show={show} onHide={() => setShow(false)} centered>
        <Modal.Header closeButton>
          <h3>Terms & Conditions</h3>
        </Modal.Header>
        <Modal.Body>
          {loading ? (
            <div className="text-center">
              <h2>Processsing Documents</h2>
              <p>This may take few minutes</p>
            </div>
          ) : documents.length > 0 && currentDocument ? (
            <div style={{ height: 500, width: "100%", overflow: "auto" }}>
              <DocViewer
                style={{
                  height: 500,
                }}
                config={{
                  header: {
                    disableHeader: true,
                    disableFileName: true,
                  },
                }}
                pluginRenderers={DocViewerRenderers}
                documents={[
                  { uri: "data:application/pdf;base64," + currentDocument },
                ]}
              />
            </div>
          ) : (
            // <iframe
            //   src={"data:application/pdf;base64," + currentDocument}
            //   width={"100%"}
            //   height={700}
            //   // title={currentDocument.title}
            // ></iframe>
            <h2>No Documents</h2>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => ({
  underwriting: state.underwriting.underwriting,
  progressCount: state.underwriting.progressCount,
});

export default connect(mapStateToProps, {
  commonApiCall,
  setSbliProgressCount,
})(withRouter(SBLITerms));
