import React, { useState, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import { Animated } from "react-animated-css";
import NumberFormat from "react-number-format";
import { Button, Modal, Form } from "react-bootstrap";
import OtpInput from "react-otp-input";
import { isEmpty, upperFirst, has } from "lodash";
import moment from "moment";
import Select from "react-select";
import { AgreementModel } from "../components/underWritingModels";
import ReCAPTCHA from "react-google-recaptcha";
import {
  commonApiCall,
  getJwt,
  UNDERWRITING_DETAILS,
  CONSUMER_DETAILS,
  setJwt,
  setSbliProgressCount,
  setAssurityProgressCount,
} from "../redux/actions/index";
import { API_URL_CLIENT, API_URL_UNDERWRITING } from "../config/configs";
import { connect } from "react-redux";
import {
  validateEmail,
  encryptme,
  setProcessStep,
  decryptme,
  ApprovalStatus,
  UnderWritingRoutes,
  showErrorToast,
  showSuccessToast,
  segmentTracking,
  getState,
} from "../utils";

const GuestSignUp = ({
  commonApiCall,
  company_information,
  underwriting,
  consumer,
  applicationCarrierInfo,
  onSignUp,
  progress = false,
  t,
  setSbliProgressCount,
  setAssurityProgressCount,
  ...props
}) => {
  const {
    applicationId,
    applicationType,
    goalType,
    policyType,
    firstName,
    lastName,
    carrierWiseLeads,
  } = underwriting;

  const [inputVal, setInputVal] = useState({
    first_name: firstName ? decryptme(firstName) : "",
    last_name: lastName ? decryptme(lastName) : "",
    email: underwriting.email ? decryptme(underwriting.email) : "",
    mobile: underwriting.mobile ? decryptme(underwriting.mobile) : "",
    countryCode: "+1",
    state: underwriting.state
      ? { label: underwriting.state.name, value: underwriting.state.id }
      : "",
    applicationType: applicationType,
    applicationId: applicationId,
    goalType: goalType,
    verificationModal: false,
    isSumbmiting: false,
    // isAgree: false,
    isRecaptchaChecked: false,
    agreementModel: false,
    otp: "",
    counter: 0,
    isRegister: false,
  });
  const [isProcessing, setIsProcessing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isTextMessage, setIsTextMessage] = useState(false);
  const [userInputVal, setUserInputVal] = useState({
    address1: "",
    dob: underwriting?.dob ? decryptme(underwriting?.dob) : null,
    drivingLicense: "",
    gender: underwriting?.gender ? decryptme(underwriting?.gender) : null,
    height: "",
    heightInch: "",
    id: "",
    postalCode: underwriting?.postalCode ? underwriting?.postalCode : "",
    profilePic: "",
    weight: "",
    city: "",
    ssn: underwriting?.ssn ? decryptme(underwriting?.ssn) : "",
    errors: {},
  });

  const [error, setError] = useState({
    first_name: "",
    last_name: "",
    email: "",
    mobile: "",
    registeredMobile: "",
    state: "",
    otp: "",
  });
  const [profileDetails, setProfileDetails] = useState(true);
  useEffect(() => {
    if (getJwt()) {
      getUserDetails();
    }
    setSbliProgressCount(0);
    setAssurityProgressCount({
      preQuestion: 0,
      basicDetail: 0,
      underWritingProgress: 0,
      beneficiaryProgress: 0,
      paymentProgress: 0,
      documentSignProgress: 0,
    });
    if (goalType === "MEDICARE") {
      getUnderWriting();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getUnderWriting = async () => {
    try {
      const appParams = {
        applicationId: applicationId,
      };
      const apiEndPoint = API_URL_UNDERWRITING + "getUnderWriting";
      const isAuthorized = getJwt() ? true : false;

      await commonApiCall(
        apiEndPoint,
        "post",
        appParams,
        UNDERWRITING_DETAILS,
        isAuthorized
      );
    } catch (err) {
      console.log(err);
    }
  };

  const [showAgree, setShowAgree] = useState(false);

  const toggleAgree = () => setShowAgree(!showAgree);

  // const [stateName, setStateName] = useState([]);

  const handleChange = (e) => {
    if (e.target.value) {
      setUserInputVal({
        ...userInputVal,
        [e.target.name]: e.target.value,
        errors: Object.assign(userInputVal.errors, { [e.target.name]: "" }),
      });
    } else
      setUserInputVal({
        ...userInputVal,
        [e.target.name]: "",
      });
  };

  const onTermsAgree = async () => {
    setLoading(true);
    try {
      const request = {
        applicationId: applicationId,
        timestamp: new Date().toLocaleString(),
      };
      const isAuthorized = getJwt() ? true : false;
      const apiEndPoint = API_URL_UNDERWRITING + "submitCompliance";
      const response = await commonApiCall(
        apiEndPoint,
        "post",
        request,
        null,
        isAuthorized
      );
      if (response.status.code === 200) {
        showSuccessToast(response.data.msgText);
        getDecision();

        // saveUnderwriteDetails(underwriting?.clientUserId);
        // saveUnderwriting();
      } else {
        showErrorToast(response.status.message);
      }
    } catch (err) {
      showErrorToast("something went wrong");
    }
    setLoading(false);
  };

  const agentAssign = async (appId = "", res) => {
    try {
      const request = {
        applicationId: appId,
      };
      const isAuthorized = getJwt() ? true : false;
      const apiEndPoint = API_URL_UNDERWRITING + "saveAssignedAgent";
      const response = await commonApiCall(
        apiEndPoint,
        "post",
        request,
        "",
        isAuthorized
      );
      if (response.status.code === 200) {
        segmentEventAfterRegister("prospect_assign", res?.clientUserId);
        setInputVal({ ...inputVal, isSumbmiting: false });
        const timer = setTimeout(() => {
          showSuccessToast(res?.applicationMessage);
          if (
            (carrierWiseLeads[0]?.carrierName === "SBLI" ||
              carrierWiseLeads[0]?.carrierName === "Centrian") &&
            res?.nextStep?.toUpperCase() !== "BENE"
          ) {
            props.history.push(UnderWritingRoutes.SBLI_PAGE);
          } else if (res?.nextStep?.toUpperCase() == "BENE" && progress) {
            onSignUp();
          } else if (
            carrierWiseLeads[0]?.carrierName === "Assurity" &&
            res?.nextStep?.toUpperCase() !== "BENE"
          ) {
            props.history.push(UnderWritingRoutes.PRE_QUESTIONS);
          } else {
            props.history.push(ApprovalStatus.APPLICATION_STATUS);
          }
        }, 1000);
        return () => clearTimeout(timer);
      } else {
        showErrorToast(response.status.message);
      }
    } catch (err) {
      showErrorToast(t("validation_message.SOMETHING_WENT_WRONG"));
    }
  };

  const saveUnderwriteDetails = async (userId) => {
    const urlPath = window.location.pathname.split("/")[1];
    try {
      const splitpath = window.location.pathname.split("/");
      let { first_name, last_name, email, mobile, state, countryCode } =
        inputVal;
      let { address1, city, postalCode, height, heightInch, weight } =
        userInputVal;

      !isEmpty(carrierWiseLeads) &&
        carrierWiseLeads.map((carrierWiseLeadsOptions, i) => {
          if (!carrierWiseLeadsOptions.agentAssigned) {
            carrierWiseLeadsOptions.leadLevel = "Level-1";
          }
        });
      let request;
      if (
        urlPath === "user-details" &&
        (carrierWiseLeads[0]?.carrierName === "SBLI" ||
          carrierWiseLeads[0]?.carrierName === "Centrian" ||
          carrierWiseLeads[0]?.carrierName === "Assurity")
      ) {
        request = {
          applicationId: applicationId,
          applicationType: applicationType,
          goalType: goalType,
          firstName: await encryptme(first_name),
          lastName: await encryptme(last_name),
          clientUserId: userId,
          email: email ? await encryptme(email) : email,
          mobile: mobile ? await encryptme(mobile) : mobile,
          // stateId: getState(postalCode).id,
          resumeProcess: setProcessStep(splitpath[1], policyType, goalType),
          nextStep: "24,PRE_QUESTIONS",
          // countryCode: await encryptme(countryCode),
          carrierWiseLeads: carrierWiseLeads,
          isMarketingCommunication: true,
          isTextMessage: isTextMessage,
        };
      } else {
        request = {
          applicationId: applicationId,
          applicationType: applicationType,
          goalType: goalType,
          firstName: await encryptme(first_name),
          lastName: await encryptme(last_name),
          clientUserId: userId,
          email: email ? await encryptme(email) : email,
          mobile: mobile ? await encryptme(mobile) : mobile,
          stateId: getState(postalCode).id,
          resumeProcess: setProcessStep(splitpath[1], policyType, goalType),
          countryCode: await encryptme(countryCode),
          carrierWiseLeads: carrierWiseLeads,
          weight: await encryptme(weight),
          ssn: ssn ? await encryptme(ssn) : null,
          addressLine1: await encryptme(address1),
          city: await encryptme(city),
          postalCode: postalCode,
          height: await encryptme(height + "." + heightInch),
          isMarketingCommunication: true,
          isTextMessage: isTextMessage,
        };
      }

      const isAuthorized = getJwt() ? true : false;
      const apiEndPoint = API_URL_UNDERWRITING + "saveUnderWriting";
      const response = await commonApiCall(
        apiEndPoint,
        "post",
        request,
        UNDERWRITING_DETAILS,
        isAuthorized
      );

      if (response.status.code === 200) {
        if (!profileDetails && underwriting?.carrierType == "online") {
          updateProfile(underwriting?.clientUserId);
        }

        agentAssign(applicationId, response?.data);
      } else {
        setIsProcessing(false);
        showErrorToast(response.status.message);
      }
    } catch (err) {
      console.log(err);
      setIsProcessing(false);
      showErrorToast(t("validation_message.SOMETHING_WENT_WRONG"));
    }
  };

  const getUserDetails = async () => {
    const isAuthorized = getJwt() ? true : false;
    let response = await commonApiCall(
      API_URL_CLIENT + "getUsersProfile",
      "",
      "get",
      "",
      isAuthorized
    );

    if (response.status.code === 200) {
      let userData = response.data;
      inputVal.first_name = underwriting.firstName
        ? await decryptme(underwriting.firstName)
        : await decryptme(userData.firstName);
      inputVal.last_name = underwriting.lastName
        ? await decryptme(underwriting.lastName)
        : await decryptme(userData.lastName);
      inputVal.mobile = underwriting.mobile
        ? await decryptme(underwriting.mobile)
        : await decryptme(userData.mobile);
      inputVal.state = {
        label: underwriting.state
          ? underwriting.state.name
          : userData.state.stateName,
        value: underwriting.state
          ? underwriting.state.id
          : userData.state.stateId,
      };
      inputVal.email = underwriting.email
        ? await decryptme(underwriting.email)
        : await decryptme(userData.email);
      userInputVal.address1 = userData.address1
        ? decryptme(userData.address1)
        : "";
      userInputVal.city = userData.city ? decryptme(userData.city) : "";
      userInputVal.dob = userData.dob
        ? decryptme(userData.dob)
        : decryptme(underwriting?.dob);
      userInputVal.drivingLicense = userData.drivingLicense
        ? decryptme(userData.drivingLicense)
        : "";
      userInputVal.gender = userData.gender
        ? decryptme(userData.gender)
        : decryptme(underwriting?.gender);
      userInputVal.height = userData.height
        ? await decryptme(userData.height).split(".")[0]
        : "";
      userInputVal.heightInch = userData.height
        ? await decryptme(userData.height).split(".")[1]
        : "";
      userInputVal.id = userData.id;
      userInputVal.postalCode = underwriting?.postalCode
        ? underwriting?.postalCode
        : decryptme(userData.postalCode);
      userInputVal.profilePic = userData.profilePic;
      userInputVal.weight = userData.weight ? decryptme(userData.weight) : "";
      userInputVal.ssn = underwriting?.ssn
        ? decryptme(underwriting?.ssn)
        : decryptme(userData.ssn);
      const isExist = has(
        response.data,
        "gender",
        "dob",
        "height",
        "weight",
        "address1",
        "postalCode"
      );
      setProfileDetails(isExist);
      setInputVal({
        ...inputVal,
        inputVal,
      });
      setUserInputVal({
        ...userInputVal,
        userInputVal,
      });
    }
  };

  const updateProfile = async (userId) => {
    let { first_name, last_name, email, mobile, state, countryCode } = inputVal;
    let {
      address1,
      city,
      postalCode,
      height,
      heightInch,
      weight,
      // id,
      gender,
      ssn,
    } = userInputVal;
    if (validateField()) {
      const request = {
        dob: dob ? await encryptme(moment(dob).format("L").toString()) : null,
        id: userId,
        city: await encryptme(city),
        stateId: getState(postalCode).id,
        firstName: await encryptme(upperFirst(first_name)),
        gender: await encryptme(gender),
        weight: weight ? await encryptme(weight) : null,
        postalCode: await encryptme(postalCode),
        address1: await encryptme(address1),
        lastName: await encryptme(upperFirst(last_name)),
        countryCode: await encryptme("+1"),
        height: height ? await encryptme(height + "." + heightInch) : null,
        ssn: ssn ? await encryptme(ssn) : null,
      };
      const authorization = true;
      const response = await commonApiCall(
        API_URL_CLIENT + "saveUserClientProfile",
        "put",
        request,
        "",
        authorization
      );
      if (response.status && response.status.code === 200) {
        return true;
      } else if (response.status && response.status.code === 409) {
        showErrorToast(response.status.message);
      }
    }
  };

  const saveNext = async () => {
    try {
      if (validateField() && !progress) {
        setIsProcessing(true);
        let { first_name, last_name, email, mobile, state, countryCode } =
          inputVal;

        let request = {
          firstName: await encryptme(upperFirst(first_name)),
          lastName: await encryptme(upperFirst(last_name)),
          email: await encryptme(email),
          mobile: await encryptme(mobile),
          countryCode: await encryptme(countryCode),
          stateId: state.value ? state.value : null,
          type: "GUEST",
          applicationId: applicationId,
          smsVerify: company_information?.smsVerify,
          isMarketingCommunication: true,
          isTextMessage: isTextMessage,
        };
        const apiEndPoint = API_URL_CLIENT + "saveUserClient";
        if (!getJwt()) {
          const response = await commonApiCall(
            apiEndPoint,
            "post",
            request,
            null,
            false,
            true
          );

          if (response.status.code === 200) {
            if (
              company_information?.smsVerify === true ||
              underwriting?.agentDetail == null
            ) {
              setIsProcessing(false);
              if (
                response.data &&
                response.data.otp &&
                response.data.otp !== ""
              )
                showSuccessToast(
                  "Please use this code to register:" + response.data.otp + " !"
                );
              else showSuccessToast(t("validation_message.successOtp"));

              setInputVal({
                ...inputVal,
                otp: "",
                registeredMobile: decryptme(response.data.registeredMobile),
                verificationModal: true,
                isRegister: response.data.isRegister,
              });
              setUserInputVal({
                ...userInputVal,
              });
            }
            if (company_information?.smsVerify === false) {
              setInputVal({
                ...inputVal,

                isRegister: response.data.isRegister,
              });
              setUserInputVal({
                ...userInputVal,
              });
              verifyOtp(response.data.isRegister);
            }
          } else {
            setIsProcessing(false);
            showErrorToast(response.status.message);
          }
        } else {
          segmentEventAfterRegister(
            "prospect_create",
            underwriting?.clientUserId
          );
          saveUnderwriteDetails(underwriting?.clientUserId);
        }
      }
    } catch (err) {
      console.log(err);
      setIsProcessing(false);
      showErrorToast(t("validation_message.SOMETHING_WENT_WRONG"));
    }
  };

  // const handleCheckbox = (e) => {
  //   const isChecked = inputVal.isAgree ? false : true;
  //   inputVal.isAgree = isChecked;
  //   setInputVal({
  //     ...inputVal,
  //     inputVal,
  //   });
  //   setUserInputVal({
  //     ...userInputVal,
  //     userInputVal,
  //   });
  //   setError({
  //     ...error,
  //     error,
  //   });
  // };
  const handleModel = (name, isHide) => {
    setInputVal({ ...inputVal, [name]: isHide });
  };
  const validateField = () => {
    let { first_name, last_name, email, mobile, state } = inputVal;
    let { address1, city, postalCode, height, heightInch, weight } =
      userInputVal;
    let validate = true;
    let postral = new RegExp(/^.{5,6}$/);

    if (first_name === "" && !progress) {
      error.first_name = t("validation_message.fName");
      validate = false;
    }
    if (last_name === "" && !progress) {
      error.last_name = t("validation_message.lName");
      validate = false;
    }
    if (email === "" && !progress) {
      error.email = t("validation_message.email");
      validate = false;
    }

    if (email !== "" && !validateEmail(email) && !progress) {
      error.email = t("validation_message.validEmail");
      validate = false;
    }
    if (mobile === "" && !progress) {
      error.mobile = t("validation_message.mobile");
      validate = false;
    }
    if (mobile !== "" && mobile.length < 10 && !progress) {
      error.mobile = t("validation_message.validMobile");
      validate = false;
    }

    if (
      underwriting?.carrierType == "online" &&
      !(
        underwriting?.carrierWiseLeads[0]?.carrierName === "SBLI" ||
        underwriting?.carrierWiseLeads[0]?.carrierName === "Centrian" ||
        underwriting?.carrierWiseLeads[0]?.carrierName === "Assurity"
      )
    ) {
      if (height === "") {
        validate = false;
        error["height"] = t("my_profile.enter_height_feet");
      }
      if (heightInch === "") {
        validate = false;
        error["heightInch"] = t("my_profile.enter_height_inch");
      }
      if (weight === "") {
        validate = false;
        error["weight"] = t("my_profile.enter_Weight_lb");
      }
      if (!city || city.trim() === "") {
        validate = false;
        error["city"] = t("my_profile.enter_city");
      }
      if (!address1 || address1.trim() === "") {
        validate = false;
        error["address1"] = t("my_profile.enter_street_address");
      }

      if (!postral.test(postalCode)) {
        validate = false;
        if (!postalCode || postalCode.trim() === "") {
          error["postalCode"] = t("my_profile.enter_postal/zip_code");
        } else
          error["postalCode"] = t("my_profile.enter_valid_postal/zip_code");
      }

      if (postalCode.trim() !== "" && !getState(postalCode)) {
        validate = false;
        error["postalCode"] = t("my_profile.enter_valid_postal/zip_code");
      }
    }

    // if (state === "" || state.label === null || state.value === 0) {
    //   error.state = t("validation_message.state");
    //   validate = false;
    // }
    // if (!isAgree) validate = false;
    // if (underwriting?.carrierType == "online" && !validateUserForm()) {
    //   validate = false;
    // }
    setError({
      ...error,
      error,
    });
    return validate;
  };

  // const validateUserForm = () => {
  //   const { ssn, address1, city, postalCode } = userInputVal;
  //   let errors = {},
  //     isValid = true;

  //   let postral = new RegExp(/^.{5,6}$/);
  //   if (underwriting?.carrierType == "online" && progress) {
  //     if (!height) {
  //       isValid = false;
  //       errors["height"] = t("my_profile.enter_height_feet");
  //     }
  //     if (!heightInch) {
  //       isValid = false;
  //       errors["heightInch"] = t("my_profile.enter_height_inch");
  //     }
  //     if (!weight) {
  //       isValid = false;
  //       errors["weight"] = t("my_profile.enter_Weight_lb");
  //     }
  //     if (
  //       !ssn &&
  //       (underwriting?.carrierWiseLeads[0]?.carrierName === "SBLI" ||
  //         underwriting?.carrierWiseLeads[0]?.carrierName === "Centrian" ||
  //         underwriting?.carrierWiseLeads[0]?.carrierName === "Assurity")
  //     ) {
  //       isValid = false;
  //       errors["ssn"] = "Please enter your SSN number";
  //     }
  //     if (!address1 || address1.trim() === "") {
  //       isValid = false;
  //       errors["address1"] = t("my_profile.enter_street_address");
  //     }

  //     if (!city || city.trim() === "") {
  //       isValid = false;
  //       errors["city"] = t("my_profile.enter_city");
  //     }

  //     if (!postral.test(postalCode)) {
  //       isValid = false;
  //       if (!postalCode || postalCode.trim() === "") {
  //         errors["postalCode"] = t("my_profile.enter_postal/zip_code");
  //       } else
  //         errors["postalCode"] = t("my_profile.enter_valid_postal/zip_code");
  //     }

  //     if (postalCode.trim() !== "" && !getState(postalCode)) {
  //       isValid = false;
  //       errors["postalCode"] = t("my_profile.enter_valid_postal/zip_code");
  //     }
  //   }

  //   setUserInputVal({ ...userInputVal, errors: errors });
  //   return isValid;
  // };

  const handleClose = (modelName) => {
    setInputVal({ ...inputVal, [modelName]: false });
  };

  const sendOtp = async () => {
    const { mobile, countryCode } = inputVal;
    if (validateField()) {
      const request = {
        countryCode: await encryptme(countryCode),
        mobile: registeredMobile
          ? await encryptme(registeredMobile)
          : await encryptme(mobile),
        role: "ROLE_CLIENT",
      };
      const apiEndPoint = API_URL_CLIENT + "sendOtpViaSms";
      const response = await commonApiCall(apiEndPoint, "post", request, null);
      if (response.status.code === 200) {
        setInputVal({ ...inputVal, counter: 30 });
        if (response.data && response.data.otp && response.data.otp !== "")
          showSuccessToast(
            "Please use this code to register:" + response.data.otp + " !"
          );
        else showSuccessToast(t("validation_message.successOtp"));
      } else {
        showErrorToast(response.status.message);
      }
    }
  };

  const validateOtp = () => {
    const { otp } = inputVal;
    let isValid = true;
    if (!otp && company_information?.smsVerify === true) {
      isValid = false;
      error.otp = "Please enter login code";
    } else if (typeof otp !== "undefined") {
      if (
        otp.toString().length < 6 &&
        company_information?.smsVerify === true
      ) {
        isValid = false;
        error.otp = "Please enter valid login code";
      }
    }

    setError({
      ...error,
      error,
    });
    return isValid;
  };

  const segmentEventAfterRegister = (eventName, id) => {
    if (applicationCarrierInfo?.selectedPolicies && id) {
      let filterLists = [];
      let liaison_type =
        underwriting?.productSelection === "appliedOnline" ? "self" : "agent";
      for (let i = 0; i < applicationCarrierInfo.selectedPolicies.length; i++) {
        filterLists.push({
          policy_id:
            applicationCarrierInfo.selectedPolicies[i].applicationCarrierId,
          policy_type: applicationCarrierInfo.selectedPolicies[i].policyType,
        });
      }
      const eventObject = {
        agent_id: underwriting?.agentDetail?.agentId,
        application_id: underwriting.applicationId,
        policy_details: filterLists,
        client_id: id,
        policy_type: underwriting?.policyType,
        liaison_type: liaison_type,
      };
      segmentTracking(eventName, eventObject);
    }
  };

  const verifyOtp = async (isRegister = false) => {
    const { mobile, countryCode, otp, isSumbmiting } = inputVal;
    if (validateOtp()) {
      let request;
      setInputVal({ ...inputVal, isSumbmiting: true });
      if (company_information?.smsVerify === false) {
        request = {
          loginType: "MOBILE",
          countryCode: await encryptme(countryCode),
          value: registeredMobile
            ? encryptme(registeredMobile)
            : encryptme(mobile),
          role: "ROLE_CLIENT",
          smsVerify: false,
          applicationId: applicationId,
          isRegister: isRegister,
        };
      } else {
        request = {
          loginType: "MOBILE",
          countryCode: await encryptme(countryCode),
          value: registeredMobile
            ? encryptme(registeredMobile)
            : encryptme(mobile),
          otp: otp,
          role: "ROLE_CLIENT",
          smsVerify: true,
          applicationId: applicationId,
          isRegister: inputVal.isRegister,
        };
      }

      const apiEndPoint = API_URL_CLIENT + "signIn";
      const response = await commonApiCall(
        apiEndPoint,
        "post",
        request,
        CONSUMER_DETAILS
      );
      if (response.status.code === 200) {
        // setInputVal({ ...inputVal, isSumbmiting: false });
        setJwt(response.data.token);
        if (applicationCarrierInfo?.selectedPolicies && response.data.id) {
          segmentEventAfterRegister("prospect_create", response.data.id);
          segmentEventAfterRegister("prospect_assign", response.data.id);
        }
        // updateProfile(response.data.id);
        saveUnderwriteDetails(response.data.id);
        if (window.ttq) {
          window.ttq
            .instance("CAJMOFBC77U2KNVFVCS0")
            .track("CompleteRegistration");
        }
      } else {
        setIsProcessing(false);
        setInputVal({ ...inputVal, isSumbmiting: false });
        setUserInputVal({ ...userInputVal });
        showErrorToast(response.status.message);
      }
    }
  };

  const getDecision = async () => {
    try {
      const response = await commonApiCall(
        `${API_URL_UNDERWRITING}getDecison?applicationId=${applicationId}`, //underwriting?.applicationId
        "get",
        {},
        ""
      );
      if (response.status.code === 200) {
        if (response.data.statusCode.submitForm) {
        } else {
          showErrorToast(response.data.statusCode.msgTxt);
          props.history.push(ApprovalStatus.APPLICATION_STATUS);
        }
        // showSuccessToast(response.data.msgText);
        // saveUnderwriting();
      } else {
        showErrorToast(response.status.message);
      }
    } catch (err) {
      console.log(err);
      showErrorToast("something went wrong");
    }
  };

  const showRestForm = () => {
    return (
      <>
        <Modal
          className="pdf-modal"
          show={showAgree}
          onHide={() => toggleAgree()}
          centered
          size="sm"
        >
          <Modal.Header closeButton>
            <Modal.Title>Terms & Conditions</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              By clicking below, I agree with these Terms and Conditions By
              reviewing and agreeing to the following authorizations and
              disclosures, you allow us to contact any physicians, hospitals,
              pharmacies, pharmacy benefit managers, consumer reporting agencies
              or any other entity that possesses prescription, medical,
              financial or other information about you for the purpose of
              evaluating your eligibility for insurance.
            </p>{" "}
            <p>
              Your authorization overrides any restrictions that you may have in
              place with any entity regarding the release of your information.
            </p>{" "}
            <p>
              Any information gathered will not be disclosed without your
              approval unless required or permitted by law, in which case it may
              not be protected under privacy rules. Your authorization remains
              valid from this date forward and may be revoked by sending written
              notice to us.
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="btn btn-primary" onClick={() => onTermsAgree()}>
              {loading ? "Processing..." : "Agree"}
            </Button>
            <Button variant="btn btn-primary" onClick={() => toggleAgree()}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        <h3 className="text-medium mb-2 pb-md-0 text-center">
          Personal Information
        </h3>
        <p className="text-center mb-3">
          In order to issue your policy, we need to run a secure ID check. The
          below information is private and will not be shared.
        </p>
        <form className="row">
          {underwriting &&
            underwriting?.carrierWiseLeads &&
            underwriting?.carrierWiseLeads.length > 0 &&
            (carrierWiseLeads[0]?.carrierName == "SBLI" ||
              carrierWiseLeads[0]?.carrierName == "Centrian" ||
              carrierWiseLeads[0]?.carrierName == "Assurity") && (
              <div className="col-lg-6 col-md-6">
                <div className="form-group">
                  <div className="input-effect ">
                    <NumberFormat
                      name="ssn"
                      id="ssn"
                      className="form-control"
                      placeholder={"Social Security Number (SSN#)"}
                      format="###-##-####"
                      value={ssn}
                      onValueChange={(e) =>
                        handleChange({
                          target: { name: "ssn", value: e.value },
                        })
                      }
                    />
                    <label htmlFor="SSN">Social Security Number (SSN#)</label>
                    <span className="text-danger">{errors.ssn}</span>
                  </div>
                </div>
              </div>
            )}
          {underwriting &&
            underwriting?.carrierWiseLeads &&
            underwriting?.carrierWiseLeads.length > 0 &&
            underwriting?.carrierType === "online" &&
            (carrierWiseLeads[0]?.carrierName != "SBLI" ||
              carrierWiseLeads[0]?.carrierName != "Centrian" ||
              carrierWiseLeads[0]?.carrierName != "Assurity") && (
              <React.Fragment>
                {" "}
                <div className={"col-lg-3 col-md-3"}>
                  <div className="form-group">
                    <div className="input-effect">
                      <NumberFormat
                        className="form-control"
                        placeholder={t("manual_form.weight_lbs")}
                        aria-describedby="basic-addon2"
                        format="###"
                        value={weight}
                        isAllowed={(values) => {
                          const { formattedValue, floatValue } = values;
                          return formattedValue === "" || floatValue <= 500;
                        }}
                        onValueChange={(e) =>
                          handleChange({
                            target: {
                              name: "weight",
                              value: e.value,
                            },
                          })
                        }
                      />
                      <label>{t("manual_form.weight_lbs")}</label>
                      <em className="input-icon"></em>
                      <span className="text-danger ">{error.weight}</span>
                    </div>
                  </div>
                </div>
                <div className={"col-lg-3 col-md-3"}>
                  <div className="form-group">
                    <div className="input-effect">
                      <NumberFormat
                        className="form-control"
                        placeholder={t("manual_form.height_feet")}
                        aria-describedby="basic-addon2"
                        format="#"
                        value={height}
                        isAllowed={(values) => {
                          const { formattedValue, floatValue } = values;
                          return formattedValue === "" || floatValue <= 7;
                        }}
                        onValueChange={(e) =>
                          handleChange({
                            target: {
                              name: "height",
                              value: e.value,
                            },
                          })
                        }
                      />
                      <label>{t("manual_form.height_feet")}</label>
                      <em className="input-icon"></em>
                      <span className="text-danger ">{error.height}</span>
                    </div>
                  </div>
                </div>
                <div className={"col-lg-3 col-md-3"}>
                  <div className="form-group">
                    <div className="input-effect">
                      <NumberFormat
                        className="form-control"
                        placeholder={t("manual_form.height_inch")}
                        aria-describedby="basic-addon2"
                        format="##"
                        value={heightInch}
                        isAllowed={(values) => {
                          const { formattedValue, floatValue } = values;
                          return formattedValue === "" || floatValue <= 11;
                        }}
                        onValueChange={(e) =>
                          handleChange({
                            target: {
                              name: "heightInch",
                              value: e.value,
                            },
                          })
                        }
                      />
                      <label>{t("manual_form.height_inch")}</label>
                      <em className="input-icon"></em>
                      <span className="text-danger ">{error.heightInch}</span>
                    </div>
                  </div>
                </div>{" "}
              </React.Fragment>
            )}
        </form>
        <h3 className="text-medium mb-3 pb-md-0 text-center">Address</h3>
        <form className="row">
          <div className="col-lg-12 col-md-12">
            <div className="form-group">
              <div className="input-effect">
                <input
                  className="form-control"
                  placeholder={t("manual_form.street_address")}
                  name="address1"
                  id="address1"
                  type="text"
                  value={address1}
                  onChange={(e) => handleChange(e)}
                />
                <label>{t("manual_form.street_address")}</label>
                <em className="input-icon"></em>
                <span className="text-danger ">{error.address1}</span>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6">
            <div className="form-group fixed-floating-label mb-4">
              <div className="input-effect">
                <input
                  className="form-control"
                  placeholder={t("manual_form.city")}
                  name="city"
                  id="city"
                  type="text"
                  value={city}
                  onChange={(e) => handleChange(e)}
                />
                <label>{t("manual_form.city")}</label>
                <em className="input-icon"></em>
                <span className="text-danger ">{error.city}</span>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-3">
            <div className="form-group">
              <div className="input-effect">
                <input
                  className="form-control"
                  placeholder={t("manual_form.postal_zip_code")}
                  name="postalCode"
                  id="Postal/Zip Code"
                  type="text"
                  maxLength="5"
                  value={postalCode}
                  onChange={(e) => handleChange(e)}
                  disabled={
                    underwriting?.productSelection === "professionalHelp" &&
                    underwriting?.postalCode
                      ? true
                      : false
                  }
                />
                <label>{t("manual_form.postal_zip_code")}</label>
                <em className="input-icon"></em>
                <span className="text-danger ">{error.postalCode}</span>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-3">
            <div className="form-group fixed-floating-label mb-4">
              <div className="default-select with-border">
                <Select
                  className={"custom-select-menu"}
                  classNamePrefix={"custom-select"}
                  value={
                    postalCode != ""
                      ? {
                          value: getState(postalCode)?.id,
                          label: getState(postalCode)?.state,
                        }
                      : ""
                  }
                  isDisabled={true}
                />
                <span className="fixed-label">{t("manual_form.state")}</span>
                <em className="input-icon"></em>
              </div>
            </div>
          </div>
        </form>
      </>
    );
  };
  const handleRecaptcha = (value) => {
    if (value) {
      setInputVal({ ...inputVal, isRecaptchaChecked: true });
    }
  };
  let {
    first_name,
    last_name,
    email,
    mobile,
    registeredMobile,
    // state,
    // countryCode,
    verificationModal,
    otp,
    counter,
    agreementModel,
    // isAgree,
    isSumbmiting,
    isRecaptchaChecked,
  } = inputVal;
  let {
    address1,
    dob,
    // drivingLicense,
    // gender,
    height,
    heightInch,
    // id,
    postalCode,
    // profilePic,
    weight,
    city,
    ssn,
    errors,
  } = userInputVal;
  return (
    <div className="basic-details">
      <div className="row justify-content-center">
        <div className="col-lg-9">
          {/*Begin: Page Title */}
          {Array.isArray(carrierWiseLeads) &&
          (carrierWiseLeads[0]?.carrierName !== "SBLI" ||
            carrierWiseLeads[0]?.carrierName !== "Centrian" ||
            carrierWiseLeads[0]?.carrierName !== "Assurity") ? (
            <div className="title-header">
              {underwriting?.carrierType != "online" ? (
                <>
                  <h2>
                    {company_information?.firstName ? (
                      <>
                        Almost done!{" "}
                        <>
                          {company_information?.showCompany ? (
                            <>
                              {company_information &&
                                company_information.companyName !== undefined &&
                                decryptme(company_information?.companyName)}
                            </>
                          ) : (
                            <>
                              {company_information &&
                                company_information.firstName !== undefined &&
                                decryptme(company_information?.firstName)}
                            </>
                          )}
                        </>{" "}
                        will take it from here.
                      </>
                    ) : (
                      t(
                        "guest_sign_up.provide_basic_details_to_connect_with_professional"
                      )
                    )}
                  </h2>
                  <p className="pb-0 color-grey">
                    {company_information?.firstName
                      ? `${decryptme(company_information?.firstName)} ${t(
                          "guest_sign_up.subtitle_dynamic"
                        )}`
                      : t("guest_sign_up.subtitle")}
                  </p>{" "}
                </>
              ) : (
                <>
                  {carrierWiseLeads[0]?.carrierName !== "SBLI" ||
                  carrierWiseLeads[0]?.carrierName !== "Centrian" ||
                  carrierWiseLeads[0]?.carrierName !== "Assurity" ? (
                    <>
                      <h2>Create an account to get started.</h2>
                      <p className="pb-0 px-md-5 color-grey">
                        Creating an account lets you save your progress and
                        easily access your information.
                      </p>{" "}
                    </>
                  ) : (
                    <>
                      <h2>Almost done!</h2>
                      <p className="pb-0 px-md-5 color-grey">
                        After you complete the information below, you will
                        receive an email from <br />
                        {underwriting &&
                          underwriting?.carrierWiseLeads &&
                          underwriting?.carrierWiseLeads.length > 0 &&
                          carrierWiseLeads[0]?.carrierName}{" "}
                        asking you to complete your online application.
                      </p>{" "}
                    </>
                  )}
                </>
              )}
            </div>
          ) : (
            <>
              <h2 className="text-center">
                This process will take about 15 minutes, but it’s worth it!
              </h2>
              <p className="pb-0 px-md-5 mb-4 color-grey text-center">
                At the end of this process you will have an active life
                insurance policy.
              </p>{" "}
            </>
          )}
          {/*End: Page Title */}
          {/*Begin: Sign Up Form Wrapper */}
          <div className="signup-wrapper">
            {/*Begin: Sign Up Form with Animate.css Element */}
            <Animated
              animationIn="fadeInRight"
              animationOut="fadeOutLeft"
              animationInDuration={800}
              animationOutDuration={800}
              isVisible={true}
            >
              <div className="form-wrapper">
                {!progress && (
                  <form className="row">
                    <div className="col-md-6">
                      {/*Begin: Form Group - First Name */}
                      <div className="form-group">
                        <div className="input-effect">
                          <input
                            className="form-control"
                            placeholder="First Name"
                            name="firstName"
                            id="firstName"
                            type="text"
                            value={first_name}
                            onChange={(e) => {
                              inputVal.first_name = e.target.value;
                              error.first_name = "";
                              setError({
                                ...error,
                                error,
                              });
                              setInputVal({
                                ...inputVal,
                                inputVal,
                              });
                            }}
                          />
                          <label htmlFor="firstName">
                            {t("guest_sign_up.first_name")}
                          </label>
                          <span className="text-danger ">
                            {error.first_name}
                          </span>
                          <em className="input-icon"></em>
                        </div>
                      </div>
                      {/*End: Form Group - First Name */}
                    </div>
                    <div className="col-md-6">
                      {/*Begin: Form Group - Last Name */}
                      <div className="form-group">
                        <div className="input-effect">
                          <input
                            className="form-control"
                            placeholder="Last Name"
                            name="lastName"
                            id="lastName"
                            type="text"
                            value={last_name}
                            onChange={(e) => {
                              inputVal.last_name = e.target.value;
                              error.last_name = "";
                              setError({
                                ...error,
                                error,
                              });
                              setInputVal({
                                ...inputVal,
                                inputVal,
                              });
                            }}
                          />
                          <label htmlFor="lastName">
                            {t("guest_sign_up.last_name")}
                          </label>
                          <span className="text-danger ">
                            {error.last_name}
                          </span>
                          <em className="input-icon"></em>
                        </div>
                      </div>
                      {/*End: Form Group - Last Name */}
                    </div>
                    <div className="col-md-6">
                      {/*Begin: Form Group - Email */}
                      <div className="form-group">
                        <div className="input-effect">
                          <input
                            className="form-control"
                            placeholder="Email"
                            name="email"
                            id="email"
                            type="mail"
                            value={email}
                            onChange={(e) => {
                              inputVal.email = e.target.value;
                              error.email = "";
                              error.mobile = "";
                              setError({
                                ...error,
                                error,
                              });
                              setInputVal({
                                ...inputVal,
                                inputVal,
                              });
                            }}
                          />
                          <label htmlFor="email">
                            {t("guest_sign_up.email")}
                          </label>
                          <span className="text-danger ">{error.email}</span>
                          <em className="input-icon"></em>
                        </div>
                      </div>
                      {/*End: Form Group - Last Name */}
                    </div>
                    <div className="col-md-6">
                      {/*Begin: Form Group - Mobile */}
                      <div className="form-group">
                        <div className="input-effect">
                          <NumberFormat
                            name="mobile"
                            id="mobile"
                            value={mobile}
                            className="form-control"
                            placeholder="Mobile"
                            format="(###) ###-####"
                            onValueChange={(e) => {
                              inputVal.mobile = e.value;
                              error.mobile = "";
                              error.email = "";
                              setError({
                                ...error,
                                error,
                              });
                              setInputVal({
                                ...inputVal,
                                inputVal,
                              });
                            }}
                          />
                          <label htmlFor="mobile">
                            {t("guest_sign_up.mobile")}
                          </label>
                          <span className="text-danger ">{error.mobile}</span>
                          <h6 className="mt-2 text-default">
                            {t("guest_sign_up.mobile_lable_text")}
                          </h6>
                        </div>
                      </div>

                      {/*End: Form Group - Mobile */}
                    </div>
                  </form>
                )}
                <div className="col-12">
                  <div className="form-group">
                    <p className="pb-0">
                      <span className="custom-checkbox">
                        <label className="m-0 text-default">
                          <input
                            type="checkbox"
                            name="isTextMessage"
                            checked={isTextMessage}
                            onChange={(e) => setIsTextMessage(e.target.checked)}
                          />
                          <span></span> By checking this box, I consent to
                          receiving my agent’s texts related to my life
                          insurance policy*
                        </label>
                      </span>
                    </p>
                    <p className=" pb-0 text-default mt-2">
                      *The number of texts received per month may vary. Message
                      and data rates may apply. Reply STOP to cancel.
                    </p>
                  </div>
                </div>

                {underwriting?.carrierType === "online"
                  ? underwriting &&
                    underwriting?.carrierWiseLeads &&
                    underwriting?.carrierWiseLeads.length > 0 &&
                    (carrierWiseLeads[0]?.carrierName == "SBLI" ||
                      carrierWiseLeads[0]?.carrierName == "Centrian" ||
                      carrierWiseLeads[0]?.carrierName == "Assurity")
                    ? progress
                      ? showRestForm()
                      : null
                    : showRestForm()
                  : null}

                {company_information?.smsVerify == false && (
                  <ReCAPTCHA
                    sitekey={process.env.REACT_APP_RECAPTCHA_ID}
                    onChange={handleRecaptcha}
                    className="recaptcha"
                    type="image"
                    onExpired={() =>
                      setInputVal({ ...inputVal, isRecaptchaChecked: false })
                    }
                  />
                )}
                <div className="col-12 mb-5">
                  <div className="text-center">
                    <button
                      className="btn btn-gradient px-5 py-3"
                      onClick={() => (progress ? toggleAgree() : saveNext())}
                      disabled={
                        company_information?.smsVerify == false &&
                        !isRecaptchaChecked
                      }
                      type="button"
                    >
                      {isProcessing
                        ? t("manual_form.processing")
                        : t("continue")}
                    </button>
                  </div>
                </div>
                {!progress && (
                  <div className="col-12">
                    {/*Begin: Form Group - Terms and Policy */}
                    <div className="form-group">
                      <p className="text-center pb-0">
                        {/*Begin: Form Group - Custom Checkbox */}
                        <span className="custom-checkbox">
                          <label className="m-0">
                            {/* <input
                              type="checkbox"
                              name="isAgree"
                              onChange={(e) => handleCheckbox(e)}
                            /> */}
                            <span></span> By clicking Sign Up you agree to
                          </label>
                        </span>
                        <Link
                          to="#"
                          className="text-link font-weight-normal"
                          title="Terms of Service"
                          onClick={() => handleModel("agreementModel", true)}
                        >
                          iLife’s Terms of Service
                        </Link>{" "}
                        and{""}
                        <Link
                          to="//ilife.tech/privacy-policy"
                          className="text-link font-weight-normal"
                          target="_blank"
                        >
                          Privacy Policy.
                        </Link>
                      </p>
                      {/* {errors.isAgree && (
                          <small className="text-danger err-msg">
                            {errors.isAgree}
                          </small>
                        )} */}
                    </div>
                    {/*End: Form Group - Terms and Policy */}
                  </div>
                )}
              </div>
            </Animated>
            {/*End: Sign Up Form with Animate.css Element */}
          </div>
          {/*End: Sign Up Form Wrapper */}
          {userInputVal.id == "" && (
            <div className="not-member">
              <p className="pb-0">
                {t("register.already_have_an_account")}
                <Link
                  to={UnderWritingRoutes.LOGIN + "/myplans"}
                  className="text-link"
                  title="Sign Up"
                >
                  {t("register.log_in")}
                </Link>
              </p>
            </div>
          )}
          <div className="col-12 text-center mt-3">
            <Link
              to={
                goalType === "MEDICARE"
                  ? underwriting?.medicarePolicyFlag
                    ? UnderWritingRoutes.MEDICARE_PLAN_LIST
                    : company_information?.prospectJourney === "single_page"
                    ? UnderWritingRoutes.SINGLE_PAGE
                    : UnderWritingRoutes.ZIPCODE
                  : UnderWritingRoutes.PLANS
              }
              className="btn btn-link"
              title="Back"
            >
              Back
            </Link>
          </div>
        </div>
      </div>
      <Modal
        className="plan-modal verification-modal"
        show={verificationModal}
        onHide={() => handleClose("verificationModal")}
        centered
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("register.verify_your_mobile_number")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="signup-otp">
            <Animated
              animationIn="fadeInRight"
              animationOut="fadeOutLeft"
              animationInDuration={800}
              animationOutDuration={800}
              isVisible={true}
            >
              <div className="form-wrapper">
                <div className="form-text">
                  <h3 className="card-text mb-5 text-center">
                    {t("register.we’ve_sent_an_otp_to")}{" "}
                    <span className="text-link">
                      <NumberFormat
                        format="### ### ####"
                        value={registeredMobile ? registeredMobile : mobile}
                        displayType={"text"}
                        renderText={(value) => <span>{value}</span>}
                      />
                    </span>{" "}
                    {t(
                      "register.to_verify_your_account_please_enter_six_digit_code_below"
                    )}
                  </h3>
                </div>
                <Form
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      verifyOtp(false);
                    }
                  }}
                >
                  <div className="otp-input-wrapper">
                    <OtpInput
                      onChange={(otp) => {
                        inputVal.otp = otp;
                        error.otp = "";
                        setError({
                          ...error,
                          error,
                        });
                        setInputVal({
                          ...inputVal,
                          inputVal,
                        });
                      }}
                      numInputs={6}
                      inputStyle={"otp-input"}
                      value={otp}
                      isInputNum={true}
                    />
                    {error.otp && (
                      <small className="text-danger err-msg">{error.otp}</small>
                    )}
                  </div>
                  <div className="form-group">
                    <p className="text-center pb-0">
                      {t("register.didnt_receive_code_yet")}
                      {counter === 0 ? (
                        <button
                          className="btn btn-link text-primary"
                          type="button"
                          onClick={sendOtp}
                          style={{ textTransform: "none" }}
                        >
                          {t("register.resend_otp")}
                        </button>
                      ) : (
                        <span className="btn btn-link text-primary">
                          ({counter})
                        </span>
                      )}
                    </p>
                  </div>
                  <div className="text-center">
                    <button
                      className="btn btn-gradient px-5 py-3"
                      type="button"
                      onClick={() => verifyOtp(false)}
                      disabled={isSumbmiting ? true : false}
                    >
                      {isSumbmiting ? "Processing" : t("submit")}
                    </button>
                  </div>
                </Form>
              </div>
            </Animated>
          </div>
        </Modal.Body>
      </Modal>
      {agreementModel && (
        <AgreementModel
          agreementModel={agreementModel}
          t={t}
          handleModel={handleModel}
        ></AgreementModel>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  underwriting: state.underwriting.underwriting,
  consumer: state.consumer?.consumerdetails,
  applicationCarrierInfo: state.underwriting.applicationCarrierInfo,
  company_information: state.underwriting.companyInformation,
});

export default connect(mapStateToProps, {
  commonApiCall,
  setSbliProgressCount,
  setAssurityProgressCount,
})(withRouter(GuestSignUp));
