import React, { memo, useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import SbliBeneficiary from "../../../components/sbli-beneficiary";
import AssurityBeneficiary from "./assurity-beneficiary";
import AssurityPayment from "./assurity-payment";
import AssurityUnderwriting from "./assurity-underwriting";
import AssurityUserDetail from "./assurity-user-detail";
import AssurityPhysicianRequest from "./assurity-physician-request";
import PreQuestions from "./pre-questions";
import { setAssurityProgressCount } from "../../../redux/actions";
import AssurityDocument from "./assurity-document";
import AssurityPreview from "./assurity-preview";
import AssurityPolicy from "./assurity-policy";
import AssurityDenial from "./assurity-denial";
import AssurityPreApproval from "./assurity-pre-approval";
import AssurityPreNotice from "./assurity-pre-notice";
import AssurityPolicyDecline from "./assurity-underwriting/assurity-policy-decline";
import AccessTokenForDomain from "./accessTokenForDomain";

const AssurityLayout = ({
  t,
  assuProgressCount,
  setAssurityProgressCount,
  ...props
}) => {
  useEffect(() => {
    document.getElementsByClassName("page-wrapper")[0].style.padding =
      "60px 0 0 0";
    window.scrollTo(0,0);
  }, []);

  useEffect(() => {
    window.scrollTo(0,0);
  },[window.location.pathname]);
  
  useEffect(() => {}, [assuProgressCount]);

  const screen = window.location.pathname.split("/")[1];

  const showAssurityScreen = () => {
    switch (screen) {
      case "pre-questions":
        return (
          <PreQuestions
            onAnswered={
              (count) => {
                setAssurityProgressCount({
                  ...assuProgressCount,
                  preQuestion: count,
                });
              }

              // setAssurityProgressCount(assuProgressCount)
            }
          />
        );
      case "assurity-user-detail":
        return (
          <AssurityUserDetail
            onAnswered={() =>
              setAssurityProgressCount({
                ...assuProgressCount,
                basicDetail: 100,
              })
            }
            t={t}
          />
        );
      case "assurity-physician-request":
        return <AssurityPhysicianRequest t={t} />;
      case "assurity-underwriting":
        return <AssurityUnderwriting t={t} />;
      case "assurity-beneficiary":
        return <AssurityBeneficiary t={t} />;
      case "assurity-pre-approval":
        return <AssurityPreApproval t={t} />;
      case "assurity-notice":
        return <AssurityPreNotice t={t} />;
      case "assurity-deny":
        return <AssurityDenial t={t} />;
      case "assurity-payment":
        return <AssurityPayment t={t} />;
      case "assurity-documents":
        return <AssurityDocument t={t} />;
      case "assurity-preview":
        return <AssurityPreview t={t} />;
      case "assurity-policy":
        return <AssurityPolicy t={t} />;
      case "assurity-policy-decline":
        return <AssurityPolicyDecline t={t} />;
      case "tokenGenerate":
        return <AccessTokenForDomain t={t} />;
    }
  };

  const getPreQuestionProgress = (current, total) => {
    return Math.round((current / total) * 100);
  };

  return (
    <div className="assurity-container design-3">
      <div className="progress-group">
        <div className="wrapper">
          <div className="step step01 complete">
            <progress
              className="progress"
              value={getPreQuestionProgress(assuProgressCount?.preQuestion, 10)}
              max="100"
              aria-describedby="Step 01"
            ></progress>
            <div className="progress-circle"></div>
          </div>

          <div className="step step02 complete">
            <progress
              className="progress"
              value={assuProgressCount?.basicDetail}
              max="100"
              aria-describedby="Step 02"
            ></progress>
            <div className="progress-circle"></div>
          </div>
          <div className="step step03 complete">
            <div className="substeps">
              <span>1</span>
              <span>2</span>
              <span>3</span>
              <span>4</span>
              <span>5</span>
              <span>6</span>
              <span>7</span>
              <span>8</span>
              <span>9</span>
            </div>
            <progress
              className="progress"
              value={getPreQuestionProgress(
                assuProgressCount?.underWritingProgress,
                9
              )}
              max="100"
              aria-describedby="Step 03"
            ></progress>
            <div className="progress-circle"></div>
          </div>
          <div className="step step04 complete">
            <progress
              className="progress"
              value={assuProgressCount?.beneficiaryProgress}
              max="100"
              aria-describedby="Step 04"
            ></progress>
            <div className="progress-circle"></div>
          </div>
          <div className="step step05">
            <progress
              className="progress"
              value={assuProgressCount?.paymentProgress}
              max="100"
              aria-describedby="Step 05"
            ></progress>
            <div className="progress-circle"></div>
          </div>
          <div className="step step06 complete">
            <progress
              className="progress"
              value={assuProgressCount?.documentSignProgress}
              max="100"
              aria-describedby="Step 06"
            ></progress>
            <div className="progress-circle"></div>
          </div>
        </div>
        <div className="progress-labels">
          <div className="label">Pre Qualification Questions</div>
          <div className="label">Basic Details</div>
          <div className="label">Underwriting Process</div>
          <div className="label">Beneficiary</div>
          <div className="label">Payment</div>
          <div className="label">Document Sign</div>
        </div>
      </div>
      <div className="assurity-content-container mt-5">
        {showAssurityScreen()}
      </div>{" "}
    </div>
  );
};

const mapStateToProps = (state) => ({
  assuProgressCount: state.underwriting.assuProgressCount,
});
export default connect(mapStateToProps, { setAssurityProgressCount })(
  withRouter(AssurityLayout)
);
