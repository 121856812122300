import React, { useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  commonApiCall,
  getJwt,
  UNDERWRITING_DETAILS,
} from "../../../../redux/actions";
import {
  AssurityBaseBoolean,
  showErrorToast,
  UnderWritingRoutes,
} from "../../../../utils";
import { API_URL_UNDERWRITING } from "../../../../config/configs";
import { setAssurityProgressCount } from "../../../../redux/actions";

const ProposedInsured = ({
  baseQuestion = null,
  commonApiCall,
  underwriting,
  setAssurityProgressCount,
  assurityCount,
  ...props
}) => {
  const [baseAnswer, setBaseAnswer] = useState(null);
  const [reflexiveQuestionList, setReflexiveQuestionList] = useState(null);
  const [reflexiveArr, setReflexiveArr] = useState([]);
  const [loading, setLoading] = useState(false);
  const [questionLoading, setQuestionLoading] = useState(false);
  const [errors, setError] = useState({});

  const saveUnderwriteDetails = async () => {
    try {
      const request = {
        applicationId: underwriting?.applicationId,
        applicationType: "Web",
        nextStep: "24,ASSURITY_UNDERWRITING,GENERAL_SECTION_1",
      };

      const isAuthorized = getJwt() ? true : false;
      const apiEndPoint = API_URL_UNDERWRITING + "saveUnderWriting";
      const response = await commonApiCall(
        apiEndPoint,
        "post",
        request,
        UNDERWRITING_DETAILS,
        isAuthorized
      );

      if (response.status.code === 200) {
        props.history.push(`${UnderWritingRoutes.ASSURITY_UNDERWRITING}?underwriting=GENERAL_SECTION_1`);
        /* window.location.href =
          UnderWritingRoutes.ASSURITY_UNDERWRITING +
          `?underwriting=GENERAL_SECTION_1`; */
      }
    } catch (err) {
      showErrorToast(err);
    }
  };

  const updateReflexiveQuestion = async (question) => {
    setLoading(true);
    try {
      const request = {
        baseQuestionId: question.BaseQuestionId,
        reflexiveQuestionAnswers: reflexiveArr,
      };
      const isAuthorized = getJwt() ? true : false;
      const apiEndPoint =
        API_URL_UNDERWRITING +
        "updateReflexiveQuestions?applicationId=" +
        underwriting?.applicationId +
        "&disclosureId=" +
        baseQuestion[0].BaseQuestionDisclosures[0].DisclosureId;
      const response = await commonApiCall(
        apiEndPoint,
        "put",
        request,
        null,
        isAuthorized
      );
      if (response.status.code === 200) {
        saveUnderwriteDetails();
      }
    } catch (err) {
      console.log(err);
      showErrorToast(err);
    }
    setLoading(false);
  };

  const getReflexiveQuestions = async (item) => {
    setQuestionLoading(true);
    try {
      const apiEndPoint =
        API_URL_UNDERWRITING +
        "assurityReflexiveQuestions?applicationId=" +
        underwriting?.applicationId +
        "&disclosureId=" +
        item;

      const response = await commonApiCall(apiEndPoint, "get", {}, "");

      if (response.status.code === 200) {
        // setOptionList(re)
        const list = JSON.parse(response.data);
        console.log(list);
        setReflexiveQuestionList(list.DecisionTree.ReflexiveQuestion);
      }
    } catch (err) {
      showErrorToast(err);
    }
    setQuestionLoading(false);
  };

  const getQuestionFromBranch = (reflex, value) => {
    if (reflex.ReflexiveQuestionType === "Text") {
      if (reflex.Branches.length > 1) {
        const currentBranch =
          reflex.Branches && value
            ? reflex.Branches.filter(
              (x) => x.BranchValue.toLowerCase() == value.toLowerCase()
            )
            : null;
        return currentBranch && currentBranch.length > 0
          ? currentBranch[0]
          : null;
      } else {
        return reflex.Branches.length > 0 && value !== "" && value
          ? reflex.Branches[0]
          : null;
      }
    } else if (reflex.ReflexiveQuestionType === "Numeric") {
      let currentBranch = null;
      if (reflex.Branches && value && reflex.Branches.length > 0) {
        for (let i = 0; i < reflex.Branches.length; i++) {
          if (parseInt(value) >= parseInt(reflex.Branches[i].BranchValue)) {
            currentBranch = reflex.Branches[i];
          } else {
            break;
          }
        }
      }
      return currentBranch ? currentBranch : null;
    } else {
      const currentBranch =
        reflex.Branches && value
          ? reflex.Branches.filter((x) => x.BranchValue == value)
          : null;

      return currentBranch && currentBranch.length > 0
        ? currentBranch[0]
        : null;
    }
  };

  const renderReflexiveQuestion = (reflex) => {
    const onValueChange = (id, e) => {
      let tempReflexiveArr = [...reflexiveArr];
      let reflexIndex = reflexiveArr.findIndex(
        (x) => x.reflexiveQuestionId === id.ReflexiveQuestionId
      );
      if (reflexIndex > -1) {
        tempReflexiveArr[reflexIndex].reflexiveQuestionAnswerValue =
          e.target.value;
        tempReflexiveArr.splice(reflexIndex + 1);
      } else {
        const obj = {
          reflexiveQuestionId: id.ReflexiveQuestionId,
          reflexiveQuestionAnswerValue: e.target.value,
        };
        tempReflexiveArr.push(obj);
      }
      setReflexiveArr(tempReflexiveArr);
    };
    const getCurrentSelectedValue = (item) => {
      const filterReflex = reflexiveArr.filter(
        (x) => x.reflexiveQuestionId == item.ReflexiveQuestionId
      );
      if (filterReflex.length > 0) {
        return filterReflex[0].reflexiveQuestionAnswerValue;
      }
      return "";
    };

    return (
      <div
        className={
          "sbli-child-question mt-4 question_" + reflex.ReflexiveQuestionId
        }
      >
        <h4 className="mb-4 parent_question_head">
          {reflex.ReflexiveQuestionText}
        </h4>
        {(reflex.ReflexiveQuestionType === "Boolean" ||
          reflex.ReflexiveQuestionType === "List") && (
            <span className="custom-radio mt-3 d-block">
              {reflex.Branches.map((branch, index) => {
                return (
                  <React.Fragment>
                    <label
                      className="m-0 mb-3"
                      htmlFor={reflex.ReflexiveQuestionId + "ab" + index}
                    >
                      <input
                        type="radio"
                        name={reflex.ReflexiveQuestionId}
                        id={reflex.ReflexiveQuestionId + "ab" + index}
                        value={branch.BranchValue}
                        checked={
                          getCurrentSelectedValue(reflex) === branch.BranchValue
                        }
                        onChange={(e) => onValueChange(reflex, e)}
                      />
                      <span></span> {branch.BranchText}
                    </label>

                  </React.Fragment>
                );
              })}
              {getQuestionFromBranch(reflex, getCurrentSelectedValue(reflex)) && getQuestionFromBranch(reflex, getCurrentSelectedValue(reflex))?.ReflexiveQuestion
                ? renderReflexiveQuestion(
                  getQuestionFromBranch(reflex, getCurrentSelectedValue(reflex))?.ReflexiveQuestion
                )
                : null}
            </span>
          )}
        {  (reflex.ReflexiveQuestionType === "Text") && (
            <React.Fragment>
              <div className="form-group ">
                <input
                  className="form-control form-control-search"
                  onChange={(e) =>
                    onValueChange(
                      reflex,
                      e
                    )
                  }
                  type="text"
                  value={getCurrentSelectedValue(reflex)}
                  placeholder="Enter Text"
                />
              </div>
              {getQuestionFromBranch(reflex, getCurrentSelectedValue(reflex)) &&
                getQuestionFromBranch(reflex, getCurrentSelectedValue(reflex))
                  ?.ReflexiveQuestion
                ? renderReflexiveQuestion(
                  getQuestionFromBranch(reflex, getCurrentSelectedValue(reflex))
                    ?.ReflexiveQuestion,
                )
                : null}


            </React.Fragment>
          )}
            {  (reflex.ReflexiveQuestionType === "Numeric") && (
            <React.Fragment>
              <div className="form-group ">
                <input
                  className="form-control form-control-search"
                  onChange={(e) =>
                    onValueChange(
                      reflex,
                      e
                    )
                  }
                  type="number"
                  value={getCurrentSelectedValue(reflex)}
                  placeholder="Enter numeric data"
                />
              </div>
              {getQuestionFromBranch(reflex, getCurrentSelectedValue(reflex)) &&
                getQuestionFromBranch(reflex, getCurrentSelectedValue(reflex))
                  ?.ReflexiveQuestion
                ? renderReflexiveQuestion(
                  getQuestionFromBranch(reflex, getCurrentSelectedValue(reflex))
                    ?.ReflexiveQuestion,
                )
                : null}


            </React.Fragment>
          )}
      </div>
    );
  };

  const baseBooleanTrigger = (question, value) => {
    if (question.BaseQuestionDisclosures[0].TriggerOn === value) {
      getReflexiveQuestions(question.BaseQuestionDisclosures[0].DisclosureId);
    } else {
      setReflexiveQuestionList(null);
      setReflexiveArr([]);
    }
  };

  const updateBaseQuestion = async (obj) => {
    try {
      const request = {
        baseQuestionAnswers: [
          {
            baseQuestionId: obj.BaseQuestionId,
            baseQuestionAnswerValue: obj.value,
          },
        ],
      };

      const isAuthorized = getJwt() ? true : false;
      const apiEndPoint =
        API_URL_UNDERWRITING +
        "updatebaseQuestions?applicationId=" +
        underwriting?.applicationId;

      const response = await commonApiCall(
        apiEndPoint,
        "put",
        request,
        null,
        isAuthorized
      );
      if (response.status.code !== 200) {
        showErrorToast(response.status.message);
      }
    } catch (err) {
      showErrorToast(err);
    }
  };

  const validateForm = () => {
    let error = { ...errors };
    if (baseAnswer) {
      delete error.baseAnswer;
      const reflexiveList = document.getElementsByClassName(
        "sbli-child-question"
      );

      for (let index = 0; index < reflexiveList.length; index++) {
        const element = reflexiveList[index];

        const questionId = element.classList[2].split("question_")[1];

        const answerIndex =
          reflexiveArr.findIndex((x) => x.reflexiveQuestionId == questionId) <
          0;
        if (answerIndex) {
          element.classList.add("validate-question-child");
          error["question_" + questionId] = "required";
        } else {
          element.classList.remove("validate-question-child");
          delete error["question_" + questionId];
        }
      }
    } else {
      error["baseAnswer"] = "this field is required";
    }
    setError(error);
    return Object.keys(error).length === 0;
  };

  const onSubmitForm = (e) => {
    try {
      setAssurityProgressCount({
        ...assurityCount,
        underWritingProgress:
          assurityCount.underWritingProgress < 2
            ? 2
            : assurityCount.underWritingProgress,
      });

      if (validateForm()) {
        if (reflexiveQuestionList) {
          reflexiveArr.length > 0 && updateReflexiveQuestion(e);
        } else {
          saveUnderwriteDetails();
        }
      }
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <div className="underwriting-detail sbli-question-container pb-5">
      {baseQuestion &&
        baseQuestion.map((question) => {
          return (
            <div
              className={
                "sbli-parent-question question_ mb-5" +
                (errors?.baseAnswer ? " validate-question-parent" : "")
              }
              id="sbli_question_container"
              key={question.BaseQuestionId}
            >
              <h4 className="mb-4 parent_question_head">
                {question.BaseQuestionText}
              </h4>

              {question.BaseQuestionType === "Trigger_On_Yes" && (
                <AssurityBaseBoolean
                  question={question}
                  value={
                    baseAnswer ? (baseAnswer === "true" ? "Yes" : "No") : ""
                  }
                  onValueChange={(question, value) => {
                    updateBaseQuestion({
                      BaseQuestionId: question.BaseQuestionId,
                      value:
                        value.target.value.toLowerCase() === "yes"
                          ? "true"
                          : "false",
                    });
                    setBaseAnswer(
                      value.target.value.toLowerCase() === "yes"
                        ? "true"
                        : "false"
                    );
                    baseBooleanTrigger(question, value.target.value);
                  }}
                />
              )}
              {reflexiveQuestionList &&
                renderReflexiveQuestion(reflexiveQuestionList)}
              {questionLoading && <p className="mt-3">Please wait...</p>}
            </div>
          );
        })}
      <div className="w-100 my-4 pb-5 text-center">
        <button
          className="btn btn-default px-5"
          disabled={loading}
          onClick={() => {
            onSubmitForm(baseQuestion[0]);
          }}
        >
          {loading ? "Processing..." : "Next"}
        </button>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  underwriting: state.underwriting.underwriting,
  assurityCount: state.underwriting.assuProgressCount,
});
export default connect(mapStateToProps, {
  commonApiCall,
  setAssurityProgressCount,
})(withRouter(ProposedInsured));
