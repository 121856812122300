import React, { useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  commonApiCall,
  getJwt,
  UNDERWRITING_DETAILS,
} from "../../redux/actions";
import { ApprovalStatus, setProcessStep, showErrorToast } from "../../utils";
import history from "../../utils/history";
import { API_URL_UNDERWRITING } from "../../config/configs";

const SBLICongratulations = ({ underwriting, commonApiCall, ...props }) => {
  const { applicationId, applicationType, goalType, policyType } = underwriting;
  useEffect(() => {
    saveUnderwriting();
  }, []);

  const saveUnderwriting = async () => {
    try {
      const path = window.location.pathname.split("/")[1];
      const request = {
        applicationId: applicationId,
        applicationType: applicationType,
        goalType: goalType,
        resumeProcess: setProcessStep(path, policyType, goalType, "online"),
      };

      const isAuthorized = getJwt() ? true : false;
      const apiEndPoint = API_URL_UNDERWRITING + "saveUnderWriting";
      const response = await commonApiCall(
        apiEndPoint,
        "post",
        request,
        UNDERWRITING_DETAILS,
        isAuthorized
      );
      if (response.status.code === 200) {
      } else {
        props.history.push(ApprovalStatus.APPLICATION_STATUS);
        showErrorToast(response.status.message);
      }
    } catch (err) {
      showErrorToast("Something went wrong");
    }
  };
  return (
    <div className="text-center">
      <h1 className="page-title txt-black">Congratulations!</h1>
      <img
        src="/assets/images/trophy.png"
        className="img-fluid my-2"
        alt="trophy"
        title="Trophy"
        width="450"
      />
      <h3 className="text-black">Policy No: {underwriting?.policyNumber}</h3>
      <p className="text-black py-4">
        You're taken a significant step to protect your family's future. You
        will
        <br className="d-md-block d-none" /> receive an email shortly from SBLI
        with your policy details.
      </p>
      <button
        className="btn btn-green-outline transprant"
        onClick={() => history.push("/dashboard")}
      >
        Go to my account
      </button>
    </div>
  );
};
const mapStateToProps = (state) => ({
  underwriting: state.underwriting.underwriting,
  company_information: state.underwriting.companyInformation,
  direct_link: state.underwriting.direct_link,
});

export default connect(mapStateToProps, { commonApiCall })(
  withRouter(SBLICongratulations)
);
