import React, { useState, useEffect } from "react";
import { Animated } from "react-animated-css";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { commonApiCall } from "../../redux/actions/index";
import { API_URL_UNDERWRITING } from "../../config/configs";
import NavBar from "../../components/navBar";
import { showErrorToast } from "../../utils/index";
import { UnderProcessLoader } from "../../components/skeletonLoaders/index";
import UnderProcessListing from "../../components/subComponents/underProcessListing";

const UnderProcess = ({ commonApiCall, t }) => {
  const [processList, setProcessList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function doSubmit() {
      try {
        const authorization = true;
        let response = await commonApiCall(
          API_URL_UNDERWRITING + `clientApplications?status=INPROGRESS`,
          "get",
          "",
          "",
          authorization
        );
        if (response && response.status.code === 200) {
          setProcessList(response.data.underProcess);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      } catch (err) {
        showErrorToast(t("underprocess.something_went_wrong"));
      }
    }
    doSubmit();
  }, []);
  return (
    <React.Fragment>
      <div className="row">
        <div className="col-3 sidebar-part">
          <NavBar t={t}></NavBar>
        </div>
        <div className="col-lg-9">
          <Animated
            animationIn="fadeIn"
            animationOut="faderOutLeft"
            animationInDuration={1200}
            animationOutDuration={800}
            isVisible={true}
          >
            <div className="white-box2">
              <div className="box-title">
                <h3 className="font-24">{t("underprocess.processing")}</h3>
              </div>
              <div className="under-process">
                {isLoading ? (
                  <UnderProcessLoader />
                ) : (
                  <UnderProcessListing
                    processList={processList}
                    from="UnderProcess"
                    t={t}
                  />
                )}
              </div>
            </div>
          </Animated>
        </div>
      </div>
    </React.Fragment>
  );
};

export default connect(null, { commonApiCall })(withRouter(UnderProcess));
