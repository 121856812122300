import React, { useEffect, useState } from "react";
import {
  useStripe,
  useElements,
  CardElement,
  CardCvcElement,
  CardNumberElement,
  CardExpiryElement,
} from "@stripe/react-stripe-js";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { encryptme, showErrorToast, UnderWritingRoutes } from "../../../utils";
import {
  commonApiCall,
  getJwt,
  setAssurityProgressCount,
  UNDERWRITING_DETAILS,
} from "../../../redux/actions";
import { API_URL_UNDERWRITING } from "../../../config/configs";

// import { assurityDoc } from "./assurity-doc";

// const stripeTokenHandler = () => async (token) => {
//   console.log("ENTERED", token);

//   console.log("called", paymentData);
//   try {
//     // Use fetch to send the token ID and any other payment data to your server.
//     // https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch
//   } catch (err) {
//     console.log(err);
//   }
// };

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      lineHeight: "27px",
      color: "#212529",
      fontSize: "1.1rem",
      border: "1px solid #ccc",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

const CheckoutForm = ({
  underwriting,
  commonApiCall,
  assurityCount,
  setAssurityProgressCount,
  ...props
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
  });

  const handleChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make  sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const card = elements.getElement(CardNumberElement);
    const result = await stripe.createToken(card);

    if (result.error) {
      // Show error to your customer.
    } else {
      localStorage.setItem("pay_token", encryptme(result.token.id));
      let localData = JSON.parse(localStorage.getItem("persist::ass::bene"));
      localData.payment = {
        automaticCreditCard: {
          paymentFrequency: underwriting?.frequency,
          creditCardType: result.token.card.brand,
          // collectedOnDelivery: false,
          // isInitialPaymentSentViaPostalService: false,
          draftIncludesInitialPayment: true,

          //dayOfCharge: "",
        },
      };
      setAssurityProgressCount({
        ...assurityCount,
        paymentProgress: 100,
      });
      localStorage.setItem("persist::ass::bene", JSON.stringify(localData));

      saveUnderwriteDetails();
      //   // Send the token to your server.
      //   // This function does not exist yet; we will define it in the next step.
      //   const paymentData = { token: result.token.id };
      //   const response = await fetch("http://localhost:3000/charge", {
      //     method: "POST",
      //     headers: {
      //       "Content-Type": "application/json",
      //     },
      //     body: JSON.stringify(paymentData),
      //   });
      //   // Return and display the result of the charge.
      //   return response.json();
    }
  };

  const saveUnderwriteDetails = async () => {
    try {
      const request = {
        applicationId: underwriting?.applicationId,
        applicationType: "Web",
        nextStep: "24,ASSURITY_NOTICE",
      };

      const isAuthorized = getJwt() ? true : false;
      const apiEndPoint = API_URL_UNDERWRITING + "saveUnderWriting";
      const response = await commonApiCall(
        apiEndPoint,
        "post",
        request,
        UNDERWRITING_DETAILS,
        isAuthorized
      );

      if (response.status.code === 200) {
        props.history.push(UnderWritingRoutes.ASSURITY_NOTICE);
      }
    } catch (err) {
      showErrorToast(err);
    }
  };

  return (
    <div className="sbli-main-question mt-5 ">
      <h3 className="text-center font-weight-bold">Payment</h3>
      <p className="text-dark text-center">
        You will not be charged until your policy is issued.
      </p>
      <form className="pt-4" onSubmit={handleSubmit}>
        <div className="container-fluid">
          <div className="row">
            {/* <div className="col-md-6">
              <h4 className="mb-3 text-dark font-weight-bold">First Name</h4>
              <div className="form-group">
                <div className="input-effect">
                  <input
                    className="form-control"
                    placeholder="First Name"
                    name="first_name"
                    id="first_name"
                    type="text"
                    value={formData.first_name}
                    onChange={handleChange}
                  />
                  <label htmlFor="first_name">{"First Name"}</label>
                  <em className="input-icon"></em>
                  <span className="text-danger">{errors?.first_name}</span>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <h4 className="mb-3 text-dark font-weight-bold">Last Name</h4>
              <div className="form-group">
                <div className="input-effect">
                  <input
                    className="form-control"
                    placeholder="Last Name"
                    name="last_name"
                    id="last_name"
                    type="text"
                    value={formData.last_name}
                    onChange={handleChange}
                  />
                  <label htmlFor="last_name">{"Last Name"}</label>
                  <em className="input-icon"></em>
                  <span className="text-danger">{errors?.first_name}</span>
                </div>
              </div>
            </div> */}
            <div className="col-md-6">
              <h4 className="mb-3 text-dark font-weight-bold">
                Credit Card Number{" "}
                <img style={{ width: 15 }} src="assets/images/card-lock.png" />
              </h4>
              <div className="form-group">
                <CardNumberElement
                  id="cc-number"
                  className="form-control w-100"
                  options={CARD_ELEMENT_OPTIONS}
                />
              </div>
            </div>
            <div className="col-md-6">
              <h4 className="mb-3 text-dark font-weight-bold">CVV</h4>
              <div className="form-group">
                <CardCvcElement
                  id="cvc"
                  className="form-control w-25"
                  options={CARD_ELEMENT_OPTIONS}
                />
              </div>
            </div>
            <div className="col-md-6">
              <h4 className="mb-3 text-dark font-weight-bold">
                Expiration Date
              </h4>
              <div className="form-group">
                <CardExpiryElement
                  id="expiry"
                  className="form-control w-100"
                  options={CARD_ELEMENT_OPTIONS}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="card-images">
                <img src="assets/images/card-images.png" />
              </div>
            </div>
          </div>
        </div>
        <div className="w-100 mb-4 mt-2 text-center">
          <button className="btn btn-default px-5" disabled={!stripe}>
            Next
          </button>
        </div>
      </form>
      {/* <div className="w-100 text-center">
        <button className="btn btn-link px-5">Back</button>
      </div> */}
    </div>
  );
};

const mapStateToProps = (state) => ({
  underwriting: state.underwriting.underwriting,
  consumer: state.consumer?.consumerdetails,
  applicationCarrierInfo: state.underwriting.applicationCarrierInfo,
  assurityCount: state.underwriting.assuProgressCount,
});

export default connect(mapStateToProps, {
  commonApiCall,
  setAssurityProgressCount,
})(withRouter(CheckoutForm));
