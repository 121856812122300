import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { commonApiCall, getJwt } from "../../../redux/actions";
import { showErrorToast, getState } from "../../../utils";
import Select from "react-select";
import { API_URL_CLIENT } from "../../../config/configs";
import { getAssurityCountry, getReasonList } from "./userdetail-list";
import NumberFormat from "react-number-format";

const AssurityPhysicianRequest = ({
  commonApiCall,
  formData,

  handleChange,
}) => {
  const [isLoading, setLoading] = useState(false);
  const [countryList, setCountryList] = useState([]);
  const [stateDropdown, setStateDropDown] = useState(null);
  // const saveNext = async () => {
  //   setLoading(false);
  //   try {
  //     if (validateField()) {
  //     }
  //   } catch (err) {
  //     showErrorToast(err);
  //   }
  //   setLoading(false);
  // };

  const getCountryList = async () => {
    try {
      const isAuthorized = getJwt() ? true : false;
      let response = await commonApiCall(
        API_URL_CLIENT + `getCountry`,
        "get",
        "",
        "",
        isAuthorized
      );

      if (response.status.code === 200) {
        let getCountry = response.data.map((list) => {
          return {
            value: list.code,
            label: list.name,
          };
        });
        setCountryList({
          ...countryList,
          getCountry,
        });
      }
    } catch (err) {
      showErrorToast(err);
    }
  };

  const handleState = async (value) => {
    try {
      if (value?.value !== "USA" && value?.value !== "CANADA") {
        setStateDropDown(null);
      }
      let response = await commonApiCall(
        API_URL_CLIENT +
        "getState?countryId=" +
        (value?.value === "USA" ? 1 : value?.value === "CANADA" ? 4 : null),
        "",
        "get"
      );

      if (response && response !== "" && response.status.code === 200) {
        var new_options = [];
        response.data &&
          response.data.length > 0 &&
          response.data.map((element) => {
            new_options.push({
              value: element.code,
              label: element.name,
            });
            return true;
          });
        setStateDropDown(new_options);
      } else if (response && response !== "" && response.status.code === 404) {
        setStateDropDown(null);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getCountryList();
  }, []);

  const { errors } = formData;

  return (
    <>
      <div className="sbli-main-question">
        <div className="container-fluid my-5">
          <form>
            <div className="row justify-content-center">
              <div className="col-md-8">
                <div
                  className={
                    "sbli-parent-question mb-0 " +
                    (errors.hasOwnProperty("healthQuestion")
                      ? "validate-question-parent"
                      : "")
                  }
                  id="sbli_question_container"
                >
                  <h4 className="mb-4 parent_question_head">
                    Do you have a primary care physician or medical centre?
                  </h4>{" "}
                  <span className="custom-radio mt-3 d-block">
                    <label className="m-0 mb-3" htmlFor={"ab"}>
                      <input
                        type="radio"
                        name={"healthQuestion"}
                        id={"ab"}
                        onChange={handleChange}
                        value={"yes"}
                        checked={formData.healthQuestion === "yes"}
                      />
                      <span></span> Yes
                    </label>

                    <label className="m-0" htmlFor={"ba"}>
                      <input
                        type="radio"
                        name={"healthQuestion"}
                        id={"ba"}
                        onChange={handleChange}
                        value={"no"}
                        checked={formData.healthQuestion === "no"}
                      />
                      <span></span> No
                    </label>
                  </span>
                </div>

                {formData.healthQuestion === "yes" && (
                  <>
                    {" "}
                    <h4 className="text-medium mt-5">
                      Please provide information about your attending physician
                    </h4>
                    <div className="row">
                      <div className="col-12">
                        <div className="form-group">
                          <div className="input-effect">
                            <input
                              className="form-control"
                              placeholder="First Name"
                              name="first_name"
                              id="first_name"
                              type="text"
                              value={formData.first_name}
                              onChange={handleChange}
                            />
                            <label htmlFor="first_name">{"First Name"}<sup className="text-danger">*</sup></label>
                            <em className="input-icon"></em>
                            <span className="text-danger">
                              {errors?.first_name}
                            </span>
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="input-effect">
                            <input
                              className="form-control"
                              placeholder="Last Name"
                              name="last_name"
                              id="last_name"
                              type="text"
                              value={formData.last_name}
                              onChange={handleChange}
                            />
                            <label htmlFor="last_name">{"Last Name"}<sup className="text-danger">*</sup></label>
                            <em className="input-icon"></em>
                            <span className="text-danger">
                              {errors?.last_name}
                            </span>
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="input-effect">
                            <input
                              className="form-control"
                              placeholder="Address"
                              name="address"
                              id="address"
                              type="text"
                              value={formData.address}
                              onChange={handleChange}
                            />
                            <label htmlFor="address">{"Address"}<sup className="text-danger">*</sup></label>
                            <em className="input-icon"></em>
                            <span className="text-danger">
                              {errors?.address}
                            </span>
                          </div>
                        </div>

                        <div className="form-group fixed-floating-label mb-4">
                          <div className="default-select with-border">
                            <Select
                              className={"custom-select-menu"}
                              classNamePrefix={"custom-select"}
                              options={getAssurityCountry()}
                              onChange={(value) => {
                                handleChange({
                                  target: {
                                    name: "country",
                                    value: value,
                                  },
                                });
                                handleState(value);
                              }}
                            // value={countryList.getCountry[0]}
                            />

                            <span className="fixed-label">Country<sup className="text-danger">*</sup></span>
                            <span className="text-danger ">
                              {errors?.country}
                            </span>
                          </div>
                        </div>

                        <div className="form-group">
                          <div className="input-effect">
                            <input
                              className="form-control"
                              placeholder="City"
                              name="city"
                              id="city"
                              type="text"
                              value={formData.city}
                              onChange={handleChange}
                            />
                            <label htmlFor="city">{"City"}<sup className="text-danger">*</sup></label>
                            <em className="input-icon"></em>
                            <span className="text-danger ">{errors?.city}</span>
                          </div>
                        </div>
                      </div>
                      <div className={stateDropdown ? "col-md-5" : "col-md-12"}>
                        <div className="form-group">
                          <div className="input-effect">
                            <input
                              className="form-control"
                              placeholder="Zip Code"
                              name="zipCode"
                              id="zipCode"
                              type="text"
                              minLength={5}
                              maxLength={10}
                              pattern={"[A-Za-z0-9]{5,10}"}
                              value={formData.zipCode}
                              onChange={handleChange}
                            />
                            <label htmlFor="zipCode">{"Zip Code"}<sup className="text-danger">*</sup></label>
                            <em className="input-icon"></em>
                            <span className="text-danger">
                              {errors?.zipCode}
                            </span>
                          </div>
                        </div>
                      </div>
                      {stateDropdown && (
                        <div className="col-md-7">
                          <div className="form-group fixed-floating-label mb-4">
                            <div className="default-select with-border">
                              <Select
                                className={"custom-select-menu"}
                                classNamePrefix={"custom-select"}
                                options={stateDropdown}
                                onChange={(value) => {
                                  handleChange({
                                    target: {
                                      name: "state",
                                      value: value,
                                    },
                                  });
                                }}
                              // value={countryList.getCountry[0]}
                              />

                              <span className="fixed-label">State<sup className="text-danger">*</sup></span>
                              <span className="text-danger ">
                                {errors?.state}
                              </span>
                            </div>
                          </div>

                          {/* <div className="form-group">
                          <div className="input-effect">
                            <input
                              className="form-control"
                              placeholder="State"
                              name="speciality"
                              id="Speciality"
                              type="text"
                              disabled
                              value={getState(formData?.zipCode)?.state}
                              // onChange={handleChange}
                            />
                            <label htmlFor="State">{"State"}</label>
                            <em className="input-icon"></em>
                            <span className="text-danger">{errors?.state}</span>
                          </div>
                        </div> */}
                        </div>
                      )}

                      <div className="col-12">
                        <div className="form-group">
                          <div className="input-effect">
                            <input
                              className="form-control"
                              placeholder="Email"
                              name="email"
                              id="email"
                              type="email"
                              value={formData.email}
                              onChange={handleChange}
                            />
                            <label htmlFor="email">{"Email"}</label>
                            <em className="input-icon"></em>
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="input-effect">
                            <NumberFormat
                              name="mobile"
                              id="mobile"
                              className="form-control"
                              placeholder="Enter Your Mobile Phone Number"
                              format="(###) ###-####"
                              value={formData.mobile}
                              onChange={handleChange}

                            />
                            <label htmlFor="mobile">{"Phone"}<sup className="text-danger">*</sup></label>
                            <em className="input-icon"></em>
                            <span className="text-danger">
                              {errors?.mobile}
                            </span>
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="input-effect">
                            <input
                              className="form-control"
                              placeholder="Speciality"
                              name="speciality"
                              id="Speciality"
                              type="text"
                              value={formData.speciality}
                              onChange={handleChange}
                            />
                            <label htmlFor="Speciality">{"Speciality"}</label>
                            <em className="input-icon"></em>
                          </div>
                        </div>

                        <h4 className="text-medium">Date of last visit<sup className="text-danger">*</sup></h4>
                        <div className="col-md-14">
                          <div className="form-group custom-datepicker">
                            <DatePicker
                              selected={formData.lastVisitDate}
                              maxDate={new Date()}
                              placeholderText="MM/DD/YYYY"
                              peekNextMonth
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                              dateFormatCalendar="MMMM"
                              value={formData.lastVisitDate}
                              onChange={(date) =>
                                handleChange({
                                  target: {
                                    name: "lastVisitDate",
                                    value: date,
                                  },
                                })
                              }
                            />
                            <span className="text-danger">
                              {errors?.lastVisitDate}
                            </span>
                          </div>
                        </div>
                        <h4 className="text-medium">Reason of last visit<sup className="text-danger">*</sup></h4>
                        <div className="form-group fixed-floating-label mb-4">
                          <div className="default-select with-border">
                            <Select
                              className={"custom-select-menu"}
                              classNamePrefix={"custom-select"}
                              options={getReasonList()}
                              onChange={(value) => {
                                handleChange({
                                  target: {
                                    name: "lastVisitReason",
                                    value: value,
                                  },
                                });
                              }}
                            // value={countryList.getCountry[0]}
                            />

                            {/* <span className="fixed-label">Reason</span> */}
                            <span className="text-danger ">
                              {errors?.lastVisitReason}
                            </span>
                          </div>
                        </div>
                        {formData?.lastVisitReason !== "" &&
                          formData?.lastVisitReason?.value === "Other" && (
                            <div className="form-group">
                              <div className="input-effect">
                                <input
                                  className="form-control"
                                  placeholder="Please specify"
                                  name="lastOtherVisitReason"
                                  id="lastOtherVisitReason"
                                  type="text"
                                  value={formData.lastOtherVisitReason}
                                  onChange={handleChange}
                                />
                                <label htmlFor="lastOtherVisitReason">
                                  {"Please specify"}
                                </label>
                                <em className="input-icon"></em>
                                <span className="text-danger">
                                  {errors?.lastOtherVisitReason}
                                </span>
                              </div>
                            </div>
                          )}

                        <h4 className="text-medium">
                          Reason of last visit (Description){formData?.lastVisitReason?.value === "Other" ? <sup className="text-danger">*</sup> : null}
                        </h4>
                        <div className="form-group">
                          <div className="input-effect">
                            <input
                              className="form-control"
                              placeholder=""
                              name="lastVisitReasonDescription"
                              id="lastVisitReasonDescription"
                              type="text"
                              value={formData.lastVisitReasonDescription}
                              onChange={handleChange}
                            />
                            <label htmlFor="lastVisitReasonDescription">
                              {"Reason of last visit"}
                            </label>
                            <em className="input-icon"></em>
                            <span className="text-danger">
                              {errors?.lastVisitReasonDescription}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
            {/* <div className="w-100 mb-4 mt-2 mb-5 text-center">
              <button
                className="btn btn-default py-3 px-5 mb-5"
                type="button"
                onClick={saveNext}
                disabled={isLoading}
              >
                {isLoading ? "Processing..." : "Next"}
              </button>
            </div> */}
          </form>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  underwriting: state.underwriting.underwriting,
});
export default connect(mapStateToProps, {
  commonApiCall,
})(withRouter(AssurityPhysicianRequest));
