import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { DashboardRoute, decryptme } from "../utils";
import { isEmpty } from "lodash";

const NavBar = ({ profile_picture, consumer, t }) => {
  const [formData, setFormData] = useState({
    profilePic: "",
    name: "",
    email: "",
  });

  useEffect(() => {
    setFormData({
      ...formData,
      profilePic: !isEmpty(profile_picture)
        ? profile_picture?.profilePic
        : !isEmpty(consumer)
        ? consumer?.profilePic
        : "",
      name: !isEmpty(profile_picture)
        ? profile_picture?.firstName
        : !isEmpty(consumer)
        ? decryptme(consumer?.firstName)
        : "",
      email: !isEmpty(profile_picture)
        ? profile_picture?.email
        : !isEmpty(consumer)
        ? decryptme(consumer?.email)
        : "",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile_picture, consumer]);

  const errorImageReplace = (id) => {
    document.getElementById(id).src = "/assets/images/user-dp-demo.jpg";
  };
  const closeSidebar = () => {
    document.body.classList.remove("sidebar-open");
  };

  let { profilePic, email, name } = formData;
  return (
    <div className="sidebar">
      <div className="sidebar-profile text-center white-box2">
        <div className="sidebar-profile-img">
          <img
            src={profilePic ? profilePic : "/assets/images/user-dp-demo.jpg"}
            id="nav-img-user"
            alt=""
            title="profile"
            onError={(e) => errorImageReplace("nav-img-user")}
          />
        </div>
        <h3>
          {t("side_bar.hello")} {name}!
        </h3>
        <h5>{email}</h5>
      </div>
      <div className="white-box2 sidebar-links-outer">
        <ul>
          <li className="sidebar-link">
            <NavLink to={DashboardRoute.DASHBOARD} onClick={closeSidebar}>
              <span className="material-icons">dashboard</span>
              {t("side_bar.dashboard")}
            </NavLink>
          </li>
          <li className="sidebar-link">
            <NavLink to={DashboardRoute.UNDERPROCESS} onClick={closeSidebar}>
              <span className="material-icons">autorenew</span>
              {t("side_bar.processing")}
            </NavLink>
          </li>

          {/* <li className="sidebar-link">
            <NavLink to={DashboardRoute.CONVERSATIONS} onClick={closeSidebar}>
              <span className="material-icons">question_answer</span>
              {t("side_bar.conversations")}
            </NavLink>
          </li> */}
          <li className="sidebar-link">
            <NavLink to={DashboardRoute.NOTIFICATIONS} onClick={closeSidebar}>
              <span className="material-icons">notifications</span>
              {t("side_bar.notifications")}
            </NavLink>
          </li>
          <li className="sidebar-link">
            <NavLink to={DashboardRoute.MYPROFILE} onClick={closeSidebar}>
              <span className="material-icons">account_circle</span>
              {t("side_bar.my_profile")}
            </NavLink>
          </li>
        </ul>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  profile_picture: state.underwriting.profile_pic,
  consumer: state.consumer.consumerdetails,
});
export default connect(mapStateToProps, null)(NavBar);
