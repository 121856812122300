import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import {} from "react-bootstrap";
import { Animated } from "react-animated-css";
import { isEmpty } from "lodash";
import queryString from "query-string";
import Select from "react-select";
import { useTranslation, withTranslation, Trans } from "react-i18next";

import {
  commonApiCall,
  UNDERWRITING_DETAILS,
  getJwt,
  clearUnderWriting,
} from "../../../redux/actions/index";
import {
  setProcessStep,
  getProcessStep,
  UnderWritingRoutes,
  encryptme,
  showErrorToast,
  segmentTracking,
} from "../../../utils/index";
import {
  API_URL_UNDERWRITING,
  CONSUMER_PORTAL_URL,
} from "../../../config/configs";
import { validationMessages } from "../../../redux/actions/validationMessages";

const PolicyTerm = ({
  commonApiCall,
  underwriting,
  clearUnderWriting,
  t,
  company_information,
  ...props
}) => {
  const { applicationId, applicationType, goalType, termLength } = underwriting;
  const [formData, setFormData] = useState({
    applicationId: applicationId,
    yourTermLength: null,
    applicationType:
      underwriting && underwriting.applicationType
        ? underwriting.applicationType
        : "Web",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    if (!applicationId || !goalType) {
      window.location.replace(CONSUMER_PORTAL_URL + "404");
    }

    if (underwriting && !isEmpty(underwriting.carrierWiseLeads)) {
      const whereToRoute = getProcessStep(
        underwriting.resumeProcess,
        underwriting.goalType,
        underwriting.policyType,
        underwriting.productSelection
      );
      props.history.push(whereToRoute);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const doSubmit = async () => {
    try {
      const { yourTermLength, applicationId, applicationType } = formData;
      if (yourTermLength) {
        const splitpath = window.location.pathname.split("/");
        const request = {
          applicationId: applicationId,
          goalType: goalType,
          applicationType: applicationType,
          termLength: yourTermLength,
          resumeProcess: setProcessStep(splitpath[1]),
        };
        const isAuthorized = getJwt() ? true : false;
        const apiEndPoint = API_URL_UNDERWRITING + "saveUnderWriting";
        const response = await commonApiCall(
          apiEndPoint,
          "post",
          request,
          UNDERWRITING_DETAILS,
          isAuthorized
        );
        if (response.status.code === 200) {
          props.history.push(UnderWritingRoutes.PLANS);
          const eventObject = {
            agent_id: response?.data?.agentDetail?.agentId,
            application_id: applicationId,
            param_name: "Term",
            param_value: yourTermLength,
          };
          segmentTracking("application_set_param", eventObject);
        } else {
          showErrorToast(response.status.message);
        }
        return;
      }
    } catch (err) {
      showErrorToast(t("validation_message.SOMETHING_WENT_WRONG"));
    }
  };

  useEffect(() => {
    if (formData["yourTermLength"]) doSubmit();
  }, [formData]);

  const { yourTermLength } = formData;
  return (
    <React.Fragment>
      <div className="steps getstart-page years-page">
        <div className="title-header">
          <div>
            <h2 className="page-title">
              {t("policy_term.how_long_would_you_like_your_coverage_to_last")}
            </h2>
            <h4>{t("policy_term.policy_term_sub_title")} </h4>
          </div>
        </div>

        <div className="row justify-content-center mt-md-5">
          <div className="col-lg-3 col-sm-6 yearblock-width">
            <Animated
              animationIn="fadeInRight"
              animationOut="fadeInLeft"
              animationInDuration={1000}
              animationOutDuration={1000}
              isVisible={true}
            >
              <label className="boxmodal-radio">
                <input
                  type="radio"
                  name="yourTermLength"
                  id="yourTermLength"
                  value={10}
                  checked={
                    yourTermLength === 10
                      ? true
                      : termLength === 10
                      ? true
                      : false
                  }
                  onClick={(e) => handleChange(e)}
                  onChange={(e) => handleChange(e)}
                />
                <div className="card-inner">
                  <div className="card-body p-0">
                    {/* <h2 className="page-title">
                      </h2> */}
                    <div className="card-text year-box mb-0">
                      <strong>10</strong>
                      Years
                    </div>
                  </div>
                </div>
              </label>
            </Animated>
          </div>
          <div className="col-lg-3 col-sm-6 yearblock-width">
            <Animated
              animationIn="fadeInRight"
              animationOut="fadeInLeft"
              animationInDuration={1000}
              animationOutDuration={1000}
              isVisible={true}
            >
              <label className="boxmodal-radio">
                <input
                  type="radio"
                  name="yourTermLength"
                  id="yourTermLength"
                  value={15}
                  checked={
                    yourTermLength === 15
                      ? true
                      : termLength === 15
                      ? true
                      : false
                  }
                  onClick={(e) => handleChange(e)}
                  onChange={(e) => handleChange(e)}
                />
                <div className="card-inner">
                  <div className="card-body p-0">
                    {/* <h2 className="page-title">
                      </h2> */}
                    <div className="card-text year-box mb-0">
                      <strong>15</strong>
                      Years
                    </div>
                  </div>
                </div>
              </label>
            </Animated>
          </div>
          <div className="col-lg-3 col-sm-6 yearblock-width">
            <Animated
              animationIn="fadeInRight"
              animationOut="fadeInLeft"
              animationInDuration={1000}
              animationOutDuration={1000}
              isVisible={true}
            >
              <label className="boxmodal-radio">
                <input
                  type="radio"
                  name="yourTermLength"
                  id="yourTermLength"
                  value={20}
                  checked={
                    yourTermLength === 20
                      ? true
                      : termLength === 20
                      ? true
                      : false
                  }
                  onClick={(e) => handleChange(e)}
                  onChange={(e) => handleChange(e)}
                />
                <div className="card-inner">
                  <div className="card-body p-0">
                    {/* <h2 className="page-title">
                      </h2> */}
                    <div className="card-text year-box mb-0">
                      <strong>20</strong>
                      Years
                    </div>
                  </div>
                </div>
              </label>
            </Animated>
          </div>
          <div className="col-lg-3 col-sm-6 yearblock-width">
            <Animated
              animationIn="fadeInRight"
              animationOut="fadeInLeft"
              animationInDuration={1000}
              animationOutDuration={1000}
              isVisible={true}
            >
              <label className="boxmodal-radio">
                <input
                  type="radio"
                  name="yourTermLength"
                  id="yourTermLength"
                  value={30}
                  checked={
                    yourTermLength === 30
                      ? true
                      : termLength === 30
                      ? true
                      : false
                  }
                  onClick={(e) => handleChange(e)}
                  onChange={(e) => handleChange(e)}
                />
                <div className="card-inner">
                  <div className="card-body p-0">
                    {/* <h2 className="page-title">
                      </h2> */}
                    <div className="card-text year-box mb-0">
                      <strong>30</strong>
                      Years
                    </div>
                  </div>
                </div>
              </label>
            </Animated>
          </div>

          <div className="col-12 text-center mt-3">
            <Link
              to={UnderWritingRoutes.YOUR_DEPOSIT}
              className="btn btn-link"
              title="Back"
            >
              {t("back")}
            </Link>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
const mapStateToProps = (state) => ({
  underwriting: state.underwriting.underwriting,
  company_information: state.underwriting.companyInformation,
});
export default connect(mapStateToProps, { commonApiCall, clearUnderWriting })(
  withRouter(PolicyTerm)
);
