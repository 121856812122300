import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";

const AboutUs = ({ t }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <React.Fragment>
      {/* Begin: About Us */}
      <div className="about-page">
        <div className="about-us">
          <div className="container pb-0">
            <div className="row">
              <div className="col-md-6">
                <div className="about-us-wrapper">
                  <div className="front-title-block">
                    <h4 className="subtitle">{t("about_ilife.about_us")}</h4>
                    <h3 className="title">
                      {t("about_ilife.group_of_engineers_mathematicians_and_entrepreneurs")}
                    </h3>
                    <p className="common-para pb-4">
                      {t("about_ilife.we_are_a_group_of_engineers_mathematicians_and_entrepreneurs_who_are_passionate_about_bringing_transparency_into_the_financial_world_because_everybody_deserves_to_be_able_to_make_an_informed_decision_with_the_necessary_information_on_options_available")}

                      <br />
                      {t("about_ilife.we_believe_that_getting_the_best_rates_with_the_highest_guarantees_on_your_savings_is_important_so_we_built_the_technology_to_help_you_do_it_better_and_faster")}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="about-us-img">
                  <img
                    src="/assets/images/about-us-img3.jpg"
                    alt=""
                    title={t("about_ilife.about_us")}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End: About Us */}

        <div className="about-us about-left our-mission">
          <div className="container pb-0">
            <div className="row">
              <div className="col-md-6">
                <div className="about-us-img shadow-left">
                  <img
                    src="/assets/images/about-us-img4.jpg"
                    alt=""
                    title={t("about_ilife.about_us")}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="about-us-wrapper">
                  <div className="about-us-wrapper with-border">
                    <div className="front-title-block">
                      <h3 className="title">{t("about_ilife.our_mission")}</h3>
                      <p className="common-para pb-4">
                        {t("about_ilife.create_what_life_insurance_never_had_data_transparency_for_everyone_because_we_all_deserve_the_opportunity_to_make_well_informed_decisions_conveniently")}
                      </p>
                    </div>
                    <div className="front-title-block">
                      <h3 className="title">{t("about_ilife.our_vision")}</h3>
                      <p className="common-para pb-4">
                        {t("about_ilife.life_insurance_should_be_a_data_driven_method_to_maximize_investment_returns_and_protection_and_this_should_happen_without_traditional_hassles_conflicts_of_interest_&_data_opacity")}

                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Begin: Database */}
        <div className="about-us our-database landing-bottom">
          <div className="container pb-0">
            <div className="row">
              <div className="col-md-6">
                <div className="about-us-wrapper">
                  <div className="about-us-wrapper">
                    <div className="front-title-block">
                      <h4 className="subtitle">{t("about_ilife.our_database")}</h4>
                      <h3 className="title">
                        {t("about_ilife.iLife_accepts_product_listing_only_from_top_rated_carriers_who")}{" "}
                        <span className="text-primary">
                          {t("about_ilife.commit_to_transparency")}
                        </span>
                      </h3>
                      <p className="common-para pb-4">
                        {t("about_ilife.our_database_has_collected_over_2_million_data_points_from_top_carriers_who_have_agreed_to_providing_transparent_product_data_to_us_to_be_independently_analyzed_by_iLife_and_compared_side_by_side_with_others")}
                        <br />
                        {t("about_ilife.we_believe_truly_great_products_should_have_no_problem_providing_transparency_and_giving_consumers_more_transparency_and_options_is_always_a_good_thing")}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="about-us-img">
                  <img
                    src="/assets/images/database-img.jpg"
                    alt=""
                    title={t("about_ilife.database")}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End: Database */}
      </div>
    </React.Fragment>
  );
};

export default withRouter(AboutUs);
