var initialState = {
  isLoading: {}
};

export const common = (state = initialState, action) => {
  switch (action.type) {
    case "LOADING":
      return Object.assign({}, state, { isLoading: action.payload });
    default:
      return state;
  }
};