import React, { useState } from 'react';

const NumberRangeInput = ({ min, max, value, onChange, ...props }) => {
    const [inputValue, setInputValue] = useState(value);
    const handleChange = (event) => {
        const { value } = event.target;
        // Check if the entered value is within the desired range
        if (value >= min && value < max) {
            setInputValue(value);
            onChange(event);
        }
    }
    return (
        
        <input  
            type="number"
            min={min}
            max={max}
            value={inputValue}
            onChange={handleChange}
            {...props}
        />

    )

}

export default NumberRangeInput;