import React, { useState, useEffect, useRef } from "react";
import { Link, withRouter } from "react-router-dom";
import { Button, Popover, OverlayTrigger } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import moment from "moment";
import * as _ from "lodash";
import { connect } from "react-redux";
import { dateFormate } from "../../hooks/index";
import {
  decryptme,
  NumberFormatter,
  getAttchmentHtml,
  getAttachmentName,
  truncate,
  getProcessStep,
  showErrorToast,
} from "../../utils/index";
import {
  commonApiCall,
  getJwt,
  UNDERWRITING_DETAILS,
  COMPANY_INFORMATION,
  clearCompanyInformation,
  consoleLog,
} from "../../redux/actions/index";
import {
  API_URL_CHATSERVICE,
  API_URL_UNDERWRITING,
  API_URL_CLIENT,
} from "../../config/configs";
import { ChatLoader } from "../skeletonLoaders/index";
import ChatLeftbar from "./chatLeftbar";
import ChatRightbar from "./chatRightbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileWord } from "@fortawesome/free-solid-svg-icons";
import { faFileLines } from "@fortawesome/free-solid-svg-icons";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import { faFileExcel } from "@fortawesome/free-solid-svg-icons";

let stompClient, SockJS;
const PrivateChat = ({
  commonApiCall,
  consumer,
  underwriting,
  clearCompanyInformation,
  isPolicySigned = false,
  handleModel,
  chatModel,
  t,
  ...props
}) => {
  const imgRef = useRef(null);
  const currentChatRef = useRef(null);
  const [image, setImage] = useState(null);
  const [preview, setPreview] = useState();
  const [loading, setLoading] = useState(false);
  const { conversationDetails, applicationId } = props;
  const [onSocketStatus, setSocketStatus] = useState(null);
  const [messageListing, setMessageListing] = useState({});
  const [attachmentListing, setAttachmentListing] = useState([]);
  let isDatePrinted = [];
  const [formData, setFormData] = useState({
    totalRecords: 0,
    currentPage: 0,
    textMessage: "",
    textErr: "",
    channelId: conversationDetails?.agentResponse
      ? conversationDetails.agentResponse?.channelId
      : "",
    chatStatus: onSocketStatus
      ? onSocketStatus
      : conversationDetails?.agentResponse
      ? conversationDetails.agentResponse?.status
      : conversationDetails?.status
      ? conversationDetails.status
      : "OFF",
    isChatLoader: true,
  });

  const [offlineAgnet, setOfflineAgnet] = useState("");
  const firstName = conversationDetails?.agentResponse
    ? decryptme(conversationDetails.agentResponse?.firstName)
    : conversationDetails?.senderDetail?.firstName;
  const lastName = conversationDetails?.agentResponse
    ? decryptme(conversationDetails.agentResponse?.lastName)
    : conversationDetails?.senderDetail?.lastName;
  const conChannelId = conversationDetails.agentResponse?.channelId
    ? conversationDetails.agentResponse?.channelId
    : conversationDetails?.channelId;
  const createChannel = async () => {
    try {
      if (conversationDetails.agentResponse) {
        if (
          conChannelId === undefined ||
          conChannelId === null ||
          conChannelId === ""
        ) {
          const request = {
            clientUserId: consumer && consumer.id,
            agentUserId:
              conversationDetails &&
              conversationDetails.agentResponse &&
              conversationDetails.agentResponse.agentId,
            applicationCarrierId:
              conversationDetails && conversationDetails.applicationCarrierId,
            companyId: "1",
          };

          const apiEndPoint = API_URL_CHATSERVICE + "createChannel";
          const isAuthorized = getJwt() ? true : false;
          const response = await commonApiCall(
            apiEndPoint,
            "post",
            request,
            false,
            isAuthorized
          );
          if (response.status.code === 200) {
            setFormData({
              ...formData,
              channelId: response.data.channelId,
            });
            connect(response.data.channelId);
          } else {
            showErrorToast(response.status.message);
          }
        } else {
          connect(conChannelId);
        }
      } else {
        connect(conChannelId);
      }
    } catch (err) {
      consoleLog(err, "createChannel");
      showErrorToast(t("validation_message.VALID_ATTACHMENT"));
    }
  };

  useEffect(() => {
    // create the preview
    if (image) {
      const objectUrl = URL.createObjectURL(image);
      setPreview(objectUrl);
      return () => URL.revokeObjectURL(objectUrl);
    }
  }, [image]);

  const renderIcon = () => {
    const name = image?.name?.split(".")[1];
    if (
      name == "jpg" ||
      name == "jpeg" ||
      name == "png" ||
      name == "JPG" ||
      name == "JPEG" ||
      name == "PNG"
    ) {
      return (
        <img width={50} height={50} className='img-fluid' src={preview}></img>
      );
    } else if (
      name == "xls" ||
      name === "xlsx" ||
      name == "XLS" ||
      name == "XLSX"
    ) {
      return (
        <FontAwesomeIcon className='font-24 flex-shrink-0' icon={faFileExcel} />
      );
    } else if (
      name === "doc" ||
      name === "docx" ||
      name === "DOC" ||
      name === "DOCX"
    ) {
      return (
        <FontAwesomeIcon className='font-24 flex-shrink-0' icon={faFileWord} />
      );
    } else if (name === "pdf" || name == "PDF") {
      return (
        <FontAwesomeIcon className='font-24 flex-shrink-0' icon={faFilePdf} />
      );
    } else {
      return (
        <FontAwesomeIcon className='font-24 flex-shrink-0' icon={faFileLines} />
      );
    }
  };

  const connect = async (channelId) => {
    try {
      const Stomp = require("stompjs");
      SockJS = require("sockjs-client");
      SockJS = new SockJS(API_URL_CHATSERVICE + "ws");
      stompClient = Stomp.over(SockJS);
      stompClient.debug = null;

      stompClient.connect(
        {
          clientUserId: consumer && consumer.id,
          type: "CHATSCREEN",
        },
        function () {
          getMessagesList(channelId);
          getAttachments(channelId);
          onConnected(channelId);
        },
        function () {
          onError();
        }
      );
    } catch (err) {
      consoleLog(err, "connect");
      showErrorToast(t("validation_message.SOMETHING_WENT_WRONG"));
    }
  };

  const onConnected = (channelId) => {
    try {
      if (stompClient.connected) {
        const _channelId = channelId ? channelId : formData.channelId;
        //Subscribing to the private channel room
        stompClient.subscribe(
          "/user/" + _channelId + "/reply",
          function (responsePayload) {
            onMessageReceived(responsePayload);
          }
        );
        stompClient.subscribe(
          "/user/client-" + consumer.id + "/chatStatus",
          function (responsePayload) {
            onStatusReceived(responsePayload, _channelId);
          }
        );
        // Registering user to server as a private chat user
        stompClient.send(
          "/addUser",
          {},
          JSON.stringify({ channelId: _channelId, type: "JOIN" })
        );
      }
    } catch (err) {
      consoleLog(err, "onConnected");
      showErrorToast(t("validation_message.SOMETHING_WENT_WRONG"));
    }
  };

  const onStatusReceived = (payload, _channelId) => {
    try {
      if (payload) {
        const statusPayload = JSON.parse(payload.body);
        if (conversationDetails.agentResponse.agentId == statusPayload.selfId) {
          setSocketStatus(statusPayload.status);
          setFormData({
            ...formData,
            chatStatus: statusPayload.status,
          });
          getMessagesList(_channelId);
        }
      }
    } catch (err) {
      consoleLog(err, "onStatusReceived");
    }
  };

  const onError = (error) => {
    consoleLog(error, "error");
    consoleLog(
      t(
        "private_chat.could_not_connect_you_to_the_chat_room_server_please_refresh_this_page_and_try_again"
      )
    );
  };

  useEffect(() => {
    imgRef &&
      imgRef.current &&
      imgRef.current.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
  }, [image]);

  const sendMessage = () => {
    try {
      const { textMessage, channelId } = formData;
      if (stompClient) {
        if (textMessage.replace(/\s/g, "").length) {
          const chatMessage = JSON.stringify({
            message: textMessage,
            agentUserId: "",
            clientUserId: consumer && consumer.id,
            channelId: channelId,
          });
          stompClient.send("/sendMessage", {}, chatMessage);
          setFormData({ ...formData, textMessage: "" });
        } else {
          setFormData({ ...formData, textMessage: "" });
        }
      }
    } catch (err) {
      consoleLog(err, "sendMessage");
      // showErrorToast(t("validation_message.SOMETHING_WENT_WRONG"));
    }
  };

  const onMessageReceived = (payload) => {
    try {
      if (payload) {
        const message = JSON.parse(payload.body);
        if (message) {
          setOfflineAgnet(message.anonymousStatus);
        }
        if (message) {
          let chatConversationArray = [];

          chatConversationArray.push({
            channelId: message.channelId,
            agentUserId: message.agentUserId,
            clientUserId: message.clientUserId,
            senderName: message.senderName,
            message: message.message ? message.message : "",
            carrierSupportDocUrl: message.carrierSupportDocUrl
              ? message.carrierSupportDocUrl
              : "",
            carrierSupportDocName: message.carrierSupportDocName
              ? message.carrierSupportDocName
              : "",
            uploadedDoc: message.uploadedDoc ? message.uploadedDoc : "",
            uploadedDocName: message.uploadedDocName
              ? message.uploadedDocName
              : "",
            createdDate: message.createdDate,
          });
          setMessageListing((prevMessageListing) => [
            ...prevMessageListing,
            ...chatConversationArray,
          ]);
          if (message.uploadedDoc || message.carrierSupportDocUrl) {
            let attachmentListArray = [];

            attachmentListArray.push({
              uploadedDoc: message.uploadedDoc
                ? message.uploadedDoc
                : message.carrierSupportDocUrl
                ? message.carrierSupportDocUrl
                : "",
              uploadedDocName: message.uploadedDocName
                ? message.uploadedDocName
                : message.carrierSupportDocName
                ? message.carrierSupportDocName
                : "",
            });
            setAttachmentListing((prevAttachmentListing) => {
              let returnAttachmentListArray = [];
              if (prevAttachmentListing.length >= 15) {
                prevAttachmentListing.pop();
                returnAttachmentListArray = [
                  ...attachmentListArray,
                  ...prevAttachmentListing,
                ];
              } else {
                returnAttachmentListArray = [
                  ...attachmentListArray,
                  ...prevAttachmentListing,
                ];
              }
              return returnAttachmentListArray;
            });
          }
          scrollToEnd();
        }
      }
    } catch (err) {
      consoleLog(err, "onMessageReceived");
      //showErrorToast(t("validation_message.SOMETHING_WENT_WRONG"));
    }
  };

  useEffect(() => {
    createChannel();
  }, []);

  const getMessagesList = async (channelId) => {
    try {
      const _channelId = channelId ? channelId : formData.channelId;
      const request = {
        channelId: _channelId,
        clientUserId: consumer && consumer.id,
        pageNo: formData.currentPage,
        pageSize: 100,
      };

      const apiEndPoint = API_URL_CHATSERVICE + "getChatConversation";
      const isAuthorized = getJwt() ? true : false;
      const response = await commonApiCall(
        apiEndPoint,
        "post",
        request,
        false,
        isAuthorized
      );
      if (response.status.code === 200) {
        if (!_.isEmpty(messageListing)) {
          const conversationMessagesList = [
            ...response.data.chatConversation,
            ...messageListing,
          ];
          setMessageListing(conversationMessagesList);
        } else {
          setMessageListing(response.data.chatConversation);
        }
        setFormData({
          ...formData,
          totalRecords: response.data.totalRecord,
          currentPage: formData.currentPage + 1,
          channelId: _channelId,
          isChatLoader: false,
          chatStatus: response.data.chatStatus,
        });
        scrollToEnd();
      } else {
        showErrorToast(response.status.message);
      }
    } catch (err) {
      consoleLog(err, "getMessagesList");
    }
  };

  const handleUpload = (e) => {
    const name = e.target.files[0]?.name?.split(".")[1];
    let fileSize = Math.round(e.target.files[0].size / 1024);
    let fileExtension = [
      "JPG",
      "jpg",
      "jpeg",
      "JPEG",
      "png",
      "PNG",
      "doc",
      "DOC",
      "DOCX",
      "docx",
      "pdf",
      "PDF",
      "ppt",
      "PPT",
      "pptx",
    ];
    if (fileExtension.includes(name)) {
      if (!e.target.files[0]) {
        showErrorToast(t("validation_message.SELECT_FILE"));
      } else if (e.target.files[0] && fileSize > 10 * 1024) {
        showErrorToast(t("validation_message.SINGLE_FILE_VALIDATION"));
      } else {
        setImage(e.target.files[0]);
      }
    } else {
      showErrorToast("Please upload only .jpeg .jpg .pdf .doc .docx file");
    }
  };

  const sendAttachments = async () => {
    try {
      const { channelId } = formData;
      let renamedFile;
      const file = image;
      const splitName = file?.name?.split(".");
      const loweCase = splitName[1].toLowerCase();
      const finalString = splitName[0] + "." + loweCase;
      renamedFile = new File([file], finalString, { type: file.type });
      setLoading(true);
      let requestFormData = new FormData();
      //let attachmentFile = document.querySelector("#file");
      if (checkAllowedAttachment(renamedFile)) {
        requestFormData.append("document", renamedFile);
        requestFormData.append("agentUserId", "");
        requestFormData.append(
          "clientUserId",
          consumer && consumer.id ? consumer.id : underwriting.clientUserId
        );
        requestFormData.append("channelId", channelId);
        const apiEndPoint = API_URL_CHATSERVICE + "fileUpload";

        const isAuthorized = getJwt() ? true : false;
        setLoading(true);
        const response = await commonApiCall(
          apiEndPoint,
          "post",
          requestFormData,
          false,
          isAuthorized
        );

        if (response.status.code === 200) {
          formData.textMessage && sendMessage();
          let conversationList = [...messageListing, response.data];
          setFormData({
            ...formData,
            totalRecords: formData.totalRecords + 1,
            textMessage: "",
          });
          setMessageListing(conversationList);
          getAttachments(channelId);
          setImage(null);
          setLoading(false);
          scrollToEnd();
        } else {
          setLoading(false);
          showErrorToast(response.status.message);
        }
      } else {
        setLoading(false);
        // showErrorToast(t("validation_message.VALID_ATTACHMENT"));
      }
    } catch (err) {
      setLoading(false);
    }
  };

  const getAttachments = async (channelId) => {
    try {
      const request = {};

      const apiEndPoint =
        API_URL_CHATSERVICE +
        "getDocuments?channelId=" +
        channelId +
        "&pageNo=0&pageSize=15";
      const isAuthorized = getJwt() ? true : false;
      const response = await commonApiCall(
        apiEndPoint,
        "post",
        request,
        false,
        isAuthorized
      );
      if (response && response.status.code === 200) {
        if (response.data && response.data.chatConversation) {
          setAttachmentListing(response.data.chatConversation);
        }
      }
    } catch (err) {
      consoleLog(err, "getAttachments");
      //showErrorToast(t("validation_message.SOMETHING_WENT_WRONG"));
    }
  };

  const handleKeyPress = (evt) => {
    if (evt.key === "Enter" && !evt.shiftKey) {
      if (evt.type === "keypress") {
        sendMessage();
      }
      evt.preventDefault();
    }
  };

  const checkAllowedAttachment = (fileVal) => {
    switch (fileVal.type) {
      case "application/msword":
        return true;

      case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        return true;

      case "application/pdf":
        return true;

      case "image/png":
        return true;

      case "image/jpeg":
        return true;

      case "image/jpg":
        return true;

      default:
        return false;
    }
  };

  const resumeApplication = async (applicationId) => {
    try {
      const request = {
        applicationId: applicationId,
      };
      const apiEndPoint = API_URL_UNDERWRITING + "getUnderWriting";
      const isAuthorized = getJwt() ? true : false;
      const response = await commonApiCall(
        apiEndPoint,
        "post",
        request,
        UNDERWRITING_DETAILS,
        isAuthorized
      );
      if (response.status.code === 200) {
        if (response.data.referralCode) {
          const request = {};
          const apiEndPoint =
            API_URL_CLIENT +
            "getCompanyInfo?referralCode=" +
            response.data.referralCode;
          const isAuthorized = false;
          await commonApiCall(
            apiEndPoint,
            "get",
            request,
            COMPANY_INFORMATION,
            isAuthorized
          );
          const whereToRoute = getProcessStep(
            response.data.resumeProcess,
            response.data.goalType,
            response.data.policyType,
            response.data.productSelection
          );
          props.history.push(whereToRoute);
        } else {
          await clearCompanyInformation();
          const whereToRoute = getProcessStep(
            response.data.resumeProcess,
            response.data.goalType,
            response.data.policyType,
            response.data.productSelection
          );
          props.history.push(whereToRoute);
        }
      } else {
        showErrorToast(response.status.message);
      }
    } catch (err) {
      consoleLog(err, "resumeApplication");
      showErrorToast(t("validation_message.SOMETHING_WENT_WRONG"));
    }
  };

  const handleScroll = (container) => {
    const { totalRecords } = formData;
    if (messageListing.length === totalRecords) {
      return false;
    } else if (
      container.scrollTop === 0 &&
      messageListing.length <= totalRecords
    ) {
      getMessagesList();
    }
  };

  const scrollToEnd = () => {
    // const { currentPage } = formData;
    // let pageOffset = currentPage + 1;
    // const container = document.getElementById("chat-area");
    // container.getElementsByClassName("scrollbar-container")[0].scrollTop =
    //   container.getElementsByClassName("scrollbar-container")[0].scrollHeight /
    //   pageOffset;

    const { currentPage } = formData;
    let pageOffset = currentPage + 1;
    const container = document.getElementById("chat-area");
    if (container !== null && !loading) {
      container.getElementsByClassName("scrollbar-container")[0].scrollTop =
        container.getElementsByClassName("scrollbar-container")[0].scrollHeight;
    }
    if (container !== null && loading) {
      container.getElementsByClassName("scrollbar-container")[0].scrollTop =
        container.getElementsByClassName("scrollbar-container")[0].scrollHeight;
    }
  };

  useEffect(() => {
    currentChatRef &&
      currentChatRef.current &&
      currentChatRef.current.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
  }, [messageListing]);

  useEffect(() => {
    return () => {
      removeConnection();
      document.body.classList.remove("modal-open");
    };
  }, []);

  const removeConnection = () => {
    if (stompClient && stompClient.connected) {
      stompClient.disconnect(function () {
        consoleLog("Disconnected. See you next time!");
      });
    }
  };

  const returnPopOver = (id, content) => {
    return (
      <Popover id={id}>
        <Popover.Content className='popover-content'>{content}</Popover.Content>
      </Popover>
    );
  };

  const { textMessage, isChatLoader, channelId, chatStatus } = formData;
  return (
    <React.Fragment>
      <span>
        {stompClient && stompClient.connected && (
          <Link
            to='#'
            onClick={() => {
              handleModel("chatModel", !chatModel);
            }}
            className='close-btn'
            title='Close'
          >
            <span className='material-icons'>close</span>
          </Link>
        )}
      </span>
      <div className='row chat-screen'>
        <ChatLeftbar
          conversationDetails={conversationDetails}
          channelId={channelId}
          chatStatus={onSocketStatus ? onSocketStatus : chatStatus}
          t={t}
        ></ChatLeftbar>
        <div className='col-lg-9'>
          <div className='converexp'>
            <div className='conexp-left'>
              <div className='chat-component-header'>
                <div className='card-outer mb-0'>
                  <div className='guardian-outer'>
                    <div className='guardian-details'>
                      {conversationDetails.carrierLogo && (
                        <div className='guardian-img'>
                          <img
                            src={conversationDetails.carrierLogo}
                            alt='Carrier Logo'
                            title={conversationDetails.carrier_name}
                            width='32'
                            height='32'
                          />
                        </div>
                      )}
                      <div className='guardian-name'>
                        <h2>
                          <span>{conversationDetails?.carrierName}</span>
                        </h2>
                      </div>
                      {applicationId && !isPolicySigned && (
                        <span className='mt-2 resume-action'>
                          <Button
                            variant='primary-outline'
                            type='button'
                            onClick={() => resumeApplication(applicationId)}
                          >
                            {t("private_chat.resume")}
                          </Button>
                        </span>
                      )}
                    </div>
                    <div className='guardian-info'>
                      <ul>
                        {conversationDetails.creditRating && (
                          <li>
                            <h3>{conversationDetails.creditRating}</h3>
                            <span>{t("private_chat.credit_rating")}</span>
                          </li>
                        )}

                        {conversationDetails.carrierName !== "Pacific Life" &&
                        conversationDetails.carrierName !== "John Hancock" ? (
                          <li>
                            {conversationDetails.cashValueDividend !== 0 && (
                              <>
                                <h3>
                                  {NumberFormatter(
                                    conversationDetails.cashValueDividend?.toString(),
                                    "",
                                    "%"
                                  )}
                                </h3>
                                <span>
                                  {t("private_chat.cash_value_dividend")}
                                </span>
                              </>
                            )}
                          </li>
                        ) : (
                          <li></li>
                        )}
                        {conversationDetails.minInterestRate !== 0 && (
                          <li>
                            <h3>
                              {NumberFormatter(
                                conversationDetails.minInterestRate?.toString(),
                                "",
                                "%"
                              )}{" "}
                            </h3>
                            <span>
                              {t("private_chat.minimum_interest_rate")}
                            </span>
                          </li>
                        )}
                      </ul>
                    </div>
                  </div>

                  <div className='policy-outer'>
                    <div className='policy-left'>
                      <div className='policy-name'>
                        {conversationDetails.productName && (
                          <h3>
                            {" "}
                            {conversationDetails?.productName?.replace(
                              "15",
                              conversationDetails.pay
                            )}
                          </h3>
                        )}
                        <div className='chips-outer'>
                          {conversationDetails.frequency && (
                            <span className='chips-label'>
                              {conversationDetails.frequency}
                            </span>
                          )}
                          {conversationDetails.premium && (
                            <span className='chips-label'>
                              {conversationDetails.premium &&
                                NumberFormatter(
                                  conversationDetails.premium.toString(),
                                  "$"
                                )}
                            </span>
                          )}
                        </div>
                      </div>
                      <h4 className='m-0'>
                        {conversationDetails.productDescription
                          ? conversationDetails.productDescription
                          : "N/A"}
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
              {/* End: Chat Component Header */}
              {/* Begin: Chat Box */}
              <div className='chat-box-wrapper'>
                <div className='card-outer p-0 mb-0'>
                  <div className='chat-box-header'>
                    <h3 className='mb-0'>
                      {t("private_chat.youre_chatting_with")}{" "}
                      <span>
                        {firstName} {lastName}
                      </span>
                    </h3>
                  </div>

                  <div className='chat-box'>
                    {messageListing && messageListing.length <= 0 ? (
                      <h4 className='no-conve'>
                        {t("private_chat.no_conversations_found")}
                      </h4>
                    ) : null}

                    <ul className='chat-area' id='chat-area'>
                      {isChatLoader && <ChatLoader></ChatLoader>}

                      {/* <PerfectScrollbar onScrollY={(e) => handleScroll(e)}> */}
                      {Array.isArray(messageListing) &&
                        messageListing.map((message, key) => {
                          return (
                            <React.Fragment key={key}>
                              {_.includes(
                                isDatePrinted,
                                dateFormate(message.createdDate, "MM/DD/YYYY")
                              ) === false && (
                                <React.Fragment>
                                  <li className='chat-msg-item chat-date'>
                                    <span className='chat-date-span'>
                                      {dateFormate(
                                        message.createdDate,
                                        "MM/DD/YYYY"
                                      )}
                                    </span>
                                  </li>
                                  <li className='d-none'>
                                    {isDatePrinted.push(
                                      dateFormate(
                                        message.createdDate,
                                        "MM/DD/YYYY"
                                      )
                                    )}
                                  </li>
                                </React.Fragment>
                              )}
                              <li
                                className={
                                  message.clientUserId === ""
                                    ? "chat-msg-item "
                                    : "chat-msg-item sent-msg"
                                }
                                ref={
                                  key === messageListing.length - 1
                                    ? currentChatRef
                                    : null
                                }
                              >
                                <div
                                  className={
                                    message.uploadedDoc ||
                                    message.carrierSupportDocUrl
                                      ? "chat-msg no-padding"
                                      : "chat-msg"
                                  }
                                >
                                  <p>
                                    {message.message ? message.message : ""}
                                  </p>

                                  <span className='msg-time'>
                                    {message.agentUserId !== "" && (
                                      <React.Fragment>
                                        <span>{message.senderName}</span>
                                        <br />
                                      </React.Fragment>
                                    )}
                                    {moment(message.createdDate).format(
                                      "hh:mm a"
                                    )}
                                  </span>
                                </div>

                                <div className='document document-row'>
                                  <div className='chat-image'>
                                    {message.uploadedDoc &&
                                      getAttchmentHtml(message.uploadedDoc)}
                                    {message.carrierSupportDocUrl &&
                                      getAttchmentHtml(
                                        message.carrierSupportDocUrl
                                      )}
                                  </div>
                                  <div className='document-name'>
                                    <span
                                      className='list-label'
                                      style={{ cursor: "pointer" }}
                                    >
                                      <OverlayTrigger
                                        trigger={["hover", "focus"]}
                                        overlay={returnPopOver(
                                          "popover-basic2",
                                          message.uploadedDocName
                                            ? message.uploadedDocName
                                            : message.carrierSupportDocName
                                            ? message.carrierSupportDocName
                                            : ""
                                        )}
                                      >
                                        <span>
                                          {message.uploadedDoc &&
                                          message.uploadedDocName &&
                                          getAttachmentName(message.uploadedDoc)
                                            ? truncate(
                                                message.uploadedDocName,
                                                15
                                              )
                                            : ""}
                                          {message.carrierSupportDocUrl &&
                                            message.carrierSupportDocName && (
                                              <p>
                                                {getAttachmentName(
                                                  message.carrierSupportDocUrl
                                                )
                                                  ? truncate(
                                                      message.carrierSupportDocName,
                                                      15
                                                    )
                                                  : ""}
                                              </p>
                                            )}
                                        </span>
                                      </OverlayTrigger>
                                    </span>
                                  </div>
                                </div>
                              </li>
                            </React.Fragment>
                          );
                        })}
                      {(offlineAgnet === "OFF" || onSocketStatus === "OFF") && (
                        <li className={"chat-msg-item"}>
                          <p>
                            {firstName} {lastName} is offline now, and will
                            answer your questions as soon as comes back!
                          </p>
                        </li>
                      )}
                      {/* </PerfectScrollbar> */}
                    </ul>
                  </div>

                  <div className='chat-buttons'>
                    {image && (
                      <>
                        <div className='white-box p-2 border mb-2 d-flex'>
                          {renderIcon()}
                          <span className='ml-2 line-count'>{image.name}</span>
                          <span
                            onClick={() => setImage(null)}
                            class='material-icons cursor-pointer ml-auto'
                          >
                            close
                          </span>
                        </div>
                      </>
                    )}
                    <div className='chat-button-inner'>
                      <div className='form-group mb-0 chat-input'>
                        <textarea
                          type='text'
                          name='textmsg'
                          value={textMessage}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              textMessage: e.target.value,
                              textErr: "",
                            })
                          }
                          onKeyPress={(e) => handleKeyPress(e)}
                          placeholder={t("private_chat.type_message_here")}
                          className='form-control'
                          disabled={isChatLoader ? true : false}
                        />
                        <div className='choose-file'>
                          <input
                            className='profile-input'
                            type='file'
                            style={{ display: "block" }}
                            name='image'
                            id='file'
                            value={""}
                            accept='image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf'
                            onChange={(e) => handleUpload(e)}
                          />
                          <label
                            className='material-icons add-file'
                            htmlFor='file'
                          >
                            add_photo_alternate
                          </label>
                        </div>
                      </div>

                      <div className='form-group mb-0'>
                        <Button
                          type='button'
                          value='Submit'
                          className='btn-sm'
                          disabled={loading}
                          //onClick={() => sendMessage()}
                          onClick={(event) =>
                            image ? sendAttachments() : sendMessage()
                          }
                        >
                          {" "}
                          {t("private_chat.send")}{" "}
                        </Button>
                      </div>
                    </div>
                    <span className='text-danger '>{formData.textErr}</span>
                  </div>
                </div>
              </div>
              <div ref={imgRef ? imgRef : null} />
            </div>
            <ChatRightbar
              conversationDetails={conversationDetails}
              attachmentListing={attachmentListing}
              t={t}
            ></ChatRightbar>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  consumer: state.consumer.consumerdetails,
  underwriting: state.underwriting.underwriting,
});
export default connect(mapStateToProps, {
  commonApiCall,
  clearCompanyInformation,
})(withRouter(PrivateChat));
