import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Animated } from "react-animated-css";
import { withRouter } from "react-router-dom";
import PolicyNavbar from "../../components/policyNavbar";
import { commonApiCall, getJwt } from "../../redux/actions/index";
import { API_URL_UNDERWRITING } from "../../config/configs";
import { decryptme, DashboardRoute, showErrorToast } from "../../utils/index";
import moment from "moment";
import NumberFormat from "react-number-format";
import { BasicDetailLoader } from "../../components/skeletonLoaders";

const BasicDetailDashboard = ({ commonApiCall, t, ...props }) => {
  const [policyDetails, setPolicyDetails] = useState({
    firstName: "",
    lastName: "",
    email: "",
    mobile: "",
    ssn: "",
    drivingLicense: "",
    dob: new Date(),
    addressLine1: "",
    addressLine2: "",
    initialDeposit: "",
    commission: "",
    carrierName: "",
    policyType: "",
    location: "",
    reference: "",
    ip: "",
    applicationid: "",
  });
  const [isLoading, setLoading] = useState(true);
  const isAuthorized = getJwt() ? true : false;
  if (props.location.policyId) {
    localStorage.setItem("policyId", props.location.policyId);
  } else if (!localStorage.getItem("policyId")) {
    props.history.push(DashboardRoute.DASHBOARD);
  }

  if (props.location.redirectPathBack) {
    localStorage.setItem("redirectPathBack", props.location.redirectPathBack);
  }

  let redirectPathBack = localStorage.getItem("redirectPathBack");

  let id = localStorage.getItem("policyId");
  useEffect(() => {
    async function getBasicDetails() {
      try {
        const apiEndPoint =
          API_URL_UNDERWRITING +
          "applicationBasicDetails?anonymousApplicationCarrierId=" +
          id;
        let response = await commonApiCall(
          apiEndPoint,
          "get",
          null,
          null,
          isAuthorized
        );
        if (response && response.status.code === 200) {
          let {
            firstName,
            lastName,
            email,
            mobile,
            drivingLicense,
            dob,
            addressLine1,
            initialDeposit,
            commission,
            carrierName,
            policyType,
            location,
            reference,
            applicationid,
          } = response.data;
          setPolicyDetails({
            ...policyDetails,
            drivingLicense: drivingLicense
              ? await decryptme(drivingLicense)
              : "N/A",
            firstName: firstName ? await decryptme(firstName) : "N/A",
            lastName: lastName ? await decryptme(lastName) : "N/A",
            email: email ? await decryptme(email) : "N/A",
            dob: dob ? new Date(await decryptme(dob)) : new Date(),
            street: addressLine1 ? await decryptme(addressLine1) : "N/A",
            mobile: mobile ? await decryptme(mobile) : "N/A",
            initialDeposit: initialDeposit ? initialDeposit : "N/A",
            commission: commission ? commission : "N/A",
            carrierName: carrierName ? carrierName : "N/A",
            policyType: policyType ? policyType : "N/A",
            location: location ? location : "N/A",
            reference: reference ? reference : "N/A",
            applicationid: applicationid ? applicationid : "N/A",
          });
          setLoading(false);
        } else {
          showErrorToast(t("dashboard_basic_details.something_went_wrong"));
        }
      } catch (err) {
        showErrorToast(t("dashboard_basic_details.something_went_wrong"));
      }
    }
    getBasicDetails();
  }, []);

  let {
    firstName,
    lastName,
    email,
    mobile,
    drivingLicense,
    dob,
    street,
    applicationid,
  } = policyDetails;

  return (
    <React.Fragment>
      <div className="row">
        <div className="col-3 sidebar-part">
          <PolicyNavbar
            t={t}
            redirectPathBack={redirectPathBack}
          ></PolicyNavbar>
        </div>
        <div className="col-lg-9">
          <Animated
            animationIn="fadeIn"
            animationOut="faderOutLeft"
            animationInDuration={1200}
            animationOutDuration={800}
            isVisible={true}
          >
            <div className="white-box2">
              {isLoading ? (
                <BasicDetailLoader />
              ) : (
                <React.Fragment>
                  <div className="box-title no-border">
                    <h3 className="font-24">
                      {t("dashboard_basic_details.basic_details")}
                    </h3>
                  </div>
                  <div className="row">
                    <div className="col-sm-4">
                      <div className="form-group filled-value">
                        <label>{t("dashboard_basic_details.first_name")}</label>
                        <h4>{firstName}</h4>
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="form-group filled-value">
                        <label>{t("dashboard_basic_details.last_name")}</label>
                        <h4>{lastName}</h4>
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="form-group filled-value">
                        <label>{t("dashboard_basic_details.email")}</label>
                        <h4>{email}</h4>
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="form-group filled-value">
                        <label>
                          {t("dashboard_basic_details.mobile_number")}
                        </label>
                        <h4>
                          <NumberFormat
                            name="mobile"
                            id="mobile"
                            className="form-control"
                            placeholder="Mobile"
                            format="(###) ###-####"
                            value={mobile}
                            disabled={true}
                            displayType="text"
                            renderText={(mobile) => <span>{mobile}</span>}
                          />
                        </h4>
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="form-group filled-value">
                        <label>
                          {t("dashboard_basic_details.driver's_license_number")}
                        </label>
                        <h4>{drivingLicense ? drivingLicense : "N/A"}</h4>
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="form-group filled-value">
                        <label>
                          {t("dashboard_basic_details.date_of_birth")}
                        </label>
                        <h4>{moment(dob).format("L").toString()}</h4>
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="form-group filled-value">
                        <label>
                          {t("dashboard_basic_details.address_line_1")}
                        </label>
                        <h4>{street}</h4>
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="form-group filled-value">
                        <label>
                          {t("dashboard_basic_details.application_id")}
                        </label>
                        <h4>{applicationid}</h4>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              )}
            </div>
          </Animated>
        </div>
      </div>
    </React.Fragment>
  );
};

export default connect(null, { commonApiCall })(
  withRouter(BasicDetailDashboard)
);
