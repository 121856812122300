import React, { useState, useEffect } from "react";
import { Animated } from "react-animated-css";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Dropzone from "react-dropzone";
import PolicyNavbar from "../../components/policyNavbar";
import { DocumentLoader } from "../../components/skeletonLoaders";
import { commonApiCall, getJwt } from "../../redux/actions";
import { API_URL_UNDERWRITING } from "../../config/configs";
import {
  DashboardRoute,
  getFileType,
  showErrorToast,
  showSuccessToast,
} from "../../utils";

const PolicyDocs = ({
  commonApiCall,
  app_id,
  uploadedFrom,
  agentId,
  from,
  closeModal,
  t,
  ...props
}) => {
  const [filesUpload, setFilesUpload] = useState([]);
  const [uploadDisabled, setUploadDisabled] = useState(false);
  const [getFileList, setFileList] = useState({
    fileListing: [],
  });

  const [getFileDoc, setFileDoc] = useState({
    documentName: "",
    documentDesc: "",
    errors: {},
  });
  const [isLoading, setLoading] = useState(true);
  if (props.location.policyId) {
    localStorage.setItem("policyId", props.location.policyId);
  } else if (!localStorage.getItem("policyId") && !app_id) {
    props.history.push(DashboardRoute.DASHBOARD);
  }
  let id = app_id ? app_id : localStorage.getItem("policyId");
  const onDrop = (filesUpload) => {
    if (checkAllowedFile(filesUpload[0])) {
      setFilesUpload(filesUpload);
      errors["documentDesc"] = "";
      setFileDoc({ ...getFileDoc, errors: errors });
    } else {
      errors["documentDesc"] = "please upload only pdf, doc, jpg, png file";
      setFileDoc({ ...getFileDoc, errors: errors });
    }
  };

  const checkAllowedFile = (fileVal) => {
    switch (fileVal.type) {
      case "application/msword":
        return true;

      case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        return true;

      case "application/pdf":
        return true;

      case "image/png":
        return true;

      case "image/jpeg":
        return true;

      case "image/jpg":
        return true;

      default:
        return false;
    }
  };

  const handleChange = (e) => {
    setFileDoc({
      ...getFileDoc,
      [e.target.name]: e.target.value,
      errors: Object.assign(getFileDoc.errors, { [e.target.name]: "" }),
    });
  };

  useEffect(() => {
    getDocumentList();
  }, []);

  const getDocumentList = async () => {
    const isAuthorized = getJwt() ? true : false;
    try {
      const apiEndPoint =
        API_URL_UNDERWRITING +
        "getPolicyDocumentUpload?aplicationCarrierId=" +
        id;
      let response = await commonApiCall(
        apiEndPoint,
        "get",
        null,
        null,
        isAuthorized
      );
      if (response.status.code === 200 || response.status.code === 404) {
        setFileList({
          ...getFileList,
          fileListing: response.data,
        });
        setLoading(false);
      } else {
        showErrorToast(response.status.message);
        setLoading(false);
      }
    } catch (err) {
      showErrorToast(t("policy_document.something_went_wrong"));
    }
  };

  const formValidate = () => {
    let { documentName, errors } = getFileDoc;
    let isValid = true;
    if (documentName.trim().length <= 0) {
      errors["documentName"] = t("policy_document.please_enter_document_name");
      isValid = false;
    } else if (documentName.trim().length > 50) {
      isValid = false;
      errors["documentName"] = t(
        "policy_document.maximum_50_characters_allowed"
      );
    }

    if (filesUpload.length <= 0) {
      errors["documentDesc"] = t("policy_document.please_select_file");
      isValid = false;
    }
    setFileDoc({ ...getFileDoc, errors: errors });
    return isValid;
  };

  const uploadDoc = () => {
    if (formValidate()) {
      let { documentName, documentDesc } = getFileDoc;
      const isAuthorized = getJwt() ? true : false;
      setUploadDisabled(true);
      let formData = new FormData();
      formData.append("aplicationCarrierId", id);
      formData.append("file", filesUpload[0]);
      formData.append("documentName", documentName);
      formData.append("description", documentDesc);
      formData.append(
        "uploadedFrom",
        uploadedFrom ? uploadedFrom : "DASHBOARD"
      );
      if (agentId) {
        formData.append("agentId", agentId);
      }

      async function getUploadDocs() {
        try {
          const apiEndPoint = API_URL_UNDERWRITING + "policyDocumentUpload";
          let response = await commonApiCall(
            apiEndPoint,
            "post",
            formData,
            null,
            isAuthorized,
            true
          );
          if (response.status.code === 200) {
            showSuccessToast(response.status.message);
            setUploadDisabled(false);
            setFileDoc({
              ...getFileDoc,
              documentName: "",
              documentDesc: "",
            });
            setFilesUpload({
              filesUpload: [],
            });
            getDocumentList();
            if (closeModal !== undefined) {
              closeModal();
            }
          } else {
            setUploadDisabled(false);
            showErrorToast(response.status.message);
          }
        } catch (err) {
          showErrorToast(t("policy_document.something_went_wrong"));
        }
      }
      getUploadDocs();
    }
  };

  let { errors, documentName, documentDesc } = getFileDoc;

  const openDocuments = (url) => {
    try {
      window.open(url, "_blank");
    } catch (err) {
      showErrorToast(t("policy_document.something_went_wrong"));
    }
  };

  let { fileListing } = getFileList;

  return (
    <React.Fragment>
      <div className="row">
        {from === "ApproveProcess" ? (
          ""
        ) : (
          <div className="col-3 sidebar-part">
            <PolicyNavbar t={t}></PolicyNavbar>
          </div>
        )}

        <div className="col-lg-9">
          <Animated
            animationIn="fadeIn"
            animationOut="faderOutLeft"
            animationInDuration={1200}
            animationOutDuration={800}
            isVisible={true}
          >
            <div className="white-box2">
              <h3 className="font-24 mb-4">{t("policy_document.documents")}</h3>
              <div className="row">
                <div className="col-12">
                  <div className="form-group">
                    <div className="input-effect">
                      <input
                        className="form-control"
                        placeholder="Document Name"
                        name="documentName"
                        id="documentname"
                        type="text"
                        onChange={(e) => handleChange(e)}
                        value={documentName}
                      />
                      <label htmlFor="documentname">
                        {t("policy_document.document_name")}
                      </label>
                      <span className="text-danger ">
                        {errors.documentName}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="form-group">
                    <div className="input-effect">
                      <textarea
                        className="form-control"
                        placeholder="Description"
                        name="documentDesc"
                        onChange={(e) => handleChange(e)}
                        id="description"
                        rows="4"
                        value={documentDesc}
                      />
                      <label htmlFor="description">
                        {t("policy_document.description")}
                      </label>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="form-group">
                    <Dropzone onDrop={onDrop} multiple={false}>
                      {({ getRootProps, getInputProps }) => (
                        <React.Fragment>
                          <div {...getRootProps({ className: "dropzone" })}>
                            <input {...getInputProps()} />
                            <h5>
                              {t(
                                "policy_document.drag_and_drop_files_here_or_click_to_upload_file"
                              )}
                            </h5>
                            <span className="material-icons">cloud_upload</span>
                          </div>
                          <aside>
                            <ul className="uploaded-file">
                              {filesUpload.length > 0 &&
                                filesUpload.map((each) => {
                                  return (
                                    <li key={each.name} className="file-name">
                                      <span className="material-icons">
                                        attach_file
                                      </span>
                                      {each.name} - {each.size} bytes
                                    </li>
                                  );
                                })}
                            </ul>
                          </aside>
                        </React.Fragment>
                      )}
                    </Dropzone>
                    <span className="text-danger ">{errors.documentDesc}</span>
                  </div>
                </div>
                <div className="col-12 text-right">
                  <div className="form-group mb-0">
                    <button
                      className="btn btn-primary btn-lg"
                      onClick={uploadDoc}
                      disabled={uploadDisabled}
                    >
                      {uploadDisabled
                        ? t("policy_document.wait...")
                        : t("policy_document.upload")}
                    </button>
                  </div>
                </div>
              </div>

              {fileListing && fileListing.length > 0 ? (
                <React.Fragment>
                  {from === "ApproveProcess" ? (
                    ""
                  ) : (
                    <React.Fragment>
                      <h3 className="font-24 mb-4 mt-3">
                        {t("policy_document.uploaded_documents")}
                      </h3>
                      <div className="documents documents-card">
                        {isLoading ? (
                          <DocumentLoader />
                        ) : (
                          <div className="row">
                            {fileListing &&
                              fileListing.map((list, index) => {
                                return (
                                  <div
                                    className="col-md-4 col-sm-6"
                                    onClick={() => openDocuments(list.url)}
                                    key={"doc" + index}
                                  >
                                    <div className="w-100">
                                      {getFileType(list.url, list.documentName)}
                                    </div>
                                  </div>
                                );
                              })}
                          </div>
                        )}
                      </div>
                    </React.Fragment>
                  )}
                </React.Fragment>
              ) : (
                ""
              )}
            </div>
          </Animated>
        </div>
      </div>
    </React.Fragment>
  );
};

export default connect(null, { commonApiCall })(withRouter(PolicyDocs));
