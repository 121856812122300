import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

const WelcomePageSkeleton = () => {
  return (
    <>
      <SkeletonTheme>
        <div className="page-wrapper-welcome">
          <header sticky="top">
            <div className="welcome-header custom-nav">
              <p className="mt-4">
                <Skeleton
                  height={40}
                  width={200}
                  style={{ marginLeft: "50px" }}
                />
                <Skeleton
                  height={40}
                  width={100}
                  style={{
                    float: "right",
                    marginRight: "20px",
                    marginLeft: "50px",
                  }}
                />
                <Skeleton height={40} width={100} style={{ float: "right" }} />
                <Skeleton
                  height={40}
                  width={100}
                  style={{ float: "right", marginRight: "3%" }}
                />
              </p>
            </div>
          </header>
          <div className="welcome-mode">
            <div className="text-center">
              <div className="animated fadeInRight">
                <div className="demopage">
                  <div className="demopagewrapper">
                    <div className="welcomeContainer position-relative welcome-background">
                      <div className="welcomePageBg">
                        <div className="demopagebody position-relative">
                          <div className="pre-main-body">
                            <div className="body-block bg-transparent">
                              <h2 className="body-title">
                                <Skeleton height={40} width={200} />
                              </h2>
                              <p className="body-msg">
                                <span>
                                  <Skeleton height={80} width={400} />
                                </span>
                              </p>
                              <div>
                                <Skeleton height={40} width={250} />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="premodel-footer modal-footer">
                      <div className="ftitle-block text-center">
                        <h3 className="footerTitle">
                          <Skeleton height={40} width={100} />
                        </h3>
                      </div>
                      <div className="fcontact">
                        <p className="f-address"></p>
                        <p className="f-tell">
                          <span>
                            <Skeleton
                              height={40}
                              width={100}
                              style={{
                                baseColor: "red",
                              }}
                            />
                          </span>
                        </p>
                        <p className="f-web"></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </SkeletonTheme>
    </>
  );
};

export default WelcomePageSkeleton;
