import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import queryString from "query-string";
import {
  clearCompanyInformation,
  clearUnderWriting,
} from "../../../redux/actions";
import { UnderWritingRoutes } from "../../../utils";
import ApplicationStatus from "../../approvalStatus/applicationStatus";

const AssurityDeny = ({ t, underwriting, ...props }) => {
  const phase = new URLSearchParams(props.location.search).get("phase");
  const handleNavigation = async () => {
    props.history.push(UnderWritingRoutes.PLANS);
  };
  return (
    <div className="assurity w-100 mw-100">
      <div className="container mt-5">
        <div className="heading-block">
          <h3 className="w-75 mx-auto">
            {phase === "underwriting"
              ? "Based on the preliminary information provided, you are not eligible for coverage under this plan of insurance."
              : "We're sorry. Based on your application, Assurity is unable to offer you a policy."}
          </h3>
          {(phase === null || phase === undefined) && (
            <h4>
              Based on the preliminary information provided, you are not
              eligible for coverage under this plan of insurance.
            </h4>
          )}
        </div>

        <div className="white-box mb-5 w-50 mx-auto mt-5">
          {(phase === null || phase === undefined) && (
            <h4
              className="text-center"
              style={{ fontSize: 17, color: "#000", fontWeight: "bold" }}
            >
              However, there are many available policies for your needs.
            </h4>
          )}

          {phase === null || phase === undefined ? (
            <img
              className="mt-5 mx-auto d-block"
              src="/assets/images/family.png"
              style={{ width: 100 }}
            />
          ) : (
            <img
              className="mt-5 mx-auto d-block"
              src="/assets/images/family.svg"
              style={{ width: 100 }}
            />
          )}

          <div className="w-100 text-center mt-5">
            <button
              className="btn btn-primary-outlined"
              onClick={handleNavigation}
            >
              Browse Quotes
            </button>
          </div>
          <ApplicationStatus t={t} sbli={true} assurity={true} />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  underwriting: state.underwriting.underwriting,
});
export default connect(mapStateToProps, {})(withRouter(AssurityDeny));
