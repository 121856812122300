import React, { useEffect, useState } from "react";
import { Modal, Table } from "react-bootstrap";
import { isEmpty } from "lodash";
import DatePicker from "react-datepicker";
import {
  commonApiCall,
  getJwt,
  UNDERWRITING_DETAILS,
  setSbliProgressCount,
  setAssurityProgressCount,
} from "../../../redux/actions";
import { API_URL_UNDERWRITING } from "../../../config/configs";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import { showErrorToast, UnderWritingRoutes } from "../../../utils";

import moment from "moment";
import Select from "react-select";

let initForm = {
  firstName: "",
  lastName: "",
  dob: "",
  ssn: "",
  relationshipType: "",
  percentage: "",
  errors: {},
};

const relationshipTypeArr = [
  "Aunt",
  "Brother",
  "Brother_In_Law",
  "Business",
  "Business_Associate",
  "Business_Partner",
  "Charity",
  "Child",
  "Children_Equally_or_Survivor",
  "Company",
  "Corporation",
  "Cousin",
  "Creditor",
  "Daughter",
  "Daughter_In_Law",
  "Domestic_Partner",
  "Employer",
  "Estate",
  "Father",
  "Father_In_Law",
  "Fiance",
  "Fiancee",
  "Former_Husband",
  "Former_Spouse",
  "Former_Wife",
  "Foster_Children",
  "Foster_Daughter",
  "Foster_Father",
  "Foster_Mother",
  "Foster_Son",
  "Friend",
  "Godchild",
  "Goddaughter",
  "Godfather",
  "Godmother",
  "Godparent",
  "Godson",
  "Grandchild",
  "Granddaughter",
  "Grandfather",
  "Grandmother",
  "Grandnephew",
  "Grandniece",
  "Grandparent",
  "Grandson",
  "Great_Aunt",
  "Great_Granddaughter",
  "Great_Grandfather",
  "Great_Grandmother",
  "Great_Grandson",
  "Great_Uncle",
  "Guardian",
  "Half_Brother",
  "Half_Sister",
  "Husband",
  "Institution",
  "Mother",
  "Mother_In_Law",
  "Nephew",
  "Niece",
  "Parent",
  "Partner",
  "Partnership",
  "Sibling",
  "Sister",
  "Sister_In_Law",
  "Sole_Proprietorship",
  "Son",
  "Son_In_Law",
  "Spouse",
  "Step_Brother",
  "Step_Child",
  "Step_Daughter",
  "Step_Father",
  "Step_Mother",
  "Step_Parent",
  "Step_Sibling",
  "Step_Sister",
  "Step_Son",
  "Trust",
  "Trustee",
  "Uncle",
  "Wife",
  "Will",
];

const AssurityBeneficiary = ({
  t,
  commonApiCall,
  underwriting,
  setSbliProgressCount,
  progressCount,
  setAssurityProgressCount,
  assurityCount,
  ...props
}) => {
  const { applicationId, applicationType, goalType, policyType } = underwriting;
  const [showBenefiaryForm, setShowBeneficiaryForm] = useState(false);
  const [beneficiaryPerson, setBeneficiaryPerson] = useState([]);
  const [beneficiaryItem, setBeneficiaryItem] = useState(initForm);
  const [decisionLoading, setDecisionLoading] = useState(false);
  const [decisionData, setDecisionData] = useState(
    localStorage.getItem("persist::ass::bene")
      ? JSON.parse(localStorage.getItem("persist::ass::bene"))
      : null
  );
  const [selectedIndex, setSelectedIndex] = useState(null);
  // const changesDone = new URLSearchParams(props.location.search).get("changes");
  
  const toggleForm = () => setShowBeneficiaryForm(!showBenefiaryForm);

  const {
    firstName,
    lastName,
    dob,
    ssn,
    relationshipType,
    percentage,
    errors,
  } = beneficiaryItem;

  const handleChange = (e) => {
    if (e.target.name === "percentage") {
      if (!e.target.value.match(/^\d*$/)) {
        return;
      }
    }
    let tempBeneficiary = { ...beneficiaryItem };
    tempBeneficiary[e.target.name] = e.target.value;
    setBeneficiaryItem(tempBeneficiary);
  };

  const validateForm = () => {
    const { firstName, lastName, dob, relationshipType, ssn, percentage } =
      beneficiaryItem;

    let errors = {};

    if (beneficiaryItem.hasOwnProperty("firstName")) {
      if (isEmpty(firstName)) errors.firstName = t("validation_message.fName");
      else delete errors.firstName;
    }
    if (beneficiaryItem.hasOwnProperty("lastName")) {
      if (isEmpty(lastName)) errors.lastName = t("validation_message.lName");
      else delete errors.lastName;
    }
    // if (beneficiaryItem.hasOwnProperty("dob")) {
    //   if (isEmpty(dob)) errors.dob = t("validation_message.dob");
    //   else delete errors.dob;
    // }
    if (beneficiaryItem.hasOwnProperty("relationshipType")) {
      if (isEmpty(relationshipType))
        errors.relationship = t("validation_message.relationship");
      else delete errors.relationship;
    }
    // if (beneficiaryItem.hasOwnProperty("ssn")) {
    //   if (isEmpty(ssn)) errors.ssn = "Enter Social Security Number";
    //   else delete errors.ssn;
    // }

    if (beneficiaryItem.hasOwnProperty("percentage")) {
      if (isEmpty(percentage))
        errors.percentage = t("validation_message.percentage");
      else delete errors.percentage;
    }
    const isValid = Object.keys(errors).length !== 0 ? false : true;
    setBeneficiaryItem({
      ...beneficiaryItem,
      errors: errors,
    });
    return isValid;
  };

  const onSave = () => {
    if (validateForm()) {
      if (selectedIndex !== null) {
        let data = beneficiaryPerson.map((item, index) => {
          if (index === selectedIndex) {
            return { ...beneficiaryItem };
          }
          return item;
        });
        setBeneficiaryPerson(data);
        setShowBeneficiaryForm(false);
      } else {
        setBeneficiaryPerson([...beneficiaryPerson, beneficiaryItem]);
        setShowBeneficiaryForm(false);
      }
      setBeneficiaryItem({ ...initForm });
      setSelectedIndex(null);
    }
  };

  // useEffect(() => {
  //   if (changesDone === null || changesDone === undefined) {
  //     getDecision();
  //   }
  // }, []);

  const onDelete = (index) => {
    setBeneficiaryPerson(beneficiaryPerson.filter((_, i) => i !== index));
  };

  const validateTable = () => {
    let value = beneficiaryPerson.reduce(
      (total, obj) => parseInt(obj.percentage) + parseInt(total),
      0
    );
    if (!beneficiaryPerson.length) {
      showErrorToast("Please add beneficiary data first!!");
      return false;
    } else if (value !== 100) {
      showErrorToast("Percentage division should be equivalent to 100");
    } else {
      return true;
    }
  };

  const getTermYears = (pay) => {
    return pay === 10
      ? "TenYears"
      : pay === 15
      ? "FifteenYears"
      : pay === 20
      ? "TwentyYears"
      : pay === 30
      ? "ThirtyYears"
      : "";
  };

  const onTableSubmit = async () => {
    if (validateTable()) {
      let tempDec = { ...decisionData };
      let temp = beneficiaryPerson.map((item) => ({
        person: {
          name: {
            first: item.firstName,
            last: item.lastName,
          },
          dateOfBirth: moment(item.dob).format(),
          // socialSecurityNumber: item.ssn,
        },
        relationshipToPrimaryInsured: item.relationshipType,
        sharedPercentage: parseInt(item.percentage),
      }));

     
      tempDec.product.beneficiaries = {
        primaryBeneficiaries: temp,
      };

      tempDec.product.primaryInsured.coverages[0].benefitAmount = {
        applicationValue: underwriting?.amount,
      };

      tempDec.product.primaryInsured.coverages[0].options = {
        termPeriod: { applicationValue: getTermYears(underwriting?.pay) },
      };
      localStorage.setItem("persist::ass::bene", JSON.stringify(tempDec));
      setAssurityProgressCount({
        ...assurityCount,
        beneficiaryProgress: 100,
      });

      saveUnderwriteDetails();
      //   setSbliProgressCount(progressCount + 1);
    }
  };

  const saveUnderwriteDetails = async () => {
    try {
      const request = {
        applicationId: underwriting?.applicationId,
        applicationType: "Web",
        nextStep: "24,ASSURITY_PAY",
      };

      const isAuthorized = getJwt() ? true : false;
      const apiEndPoint = API_URL_UNDERWRITING + "saveUnderWriting";
      const response = await commonApiCall(
        apiEndPoint,
        "post",
        request,
        UNDERWRITING_DETAILS,
        isAuthorized
      );

      if (response.status.code === 200) {
        props.history.push(UnderWritingRoutes.ASSURITY_PAY);
      }
    } catch (err) {
      showErrorToast(err);
    }
  };

  const onEdit = (item, index) => {
    setShowBeneficiaryForm(true);
    setBeneficiaryItem(item);
    setSelectedIndex(index);
  };
  return decisionLoading ? (
    <h2 className="text-center">Please wait...</h2>
  ) : (
    <div className="sbli-beneficiary-container mt-4">
      <Modal
        show={showBenefiaryForm}
        // onHide={() => toggleDocument()}s
        centered
      >
        <Modal.Header className="p-2"></Modal.Header>
        <Modal.Body className="beneficiary-body more-info">
          <div className="form-wrapper">
            <h4 className="mb-3">Who should we pay if you pass away?</h4>
            <form className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <div className="input-effect">
                    <input
                      className="form-control"
                      placeholder={"First Name"}
                      name="firstName"
                      id="first_name"
                      type="text"
                      onChange={handleChange}
                      value={firstName}
                    />
                    <label htmlFor="city">{"First name"}</label>
                    <em className="input-icon"></em>
                    <span className="text-danger ">{errors?.firstName}</span>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <div className="input-effect">
                    <input
                      className="form-control"
                      placeholder={"Last Name"}
                      name="lastName"
                      id="last_name"
                      type="text"
                      onChange={handleChange}
                      value={lastName}
                    />
                    <label htmlFor="city">{"Last name"}</label>
                    <em className="input-icon"></em>
                    <span className="text-danger ">{errors?.lastName}</span>
                  </div>
                </div>
              </div>
              {/* <div className="col-md-6">
                <h4 className="mb-3">What is your social security number?</h4>
                <div className="form-group">
                  <div className="input-effect ">
                    <NumberFormat
                      name="ssn"
                      id="ssn"
                      className="form-control"
                      placeholder="Social Security Number (SSN#)"
                      format="###-##-####"
                      value={ssn}
                      onValueChange={(e) =>
                        handleChange({
                          target: { name: "ssn", value: e.value },
                        })
                      }
                    />
                    <label htmlFor="SSN">Social Security Number (SSN#)</label>
                    <span className="text-danger ">{errors?.ssn}</span>
                  </div>
                </div>
              </div> */}

              <div className="col-lg-6 col-md-6">
                <h4 className="mb-3">What is their relationship to you?</h4>
                <div className="form-group fixed-floating-label mb-4">
                  <div className="default-select with-border">
                    <Select
                      options={relationshipTypeArr.map((relation) => ({
                        label: relation.replaceAll("_", " "),
                        value: relation,
                      }))}
                      className={"custom-select-menu"}
                      onChange={(e) => {
                        handleChange({
                          target: {
                            name: "relationshipType",
                            value: e.value,
                          },
                        });
                      }}
                      isSearchable={true}
                      classNamePrefix={"custom-select"}
                      value={{
                        label: relationshipType,
                        value: relationshipType,
                      }}
                    />
                    <span className="fixed-label"></span>
                    <span className="text-danger ">{errors?.relationship}</span>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <h4 className="mb-3">
                  What is the beneficiary's date of birth?
                </h4>
                <div className="form-group custom-datepicker">
                  <DatePicker
                    selected={dob ? new Date(dob) : ""}
                    maxDate={new Date()}
                    onChange={(date) =>
                      setBeneficiaryItem({
                        ...beneficiaryItem,
                        dob: `${
                          date.getMonth() + 1
                        }/${date.getDate()}/${date.getFullYear()}`,
                      })
                    }
                    placeholderText={"MM/DD/YYYY"}
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    dateFormatCalendar="MMMM"
                  />
                  <span className="datepicker-label">
                    {t("beneficiaryPage.date_of_birth")}
                  </span>
                  <span className="text-danger ">{errors?.dob}</span>
                </div>
              </div>
              {/* <div className="col-md-12">
                <h4 className="mb-3">What is their relationship to you?</h4>
                <div className="label-checkbox">
                  {relationshipTypeArr.map((rel, index) => {
                    return (
                      <label
                        htmlFor={rel}
                        key={index}
                        className={relationshipType === rel ? "active" : ""}
                      >
                        <input
                          type={"radio"}
                          id={rel}
                          name="relationshipType"
                          onChange={handleChange}
                          checked={relationshipType === rel}
                          value={rel}
                        />{" "}
                        {rel.replaceAll("_", " ")}
                      </label>
                    );
                  })}
                </div>
              </div> */}
              <div className="col-md-6">
                <h4 className="mb-3">What % share should they receive?</h4>
                <div className="form-group">
                  <div className="input-effect">
                    <input
                      className="form-control"
                      placeholder={"% share"}
                      name="percentage"
                      id="share"
                      type="text"
                      maxLength={3}
                      onChange={handleChange}
                      value={percentage}
                    />
                    <label htmlFor="share">{"% share"}</label>
                    <em className="input-icon"></em>
                    <span className="text-danger ">{errors?.percentage}</span>
                  </div>
                </div>
              </div>
              {/* {(relationship === "Parent" || relationship === "Sibling") && (
                <div className="col-md-6">
                  <div className="custom-checkbox mt-4">
                    <label
                      className="m-0"
                      style={{ fontSize: 16, color: "#000" }}
                      htmlFor={"confirm_checkbox"}
                    >
                      <input type="checkbox" id={"confirm_checkbox"} />
                      <span></span> * I confirm that this beneficiary would be
                      financially impacted by my death.
                    </label>
                  </div>
                </div>
              )} */}

              <div className="col-md-12 text-right">
                <button
                  className="btn btn-primary mr-3"
                  type="button"
                  onClick={toggleForm}
                >
                  Cancel
                </button>
                <button
                  className="btn btn-default"
                  type="button"
                  onClick={onSave}
                >
                  Save
                </button>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
      <h3 className="text-center mb-5">Beneficiary Information</h3>
      <Table responsive striped>
        <thead>
          <tr>
            <th>First Name</th>
            <th>Last Name</th>

            <th>Date of Birth</th>
            <th>Relationship</th>
            <th>Percentage</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {beneficiaryPerson.map((person, index) => {
            return (
              <tr key={index}>
                <td>{person.firstName}</td>
                <td>{person.lastName}</td>
                <td>{person.dob}</td>
                <td>{person.relationshipType.replaceAll("_", " ")}</td>
                <td>{person.percentage}</td>
                <td>
                  <button
                    className="btn btn-link mw-auto p-0"
                    onClick={() => onEdit(person, index)}
                  >
                    <img src="/assets/images/pencil.png" />
                  </button>
                  <button
                    className="btn btn-link"
                    onClick={() => onDelete(index)}
                  >
                    <img src="/assets/images/delete.png" />
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      <button className="btn btn-link text-primary" onClick={toggleForm}>
        Add Beneficiary
      </button>
      <div className="w-100 text-center mt-5">
        <button className="btn btn-default" onClick={onTableSubmit}>
          Next
        </button>
      </div>
      {/* <div className="w-100 text-center mt-5">
        <button
          className="btn btn-link"
          onClick={() => props.history.push("/sbli-terms")}
        >
          Back
        </button>
      </div> */}
    </div>
  );
};
const mapStateToProps = (state) => ({
  underwriting: state.underwriting.underwriting,
  progressCount: state.underwriting.progressCount,
  assurityCount: state.underwriting.assuProgressCount,
});

export default connect(mapStateToProps, {
  commonApiCall,
  setSbliProgressCount,
  setAssurityProgressCount,
})(withRouter(AssurityBeneficiary));
