import React, { useState, useEffect } from "react";
import { Animated } from "react-animated-css";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import DatePicker from "react-datepicker";
import Select from "react-select";
import { isEmpty } from "lodash";
import {
  UnderWritingRoutes,
  ApprovalStatus,
  setProcessStep,
  showErrorToast,
  decryptme,
} from "../../../utils";
import moment from "moment";
import {
  API_URL_CLIENT,
  API_URL_MUNICH,
  API_URL_UNDERWRITING,
} from "../../../config/configs";
import {
  commonApiCall,
  getJwt,
  FACT_FINDER_QUESTION,
  UNDERWRITING_DETAILS,
  ASSIGNED_AGENT,
} from "../../../redux/actions/index";

const FactFinderThird = ({
  commonApiCall,
  underwriting,
  factFinderQuestionList,
  consumer,
  t,
  ...props
}) => {
  const {
    applicationId,
    applicationType,
    policyType,
    goalType,
    carrierWiseLeads,
    isMedicalInfoAvailable,
  } = underwriting;

  const handleSelect = (date, index, opIndex) => {
    if (opIndex === 6) {
      data[index].options[opIndex].answer = moment(date).format("MM/DD/YYYY");
      error.errorData[6] = "";
      setError({
        ...error,
        error,
      });
    } else {
      data[index].options[opIndex].answer = date;
    }

    setFactFinderQuestions({
      ...factFinderQuestions,
      data,
    });
    error.errorData[opIndex + 7] = "";
    setError({
      ...error,
      error,
    });
  };
  useEffect(() => window.scrollTo(0, 0), []);
  useEffect(() => {
    async function doSubmit() {
      if (applicationId !== "") {
        const isAuthorized = getJwt() ? true : false;
        var requestParams = {
          applicationId: applicationId,
        };
        let response = await commonApiCall(
          API_URL_MUNICH + `getApplicationQuestionnaire`,
          "post",
          requestParams,
          "",
          isAuthorized
        );
        if (response && response.status.code === 200) {
          if (response.data.data.length > 8) {
            response.data.data.splice(0, 8);
            setFactFinderQuestions({
              ...factFinderQuestions,
              data: response.data.data,
            });
          }
        } else if (response && response.status.code === 404) {
          props.history.push(UnderWritingRoutes.FACT_FINDER);
        }
      }
    }
    doSubmit();
  }, []);
  const [isProcessing, setIsProcessing] = useState(false);
  const continueNext = async () => {
    const isAuthorized = getJwt() ? true : false;
    let { data } = factFinderQuestions;
    const result = data.filter((word) => word.answer === "");
    if (data[0].answer === "No" && data[0].options[5].answer === "") {
      return showErrorToast(t("validation_message.answers"));
    }
    if (
      (data[0].options[1].answer === "No" ||
        data[0].answer === "Non-resident Alien") &&
      data[0].options[6].answer === ""
    ) {
      return showErrorToast(t("validation_message.answers"));
    }
    if (result.length === 0) {
      if (validateFields()) {
        const splitpath = window.location.pathname.split("/");
        let arrMerrge = [];

        if (factFinderQuestionList.data.data.length <= 8) {
          arrMerrge = factFinderQuestionList.data.data.concat(data);
        } else if (factFinderQuestionList.data.data.length > 8) {
          let arrForShort = factFinderQuestionList.data.data.splice(0, 8);
          arrMerrge = arrForShort.concat(data);
        }
        const request = {
          resumeProcess: setProcessStep(splitpath[1], policyType, goalType),
          applicationType: applicationType,
          applicationId: applicationId,
          data: arrMerrge,
          clientUserId: consumer && consumer.id ? consumer.id : "",
        };
        setIsProcessing(true);
        const apiEndPoint = API_URL_MUNICH + "saveApplicationQuestionnaire";
        const response = await commonApiCall(
          apiEndPoint,
          "post",
          request,
          FACT_FINDER_QUESTION,
          isAuthorized
        );
        if (response.status && response.status.code === 200) {
          !isEmpty(carrierWiseLeads) &&
            carrierWiseLeads.map((carrierWiseLeadsOptions, i) => {
              if (!carrierWiseLeadsOptions.agentAssigned) {
                if (isMedicalInfoAvailable)
                  carrierWiseLeadsOptions.leadLevel = "Level-3";
                else carrierWiseLeadsOptions.leadLevel = "Level-2";
              }
            });
          const request_params = {
            resumeProcess: setProcessStep(splitpath[1], policyType, goalType),
            applicationType: applicationType,
            applicationId: applicationId,
            goalType: goalType,
            carrierWiseLeads: carrierWiseLeads,
          };

          const apiEndPoint_underwriting =
            API_URL_UNDERWRITING + "saveUnderWriting";
          const underwritingdetails = await commonApiCall(
            apiEndPoint_underwriting,
            "post",
            request_params,
            UNDERWRITING_DETAILS,
            isAuthorized
          );
          if (
            underwritingdetails.data.carrierWiseLeads[0]
              .continueApplyOnlineAvailable
          ) {
            props.history.push(UnderWritingRoutes.INFORMATION_OPTION);
          } else {
            const apiEndPoint_agentAssign =
              API_URL_UNDERWRITING + "saveAssignedAgent";
            const isAuthorized = getJwt() ? true : false;
            const request_param = { applicationId: applicationId };
            const response = await commonApiCall(
              apiEndPoint_agentAssign,
              "post",
              request_param,
              ASSIGNED_AGENT,
              isAuthorized
            );

            if (response.status && response.status.code === 200) {
              props.history.push(ApprovalStatus.APPLICATION_STATUS);
            } else {
              setIsProcessing(false);
              showErrorToast(response.status.message);
            }
          }
        }
      }
    } else {
      showErrorToast(t("validation_message.answers"));
    }
  };

  const [factFinderQuestions, setFactFinderQuestions] = useState({
    data: [
      {
        question: "us_citizen_or_permanent_resident_last_two_year",
        answer: "",
        options: [
          {
            question: "us_citizen_born_country",
            answer: "",
          },
          {
            question: "are_you_permanent_resident",
            answer: "",
          },
          {
            question: "us_citizen_born_state",
            answer: "",
          },
          {
            question: "us_citizen_citizenship_country",
            answer: "",
          },
          {
            question: "us_citizen_live_country",
            answer: "",
          },
          {
            question: "us_citizen_live_state",
            answer: "",
          },
          {
            question: "have_you_been_us",
            answer: "",
          },
          {
            question: "us_citizen_entry_date",
            answer: "",
          },
          {
            question: "us_citizen_for_how_long",
            answer: "",
          },
          {
            question: "us_alien_type_of_visa",
            answer: "",
          },
        ],
      },
      {
        question: "are_you_currently_employed",
        answer: "",
        options: [
          {
            question: "employer_name",
            answer: "",
          },
          {
            question: "employer_duration_year",
            answer: "",
          },
          {
            question: "employer_duration_month",
            answer: "",
          },
          {
            question: "employer_occupation",
            answer: "",
          },
          {
            question: "employer_nature_of_business",
            answer: "",
          },
        ],
      },
      {
        question: "policy_beneficiary",
        answer: "",
        options: [
          {
            question: "policy_beneficiary_name",
            answer: "",
          },
        ],
      },
    ],
  });

  const [error, setError] = useState({
    errorData: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""],
  });

  const validateFields = () => {
    if (
      data[0].answer === "Resident U.S. Citizen" ||
      data[0].answer === "Non-resident U.S. Citizen" ||
      data[0].options[1].answer === "Yes"
    ) {
      if (data[0].options[0].answer) {
        error.errorData[0] = "";
      } else {
        error.errorData[0] = t(
          "fact_finder_third.please_enter_where_were_you_born"
        );
      }
    }

    if (data[0].answer === "Resident Alien") {
      if (data[0].options[1].answer) {
        error.errorData[13] = "";
      } else {
        error.errorData[13] = t(
          "fact_finder_third.please_select_if_you_re_a_permanent_resident"
        );
      }
      if (data[0].options[3].answer) {
        error.errorData[2] = "";
      } else {
        error.errorData[2] = t("fact_finder_third.please_enter_country");
      }
      if (data[0].options[9].answer) {
        error.errorData[14] = "";
      } else {
        error.errorData[14] = t("fact_finder_third.please_enter_visa_type");
      }
    }

    if (
      data[0].options[1].answer === "No" ||
      data[0].answer === "Non-resident Alien"
    ) {
      if (data[0].options[0].answer) {
        error.errorData[0] = "";
      } else {
        error.errorData[0] = t("fact_finder_third.please_enter_country");
      }
      if (data[0].options[2].answer) {
        error.errorData[1] = "";
      } else {
        error.errorData[1] = t("fact_finder_third.please_enter_state");
      }
      if (data[0].options[3].answer) {
        error.errorData[2] = "";
      } else {
        error.errorData[2] = t("fact_finder_third.please_enter_country");
      }
      if (data[0].options[4].answer) {
        error.errorData[3] = "";
      } else {
        error.errorData[3] = t("fact_finder_third.please_enter_country");
      }
      if (data[0].options[5].answer) {
        error.errorData[4] = "";
      } else {
        error.errorData[4] = t("fact_finder_third.please_enter_state");
      }
      if (data[0].options[9].answer) {
        error.errorData[14] = "";
      } else {
        error.errorData[14] = t("fact_finder_third.please_enter_visa_type");
      }
      if (data[0].options[6].answer === "Yes") {
        if (data[0].options[7].answer) {
          error.errorData[6] = "";
        } else {
          error.errorData[6] = t("fact_finder_third.please_select_date");
        }
      }
    }
    if (data[1].answer === "Yes") {
      if (data[1].options[0].answer) {
        error.errorData[7] = "";
      } else {
        error.errorData[7] = t("fact_finder_third.please_enter_employer_name");
      }
      if (data[1].options[1].answer) {
        error.errorData[8] = "";
      } else {
        error.errorData[8] = t(
          "fact_finder_third.please_select_duration_in_years"
        );
      }
      if (data[1].options[2].answer) {
        error.errorData[9] = "";
      } else {
        error.errorData[9] = t(
          "fact_finder_third.please_select_duration_in_months"
        );
      }
      if (data[1].options[3].answer) {
        error.errorData[10] = "";
      } else {
        error.errorData[10] = t("fact_finder_third.please_enter_occupation");
      }

      if (data[1].options[4].answer) {
        error.errorData[11] = "";
      } else {
        error.errorData[11] = t(
          "fact_finder_third.please_enter_nature_of_business"
        );
      }
    }
    if (data[2].answer === "Decide Now") {
      if (data[2].options[0].answer) {
        error.errorData[12] = "";
      } else {
        error.errorData[12] = t(
          "fact_finder_third.please_enter_beneficiary_name"
        );
      }
    }
    setError({
      ...error,
      error,
    });

    return getErrorCheck();
  };

  const getErrorCheck = () => {
    let result = error.errorData.filter((word) => word !== "");
    if (result.length === 0) {
      return true;
    } else {
      return false;
    }
  };

  const [countryList, setCountryList] = useState({
    getCountryList: [],
  });

  const [stateList1, setStateList] = useState({
    stateList1: [],
  });
  const [stateList2, setStateList2] = useState({
    stateList2: [],
  });

  const Years = [
    { value: "0 Year", label: "0 Year" },
    { value: "1 Year", label: "1 Year" },
    { value: "2 Years", label: "2 Years" },
    { value: "3 Years", label: "3 Years" },
    { value: "4 Years", label: "4 Years" },
    { value: "5 Years", label: "5 Years" },
    { value: "6 Years", label: "6 Years" },
    { value: "7 Years", label: "7 Years" },
    { value: "8 Years", label: "8 Years" },
    { value: "9 Years", label: "9 Years" },
    { value: "10 Years", label: "10 Years" },
    { value: "11 Years", label: "11 Years" },
    { value: "12 Years", label: "12 Years" },
    { value: "13 Years", label: "13 Years" },
    { value: "14 Years", label: "14 Years" },
    { value: "15 Years", label: "15 Years" },
    { value: "16 Years", label: "16 Years" },
    { value: "17 Years", label: "17 Years" },
    { value: "18 Years", label: "18 Years" },
    { value: "19 Years", label: "19 Years" },
    { value: "20 Years", label: "20 Years" },
    { value: "21 Years", label: "21 Years" },
    { value: "22 Years", label: "22 Years" },
    { value: "23 Years", label: "23 Years" },
    { value: "24 Years", label: "24 Years" },
    { value: "25 Years", label: "25 Years" },
    { value: "26 Years", label: "26 Years" },
    { value: "27 Years", label: "27 Years" },
    { value: "28 Years", label: "28 Years" },
    { value: "29 Years", label: "29 Years" },
    { value: "30 Years", label: "30 Years" },
    { value: "31 Years", label: "31 Years" },
    { value: "32 Years", label: "32 Years" },
    { value: "33 Years", label: "33 Years" },
    { value: "34 Years", label: "34 Years" },
    { value: "35 Years", label: "35 Years" },
    { value: "36 Years", label: "36 Years" },
    { value: "37 Years", label: "37 Years" },
    { value: "38 Years", label: "38 Years" },
    { value: "39 Years", label: "39 Years" },
    { value: "40 Years", label: "40 Years" },
  ];
  const Months = [
    { value: "0 Month", label: "0 Month" },
    { value: "1 Month", label: "1 Month" },
    { value: "2 Months", label: "2 Months" },
    { value: "3 Months", label: "3 Months" },
    { value: "4 Months", label: "4 Months" },
    { value: "5 Months", label: "5 Months" },
    { value: "6 Months", label: "6 Months" },
    { value: "7 Months", label: "7 Months" },
    { value: "8 Months", label: "8 Months" },
    { value: "9 Months", label: "9 Months" },
    { value: "10 Months", label: "10 Months" },
    { value: "11 Months", label: "11 Months" },
  ];

  const getCountryList = async () => {
    try {
      const isAuthorized = getJwt() ? true : false;
      let response = await commonApiCall(
        API_URL_CLIENT + `getCountry`,
        "get",
        "",
        "",
        isAuthorized
      );

      if (response.status.code === 200) {
        let getCountryList = response.data.map((list) => {
          return {
            value: list.id,
            label: list.name,
          };
        });
        setCountryList({
          ...countryList,
          getCountryList,
        });
      }
    } catch (err) {}
  };

  let { data } = factFinderQuestions;
  return (
    <React.Fragment>
      <div className="register-steps">
        <div className="row justify-content-center">
          <div className="col-lg-10">
            <div className="title-header">
              <h2>
                {t(
                  "fact_finder_third.tell_us_more_about_your_health_lifestyle"
                )}
              </h2>
            </div>
            <div className="register-steps-wrapper">
              <Animated
                animationIn="fadeInRight"
                animationOut="fadeOutLeft"
                animationInDuration={800}
                animationOutDuration={800}
                isVisible={true}
              >
                <div className="form-wrapper">
                  <form>
                    <div className="register-form-block">
                      <h3 className="form-text card-text mt-0 mb-3 text-center">
                        {t(
                          "fact_finder_third.whats_your_legal_status_in_the_u_s"
                        )}
                      </h3>
                      <div className="form-block radio-block">
                        <div className="form-group fact-finder-third-question-option1">
                          <div className="custom-radio">
                            <label className="m-0" htmlFor="resident_citizen">
                              <input
                                type="radio"
                                id="resident_citizen"
                                name="tobacco"
                                checked={
                                  data[0].answer === "Resident U.S. Citizen"
                                    ? true
                                    : false
                                }
                                onChange={() => {
                                  data[0].answer = "Resident U.S. Citizen";
                                  data[0].options[0].answer = "";
                                  data[0].options[1].answer = "";
                                  data[0].options[2].answer = "";
                                  data[0].options[3].answer = "";
                                  data[0].options[4].answer = "";
                                  data[0].options[5].answer = "";
                                  data[0].options[6].answer = "";
                                  data[0].options[7].answer = "";
                                  data[0].options[8].answer = "";
                                  data[0].options[9].answer = "";
                                  setFactFinderQuestions({
                                    ...factFinderQuestions,
                                    data,
                                  });
                                  error.errorData[0] = "";
                                  error.errorData[1] = "";
                                  error.errorData[2] = "";
                                  error.errorData[3] = "";
                                  error.errorData[4] = "";
                                  stateList1.stateListMap = [];
                                  setStateList({
                                    ...stateList1,
                                    stateList1,
                                  });
                                  stateList2.stateListMap = [];
                                  setStateList2({
                                    ...stateList2,
                                    stateList2,
                                  });
                                  setError({
                                    ...error,
                                    error,
                                  });
                                }}
                              />
                              <span></span>{" "}
                              {t("fact_finder_third.resident_u_s_citizen")}
                            </label>
                          </div>
                        </div>
                        <div className="form-group fact-finder-third-question-option2">
                          <div className="custom-radio">
                            <label
                              className="m-0"
                              htmlFor="non_resident_citizen"
                            >
                              <input
                                type="radio"
                                id="non_resident_citizen"
                                name="tobacco"
                                checked={
                                  data[0].answer === "Non-resident U.S. Citizen"
                                    ? true
                                    : false
                                }
                                onChange={() => {
                                  data[0].answer = "Non-resident U.S. Citizen";
                                  data[0].options[0].answer = "";
                                  data[0].options[1].answer = "";
                                  data[0].options[2].answer = "";
                                  data[0].options[3].answer = "";
                                  data[0].options[4].answer = "";
                                  data[0].options[5].answer = "";
                                  data[0].options[6].answer = "";
                                  data[0].options[7].answer = "";
                                  data[0].options[8].answer = "";
                                  data[0].options[9].answer = "";
                                  setFactFinderQuestions({
                                    ...factFinderQuestions,
                                    data,
                                  });
                                  error.errorData[0] = "";
                                  error.errorData[1] = "";
                                  error.errorData[2] = "";
                                  error.errorData[3] = "";
                                  error.errorData[4] = "";
                                  stateList1.stateListMap = [];
                                  setStateList({
                                    ...stateList1,
                                    stateList1,
                                  });
                                  stateList2.stateListMap = [];
                                  setStateList2({
                                    ...stateList2,
                                    stateList2,
                                  });
                                  setError({
                                    ...error,
                                    error,
                                  });
                                }}
                              />
                              <span></span>{" "}
                              {t("fact_finder_third.non_resident_u_s_citizen")}
                            </label>
                          </div>
                        </div>
                        <div className="form-group fact-finder-third-question-option3">
                          <div className="custom-radio">
                            <label className="m-0" htmlFor="resident_alien">
                              <input
                                type="radio"
                                id="resident_alien"
                                name="tobacco"
                                onClick={() => getCountryList()}
                                checked={
                                  data[0].answer === "Resident Alien"
                                    ? true
                                    : false
                                }
                                onChange={() => {
                                  data[0].answer = "Resident Alien";
                                  data[0].options[0].answer = "";
                                  data[0].options[1].answer = "";
                                  data[0].options[2].answer = "";
                                  data[0].options[3].answer = "";
                                  data[0].options[4].answer = "";
                                  data[0].options[5].answer = "";
                                  data[0].options[6].answer = "";
                                  data[0].options[7].answer = "";
                                  data[0].options[8].answer = "";
                                  data[0].options[9].answer = "";
                                  setFactFinderQuestions({
                                    ...factFinderQuestions,
                                    data,
                                  });
                                  error.errorData[0] = "";
                                  setError({
                                    ...error,
                                    error,
                                  });
                                }}
                              />
                              <span></span>{" "}
                              {t("fact_finder_third.resident_alien")}
                            </label>
                          </div>
                        </div>
                        <div className="form-group fact-finder-third-question-option4">
                          <div className="custom-radio">
                            <label className="m-0" htmlFor="non_resident_alien">
                              <input
                                type="radio"
                                id="non_resident_alien"
                                name="tobacco"
                                onClick={() => getCountryList()}
                                checked={
                                  data[0].answer === "Non-resident Alien"
                                    ? true
                                    : false
                                }
                                onChange={() => {
                                  data[0].answer = "Non-resident Alien";
                                  data[0].options[0].answer = "";
                                  data[0].options[1].answer = "";
                                  data[0].options[2].answer = "";
                                  data[0].options[3].answer = "";
                                  data[0].options[4].answer = "";
                                  data[0].options[5].answer = "";
                                  data[0].options[6].answer = "";
                                  data[0].options[7].answer = "";
                                  data[0].options[8].answer = "";
                                  data[0].options[9].answer = "";
                                  setFactFinderQuestions({
                                    ...factFinderQuestions,
                                    data,
                                  });
                                  error.errorData[0] = "";
                                  setError({
                                    ...error,
                                    error,
                                  });
                                }}
                              />
                              <span></span>{" "}
                              {t("fact_finder_third.non_resident_alien")}
                            </label>
                          </div>
                        </div>
                      </div>
                      {/*End: Form Group */}

                      {data[0].answer === "Resident Alien" && (
                        <div className="form-block-questions register-step3">
                          <h3 className="form-text card-text mt-4 mb-3 text-center">
                            {t(
                              "fact_finder_third.are_you_a_permanent_resident"
                            )}
                          </h3>
                          <div className="form-block radio-block">
                            <div className="form-group">
                              <div className="custom-radio">
                                <label
                                  className="m-0"
                                  htmlFor="yes_permanent_resident"
                                >
                                  <input
                                    type="radio"
                                    id="yes_permanent_resident"
                                    name="yes_permanent_resident"
                                    checked={
                                      data[0].options[1].answer === "Yes"
                                        ? true
                                        : false
                                    }
                                    onChange={() => {
                                      data[0].options[0].answer = "";
                                      data[0].options[1].answer = "Yes";
                                      data[0].options[2].answer = "";
                                      data[0].options[3].answer = "";
                                      data[0].options[4].answer = "";
                                      data[0].options[5].answer = "";
                                      data[0].options[6].answer = "";
                                      data[0].options[7].answer = "";
                                      data[0].options[8].answer = "";
                                      data[0].options[9].answer = "";
                                      setFactFinderQuestions({
                                        ...factFinderQuestions,
                                        data,
                                      });
                                      error.errorData[13] = "";
                                      setError({
                                        ...error,
                                        error,
                                      });
                                    }}
                                  />
                                  <span></span> {t("fact_finder_third.yes")}
                                </label>
                              </div>
                            </div>
                            <div className="form-group">
                              <div className="custom-radio">
                                <label
                                  className="m-0"
                                  htmlFor="no_permanent_resident"
                                >
                                  <input
                                    type="radio"
                                    id="no_permanent_resident"
                                    name="no_permanent_resident"
                                    checked={
                                      data[0].options[1].answer === "No"
                                        ? true
                                        : false
                                    }
                                    onChange={() => {
                                      data[0].options[0].answer = "";
                                      data[0].options[1].answer = "No";
                                      data[0].options[2].answer = "";
                                      data[0].options[3].answer = "";
                                      data[0].options[4].answer = "";
                                      data[0].options[5].answer = "";
                                      data[0].options[6].answer = "";
                                      data[0].options[7].answer = "";
                                      data[0].options[8].answer = "";
                                      data[0].options[9].answer = "";
                                      setFactFinderQuestions({
                                        ...factFinderQuestions,
                                        data,
                                      });
                                      error.errorData[13] = "";
                                      setError({
                                        ...error,
                                        error,
                                      });
                                    }}
                                  />
                                  <span></span> {t("fact_finder_third.no")}
                                </label>
                              </div>
                            </div>
                          </div>
                          <span className="text-danger">
                            {error.errorData[13]}
                          </span>
                        </div>
                      )}

                      {data[0].answer === "Resident U.S. Citizen" ||
                      data[0].answer === "Non-resident U.S. Citizen" ? (
                        <div className="form-block-questions">
                          <div className="questions-wrapper">
                            <div className="form-group full">
                              <div className="input-effect">
                                <input
                                  className="form-control"
                                  placeholder="Where Were You Born ( Country, i.e. U.S.)"
                                  name="where-were-you-born"
                                  id="where-were-you-born"
                                  value={data[0].options[0].answer}
                                  onChange={(e) => {
                                    data[0].options[0].answer = e.target.value;
                                    setFactFinderQuestions({
                                      ...factFinderQuestions,
                                      data,
                                    });
                                    error.errorData[0] = "";
                                    setError({
                                      ...error,
                                      error,
                                    });
                                  }}
                                  type="text"
                                />
                                <label htmlFor="Country">
                                  {/* Where Were You Born ( Country, i.e. U.S.) */}
                                  {t("fact_finder_third.place_of_birth")}
                                </label>
                                <span className="text-danger">
                                  {error.errorData[0]}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : null}

                      {data[0].options[1].answer === "Yes" ? (
                        <div className="form-block-questions">
                          <div className="questions-wrapper">
                            <div className="form-group full">
                              <div className="input-effect">
                                <input
                                  className="form-control"
                                  placeholder="Where Were You Born ( Country, i.e. U.S.)"
                                  name="where-were-you-born"
                                  id="where-were-you-born"
                                  value={data[0].options[0].answer}
                                  onChange={(e) => {
                                    data[0].options[0].answer = e.target.value;
                                    setFactFinderQuestions({
                                      ...factFinderQuestions,
                                      data,
                                    });
                                    error.errorData[0] = "";
                                    setError({
                                      ...error,
                                      error,
                                    });
                                  }}
                                  type="text"
                                />
                                <label htmlFor="Country">
                                  {/* Where Were You Born ( Country, i.e. U.S.) */}
                                  {t("fact_finder_third.place_of_birth")}
                                </label>
                                <span className="text-danger">
                                  {error.errorData[0]}
                                </span>
                              </div>
                            </div>
                          </div>
                          <form>
                            <h3 className="form-text card-text mt-4 mb-3 text-center">
                              {t("fact_finder_third.whats_your_citizenship")}
                            </h3>
                            <div className="questions-wrapper">
                              <div className="form-group fixed-floating-label">
                                <div className="input-effect">
                                  <input
                                    className="form-control"
                                    placeholder="Country"
                                    name="Country"
                                    id="Country"
                                    value={data[0].options[3].answer}
                                    onChange={(e) => {
                                      data[0].options[3].answer =
                                        e.target.value;
                                      setFactFinderQuestions({
                                        ...factFinderQuestions,
                                        data,
                                      });
                                      error.errorData[2] = "";
                                      setError({
                                        ...error,
                                        error,
                                      });
                                    }}
                                    type="text"
                                  />
                                  <label htmlFor="Country">
                                    {t("fact_finder_third.country")}
                                  </label>
                                  <span className="text-danger ">
                                    {error.errorData[2]}
                                  </span>
                                </div>
                              </div>
                            </div>
                            <h3 className="form-text card-text mt-4 mb-3 text-center">
                              {t("fact_finder_third.whats_your_visa_type")}
                            </h3>
                            <div className="questions-wrapper">
                              <div className="form-group fixed-floating-label">
                                <div className="input-effect">
                                  <input
                                    className="form-control"
                                    placeholder="State"
                                    name="State"
                                    id="State"
                                    value={data[0].options[9].answer}
                                    onChange={(e) => {
                                      data[0].options[9].answer =
                                        e.target.value;
                                      setFactFinderQuestions({
                                        ...factFinderQuestions,
                                        data,
                                      });
                                      error.errorData[14] = "";
                                      setError({
                                        ...error,
                                        error,
                                      });
                                    }}
                                    type="text"
                                  />
                                  <label htmlFor="Country">
                                    {t("fact_finder_third.type_of_visa")}
                                  </label>
                                  <span className="text-danger ">
                                    {error.errorData[14]}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      ) : null}

                      {data[0].options[1].answer === "No" ||
                      data[0].answer === "Non-resident Alien" ? (
                        <div className="form-block-questions register-step3">
                          <h3 className="form-text card-text mt-4 mb-3 text-center">
                            {t("fact_finder_third.where_were_you_born")}
                          </h3>
                          <div className="questions-wrapper">
                            <div className="form-group fixed-floating-label">
                              <div className="default-select with-border">
                                <div className="input-effect">
                                  <input
                                    className="form-control"
                                    placeholder="Country"
                                    name="Country"
                                    id="Country"
                                    value={data[0].options[0].answer}
                                    onChange={(e) => {
                                      data[0].options[0].answer =
                                        e.target.value;
                                      setFactFinderQuestions({
                                        ...factFinderQuestions,
                                        data,
                                      });
                                      error.errorData[0] = "";
                                      setError({
                                        ...error,
                                        error,
                                      });
                                    }}
                                    type="text"
                                  />
                                  <label htmlFor="Country">
                                    {t("fact_finder_third.country")}
                                  </label>
                                  <span className="text-danger ">
                                    {error.errorData[0]}
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="form-group fixed-floating-label">
                              <div className="input-effect">
                                <input
                                  className="form-control"
                                  placeholder="State"
                                  name="State"
                                  id="State"
                                  value={data[0].options[2].answer}
                                  onChange={(e) => {
                                    data[0].options[2].answer = e.target.value;
                                    setFactFinderQuestions({
                                      ...factFinderQuestions,
                                      data,
                                    });
                                    error.errorData[1] = "";
                                    setError({
                                      ...error,
                                      error,
                                    });
                                  }}
                                  type="text"
                                />
                                <label htmlFor="State">
                                  {t("fact_finder_third.state")}
                                </label>
                                <span className="text-danger ">
                                  {error.errorData[1]}
                                </span>
                              </div>
                            </div>
                          </div>
                          <h3 className="form-text card-text mt-4 mb-3 text-center">
                            {t("fact_finder_third.whats_your_citizenship")}
                          </h3>
                          <div className="questions-wrapper">
                            <div className="form-group fixed-floating-label">
                              <div className="input-effect">
                                <input
                                  className="form-control"
                                  placeholder="Country"
                                  name="Country"
                                  id="Country"
                                  value={data[0].options[3].answer}
                                  onChange={(e) => {
                                    data[0].options[3].answer = e.target.value;
                                    setFactFinderQuestions({
                                      ...factFinderQuestions,
                                      data,
                                    });
                                    error.errorData[2] = "";
                                    setError({
                                      ...error,
                                      error,
                                    });
                                  }}
                                  type="text"
                                />
                                <label htmlFor="Country">
                                  {t("fact_finder_third.country")}
                                </label>
                                <span className="text-danger ">
                                  {error.errorData[2]}
                                </span>
                              </div>
                            </div>
                          </div>
                          <h3 className="form-text card-text mt-4 mb-3 text-center">
                            {t("fact_finder_third.whats_your_visa_type")}
                          </h3>
                          <div className="questions-wrapper">
                            <div className="form-group fixed-floating-label">
                              <div className="input-effect">
                                <input
                                  className="form-control"
                                  placeholder="State"
                                  name="State"
                                  id="State"
                                  value={data[0].options[9].answer}
                                  onChange={(e) => {
                                    data[0].options[9].answer = e.target.value;
                                    setFactFinderQuestions({
                                      ...factFinderQuestions,
                                      data,
                                    });
                                    error.errorData[14] = "";
                                    setError({
                                      ...error,
                                      error,
                                    });
                                  }}
                                  type="text"
                                />
                                <label htmlFor="Country">
                                  {t("fact_finder_third.type_of_visa")}
                                </label>
                                <span className="text-danger ">
                                  {error.errorData[14]}
                                </span>
                              </div>
                            </div>
                          </div>
                          <h3 className="form-text card-text mt-4 mb-3 text-center">
                            {" "}
                            {t("fact_finder_third.where_do_you_live")}
                          </h3>
                          <div className="questions-wrapper">
                            <div className="form-group fixed-floating-label">
                              <div className="input-effect">
                                <input
                                  className="form-control"
                                  placeholder="Country"
                                  name="Country"
                                  id="Country"
                                  value={data[0].options[4].answer}
                                  onChange={(e) => {
                                    data[0].options[4].answer = e.target.value;
                                    setFactFinderQuestions({
                                      ...factFinderQuestions,
                                      data,
                                    });
                                    error.errorData[3] = "";
                                    setError({
                                      ...error,
                                      error,
                                    });
                                  }}
                                  type="text"
                                />
                                <label htmlFor="Country">
                                  {t("fact_finder_third.country")}
                                </label>
                                <span className="text-danger ">
                                  {error.errorData[3]}
                                </span>
                              </div>
                            </div>
                            <div className="form-group fixed-floating-label">
                              <div className="input-effect">
                                <input
                                  className="form-control"
                                  placeholder="State"
                                  name="State"
                                  id="State"
                                  value={data[0].options[5].answer}
                                  onChange={(e) => {
                                    data[0].options[5].answer = e.target.value;
                                    setFactFinderQuestions({
                                      ...factFinderQuestions,
                                      data,
                                    });
                                    error.errorData[4] = "";
                                    setError({
                                      ...error,
                                      error,
                                    });
                                  }}
                                  type="text"
                                />
                                <label htmlFor="State">
                                  {t("fact_finder_third.state")}
                                </label>
                                <span className="text-danger ">
                                  {error.errorData[4]}
                                </span>
                              </div>
                            </div>
                          </div>
                          <h3 className="form-text card-text mt-4 mb-3 text-center">
                            {t("fact_finder_third.have_you_been_to_u_s")}
                          </h3>
                          <div className="form-block radio-block">
                            <div className="form-group">
                              <div className="custom-radio">
                                <label className="m-0" htmlFor="yes2">
                                  <input
                                    type="radio"
                                    id="yes2"
                                    name="beenus"
                                    checked={
                                      data[0].options[6].answer === "Yes"
                                        ? true
                                        : false
                                    }
                                    onChange={() => {
                                      data[0].options[6].answer = "Yes";
                                      data[0].options[7].answer = "";
                                      setFactFinderQuestions({
                                        ...factFinderQuestions,
                                        data,
                                      });
                                    }}
                                  />
                                  <span></span> {t("fact_finder_third.yes")}
                                </label>
                              </div>
                            </div>
                            <div className="form-group">
                              <div className="custom-radio">
                                <label className="m-0" htmlFor="no2">
                                  <input
                                    type="radio"
                                    id="no2"
                                    name="beenus"
                                    checked={
                                      data[0].options[6].answer === "No"
                                        ? true
                                        : false
                                    }
                                    onChange={() => {
                                      data[0].options[6].answer = "No";
                                      data[0].options[7].answer = "";
                                      data[0].options[8].answer = "";

                                      setFactFinderQuestions({
                                        ...factFinderQuestions,
                                        data,
                                      });
                                      error.errorData[6] = "";
                                      setError({
                                        ...error,
                                        error,
                                      });
                                    }}
                                  />
                                  <span></span> {t("fact_finder_third.no")}
                                </label>
                              </div>
                            </div>
                          </div>
                          {data[0].options[6].answer === "Yes" ? (
                            <div className="form-block-questions pb-0">
                              <div className="questions-wrapper">
                                <div className="form-group custom-datepicker">
                                  <DatePicker
                                    selected={
                                      data[0].options[7].answer === ""
                                        ? ""
                                        : new Date(data[0].options[7].answer)
                                    }
                                    onChange={(date) =>
                                      handleSelect(date, 0, 7)
                                    }
                                    placeholderText={"MM/DD/YYYY"}
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    dateFormatCalendar="MMMM"
                                    maxDate={new Date()}
                                    minDate={
                                      new Date(decryptme(underwriting.dob))
                                    }
                                  />
                                  <span className="datepicker-label">
                                    {t("fact_finder_third.date_of_entry")}
                                  </span>
                                  <span className="text-danger ">
                                    {error.errorData[6]}
                                  </span>
                                </div>
                              </div>
                            </div>
                          ) : null}
                        </div>
                      ) : null}
                    </div>
                  </form>
                </div>
              </Animated>
              {/*End: Animate.css Element */}
            </div>
            {/*End: About your health Form Wrapper */}
          </div>
        </div>
      </div>

      <div className="register-steps register-step3">
        <div className="row justify-content-center">
          <div className="col-lg-10">
            {/*End: Page Title */}
            {/*Begin: About your health Form Wrapper */}
            <div className="register-steps-wrapper">
              {/*Begin: Animate.css Element */}
              <Animated
                animationIn="fadeInRight"
                animationOut="fadeOutLeft"
                animationInDuration={800}
                animationOutDuration={800}
                isVisible={true}
              >
                <div className="form-wrapper">
                  <form>
                    <div className="register-form-block">
                      <h3 className="form-text card-text mt-0 mb-3 text-center">
                        {t("fact_finder_third.are_you_currently_employed")}
                      </h3>
                      {/*Begin: Form Group */}
                      <div className="form-block radio-block">
                        <div className="form-group">
                          <div className="custom-radio">
                            <label className="m-0" htmlFor="drugs">
                              <input
                                type="radio"
                                id="drugs"
                                name="drugs"
                                checked={
                                  data[1].answer === "Yes" ? true : false
                                }
                                onChange={() => {
                                  data[1].answer = "Yes";
                                  setFactFinderQuestions({
                                    ...factFinderQuestions,
                                    data,
                                  });
                                }}
                              />
                              <span></span> {t("fact_finder_third.yes")}
                            </label>
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="custom-radio">
                            <label className="m-0" htmlFor="nodrugs">
                              <input
                                type="radio"
                                id="nodrugs"
                                name="drugs"
                                checked={data[1].answer === "No" ? true : false}
                                onChange={() => {
                                  data[1].answer = "No";
                                  data[1].options[0].answer = "";
                                  data[1].options[1].answer = "";
                                  data[1].options[2].answer = "";
                                  data[1].options[3].answer = "";
                                  data[1].options[4].answer = "";
                                  setFactFinderQuestions({
                                    ...factFinderQuestions,
                                    data,
                                  });
                                  error.errorData[7] = "";
                                  error.errorData[8] = "";
                                  error.errorData[9] = "";
                                  error.errorData[10] = "";
                                  error.errorData[11] = "";
                                  setError({
                                    ...error,
                                    error,
                                  });
                                }}
                              />
                              <span></span> {t("fact_finder_third.no")}
                            </label>
                          </div>
                        </div>
                      </div>
                      {/*End: Form Group */}
                      {data[1].answer === "Yes" ? (
                        <div className="form-block-questions register-step3">
                          <div className="questions-wrapper">
                            <div className="form-group">
                              <div className="input-effect">
                                <input
                                  className="form-control"
                                  placeholder="Employer name"
                                  name="employer-name"
                                  id="employer-name"
                                  type="text"
                                  value={data[1].options[0].answer}
                                  onChange={(e) => {
                                    data[1].options[0].answer = e.target.value;
                                    setFactFinderQuestions({
                                      ...factFinderQuestions,
                                      data,
                                    });
                                    error.errorData[7] = "";
                                    setError({
                                      ...error,
                                      error,
                                    });
                                  }}
                                />
                                <label htmlFor="employer-name">
                                  {t("fact_finder_third.employer_name")}
                                </label>
                                <span className="text-danger ">
                                  {error.errorData[7]}
                                </span>
                              </div>
                            </div>
                            <div className="form-group fixed-floating-label">
                              <div className="default-select with-border">
                                <Select
                                  value={data[1].options[1].answer}
                                  onChange={(val) => handleSelect(val, 1, 1)}
                                  options={Years}
                                  className={"custom-select-menu"}
                                  classNamePrefix={"custom-select"}
                                  placeholder={t(
                                    "fact_finder_third.duration_in_years"
                                  )}
                                />
                              </div>
                              <span className="fixed-label">
                                {t("fact_finder_third.duration_in_years")}
                              </span>
                              <span className="text-danger ">
                                {error.errorData[8]}
                              </span>
                            </div>
                            <div className="form-group fixed-floating-label">
                              <div className="default-select with-border">
                                <Select
                                  value={data[1].options[2].answer}
                                  onChange={(val) => handleSelect(val, 1, 2)}
                                  options={Months}
                                  className={"custom-select-menu"}
                                  classNamePrefix={"custom-select"}
                                  placeholder={t(
                                    "fact_finder_third.duration_in_months"
                                  )}
                                />
                              </div>
                              <span className="fixed-label">
                                {t("fact_finder_third.duration_in_months")}
                              </span>
                              <span className="text-danger ">
                                {error.errorData[9]}
                              </span>
                            </div>

                            <div className="form-group">
                              <div className="input-effect">
                                <input
                                  className="form-control"
                                  placeholder={t(
                                    "fact_finder_third.occupation"
                                  )}
                                  name="occupation"
                                  id="occupation"
                                  type="text"
                                  value={data[1].options[3].answer}
                                  onChange={(e) => {
                                    data[1].options[3].answer = e.target.value;
                                    setFactFinderQuestions({
                                      ...factFinderQuestions,
                                      data,
                                    });
                                    error.errorData[10] = "";
                                    setError({
                                      ...error,
                                      error,
                                    });
                                  }}
                                />
                                <label htmlFor="face-amount">
                                  {t("fact_finder_third.occupation")}
                                </label>
                                <span className="text-danger ">
                                  {error.errorData[10]}
                                </span>
                              </div>
                            </div>
                            <div className="form-group">
                              <div className="input-effect">
                                <input
                                  className="form-control"
                                  placeholder="Nature of Business"
                                  name="nature-of-business"
                                  id="nature-of-business"
                                  type="text"
                                  value={data[1].options[4].answer}
                                  onChange={(e) => {
                                    data[1].options[4].answer = e.target.value;
                                    setFactFinderQuestions({
                                      ...factFinderQuestions,
                                      data,
                                    });
                                    error.errorData[11] = "";
                                    setError({
                                      ...error,
                                      error,
                                    });
                                  }}
                                />
                                <label htmlFor="face-amount">
                                  {t("fact_finder_third.nature_of_business")}
                                </label>
                                <span className="text-danger ">
                                  {error.errorData[11]}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </form>
                </div>
              </Animated>
              {/*End: Animate.css Element */}
            </div>
            {/*End: About your health Form Wrapper */}
          </div>
        </div>
      </div>

      <div className="register-steps register-step3">
        <div className="row justify-content-center">
          <div className="col-lg-10">
            {/*Begin: About your health Form Wrapper */}
            <div className="register-steps-wrapper">
              {/*Begin: Animate.css Element */}
              <Animated
                animationIn="fadeInRight"
                animationOut="fadeOutLeft"
                animationInDuration={800}
                animationOutDuration={800}
                isVisible={true}
              >
                <div className="form-wrapper">
                  <form>
                    <div className="register-form-block">
                      <h3 className="form-text card-text mt-0 mb-3 text-center">
                        {t(
                          "fact_finder_third.who_will_be_the_policy_beneficiary"
                        )}
                      </h3>
                      {/*Begin: Form Group */}
                      <div className="form-block radio-block">
                        <div className="form-group">
                          <div className="custom-radio">
                            <label className="m-0" htmlFor="travelled">
                              <input
                                type="radio"
                                id="travelled"
                                name="travelled"
                                checked={
                                  data[2].answer === "Decide Now" ? true : false
                                }
                                onChange={() => {
                                  data[2].answer = "Decide Now";

                                  setFactFinderQuestions({
                                    ...factFinderQuestions,
                                    data,
                                  });
                                }}
                              />
                              <span></span> {t("fact_finder_third.decide_now")}
                            </label>
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="custom-radio">
                            <label className="m-0" htmlFor="notravelled">
                              <input
                                type="radio"
                                id="notravelled"
                                name="travelled"
                                checked={
                                  data[2].answer === "Decide Later"
                                    ? true
                                    : false
                                }
                                onChange={() => {
                                  data[2].answer = "Decide Later";
                                  data[2].options[0].answer = "";

                                  setFactFinderQuestions({
                                    ...factFinderQuestions,
                                    data,
                                  });
                                  error.errorData[12] = "";
                                  setError({
                                    ...error,
                                    error,
                                  });
                                }}
                              />
                              <span></span>{" "}
                              {t("fact_finder_third.decide_later")}
                            </label>
                          </div>
                        </div>
                      </div>
                      {/*End: Form Group */}
                      {data[2].answer === "Decide Now" ? (
                        <div className="form-block-questions">
                          <div className="questions-wrapper">
                            <div className="form-group full">
                              <div className="input-effect">
                                <input
                                  className="form-control"
                                  placeholder="Name"
                                  name="decide-name"
                                  id="decide-name"
                                  type="text"
                                  value={data[2].options[0].answer}
                                  onChange={(e) => {
                                    data[2].options[0].answer = e.target.value;
                                    setFactFinderQuestions({
                                      ...factFinderQuestions,
                                      data,
                                    });
                                    error.errorData[12] = "";
                                    setError({
                                      ...error,
                                      error,
                                    });
                                  }}
                                />
                                <label htmlFor="decide-name">
                                  {t("fact_finder_third.name")}
                                </label>
                                <span className="text-danger ">
                                  {error.errorData[12]}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </form>
                </div>
              </Animated>
              {/*End: Animate.css Element */}
            </div>
            {/*End: About your health Form Wrapper */}
          </div>
        </div>

        <div className="text-center mt-5">
          {isProcessing ? (
            <Link className="btn btn-primary" to="#">
              {t("fact_finder_second.processing")}
            </Link>
          ) : (
            <Link
              className="btn btn-primary"
              to="#"
              onClick={() => continueNext()}
            >
              {t("next")}
            </Link>
          )}
        </div>
        <div className="text-center mt-5">
          <Link className="btn btn-link" to={UnderWritingRoutes.FACT_FINDER_2}>
            {t("back")}
          </Link>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  underwriting: state.underwriting.underwriting,
  consumer: state.consumer.consumerdetails,
  question_answers: state.underwriting,
  factFinderQuestionList: state.underwriting.factFinderQuestion,
});

export default connect(mapStateToProps, {
  commonApiCall,
})(withRouter(FactFinderThird));
