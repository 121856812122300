import {
  UNDERWRITING_DETAILS,
  COMPANY_INFORMATION,
  PROFILE_PIC,
  DIRECT_LINK,
  QUESTIONARIES,
  AGE,
  ASSIGNED_AGENT,
  FACT_FINDER_QUESTION,
  CONSUMER_DETAILS,
  APPLICATION_CARRIERS_DETAILS,
  SBLI_PROGRESS_BAR,
  SBLI_ARC_ID,
  SBLI_PROGRESS_COUNT,
  ASSURITY_PROGRESS_COUNT,
} from "./types";

export const clearUnderWriting = () => async (dispatch) => {
  const payload = {};
  dispatch({ type: UNDERWRITING_DETAILS, payload });
};

export const clearCompanyInformation = () => async (dispatch) => {
  const payload = {};
  dispatch({ type: COMPANY_INFORMATION, payload });
};
export const directLinkPath = (data) => async (dispatch) => {
  dispatch({ type: DIRECT_LINK, payload: data });
};
export const profilePicture = (value) => async (dispatch) => {
  const payload = { value };
  dispatch({ type: PROFILE_PIC, payload });
};

export const getAge = (value) => async (dispatch) => {
  const payload = { value };
  dispatch({ type: AGE, payload });
};

export const questionaries = (value) => async (dispatch) => {
  const payload = { value };
  dispatch({ type: QUESTIONARIES, payload });
};

export const assignedAggent = (value) => async (dispatch) => {
  const payload = { value };
  dispatch({ type: ASSIGNED_AGENT, payload });
};

export const factFinderQuestion = (value) => async (dispatch) => {
  const payload = { value };
  dispatch({ type: FACT_FINDER_QUESTION, payload });
};

export const updateConsumer = (value) => async (dispatch) => {
  const payload = { data: value };
  dispatch({ type: CONSUMER_DETAILS, payload });
};

export const applicationCarriersInfo = () => async (dispatch) => {
  const payload = {};
  dispatch({ type: APPLICATION_CARRIERS_DETAILS, payload });
};

export const setSbliProgressBar = (value) => async (dispatch) => {
  const payload = { data: value };
  dispatch({ type: SBLI_PROGRESS_BAR, payload });
};
export const setSbliProgressCount = (value) => async (dispatch) => {
  const payload = { data: value };
  dispatch({ type: SBLI_PROGRESS_COUNT, payload });
};

export const setAssurityProgressCount = (value) => async (dispatch) => {
  const payload = { data: value };
  dispatch({ type: ASSURITY_PROGRESS_COUNT, payload });
};

export const setArcId = (value) => async (dispatch) => {
  const payload = { data: value };
  dispatch({ type: SBLI_ARC_ID, payload });
};
