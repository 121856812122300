import React, { memo, useCallback, useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { setSbliProgressBar, setSbliProgressCount } from "../../redux/actions";
import { getState, showErrorToast } from "../../utils";

const RadioBlock = ({ radio, onValueChange, answer }) => {
  radio.answers = radio.answers.sort((a, b) => {
    return a.answer_option_seq - b.answer_option_seq;
  });
  const checkAnswerValue = (question) => {
    let check = answer.filter((x) => x.questionId === question.questionID);
    return check.length > 0 ? check[0] : false;
  };

  return (
    <span className="custom-radio mt-3 d-block">
      {radio.answers &&
        radio.answers.map((_radio, index) => {
          return (
            <label
              key={index}
              className="m-0 mr-3 d-block mb-2"
              htmlFor={"question_" + _radio.answer_option_id}
            >
              <input
                checked={
                  checkAnswerValue(radio)
                    ? checkAnswerValue(radio).answerOptionId ===
                      _radio.answer_option_id
                    : false
                }
                type="radio"
                onChange={(e) => onValueChange(radio, _radio.answer_option_id)}
                id={"question_" + _radio.answer_option_id}
                name={"question_" + radio.questionID}
              />
              <span></span>{" "}
              <span className="emailCheck">{_radio.answer_text}</span>
            </label>
          );
        })}
    </span>
  );
};

const CheckboxBlock = ({ check, onValueChange, answer }) => {
  check.answers = check.answers.sort((a, b) => {
    return a.answer_option_seq - b.answer_option_seq;
  });
  return (
    <>
      <div className="form-block radio-block">
        {check.answers &&
          check.answers.map((radio, index) => {
            if (
              check.checkBoxQuestion ||
              (radio.Parent_answer_option_id === null &&
                radio.answer_category === null)
            ) {
              let isChecked =
                answer &&
                answer.findIndex(
                  (x) => x.answerOptionId == radio.answer_option_id
                ) > -1;
              return (
                <div className="form-group" key={index}>
                  <div className="custom-checkbox">
                    <label
                      className="m-0"
                      htmlFor={"question_" + radio.answer_option_id}
                    >
                      <input
                        type="checkbox"
                        checked={isChecked}
                        onChange={(e) => {
                          onValueChange(check, radio.answer_option_id, e);
                        }}
                        id={"question_" + radio.answer_option_id}
                        name={"question_" + check.questionID}
                      />
                      <span></span> {radio.answer_text}
                    </label>
                  </div>
                </div>
              );
            }
          })}
      </div>
    </>
  );
};

const YesNoBlock = ({ question, onValueChange, answer }) => {
  const checkAnswerValue = (question) => {
    let check = answer.filter((x) => x.questionId === question.questionID);
    return check.length > 0 ? check[0] : false;
  };

  return (
    <span className="custom-radio mt-3 d-block">
      <label className="m-0 mb-3" htmlFor={question.questionID + "ab"}>
        <input
          type="radio"
          name={question.questionID}
          onChange={(e) => onValueChange(question, e)}
          id={question.questionID + "ab"}
          value={"1"}
          checked={
            checkAnswerValue(question)
              ? checkAnswerValue(question).answerValue === "1"
              : false
          }
        />
        <span></span> Yes
      </label>

      <label className="m-0" htmlFor={question.questionID + "ba"}>
        <input
          type="radio"
          name={question.questionID}
          id={question.questionID + "ba"}
          value={"0"}
          onChange={(e) => onValueChange(question, e)}
          checked={
            checkAnswerValue(question)
              ? checkAnswerValue(question).answerValue === "0"
              : false
          }
        />
        <span></span> No
      </label>
    </span>
  );
};

const TextFieldBlock = ({ question, onValueChange, answer }) => {
  const checkAnswerValue = (question) => {
    let check = answer.filter((x) => x.questionId === question.questionID);
    return check.length > 0 ? check[0] : false;
  };
  return question.question_attribute === "INCOME" ? (
    <NumberFormat
      // autoFocus
      prefix={"$"}
      thousandSeparator={true}
      name="number"
      className="form-control number-format-amount"
      inputMode="numeric"
      allowNegative={false}
      value={
        checkAnswerValue(question) ? checkAnswerValue(question).answerValue : ""
      }
      //   isAllowed={(values) => {
      //     const { formattedValue, floatValue } = values;
      //     return (
      //       formattedValue === "" || floatValue <= slideLabels.max
      //     );
      //   }}
      //   onBlur={(e) => handleValue()}
      onValueChange={(e) =>
        onValueChange(question, {
          target: {
            value: e.value,
          },
        })
      }
    />
  ) : (
    <div className="form-group ">
      <input
        className="form-control"
        onChange={(e) => onValueChange(question, e)}
        type="text"
        value={
          checkAnswerValue(question)
            ? checkAnswerValue(question).answerValue
            : ""
        }
        placeholder="Enter Text"
      />
    </div>
  );
};

const CustomFormBuilder = ({
  question_data,
  onAnswerChange,
  setSbliProgressBar,
  setSbliProgressCount,
  onSubmit,
  progressCount,
  loading,
  underwriting,
  onPageComplete,
  onPages,
}) => {
  const [questionsData, setQuestionsData] = useState(question_data);
  const [parentQuestionData, setParentQuestionData] = useState([]);
  const [childQuestionData, setChildQuestionData] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [answerChildQuestion, setAnswerChildQuestion] = useState([]);
  const [attributeQues, setAttributeQues] = useState([]);
  const [healthIndex, setHealthIndex] = useState(null);
  const [errors, setErrors] = useState({});
  const [answers, setAnswers] = useState([]);

  // Split child questions
  const filterChildQuestions = () => {
    if (questionsData.length > 0) {
      const childQuestion = questionsData.filter(
        (x) => x.Parent_question_id !== null
      );
      let parentQuestion = questionsData
        .filter((x) => x.Parent_question_id === null)
        .sort((a, b) => {
          return a.question_sequence_number - b.question_sequence_number;
        });

      let newQuestion = parentQuestion.filter(
        (x) =>
          x.question_attribute !== "HEIGHTANDWEIGHT" &&
          x.question_attribute !== "HEIGHT" &&
          x.question_attribute !== "WEIGHT"
      );

      if (newQuestion.length > 0) {
        let indx = parentQuestion.findIndex(
          (x) => x.question_attribute === "HEIGHTANDWEIGHT"
        );
        if (indx > -1) {
          setHealthIndex(indx);
          newQuestion.splice(indx, 0, "");
        }
      }

      const item = parentQuestion.filter(
        (x) =>
          x.question_attribute === "HEIGHTANDWEIGHT" ||
          x.question_attribute === "HEIGHT" ||
          x.question_attribute === "WEIGHT"
      );

      if (item.length > 0) {
        setAttributeQues(item);
      } else {
        setCurrentQuestion(0);
      }

      setChildQuestionData(childQuestion);
      setParentQuestionData(newQuestion);

      setSbliProgressBar(parentQuestion.length);
    }
  };

  const checkAttrAnsValue = (question) => {
    let check = answers.filter((x) => x.questionId === question.questionID);
    return check.length > 0 ? check[0] : false;
  };

  const filterAnsChildQuestion = () => {
    let ansChildArr = [];
    if (questionsData.length > 0) {
      const radioCheckQuestion = questionsData.filter(
        (x) => x.answer_category === 2 || x.answer_category === 3
      );
      if (radioCheckQuestion.length > 0) {
        for (let index = 0; index < radioCheckQuestion.length; index++) {
          const element = radioCheckQuestion[index];
          for (let j = 0; j < element.answers.length; j++) {
            const ansElement = element.answers[j];

            if (
              ansElement.answer_category &&
              ansElement.Parent_answer_option_id
            ) {
              const options = element.answers.filter(
                (x) => x.Parent_answer_option_id == ansElement.answer_option_id
              );
              const obj = {
                question_text: ansElement.answer_text,
                questionID: ansElement.answer_option_id,
                Parent_question_id: ansElement.Parent_answer_option_id,
                dependency: 0,
                question_type: 1,
                question_attribute: "",
                answers: options,
                answer_category: ansElement.answer_category,
                active_flag: 1,
                categories: "",
                question_sequence_number: 270,
                open_field_data_type: 2,
                checkBoxQuestion: true,
                applicable_parent_answer: String(
                  ansElement.applicable_parent_answer
                ),
              };
              ansChildArr.push(obj);
            }
          }
        }
      }
      setAnswerChildQuestion(ansChildArr);
    }
  };

  useEffect(() => {
    if (question_data && question_data.length > 0) {
      setQuestionsData(question_data);
    }
  }, [question_data]);

  useEffect(() => {
    filterChildQuestions();
    filterAnsChildQuestion();
  }, [questionsData]);

  //  handle changes on yes/no & textfield
  const handleChange = (question, e) => {
    let tempAnswers = [...answers];
    const checkQuestionIndex = answers.findIndex(
      (x) => x.questionId === question.questionID
    );

    if (checkQuestionIndex > -1) {
      tempAnswers[checkQuestionIndex].answerValue = e.target.value;
    } else {
      let obj;
      if (question.question_attribute === "WEIGHT") {
        obj = {
          questionId: question.questionID,
          answerValue: e.target.value,
          attr: question.question_attribute,
        };
      } else {
        obj = {
          questionId: question.questionID,
          answerValue: e.target.value,
        };
      }

      tempAnswers.push(obj);
    }

    const childQuestion = childQuestionData.filter(
      (x) => x.Parent_question_id == question.questionID
    );
    const answerChild = answerChildQuestion.filter(
      (x) => x.Parent_question_id == question.questionID
    );

    const child = childQuestion.concat(answerChild);

    if (child.length > 0) {
      for (let index = 0; index < child.length; index++) {
        const children = child[index];
        let childIndex = tempAnswers.findIndex(
          (x) => x.questionId == children.questionID
        );

        if (childIndex > -1) {
          tempAnswers.splice(childIndex, 1);
        }
      }
    }
    setAnswers(tempAnswers);
    onAnswerChange(tempAnswers);
  };

  // handle changes on radio
  const handleRadioChange = (question, id) => {
    let tempAnswers = [...answers];
    const checkQuestionIndex = answers.findIndex(
      (x) => x.questionId === question.questionID
    );

    if (checkQuestionIndex > -1) {
      tempAnswers[checkQuestionIndex].answerOptionId = id;
      tempAnswers[checkQuestionIndex].answerValue = "1";
    } else {
      const obj = {
        questionId: question.questionID,
        answerOptionId: id,
        answerValue: "1",
      };

      tempAnswers.push(obj);
    }

    let childQuestion = childQuestionData.filter(
      (x) => x.Parent_question_id == question.questionID
    );
    let answerChildQuestion = childQuestionData.filter(
      (x) => x.Parent_question_id == question.questionID
    );

    let child = childQuestion.concat(answerChildQuestion);

    if (child.length > 0) {
      for (let index = 0; index < child.length; index++) {
        const children = child[index];
        let childIndex = tempAnswers.findIndex(
          (x) => x.questionId == children.questionID
        );

        if (childIndex > -1) {
          tempAnswers.splice(childIndex, 1);
        }
      }
    }
    setAnswers(tempAnswers);
    onAnswerChange(tempAnswers);
  };

  // Handle changes on checkbox

  const handleCheckboxChange = (question, id, e) => {
    let tempAnswers = [...answers];
    const checkQuestionIndex = answers.findIndex(
      (x) => x.questionId === question.questionID && x.answerOptionId === id
    );

    if (checkQuestionIndex > -1) {
      tempAnswers.splice(checkQuestionIndex, 1);
    } else {
      const obj = {
        questionId: question.questionID,
        answerOptionId: id,
        answerValue: "1",
      };

      tempAnswers.push(obj);
    }
    setAnswers(tempAnswers);
    onAnswerChange(tempAnswers);
  };

  const handleAttrQues = (question, value, type) => {
    let tempAnswers = [...answers];
    const answerIndex = answers.findIndex(
      (x) => x.questionId === question.questionID
    );

    if (answerIndex > -1) {
      tempAnswers[answerIndex].answerValue =
        type === "feet"
          ? value + "." + tempAnswers[answerIndex].answerValue.split(".")[1]
          : tempAnswers[answerIndex].answerValue.split(".")[0] + "." + value;
    } else {
      tempAnswers.push({
        questionId: question.questionID,
        answerValue: type === "feet" ? value + ".0" : "0." + value,
        attr: question.question_attribute,
      });
    }
    setAnswers(tempAnswers);
    onAnswerChange(tempAnswers);
  };

  // For Getting dynamic field type
  const getFieldType = (question) => {
    switch (question?.answer_category) {
      case 1:
        return (
          <YesNoBlock
            question={question}
            onValueChange={handleChange}
            answer={answers}
          />
        );

      case 2:
        return (
          <RadioBlock
            radio={question}
            onValueChange={handleRadioChange}
            answer={answers}
          />
        );

      case 3:
        return (
          <CheckboxBlock
            answer={answers}
            check={question}
            onValueChange={handleCheckboxChange}
          />
        );

      case 4:
        return (
          <TextFieldBlock
            question={question}
            onValueChange={handleChange}
            answer={answers}
          />
        );

      default:
        return console.log("error");
    }
  };

  const validateQuestions = () => {
    const ques_container = document.getElementById("sbli_question_container");

    const current_question_id = parseInt(
      ques_container.classList[1].replace("question_", "")
    );
    const check_parent = answers.filter(
      (x) => x.questionId == current_question_id
    );

    if (check_parent.length > 0) {
      ques_container.classList.remove("validate-question-parent");
      let child_ques_container =
        ques_container.getElementsByClassName("child-question");

      if (child_ques_container.length > 0) {
        for (let index = 0; index < child_ques_container.length; index++) {
          const element = child_ques_container[index];
          let current_child_id = parseInt(
            element.classList[3].replace("question_", "")
          );

          const check_child = answers.filter(
            (x) => x.questionId == current_child_id
          );
          if (check_child.length === 0) {
            element.classList.add("validate-question-child");
            showErrorToast("Please answer all the questions");
            return false;
          }
          element.classList.remove("validate-question-child");
        }
        return true;
      } else {
        return true;
      }
    } else {
      ques_container.classList.add("validate-question-parent");
      showErrorToast("Please answer all the questions");
      return false;
    }
  };

  // next button for next questions
  const nextBtnQuestion = (question) => {
    if (currentQuestion !== healthIndex) {
      // const check = answers.findIndex(
      //   (x) => x.questionId === question.questionID
      // );

      if (validateQuestions(question)) {
        if (currentQuestion + 1 < parentQuestionData.length) {
          setSbliProgressCount(progressCount + 1);
          setCurrentQuestion(currentQuestion + 1);
        }
        // } else {
        //   onSubmit();
        // }

        if (currentQuestion + 1 === parentQuestionData.length) {
          onSubmit();
        }
      }
    } else {
      let checkHeightAttr = answers.findIndex((x) => x.attr === "HEIGHT");

      let checkWeightAttr = answers.findIndex((x) => x.attr === "WEIGHT");
      let feetVal = "0",
        inchVal = "0";
      if (checkHeightAttr < 0) {
        document
          .getElementById("attr-question-head-feet")
          .classList.add("text-danger");
        document
          .getElementById("attr-question-head-inch")
          .classList.add("text-danger");
      } else {
        feetVal = answers[checkHeightAttr].answerValue.split(".")[0];
        inchVal = answers[checkHeightAttr].answerValue.split(".")[1];

        if (feetVal == "0") {
          document
            .getElementById("attr-question-head-feet")
            .classList.add("text-danger");
        } else {
          document
            .getElementById("attr-question-head-feet")
            .classList.remove("text-danger");
        }
        if (inchVal == "0") {
          document
            .getElementById("attr-question-head-inch")
            .classList.add("text-danger");
        } else {
          document
            .getElementById("attr-question-head-inch")
            .classList.remove("text-danger");
        }
      }

      if (checkWeightAttr < 0) {
        document.getElementById("weight_ques").classList.add("is-invalid");
      } else {
        document.getElementById("weight_ques").classList.remove("is-invalid");
      }
      if (
        checkHeightAttr > -1 &&
        feetVal !== "0" &&
        inchVal !== "0" &&
        checkWeightAttr > -1
      ) {
        setSbliProgressCount(progressCount + 1);
        setCurrentQuestion(currentQuestion + 1);
      } else {
      }
    }
  };

  // back button for previous question
  const backBtnQuestion = () => {
    if (currentQuestion !== 0) {
      setCurrentQuestion(currentQuestion > 0 ? currentQuestion - 1 : 0);
      setSbliProgressCount(progressCount - 1);
    } else {
      setCurrentQuestion(null);
      setSbliProgressCount(progressCount - 1);
    }
  };

  const renderChildQuestion = (parent) => {
    const ansObj = answers.filter((x) => x.questionId == parent?.questionID);

    if (ansObj.length > 0) {
      // const child = childQuestionData.filter(
      //   (x) => x.Parent_question_id === parent?.questionID
      // );

      const childQuestion = childQuestionData.filter(
        (x) => x.Parent_question_id == parent?.questionID
      );
      const answerChild = answerChildQuestion.filter(
        (x) => x.Parent_question_id == parent?.questionID
      );

      const child = childQuestion.concat(answerChild);

      if (child.length > 0) {
        return child.map((children) => {
          if (
            getState(underwriting?.postalCode).st !== "FL" ||
            (getState(underwriting?.postalCode).st === "FL" &&
              children.questionID != 1970)
          ) {
            if (parent?.answer_category === 2) {
              if (children.applicable_parent_answer.includes("[")) {
                let applicable_ans = children.applicable_parent_answer
                  .replace("[", "")
                  .replace("]", "")
                  .split(",");

                if (
                  applicable_ans.filter((x) => x == ansObj[0].answerOptionId)
                    .length > 0
                ) {
                  return (
                    <div
                      className={
                        "child-question pl-3 mt-4 question_" +
                        children.questionID
                      }
                    >
                      <h4 className="child-question_head">
                        {children?.question_text}
                      </h4>
                      {children?.answer_category !== 5 &&
                        getFieldType(children)}
                      {errors && errors[children.questionID] && (
                        <p className="validate-msg">
                          {errors[children.questionID]}
                        </p>
                      )}
                      {renderChildQuestion(children)}
                    </div>
                  );
                }
              } else if (
                ansObj[0].answerOptionId == children.applicable_parent_answer
              ) {
                return (
                  <div
                    className={
                      "child-question pl-3 mt-4 question_" + children.questionID
                    }
                  >
                    <h4 className="child-question_head">
                      {children?.question_text}
                    </h4>
                    {children?.answer_category !== 5 && getFieldType(children)}
                    {errors && errors[children.questionID] && (
                      <p className="validate-msg">
                        {errors[children.questionID]}
                      </p>
                    )}
                    {renderChildQuestion(children)}
                  </div>
                );
              }
            } else if (parent?.answer_category === 3) {
            } else {
              if (children.applicable_parent_answer.includes("[")) {
                let applicable_ans = children.applicable_parent_answer
                  .replace("[", "")
                  .replace("]", "")
                  .split(",");
                if (
                  applicable_ans.filter((x) => x == ansObj[0].answerValue)
                    .length > 0
                ) {
                  return (
                    <div
                      className={
                        "child-question pl-3 mt-4 question_" +
                        children.questionID
                      }
                    >
                      <h4 className="child-question_head">
                        {children?.question_text}
                      </h4>
                      {children?.answer_category !== 5 &&
                        getFieldType(children)}
                      {errors && errors[children.questionID] && (
                        <p className="validate-msg">
                          {errors[children.questionID]}
                        </p>
                      )}
                      {renderChildQuestion(children)}
                    </div>
                  );
                }
              } else if (
                ansObj[0].answerValue == children.applicable_parent_answer
              ) {
                return (
                  <div
                    className={
                      "child-question pl-3 mt-4 question_" + children.questionID
                    }
                  >
                    <h4 className="child-question_head">
                      {children?.question_text}
                    </h4>
                    {children?.answer_category !== 5 && getFieldType(children)}
                    {errors && errors[children.questionID] && (
                      <p className="validate-msg">
                        {errors[children.questionID]}
                      </p>
                    )}
                    {renderChildQuestion(children)}
                  </div>
                );
              }
            }
          }
        });
      }
    }
  };

  const getHeightAndWeight = (question, type) => {
    const height = answers.filter((x) => x.questionId === question.questionID);
    let value = false;
    if (height.length > 0) {
      if (height[0].answerValue.includes(".")) {
        value =
          type == "feet"
            ? height[0].answerValue.split(".")[0]
            : height[0].answerValue.split(".")[1];
        return value;
      }
    }
    value = false;
    return value;
  };

  const renderAnswerChildQuestion = (parent) => {
    let ansQues = [];
    const answeredQues = answers.filter(
      (x) => x.questionId === parent?.questionID
    );

    answeredQues.forEach((x) => {
      const ques = answerChildQuestion.filter((y) =>
        x.hasOwnProperty("answerOptionId")
          ? y.Parent_question_id == x.answerOptionId
          : y.Parent_question_id == x.questionId &&
            y.applicable_parent_answer == x.answerValue
      );
      ansQues = [...ansQues, ...ques];
    });

    if (ansQues.length > 0) {
      return ansQues.map((ques, index) => {
        return (
          <div
            className={"child-question pl-3 mt-4 question_" + ques.questionID}
            key={index}
          >
            <h4 className="mb-3 child-question_head">{ques?.question_text}</h4>
            {ques?.answer_category !== 5 && getFieldType(ques)}

            {/* {renderChildQuestion(ques)} */}
            {renderAnswerChildQuestion(ques)}
          </div>
        );
      });
    }
  };

  const getAttributeObject = (attr) => {
    const obj = attributeQues.filter((x) => x.question_attribute === attr);
    return obj.length > 0 ? obj[0] : null;
  };

  return (
    parentQuestionData.length > 0 && (
      <div className="sbli-question-container">
        {currentQuestion === healthIndex && attributeQues.length > 0 && (
          <div className="attr-question">
            <h4 className="text-center mb-5 ">
              {
                getAttributeObject(attributeQues[0].question_attribute)
                  .question_text
              }
            </h4>
            <h4 className="text-center mb-4" id="attr-question-head-feet">
              Feet
            </h4>
            <div className="height-box mb-5">
              {[...Array(9).keys()].map((_, index) => {
                return (
                  <div
                    onClick={() =>
                      handleAttrQues(attributeQues[1], index + 1, "feet")
                    }
                    className={
                      "unit-box text-dark " +
                      (getHeightAndWeight(attributeQues[1], "feet")
                        ? getHeightAndWeight(attributeQues[1], "feet") - 1 ==
                          index
                          ? "active"
                          : ""
                        : "")
                    }
                    key={index}
                  >
                    {index + 1}
                  </div>
                );
              })}
            </div>
            <h4 className="text-center mb-4" id="attr-question-head-inch">
              Inches
            </h4>
            <div className="height-box">
              {[...Array(11).keys()].map((_, index) => {
                return (
                  <div
                    onClick={() =>
                      handleAttrQues(
                        attributeQues[1],
                        index < 9 ? `0${index + 1}` : index + 1,
                        "inches"
                      )
                    }
                    className={
                      "unit-box text-dark " +
                      (getHeightAndWeight(attributeQues[1], "inches")
                        ? getHeightAndWeight(attributeQues[1], "inches") - 1 ==
                          index
                          ? "active"
                          : ""
                        : "")
                    }
                    key={index}
                  >
                    {index + 1}
                  </div>
                );
              })}
            </div>
            <div className="form-group mt-5 w-50 mx-auto pt-4">
              <div className="input-effect">
                <input
                  className="form-control"
                  placeholder={"Weight (lbs)"}
                  id="weight_ques"
                  type="number"
                  value={
                    checkAttrAnsValue(attributeQues[2])
                      ? checkAttrAnsValue(attributeQues[2]).answerValue
                      : ""
                  }
                  onChange={(e) => handleChange(attributeQues[2], e)}
                />
                <label htmlFor="weight_ques">{"Weight (lbs)"}</label>
                <em className="input-icon"></em>
              </div>
            </div>
          </div>
        )}
        <div
          className={
            "sbli-parent-question question_" +
            parentQuestionData[currentQuestion].questionID
          }
          id="sbli_question_container"
        >
          {currentQuestion !== healthIndex && parentQuestionData.length > 0 && (
            <>
              <h4 className="mb-4 parent_question_head">
                {parentQuestionData[currentQuestion].question_text}
              </h4>
              {parentQuestionData[currentQuestion].answer_category !== 5 &&
                getFieldType(parentQuestionData[currentQuestion])}
              {/* {errors && errors[parentQuestionData[currentQuestion]] && (
                  <p className="validate-msg">
                    {errors[parentQuestionData[currentQuestion]]}
                  </p>
                )} */}

              {renderChildQuestion(parentQuestionData[currentQuestion])}

              {/* {errors && errors[parentQuestionData[currentQuestion]] && (
                  <p className="validate-msg">
                    {errors[parentQuestionData[currentQuestion]]}
                  </p>
                )} */}
              {renderAnswerChildQuestion(parentQuestionData[currentQuestion])}
            </>
          )}{" "}
          <div className="w-100 my-4 text-center">
            <button
              className="btn btn-default px-5"
              disabled={loading}
              onClick={() =>
                nextBtnQuestion(
                  currentQuestion !== null
                    ? parentQuestionData[currentQuestion]
                    : {}
                )
              }
            >
              {loading ? "Processing..." : "Next"}
            </button>
          </div>
          <div className="w-100 text-center">
            {currentQuestion !== 0 && (
              <button className="btn btn-link px-5" onClick={backBtnQuestion}>
                Back
              </button>
            )}
          </div>
        </div>
        {currentQuestion === 0 && (
          <div className="highlighted-header">
            <b>
              It should take you about 15 minutes to complete the application,
              but it's worth it!
            </b>
            <p>At the end you will have an active life insurance policy</p>
          </div>
        )}
      </div>
    )
  );
};

const mapStateToProps = (state) => ({
  progressCount: state.underwriting.progressCount,
  underwriting: state.underwriting.underwriting,
});

export default connect(mapStateToProps, {
  setSbliProgressBar,
  setSbliProgressCount,
})(withRouter(CustomFormBuilder));
// export default memo(CustomFormBuilder);
