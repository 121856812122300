import React from "react";
import { Link } from "react-router-dom";
import { CmsPageRoutes } from "../utils/index";
import { AGENT_PORTAL_URL } from "../config/configs";

const Footer = ({ t }) => {
  let currentDate = new Date();
  return (
    <footer>
      <div className="container pb-0">
        <div className="footer-block">
          <div className="row">
            <div className="col-lg-4">
              <div className="front-title-block">
                <h4 className="subtitle">{t("footer.contact")}</h4>
                <h3 className="title">
                  {t("footer.need_help_with")}{" "}
                  <span className="text-primary">{t("footer.anything?")}</span>
                </h3>
                <p className="common-para">
                  {t("footer.let’s_hear_all_about_it!")}&nbsp;
                  <Link
                    to={CmsPageRoutes.CONTACT_US}
                    className="gray-link text-link-gray"
                    title={t("footer.contact_us")}
                  >
                    {t("footer.contact_us")}
                  </Link>
                </p>
              </div>
            </div>
            <div className="col-lg-8">
              <div className="row">
                <div className="col-md-4">
                  <h4 className="footer-nav-title">{t("footer.home")}</h4>
                  <ul className="footer-nav">
                    <li className="footer-link">
                      <Link
                        to={CmsPageRoutes.ABOUT_US}
                        className="text-link-gray"
                        title={t("footer.about_iLife")}
                      >
                        {t("footer.about_iLife")}
                      </Link>
                    </li>
                    <li className="footer-link">
                      <Link
                        to={AGENT_PORTAL_URL}
                        className="text-link-gray"
                        title={t("footer.iLife_service_reps")}
                      >
                        {t("footer.iLife_service_reps")}
                      </Link>
                    </li>
                    <li className="footer-link">
                      <Link
                        to="#"
                        className="text-link-gray"
                        title={t("footer.consultation")}
                      >
                        {t("footer.consultation")}
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="col-md-4">
                  <h4 className="footer-nav-title">{t("footer.about")}</h4>
                  <ul className="footer-nav">
                    <li className="footer-link">
                      <Link
                        to="#"
                        className="text-link-gray"
                        title={t("footer.general")}
                      >
                        {t("footer.general")}
                      </Link>
                    </li>
                    <li className="footer-link">
                      <Link
                        to="#"
                        className="text-link-gray"
                        title={t("footer.faq")}
                      >
                        {t("footer.faq")}
                      </Link>
                    </li>
                    <li className="footer-link">
                      <Link
                        to="#"
                        className="text-link-gray"
                        title={t("footer.testimonials")}
                      >
                        {t("footer.testimonials")}
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="col-md-4">
                  <h4 className="footer-nav-title">{t("footer.help")}</h4>
                  <ul className="footer-nav">
                    <li className="footer-link">
                      <Link
                        to="#"
                        className="text-link-gray"
                        title={t("footer.policy")}
                      >
                        {t("footer.policy")}
                      </Link>
                    </li>
                    <li className="footer-link">
                      <Link
                        to="#"
                        className="text-link-gray"
                        title={t("footer.all_plans")}
                      >
                        {t("footer.all_plans")}
                      </Link>
                    </li>
                    <li className="footer-link">
                      <Link
                        to="#"
                        className="text-link-gray"
                        title={t("footer.terms_&_conditions")}
                      >
                        {t("footer.terms_&_conditions")}
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="copyright-block">
          <div className="footer-logo">
            <img
              src="/assets/images/logo.svg"
              alt={t("footer.iLife")}
              title={t("footer.iLife")}
              width="130"
              height="38"
            />
          </div>
          <div className="copyright">
            <p className="pb-0">
              {t("footer.copyright")} &copy; {currentDate.getFullYear()}{" "}
              <strong>{t("footer.iLife_technologies")}</strong>
            </p>
          </div>
          <ul className="social-links">
            <li>
              <svg width="9px" height="19px" viewBox="0 0 9 19" version="1.1">
                <g
                  stroke="none"
                  strokeWidth="1"
                  fill="none"
                  fillRule="evenodd"
                  opacity="0.5"
                >
                  <g
                    transform="translate(-1413.000000, -509.000000)"
                    fill="#839C9F"
                    fillRule="nonzero"
                  >
                    <g transform="translate(-105.000000, 0.000000)">
                      <g transform="translate(480.000000, 165.000000)">
                        <g transform="translate(0.000000, 338.000000)">
                          <g transform="translate(1038.000000, 6.000000)">
                            <path d="M5.96871818,6.21917197 L5.96871818,4.58376008 C5.96871818,4.33825265 5.98017273,4.1480913 6.00345,4.01376008 C6.02664545,3.87922718 6.07941818,3.74691295 6.16131818,3.61629299 C6.24309545,3.48575372 6.37551818,3.39563482 6.55838182,3.34569427 C6.74149091,3.29567304 6.98481818,3.2707431 7.28860909,3.2707431 L8.94702273,3.2707431 L8.94702273,7.46069873e-14 L6.29574545,7.46069873e-14 C4.76165455,7.46069873e-14 3.65985,0.358902335 2.99016818,1.07670701 C2.32056818,1.79467304 1.98580909,2.85221868 1.98580909,4.24974735 L1.98580909,6.21913163 L-3.54383189e-13,6.21913163 L-3.54383189e-13,9.49011677 L1.98568636,9.49011677 L1.98568636,18.9802739 L5.96867727,18.9802739 L5.96867727,9.49015711 L8.61979091,9.49015711 L8.97021818,6.21917197 L5.96871818,6.21917197 Z"></path>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </svg>
            </li>
            <li>
              <svg width="20px" height="17px" viewBox="0 0 20 17" version="1.1">
                <g
                  stroke="none"
                  strokeWidth="1"
                  fill="none"
                  fillRule="evenodd"
                  opacity="0.5"
                >
                  <g
                    transform="translate(-1442.000000, -511.000000)"
                    fill="#839C9F"
                    fillRule="nonzero"
                  >
                    <g transform="translate(-105.000000, 0.000000)">
                      <g transform="translate(480.000000, 165.000000)">
                        <g transform="translate(0.000000, 338.000000)">
                          <g transform="translate(1038.000000, 6.000000)">
                            <path d="M48.9999673,4.00780924 C48.2638562,4.3485261 47.4740196,4.57959639 46.6441503,4.68275703 C47.4915033,4.15271888 48.1401307,3.31200402 48.447549,2.31330522 C47.6527124,2.80415462 46.7753595,3.1605743 45.8405229,3.35378715 C45.0919281,2.51959237 44.0271242,2 42.8460784,2 C40.5802614,2 38.7431046,3.91905422 38.7431046,6.28458032 C38.7431046,6.6200743 38.7793464,6.947751 38.8493464,7.26109036 C35.44,7.08221486 32.4168301,5.37596787 30.3934641,2.78326305 C30.0397712,3.41513052 29.8385621,4.15138755 29.8385621,4.93731325 C29.8385621,6.42426707 30.5634314,7.73627108 31.6632353,8.50386546 C30.9908497,8.48037952 30.3584641,8.28716667 29.8048366,7.96601004 L29.8048366,8.01953614 C29.8048366,10.095243 31.2195752,11.8276044 33.0954575,12.2218815 C32.7517647,12.318488 32.3893464,12.3720141 32.0144118,12.3720141 C31.7494771,12.3720141 31.493268,12.3446024 31.2420588,12.2923735 C31.7644444,13.9960261 33.279183,15.2349096 35.0738562,15.2688755 C33.6703595,16.4177068 31.9006863,17.1004719 29.9785621,17.1004719 C29.6473856,17.1004719 29.3211765,17.0795803 29,17.0417229 C30.815915,18.259749 32.9717647,18.9699257 35.2888235,18.9699257 C42.8361438,18.9699257 46.961634,12.4399116 46.961634,6.77676104 L46.9478758,6.22193976 C47.7539542,5.6213755 48.4513072,4.86682129 48.9999673,4.00780924 Z"></path>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </svg>
            </li>
            <li>
              <svg width="20px" height="19px" viewBox="0 0 20 19" version="1.1">
                <g
                  stroke="none"
                  strokeWidth="1"
                  fill="none"
                  fillRule="evenodd"
                  opacity="0.5"
                >
                  <g
                    transform="translate(-1482.000000, -509.000000)"
                    fill="#839C9F"
                    fillRule="nonzero"
                  >
                    <g transform="translate(-105.000000, 0.000000)">
                      <g transform="translate(480.000000, 165.000000)">
                        <g transform="translate(0.000000, 338.000000)">
                          <g transform="translate(1038.000000, 6.000000)">
                            <path d="M88.9590255,11.6206675 L88.9590255,18.9539757 L84.6811601,18.9539757 L84.6811601,12.1118083 C84.6811601,10.393 84.062413,9.21998058 82.5139675,9.21998058 C81.3319258,9.21998058 80.6284919,10.0105097 80.3190255,10.7752136 C80.2061717,11.0485922 80.177123,11.4290995 80.177123,11.8117743 L80.177123,18.9539757 L75.897819,18.9539757 C75.897819,18.9539757 75.9554524,7.36558981 75.897819,6.1649466 L80.1767053,6.1649466 L80.1767053,7.97774029 C80.1680278,7.99129854 80.1567517,8.00591748 80.1485847,8.01906068 L80.1767053,8.01906068 L80.1767053,7.97774029 C80.7451972,7.10756796 81.760464,5.86449757 84.0329466,5.86449757 C86.8483991,5.86445146 88.9590255,7.69237136 88.9590255,11.6206675 Z M71.4214849,6.21724894e-14 C69.9575406,6.21724894e-14 69,0.954288835 69,2.20921117 C69,3.43678641 69.9298376,4.42003641 71.3646868,4.42003641 L71.3932715,4.42003641 C72.8855684,4.42003641 73.8136427,3.43697087 73.8136427,2.20921117 C73.785522,0.954288835 72.8855684,6.21724894e-14 71.4214849,6.21724894e-14 Z M69.2541531,18.9539757 L73.5318329,18.9539757 L73.5318329,6.1649466 L69.2541531,6.1649466 L69.2541531,18.9539757 Z"></path>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </svg>
            </li>
            <li>
              <svg width="23px" height="17px" viewBox="0 0 23 17" version="1.1">
                <g
                  stroke="none"
                  strokeWidth="1"
                  fill="none"
                  fillRule="evenodd"
                  opacity="0.5"
                >
                  <g
                    transform="translate(-1522.000000, -511.000000)"
                    fill="#839C9F"
                    fillRule="nonzero"
                  >
                    <g transform="translate(-105.000000, 0.000000)">
                      <g transform="translate(480.000000, 165.000000)">
                        <g transform="translate(0.000000, 338.000000)">
                          <g transform="translate(1038.000000, 6.000000)">
                            <path d="M131.602559,4.73465333 C131.32098,3.43754789 130.320495,2.48058015 129.116066,2.33782805 C126.263948,2.00020802 123.377166,1.99844565 120.50368,2.00020802 C117.629718,1.99844565 114.742462,2.00020802 111.890343,2.33782805 C110.686627,2.48058015 109.686854,3.43754789 109.405275,4.73465333 C109.004511,6.58187047 109,8.59827527 109,10.5001259 C109,12.4019766 109,14.4181296 109.400764,16.265095 C109.681868,17.5619486 110.681879,18.5189164 111.886307,18.6619202 C114.738188,18.999792 117.625207,19.0015544 120.499169,18.999792 C123.373605,19.0015544 126.259912,18.999792 129.111555,18.6619202 C130.315271,18.5191681 131.315994,17.5622004 131.597573,16.265095 C131.998575,14.4178778 131.999999,12.4017248 131.999999,10.5001259 C131.999999,8.59827527 132.003323,6.58187047 131.602559,4.73465333 Z M117.531902,14.2857 C117.531902,11.5877609 117.531902,8.91801981 117.531902,6.219829 C119.969486,7.56854676 122.390926,8.90820089 124.843943,10.265227 C122.398523,11.6101682 119.975896,12.9417658 117.531902,14.2857 Z"></path>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </svg>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
