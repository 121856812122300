import React, { lazy, Suspense } from "react";
import LoadingBar from "react-top-loading-bar";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import {
  UnderWritingRoutes,
  CmsPageRoutes,
  ApprovalStatus,
  ProtectedRoute,
  UnProtectedRoute,
  DashboardRoute,
} from "./utils/index";
// import { getJwt } from "./redux/actions/index";
import history from "./utils/history";
// import SBLIForm from "./screens/underWriting/sbli-form";
// import sbliLayout from "./screens/underWriting/sbli-layout";
import layout from "./components/layout";
import AssurityPreview from "./screens/underWriting/assurity/assurity-preview";

const Layout = lazy(() => import("./components/layout"));
// const Settings = lazy(() => import("./components/settings"));

const AppRouting = () => {
  // const requireAuth = () => {
  //   return getJwt() ? true : false;
  // };
  return (
    <React.Fragment>
      <Router history={history}>
        <div className="AppRouting">
          <Suspense
            fallback={
              <div>
                <LoadingBar
                  color="#2ba84a"
                  shadow={true}
                  height={5}
                  progress={50}
                />
              </div>
            }
          >
            <Switch>
              <Route
                exact
                path={UnderWritingRoutes.ASSURITY_USER_DETAIL}
                component={Layout}
              />
              <Route
                exact
                path={UnderWritingRoutes.ASSURITY_PHYSICIAN_REQUEST}
                component={Layout}
              />
              <Route
                exact
                path={UnderWritingRoutes.ASSURITY_PAY}
                component={Layout}
              />
              <Route
                exact
                path={UnderWritingRoutes.ASSURITY_TOKEN_DOMAIN}
                component={Layout}
              />
              <Route
                exact
                path={UnderWritingRoutes.ASSURITY_DOCUMENTS}
                component={Layout}
              />
              <Route
                exact
                path={UnderWritingRoutes.ASSURITY_UNDERWRITING}
                component={Layout}
              />
              <Route
                exact
                path={UnderWritingRoutes.ASSURITY_PRE_APPROVAL}
                component={Layout}
              />
              <Route
                exact
                path={UnderWritingRoutes.ASSURITY_NOTICE}
                component={Layout}
              />
              <Route
                exact
                path={UnderWritingRoutes.ASSURITY_POLICY}
                component={Layout}
              />
              <Route
                exact
                path={UnderWritingRoutes.ASSURITY_PREVIEW}
                component={Layout}
              />
              <Route
                exact
                path={UnderWritingRoutes.ASSURITY_POLICY_DECLINE}
                component={Layout}
              />
              <Route
                exact
                path={UnderWritingRoutes.ASSURITY_PRE_DENIED}
                component={Layout}
              />
              <Route
                exact
                path={UnderWritingRoutes.ASSURITY_BENE}
                component={Layout}
              />
              <Route
                exact
                path={UnderWritingRoutes.SBLI_PAGE}
                component={Layout}
              />
              <Route
                exact
                path={UnderWritingRoutes.SBLI_USER_DETAIL}
                component={Layout}
              />
              <Route
                exact
                path={UnderWritingRoutes.SBLI_DENIAL}
                component={Layout}
              />
              <Route
                exact
                path={UnderWritingRoutes.ASSURITY_LAYOUT}
                component={Layout}
              />
              <Route
                exact
                path={UnderWritingRoutes.ASSURITY_LIMITATION}
                component={Layout}
              />
              <Route
                exact
                path={UnderWritingRoutes.ASSURITY_WITH_CHANGES}
                component={Layout}
              />
              <Route
                exact
                path={UnderWritingRoutes.PRE_QUESTIONS}
                component={Layout}
              />
              <Route
                exact
                path={UnderWritingRoutes.SBLI_TERMS}
                component={Layout}
              />
              <Route
                exact
                path={UnderWritingRoutes.SBLI_THIRD_PARTY}
                component={Layout}
              />
              <Route
                exact
                path={UnderWritingRoutes.SBLI_COVERAGE}
                component={Layout}
              />
              <Route
                exact
                path={UnderWritingRoutes.SBLI_DOC}
                component={Layout}
              />
              <Route
                exact
                path={UnderWritingRoutes.SBLI_PAY}
                component={Layout}
              />
              <Route
                exact
                path={UnderWritingRoutes.SBLI_CONGRATULATIONS}
                component={Layout}
              />
              <Route exact path={UnderWritingRoutes.HOME} component={Layout} />
              <Route
                exact
                path={UnderWritingRoutes.INSURENCE}
                component={Layout}
              />
              <Route
                exact
                path={UnderWritingRoutes.FINACIAL}
                component={Layout}
              />
              <Route
                exact
                path={UnderWritingRoutes.FINANCIAL}
                component={Layout}
              />
              <Route
                exact
                path={UnderWritingRoutes.DIVIDEND}
                component={Layout}
              />
              <Route exact path={UnderWritingRoutes.INDEX} component={Layout} />
              <Route exact path={UnderWritingRoutes.TERM} component={Layout} />
              <Route
                exact
                path={UnderWritingRoutes.MEDICARE}
                component={Layout}
              />
              <Route
                exact
                path={UnderWritingRoutes.PERMANENT}
                component={Layout}
              />
              <Route
                exact
                path={UnderWritingRoutes.FINAL_EXPENSE}
                component={Layout}
              />
              <Route
                exact
                path={UnderWritingRoutes.UNDERWRITING_WELCOME}
                component={Layout}
              />
              <Route
                exact
                path={UnderWritingRoutes.GET_START}
                component={Layout}
              />
              <Route
                exact
                path={UnderWritingRoutes.SINGLE_PAGE}
                component={Layout}
              />
              <Route
                exact
                path={UnderWritingRoutes.BENEFICIARY}
                component={layout}
              />
              <Route
                exact
                path={UnderWritingRoutes.INVESTMENT_TYPE}
                component={Layout}
              />
              <Route
                exact
                path={UnderWritingRoutes.INSURE_TYPE}
                component={Layout}
              />
              <Route
                exact
                path={UnderWritingRoutes.ZIPCODE}
                component={Layout}
              />
              <Route
                exact
                path={UnderWritingRoutes.CITIZENSHIP}
                component={Layout}
              />
              <Route
                exact
                path={UnderWritingRoutes.POLICY_TERM}
                component={Layout}
              />
              <Route
                exact
                path={UnderWritingRoutes.YOUR_FREQUENCY}
                component={Layout}
              />
              <Route
                exact
                path={UnderWritingRoutes.POLICY_TYPE}
                component={Layout}
              />
              <Route
                exact
                path={UnderWritingRoutes.YOUR_DEPOSIT}
                component={Layout}
              />
              <Route
                exact
                path={UnderWritingRoutes.YOUR_GENDER}
                component={Layout}
              />
              <Route
                exact
                path={UnderWritingRoutes.YOUR_AGE}
                component={Layout}
              />

              <Route
                exact
                path={UnderWritingRoutes.YOUR_HEALTH}
                component={Layout}
              />
              <Route
                exact
                path={UnderWritingRoutes.SOMKING_HABIT}
                component={Layout}
              />
              <Route
                exact
                path={UnderWritingRoutes.MEDICARE_ENROLL}
                component={Layout}
              />
              <Route
                exact
                path={UnderWritingRoutes.MEDICARE_PLAN_LIST}
                component={Layout}
              />
              <Route exact path={UnderWritingRoutes.PLANS} component={Layout} />
              <Route
                exact
                path={UnderWritingRoutes.LOGIN_AGENT_CHAT}
                component={Layout}
              />
              <Route
                exact
                path={UnderWritingRoutes.AFFILIATE_BANNER_FLOW}
                component={Layout}
              />
              <Route
                exact
                path={UnderWritingRoutes.GUEST_BASIC_DETAILS}
                component={Layout}
              />

              <ProtectedRoute
                exact
                path={UnderWritingRoutes.MANUAL_FORM}
                component={Layout}
              />
              <ProtectedRoute
                exact
                path={UnderWritingRoutes.PAYMENT}
                component={Layout}
              />
              <ProtectedRoute
                exact
                path={UnderWritingRoutes.CLIENT_FORM}
                component={Layout}
              />
              <ProtectedRoute
                exact
                path={UnderWritingRoutes.FACT_FINDER}
                component={Layout}
              />
              <ProtectedRoute
                exact
                path={UnderWritingRoutes.FACT_FINDER_2}
                component={Layout}
              />
              <ProtectedRoute
                exact
                path={UnderWritingRoutes.FACT_FINDER_3}
                component={Layout}
              />
              <ProtectedRoute
                exact
                path={UnderWritingRoutes.INFORMATION_OPTION}
                component={Layout}
              />
              <Route
                exact
                path={UnderWritingRoutes.LOGIN_EMAIL + "/:name"}
                component={Layout}
              />
              <UnProtectedRoute
                exact
                path={UnderWritingRoutes.LOGIN_EMAIL}
                component={Layout}
              />
              <Route
                exact
                path={UnderWritingRoutes.LOGIN + "/:name"}
                component={Layout}
              />
              <Route exact path={UnderWritingRoutes.LOGIN} component={Layout} />
              <Route
                exact
                path={UnderWritingRoutes.REGISTER + "/:name"}
                component={Layout}
              />
              <UnProtectedRoute
                exact
                path={UnderWritingRoutes.REGISTER}
                component={Layout}
              />
              <Route
                exact
                path={ApprovalStatus.APPLICATION_STATUS}
                component={Layout}
              />
              <Route
                exact
                path={ApprovalStatus.APPROVAL_PROCESS}
                component={Layout}
              />
              <Route
                exact
                path={ApprovalStatus.THANKYOU + "/:carrierid"}
                component={Layout}
              />
              <ProtectedRoute
                exact
                path={DashboardRoute.DASHBOARD}
                component={Layout}
              />
              <ProtectedRoute
                exact
                path={DashboardRoute.MYPROFILE}
                component={Layout}
              />
              <ProtectedRoute
                exact
                path={DashboardRoute.SETTING}
                component={Layout}
              />
              <ProtectedRoute
                exact
                path={DashboardRoute.UNDERPROCESS}
                component={Layout}
              />
              <ProtectedRoute
                exact
                path={DashboardRoute.CONVERSATIONS}
                component={Layout}
              />
              <ProtectedRoute
                exact
                path={DashboardRoute.BASICDETAILSDASHBOARD}
                component={Layout}
              />
              <ProtectedRoute
                exact
                path={DashboardRoute.PLOICYINFORMATION}
                component={Layout}
              />
              <ProtectedRoute
                exact
                path={DashboardRoute.POLICYDOCUMENTS}
                component={Layout}
              />
              <ProtectedRoute
                exact
                path={DashboardRoute.NOTIFICATIONS}
                component={Layout}
              />
              <ProtectedRoute
                exact
                path={DashboardRoute.CONVERSATION_DOCUMENTS}
                component={Layout}
              />
              <Route exact path={CmsPageRoutes.ABOUT_US} component={Layout} />
              <Route exact path={CmsPageRoutes.CONTACT_US} component={Layout} />
              <Route exact path={CmsPageRoutes.FAQ} component={Layout} />
              <Route path={CmsPageRoutes.NOTFOUND} component={Layout} />
              <Redirect to={CmsPageRoutes.NOTFOUND} />
            </Switch>
          </Suspense>
        </div>
      </Router>
    </React.Fragment>
  );
};

export default AppRouting;
