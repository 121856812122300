export const assurtiyCountryList = [
  //"UNKNOWN",
  "AFGHANISTAN",
  "ALBANIA",
  "ALGERIA",
  "AMERICAN_SAMOA",
  "ANDORRA",
  "ANGOLA",
  "ANGUILLA",
  "ANTARCTICA",
  "ANTIGUA_BARBUDA",
  "ARGENTINA",
  "ARMENIA",
  "ARUBA",
  "ASCENSION_ISLANDS",
  "AUSTRALIA",
  "AUSTRIA",
  "AZERBAIJAN",
  "BAHAMAS",
  "BAHRAIN,",
  "BANGLADESH",
  "BARBADOS",
  "BELARUS",
  "BELGIUM",
  "BELIZE",
  "BENIN",
  "BERMUDA",
  "BHUTAN",
  "BOLIVIA",
  "BOSNIA_HERZEGOVINA",
  "BOTSWANA",
  "BOUVET_ISLAND",
  "BRAZIL",
  "BRITISH_INDIAN_OCNTERR",
  "BRUNEI",
  "BULGARIA",
  "BURKINA_FASO",
  "BURMA",
  "BURUNDI",
  "CAMBODIA",
  "CAMEROON",
  "CANADA",
  "CAPEVERDE_ISLAND",
  "CAYMAN_IS",
  "CENTRAL_AFRICAN_REP",
  "CHAD",
  "CHILE",
  "CHINA",
  "CHRISTMAS_ISLANDS",
  "COCOA_ISLANDS",
  "COCOS",
  "COCOS_KEELING",
  "COLUMBIA",
  "COMOROS",
  "CONGO",
  "CONGO_DEM_REP",
  "COOK_ISLANDS",
  "COSTA_RICA",
  "COTE_DIVORIE",
  "CROATIA",
  "CURACAO",
  "CUBA",
  "CYPRUS",
  "CZECH_REPUBLIC",
  "DENMARK",
  "DJIBOUTI",
  "DOMINICA",
  "DOMINICAN_REPUBLIC",
  "ECUADOR",
  "EGYPT",
  "EL_SALVADOR",
  "EQUATORIAL_GUINEA",
  "ERITREA",
  "ESTONIA",
  "ETHIOPIA",
  "FALKLAND_ISLANDS",
  "FAEROE_ISLANDS",
  "FIJI",
  "FINLAND",
  "FRANCE",
  "FRENCH_GUIANA",
  "FRENCH_POLYNESIA",
  "FRENCH_SOUTHERN_TERRITORIES",
  "GABON",
  "GAMBIA",
  "GEORGIA",
  "GERMANY",
  "GHANA",
  "GIBRALTAR",
  "GREECE",
  "GREENLAND",
  "GRENADA",
  "GUADALOUPE",
  "GUAM",
  "GUATEMALA",
  "GUERNSEY",
  "GUINEA",
  "GUINEA_BISSAU",
  "GUYANA",
  "HAITI",
  "HEARD_ISLAND",
  "HOLY_SEE",
  "HONDURAS",
  "HONG_KONG",
  "HUNGARY",
  "ICELAND",
  "INDIA",
  "INDONESIA",
  "IRAN",
  "IRAQ",
  "IRELAND",
  "ISLE_OF_MAN",
  "ISRAEL",
  "ITALY",
  "IVORY_COAST",
  "JAMAICA",
  "JAPAN",
  "JERSEY",
  "JORDAN",
  "KAZAKHSTAN",
  "KENYA",
  "KIRIBATI",
  "KOREA_DEM_PEOPLE_REP",
  "KOREA_REPUBLIC",
  "KOSOVO",
  "KUWAIT",
  "KYRGYZSTAN",
  "LAOS",
  "LATVIA",
  "LEBANON",
  "LESOTHO",
  "LIBERIA",
  "LIBYA",
  "LIECHTENSTEIN",
  "LITHUANIA",
  "LUXEMBOURG",
  "MACAO",
  "MACEDONIA",
  "MADAGASCAR",
  "MALAWI",
  "MALAYSIA",
  "MALDIVES",
  "MALI",
  "MALTA",
  "MARSHALL_ISLANDS",
  "MARTINIQUE",
  "MAURITANIA",
  "MAURITIUS",
  "MAYOTTE_ISLAND",
  "MEXICO",
  "MICRONESIA",
  "MOLDOVA",
  "MONACO",
  "MONGOLIA",
  "MONTENEGRO",
  "MONTSERRAT",
  "MOROCCO",
  "MOZAMBIQUE",
  "MYANMAR",
  "NAMIBIA",
  "NAURU",
  "NEPAL",
  "NETHERLANDS",
  "NETHERLANDS_ANTILLES",
  "NEW_CALEDONIA",
  "NEW_ZEALAND",
  "NICARAGUA",
  "NIGER",
  "NIGERIA",
  "NIUE",
  "NORFOLK_ISLAND",
  "NORTH_YEMEN",
  "NORTH_MARIANA_ISLANDS",
  "NORWAY",
  "OMAN",
  "PAKISTAN",
  "PALAU",
  "PALESTINE",
  "PANAMA",
  "PAPUA_NEW_GUINEA",
  "PARAGUAY",
  "PERU",
  "PHILIPPINES",
  "PITCARIN_ISLANDS",
  "POLAND",
  "PORTUGAL",
  "PUERTO_RICO",
  "QATAR",
  "REUNION_ISLAND",
  "ROMANIA",
  "RUSSIA",
  "RWANDA",
  "SAIPAN",
  "SAMOA",
  "SAN_MARINO",
  "SAO_TOME",
  "SAUDI_ARABIA",
  "SENEGAL",
  "SERBIA",
  "SEYCHELLES",
  "SIERRA_LEONE",
  "SINGAPORE",
  "SLOVAKIA",
  "SLOVENIA",
  "SOLOMON_ISLANDS",
  "SOMALIA",
  "SOUTH_AFRICA",
  "SOUTH_GEORGIA_SANDWICH",
  "SOUTH_SUDAN",
  "SOUTH_YEMEN",
  "SPAIN",
  "SRI_LANKA",
  "ST_PIERRE",
  "ST_HELENA",
  "ST_KITTS_NEVIS",
  "ST_LUCIA",
  "ST_VINCENT",
  "SUDAN",
  "SURINAME",
  "SVALBARD_ISLAND",
  "SWAZILAND",
  "SWEDEN",
  "SWITZERLAND",
  "SYRIA",
  "TAIWAN",
  "TAJIKISTAN",
  "TANZANIA",
  "THAILAND",
  "EAST_TIMOR",
  "TOGO",
  "TOKELAU",
  "TONGA_ISLANDS",
  "TRINIDAD_TOBAGO",
  "TUNISIA",
  "TURKEY",
  "TURKMENISTAN",
  "TURKS_CAICOS_IS",
  "TUVALU",
  "UGANDA",
  "UKRAINE",
  "USSR",
  "UNITED_ARAB_EMIRATES",
  "UK",
  "US_MINOR_OUTLYING_ISLANDS",
  "USA",
  "URUGUAY",
  "VIRGIN_ISLANDS_US",
  "UZBEKISTAN",
  "VANUATU",
  "VENEZUELA",
  "VIETNAM",
  "WALLIS_ISLANDS",
  "BRITISH_VIRGINIS",
  "WESTERN_SAMOA",
  "WESTERN_SARAHA",
  "YUGOSLAVIA",
  "YEMEN",
  "ZAMBIA",
  "ZAIRE",
  "ZIMBABWE",
  "OTHER",
];

export const assurityStateList = [
  "AK",
  "AL",
  "AR",
  "AZ",
  "CA",
  "CO",
  "CT",
  "DC",
  "DE",
  "FL",
  "GA",
  "GU",
  "HI",
  "IA",
  "ID",
  "IL",
  "IN",
  "KS",
  "KY",
  "LA",
  "MA",
  "MD",
  "ME",
  "MI",
  "MN",
  "MO",
  "MS",
  "MT",
  "NC",
  "ND",
  "NE",
  "NH",
  "NJ",
  "NM",
  "NV",
  "NY",
  "OH",
  "OK",
  "OR",
  "PA",
  "PR",
  "RI",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VA",
  "VI",
  "VT",
  "WA",
  "WI",
  "WV",
  "WY",
];

export const tobaccoTypeList = [
  {
    value: "Cigarettes",
    label: "Cigarettes",
  },
  {
    value: "Cigar",
    label: "Cigar",
  },
  // {
  //   value: "cigrattes",
  //   label: "Cigrattes",
  // },
  {
    value: "Pipe",
    label: "Pipe",
  },
  {
    value: "Smokeless",
    label: "Smokeless",
  },
  {
    value: "Ecigarettes",
    label: "E-Cigrattes/Vapor Cigrattes",
  },
  {
    value: "Nicotine_Patches_Gum_Other",
    label: "Gum/Patches/ Etc.",
  },
];

export const purposeList = [
  "Retirement",
  "Estate",
  "KeyMan",
  "BuySell",
  "Charitable",
  "BusinessLoan",
  "Personal",
  "Mortgage",
  "Other",
];

export const reasonList = [
  "AnnualExam",
  "EmploymentExam",
  "FederalAviationAgencyExam",
  "GynecologicalExam",
  "MedicineRecheck",
  "MinorComplaint",
  "PregnancyExam",
  "RoutinePhysical",
  "SchoolPhysical",
  "Other",
];
export const getAssurityCountry = () => {
  return assurtiyCountryList.map((country) => ({
    value: country,
    label: country.replace(new RegExp("_"), " "),
  }));
};

export const getReasonList = () => {
  return reasonList.map((reason) => ({
    value: reason,
    label: reason.replace(new RegExp("_"), " "),
  }));
};
