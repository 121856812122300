import React, { useState, useEffect, memo } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Navbar } from "react-bootstrap";
import moment from "moment";
import queryString from "query-string";
import { isEmpty } from "lodash";
import PerfectScrollbar from "react-perfect-scrollbar";
import HeaderAgentInfo from "./headerAgentInfo";
import AnonymousChat from "./privateChat/anonymousChat";
import {
  logout,
  getJwt,
  setJwt,
  commonApiCall,
  consoleLog,
  COMPANY_INFORMATION,
  clearCompanyInformation,
  clearUnderWriting,
} from "../redux/actions/index";
import {
  API_URL_CLIENT,
  API_URL_CHATSERVICE,
  API_URL_UNDERWRITING,
  CONSUMER_PORTAL_URL,
} from "../config/configs";
import PrivateChat from "./privateChat/index";
import { dateFormate } from "../hooks/index";
import {
  decryptme,
  UnderWritingRoutes,
  CmsPageRoutes,
  DashboardRoute,
  ApprovalStatus,
  showErrorToast,
  truncate,
  checkIsInIframe,
  googleTagManager,
} from "../utils";
import UnderwritingApplicationFooter from "./underwritingApplicationFooter";

let stompClient, SockJS;

const Header = ({
  logout,
  commonApiCall,
  underwriting,
  company_information,
  profile_picture,
  consumer,
  clearCompanyInformation,
  clearUnderWriting,
  t,
  isWelcomePage = false,
  isAboutPage = false,
  props,
}) => {
  const path = window.location.pathname;
  const domainNamePath = window.location.hostname;
  const domainName = domainNamePath.split(".");
  const urlParams = queryString.parse(props.location.search);
  const [referralCode] = useState(urlParams["Ref"]);
  const [isToggle, setIsToggle] = useState(false);
  // const [login, setLogin] = useState(true);
  const [profilePic, setProfilepic] = useState("");
  const [agentReferralCode, setAgentReferralCode] = useState(
    company_information?.refferalCode
  );
  const [notificationListing, setNotificationListing] = useState([]);
  const [unReadNotificationCount, setUnReadNotificationCount] = useState(0);
  const [formData, setFormData] = useState({
    policyData: {},
    chatModel: false,
    isAnonymousChat: false,
    chatDetails: {},
  });
  const getClick = () => {
    if (isToggle) {
      document.body.classList.remove("sidebar-open");
      setIsToggle(false);
    } else {
      document.body.classList.add("sidebar-open");
      setIsToggle(true);
    }
  };

  const [colorObject] = useState({
    backgroundColor: "#fbfcfc",
    headerBkg: "#ffffff",
    fontPreference: "Roboto",
    hoverEffectColor: "#2ba84a",
    inputTextColor: "#091f1f",
    labelBackgroundColor: "#ffffff",
    primaryColor: "#2ba84a",
    screenBackgroundColor: "#ffffff",
    underwritingTextHoverColor: "#ecfdf1",
  });

  const setDefaultTheme = () => {
    document.body.style.setProperty(
      "--color-primary",
      company_information?.agentSettings?.primaryColor
        ? company_information.agentSettings?.primaryColor
        : colorObject.primaryColor
    );
    document.body.style.setProperty(
      "--color-background",
      company_information?.agentSettings?.screenBackgroundColor
        ? company_information.agentSettings?.screenBackgroundColor
        : colorObject.screenBackgroundColor
    );
    document.body.style.setProperty(
      "--color-headerapply",
      company_information?.agentSettings?.backgroundColor
        ? company_information.agentSettings?.backgroundColor
        : colorObject.headerBkg
    );
    document.body.style.setProperty(
      "--color-white",
      company_information?.agentSettings?.labelBackgroundColor
        ? company_information.agentSettings?.labelBackgroundColor
        : colorObject.labelBackgroundColor
    );
    document.body.style.setProperty(
      "--color-txt-df",
      company_information?.agentSettings?.inputTextColor
        ? company_information.agentSettings?.inputTextColor
        : colorObject.inputTextColor
    );
    document.body.style.setProperty(
      "--color-over-effect",
      company_information?.agentSettings?.hoverEffectColor
        ? company_information.agentSettings?.hoverEffectColor
        : colorObject.hoverEffectColor
    );
    document.body.style.setProperty(
      "--color-success-lgt",
      company_information?.agentSettings?.underwritingTextHoverColor
        ? company_information.agentSettings?.underwritingTextHoverColor
        : colorObject.underwritingTextHoverColor
    );
    document.body.style.setProperty(
      "--font-var",
      company_information?.agentSettings?.fontPreference
        ? company_information.agentSettings?.fontPreference
        : colorObject.fontPreference
    );
  };

  useEffect(() => {
    async function fetchData() {
      await getAgentCompanyInfo();
    }
    fetchData();
    if (process.env.REACT_APP_NODE_ENV === "production") {
      googleTagManager();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAgentCompanyInfo = async () => {
    try {
      const request = {};
      let apiEndPoint;
      if (urlParams["Ref"]) {
        apiEndPoint =
          API_URL_CLIENT + "getCompanyInfo?referralCode=" + urlParams["Ref"];
      } else if (company_information?.refferalCode) {
        apiEndPoint =
          API_URL_CLIENT +
          "getCompanyInfo?referralCode=" +
          company_information.refferalCode;
      } else {
        apiEndPoint =
          API_URL_CLIENT + "getCompanyInfo?domainName=" + domainName[0];
      }

      const isAuthorized = false;
      const response = await commonApiCall(
        apiEndPoint,
        "get",
        request,
        COMPANY_INFORMATION,
        isAuthorized
      );
      if (response.status.code === 200) {
        setDefaultTheme();
        setAgentReferralCode(response.data.refferalCode);
      } else if (response.status.code === 404) {
        setDefaultTheme();
        await clearCompanyInformation();
        await clearUnderWriting();
      }
    } catch (err) {
      showErrorToast(t("header.something_went_wrong"));
    }
  };

  useEffect(() => {
    if (profile_picture) {
      let { profilePic } = profile_picture;
      setProfilepic(profilePic);
    }
  }, [profile_picture]);

  const closeSidebar = () => {
    document.body.classList.remove("sidebar-open");
    setIsToggle(false);
  };

  //logout
  const logOut = async () => {
    setCurrentAccessedScreen("Logout");
  };

  const errorImageReplace = (id) => {
    document.getElementById(id).src = "/assets/images/user-dp-demo.jpg";
  };

  const connect = async () => {
    try {
      const Stomp = require("stompjs");
      SockJS = require("sockjs-client");
      SockJS = new SockJS(API_URL_CHATSERVICE + "ws");
      stompClient = Stomp.over(SockJS);
      stompClient.debug = null;
      stompClient.connect(
        {
          clientUserId:
            consumer && consumer.id ? consumer.id : underwriting?.clientUserId,
          type: "OTHERSCREEN",
        },
        function () {
          onConnected();
        },
        function () {
          onError();
        }
      );
    } catch (err) {
      showErrorToast(t("header.something_went_wrong"));
    }
  };

  const onConnected = () => {
    let clientId =
      consumer && consumer.id ? consumer.id : underwriting?.clientUserId;
    try {
      if (stompClient.connected) {
        stompClient.subscribe(
          "/user/client-" + clientId + "/notify",
          function (responsePayload) {
            onMessageReceived(responsePayload);
          }
        );
      }
    } catch (err) {
      showErrorToast(t("header.something_went_wrong"));
    }
  };

  const onError = (error) => {
    consoleLog(error, "error");
  };

  const onMessageReceived = (payload) => {
    try {
      if (payload) {
        const notification = JSON.parse(payload.body);
        if (notification) {
          let notificationArray = [];
          notificationArray.push(notification);
          setNotificationListing((prevNotificationListing) => {
            let returnNotificationArray = [];
            if (prevNotificationListing) {
              returnNotificationArray = [
                ...notificationArray,
                ...prevNotificationListing,
              ];
            } else {
              returnNotificationArray = notificationArray;
            }
            return returnNotificationArray;
          });
          setUnReadNotificationCount(
            (prevUnReadNotificationCount) => prevUnReadNotificationCount + 1
          );
          enableDesktopNotification(
            notification.data.subject,
            notification.data.message
          );
        }
      }
    } catch (err) {
      showErrorToast(t("header.something_went_wrong"));
    }
  };

  const getNotifications = async (isRead = false) => {
    let clientId =
      consumer && consumer.id ? consumer.id : underwriting?.clientUserId;
    try {
      if (clientId) {
        const request = {};
        const apiEndPoint = isRead
          ? API_URL_CHATSERVICE +
            "notifications?clientUserId=" +
            clientId +
            "&isNotificationRead=true"
          : API_URL_CHATSERVICE +
            "notifications?clientUserId=" +
            clientId +
            "&type=unread&pageSize=3&pageNo=0";
        const isAuthorized = getJwt() ? true : false;
        const response = await commonApiCall(
          apiEndPoint,
          "get",
          request,
          false,
          isAuthorized
        );
        if (response.status.code === 200) {
          setCurrentAccessedScreen();
          if (!isRead) {
            setNotificationListing(response.data.notifications);
            if (path !== DashboardRoute.NOTIFICATIONS)
              setUnReadNotificationCount(response.data.unreadCount);
          } else {
            setUnReadNotificationCount(0);
          }
        } else {
          showErrorToast(response.status.message);
        }
      }
    } catch (err) {
      showErrorToast(t("header.something_went_wrong"));
    }
  };
  const getPolicyStatus = async (applicationId) => {
    try {
      const isAuthorized = getJwt() ? true : false;

      let response = await commonApiCall(
        API_URL_UNDERWRITING + "getPolicyStatus?applicationId=" + applicationId,
        "get",
        "",
        "",
        isAuthorized,
        false
      );
      if (response.status.code === 200 && response.data[0]?.agentResponse) {
        setFormData({
          ...formData,
          policyData: response.data[0],
          chatModel: true,
        });
      } else {
        showErrorToast(response.status.message);
      }
    } catch (err) {
      consoleLog(err, "");
      showErrorToast(t("header.something_went_wrong"));
    }
  };

  async function getPolicyDetails(req) {
    try {
      if (req.applicationCarrierId) {
        let response = await commonApiCall(
          API_URL_UNDERWRITING +
            "client/policy/info?anonymousApplicationCarrierId=" +
            req.applicationCarrierId,
          "get",
          "",
          "",
          true
        );
        if (
          response.status.code === 200 &&
          response.data?.agentResponse?.channelId
        )
          setFormData({
            ...formData,
            policyData: response.data,
            chatModel: true,
          });
        else {
          req.status = response.data.agentResponse.status;
          setFormData({
            ...formData,
            chatModel: true,
            policyData: req,
          });
        }
      } else {
        let appId = req.data.message
          .split("has")[0]
          .split("application")[1]
          .trim();
        getPolicyStatus(appId);
      }
    } catch (err) {
      consoleLog(err, "");
      // showErrorToast(t("header.something_went_wrong"));
    }
  }

  const showNotification = (subject, message) => {
    const notification = new Notification(subject, {
      body: message,
      icon: CONSUMER_PORTAL_URL + "favicon.ico",
    });
    notification.onclick = (e) => {
      window.location.href = CONSUMER_PORTAL_URL + "conversations";
    };
  };

  const enableDesktopNotification = (subject, message) => {
    if (!window.Notification) {
    } else {
      // check if permission is already granted
      if (Notification.permission === "granted") {
        // show notification here
        showNotification(subject, message);
      } else {
        // request permission from user
        Notification.requestPermission()
          .then(function (p) {
            if (p === "granted") {
              // show notification here
              showNotification(subject, message);
            } else {
              // do nothing
            }
          })
          .catch(function (err) {
            console.error(err);
          });
      }
    }
  };

  const navigateToWebsite = (company_information) => {
    if (company_information && company_information.website) {
      let website = decryptme(company_information.website);
      if (
        website.length > 0 &&
        !decryptme(company_information.website).includes("http")
      ) {
        website = "https://" + website;
        window.open(website, "_blank");
      }
      if (
        website.length > 0 &&
        decryptme(company_information.website)?.includes("http")
      ) {
        window.open(website, "_blank");
      }

      if (website.length === 0) {
        let navigationURL =
          CONSUMER_PORTAL_URL + "welcome?Ref=" + agentReferralCode;

        window.location.replace(navigationURL);
        getAgentCompanyInfo();
      }
    } else {
      props.history.push(
        !login ? UnderWritingRoutes.HOME : DashboardRoute.DASHBOARD
      );
    }
  };

  const handleModel = (name, isHide) => {
    setFormData({ ...formData, [name]: isHide });
  };

  useEffect(() => {
    const isAuthorized = getJwt() ? true : false;
    if (isAuthorized) {
      connect();
      getNotifications();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getLastAccessedScreenName = () => {
    if (path === DashboardRoute.DASHBOARD) return "Dashboard";
    else if (path === DashboardRoute.UNDERPROCESS) return "Processing";
    else if (path === DashboardRoute.CONVERSATIONS) return "Conversations";
    else if (path === DashboardRoute.NOTIFICATIONS) return "Notifications";
    else if (path === DashboardRoute.MYPROFILE) return "My Profile";
    else return undefined;
  };

  const setCurrentAccessedScreen = async (logoutPath = undefined) => {
    if (getLastAccessedScreenName() || logoutPath) {
      const body = {
        lastScreenAccessed: logoutPath
          ? logoutPath
          : getLastAccessedScreenName(),
      };
      const apiEndPoint = API_URL_CLIENT + "clientSettings";
      const isAuthorized = getJwt() ? true : false;

      let response = await commonApiCall(
        apiEndPoint,
        "PATCH",
        body,
        null,
        isAuthorized
      );

      if (response.status.code === 200) {
        if (logoutPath) {
          try {
            let isAuthorized = true;
            let response = await logout(
              API_URL_CLIENT + "signOut",
              "get",
              isAuthorized
            );
            if (response.status.code === 200) {
              setJwt("");
              // setLogin(true);
              props.history.push(UnderWritingRoutes.LOGIN);
              await clearUnderWriting();
              await getAgentCompanyInfo();
            } else {
              showErrorToast(response.status.message);
            }
          } catch (err) {
            showErrorToast(t("header.something_went_wrong"));
          }
        }
      } else {
        showErrorToast(response?.data?.status?.message);
      }
    }
  };

  useEffect(() => {
    return () => {
      consoleLog("Disconnected. See you next time!");
      if (stompClient && stompClient.connected) {
        stompClient.disconnect(function () {
          consoleLog("Disconnected. See you next time!");
        });
      }
    };
  }, []);

  const showAgentHeader =
    company_information &&
    company_information?.contactInformation &&
    (path === UnderWritingRoutes.GET_START ||
      path === UnderWritingRoutes.INVESTMENT_TYPE ||
      path === UnderWritingRoutes.YOUR_FREQUENCY ||
      path === UnderWritingRoutes.INSURE_TYPE ||
      path === UnderWritingRoutes.POLICY_TERM ||
      path === UnderWritingRoutes.POLICY_TYPE ||
      path === UnderWritingRoutes.YOUR_DEPOSIT ||
      path === UnderWritingRoutes.YOUR_GENDER ||
      path === UnderWritingRoutes.YOUR_AGE ||
      path === UnderWritingRoutes.YOUR_HEALTH ||
      path === UnderWritingRoutes.SOMKING_HABIT ||
      path === UnderWritingRoutes.MEDICARE_ENROLL ||
      path === UnderWritingRoutes.MEDICARE_PLAN_LIST ||
      path === UnderWritingRoutes.CITIZENSHIP ||
      path === UnderWritingRoutes.ZIPCODE ||
      path === UnderWritingRoutes.PLANS ||
      path === UnderWritingRoutes.FACT_FINDER ||
      path === UnderWritingRoutes.FACT_FINDER_2 ||
      path === UnderWritingRoutes.FACT_FINDER_3 ||
      path === UnderWritingRoutes.LOGIN_AGENT_CHAT ||
      path === UnderWritingRoutes.AFFILIATE_BANNER_FLOW ||
      path === UnderWritingRoutes.GUEST_BASIC_DETAILS ||
      path === UnderWritingRoutes.MANUAL_FORM ||
      path === UnderWritingRoutes.CLIENT_FORM ||
      path === UnderWritingRoutes.INFORMATION_OPTION ||
      path === ApprovalStatus.APPLICATION_STATUS ||
      path === UnderWritingRoutes.LOGIN_MANUALFORM ||
      path === UnderWritingRoutes.REGISTER_MANUALFORM ||
      path === UnderWritingRoutes.SINGLE_PAGE ||
      path === UnderWritingRoutes.BENEFICIARY ||
      path === UnderWritingRoutes.SBLI_PAGE ||
      path === UnderWritingRoutes.SBLI_USER_DETAIL ||
      path === UnderWritingRoutes.SBLI_TERMS ||
      path === UnderWritingRoutes.SBLI_THIRD_PARTY ||
      path === UnderWritingRoutes.SBLI_COVERAGE ||
      path === UnderWritingRoutes.SBLI_CONGRATULATIONS ||
      path === UnderWritingRoutes.SBLI_DOC ||
      path === UnderWritingRoutes.SBLI_PAY ||
      path === UnderWritingRoutes.ASSURITY_LAYOUT ||
      path === UnderWritingRoutes.PRE_QUESTIONS ||
      path === UnderWritingRoutes.SBLI_COVERAGE ||
      path === UnderWritingRoutes.ASSURITY_LAYOUT ||
      path === UnderWritingRoutes.ASSURITY_PAY ||
      path === UnderWritingRoutes.ASSURITY_UNDERWRITING ||
      path === UnderWritingRoutes.ASSURITY_USER_DETAIL ||
      path === UnderWritingRoutes.ASSURITY_PRE_APPROVAL ||
      path === UnderWritingRoutes.ASSURITY_PRE_DENIED ||
      path === UnderWritingRoutes.ASSURITY_LIMITATION ||
      path === UnderWritingRoutes.ASSURITY_BENE ||
      path === UnderWritingRoutes.ASSURITY_WITH_CHANGES ||
      path === UnderWritingRoutes.ASSURITY_NOTICE ||
      path === UnderWritingRoutes.ASSURITY_POLICY ||
      path === UnderWritingRoutes.ASSURITY_PREVIEW ||
      path === UnderWritingRoutes.ASSURITY_DOCUMENTS ||
      path === UnderWritingRoutes.ASSURITY_POLICY_DECLINE ||
      path === ApprovalStatus.APPROVAL_PROCESS);

  const showApplicationId =
    !company_information &&
    (path === UnderWritingRoutes.GET_START ||
      path === UnderWritingRoutes.INVESTMENT_TYPE ||
      path === UnderWritingRoutes.YOUR_FREQUENCY ||
      path === UnderWritingRoutes.INSURE_TYPE ||
      path === UnderWritingRoutes.POLICY_TERM ||
      path === UnderWritingRoutes.POLICY_TYPE ||
      path === UnderWritingRoutes.YOUR_DEPOSIT ||
      path === UnderWritingRoutes.YOUR_GENDER ||
      path === UnderWritingRoutes.YOUR_AGE ||
      path === UnderWritingRoutes.YOUR_HEALTH ||
      path === UnderWritingRoutes.SOMKING_HABIT ||
      path === UnderWritingRoutes.MEDICARE_ENROLL ||
      path === UnderWritingRoutes.MEDICARE_PLAN_LIST ||
      path === UnderWritingRoutes.CITIZENSHIP ||
      path === UnderWritingRoutes.ZIPCODE ||
      path === UnderWritingRoutes.PLANS ||
      path === UnderWritingRoutes.SINGLE_PAGE ||
      path === UnderWritingRoutes.FACT_FINDER ||
      path === UnderWritingRoutes.FACT_FINDER_2 ||
      path === UnderWritingRoutes.FACT_FINDER_3 ||
      path === UnderWritingRoutes.LOGIN_AGENT_CHAT ||
      path === UnderWritingRoutes.AFFILIATE_BANNER_FLOW ||
      path === UnderWritingRoutes.GUEST_BASIC_DETAILS ||
      path === UnderWritingRoutes.MANUAL_FORM ||
      path === UnderWritingRoutes.CLIENT_FORM ||
      path === UnderWritingRoutes.INFORMATION_OPTION ||
      path === ApprovalStatus.APPLICATION_STATUS ||
      path === UnderWritingRoutes.LOGIN_MANUALFORM ||
      path === UnderWritingRoutes.SBLI_PAGE ||
      path === UnderWritingRoutes.REGISTER_MANUALFORM ||
      path === ApprovalStatus.APPROVAL_PROCESS);

  const { policyData, chatModel, isAnonymousChat, chatDetails } = formData;
  let login = getJwt() === null || getJwt() === "" ? false : true;
  return (
    <>
      {(company_information?.hideIlifeHeader === true &&
        checkIsInIframe() == true) ||
      (isWelcomePage === true &&
        company_information?.hideIlifeHeader === true &&
        checkIsInIframe() == true) ||
      (isAboutPage === true &&
        company_information?.hideIlifeHeader === true &&
        checkIsInIframe() == true) ? (
        <></>
      ) : (
        <React.Fragment>
          <header sticky='top'>
            <div
              className={
                isWelcomePage
                  ? "pb-0 welcome-header custom-nav"
                  : "container pb-0 custom-nav"
              }
            >
              {/*Begin: Navbar */}
              <Navbar expand='lg'>
                {/*Begin: Logo */}
                <Navbar.Brand>
                  <h1>
                    {!isEmpty(company_information) ? (
                      <React.Fragment>
                        {/* <Link
                          to={"#"}
                          onClick={() => navigateToWebsite(company_information)}
                        >
                          <img
                            src={
                              company_information && company_information.logo
                                ? company_information.logo
                                : "/assets/images/logo.svg"
                            }
                            alt="iLife"
                            // title={
                            //   company_information.website
                            //     ? decryptme(company_information.website)
                            //     : company_information.companyName
                            //     ? decryptme(company_information.companyName)
                            //     : t("header.iLife")
                            // }
                            className={
                              company_information && company_information.logo
                                ? "profile-logo ilife-profile-logo"
                                : ""
                            }
                            width={
                              company_information && company_information.logo
                                ? undefined
                                : "130"
                            }
                            height={
                              company_information && company_information.logo
                                ? undefined
                                : "38"
                            }
                          />
                        </Link> */}
                        <img
                          src={
                            company_information && company_information.logo
                              ? company_information.logo
                              : "/assets/images/logo.svg"
                          }
                          alt='iLife'
                          // title={
                          //   company_information.website
                          //     ? decryptme(company_information.website)
                          //     : company_information.companyName
                          //     ? decryptme(company_information.companyName)
                          //     : t("header.iLife")
                          // }
                          className={
                            company_information && company_information.logo
                              ? "profile-logo ilife-profile-logo"
                              : ""
                          }
                          width={
                            company_information && company_information.logo
                              ? undefined
                              : "130"
                          }
                          height={
                            company_information && company_information.logo
                              ? undefined
                              : "38"
                          }
                        />
                        {/* {company_information?.showCompany ? <> */}
                        {company_information &&
                          company_information.companyName !== undefined && (
                            <p
                              className={
                                login
                                  ? "company-info-logged-in mb-0 pb-0"
                                  : "company-info mb-0 pb-0"
                              }
                            >
                              {decryptme(company_information.companyName)}
                              <span className='powered'>
                                {t("header.powered_by_iLife")}
                              </span>
                            </p>
                          )}
                        {/* </> : null} */}
                      </React.Fragment>
                    ) : (
                      <Link
                        to={
                          !login
                            ? UnderWritingRoutes.HOME
                            : DashboardRoute.DASHBOARD
                        }
                      >
                        <img
                          src={
                            consumer && consumer.companyLogo
                              ? consumer.companyLogo
                              : "/assets/images/logo.svg"
                          }
                          alt={t("header.iLife")}
                          title={t("header.iLife")}
                          className={
                            consumer && consumer.companyLogo
                              ? "profile-logo"
                              : ""
                          }
                          width={
                            consumer && consumer.companyLogo ? undefined : "130"
                          }
                          height={
                            consumer && consumer.companyLogo ? undefined : "38"
                          }
                        />
                      </Link>
                    )}
                  </h1>
                </Navbar.Brand>

                {/*End: Logo */}
                {/*Begin: Collapsing Navbar */}
                <div className='header-right'>
                  {/*Begin: Login Navbar */}

                  {(path === UnderWritingRoutes.HOME ||
                    path === UnderWritingRoutes.UNDERWRITING_WELCOME ||
                    path ===
                      UnderWritingRoutes.UNDERWRITING_WELCOME +
                        "?page=about") && (
                    <>
                      <button
                        className='navbar-toggler'
                        type='button'
                        data-toggle='collapse'
                        data-target='#navbarSupportedContent'
                        aria-controls='navbarSupportedContent'
                        aria-expanded='false'
                        aria-label='Toggle navigation'
                      >
                        <span className='navbar-toggler-icon'></span>
                      </button>

                      <div
                        className='collapse navbar-collapse '
                        id='navbarSupportedContent'
                      >
                        <a
                          href='#!'
                          className='close-icon'
                          data-toggle='collapse'
                          data-target='#navbarSupportedContent'
                          aria-controls='navbarSupportedContent'
                          aria-expanded='false'
                          aria-label='Toggle navigation'
                        ></a>
                        <ul className='navbar-nav ml-auto'>
                          <li
                            className={
                              path === UnderWritingRoutes.HOME ||
                              (path ===
                                UnderWritingRoutes.UNDERWRITING_WELCOME &&
                                isAboutPage === false)
                                ? "nav-item active"
                                : "nav-item"
                            }
                            data-target='#navbarSupportedContent'
                            data-toggle='collapse'
                          >
                            <Link
                              to={
                                UnderWritingRoutes.UNDERWRITING_WELCOME +
                                "?Ref=" +
                                company_information?.refferalCode
                              }
                              title={t("header.home")}
                            >
                              Home
                            </Link>
                          </li>
                          <li
                            className={
                              path === UnderWritingRoutes.HOME ||
                              (path ===
                                UnderWritingRoutes.UNDERWRITING_WELCOME &&
                                isAboutPage === true)
                                ? "nav-item active"
                                : "nav-item"
                            }
                            data-target='#navbarSupportedContent'
                            data-toggle='collapse'
                          >
                            <Link
                              to={
                                UnderWritingRoutes.UNDERWRITING_WELCOME +
                                "?page=about"
                              }
                              title={
                                t("header.about") +
                                " " +
                                decryptme(company_information?.firstName)
                              }
                            >
                              About {decryptme(company_information?.firstName)}
                            </Link>
                          </li>
                          {!login && (
                            <li
                              className='nav-item'
                              data-target='#navbarSupportedContent'
                              data-toggle='collapse'
                            >
                              <Link
                                to={UnderWritingRoutes.LOGIN}
                                title={t("header.log_in")}
                              >
                                {t("header.log_in")}
                              </Link>
                            </li>
                          )}
                        </ul>
                      </div>
                    </>
                  )}

                  {/*End: Login Navbar */}
                  <React.Fragment>
                    {showApplicationId &&
                      underwriting &&
                      underwriting.applicationId && (
                        <UnderwritingApplicationFooter
                          underwriting={underwriting}
                          showApplicationId={showApplicationId}
                          t={t}
                        />
                      )}
                  </React.Fragment>
                  {showAgentHeader && (
                    <HeaderAgentInfo
                      company_information={company_information}
                    />
                  )}
                  {/*End: Login Link and User Profile Dopdown */}
                  {login &&
                    (path !== CmsPageRoutes.ABOUT_US ||
                      path !== CmsPageRoutes.CONTACT_US ||
                      path !== CmsPageRoutes.FAQ) && (
                      <div className='profile-dropdown'>
                        <div className='dropdown'>
                          <span
                            className='notification-icon'
                            title={t("header.notification")}
                            id='dropdownNotification'
                            data-toggle='dropdown'
                            aria-haspopup='true'
                            aria-expanded='false'
                            onClick={() => getNotifications(true)}
                          >
                            <span className='material-icons'>
                              notifications
                            </span>
                            {unReadNotificationCount !== 0 && (
                              <span className='counter-icon'>
                                {unReadNotificationCount}
                              </span>
                            )}
                          </span>
                          <div
                            className='dropdown-menu dropdown-menu-notifications dropdown-menu-right'
                            aria-labelledby='dropdownNotification'
                          >
                            {!isEmpty(notificationListing) ? (
                              <React.Fragment>
                                <PerfectScrollbar>
                                  {notificationListing.map(
                                    (notification, key) => {
                                      return (
                                        <div
                                          className='notification-outer'
                                          key={key}
                                        >
                                          <div
                                            className='notification-msg'
                                            style={{
                                              cursor: "pointer",
                                            }}
                                            onClick={() =>
                                              getPolicyDetails(notification)
                                            }
                                          >
                                            <h4 className='notification-title'>
                                              {notification.data.subject}
                                            </h4>
                                            <h4
                                              style={{
                                                wordBreak: "break-word",
                                              }}
                                            >
                                              {notification.data.message !==
                                                null &&
                                                truncate(
                                                  notification.data.message,
                                                  45
                                                )}
                                            </h4>
                                          </div>
                                          <div className='notification-date'>
                                            <h6>
                                              {moment(
                                                notification.createdDate
                                              ).format("hh:mm a")}{" "}
                                              •{" "}
                                              {dateFormate(
                                                notification.createdDate,
                                                "MM/DD/YYYY"
                                              )}
                                            </h6>
                                          </div>
                                        </div>
                                      );
                                    }
                                  )}
                                </PerfectScrollbar>
                                <Link
                                  to={DashboardRoute.NOTIFICATIONS}
                                  className='dropdown-item view-notification'
                                >
                                  {t("header.view_all_notifications")}
                                </Link>
                              </React.Fragment>
                            ) : (
                              <div className='notification-outer'>
                                <div className='notification-msg pl-2 pr-2'>
                                  {t(
                                    "header.there_are_no_notifications_to_display"
                                  )}
                                </div>
                              </div>
                            )}
                          </div>
                        </div>

                        {login &&
                          (path !== CmsPageRoutes.ABOUT_US ||
                            path !== CmsPageRoutes.CONTACT_US ||
                            path !== CmsPageRoutes.FAQ) && (
                            <>
                              <div className='dropdown'>
                                <div
                                  className='profile-img dropdown-toggle'
                                  id='dropdownMenuButton'
                                  data-toggle='dropdown'
                                  aria-haspopup='true'
                                  aria-expanded='false'
                                >
                                  <img
                                    src={
                                      profilePic
                                        ? profilePic
                                        : "/assets/images/user-dp-demo.jpg"
                                    }
                                    alt=''
                                    title=''
                                    id='side-img-user'
                                    onError={(e) =>
                                      errorImageReplace("side-img-user")
                                    }
                                  />
                                </div>

                                <div
                                  className='dropdown-menu dropdown-menu-right'
                                  aria-labelledby='dropdownMenuButton'
                                >
                                  <Link
                                    to={DashboardRoute.DASHBOARD}
                                    className='dropdown-item'
                                    title={t("header.dashboard")}
                                  >
                                    <span className='dropdown-icon material-icons'>
                                      {" "}
                                      dashboard
                                    </span>
                                    {t("header.dashboard")}
                                  </Link>

                                  <Link
                                    to={DashboardRoute.MYPROFILE}
                                    className='dropdown-item'
                                    title={t("header.my_profile")}
                                  >
                                    <span className='dropdown-icon material-icons'>
                                      {" "}
                                      account_circle
                                    </span>
                                    {t("header.my_profile")}
                                  </Link>
                                  <Link
                                    to={DashboardRoute.SETTING}
                                    className='dropdown-item'
                                    title={t("header.setting")}
                                  >
                                    <span className='dropdown-icon material-icons'>
                                      settings
                                    </span>
                                    {t("header.setting")}
                                  </Link>

                                  <Link
                                    to='#'
                                    className='dropdown-item'
                                    title={t("header.logout")}
                                    onClick={() => logOut()}
                                  >
                                    <span className='dropdown-icon material-icons'>
                                      {" "}
                                      input
                                    </span>
                                    {t("header.logout")}
                                  </Link>
                                </div>
                              </div>
                              {path === "/" ||
                              path === "/index" ||
                              path === CmsPageRoutes.ABOUT_US ||
                              path === CmsPageRoutes.CONTACT_US ||
                              path === CmsPageRoutes.FAQ ? (
                                <Navbar.Toggle aria-controls='basic-navbar-nav'>
                                  <span className='material-icons'>menu</span>
                                </Navbar.Toggle>
                              ) : path ===
                                  UnderWritingRoutes.UNDERWRITING_WELCOME ||
                                path === UnderWritingRoutes.HOME ||
                                path === UnderWritingRoutes.GET_START ||
                                path === UnderWritingRoutes.INVESTMENT_TYPE ||
                                path === UnderWritingRoutes.YOUR_FREQUENCY ||
                                path === UnderWritingRoutes.INSURE_TYPE ||
                                path === UnderWritingRoutes.POLICY_TERM ||
                                path === UnderWritingRoutes.POLICY_TYPE ||
                                path === UnderWritingRoutes.YOUR_DEPOSIT ||
                                path === UnderWritingRoutes.YOUR_GENDER ||
                                path === UnderWritingRoutes.YOUR_AGE ||
                                path === UnderWritingRoutes.YOUR_HEALTH ||
                                path === UnderWritingRoutes.SOMKING_HABIT ||
                                path === UnderWritingRoutes.MEDICARE_ENROLL ||
                                path ===
                                  UnderWritingRoutes.MEDICARE_PLAN_LIST ||
                                path === UnderWritingRoutes.CITIZENSHIP ||
                                path === UnderWritingRoutes.ZIPCODE ||
                                path === UnderWritingRoutes.PLANS ||
                                path === UnderWritingRoutes.FACT_FINDER ||
                                path === UnderWritingRoutes.MANUAL_FORM ||
                                path === UnderWritingRoutes.CLIENT_FORM ||
                                path ===
                                  UnderWritingRoutes.INFORMATION_OPTION ||
                                path === ApprovalStatus.APPLICATION_STATUS ||
                                path === UnderWritingRoutes.SINGLE_PAGE ||
                                path ===
                                  ApprovalStatus.APPROVAL_PROCESS ? null : (
                                <div className='dropdown responsive-menu'>
                                  <Link
                                    to='#'
                                    className='material-icons'
                                    title='menu'
                                    onClick={getClick}
                                  >
                                    menu
                                  </Link>
                                </div>
                              )}
                            </>
                          )}
                      </div>
                    )}
                  {/*End: Collapsing Navbar */}
                </div>
              </Navbar>
              {/*End: Navbar */}
            </div>
          </header>
          <div className='sidebar-overlay' onClick={closeSidebar}></div>
          <div
            className={
              chatModel === false
                ? "conversation-popup"
                : "conversation-popup active"
            }
          >
            {chatModel && (
              <PrivateChat
                conversationDetails={policyData}
                handleModel={handleModel}
                chatModel={chatModel}
                t={t}
              />
            )}
            {isAnonymousChat && (
              <AnonymousChat
                t={t}
                isChat={true}
                anonymousChatDetail={chatDetails}
                handleChatModel={handleModel}
              />
            )}
          </div>
          <div
            className={
              chatModel === false ? "conv-overlay" : "conv-overlay active"
            }
          ></div>
        </React.Fragment>
      )}
    </>
  );
};
const mapStateToProps = (state) => ({
  consumer: state.consumer.consumerdetails,
  underwriting: state.underwriting.underwriting,
  company_information: state.underwriting.companyInformation,
  profile_picture: state.underwriting.profile_pic,
});
export default memo(
  connect(mapStateToProps, {
    logout,
    commonApiCall,
    clearCompanyInformation,
    clearUnderWriting,
  })(Header)
);
