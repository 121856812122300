import React from "react";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { withRouter } from "react-router-dom";
import {
  commonApiCall,
  getJwt,
  UNDERWRITING_DETAILS,
} from "../../../redux/actions";
import { connect } from "react-redux";
import CheckoutForm from "./checkout-assurity";

const stripePromise = loadStripe("pk_test_g89o9VnNmX0ipv3mbDlh3m9t");
const AssurityPayment = ({ commonApiCall, ...props }) => {
  return (
    <Elements stripe={stripePromise}>
      <CheckoutForm />
    </Elements>
  );
};

const mapStateToProps = (state) => ({
  underwriting: state.underwriting.underwriting,
  consumer: state.consumer?.consumerdetails,
  applicationCarrierInfo: state.underwriting.applicationCarrierInfo,
});

export default connect(mapStateToProps, { commonApiCall })(
  withRouter(AssurityPayment)
);
