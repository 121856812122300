const env = {
  production: {
    API_URL: "https://api-gateway.ilife.tech/",
    API_URL_CLIENT: "https://api-gateway.ilife.tech/ilifeService/",
    API_URL_UNDERWRITING: "https://api-gateway.ilife.tech/underwritingService/",
    API_URL_CHATSERVICE: "https://chat-service.ilife.tech/",
    API_URL_PAYMENTSERVICE: "https://api-gateway.ilife.tech/paymentService/",
    API_URL_MUNICH: "https://api-gateway.ilife.tech/munichreService/",
    API_URL_HUMAN_API: "https://api-gateway.ilife.tech/humanapiService/",
    AGENT_PORTAL_URL: "https://agent.ilife.tech/",
    CONSUMER_PORTAL_URL: "https://client.ilife.tech/",
    LANDING_PAGE_URL: 'https://ilife.tech/',
    CONSUMER_DOMAIN_NAME: "client",
    CLIENT_DOMAIN_NAME: ".ilife.tech",
    PORT: 3000,
    ALGORITHM: process.env.REACT_APP_ALGORITHM,
    ALGORITHM_KEY: process.env.REACT_APP_ALGORITHM_KEY,
    ALGORITHM_IV: process.env.REACT_APP_ALGORITHM_IV,
    ZENDESK_KEY: process.env.REACT_APP_ZENDESK_KEY,
    GEO_CODES_KEY:process.env.REACT_APP_GEOCODES_KEY
  },
  staging: {
    API_URL: "https://beta-api-gateway.ilife.tech/",
    API_URL_CLIENT: "https://beta-api-gateway.ilife.tech/ilifeService/",
    API_URL_UNDERWRITING: "https://beta-api-gateway.ilife.tech/underwritingService/",
    API_URL_CHATSERVICE: "https://beta-chat-service.ilife.tech/",
    API_URL_PAYMENTSERVICE: "https://beta-api-gateway.ilife.tech/paymentService/",
    API_URL_MUNICH: "https://beta-api-gateway.ilife.tech/munichreService/",
    API_URL_HUMAN_API: "https://beta-api-gateway.ilife.tech/humanapiService/",
    AGENT_PORTAL_URL: "https://agent.ilife.tech/",
    CONSUMER_PORTAL_URL: "https://client.ilife.tech/",
    LANDING_PAGE_URL: 'https://ilifestaging.tech/',
    CONSUMER_DOMAIN_NAME: "client",
    CLIENT_DOMAIN_NAME: ".ilifestaging.tech",
    PORT: 3000,
    ALGORITHM: process.env.REACT_APP_ALGORITHM,
    ALGORITHM_KEY: process.env.REACT_APP_ALGORITHM_KEY,
    ALGORITHM_IV: process.env.REACT_APP_ALGORITHM_IV,
    ZENDESK_KEY: process.env.REACT_APP_ZENDESK_KEY,
    GEO_CODES_KEY:process.env.REACT_APP_GEOCODES_KEY
  },
  development: {
    API_URL: "http://10.2.1.202:9000/",
    API_URL_CLIENT: "http://10.2.1.202:9000/ilifeService/",
    API_URL_UNDERWRITING: "http://10.2.1.202:9000/underwritingService/",
    API_URL_CHATSERVICE: "http://10.2.1.202:9000/chatService/",
    API_URL_PAYMENTSERVICE: "http://10.2.1.202:9000/paymentService/",
    API_URL_MUNICH: "http://10.2.1.202:9000/munichreService/",
    API_URL_HUMAN_API: "http://10.2.1.202:9000/humanapiService/",
    AGENT_PORTAL_URL: "http://10.2.1.202:3000/",
    CONSUMER_PORTAL_URL: "http://ilife-client.devpress.net/",
    LANDING_PAGE_URL: 'http://ilife-tech.devpress.net',
    CONSUMER_DOMAIN_NAME: "ilife-client",
    CLIENT_DOMAIN_NAME: ".devpress.net",
    PORT: 3000,
    ALGORITHM: process.env.REACT_APP_ALGORITHM,
    ALGORITHM_KEY: process.env.REACT_APP_ALGORITHM_KEY,
    ALGORITHM_IV: process.env.REACT_APP_ALGORITHM_IV,
    ZENDESK_KEY: process.env.REACT_APP_ZENDESK_KEY,
    GEO_CODES_KEY:process.env.REACT_APP_GEOCODES_KEY
  },
  local: {
    API_URL: "http://10.2.1.202:9000/",
    API_URL_CLIENT: "http://10.2.1.202:9000/ilifeService/",
    API_URL_UNDERWRITING: "http://10.2.1.202:9000/underwritingService/",
    API_URL_CHATSERVICE: "http://10.2.1.202:9000/chatService/",
    API_URL_PAYMENTSERVICE: "http://10.2.1.202:9000/paymentService/",
    API_URL_HUMAN_API: "http://10.2.1.202:9000/humanapiService/",
    API_URL_MUNICH: "http://10.2.1.202:9000/munichreService/",
    AGENT_PORTAL_URL: "http://10.2.1.202:3000/",
    CONSUMER_PORTAL_URL: "http://ilife-client.devpress.net/",
    LANDING_PAGE_URL: 'http://ilife-tech.devpress.net',
    CONSUMER_DOMAIN_NAME: "ilife-client",
    PORT: 3000,
    ALGORITHM: process.env.REACT_APP_ALGORITHM,
    ALGORITHM_KEY: process.env.REACT_APP_ALGORITHM_KEY,
    ALGORITHM_IV: process.env.REACT_APP_ALGORITHM_IV,
    ZENDESK_KEY: process.env.REACT_APP_ZENDESK_KEY,
    GEO_CODES_KEY:process.env.REACT_APP_GEOCODES_KEY
  }
};

module.exports = env[process.env.REACT_APP_NODE_ENV];