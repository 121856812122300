import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useLocation, withRouter } from "react-router-dom";
import {
  commonApiCall,
  getJwt,
  setAssurityProgressCount,
} from "../../../redux/actions";
import { decryptme, generatePdfBase64, showErrorToast, UnderWritingRoutes } from "../../../utils";
import { API_URL_UNDERWRITING } from "../../../config/configs";
import moment from "moment";
// import assurityBase from "./assurity-base.json";
const underwritingKeys = ["approved", "approved_with_changes"];

const AssurityPreview = ({
  setAssurityProgressCount,
  assurityCount,
  t,
  underwriting,
  commonApiCall,
  ...props
}) => {
  const { applicationId } = underwriting;
  const [agreeTerms, setAgreeTerm] = useState(false);

  const [previewDocument, setPreviewDocument] = useState(
    []
    // JSON.parse(assurityBase.data)
  );

  const [loading, setLoading] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  const location = useLocation();

  const getHashValue = (key) => {
    const matches = location.hash.match(new RegExp(key + "=([^&]*)"));
    return matches ? matches[1] : null;
  };

  const getDecision = async (policy) => {
    setLoading(true);
    try {
      const apiEndPoint =
        API_URL_UNDERWRITING +
        "getDecisions?applicationId=" +
        underwriting?.applicationId;

      const response = await commonApiCall(apiEndPoint, "get", {}, "");

      if (response.status.code === 200) {
        // setOptionList(re)
        const data = JSON.parse(response.data);

        if (
          underwritingKeys.indexOf(data.underwritingStatus.toLowerCase()) > -1
        ) {
          props.history.push(
            UnderWritingRoutes.ASSURITY_POLICY +
              "?policy=" +
              policy.PolicyNumber +
              "&underWritingStatus=" +
              data.underwritingStatus.toLowerCase()
          );

          //  localStorage.setItem("persist::ass::bene", response.data);
          //  getUnderWriting(data);
          // setDecisionData(data);
        } else {
          props.history.push(
            UnderWritingRoutes.ASSURITY_POLICY_DECLINE +
              "?underWritingStatus=" +
              data.underwritingStatus.toLowerCase()
          );
        }
      }
    } catch (err) {
      console.log(err);
      showErrorToast(err);
    }
  };

  const submitDocument = async () => {
    setLoading(true);

    try {
      let tempAsBene = JSON.parse(localStorage.getItem("persist::ass::bene"));
      tempAsBene.primaryInsuranceAgent.commissionPercentage = 100;

      let obj = {};
      obj.address = tempAsBene.product.primaryInsured.address;
      obj.person = {
        dateOfBirth: tempAsBene.product.primaryInsured.person.dateOfBirth,
        name: {
          first: tempAsBene.product.primaryInsured.person.name.first,
          last: tempAsBene.product.primaryInsured.person.name.last,
        },
        socialSecurityNumber:
          tempAsBene.product.primaryInsured.person.socialSecurityNumber,
      };
      let tempProduct = tempAsBene.product;
      tempProduct.payors = {
        primaryPayor: obj,
      };
      tempProduct.owners = {
        primaryOwner: obj,
      };
      tempProduct.issueStateAbbreviation =
        tempAsBene.signedInformation.location.stateAbbreviation;

      tempAsBene.product = tempProduct;

      // tempAsBene.product.payors.primaryPayor = obj;
      // tempAsBene.product.owners.primaryOwner = obj;
      tempAsBene.payment.automaticCreditCard.stripePayment = {
        cardTokenId: decryptme(localStorage.getItem("pay_token")),
        emailAddress: tempAsBene.product.primaryInsured.person.emailAddress,
      };

      tempAsBene.product.primaryInsured.coverages.forEach((ele) => {
        delete ele.premiumAddition;
      });

      tempAsBene.Documents = previewDocument;
      const isAuthorized = getJwt() ? true : false;
      const response = await commonApiCall(
        API_URL_UNDERWRITING +
          "assurityApplication?applicationId=" +
          applicationId,
        "post",
        tempAsBene,
        "",
        isAuthorized
      );
      if (response.status.code === 200) {
        // if(response.)
        setAssurityProgressCount({
          ...assurityCount,
          documentSignProgress: 100,
        });
        const policy = JSON.parse(response.data);
        getDecision(policy);

        // setSbliProgressCount(progressCount + 1);
        // props.history.push(UnderWritingRoutes.SBLI_CONGRATULATIONS);
      } else {
        showErrorToast(response.status.message);
      }
    } catch (err) {
      console.log(err);
      showErrorToast(t("application_status.something_went_wrong"));
    }
  };
  // &basicAuthToken=${window.btoa(
  //           `${process.env.REACT_APP_DOCUSIGN_INTEGRATION_KEY}:${process.env.REACT_APP_DOCUSIGN_SECRET_KEY}`
  //         )}

  useEffect(() => {
    if (previewDocument && previewDocument.length > 0) {
      submitDocument();
    }
  }, [previewDocument]);

  const getPreviewDocuments = async () => {
    setLoading(true);
    try {
      const response = await commonApiCall(
        `${API_URL_UNDERWRITING}envelopeDocuments?applicationId=${
          underwriting?.applicationId
        }&code=${getHashValue("access_token")}`,
        "get",
        {},
        ""
      );

      if (response.status.code === 200) {
        if (response.data == null || response.data.length === 0) {
          setTimeout(() => {
            getPreviewDocuments();
          }, 20000);
          return;
        }
        let document = JSON.parse(response.data);

        const date = new Date();
        const offset = -300; //Timezone offset for EST in minutes.
        const estDate = new Date(date.getTime() + offset * 60 * 1000);
        const hippaDoc = `Ilife Technologies – Assurity Life Insurance Company \n ${decryptme(
          underwriting?.firstName
        )} ${decryptme(
          underwriting?.lastName
        )} has been presented and acknowledged \n Pre-Notice at ${estDate} \n Your Company Name Application ID: ${
          JSON.parse(localStorage.getItem("persist::ass::bene"))?.caseId
        } \n Resident State: ${underwriting?.state?.code}`;
        let pdfBase64String = null;
        await generatePdfBase64(hippaDoc).then ((response) => {
          pdfBase64String = response;
        });
        document.push({
          Name: "HIPPA",
          DocumentType: "MIB_PRENOTICE",
          Base64Content: pdfBase64String,
        });

        setPreviewDocument(document);
      } else {
        setTimeout(() => {
          getPreviewDocuments();
        }, 30000);
        return;
        // showErrorToast(response.)
      }
    } catch (err) {
      console.log(err);

      showErrorToast(err);
    }
    setLoading(false);
  };

  useEffect(() => {
    // if (localStorage.getItem("assurityDomain")) {
    //   window.location.href =
    //     localStorage.getItem("assurityDomain") +
    //     "/assurity-preview#access_token=" +
    //     getHashValue("access_token");
    // } else if (getHashValue("access_token")) {

    // }
    getPreviewDocuments();
  }, []);

  // const onDocumentBack = () =>
  //   setCurrentIndex(currentIndex > 1 ? currentIndex - 1 : 0);

  // const onDocumentNext = () =>
  //   setCurrentIndex(
  //     currentIndex < previewDocument.length - 1
  //       ? currentIndex + 1
  //       : currentIndex
  //   );

  // useEffect(() => {
  //   if (previewDocument.length > 0) {
  //     console.log(
  //       Buffer.from(previewDocument[0].Base64, "utf8").toString("base64")
  //     );
  //   }
  // }, [previewDocument]);
  return (
    <div className="sbli-documents-main mt-5" style={{ maxWidth: "100%" }}>
      <div className="container">
        {/* <h3 className="text-center mb-5">
          Review your Application and Documents
        </h3> */}
        <div className="assurity-loader text-center mt-5">
          <img src="/assets/images/iLifeLoader.gif" />
        </div>
        {/* <div className="pdf-container mt-0 mb-0">
          {loading && (
            <div className="payment-loading text-center mt-5">
              <h3>Please wait...</h3>
              <p>We're processing your data</p>
            </div>
          )}
          {documentLoading ? (
            <div className="text-center">
              <h2>Processsing Documents</h2>
              <p>This may take few minutes</p>
            </div>
          ) : documents.length > 0 && currentDocument ? (
            <iframe
              src={currentDocument.file}
              width={"100%"}
              height={500}
              title={currentDocument.title}
            ></iframe>
          ) : (
            <h2>No Documents</h2>
          )}

          {previewDocument && previewDocument.length > 0 && (
            <iframe
              src={
                "data:application/pdf;base64," +
                previewDocument[currentIndex].Base64Content
              }
              width={"100%"}
              height={700}
            ></iframe>
          )}
        </div>
        <div className="pdf-container-button py-2">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div className="d-flex align-items-center ml-2">
              {previewDocument &&
                previewDocument.length > 0 &&
                currentIndex !== 0 && (
                  <img
                    onClick={onDocumentBack}
                    src="/assets/images/next.png"
                    className="mr-2"
                    style={{ width: 15, cursor: "pointer" }}
                  />
                )}
              <p className="p-0">
                {currentIndex + 1 + "/" + previewDocument.length}
              </p>
            </div>
          </div>
        </div> */}
        {/* <div className="w-100 text-center mt-4">
          <button
            disabled={currentIndex + 1 >= previewDocument.length}
            className="btn btn-default"
            onClick={onDocumentNext}
          >
            Next
          </button>
        </div>
        <div className="form-group mt-4">
          <span className="custom-checkbox">
            <label className="m-0 text-dark">
              <input
                disabled={loading}
                type="checkbox"
                name="isAgree"
                onChange={(e) => handleCheckbox(e)}
              />
              <span></span> I have reviewed ,understand, and agree to the terms
              of each of the above disclosures
            </label>
          </span>
        </div>
        <div className="w-100 text-center">
          <button
            disabled={!agreeTerms || loading}
            className="btn btn-default"
            onClick={submitDocument}
          >
            {loading ? "Processing..." : "Submit"}
          </button>
        </div> */}
        {/* <div className="w-100 text-center mt-3">
          <button
            className="btn btn-link"
            onClick={() => props.history.push("/sbli-coverage")}
          >
            Back
          </button>
        </div> */}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  underwriting: state.underwriting.underwriting,
  assurityCount: state.underwriting.assuProgressCount,
});

export default connect(mapStateToProps, {
  commonApiCall,
  setAssurityProgressCount,
})(withRouter(AssurityPreview));
