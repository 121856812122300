import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

const AssurityLimitation = () => {
  return (
    <div className="assurity-limitation">
      <div className="container">
        <p>
          <strong className="text-dark">
            Standard Policy Limitations and Exclusions
          </strong>
          <br />
          <strong className="text-dark">(Form No. I L1702)</strong>
        </p>

        <p>
          <strong className="text-dark">Note:</strong> Limitations and
          Exclusions may vary by state. The state specific policy form is the
          ultimate authority for any questions about this product.
        </p>

        <p>
          <u>Misstatement of Age and/or Gender</u> - If the Insured
          Person&rsquo;s age and/or gender is misstated in the Application, this
          policy&rsquo;s and any applicable riders&rsquo; benefit amounts will
          be revised to the amount the premium paid would have purchased for the
          correct age and/or gender using published rates in effect on the Issue
          Date.
        </p>

        <p>
          <u>Suicide</u> - If the Insured Person dies by suicide within two
          years of the Issue Date, liability is limited to a refund of premiums
          paid, less benefits paid under any riders. If the Insured Person dies
          by suicide within two years of the last Reinstatement Date, liability
          is limited to a refund of premiums paid since the last Reinstatement
          Date, less benefits paid under any riders.
        </p>

        <p>
          <u>Contestable Period</u> &ndash; The validity of this policy and any
          attached riders may be contested based on material misrepresentations
          made in the initial Application. However, validity of this policy or
          any attached riders may not be contested after it has been in force
          during the Insured Person&rsquo;s lifetime for two years from the
          Issue Date, except for fraudulent misstatements in the Application
          when permitted by applicable law in the state where this policy is
          delivered or issued for delivery.
        </p>

        <p>
          <strong className="text-dark">
            Accident-Only Disability Income Rider Standard Limitations and
            Exclusions
          </strong>
          <br />
          <strong className="text-dark">(Form No. R I0827-T)</strong>
        </p>

        <p>
          The Monthly Benefit will only be paid to the insured if the insured
          becomes totally disabled as the result of and within 180 days of a
          covered accident occurring prior to the insured&rsquo;s 65
          <sup>th</sup> birthday.
        </p>

        <p>
          Benefits are not paid under this rider for Total Disabilities that
          result from the Insured Person:
        </p>

        <ul>
          <li>
            <p>
              being exposed to war or any act of war, declared or undeclared;
            </p>
          </li>
          <li>
            <p>
              actively serving in any of the armed forces, or unit auxiliary
              thereto, including the National Guard or Army Reserve;
            </p>
          </li>
          <li>
            <p>self-inflicting an injury intentionally;</p>
          </li>
          <li>
            <p>participating in or attempting to commit a felony;</p>
          </li>
          <li>
            <p>engaging in an illegal occupation;</p>
          </li>
          <li>
            <p>
              committing or attempting to commit suicide, whether sane or
              insane;
            </p>
          </li>
          <li>
            <p>
              using drugs or alcohol except for prescribed drugs taken as
              prescribed;
            </p>
          </li>
          <li>
            <p>
              piloting a non-commercial aircraft more than 150 hours annually;
            </p>
          </li>
          <li>
            <p>
              &ldquo;flying for pay&rdquo; an aircraft outside of established
              air routes in the United States or Canada;
            </p>
          </li>
          <li>
            <p>
              involved in motor vehicle or boat racing, hang gliding, sky
              diving, mountain or rock climbing, underwater diving and
              professional sports;
            </p>
          </li>
          <li>
            <p>
              traveling outside of the United States or Canada for more than 14
              days;
            </p>
          </li>
          <li>
            <p>
              operating a motor vehicle while under the influenced alcohol or
              drugs; or
            </p>
          </li>
          <li>
            <p>
              a Preexisting Condition during the first 24 months following the
              Issue Date of this rider.
            </p>
          </li>
        </ul>

        <p>
          <strong className="text-dark">
            Monthly Disability Income Rider Standard Limitations and Exclusions
          </strong>
          <br />
          <strong className="text-dark">(Form No. R I0825-T)</strong>
        </p>

        <p>
          The Monthly Benefit will only be paid to the insured if the insured
          becomes totally disabled prior to their 65th birthday.
        </p>

        <p>Benefits are not paid for Total Disabilities that result from:</p>

        <ul>
          <li>
            <p>
              being exposed to war or any act of war, declared or undeclared;
            </p>
          </li>
          <li>
            <p>
              actively serving in any of the armed forces, or unit auxiliary
              thereto, including the National Guard or Army Reserve;
            </p>
          </li>
          <li>
            <p>self-inflicting an injury intentionally;</p>
          </li>
          <li>
            <p>participating in or attempting to commit a felony;</p>
          </li>
          <li>
            <p>engaging in an illegal occupation;</p>
          </li>
          <li>
            <p>
              normal pregnancy (except for Complications of Pregnancy),
              childbirth or elective abortion; or
            </p>
          </li>
          <li>
            <p>
              a Preexisting Condition during the first 24 months following the
              Issue Date of this rider.
            </p>
          </li>
        </ul>

        <p>
          <strong className="text-dark">
            Critical Illness Rider Standard Limitations and Exclusions
          </strong>
          <br />
          <strong className="text-dark">(Form No. R I0762)</strong>
        </p>

        <p>
          Benefits are payable for the insured&rsquo;s first ever diagnosis or
          procedure for one of the specified critical illnesses covered in the
          rider only if:
        </p>

        <ul>
          <li>
            <p>
              the date of diagnosis is while coverage under this rider is in
              force; and
            </p>
          </li>
          <li>
            <p>the specified critical illness definition is satisfied; and</p>
          </li>
          <li>
            <p>
              the specified critical illness is not excluded by name or specific
              description.
            </p>
          </li>
        </ul>

        <p>
          <u>Waiting Period</u>
        </p>

        <p>
          If within 90 days following the Issue Date or last reinstatement date
          of this rider, the Insured Person:
        </p>

        <ul>
          <li>
            <p>
              receives a First Ever Diagnosis of having Invasive Cancer or
              Carcinoma in Situ; or
            </p>
          </li>
          <li>
            <p>
              has exhibited any common or identifiable symptoms or medical
              problems which lead to a Diagnosis of Invasive Cancer or Carcinoma
              in Situ and would cause an ordinary prudent person to seek medical
              advice or treatment
            </p>
          </li>
        </ul>

        <p>
          A reduced percentage of the Benefit Amount will be paid. The
          percentage payable will be:
        </p>

        <ul>
          <li>
            <p>10% of the Benefit Amount for Invasive Cancer; or</p>
          </li>
          <li>
            <p>2.5% of the Benefit Amount for Carcinoma in Situ.</p>
          </li>
        </ul>

        <p>
          Benefits will not be paid under this rider for conditions caused by or
          as the result of the Insured Person:
        </p>

        <ul>
          <li>
            <p>being addicted to drugs or suffering from alcoholism;</p>
          </li>
          <li>
            <p>
              being under the influence of an excitant, depressant,
              hallucinogen, narcotic, or any other drug or intoxicant, including
              those prescribed by a Physician that are misused;
            </p>
          </li>
          <li>
            <p>
              receiving injuries caused directly or indirectly while under the
              influence of a controlled substance or by intoxication as defined
              by the laws and jurisdiction of the geographical area in which the
              loss or cause of loss was incurred;
            </p>
          </li>
          <li>
            <p>participating in or attempting to commit a felony;</p>
          </li>
          <li>
            <p>
              being exposed to war or any act of war, declared or undeclared;
            </p>
          </li>
          <li>
            <p>
              actively serving in any of the armed forces, or unit auxiliary
              thereto, including the National Guard or Army Reserve;
            </p>
          </li>
          <li>
            <p>
              being incarcerated in a penal institution or government detention
              facility;
            </p>
          </li>
          <li>
            <p>engaging in an illegal occupation;</p>
          </li>
          <li>
            <p>self-inflicting an injury intentionally; or</p>
          </li>
          <li>
            <p>
              committing or attempting to commit suicide, while sane or insane.
            </p>
          </li>
        </ul>

        <p>
          <strong className="text-dark">
            Disability Waiver of Premium Rider Standard Limitations and
            Exclusions
          </strong>
          <br />
          <strong className="text-dark">(Form No. R I1703)</strong>
        </p>

        <p>
          The premium waived will correspond to the Premium Mode in effect for
          the policy. Until the Insured Person&rsquo;s Total Disability claim is
          approved, premiums must continue to be paid when due to avoid a lapse
          of the policy. If the Insured Person is not Totally Disabled on their
          65th birthday, this rider&rsquo;s benefits will no longer be provided
          for any Total Disability.
        </p>

        <p>This rider does not cover Total Disability caused by:</p>

        <ul>
          <li>
            <p>war or any act of war, declared or undeclared;</p>
          </li>
          <li>
            <p>
              any attempt at suicide or intentionally self-inflicting an Injury
              or Sickness, while sane or insane;
            </p>
          </li>
          <li>
            <p>participating in a riot, insurrection or an act of terrorism;</p>
          </li>
          <li>
            <p>
              being intoxicated (as defined by the jurisdiction where loss
              occurs) or under the influence of an illegal substance&nbsp;or any
              drug (except as prescribed to the Insured Person by a Physician);
              or
            </p>
          </li>
          <li>
            <p>committing or attempting to commit a felony</p>
          </li>
        </ul>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {})(withRouter(AssurityLimitation));
