import React, { useEffect, useState } from "react";

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  commonApiCall,
  getJwt,
  setSbliProgressCount,
} from "../../redux/actions";
import { API_URL_UNDERWRITING } from "../../config/configs";
import {
  ApprovalStatus,
  showErrorToast,
  UnderWritingRoutes,
} from "../../utils";
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";

const myHeader = (state, previousDocument, nextDocument) => {
  if (!state.currentDocument || state.config?.header?.disableFileName) {
    return null;
  }

  return (
    <>
      <div>{state.currentDocument.uri || ""}</div>
      <div>
        <button onClick={previousDocument} disabled={state.currentFileNo === 0}>
          Previous Document
        </button>
        <button
          onClick={nextDocument}
          disabled={state.currentFileNo >= state.documents.length - 1}
        >
          Next Document
        </button>
      </div>
    </>
  );
};

const SbliDocuments = ({
  t,
  commonApiCall,
  underwriting,
  setSbliProgressCount,
  progressCount,
  ...props
}) => {
  const { applicationId } = underwriting;
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [documentLoading, setDocumentLoading] = useState(false);
  const [agreeTerms, setAgreeTerm] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentDocument, setCurrentDocument] = useState({
    index: 0,
    title: null,
    file: null,
  });

  const [bodyData, setBodyData] = useState([]);

  function isBase64(str = "") {
    try {
      return btoa(atob(str)) == str;
    } catch (err) {
      return false;
    }
  }
  // useEffect(() => {
  //   if (isBase64(currentDocument.file)) {
  //     let blob = base64toBlob(currentDocument.file);
  //     let url = URL.createObjectURL(blob);
  //     setCurrentDocument({ ...currentDocument, file: url });
  //   }
  // }, [currentDocument]);

  const onDocumentBack = () => {
    if (currentDocument.index > 0) {
      setCurrentDocument({
        index: currentDocument.index - 1,
        title: Object.keys(documents[currentDocument.index - 1])[0],
        file: documents[currentDocument.index - 1][
          Object.keys(documents[currentDocument.index - 1])[0]
        ],
      });
    }
  };

  function downloadPDF(name, pdf) {
    const linkSource = `data:application/pdf;base64,${pdf}`;
    const downloadLink = document.createElement("a");
    const fileName = `${name}.pdf`;

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }

  const submitDocument = async () => {
    setLoading(true);
    try {
      let body = {
        applicationId: applicationId,
        documentSign: bodyData,
        userInput: agreeTerms ? "agree" : "decline",
      };
      const isAuthorized = getJwt() ? true : false;
      const response = await commonApiCall(
        API_URL_UNDERWRITING + "submitDocument",
        "post",
        body,
        "",
        isAuthorized
      );
      if (response.status.code === 200) {
        setSbliProgressCount(progressCount + 1);
        props.history.push(UnderWritingRoutes.SBLI_CONGRATULATIONS);
      } else {
        showErrorToast(response.status.message);
      }
    } catch (err) {
      showErrorToast(t("application_status.something_went_wrong"));
    }
    setLoading(false);
  };

  const handleCheckbox = (e) => {
    setAgreeTerm(e.target.checked);
  };

  const onDocumentNext = () => {
    let sign = [...bodyData];

    if (currentDocument.index + 1 <= documents.length) {
      setCurrentDocument({
        index: currentDocument.index + 1,
        title: Object.keys(documents[currentDocument.index + 1])[0],
        file: documents[currentDocument.index + 1][
          Object.keys(documents[currentDocument.index + 1])[0]
        ],
      });
      let findDoc = sign.findIndex(
        (x) => x.form_no === Object.keys(documents[currentDocument.index])[0]
      );
      const stamp = {
        esign_timestamp: new Date().toUTCString(),
        form_no: Object.keys(documents[currentDocument.index + 1])[0],
        doc_full_view_flag: 1,
        esign_flag: 1,
      };
      if (findDoc > -1) {
        sign[currentDocument.index + 1] = stamp;
      } else {
        sign.push(stamp);
      }

      setBodyData(sign);
    }
  };
  console.log(documents);
  const getDocuments = async () => {
    setDocumentLoading(true);
    try {
      let response = await commonApiCall(
        `${API_URL_UNDERWRITING}getDocument?applicationId=${underwriting?.applicationId}`,
        "get",
        {},
        ""
      );
      if (response.status.code === 200) {
        const data = JSON.parse(response.data.formsJson);
        if (data && data.length > 0) {
          let sign = [...bodyData];
          setDocuments(data);
          setCurrentDocument({
            index: 0,
            title: Object.keys(data[0])[0],
            file: data[0][Object.keys(data[0])[0]],
          });

          const stamp = {
            esign_timestamp: new Date().toUTCString(),
            form_no: Object.keys(data[currentDocument.index])[0],
            doc_full_view_flag: 1,
            esign_flag: 1,
          };
          sign.push(stamp);
          setBodyData(sign);
        }
      } else {
        showErrorToast(response.status.message);
      }
    } catch (err) {
      showErrorToast(err);
    }
    setDocumentLoading(false);
  };

  const base64toBlob = (data) => {
    // Cut the prefix `data:application/pdf;base64` from the raw base 64
    const base64WithoutPrefix = data;

    // // .substr(
    //   "data:application/pdf;base64,".length
    // );

    const bytes = atob(base64WithoutPrefix);
    let length = bytes.length;
    let out = new Uint8Array(length);

    while (length--) {
      out[length] = bytes.charCodeAt(length);
    }

    return new Blob([out], { type: "application/pdf" });
  };
  useEffect(() => {
    getDocuments();
  }, []);

  //   const onSavePdf = () => {
  //     if (documents && documents.length > 0) {
  //       documents.forEach((x) => {
  //         let name = Object.keys(x)[0];
  //         console.log(name, x[name]);
  //         downloadPDF(name, x[name]);
  //       });
  //     }
  //   };

  return (
    <div className="sbli-documents-main mt-5">
      <div className="container">
        <h3 className="text-center mb-5">
          Review and Sign your Application and Documents
        </h3>
        <div className="pdf-container mt-0 mb-0">
          {documentLoading ? (
            <div className="text-center">
              <h2>Processsing Documents</h2>
              <p>This may take few minutes</p>
            </div>
          ) : documents.length > 0 && currentDocument ? (
            <div style={{ height: 700, width: "100%", overflow: "auto" }}>
              <DocViewer
                style={{
                  height: 700,
                }}
                config={{
                  header: {
                    disableHeader: true,
                    disableFileName: true,
                    overrideComponent: myHeader,
                  },
                }}
                pluginRenderers={DocViewerRenderers}
                documents={[
                  {
                    uri: "data:application/pdf;base64," + currentDocument.file,
                  },
                ]}
              />
            </div>
          ) : (
            <h2>No Documents</h2>
          )}
        </div>
        <div className="pdf-container-button py-2">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div className="d-flex align-items-center ml-2">
              {documents.length > 0 && currentDocument.index !== 0 && (
                <img
                  onClick={onDocumentBack}
                  src="/assets/images/next.png"
                  className="mr-2"
                  style={{ width: 15, cursor: "pointer" }}
                />
              )}
              <p className="p-0">
                {currentDocument.index + 1 + "/" + documents.length}
              </p>
            </div>
          </div>
        </div>
        <div className="w-100 text-center mt-4">
          <button
            disabled={currentDocument.index + 1 >= documents.length}
            className="btn btn-default"
            onClick={onDocumentNext}
          >
            Next
          </button>
        </div>
        <div className="form-group mt-4">
          <span className="custom-checkbox">
            <label className="m-0 text-dark">
              <input
                disabled={documentLoading || bodyData.length < documents.length}
                type="checkbox"
                name="isAgree"
                onChange={(e) => handleCheckbox(e)}
              />
              <span></span> I have reviewed, understand and agree to the terms
              of each of the above disclosures.
            </label>
          </span>
        </div>
        <div className="w-100 text-center">
          <button
            disabled={bodyData.length < documents.length || !agreeTerms}
            className="btn btn-default"
            onClick={submitDocument}
          >
            {loading ? "Processing..." : "Submit"}
          </button>
        </div>
        {/* <div className="w-100 text-center mt-3">
          <button
            className="btn btn-link"
            onClick={() => props.history.push("/sbli-coverage")}
          >
            Back
          </button>
        </div> */}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  underwriting: state.underwriting.underwriting,
  company_information: state.underwriting.companyInformation,
  direct_link: state.underwriting.direct_link,
  progressCount: state.underwriting.progressCount,
});

export default connect(mapStateToProps, {
  commonApiCall,
  setSbliProgressCount,
})(withRouter(SbliDocuments));
