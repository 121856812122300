import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Button, Card, Accordion } from "react-bootstrap";

const FAQ = ({ t }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <React.Fragment>
      {/* Begin: FAQ */}
      <div className="faqs-content">
        <div className="container pb-0">
          <div className="front-title-block">
            <h4 className="subtitle">{t("faq.faq")}</h4>
            <h3 className="title">
              {t("faq.got_some")} <span className="text-primary d-block">{t("faq.questions?")}</span>
            </h3>
            <p className="common-para">{t("faq.We’ve_got_answers")}</p>
          </div>
          <Accordion defaultActiveKey="0">
            <Card>
              <Accordion.Collapse eventKey="0">
                <Card.Body>
                  <p>
                    {t("faq.permanent_life_insurance_policies_provide_higher_rates_of_return_on_long-term_savings_compared_to_traditional_bank_savings_accounts_and_CDs_Permanent_life_insurance_policies_also_provide_higher_guaranteed_minimum_rates_that_protect_your_investment_principal")}

                  </p>
                  <p>
                    {t("faq.as_an_added_benefit_because_your_cash_value_accumulates_within_an_insurance_policy_the_cash_value_is_considered_tax-free_no_more_headaches_in_filing_and_paying_taxes_on_investment_gains")}

                  </p>
                  <p>
                    {t("faq.lastly_investing_via_a_permanent_life_insurance_policy_ensures_that_you_obtain_automatic_insurance_coverage_as_you_save_and_watch_your_money_grow")}

                  </p>
                </Card.Body>
              </Accordion.Collapse>
              <Accordion.Toggle as={Card.Header} eventKey="0">
                <h4>
                  {t("faq.what_are_the_advantages_of_saving_with_a_life_insurance_company_vs_traditional_savings_accounts_or_stock_investments?")}

                </h4>
                <Button variant="link">
                  <i className="material-icons">keyboard_arrow_right</i>
                </Button>
              </Accordion.Toggle>
            </Card>
            <Card>
              <Accordion.Collapse eventKey="1">
                <Card.Body>
                  <p>
                    {t("faq.life_insurance_companies_are_able_to_provide_policies_with_high_performing_rates_and_strong_guaranteed_returns_based_on_the_quality_of_data_received_by_the_policy_applicant_as_a_result_insurance_companies_consider_the_health_and_financial_stability_of_applicants_when_determining_their_qualification_in_general_the_healthier_you_are_the_more_likely_the_application_will_be_approved_for_a_top_rate_similarly_demonstrated_financial_ability_will_qualify_applicants_for_high-performing_long-term_savings")}

                  </p>
                  <p>
                    {t("faq.in_order_to_maximize_your_rates_and_returns_it_is_in_the_applicant’s_best_interest_to_provide_the_most_accurate_information_possible_to_be_considered_qualified_and_approved_for_a_top_tier_policy")}

                  </p>
                </Card.Body>
              </Accordion.Collapse>
              <Accordion.Toggle as={Card.Header} eventKey="1">
                <h4>
                  {t("faq.why_do_life_insurance_companies_need_my_medical_and_financial_information?")}
                </h4>
                <Button variant="link">
                  <i className="material-icons">keyboard_arrow_right</i>
                </Button>
              </Accordion.Toggle>
            </Card>
            <Card>
              <Accordion.Collapse eventKey="2">
                <Card.Body>
                  <p>
                    {t("faq.surprising_to_many_it_is_actually_more_expensive_to_buy_term_life_insurance_for_most_people_unless_coverage_is_only_necessary_for_a_short_period_of_time")}
                  </p>
                  <p>
                    {t("faq.for_example_with_a_20_year_term_the_term_expires_at_the_20_year_mark_meaning_that_if_you_are_still_alive_at_the_end_of_the_20_year_period_you_no_longer_have_insurance_coverage_and_you_will_not_receive_your_money_back")}

                  </p>
                  <p>
                    {t("faq.for_example_with_a_20_year_term_the_term_expires_at_the_20_year_mark_meaning_that_if_you_are_still_alive_at_the_end_of_the_20_year_period_you_no_longer_have_insurance_coverage_and_you_will_not_receive_your_money_back")}

                  </p>
                </Card.Body>
              </Accordion.Collapse>
              <Accordion.Toggle as={Card.Header} eventKey="2">
                <h4>
                  {t("faq.if_i_just_need_life_insurance_coverage_is_it_cheaper_to_just_buy_term_life_insurance?")}

                </h4>
                <Button variant="link">
                  <i className="material-icons">keyboard_arrow_right</i>
                </Button>
              </Accordion.Toggle>
            </Card>
          </Accordion>
        </div>
      </div>
      {/* End: FAQ */}
    </React.Fragment>
  );
};

export default withRouter(FAQ);
