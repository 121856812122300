import { getJwt } from "../redux/actions/index";
import { UnderWritingRoutes, ApprovalStatus } from "../utils/index";
export function setProcessStep(
  pageUrl,
  insureType = "",
  goalType = "",
  medicareFlag = true
) {
  let processStep;
  switch (pageUrl) {
    case "get-start":
      if (insureType === "MEDICARE") {
        processStep = "24";
      } else if (insureType === "INSURE") {
        processStep = "1";
      } else processStep = "1";
      break;
    case "investment-type":
      processStep = "1";
      break;
    case "your-frequency":
      processStep = "2";
      break;

    case "insure-type":
      processStep = "2";
      break;

    case "your-deposit":
      processStep = "3";
      break;

    case "policy-type":
      processStep = "3";
      break;

    case "policy-term":
      processStep = "4";
      break;

    case "your-gender":
      if (
        (goalType === "INSURE" && insureType === "PERMANENT_LIFE_INSURANCE") ||
        (goalType === "INSURE" && insureType === "FINAL_EXPENSE_INSURANCE")
      )
        processStep = "6";
      else if (
        (goalType === "INSURE" && insureType === "TERM_LIFE_INSURANCE") ||
        (goalType === "MEDICARE" && insureType === "MEDICARE_INSURANCE")
      )
        processStep = "5";
      else processStep = "4";
      break;

    case "your-age":
      if (
        (goalType === "INSURE" && insureType === "PERMANENT_LIFE_INSURANCE") ||
        (goalType === "INSURE" && insureType === "FINAL_EXPENSE_INSURANCE")
      )
        processStep = "7";
      else if (goalType === "INSURE" && insureType === "TERM_LIFE_INSURANCE")
        processStep = "6";
      else if (goalType === "MEDICARE" && insureType === "MEDICARE_INSURANCE")
        processStep = "20";
      else processStep = "5";
      break;

    case "your-health":
      if (
        (goalType === "INSURE" && insureType === "PERMANENT_LIFE_INSURANCE") ||
        (goalType === "INSURE" && insureType === "FINAL_EXPENSE_INSURANCE")
      )
        processStep = "8";
      else if (goalType === "INSURE" && insureType === "TERM_LIFE_INSURANCE")
        processStep = "7";
      else processStep = "6";
      break;

    case "smoking-habit":
      if (
        (goalType === "INSURE" && insureType === "PERMANENT_LIFE_INSURANCE") ||
        (goalType === "INSURE" && insureType === "FINAL_EXPENSE_INSURANCE")
      )
        processStep = "9";
      else if (goalType === "INSURE" && insureType === "TERM_LIFE_INSURANCE")
        processStep = "8";
      else processStep = "7";
      break;

    case "zipcode":
      if (
        (goalType === "INSURE" && insureType === "PERMANENT_LIFE_INSURANCE") ||
        (goalType === "INSURE" && insureType === "FINAL_EXPENSE_INSURANCE")
      )
        processStep = "10";
      else if (goalType === "INSURE" && insureType === "TERM_LIFE_INSURANCE")
        processStep = "9";
      else if (goalType === "MEDICARE" && insureType === "MEDICARE_INSURANCE") {
        if (medicareFlag) {
          processStep = "25";
        } else {
          processStep = "9";
        }
      } else processStep = "8";
      break;

    case "plans":
      if (
        (goalType === "INSURE" && insureType === "PERMANENT_LIFE_INSURANCE") ||
        (goalType === "INSURE" && insureType === "FINAL_EXPENSE_INSURANCE")
      )
        processStep = "11";
      else if (goalType === "INSURE" && insureType === "TERM_LIFE_INSURANCE")
        processStep = "10";
      else processStep = "9";
      break;

    case "manual-form":
      if (
        (goalType === "INSURE" && insureType === "PERMANENT_LIFE_INSURANCE") ||
        (goalType === "INSURE" && insureType === "FINAL_EXPENSE_INSURANCE")
      )
        processStep = "12";
      else if (goalType === "INSURE" && insureType === "TERM_LIFE_INSURANCE")
        processStep = "11";
      else processStep = "10";
      break;

    case "fact-finder":
      if (
        (goalType === "INSURE" && insureType === "PERMANENT_LIFE_INSURANCE") ||
        (goalType === "INSURE" && insureType === "FINAL_EXPENSE_INSURANCE")
      )
        processStep = "13";
      else if (goalType === "INSURE" && insureType === "TERM_LIFE_INSURANCE")
        processStep = "12";
      else processStep = "11";
      break;

    case "fact-finder2":
      if (
        (goalType === "INSURE" && insureType === "PERMANENT_LIFE_INSURANCE") ||
        (goalType === "INSURE" && insureType === "FINAL_EXPENSE_INSURANCE")
      )
        processStep = "14";
      else if (goalType === "INSURE" && insureType === "TERM_LIFE_INSURANCE")
        processStep = "13";
      else processStep = "12";
      break;

    case "fact-finder3":
      if (
        (goalType === "INSURE" && insureType === "PERMANENT_LIFE_INSURANCE") ||
        (goalType === "INSURE" && insureType === "FINAL_EXPENSE_INSURANCE")
      )
        processStep = "15";
      else if (goalType === "INSURE" && insureType === "TERM_LIFE_INSURANCE")
        processStep = "14";
      else processStep = "13";
      break;

    case "information-option":
      if (
        (goalType === "INSURE" && insureType === "PERMANENT_LIFE_INSURANCE") ||
        (goalType === "INSURE" && insureType === "FINAL_EXPENSE_INSURANCE")
      )
        processStep = "16";
      else if (goalType === "INSURE" && insureType === "TERM_LIFE_INSURANCE")
        processStep = "15";
      else processStep = "14";
      break;

    case "application-status":
      if (
        (goalType === "INSURE" && insureType === "PERMANENT_LIFE_INSURANCE") ||
        (goalType === "INSURE" && insureType === "FINAL_EXPENSE_INSURANCE")
      )
        processStep = "17";
      else if (goalType === "INSURE" && insureType === "TERM_LIFE_INSURANCE")
        processStep = "16";
      else processStep = "15";
      break;

    case "login-agent-chat":
      if (
        (goalType === "INSURE" && insureType === "PERMANENT_LIFE_INSURANCE") ||
        (goalType === "INSURE" && insureType === "FINAL_EXPENSE_INSURANCE")
      )
        processStep = "18";
      else if (goalType === "INSURE" && insureType === "TERM_LIFE_INSURANCE")
        processStep = "17";
      else processStep = "16";
      break;

    case "user-details":
      if (
        (goalType === "INSURE" && insureType === "PERMANENT_LIFE_INSURANCE") ||
        (goalType === "INSURE" && insureType === "FINAL_EXPENSE_INSURANCE")
      )
        processStep = "19";
      else if (goalType === "INSURE" && insureType === "TERM_LIFE_INSURANCE")
        processStep = "18";
      else processStep = "17";
      break;

    case "citizenship":
      processStep = "20";
      break;

    case "client-form":
      processStep = "21";
      break;

    case "sbli-page":
      processStep = "22";
      break;
    case "beneficiary":
      processStep = "23";
      break;

    case "medicare-enroll":
      if (goalType === "MEDICARE" && medicareFlag === null) {
        processStep = "24";
      } else if (goalType === "MEDICARE" && medicareFlag !== null) {
        processStep = "4";
      }

      break;

    case "medicare-plan-lists":
      processStep = "9";
      break;

    default:
      processStep = "0";
      break;
  }
  return processStep;
}

export function getProcessStep(
  processStep,
  goalType,
  insureType,
  applyType = "",
  medicareFlag = ""
) {
  let redirectURL;
  const token = getJwt();
  switch (processStep) {
    case "1":
      if (goalType === "INSURE") redirectURL = UnderWritingRoutes.INSURE_TYPE;
      else redirectURL = UnderWritingRoutes.YOUR_FREQUENCY;
      break;

    case "2":
      // if (goalType === "INSURE") redirectURL = UnderWritingRoutes.YOUR_DEPOSIT;
      // else redirectURL = UnderWritingRoutes.YOUR_DEPOSIT;
      redirectURL = UnderWritingRoutes.YOUR_DEPOSIT;
      break;

    case "3":
      if (
        (goalType === "INSURE" && insureType === "PERMANENT_LIFE_INSURANCE") ||
        (goalType === "INSURE" && insureType === "FINAL_EXPENSE_INSURANCE")
      )
        redirectURL = UnderWritingRoutes.YOUR_GENDER;
      else if (goalType === "INSURE" && insureType === "TERM_LIFE_INSURANCE")
        redirectURL = UnderWritingRoutes.POLICY_TERM;
      else redirectURL = UnderWritingRoutes.YOUR_GENDER;
      break;

    case "4":
      if (
        (goalType === "INSURE" && insureType === "PERMANENT_LIFE_INSURANCE") ||
        (goalType === "INSURE" && insureType === "FINAL_EXPENSE_INSURANCE") ||
        (goalType === "MEDICARE" && insureType === "MEDICARE_INSURANCE")
      )
        redirectURL = UnderWritingRoutes.YOUR_GENDER;
      else if (goalType === "INSURE" && insureType === "TERM_LIFE_INSURANCE")
        redirectURL = UnderWritingRoutes.YOUR_GENDER;
      else redirectURL = UnderWritingRoutes.YOUR_AGE;
      break;

    case "5":
      if (
        (goalType === "INSURE" && insureType === "PERMANENT_LIFE_INSURANCE") ||
        (goalType === "INSURE" && insureType === "FINAL_EXPENSE_INSURANCE")
      )
        redirectURL = UnderWritingRoutes.YOUR_GENDER;
      else if (
        (goalType === "INSURE" && insureType === "TERM_LIFE_INSURANCE") ||
        (goalType === "MEDICARE" && insureType === "MEDICARE_INSURANCE")
      )
        redirectURL = UnderWritingRoutes.YOUR_AGE;
      else redirectURL = UnderWritingRoutes.YOUR_HEALTH;
      break;

    case "6":
      if (
        (goalType === "INSURE" && insureType === "PERMANENT_LIFE_INSURANCE") ||
        (goalType === "INSURE" && insureType === "FINAL_EXPENSE_INSURANCE")
      )
        redirectURL = UnderWritingRoutes.YOUR_AGE;
      else if (goalType === "INSURE" && insureType === "TERM_LIFE_INSURANCE")
        redirectURL = UnderWritingRoutes.YOUR_HEALTH;
      else redirectURL = UnderWritingRoutes.SOMKING_HABIT;
      break;

    case "7":
      if (
        (goalType === "INSURE" && insureType === "PERMANENT_LIFE_INSURANCE") ||
        (goalType === "INSURE" && insureType === "FINAL_EXPENSE_INSURANCE")
      )
        redirectURL = UnderWritingRoutes.YOUR_HEALTH;
      else if (insureType === "TERM_LIFE_INSURANCE")
        redirectURL = UnderWritingRoutes.SOMKING_HABIT;
      else redirectURL = UnderWritingRoutes.CITIZENSHIP;
      break;

    case "8":
      if (
        (goalType === "INSURE" && insureType === "PERMANENT_LIFE_INSURANCE") ||
        (goalType === "INSURE" && insureType === "FINAL_EXPENSE_INSURANCE")
      )
        redirectURL = UnderWritingRoutes.SOMKING_HABIT;
      else if (goalType === "INSURE" && insureType === "TERM_LIFE_INSURANCE")
        redirectURL = UnderWritingRoutes.CITIZENSHIP;
      else redirectURL = UnderWritingRoutes.PLANS;
      // redirectURL = token
      //   ? UnderWritingRoutes.MANUAL_FORM
      //   : UnderWritingRoutes.REGISTER + "?name=myplans";
      break;

    case "9":
      if (
        (goalType === "INSURE" && insureType === "PERMANENT_LIFE_INSURANCE") ||
        (goalType === "INSURE" && insureType === "FINAL_EXPENSE_INSURANCE")
      )
        redirectURL = UnderWritingRoutes.CITIZENSHIP;
      else if (goalType === "INSURE" && insureType === "TERM_LIFE_INSURANCE")
        redirectURL = UnderWritingRoutes.PLANS;
      else redirectURL = UnderWritingRoutes.GUEST_BASIC_DETAILS;
      //   redirectURL = token
      //     ? UnderWritingRoutes.MANUAL_FORM
      //     : UnderWritingRoutes.REGISTER + "?name=myplans";
      // else
      //   redirectURL = token
      //     ? UnderWritingRoutes.FACT_FINDER
      //     : UnderWritingRoutes.LOGIN + "?name=myplans";
      break;

    case "10":
      if (
        (goalType === "INSURE" && insureType === "PERMANENT_LIFE_INSURANCE") ||
        (goalType === "INSURE" && insureType === "FINAL_EXPENSE_INSURANCE")
      )
        redirectURL = UnderWritingRoutes.PLANS;
      else if (goalType === "INSURE" && insureType === "TERM_LIFE_INSURANCE")
        redirectURL = UnderWritingRoutes.GUEST_BASIC_DETAILS;
      else
        redirectURL = token
          ? UnderWritingRoutes.FACT_FINDER
          : UnderWritingRoutes.REGISTER + "?name=myplans";
      //   redirectURL = token
      //     ? UnderWritingRoutes.MANUAL_FORM
      //     : UnderWritingRoutes.LOGIN + "?name=myplans";
      // else if (goalType === "INSURE" && insureType === "TERM_LIFE_INSURANCE")
      //   redirectURL = token
      //     ? UnderWritingRoutes.FACT_FINDER
      //     : UnderWritingRoutes.LOGIN + "?name=myplans";
      // else
      //   redirectURL = token
      //     ? UnderWritingRoutes.FACT_FINDER_2
      //     : UnderWritingRoutes.LOGIN + "?name=myplans";
      break;

    case "11":
      if (
        (goalType === "INSURE" && insureType === "PERMANENT_LIFE_INSURANCE") ||
        (goalType === "INSURE" && insureType === "FINAL_EXPENSE_INSURANCE")
      )
        redirectURL = token
          ? UnderWritingRoutes.GUEST_BASIC_DETAILS
          : UnderWritingRoutes.LOGIN + "?name=myplans";
      else if (goalType === "INSURE" && insureType === "TERM_LIFE_INSURANCE")
        redirectURL = token
          ? UnderWritingRoutes.FACT_FINDER
          : "/login?name=myplans";
      else
        redirectURL = token
          ? UnderWritingRoutes.FACT_FINDER_2
          : UnderWritingRoutes.LOGIN + "?name=myplans";
      break;

    case "12":
      if (
        (goalType === "INSURE" && insureType === "PERMANENT_LIFE_INSURANCE") ||
        (goalType === "INSURE" && insureType === "FINAL_EXPENSE_INSURANCE")
      ) {
        redirectURL = token
          ? UnderWritingRoutes.FACT_FINDER
          : UnderWritingRoutes.LOGIN + "?name=myplans";
      } else if (
        goalType === "INSURE" &&
        insureType === "TERM_LIFE_INSURANCE"
      ) {
        redirectURL = token
          ? UnderWritingRoutes.FACT_FINDER_2
          : "/login?name=myplans";
      } else {
        redirectURL = token
          ? UnderWritingRoutes.FACT_FINDER_3
          : UnderWritingRoutes.LOGIN + "?name=myplans";
      }
      break;

    case "13":
      if (
        (goalType === "INSURE" && insureType === "PERMANENT_LIFE_INSURANCE") ||
        (goalType === "INSURE" && insureType === "FINAL_EXPENSE_INSURANCE")
      ) {
        redirectURL = token
          ? UnderWritingRoutes.FACT_FINDER_2
          : UnderWritingRoutes.LOGIN + "?name=myplans";
      } else if (goalType === "INSURE" && insureType === "TERM_LIFE_INSURANCE")
        redirectURL = token
          ? UnderWritingRoutes.FACT_FINDER_3
          : "/login?name=myplans";
      else {
        redirectURL = token
          ? UnderWritingRoutes.INFORMATION_OPTION
          : "/login?name=myplans";
      }
      break;

    case "14":
      if (
        (goalType === "INSURE" && insureType === "PERMANENT_LIFE_INSURANCE") ||
        (goalType === "INSURE" && insureType === "FINAL_EXPENSE_INSURANCE")
      ) {
        redirectURL = token
          ? UnderWritingRoutes.FACT_FINDER_3
          : UnderWritingRoutes.LOGIN + "?name=myplans";
      } else if (goalType === "INSURE" && insureType === "TERM_LIFE_INSURANCE")
        redirectURL = UnderWritingRoutes.INFORMATION_OPTION;
      else {
        redirectURL = ApprovalStatus.APPLICATION_STATUS;
      }

      break;

    case "15":
      if (
        (goalType === "INSURE" && insureType === "PERMANENT_LIFE_INSURANCE") ||
        (goalType === "INSURE" && insureType === "FINAL_EXPENSE_INSURANCE")
      ) {
        redirectURL = UnderWritingRoutes.INFORMATION_OPTION;
      } else if (
        goalType === "INSURE" &&
        insureType === "TERM_LIFE_INSURANCE"
      ) {
        redirectURL = ApprovalStatus.APPLICATION_STATUS;
      } else if (token) {
        redirectURL = ApprovalStatus.APPLICATION_STATUS;
      } else if (!token && applyType === "appliedOnline") {
        redirectURL = UnderWritingRoutes.REGISTER + "?name=myplans";
      } else if (applyType === "professionalHelp") {
        redirectURL = UnderWritingRoutes.GUEST_BASIC_DETAILS;
      }

      break;

    case "16":
      if (
        (goalType === "INSURE" && insureType === "PERMANENT_LIFE_INSURANCE") ||
        (goalType === "INSURE" && insureType === "FINAL_EXPENSE_INSURANCE")
      ) {
        redirectURL = ApprovalStatus.APPLICATION_STATUS;
      } else if (
        goalType === "INSURE" &&
        insureType === "TERM_LIFE_INSURANCE" &&
        token
      ) {
        redirectURL = ApprovalStatus.APPLICATION_STATUS;
      } else if (token) {
        redirectURL = ApprovalStatus.APPLICATION_STATUS;
      } else if (
        goalType === "INSURE" &&
        insureType === "TERM_LIFE_INSURANCE" &&
        !token
      ) {
        redirectURL = UnderWritingRoutes.REGISTER + "?name=myplans";
      }
      break;

    // case "16":
    //   if (
    //     (goalType === "INSURE" && insureType === "PERMANENT_LIFE_INSURANCE") ||
    //     (goalType === "INSURE" && insureType === "FINAL_EXPENSE_INSURANCE")
    //   ) {
    //     redirectURL = ApprovalStatus.APPLICATION_STATUS;
    //   } else if (
    //     goalType === "INSURE" &&
    //     insureType === "TERM_LIFE_INSURANCE" &&
    //     token &&
    //     applyType === "appliedOnline"
    //   ) {
    //     redirectURL = ApprovalStatus.APPLICATION_STATUS;
    //   } else if (
    //     goalType === "INSURE" &&
    //     insureType === "TERM_LIFE_INSURANCE" &&
    //     !token &&
    //     applyType === "appliedOnline"
    //   ) {
    //     redirectURL = UnderWritingRoutes.REGISTER + "?name=myplans";
    //   } else {
    //     redirectURL = UnderWritingRoutes.MANUAL_FORM;
    //   }
    //   break;

    // case "17":
    //   if (
    //     (goalType === "INSURE" &&
    //       insureType === "PERMANENT_LIFE_INSURANCE" &&
    //       token &&
    //       applyType === "appliedOnline") ||
    //     (goalType === "INSURE" &&
    //       insureType === "FINAL_EXPENSE_INSURANCE" &&
    //       token &&
    //       applyType === "appliedOnline")
    //   ) {
    //     redirectURL = ApprovalStatus.APPLICATION_STATUS;
    //   } else if (
    //     (goalType === "INSURE" &&
    //       insureType === "PERMANENT_LIFE_INSURANCE" &&
    //       token &&
    //       applyType === "professionalHelp") ||
    //     (goalType === "INSURE" &&
    //       insureType === "FINAL_EXPENSE_INSURANCE" &&
    //       token &&
    //       applyType === "professionalHelp")
    //   ) {
    //     redirectURL = ApprovalStatus.APPLICATION_STATUS;
    //   } else if (
    //     (goalType === "INSURE" &&
    //       insureType === "PERMANENT_LIFE_INSURANCE" &&
    //       !token &&
    //       applyType === "appliedOnline") ||
    //     (goalType === "INSURE" &&
    //       insureType === "FINAL_EXPENSE_INSURANCE" &&
    //       !token &&
    //       applyType === "appliedOnline")
    //   ) {
    //     redirectURL = UnderWritingRoutes.REGISTER + "?name=myplans";
    //   } else if (
    //     goalType === "INSURE" &&
    //     token &&
    //     insureType === "TERM_LIFE_INSURANCE" &&
    //     applyType === "appliedOnline"
    //   )
    //     redirectURL = UnderWritingRoutes.MANUAL_FORM;
    //   else if (
    //     goalType === "INSURE" &&
    //     token &&
    //     insureType === "TERM_LIFE_INSURANCE" &&
    //     applyType === "professionalHelp"
    //   )
    //     redirectURL = UnderWritingRoutes.GUEST_BASIC_DETAILS;
    //   break;

    case "17":
      if (
        (goalType === "INSURE" &&
          insureType === "PERMANENT_LIFE_INSURANCE" &&
          token &&
          applyType === "appliedOnline") ||
        (goalType === "INSURE" &&
          insureType === "FINAL_EXPENSE_INSURANCE" &&
          token)
      ) {
        redirectURL = ApprovalStatus.APPLICATION_STATUS;
      } else if (
        (goalType === "INSURE" &&
          insureType === "PERMANENT_LIFE_INSURANCE" &&
          token) ||
        (goalType === "INSURE" &&
          insureType === "FINAL_EXPENSE_INSURANCE" &&
          token)
      ) {
        redirectURL = ApprovalStatus.APPLICATION_STATUS;
      } else if (
        (goalType === "INSURE" &&
          insureType === "PERMANENT_LIFE_INSURANCE" &&
          !token) ||
        (goalType === "INSURE" &&
          insureType === "FINAL_EXPENSE_INSURANCE" &&
          !token)
      ) {
        redirectURL = UnderWritingRoutes.REGISTER + "?name=myplans";
      }
      // } else if (
      //   goalType === "INSURE" &&
      //   token &&
      //   insureType === "TERM_LIFE_INSURANCE" &&
      //   applyType === "appliedOnline"
      // )
      //   redirectURL = UnderWritingRoutes.MANUAL_FORM;
      else if (
        goalType === "INSURE" &&
        token &&
        insureType === "TERM_LIFE_INSURANCE"
      )
        redirectURL = UnderWritingRoutes.GUEST_BASIC_DETAILS;
      break;

    case "18":
      if (
        (goalType === "INSURE" &&
          insureType === "PERMANENT_LIFE_INSURANCE" &&
          token &&
          applyType === "appliedOnline") ||
        (goalType === "INSURE" &&
          insureType === "FINAL_EXPENSE_INSURANCE" &&
          token &&
          applyType === "appliedOnline")
      ) {
        redirectURL = UnderWritingRoutes.MANUAL_FORM;
      } else if (
        (goalType === "INSURE" &&
          insureType === "PERMANENT_LIFE_INSURANCE" &&
          !token &&
          applyType === "appliedOnline") ||
        (goalType === "INSURE" &&
          insureType === "FINAL_EXPENSE_INSURANCE" &&
          !token &&
          applyType === "appliedOnline")
      ) {
        redirectURL = UnderWritingRoutes.REGISTER + "?name=myplans";
      } else if (
        (goalType === "INSURE" &&
          insureType === "PERMANENT_LIFE_INSURANCE" &&
          token &&
          applyType === "professionalHelp") ||
        (goalType === "INSURE" &&
          insureType === "FINAL_EXPENSE_INSURANCE" &&
          token &&
          applyType === "professionalHelp")
      ) {
        redirectURL = UnderWritingRoutes.GUEST_BASIC_DETAILS;
      } else if (goalType === "INSURE" && insureType === "TERM_LIFE_INSURANCE")
        redirectURL = ApprovalStatus.APPLICATION_STATUS;
      break;

    case "19":
      if (
        (goalType === "INSURE" && insureType === "PERMANENT_LIFE_INSURANCE") ||
        (goalType === "INSURE" && insureType === "FINAL_EXPENSE_INSURANCE")
      ) {
        redirectURL = ApprovalStatus.APPLICATION_STATUS;
      }
      break;

    case "20":
      redirectURL = UnderWritingRoutes.ZIPCODE;
      break;

    case "21":
      if (token && applyType == "online") {
        redirectURL = UnderWritingRoutes.CLIENT_FORM;
      } else {
        redirectURL = UnderWritingRoutes.APPLICATION_STATUS;
      }

      break;

    case "22":
      if (token && applyType == "online") {
        redirectURL = UnderWritingRoutes.SBLI_PAGE;
      } else {
        redirectURL = UnderWritingRoutes.APPLICATION_STATUS;
      }

      break;

    case "23":
      if (token && applyType == "online") {
        redirectURL = UnderWritingRoutes.BENEFICIARY;
      } else {
        redirectURL = UnderWritingRoutes.APPLICATION_STATUS;
      }

      break;

    case "24":
      if (goalType === "MEDICARE" && insureType === "MEDICARE_INSURANCE") {
        redirectURL = UnderWritingRoutes.MEDICARE_ENROLL;
      }
      break;

    case "25":
      if (goalType === "MEDICARE" && insureType === "MEDICARE_INSURANCE") {
        redirectURL = UnderWritingRoutes.MEDICARE_PLAN_LIST;
      }
      break;

    default:
      redirectURL = UnderWritingRoutes.GET_START;
      break;
  }
  return redirectURL;
}

export function getSliderVariables(screen, amount, policyType) {
  let sliderLables;
  switch (screen) {
    case "MONTHLY":
      sliderLables = {
        labels: {
          100: "$100",
          2500: "$2500",
          5000: "$5000",
        },
        min: 100,
        max: 5000,
        defaultValue:
          amount && amount <= 5000 && amount >= 100 ? amount : "300",
      };
      break;

    case "QUARTERLY":
      sliderLables = {
        labels: {
          50: "$50",
          1500: "$1,500",
          3000: "$3,000",
        },
        min: 50,
        max: 3000,
        defaultValue:
          amount && amount <= 3000 && amount >= 50 ? amount : "1400",
      };
      break;

    case "ANNUALLY":
      sliderLables = {
        labels: {
          1000: "$1000",
          50000: "$50,000",
          100000: "$100,000",
        },
        min: 1000,
        max: 100000,
        defaultValue:
          amount && amount <= 100000 && amount >= 1000 ? amount : "3500",
      };
      break;

    case "ONETIME":
      sliderLables = {
        labels: {
          1000: "$1,000",
          500000: "$500,000",
          1000000: "$1,000,000",
        },
        min: 1000,
        max: 1000000,
        defaultValue:
          amount && amount <= 1000000 && amount >= 1000 ? amount : "42000",
      };
      break;

    case "INSURE":
      sliderLables =
        policyType === "PERMANENT_LIFE_INSURANCE"
          ? {
              labels: {
                100000: "$100,000",
                500000: "$500,000",
                // 1000000: "$1,000,000",
                1000000: "$1,000,000",
              },
              min: 100000,
              // max: 1000000,
              max: 1000000,
              stepAmount: 50000,
              defaultValue:
                amount && amount <= 1000000 && amount >= 100000
                  ? amount
                  : "350000",
            }
          : policyType === "FINAL_EXPENSE_INSURANCE"
          ? {
              labels: {
                1000: "$1000",
                25000: "$25,000",
                // 1000000: "$1,000,000",
                50000: "$50,000",
              },
              min: 1000,
              // max: 1000000,
              max: 50000,
              stepAmount: 1000,
              defaultValue:
                amount && amount <= 50000 && amount >= 1000 ? amount : "10000",
            }
          : {
              labels: {
                25000: "$25,000",
                1500000: "$1,500,000",
                // 1000000: "$1,000,000",
                3000000: "$3,000,000",
              },
              min: 25000,
              // max: 1000000,
              max: 3000000,
              stepAmount: 25000,
              defaultValue:
                amount && amount <= 3000000 && amount >= 25000
                  ? amount
                  : "350000",
            };

      break;

    default:
      sliderLables = {};
      break;
  }
  return sliderLables;
}

export function getProductDescription(carrierName, productName, pay) {
  let description;
  switch (carrierName) {
    case "MassMutual":
      if (carrierName.trim() === "MassMutual") {
        if (productName.trim() === "Whole Life Legacy 100" && pay === 100)
          description =
            "Level Premium Whole Life Policy with Premiums Payable to Age 100";
        else if (
          productName.trim() === "Whole Life Legacy 12 Pay" &&
          pay === 12
        )
          description =
            "Level Premium Whole Life Policy with Premiums Payable for 12 Years";
        else if (
          productName.trim() === "Whole Life Legacy 10 Pay" &&
          pay === 10
        )
          description =
            "Level Premium Whole Life Policy with Premiums Payable for 10 Years";
        else
          description = `Level Premium Term Policy with Premiums Payable for ${pay} Years`;
      }
      break;

    case "Guardian":
      if (carrierName.trim() === "Guardian") {
        if (productName.trim() === "Whole Life 121" && pay === 121)
          description =
            "Level Premium Whole Life Policy with Premiums Payable to Age 121";
        else if (productName.trim() === "10 Pay Whole Life" && pay === 10)
          description =
            "Level Premium Whole Life Policy with Premiums Payable for 10 Years";
        else
          description = `Level Premium Term Policy with Premiums Payable for ${pay} Years`;
      }
      break;

    case "Pacific Life":
      if (carrierName.trim() === "Pacific Life") {
        if (productName === "Pacific Discovery Xelerator IUL 2")
          description =
            "Flexible Premium Index Universal Life Policy with Premiums Payable for 6 Years";
        else
          description = `Level Premium Term Policy with Premiums Payable for ${pay} Years`;
      }
      break;

    case "John Hancock":
      if (carrierName.trim() === "John Hancock") {
        if (productName.trim() === "Accumulation IUL")
          description =
            "Flexible Premium Index Universal Life Policy with Premiums Payable for 6 Years";
        else
          description = `Level Premium Term Policy with Premiums Payable for ${pay} Years`;
      }
      break;
  }
  return description;
}
