import React, { useState } from "react";
import { Link, withRouter } from "react-router-dom";
import { Animated } from "react-animated-css";
import { connect } from "react-redux";
import NumberFormat from "react-number-format";
import { isEmpty } from "lodash";
import {
  commonApiCall,
  CONSUMER_DETAILS,
  profilePicture,
  setJwt,
  getJwt,
} from "../redux/actions/index";
import {
  encryptme,
  decryptme,
  validateEmail,
  validateOtp,
  UnderWritingRoutes,
  ApprovalStatus,
  DashboardRoute,
  requireAuth,
  showErrorToast,
  showSuccessToast,
  getItem,
  removeItem,
} from "../utils/index";
import { API_URL_CLIENT, API_URL_UNDERWRITING } from "../config/configs";
import { UseInterval } from "../hooks/index";

const EmailLogin = ({
  commonApiCall,
  profilePicture,
  underwriting,
  t,
  ...props
}) => {
  const { applicationId } = underwriting;
  const router = props.match.params;
  const [formData, setFormData] = useState({
    email: "",
    verificationCode: "",
    isResendOTP: false,
    counter: 0,
    errors: {},
    isRegister: false,
  });
  const [isLoading, setIsLoading] = useState(false);

  if (requireAuth() && !isEmpty(router.name)) {
    props.history.push(UnderWritingRoutes.PLANS);
  }

  UseInterval(
    () => {
      setFormData({ ...formData, counter: formData.counter - 1 });
    },
    formData.counter > 0 ? 1000 : null
  );

  const handleChange = (e) => {
    if (e.target.value) {
      setFormData({
        ...formData,
        errors: Object.assign(formData.errors, { [e.target.name]: "" }),
      });
    }
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const sendOtp = async () => {
    const { email } = formData;
    if (validation(false)) {
      const request = {
        email: await encryptme(email),
        role: "ROLE_CLIENT",
      };
      const apiEndPoint = API_URL_CLIENT + "sendOtpViaMail";
      const response = await commonApiCall(apiEndPoint, "post", request, null);
      if (response.status.code === 200) {
        setFormData({
          ...formData,
          isResendOTP: true,
          counter: 30,
        });

        if (response.data && response.data.otp && response.data.otp !== "")
          showSuccessToast(
            "Please use this code to log in:" + response.data.otp + " !"
          );
        else showSuccessToast(t("validation_message.successOtp"));
      } else {
        showErrorToast(response.status.message);
      }
    }
  };

  const getUnderWriting = async (applicationId) => {
    try {
      if (applicationId) {
        const request = {
          applicationId: applicationId,
        };

        const apiEndPoint = API_URL_UNDERWRITING + "getUnderWriting";
        const isAuthorized = getJwt() ? true : false;

        await commonApiCall(apiEndPoint, "post", request, null, isAuthorized);
        return;
      } else {
        showErrorToast(t("validation_message.SOMETHING_WENT_WRONG"));
      }
    } catch (err) {
      showErrorToast(t("validation_message.SOMETHING_WENT_WRONG"));
    }
  };

  const doSubmit = async () => {
    const { email, verificationCode, isRegister } = formData;
    if (validation(true)) {
      setIsLoading(true);
      const request = {
        loginType: "EMAIL",
        value: await encryptme(email),
        otp: verificationCode,
        role: "ROLE_CLIENT",
        smsVerify: true,
        applicationId: applicationId,
        isRegister: isRegister,
      };

      const apiEndPoint = API_URL_CLIENT + "signIn";
      const response = await commonApiCall(
        apiEndPoint,
        "post",
        request,
        CONSUMER_DETAILS
      );
      if (response.status.code === 200) {
        setJwt(response.data.token);
        const reduxStore = {
          profilePic: response.data.profilePic,
          email: await decryptme(response.data.email),
          firstName: await decryptme(response.data.firstName),
          lastName: await decryptme(response.data.lastName),
        };
        await profilePicture(reduxStore);

        if (!isEmpty(router.name)) {
          props.history.push(UnderWritingRoutes.MANUAL_FORM);
        } else {
          const lastScreen = getItem("lastScreen");
          if (lastScreen) {
            const findInUnderwriting = Object.keys(UnderWritingRoutes).find(
              (key) => UnderWritingRoutes[key] === lastScreen
            );
            const findInApproval = Object.keys(ApprovalStatus).find(
              (key) => ApprovalStatus[key] === lastScreen
            );
            const applicationId =
              !isEmpty(underwriting) && underwriting.applicationId
                ? underwriting.applicationId
                : "";
            if ((findInUnderwriting || findInApproval) && applicationId) {
              await getUnderWriting(applicationId);
              removeItem("lastScreen");
              props.history.push(lastScreen);
            } else {
              removeItem("lastScreen");
              props.history.push(lastScreen);
            }
          } else props.history.push(DashboardRoute.DASHBOARD);
        }
      } else {
        showErrorToast(response.status.message);
      }
      setIsLoading(false);
    }
  };

  /*******************  Form validation  ******************/
  const validation = (isGetOtp) => {
    const { email, verificationCode } = formData;
    let errors = {};
    // for email
    if (formData.hasOwnProperty("email")) {
      if (isEmpty(email))
        errors.email = t("validation_message.PROVIDE_REGISTERED_EMAIL");
      else if (!validateEmail(email))
        errors.email = t("validation_message.PROVIDE_VALID_EMAIL");
      else delete errors.email;
    }
    // for otp
    if (isGetOtp) {
      if (formData.hasOwnProperty("verificationCode")) {
        if (isEmpty(verificationCode))
          errors.verificationCode = t(
            "validation_message.PROVIDE_VERIFICATIONCODE_NUMBER"
          );
        else if (!validateOtp(verificationCode))
          errors.verificationCode = t(
            "validation_message.PROVIDE_VALID_VERIFICATIONCODE_NUMBER"
          );
        else delete errors.verificationCode;
      }
    }
    const isFormValid = Object.keys(errors).length !== 0 ? false : true;
    setFormData({
      ...formData,
      errors: errors,
    });
    return isFormValid;
  };

  const { email, verificationCode, isResendOTP, counter, errors, isRegister } =
    formData;
  return (
    <React.Fragment>
      <div className="login-wrapper">
        <div className="title-header">
          <h2>{t("email_login.log_in")}</h2>
          <p className="pb-0">
            {t(
              "email_login.enter_your_registered_email_address_here_which_was_provided_at_time_of_registration"
            )}
          </p>
        </div>
        <Animated
          animationIn="fadeInRight"
          animationOut="fadeOutLeft"
          animationInDuration={800}
          animationOutDuration={800}
          isVisible={true}
        >
          <div className="form-wrapper">
            <form>
              <div className="form-group">
                <div className="input-effect otp-inputs">
                  <input
                    className="form-control"
                    placeholder="Email"
                    name="email"
                    id="email"
                    type="mail"
                    value={email}
                    onChange={(e) => handleChange(e)}
                  />
                  <label htmlFor="email">{t("email_login.email")}</label>
                  {counter === 0 ? (
                    <button
                      className="btn btn-link text-primary"
                      type="button"
                      onClick={sendOtp}
                      style={{ textTransform: "none" }}
                    >
                      {isResendOTP === false
                        ? "Get the login code"
                        : "Resend the login code"}
                    </button>
                  ) : (
                    <span
                      className="btn btn-link btn-resend"
                      style={{ textTransform: "none" }}
                    >
                      {t("email_login.resend_otp")} ({counter})
                    </span>
                  )}
                </div>
                {errors.email && (
                  <small className="text-danger err-msg">{errors.email}</small>
                )}
              </div>

              <div className="form-group">
                <div className="input-effect">
                  <NumberFormat
                    name="verificationCode"
                    id="verificationCode"
                    className="form-control"
                    placeholder="Verification code"
                    format="######"
                    value={verificationCode}
                    onValueChange={(e) =>
                      handleChange({
                        target: { name: "verificationCode", value: e.value },
                      })
                    }
                  />
                  <label htmlFor="Verificationcode">
                    {t("email_login.verification_code")}
                  </label>
                </div>
                {errors.verificationCode && (
                  <small className="text-danger err-msg">
                    {errors.verificationCode}
                  </small>
                )}
              </div>

              <div className="form-group text-center mb-0">
                <button
                  className="btn btn-primary btn-lg"
                  title="Log In"
                  onClick={doSubmit}
                  type="button"
                  disabled={isLoading}
                >
                  {t("email_login.log_in")}
                </button>
              </div>
            </form>
          </div>
        </Animated>
        <div className="form-group not-member">
          <p className="pb-0">
            {t("email_login.not_a_member_yet")}&nbsp;
            {!isEmpty(router.name) ? (
              <Link
                to={UnderWritingRoutes.REGISTER + "/myplans"}
                className="text-link"
                title="Sign Up"
              >
                {t("email_login.sign_up")}
              </Link>
            ) : (
              <Link
                to={UnderWritingRoutes.REGISTER}
                className="text-link"
                title="Sign Up"
              >
                {t("email_login.sign_up")}
              </Link>
            )}
          </p>
        </div>

        {!isEmpty(router.name) && (
          <div className="col-12 text-center mt-3">
            <Link
              to={UnderWritingRoutes.LOGIN_AGENT_CHAT}
              className="btn btn-link"
              title="Back"
            >
              Back
            </Link>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  underwriting: state.underwriting.underwriting,
});
export default connect(mapStateToProps, { commonApiCall, profilePicture })(
  withRouter(EmailLogin)
);
