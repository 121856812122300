import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  commonApiCall,
  getJwt,
  setAssurityProgressCount,
  UNDERWRITING_DETAILS,
} from "../../../../redux/actions";
import {
  AssurityBaseBoolean,
  AssurityTextField,
  calculateDaysFromNow,
  getState,
  ShowDisclosurePickList,
  showErrorToast,
  UnderWritingRoutes,
} from "../../../../utils";
import { API_URL_UNDERWRITING } from "../../../../config/configs";
import moment from "moment";
import AssurityPhysicianRequest from "../assurity-physician-request";
import DatePicker from "react-datepicker";

const underwritingKeys = [
  "approved",
  "approved_with_changes",
  "referred_to_underwriter",
  "incomplete_interview",
];
const CovidQuestion = ({
  t,
  baseQuestion = null,
  commonApiCall,
  underwriting,
  onAnswered,
  assurityCount,
  setAssurityProgressCount,
  ...props
}) => {
  const [baseAnswer, setBaseAnswer] = useState([]);
  const [reflexiveQuestionList, setReflexiveQuestionList] = useState([]);
  const [reflexiveArr, setReflexiveArr] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [currentQuestionHeader, setCurrentQuestionHeader] = useState(null);

  const [questionLoading, setQuestionLoading] = useState({
    questionId: null,
    loading: false,
  });
  const [baseQuestionErrors, setBaseQuestionErrors] = useState({});
  const [reflexQuestionErrors, setReflexiveQuestionErrors] = useState({});
  const [enableNextButton, setEnableNextButton] = useState(false);
  const [physicianQuestion, setPhysicianQuestion] = useState(false);
  const initialFormData = {
    first_name: "",
    last_name: "",
    middle_name: "",
    suffix: "",
    address: "",
    address_2: "",
    city: "",
    country: "",
    state: "",
    zipCode: "",
    email: "",
    mobile: "",
    fax: "",
    speciality: "",
    lastVisitDate: "",
    lastVisitReason: "",
    healthQuestion: "",
    lastVisitReasonDescription: "",
    errors: {},
  };
  const [formData, setFormData] = useState({ ...initialFormData });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentQuestionIndex]);

  const validateField = () => {
    let {
      first_name,
      last_name,
      address,
      city,
      state,
      country,
      zipCode,
      mobile,
      lastVisitDate,
      lastVisitReason,
      healthQuestion,
      lastVisitReasonDescription,
    } = formData;

    let error = {};
    let validate = true;

    if (!validateQuestions()) {
      validate = false;
    }

    if (healthQuestion !== "") {
      if (healthQuestion === "yes") {
        if (first_name === "") {
          error.first_name = t("validation_message.fName");
          validate = false;
        }
        if (last_name === "") {
          error.last_name = t("validation_message.lName");
          validate = false;
        }
        if (address === "") {
          error.address = t("validation_message.address");
          validate = false;
        }
        if (city === "") {
          error.city = t("validation_message.city");
          validate = false;
        }
        if (country === "") {
          error.country = t("validation_message.country");
          validate = false;
        }
        if (state === "" && (country === "USA" || country === "CANADA")) {
          error.state = t("validation_message.state");
          validate = false;
        }
        if (zipCode.trim() === "" && !getState(zipCode)) {
          error.zipCode = t("validation_message.PIN");
          validate = false;
        }
        if (mobile === "") {
          error.mobile = t("validation_message.mobile");
          validate = false;
        }
        if (lastVisitDate === "") {
          error.lastVisitDate = t("validation_message.lastVisitDate");
          validate = false;
        }
        if (lastVisitReason === "") {
          error.lastVisitReason = t("validation_message.lastVisitReason");
          validate = false;
        }
        if (
          lastVisitReasonDescription === "" &&
          lastVisitReason?.value === "Other"
        ) {
          error.lastVisitReasonDescription = t(
            "validation_message.lastVisitReasonDescription"
          );
          validate = false;
        }
      } else if (healthQuestion === "no") {
        validate = true;
      }
    } else {
      error.healthQuestion = "this field is required";
      validate = false;
    }
    setFormData({
      ...formData,
      errors: error,
    });
    return validate;
  };

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
    setEnableNextButton(true);
  };

  const [showSearchType, setSearchType] = useState([]);

  const screen = new URLSearchParams(props.location.search).get("underwriting");
  const getRoute = () => {
    if (screen) {
      switch (screen) {
        case "GENERAL_SECTION_1":
          return "GENERAL_SECTION_2";
        case "GENERAL_SECTION_2":
          return "HEALTH_SECTION_1";
        case "HEALTH_SECTION_1":
          return "HEALTH_SECTION_2";
        case "HEALTH_SECTION_2":
          return "HEALTH_SECTION_3";
        case "HEALTH_SECTION_3":
          return "HEALTH_SECTION_4";
        case "HEALTH_SECTION_4":
          return "COVID_QUESTIONS";

        default:
          return "";
      }
    }
  };

  const checkFLandND = () => {
    if (
      underwriting &&
      (underwriting?.state?.code === "FL" || underwriting?.state?.code === "ND")
    ) {
      return true;
    } else {
      return false;
    }
  };

  const getDecision = async () => {
    setLoading(true);
    try {
      const apiEndPoint =
        API_URL_UNDERWRITING +
        "getDecisions?applicationId=" +
        underwriting?.applicationId;

      const response = await commonApiCall(apiEndPoint, "get", {}, "");

      if (response.status.code === 200) {
        // setOptionList(re)
        let data = JSON.parse(response.data);
        data.product.primaryInsured.person.emailAddress = JSON.parse(
          localStorage.getItem("decisionParam")
        ).emailAddress;
        data.product.primaryInsured.person.driversLicense = {
          hasValidLicense: JSON.parse(localStorage.getItem("decisionParam"))
            .number
            ? true
            : false,
          number: JSON.parse(localStorage.getItem("decisionParam")).number,
          stateAbbreviation: JSON.parse(localStorage.getItem("decisionParam"))
            .stateAbbreviation,
        };
        data.product.primaryInsured.person.occupation = JSON.parse(
          localStorage.getItem("decisionParam")
        ).occupation;

        data.product.primaryInsured.agreementToAssignOrSellPolicy = JSON.parse(
          localStorage.getItem("decisionParam")
        ).agreementToAssignOrSellPolicy;

        data.product.primaryInsured.hadLifeExpectancyOrHealthExams = JSON.parse(
          localStorage.getItem("decisionParam")
        ).hadLifeExpectancyOrHealthExams;

        data.product.primaryInsured.intentToSellInsurance = JSON.parse(
          localStorage.getItem("decisionParam")
        ).intentToSellInsurance;
        // data.product.primaryInsured.person.occupation = {
        //   lengthOfEmployment: {
        //     years: 5,
        //     months: 5,
        //   },
        //   income: {
        //     netMonthlyIncome: 3000,
        //   },
        //   isSelfEmployed: true,
        // };
        let obj = {};
        obj.address = data.product.primaryInsured.address;
        obj.person = {
          dateOfBirth: data.product.primaryInsured.person.dateOfBirth,
          name: {
            first: data.product.primaryInsured.person.name.first,
            last: data.product.primaryInsured.person.name.last,
          },
          socialSecurityNumber:
            data.product.primaryInsured.person.socialSecurityNumber,
        };

        obj.phone = {
          areaCode: JSON.parse(localStorage.getItem("decisionParam")).phone
            ?.areaCode,
          number: JSON.parse(localStorage.getItem("decisionParam"))?.phone
            ?.number,
        };

        obj.relationshipToPrimaryInsured = "self";
        data.product.payors = {
          primaryPayor: obj,
          secondaryAddresseePayor: JSON.parse(
            localStorage.getItem("decisionParam")
          ).secondaryAddresseePayor,
        };
        data.product.primaryInsured.otherInsurance = JSON.parse(
          localStorage.getItem("decisionParam")
        ).otherInsurance;
        data.product.primaryInsured.purposeOfInsurance = JSON.parse(
          localStorage.getItem("decisionParam")
        ).purposeOfInsurance;
        if (
          underwritingKeys.indexOf(data.underwritingStatus.toLowerCase()) > -1
        ) {
          localStorage.setItem("persist::ass::bene", JSON.stringify(data));
          getUnderWriting(data);
          // setDecisionData(data);
        } else {
          props.history.push(
            UnderWritingRoutes.ASSURITY_PRE_DENIED + "?phase=underwriting"
          );
        }
      }
    } catch (err) {
      console.log(err);
      showErrorToast(err);
    }
    setLoading(false);
  };

  const getUnderWriting = async (data) => {
    try {
      const request = {
        applicationId: underwriting?.applicationId,
      };
      const apiEndPoint = API_URL_UNDERWRITING + "getUnderWriting";
      const isAuthorized = getJwt() ? true : false;
      const response = await commonApiCall(
        apiEndPoint,
        "post",
        request,
        UNDERWRITING_DETAILS,
        isAuthorized
      );

      if (response.status.code === 200) {
        saveUnderwriteDetails(
          "24,ASSURITY_WITH_CHANGES," + data.underwritingStatus.toLowerCase(),
          UnderWritingRoutes.ASSURITY_WITH_CHANGES +
          "?status=" +
          data.underwritingStatus.toLowerCase()
        );
      } else {
        showErrorToast(response.status.message);
      }
    } catch (err) {
      console.log(err);
      showErrorToast(err);
    }
  };

  const updateBaseQuestion = async (obj) => {
    setLoading(true);
    try {
      const request = {
        baseQuestionAnswers: [
          {
            baseQuestionId: obj.BaseQuestionId,
            baseQuestionAnswerValue: obj.answerValue,
          },
        ],
      };

      const isAuthorized = getJwt() ? true : false;
      const apiEndPoint =
        API_URL_UNDERWRITING +
        "updatebaseQuestions?applicationId=" +
        underwriting?.applicationId;

      const response = await commonApiCall(
        apiEndPoint,
        "put",
        request,
        null,
        isAuthorized
      );
      if (response.status.code !== 200) {
        showErrorToast(response.status.message);
      }
    } catch (err) {
      showErrorToast(err);
    }
    setLoading(false);
  };

  // Get the disclosure details for the application
  const getDisclosureDetails = async (question) => {
    try {
      const apiEndPoint =
        API_URL_UNDERWRITING +
        "disclosureDetails?applicationId=" +
        underwriting?.applicationId;
      const response = await commonApiCall(apiEndPoint, "get", {}, "");
      if (response.status.code === 200) {
        const list = JSON.parse(response.data);
        const disclosureId = reflexiveQuestionList.find(
          (x) => x.BaseQuestionId === question?.BaseQuestionId
        )?.DisclosureId;
        //check whether we have same base question id in disclosure details with array of base question ids which matched base question ids from base question array of objects with one key as base question iDs.
        const disclosure = list.DisclosureDetails.find(
          (item) =>
            item.BaseQuestionIds.includes(question.BaseQuestionId) &&
            item.DisclosureId === disclosureId
        );
        if (disclosure && disclosure?.DisclosureStatus === "In_Progress") {
          if (disclosure?.PhysicianDisclosureRequired) {
            setPhysicianQuestion(true);
            setEnableNextButton(false);
          }
        } else {
          moveToNextQuestion();
        }
      }
    } catch (err) {
      showErrorToast(err);
    }
  };

  const updateReflexiveQuestion = useCallback(
    async (question, reflexiveAns = [], disclosure) => {
      setLoading(true);
      try {
        let obj;
        obj = {
          baseQuestionId: question.BaseQuestionId,
          reflexiveQuestionAnswers: reflexiveAns.map(
            ({ BaseQuestionId, DisclosureId, ...rest }) => ({ ...rest })
          ),
        };
        const isAuthorized = getJwt() ? true : false;
        const apiEndPoint =
          API_URL_UNDERWRITING +
          "updateReflexiveQuestions?applicationId=" +
          underwriting?.applicationId +
          "&disclosureId=" +
          disclosure;
        const response = await commonApiCall(
          apiEndPoint,
          "put",
          obj,
          null,
          isAuthorized
        );
        if (response.status.code !== 200) {
          showErrorToast(response.status.message);
        }
        if (response.status.code === 200) {
          getDisclosureDetails(question);
        }
      } catch (err) {
        console.log(err);
        showErrorToast(err);
      }
      setLoading(false);
    },
    [reflexiveArr]
  );

  const handleReflexiveList = useCallback(
    (list, disclosure, baseId, multi = false) => {
      let tempReflexive = [...reflexiveQuestionList];
      let tempIndex = tempReflexive.findIndex(
        (x) => x.BaseQuestionId === baseId
      );

      if (tempIndex > -1 && !multi) {
        tempReflexive[tempIndex].ReflexiveQuestion = list;
        tempReflexive[tempIndex].DisclosureId = disclosure;
      } else {
        tempReflexive.push({
          BaseQuestionId: baseId,
          DisclosureId: disclosure,
          ReflexiveQuestion: list,
        });
      }
      setReflexiveQuestionList(tempReflexive);
    },
    [reflexiveQuestionList]
  );

  const getReflexiveQuestions = async (baseId, disclosure, multi = false) => {
    setQuestionLoading({
      questionId: baseId,
      loading: true,
    });
    try {
      //  if (disclosure.includes(",")) disclosure = encodeURIComponent(disclosure);
      const apiEndPoint =
        API_URL_UNDERWRITING +
        "assurityReflexiveQuestions?applicationId=" +
        underwriting?.applicationId +
        "&disclosureId=" +
        disclosure;

      const response = await commonApiCall(apiEndPoint, "get", {}, "");

      if (response.status.code === 200) {
        // setOptionList(re)
        const list = JSON.parse(response.data);

        handleReflexiveList(
          list.DecisionTree.ReflexiveQuestion,
          list.DecisionTree.DisclosureId,
          baseId,
          multi
        );
      }
    } catch (err) {
      console.log(err);
      showErrorToast(err);
    }
    setQuestionLoading({
      questionId: null,
      loading: false,
    });
  };

  const getReflexiveList = (question) => {
    let itemIndex = reflexiveQuestionList.filter(
      (x) => x.BaseQuestionId === question.BaseQuestionId
    );
    if (itemIndex.length > 0) {
      return itemIndex[0];
    }
    return null;
  };
  const getCurrentSelectedValue = (item) => {
    const filterReflex = reflexiveArr.filter(
      (x) => x.reflexiveQuestionId == item.ReflexiveQuestionId
    );
    if (filterReflex.length > 0) {
      return item.ReflexiveQuestionType === "Date" ? new Date(filterReflex[0].reflexiveQuestionAnswerValue) : filterReflex[0].reflexiveQuestionAnswerValue;
    }
    return "";
  };

  const getQuestionFromBranch = (reflex, value) => {
    if (reflex.ReflexiveQuestionType === "Text") {
      if (reflex.Branches.length > 1) {
        const currentBranch =
          reflex.Branches && value
            ? reflex.Branches.filter(
              (x) => x.BranchValue.toLowerCase() == value.toLowerCase()
            )
            : null;
        return currentBranch && currentBranch.length > 0
          ? currentBranch[0]
          : null;
      } else {
        return reflex.Branches.length > 0 && value !== "" && value
          ? reflex.Branches[0]
          : null;
      }
    } else if (reflex.ReflexiveQuestionType === "Numeric") {
      let currentBranch = null;
      if (reflex.Branches && value && reflex.Branches.length > 0) {
        for (let i = 0; i < reflex.Branches.length; i++) {
          if (parseInt(value) >= parseInt(reflex.Branches[i].BranchValue)) {
            currentBranch = reflex.Branches[i];
          } else {
            break;
          }
        }
      }
      return currentBranch ? currentBranch : null;
    }
    else if (reflex.ReflexiveQuestionType === "Date") {
      let currentBranch = null;
      let noOfdaysFromNow = calculateDaysFromNow(value);
      if (reflex.Branches && noOfdaysFromNow && reflex.Branches.length > 0) {
        for (let i = 0; i < reflex.Branches.length; i++) {
          if (noOfdaysFromNow >= parseInt(reflex.Branches[i].BranchValue)) {
            currentBranch = reflex.Branches[i];
          } else {
            break;
          }
        }
      }
      return currentBranch ? currentBranch : null;
    } else {
      const currentBranch =
        reflex.Branches && value
          ? reflex.Branches.filter((x) => x.BranchValue == value)
          : null;

      return currentBranch && currentBranch.length > 0
        ? currentBranch[0]
        : null;
    }
  };

  const renderReflexiveQuestion = (question, reflex, disclosureId = null) => {
    const onValueChange = (id, e, branch = null) => {
      let tempReflexiveArr = [...reflexiveArr];
      let reflexIndex = reflexiveArr.findIndex(
        (x) => x.reflexiveQuestionId === id.ReflexiveQuestionId
      );
      if (reflexIndex > -1) {
        tempReflexiveArr[reflexIndex].reflexiveQuestionAnswerValue =
          e.target.value;
        tempReflexiveArr.splice(reflexIndex + 1);
      } else {
        const obj = {
          reflexiveQuestionId: id.ReflexiveQuestionId,
          reflexiveQuestionAnswerValue: e.target.value,
          BaseQuestionId: question.BaseQuestionId,
          DisclosureId: disclosureId,
        };
        tempReflexiveArr.push(obj);
      }

      setReflexiveArr(tempReflexiveArr);
      setPhysicianQuestion(false);
      if (branch === null || branch?.ReflexiveQuestion === null) {
        setEnableNextButton(true);
      }
    };
    return (
      <div
        className={
          "sbli-child-question mt-4 question_" + reflex.ReflexiveQuestionId
        }
      >
        <h4 className="mb-4 parent_question_head">
          {reflex.ReflexiveQuestionText}
        </h4>
        {(
          reflex.ReflexiveQuestionType === "Boolean" ||
          reflex.ReflexiveQuestionType === "List") && (
            <span className="custom-radio mt-3 d-block">
              {reflex.Branches.map((branch, index) => {
                return (
                  <React.Fragment>
                    <label
                      className="m-0 mb-3"
                      htmlFor={reflex.ReflexiveQuestionId + "ab" + index}
                    >
                      <input
                        type="radio"
                        name={reflex.ReflexiveQuestionId}
                        id={reflex.ReflexiveQuestionId + "ab" + index}
                        value={branch.BranchValue}
                        checked={
                          getCurrentSelectedValue(reflex) === branch.BranchValue
                        }
                        onChange={(e) => onValueChange(reflex, e, branch)}
                      />
                      <span></span> {branch.BranchText}
                    </label>

                  </React.Fragment>
                );
              })}
              {getQuestionFromBranch(reflex, getCurrentSelectedValue(reflex)) &&
                getQuestionFromBranch(reflex, getCurrentSelectedValue(reflex))
                  ?.ReflexiveQuestion
                ? renderReflexiveQuestion(
                  question,
                  getQuestionFromBranch(reflex, getCurrentSelectedValue(reflex))
                    ?.ReflexiveQuestion,
                  disclosureId
                )
                : null}
            </span>
          )}
        {(reflex.ReflexiveQuestionType === "Text") && (
          <React.Fragment>
            <div className="form-group ">
              <input
                className="form-control form-control-search"
                onChange={(e) =>
                  onValueChange(
                    reflex,
                    e,
                    getQuestionFromBranch(reflex, e.target.value)
                  )
                }
                type="text"
                value={getCurrentSelectedValue(reflex)}
                placeholder="Enter Text"
              />
            </div>

            {getQuestionFromBranch(reflex, getCurrentSelectedValue(reflex)) &&
              getQuestionFromBranch(reflex, getCurrentSelectedValue(reflex))
                ?.ReflexiveQuestion
              ? renderReflexiveQuestion(
                question,
                getQuestionFromBranch(reflex, getCurrentSelectedValue(reflex))
                  ?.ReflexiveQuestion,
                disclosureId
              )
              : null}
          </React.Fragment>
        )}
        {(reflex.ReflexiveQuestionType === "Numeric") && (
          <React.Fragment>
            <div className="form-group ">
              <input
                className="form-control form-control-search"
                onChange={(e) =>
                  onValueChange(
                    reflex,
                    e,
                    getQuestionFromBranch(reflex, e.target.value)
                  )
                }
                type="number"
                value={getCurrentSelectedValue(reflex)}
                placeholder="Enter numeric data"
              />
            </div>

            {getQuestionFromBranch(reflex, getCurrentSelectedValue(reflex)) &&
              getQuestionFromBranch(reflex, getCurrentSelectedValue(reflex))
                ?.ReflexiveQuestion
              ? renderReflexiveQuestion(
                question,
                getQuestionFromBranch(reflex, getCurrentSelectedValue(reflex))
                  ?.ReflexiveQuestion,
                disclosureId
              )
              : null}
          </React.Fragment>
        )}
        {(reflex.ReflexiveQuestionType === "Date") && (
          <React.Fragment>
            <div className="form-group custom-datepicker">
              <DatePicker
                selected={getCurrentSelectedValue(reflex)}
                maxDate={new Date()}
                placeholderText="MM/DD/YYYY"
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                dateFormatCalendar="MMMM"
                value={getCurrentSelectedValue(reflex)}
                onChange={(date) => {
                  const formattedDate = date.toISOString().split('T')[0];
                  onValueChange(
                    reflex,
                    { target: { value: formattedDate } },
                    getQuestionFromBranch(reflex, date)
                  )
                }
                }
              />
            </div>

            {getQuestionFromBranch(reflex, getCurrentSelectedValue(reflex)) &&
              getQuestionFromBranch(reflex, getCurrentSelectedValue(reflex))
                ?.ReflexiveQuestion
              ? renderReflexiveQuestion(
                question,
                getQuestionFromBranch(reflex, getCurrentSelectedValue(reflex))
                  ?.ReflexiveQuestion,
                disclosureId
              )
              : null}
          </React.Fragment>
        )}
      </div>
    );
  };

  const baseBooleanTrigger = (question, value) => {
    if (question.BaseQuestionDisclosures.length > 0) {
      if (question.BaseQuestionDisclosures[0].TriggerOn === value) {
        getReflexiveQuestions(
          question.BaseQuestionId,
          question.BaseQuestionDisclosures[0].DisclosureId
        );
      } else {
        let tempArr = [...reflexiveQuestionList];
        let r_item = reflexiveQuestionList.findIndex(
          (x) => x.BaseQuestionId === question.BaseQuestionId
        );
        if (r_item > -1) {
          tempArr.splice(r_item, 1);
        }

        setReflexiveQuestionList(tempArr);
        setReflexiveArr(
          reflexiveArr.filter(
            (x) => x.BaseQuestionId !== question.BaseQuestionId
          )
        );
      }
    }
  };

  const handleBaseAnswer = (question, value, type) => {
    if (value === "true" && (question.SearchType[0].Value !== "Pick_List" && question.SearchType[0].Value !== "Search")) {
      setEnableNextButton(true);
    }
    if (value === "false") {
      setEnableNextButton(true);
    }
    let tempBaseAnswer = [...baseAnswer];
    let itemIndex = baseAnswer.findIndex(
      (x) => x.BaseQuestionId === question.BaseQuestionId
    );
    if (itemIndex > -1) {
      tempBaseAnswer[itemIndex][type] = value;
      if (type === "answerValue") {
        updateBaseQuestion(tempBaseAnswer[itemIndex]);
      }
    } else {
      const obj = {
        BaseQuestionId: question.BaseQuestionId,
        [type]: value,
      };
      if (type === "answerValue") {
        updateBaseQuestion(obj);
      }
      tempBaseAnswer.push(obj);
    }

    if (type === "disclosureId" && value) {
      getReflexiveQuestions(
        question.BaseQuestionId,
        value.includes(",") ? value.split(",").at(-1) : value,
        value.includes(",")
      );
    }

    setBaseAnswer(tempBaseAnswer);
    setPhysicianQuestion(false);
    setReflexiveArr([]);
  };

  const getPickListClosureId = (question) => {
    return baseAnswer.filter(
      (x) => x.BaseQuestionId === question.BaseQuestionId
    ).length > 0
      ? baseAnswer.filter(
        (x) => x.BaseQuestionId === question.BaseQuestionId
      )[0].disclosureId
      : "";
  };

  const getBaseAnswer = (question) => {
    if (
      baseAnswer.filter((x) => x.BaseQuestionId === question.BaseQuestionId)
        .length > 0
    ) {
      return baseAnswer.filter(
        (x) => x.BaseQuestionId === question.BaseQuestionId
      )[0].answerValue;
    }
    return "";
  };

  const updateHealth = async () => {
    let obj = {};
    if (formData.healthQuestion === "yes") {
      obj = {
        person: {
          emailAddress: formData.email,
          name: {
            first: formData.first_name,
            last: formData.last_name,
            middle: formData.middle_name,
            suffix: formData.suffix,
          },
        },
        specialty: formData.speciality,
        address: {
          city: formData.city,
          country: formData.country?.value,
          line1: formData.address,
          line2: formData.address_2,
          stateAbbreviation: formData.state?.value,
          zipCode: formData.zipCode,
        },
        phone: {
          areaCode: formData.mobile.split(" ")[0].replace(/[()]/g, ""),
          number: formData.mobile.split(" ")[1].replace("-", ""),
        },
        fax: {
          areaCode:
            formData.fax !== ""
              ? formData.fax.split(" ")[0].replace(/[()]/g, "")
              : null,
          number:
            formData.fax !== ""
              ? formData.fax.split(" ")[1].replace("-", "")
              : null,
        },
        hasMostRecords: true,
        lastVisitDate: moment(formData.lastVisitDate).format(),
        lastVisitReason: formData.lastVisitReason?.value,
        lastVisitReasonDescription: formData.lastVisitReasonDescription,
      };
    }
    setLoading(true);
    try {
      const isAuthorized = getJwt() ? true : false;
      const apiEndPoint =
        API_URL_UNDERWRITING +
        "submitAssurityPhysician?applicationId=" +
        underwriting?.applicationId;
      const response = await commonApiCall(
        apiEndPoint,
        "post",
        obj,
        null,
        isAuthorized
      );
      if (response.status.code !== 200) {
        showErrorToast(response.status.message);
      } else if (response.status.code === 200) {
        getDecision();
        // props.history.push(UnderWritingRoutes.ASSURITY_BENE);
      }
    } catch (err) {
      showErrorToast(err);
    }
    setLoading(false);
  };

  const updatePhysicianDisclosureHeath = async () => {
    let obj = {};
    if (formData.healthQuestion === "yes") {
      obj = {
        person: {
          name: {
            first: formData.first_name,
            last: formData.last_name,
            middle: formData.middle_name,
            suffix: formData.suffix,
          },
        },
        specialty: formData.speciality,
        address: {
          city: formData.city,
          country: formData.country?.value,
          line1: formData.address,
          line2: formData.address_2,
          stateAbbreviation: formData.state?.value,
          zipCode: formData.zipCode,
        },
        phone: {
          areaCode: formData.mobile.split(" ")[0].replace(/[()]/g, ""),
          number: formData.mobile.split(" ")[1].replace("-", ""),
        },
        fax: {
          areaCode:
            formData.fax !== ""
              ? formData.fax.split(" ")[0].replace(/[()]/g, "")
              : null,
          number:
            formData.fax !== ""
              ? formData.fax.split(" ")[1].replace("-", "")
              : null,
        },
        hasMostRecords: true,
        lastVisitDate: moment(formData.lastVisitDate).format(),
        lastVisitReason: formData.lastVisitReason?.value,
        lastVisitReasonDescription: formData.lastVisitReasonDescription,
      };
      if (formData.email !== "") {
        obj.person.emailAddress = formData.email;
      }
    }
    setLoading(true);
    try {
      const isAuthorized = getJwt() ? true : false;
      const apiEndPoint =
        API_URL_UNDERWRITING +
        "physician?applicationId=" +
        underwriting?.applicationId +
        "&disclosureId=" +
        reflexiveQuestionList.find(
          (x) =>
            x.BaseQuestionId ===
            baseQuestion[currentQuestionIndex]?.BaseQuestionId
        )?.DisclosureId;
      const physicianBody = {
        baseQuestionId: baseQuestion[currentQuestionIndex]?.BaseQuestionId,
        physician: Object.keys(obj).length > 0 ? { ...obj } : null,
      };
      const response = await commonApiCall(
        apiEndPoint,
        "post",
        physicianBody,
        null,
        isAuthorized
      );
      if (response.status.code !== 200) {
        showErrorToast(response.status.message);
      } else if (response.status.code === 200) {
        getDisclosureDetails(baseQuestion[currentQuestionIndex]);
      }
    } catch (err) {
      showErrorToast(err);
    }
    setLoading(false);
  };

  const validateQuestions = () => {
    let baseErrors = {};
    let reflexiveErrors = {};
    const baseQuestions = document.getElementsByClassName("question");

    if (baseQuestions.length > 0) {
      for (let index = 0; index < baseQuestions.length; index++) {
        const element = baseQuestions[index];
        let classList = element.classList[
          Object.keys(element.classList)[2]
        ].replace("question_", "");

        if (baseAnswer.findIndex((y) => y.BaseQuestionId == classList) < 0) {
          element.classList.add("validate-question-parent");
          baseErrors = {
            ...baseErrors,
            [classList]: "required",
          };
          // baseErrors[questionId] = "required";
        } else {
          element.classList.remove("validate-question-parent");
        }

        let childReflexiveList = element.getElementsByClassName(
          "sbli-child-question"
        );
        if (childReflexiveList.length > 0) {
          for (let index = 0; index < childReflexiveList.length; index++) {
            const childElement = childReflexiveList[index];
            let childClassList = childElement.classList[
              2
            ].replace("question_", "");
            if (
              reflexiveArr.findIndex(
                (y) => y.reflexiveQuestionId == childClassList
              ) < 0
            ) {
              childElement.classList.add("validate-question-child");
              reflexiveErrors = {
                ...reflexiveErrors,
                [childClassList]: "required",
              };
            } else {
              childElement.classList.remove("validate-question-child");
            }
          }
        }
      }

      setBaseQuestionErrors(baseErrors);
      setReflexiveQuestionErrors(reflexiveErrors);
    }

    return Object.keys(baseErrors).length === 0 &&
      Object.keys(reflexiveErrors).length === 0
      ? true
      : false;
  };

  const handleInputChange = async (question, value) => {
    try {
      let tempSearchType = [...showSearchType];
      let searchIndex = tempSearchType.findIndex(
        (x) => x.questionId === question.BaseQuestionId
      );
      if (searchIndex > -1) {
        tempSearchType[searchIndex].value = value;
      } else {
        tempSearchType.push({
          questionId: question.BaseQuestionId,
          value: value,
          options: [],
        });
      }

      setSearchType(tempSearchType);
      if (value !== "") {
        const apiEndPoint =
          API_URL_UNDERWRITING +
          "assurityDisclosures?applicationId=" +
          underwriting?.applicationId +
          "&categoryId=" +
          question?.CategoryId +
          "&searchText=" +
          value;
        const response = await commonApiCall(apiEndPoint, "get", {}, "");

        if (response.status.code === 200) {
          // setOptionList(re)
          const list = JSON.parse(response.data);
          if (tempSearchType.length === 0) {
            tempSearchType[0].options = list.DisclosureMatches;
          } else if (searchIndex > -1) {
            tempSearchType[searchIndex].options = list.DisclosureMatches;
          }
          setSearchType(tempSearchType);
        }
      }
    } catch (err) {
      console.log(err);
      showErrorToast(err);
    }
  };

  const getProgressCount = () => {
    let count = 0;
    assurityCount = assurityCount ? assurityCount : { underWritingProgress: 7 };
    if (
      screen === "GENERAL_SECTION_1" &&
      assurityCount.underWritingProgress < 3
    ) {
      count = 3;
    }
    if (
      screen === "GENERAL_SECTION_2" &&
      assurityCount.underWritingProgress < 4
    ) {
      count = 4;
    }
    if (
      screen === "HEALTH_SECTION_1" &&
      assurityCount.underWritingProgress < 5
    ) {
      count = 5;
    }
    if (
      screen === "HEALTH_SECTION_2" &&
      assurityCount.underWritingProgress < 6
    ) {
      count = 6;
    }
    if (
      screen === "HEALTH_SECTION_3" &&
      assurityCount.underWritingProgress < 7
    ) {
      count = 7;
    }
    if (
      screen === "HEALTH_SECTION_4" &&
      assurityCount.underWritingProgress < 8
    ) {
      count = 8;
    }
    if (
      screen === "COVID_QUESTIONS" &&
      assurityCount.underWritingProgress < 9
    ) {
      count = 9;
    }
    return count;
  };

  useEffect(() => {
    if (screen === "COVID_QUESTIONS" && checkFLandND()) {
      getDecision();
    }
  }, [screen]);


  useEffect(() => {
    if (baseQuestion && baseQuestion.length > 0) {
      for (let index = 0; index < baseQuestion.length; index++) {
        const element = baseQuestion[index];
        if (element.BaseQuestionType !== "Heading") {
          setCurrentQuestionIndex(index);
          //  setCurrentQuestionIndex(5);
          break;
        }
      }
      let updatedQuestionHeader =
        baseQuestion &&
        baseQuestion.length > 0 &&
        baseQuestion.find((question) => question.BaseQuestionType === "Heading")
          ?.BaseQuestionText;
      if (
        updatedQuestionHeader &&
        updatedQuestionHeader !== currentQuestionHeader
      ) {
        setCurrentQuestionHeader(updatedQuestionHeader);
      }
    }
  }, [baseQuestion]);

  const moveToNextQuestion = async () => {
    setPhysicianQuestion(false);
    setFormData({ ...initialFormData });
    if (currentQuestionIndex < baseQuestion.length - 1) {
      for (
        let index = currentQuestionIndex + 1;
        index < baseQuestion.length;
        index++
      ) {
        const element = baseQuestion[index];
        if (element.BaseQuestionType === "Heading") {
          setCurrentQuestionHeader(element?.BaseQuestionText);
        }
        if (element.BaseQuestionType !== "Heading") {
          baseAnswer.findIndex(x => x.BaseQuestionId === baseQuestion[index].BaseQuestionId) < 0 && setEnableNextButton(false);
          setCurrentQuestionIndex(index);
          break;
        }
      }
    } else {
      setAssurityProgressCount({
        ...assurityCount,
        underWritingProgress: getProgressCount(),
      });
      saveUnderwriteDetails(
        "24,ASSURITY_UNDERWRITING," + getRoute(),
        getRoute()
      );
    }
  };

  const onNextHandler = async () => {
    if (
      currentQuestionIndex < baseQuestion.length - 1) {
      if (validateQuestions()) {
        const baseAnswerValue = baseAnswer.find(
          (x) =>
            x.BaseQuestionId ===
            baseQuestion[currentQuestionIndex].BaseQuestionId
        )?.answerValue;
        if (baseAnswerValue === "true") {
          // console.log(reflexiveQuestionList)
          reflexiveQuestionList.filter(x => x.BaseQuestionId === baseQuestion[currentQuestionIndex].BaseQuestionId).forEach((element) => {
            const reflexiveAns = reflexiveArr.filter(item => item.BaseQuestionId === baseQuestion[currentQuestionIndex].BaseQuestionId && item.DisclosureId === element.DisclosureId);
            if (reflexiveAns.length > 0) {
              updateReflexiveQuestion(
                baseQuestion[currentQuestionIndex],
                reflexiveAns,
                element.DisclosureId
              );
            }
          });
        } else {
          moveToNextQuestion();
        }
      }
    } else {
      if (validateField()) {
        const baseAnswerValue = baseAnswer.find(
          (x) =>
            x.BaseQuestionId ===
            baseQuestion[currentQuestionIndex].BaseQuestionId
        )?.answerValue;
        if (baseAnswerValue === "true") {
          reflexiveQuestionList.filter(x => x.BaseQuestionId === baseQuestion[currentQuestionIndex].BaseQuestionId).forEach((element) => {
            const reflexiveAns = reflexiveArr.filter(item => item.BaseQuestionId === baseQuestion[currentQuestionIndex].BaseQuestionId && item.DisclosureId === element.DisclosureId);
            if (reflexiveAns.length > 0) {
              updateReflexiveQuestion(
                baseQuestion[currentQuestionIndex],
                reflexiveAns,
                element.DisclosureId
              );
            }
          });
        }
        physicianQuestion ? updatePhysicianDisclosureHeath() : updateHealth();
        setAssurityProgressCount({
          ...assurityCount,
          underWritingProgress: getProgressCount(),
        });
      }
    }
  };

  const saveUnderwriteDetails = async (step, route) => {
    try {
      const request = {
        applicationId: underwriting?.applicationId,
        applicationType: "Web",
        nextStep: step,
      };

      const isAuthorized = getJwt() ? true : false;
      const apiEndPoint = API_URL_UNDERWRITING + "saveUnderWriting";
      const response = await commonApiCall(
        apiEndPoint,
        "post",
        request,
        UNDERWRITING_DETAILS,
        isAuthorized
      );

      if (response.status.code === 200) {

        props.history.push(route);

      }
    } catch (err) {
      showErrorToast(err);
    }
  };

  const onBackHandler = () => {
    for (let index = currentQuestionIndex - 1; index >= 0; index--) {
      const element = baseQuestion[index];
      if (element.BaseQuestionType === "Heading") {
        setCurrentQuestionHeader(element?.BaseQuestionText);
      }
      if (element.BaseQuestionType !== "Heading") {
        setEnableNextButton(true);
        setCurrentQuestionIndex(index);
        break;
      }
    }
  };

  return (
    <div className="underwriting-detail sbli-question-container pb-5">
      {baseQuestion &&
        baseQuestion[currentQuestionIndex]?.BaseQuestionId.includes("Sub") && (
          <div
            className={"sbli-parent-question  mb-3"}
            id="sbli_question_container"
            key={baseQuestion[currentQuestionIndex]?.BaseQuestionId}
          >
            <h4
              style={{
                fontWeight: 600,
              }}
              className="mb-4 parent_question_head"
            >
              {currentQuestionHeader}
            </h4>
          </div>
        )}
      {baseQuestion &&
        baseQuestion.map((question, index) => {
          if (
            index === currentQuestionIndex &&
            question.BaseQuestionType !== "Heading"
          ) {
            return (
              <div
                className={
                  "sbli-parent-question " +
                  // (baseQuestionErrors.hasOwnProperty(question.BaseQuestionId)
                  //   ? "validate-question-parent "
                  //   : "") +
                  `question question_${question.BaseQuestionId} mb-5`
                }
                id="sbli_question_container"
                key={question.BaseQuestionId}
              >
                <h4
                  style={{
                    fontWeight: 500,
                  }}
                  className="mb-4 parent_question_head"
                >
                  {question.BaseQuestionText}
                </h4>

                {question.BaseQuestionType === "Trigger_On_Yes" && (
                  <AssurityBaseBoolean
                    question={question}
                    value={
                      getBaseAnswer(question)
                        ? getBaseAnswer(question) === "true"
                          ? "Yes"
                          : "No"
                        : ""
                    }
                    disabled={questionLoading.loading}
                    onValueChange={(question, value) => {
                      handleBaseAnswer(
                        question,
                        value.target.value.toLowerCase() === "yes"
                          ? "true"
                          : "false",
                        "answerValue"
                      );
                      value.target.value.toLowerCase() === "no" &&
                        setReflexiveQuestionList([]);
                      if (question.SearchType[0].Value !== "Pick_List") {
                        baseBooleanTrigger(question, value.target.value);
                      }
                    }}
                  />
                )}
                {question.SearchType[0]?.TriggerOn ===
                  (getBaseAnswer(question)
                    ? getBaseAnswer(question) === "true"
                      ? "Yes"
                      : "No"
                    : "") &&
                  question.SearchType[0]?.Value === "Search" && (
                    <div className="mt-4">
                      <AssurityTextField
                        options={
                          showSearchType.filter(
                            (x) => x.questionId === question.BaseQuestionId
                          ).length > 0
                            ? showSearchType.filter(
                              (x) => x.questionId === question.BaseQuestionId
                            )[0].options
                            : []
                        }
                        value={
                          showSearchType.filter(
                            (x) => x.questionId === question.BaseQuestionId
                          ).length > 0
                            ? showSearchType.filter(
                              (x) => x.questionId === question.BaseQuestionId
                            )[0].value
                            : ""
                        }
                        onValueChange={(e, item) => {
                          let tempSearchType = [...showSearchType];
                          let searchIndex = tempSearchType.findIndex(
                            (x) => x.questionId === question.BaseQuestionId
                          );
                          if (searchIndex > -1) {
                            tempSearchType[searchIndex].value = e;
                          }

                          setSearchType(tempSearchType);
                          setReflexiveQuestionList([]);
                          setReflexiveArr([]);
                          setPhysicianQuestion(false);
                          getReflexiveQuestions(
                            question.BaseQuestionId,
                            item.DisclosureId
                          );
                        }}
                        onInputChange={(d) => handleInputChange(question, d)}
                      />
                    </div>
                  )}

                {question.BaseQuestionType !== "Heading" &&
                  questionLoading.questionId === question.BaseQuestionId &&
                  questionLoading.loading && (
                    <p className="mt-3">Please wait....</p>
                  )}

                {getBaseAnswer(question) &&
                  question.SearchType[0].Value === "Pick_List" &&
                  (question.SearchType[0].TriggerOn === "Yes"
                    ? "true"
                    : "false") === getBaseAnswer(question) && (
                    <ShowDisclosurePickList
                      list={question.BaseQuestionDisclosures}
                      onValueChange={(id) =>
                        handleBaseAnswer(question, id, "disclosureId")
                      }
                      value={getPickListClosureId(question)}
                      baseAnswer={getBaseAnswer(question)}
                    />
                  )}

                {getReflexiveList(question) &&
                  getReflexiveList(question).ReflexiveQuestion &&
                  renderReflexiveQuestion(
                    question,
                    getReflexiveList(question).ReflexiveQuestion,
                    getReflexiveList(question).DisclosureId
                  )
                }
              </div>
            );
          }
        })}

      {(
        baseQuestion &&
        currentQuestionIndex === baseQuestion.length - 1) && (
          <AssurityPhysicianRequest
            formData={formData}
            handleChange={handleChange}
          />
        )}

      <div className="w-100 my-4 pb-5 text-center">
        <button
          className="btn btn-default px-5 d-block mx-auto mb-3"
          disabled={
            (loading && !enableNextButton) ||
            (checkFLandND())
          }
          onClick={onNextHandler}
        >
          {loading || (checkFLandND())
            ? "Processing..."
            : "Next"}
        </button>
        {(baseQuestion && baseQuestion[0]?.BaseQuestionType === "Heading"
          ? currentQuestionIndex > 1
          : currentQuestionIndex > 0) && (
            <button
              className="btn btn-link px-5 d-block mx-auto"
              disabled={loading}
              onClick={onBackHandler}
            >
              {loading ? "Processing..." : "Back"}
            </button>
          )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  underwriting: state.underwriting.underwriting,
  assurityCount: state.underwriting.assuProgressCount,
});
export default connect(mapStateToProps, {
  commonApiCall,
  setAssurityProgressCount,
})(withRouter(CovidQuestion));
