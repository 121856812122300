import http from "./http";
import {
  consoleLog,
  getJwt,
  validationMessages,
  setJwt,
  SESSION_LOGOUT,
} from "./index";
import { showErrorToast } from "../../utils";
import history from "../../utils/history";

export const commonApiCall =
  (
    url,
    method,
    data,
    dispatchType = null,
    isAuthorized = false,
    isMultipart = false
  ) =>
  (dispatch) => {
    if (getJwt()) http.setJwtAuthorization(getJwt());
    else http.removeJwtAuthorization();
    if (isMultipart) http.setMultipart();

    return new Promise((resolve, reject) => {
      http
        .axios({ method, url, data })
        .then((response) => {
          if (response && response.status === 200) {
            if (dispatchType === "CONSUMER_DETAILS") {
              const payload = { data: response.data.data, details: data };
              dispatch({ type: dispatchType, payload });
            }
            if (dispatchType !== null) {
              const payload = { data: response.data.data };
              dispatch({ type: dispatchType, payload });
            }
            const responseData = response.data;
            resolve(responseData);
          } else if (response && response.status === 401) {
            showErrorToast(validationMessages.SESSTION_EXPIRED);
            const payload = { data: "" };
            dispatch({ type: SESSION_LOGOUT, payload });
            setJwt("");
            history.push("/login");
          } else if (response && response.status === 403) {
            history.push("/dashboard");
          } else if (response && response.status === 500) {
            showErrorToast(validationMessages.INTERNAL_SERVER_ERROR);
            const responseData = response.data;
            resolve(responseData);
          } else {
            const responseData = response.data;
            resolve(responseData);
          }
        })
        .catch((error) => {
          console.log("error is ", error);
          reject(error);
        });
    });
  };

export const commonApiCallHeader =
  (
    url,
    method,
    data,
    dispatchType = null,
    isAuthorized = false,
    isMultipart = false,
    _headers
  ) =>
  (dispatch) => {
    if (getJwt()) http.setJwtAuthorization(getJwt());
    else http.removeJwtAuthorization();
    if (isMultipart) http.setMultipart();
    let headers = _headers;
    headers["Content-Type"] = "application/json";
    return new Promise((resolve, reject) => {
      http
        .axios({ method, url, headers, data })
        .then((response) => {
          if (response && response.status === 200) {
            if (dispatchType === "CONSUMER_DETAILS") {
              const payload = { data: response.data.data, details: data };
              dispatch({ type: dispatchType, payload });
            }
            if (dispatchType !== null) {
              const payload = { data: response.data.data };
              dispatch({ type: dispatchType, payload });
            }
            const responseData = response.data;
            resolve(responseData);
          } else if (response && response.status === 401) {
            showErrorToast(validationMessages.SESSTION_EXPIRED);
            const payload = { data: "" };
            dispatch({ type: SESSION_LOGOUT, payload });
            setJwt("");
            history.push("/login");
          } else if (response && response.status === 403) {
            history.push("/dashboard");
          } else {
            const responseData = response.data;
            resolve(responseData);
          }
        })
        .catch((error) => {
          consoleLog("error is ", error);
          reject(error);
        });
    });
  };
