import React, { useState, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Animated } from "react-animated-css";
import * as HumanConnect from "humanapi-connect-client";
import { Modal, Button } from "react-bootstrap";
import NavBar from "../../components/navBar";
import { UnderProcessLoader } from "../../components/skeletonLoaders";
import {
  DashboardRoute,
  UnderWritingRoutes,
  showErrorToast,
  decryptme,
} from "../../utils/index";
import {
  commonApiCall,
  clearUnderWriting,
  clearCompanyInformation,
  updateConsumer,
  getJwt,
  consoleLog,
  COMPANY_INFORMATION,
} from "../../redux/actions/index";
import {
  API_URL_UNDERWRITING,
  API_URL_CLIENT,
  API_URL_HUMAN_API,
  CONSUMER_PORTAL_URL,
} from "../../config/configs";
import UnderProcessListing from "../../components/subComponents/underProcessListing";

const Index = ({
  commonApiCall,
  clearUnderWriting,
  clearCompanyInformation,
  updateConsumer,
  consumer,
  t,
  underwriting,
  company_information,
  ...props
}) => {
  localStorage.removeItem("policyId");
  const [isLoading, setIsLoading] = useState(true);
  const [processList, setProcessList] = useState([]);
  const [guidedTourVisited, setGuidedTourVisited] = useState(
    consumer?.guidedTourVisited
  );
  const [colorObject] = useState({
    backgroundColor: "#fbfcfc",
    fontPreference: "Manrope",
    hoverEffectColor: "#2ba84a",
    inputTextColor: "#091f1f",
    labelBackgroundColor: "#ffffff",
    primaryColor: "#2ba84a",
    screenBackgroundColor: "#ffffff",
    underwritingTextHoverColor: "#ecfdf1",
    headerBkg: "#ffffff",
  });
  useEffect(() => {
    async function doSubmit() {
      const authorization = true;
      let response = await commonApiCall(
        API_URL_UNDERWRITING + `clientApplications?status=INPROGRESS&size=3`,
        "get",
        "",
        "",
        authorization
      );
      if (response && response.status.code === 200) {
        setProcessList(response.data.underProcess);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    }
    doSubmit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setDefaultTheme = (obj) => {
    document.body.style.setProperty(
      "--color-primary",
      obj.primaryColor ? obj.primaryColor : colorObject.primaryColor
    );
    document.body.style.setProperty(
      "--color-background",
      obj.screenBackgroundColor
        ? obj.screenBackgroundColor
        : colorObject.screenBackgroundColor
    );
    document.body.style.setProperty(
      "--color-headerapply",
      obj.backgroundColor ? obj.backgroundColor : colorObject.headerBkg
    );
    document.body.style.setProperty(
      "--color-white",
      obj.labelBackgroundColor
        ? obj.labelBackgroundColor
        : colorObject.labelBackgroundColor
    );
    document.body.style.setProperty(
      "--color-txt-df",
      obj.inputTextColor ? obj.inputTextColor : colorObject.inputTextColor
    );
    document.body.style.setProperty(
      "--color-over-effect",
      obj.hoverEffectColor ? obj.hoverEffectColor : colorObject.hoverEffectColor
    );
    document.body.style.setProperty(
      "--color-success-lgt",
      obj.underwritingTextHoverColor
        ? obj.underwritingTextHoverColor
        : colorObject.underwritingTextHoverColor
    );
    document.body.style.setProperty(
      "--font-var",
      obj.fontPreference ? obj.fontPreference : colorObject.fontPreference
    );
  };

  const handleNavigation = async () => {
    const domainNamePath = window.location.hostname;
    const domainName = domainNamePath.split(".");
    try {
      const request = {};
      let apiEndPoint;
      if (company_information?.refferalCode) {
        apiEndPoint =
          API_URL_CLIENT +
          "getCompanyInfo?referralCode=" +
          company_information.refferalCode;
      } else if (consumer?.referralCode) {
        apiEndPoint =
          API_URL_CLIENT +
          "getCompanyInfo?referralCode=" +
          consumer.referralCode;
      } else {
        apiEndPoint =
          API_URL_CLIENT + "getCompanyInfo?domainName=" + domainName[0];
      }

      const isAuthorized = false;
      const response = await commonApiCall(
        apiEndPoint,
        "get",
        request,
        COMPANY_INFORMATION,
        isAuthorized
      );

      if (response.status.code === 200) {
        setDefaultTheme(response.data.agentSettings);
        await clearUnderWriting();
        if (
          response?.data?.defaultPage === "goal_type" ||
          response?.data?.defaultPage === "welcome_screen"
        ) {
          props.history.push(UnderWritingRoutes.GET_START);
        } else if (response?.data?.defaultPage === "insurance") {
          props.history.push(UnderWritingRoutes.INSURE_TYPE);
        } else if (response?.data?.defaultPage === "investment") {
          props.history.push(UnderWritingRoutes.INVESTMENT_TYPE);
        } else if (
          (response?.data?.defaultPage &&
            response?.data?.defaultPage === "final_expense") ||
          (response?.data?.defaultPage &&
            response?.data?.defaultPage === "term") ||
          (response?.data?.defaultPage &&
            response?.data?.defaultPage === "permanent")
        ) {
          if (response?.data?.prospectJourney === "single_page") {
            props.history.push(UnderWritingRoutes.SINGLE_PAGE);
          } else {
            props.history.push(UnderWritingRoutes.YOUR_GENDER);
          }
        } else if (
          response?.data?.defaultPage &&
          response?.data?.defaultPage === "medicare"
        ) {
          if (response?.data?.prospectJourney === "single_page") {
            props.history.push(UnderWritingRoutes.SINGLE_PAGE);
          } else {
            props.history.push(UnderWritingRoutes.MEDICARE_ENROLL);
          }
        }
      } else if (response.status.code === 404) {
        await clearUnderWriting();
        await clearCompanyInformation();
        setDefaultTheme("");
        window.location.replace(CONSUMER_PORTAL_URL + "404");
      }
    } catch (err) {
      showErrorToast(t("validation_message.SOMETHING_WENT_WRONG"));
    }
  };

  function handleClose(response) {
    if (response.status === "SUCCESS") {
      const isAuthorized = getJwt() ? true : false;
      const apiEndPoint = API_URL_HUMAN_API + "generateMedicalReport";
      const request = {
        clientUserId: "HUMAN_ILIFECLIENT_" + consumer.id,
        type: "access",
      };
      async function humanAPiPost() {
        try {
          let response = await commonApiCall(
            apiEndPoint,
            "post",
            request,
            null,
            isAuthorized
          );
          if (response.status.code === 200) {
          } else if (response.status.code === 403) {
            let boxId = document.getElementById("humanapi-box");
            if (boxId) {
              boxId.checked = "";
            }
          }
        } catch (error) {
          showErrorToast(t("dashboard.something_went_wrong"));
        }
      }
      humanAPiPost();
    } else {
      consoleLog("Widget closed with error", response.status);
    }
  }

  function handleConnect(response) {
    consoleLog("Connected a source");
  }

  function handleDisconnect(response) {
    consoleLog("Disconnected a source");
  }

  const getHumanApiCall = () => {
    const isAuthorized = getJwt() ? true : false;
    const apiEndPoint = API_URL_HUMAN_API + "generateMedicalReport";

    const request = {
      clientUserId: "HUMAN_ILIFECLIENT_" + consumer.id,
      type: "session",
    };
    async function humanAPiGetToken() {
      try {
        let response = await commonApiCall(
          apiEndPoint,
          "post",
          request,
          null,
          isAuthorized
        );
        if (response.status.code === 200) {
          HumanConnect.open({
            token: decryptme(response.data), // "session_token" or "id_token"
            onClose: handleClose, // invoked when the user closes Connect
            onConnectSource: handleConnect, // invoked when the user connects a source
            onDisconnectSource: handleDisconnect, // invoked when the user disconnects a source
          });
        } else if (response.status.code === 403) {
          HumanConnect.open({
            token: decryptme(response.data), // "session_token" or "id_token"
            onClose: handleClose, // invoked when the user closes Connect
            onConnectSource: handleConnect, // invoked when the user connects a source
            onDisconnectSource: handleDisconnect, // invoked when the user disconnects a source
          });
        } else {
          showErrorToast(response.status.message);
        }
      } catch (error) {
        showErrorToast(t("dashboard.something_went_wrong"));
      }
    }
    humanAPiGetToken();
  };

  const handleGuidedTour = async () => {
    try {
      const isAuthorized = getJwt() ? true : false;
      const apiEndPoint = API_URL_CLIENT + "clientSettings";

      const request = {
        guidedTourVisited: true,
      };
      let response = await commonApiCall(
        apiEndPoint,
        "PATCH",
        request,
        null,
        isAuthorized
      );
      if (response.status.code === 200) {
        let consumerDetails = consumer;
        consumerDetails.guidedTourVisited = true;
        setGuidedTourVisited(true);
        await updateConsumer(consumerDetails);
      } else {
        showErrorToast(response.status.message);
      }
    } catch (error) {
      showErrorToast(t("dashboard.something_went_wrong"));
    }
  };

  return (
    <React.Fragment>
      <div className="row">
        <div className="col-3 sidebar-part">
          <NavBar t={t}></NavBar>
        </div>
        <div className="col-lg-9">
          <Animated
            animationIn="fadeIn"
            animationOut="faderOutLeft"
            animationInDuration={1200}
            animationOutDuration={800}
            isVisible={true}
          >
            <div className="white-box2">
              <div className="dashboard-header-outer">
                <div className="dashboard-header">
                  <h3 className="font-24">
                    {t("dashboard.connect_my_medical_records")}
                  </h3>
                  <h4>
                    {/* Retrieve medical records and send securely to insurance
                    companies for fastest approval results */}
                    {t(
                      "dashboard.retrieve_medical_records_and_send_securely_to_insurance_companies_for_faster_underwriting_results"
                    )}
                  </h4>
                </div>
                <div>
                  <div>
                    <div>
                      <button
                        className="btn btn-primary btn-lg"
                        onClick={() => getHumanApiCall()}
                      >
                        {t("dashboard.connect_now")}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="white-box2">
              <div className="box-title">
                <h3 className="font-24">{t("dashboard.processing")}</h3>
                <div className="row getstart-container">
                  <div className="getstart-button">
                    <button
                      className="btn btn-primary btn-lg"
                      onClick={() => handleNavigation()}
                      title="Start new Application"
                    >
                      {t("dashboard.browse_quotes")}
                    </button>
                  </div>
                  {processList && processList.length > 0 && (
                    <Link
                      to={DashboardRoute.UNDERPROCESS}
                      className="btn btn-link link-primary"
                    >
                      {t("dashboard.view_all")}
                    </Link>
                  )}
                </div>
              </div>
              {isLoading ? (
                <UnderProcessLoader />
              ) : (
                <UnderProcessListing
                  processList={processList}
                  from="Dashboard"
                  t={t}
                />
              )}
            </div>
            {!guidedTourVisited && (
              <Modal
                className="pdf-modal"
                show={!guidedTourVisited}
                onHide={() => handleGuidedTour()}
                centered
                size="sm"
              >
                <Modal.Header closeButton>
                  <Modal.Title>Welcome to iLife</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <p>
                    Welcome to your dashboard! Here you can easily check your
                    application documents and chat with your licensed expert!
                  </p>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="btn btn-primary"
                    onClick={() => handleGuidedTour()}
                  >
                    Close
                  </Button>
                </Modal.Footer>
              </Modal>
            )}
          </Animated>
        </div>
      </div>
    </React.Fragment>
  );
};
const mapStateToProps = (state) => ({
  consumer: state.consumer.consumerdetails,
  underwriting: state.underwriting.underwriting,
  company_information: state.underwriting.companyInformation,
});
export default connect(mapStateToProps, {
  commonApiCall,
  clearUnderWriting,
  clearCompanyInformation,
  updateConsumer,
})(withRouter(Index));
