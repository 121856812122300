import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { commonApiCall, consoleLog, getJwt } from "../../../redux/actions";
import { showErrorToast, getState } from "../../../utils";
import Select from "react-select";
import { API_URL_CLIENT } from "../../../config/configs";
import { getAssurityCountry, getReasonList } from "./userdetail-list";
import NumberFormat from "react-number-format";

const AssurityDisclosurePhysician = ({
    commonApiCall,
    formData,
    handleChange,
    disclosureId,
    setFormData,
    initialFormData
}) => {
    const [countryList, setCountryList] = useState([]);
    const [stateDropdown, setStateDropDown] = useState(null);
    const formItems = formData[disclosureId] ?? [{ ...initialFormData }];
    const getCountryList = async () => {
        try {
            const isAuthorized = getJwt() ? true : false;
            let response = await commonApiCall(
                API_URL_CLIENT + `getCountry`,
                "get",
                "",
                "",
                isAuthorized
            );

            if (response.status.code === 200) {
                let getCountry = response.data.map((list) => {
                    return {
                        value: list.code,
                        label: list.name,
                    };
                });
                setCountryList({
                    ...countryList,
                    getCountry,
                });
            }
        } catch (err) {
            showErrorToast(err);
        }
    };

    const handleState = async (value) => {
        try {
            if (value?.value !== "USA" && value?.value !== "CANADA") {
                setStateDropDown(null);
            }
            else {
                let response = await commonApiCall(
                    API_URL_CLIENT +
                    "getState?countryId=" +
                    (value?.value === "USA" ? 1 : value?.value === "CANADA" ? 4 : null),
                    "",
                    "get"
                );

                if (response && response !== "" && response.status.code === 200) {
                    var new_options = [];
                    response.data &&
                        response.data.length > 0 &&
                        response.data.map((element) => {
                            new_options.push({
                                value: element.code,
                                label: element.name,
                            });
                            return true;
                        });
                    setStateDropDown(new_options);
                } else if (response && response !== "" && response.status.code === 404) {
                    setStateDropDown(null);
                }
            }
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        getCountryList();
        if(formData[disclosureId]) {
            formData[disclosureId].forEach(formItem => {
               formItem.country !== "" && handleState(formItem.country);
            });
        }
    }, []);


    // console.log(formItems, disclosureId);
    return (
        <>
            <div className={`sbli-main-question physician_${disclosureId} card border text-left mb-0 shadow-none`}>
                <div className="container-fluid">
                    <form>
                        <div className="row justify-content-center">
                            <div className={"col-md-12"}>
                                <div
                                    className={
                                        "sbli-parent-question mb-0 " +
                                        (formItems[0].errors.hasOwnProperty(`healthQuestion`)
                                            ? "validate-question-parent"
                                            : "")
                                    }
                                    id="sbli_question_container"
                                >
                                    <h4 className="mb-4 parent_question_head">
                                        Do you have a primary care physician or medical centre?
                                    </h4>{" "}
                                    <span className="custom-radio mt-3 d-block">
                                        <label className="m-0 mb-3" htmlFor={`ab_${disclosureId}`}>
                                            <input
                                                type="radio"
                                                name={"healthQuestion"}
                                                id={`ab_${disclosureId}`}
                                                onChange={(e) => handleChange(e, disclosureId, 0)}
                                                value={"yes"}
                                                checked={formItems[0].healthQuestion === "yes"}
                                            />
                                            <span></span> Yes
                                        </label>

                                        <label className="m-0" htmlFor={`ba_${disclosureId}`}>
                                            <input
                                                type="radio"
                                                name={"healthQuestion"}
                                                id={`ba_${disclosureId}`}
                                                onChange={(e) => handleChange(e, disclosureId, 0)}
                                                value={"no"}
                                                checked={formItems[0].healthQuestion === "no"}
                                            />
                                            <span></span> No
                                        </label>
                                    </span>
                                </div>

                                {formItems[0].healthQuestion === "yes" && formItems.map((formItem, index) => {
                                    const { errors } = formItems[index];
                                    return (
                                        <div className="border-top pt-3 mt-4">
                                            {" "}
                                            <span className="d-flex justify-content-end mb-3 text-danger">{index > 0 && <a onClick={() => {
                                                let tempFormData = { ...formData };
                                                formItems.splice(index, 1);
                                                setFormData({ ...tempFormData });
                                            }} ><u>Remove</u></a>}</span>
                                            <h4 className="text-medium mb-3">
                                                {`Please provide information about your attending physician ${index + 1}`}
                                            </h4>
                                            <div className="row">

                                                <div className="col-12">
                                                    <div className="form-group">
                                                        <div className="input-effect">
                                                            <input
                                                                className="form-control"
                                                                placeholder="First Name"
                                                                name="first_name"
                                                                id="first_name"
                                                                type="text"
                                                                value={formItem.first_name}
                                                                onChange={(e) => handleChange(e, disclosureId, index)}
                                                                maxLength={20}
                                                            />
                                                            <label htmlFor="first_name">{"First Name"}<sup className="text-danger">*</sup></label>
                                                            <em className="input-icon"></em>
                                                            <span className="text-danger">
                                                                {errors?.first_name}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <div className="input-effect">
                                                            <input
                                                                className="form-control"
                                                                placeholder="Middle Name"
                                                                name="middle_name"
                                                                id="middle_name"
                                                                type="text"
                                                                value={formItem.middle_name}
                                                                onChange={(e) => handleChange(e, disclosureId, index)}
                                                                maxLength={20}
                                                            />
                                                            <label htmlFor="middle_name">{"Middle Name"}</label>
                                                            <em className="input-icon"></em>
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <div className="input-effect">
                                                            <input
                                                                className="form-control"
                                                                placeholder="Last Name"
                                                                name="last_name"
                                                                id="last_name"
                                                                type="text"
                                                                value={formItem.last_name}
                                                                onChange={(e) => handleChange(e, disclosureId, index)}
                                                                maxLength={40}
                                                            />
                                                            <label htmlFor="last_name">{"Last Name"}<sup className="text-danger">*</sup></label>
                                                            <em className="input-icon"></em>
                                                            <span className="text-danger">
                                                                {errors?.last_name}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <div className="input-effect">
                                                            <input
                                                                className="form-control"
                                                                placeholder="Suffix"
                                                                name="suffix"
                                                                id="suffix"
                                                                type="text"
                                                                value={formItem.suffix}
                                                                onChange={(e) => handleChange(e, disclosureId, index)}
                                                                maxLength={6}
                                                            />
                                                            <label htmlFor="suffix">{"Suffix"}</label>
                                                            <em className="input-icon"></em>
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <div className="input-effect">
                                                            <input
                                                                className="form-control"
                                                                placeholder="Address"
                                                                name="address"
                                                                id="address"
                                                                type="text"
                                                                value={formItem.address}
                                                                onChange={(e) => handleChange(e, disclosureId, index)}
                                                                maxLength={35}
                                                            />
                                                            <label htmlFor="address">{"Address"}<sup className="text-danger">*</sup></label>
                                                            <em className="input-icon"></em>
                                                            <span className="text-danger">
                                                                {errors?.address}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <div className="input-effect">
                                                            <input
                                                                className="form-control"
                                                                placeholder="Address line 2"
                                                                name="address_2"
                                                                id="address_2"
                                                                type="text"
                                                                value={formItem.address_2}
                                                                onChange={(e) => handleChange(e, disclosureId, index)}
                                                                maxLength={35}
                                                            />
                                                            <label htmlFor="address">{"Address"}</label>
                                                            <em className="input-icon"></em>
                                                        </div>
                                                    </div>

                                                    <div className="form-group fixed-floating-label mb-4">
                                                        <div className="default-select with-border">
                                                            <Select
                                                                className={"custom-select-menu"}
                                                                classNamePrefix={"custom-select"}
                                                                options={getAssurityCountry()}
                                                                onChange={(value) => {
                                                                    handleChange({
                                                                        target: {
                                                                            name: "country",
                                                                            value: value,
                                                                        },
                                                                    }, disclosureId, index);
                                                                    handleState(value);
                                                                }}
                                                                value={formItem.country}
                                                            />

                                                            <span className="fixed-label">Country<sup className="text-danger">*</sup></span>
                                                            <span className="text-danger ">
                                                                {errors?.country}
                                                            </span>
                                                        </div>
                                                    </div>

                                                    <div className="form-group">
                                                        <div className="input-effect">
                                                            <input
                                                                className="form-control"
                                                                placeholder="City"
                                                                name="city"
                                                                id="city"
                                                                type="text"
                                                                value={formItem.city}
                                                                onChange={(e) => handleChange(e, disclosureId, index)}
                                                            />
                                                            <label htmlFor="city">{"City"}<sup className="text-danger">*</sup></label>
                                                            <em className="input-icon"></em>
                                                            <span className="text-danger ">{errors?.city}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={stateDropdown ? "col-md-5" : "col-md-12"}>
                                                    <div className="form-group">
                                                        <div className="input-effect">
                                                            <input
                                                                className="form-control"
                                                                placeholder="Zip Code"
                                                                name="zipCode"
                                                                id="zipCode"
                                                                type="text"
                                                                minLength={5}
                                                                maxLength={10}
                                                                pattern={"[A-Za-z0-9]{5,10}"}
                                                                value={formItem.zipCode}
                                                                onChange={(e) => handleChange(e, disclosureId, index)}
                                                            />
                                                            <label htmlFor="zipCode">{"Zip Code"}<sup className="text-danger">*</sup></label>
                                                            <em className="input-icon"></em>
                                                            <span className="text-danger">
                                                                {errors?.zipCode}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                {stateDropdown && (
                                                    <div className="col-md-7">
                                                        <div className="form-group fixed-floating-label mb-4">
                                                            <div className="default-select with-border">
                                                                <Select
                                                                    className={"custom-select-menu"}
                                                                    classNamePrefix={"custom-select"}
                                                                    options={stateDropdown}
                                                                    onChange={(value) => {
                                                                        handleChange({
                                                                            target: {
                                                                                name: "state",
                                                                                value: value,
                                                                            },
                                                                        }, disclosureId, index);
                                                                    }}
                                                                    value={formItem.state}
                                                                />

                                                                <span className="fixed-label">State<sup className="text-danger">*</sup></span>
                                                                <span className="text-danger ">
                                                                    {errors?.state}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}

                                                <div className="col-12">
                                                    <div className="form-group">
                                                        <div className="input-effect">
                                                            <input
                                                                className="form-control"
                                                                placeholder="Email"
                                                                name="email"
                                                                id="email"
                                                                type="email"
                                                                value={formItem.email}
                                                                onChange={(e) => handleChange(e, disclosureId, index)}
                                                            />
                                                            <label htmlFor="email">{"Email"}</label>
                                                            <em className="input-icon"></em>
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <div className="input-effect">
                                                            <NumberFormat
                                                                name="mobile"
                                                                id="mobile"
                                                                className="form-control"
                                                                placeholder="Enter Your Mobile Phone Number"
                                                                format="(###) ###-####"
                                                                value={formItem.mobile}
                                                                onChange={(e) => handleChange(e, disclosureId, index)}

                                                            />

                                                            <label htmlFor="mobile">{"Phone"}<sup className="text-danger">*</sup></label>
                                                            <em className="input-icon"></em>
                                                            <span className="text-danger">
                                                                {errors?.mobile}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <div className="input-effect">
                                                            <NumberFormat
                                                                name="fax"
                                                                id="fax"
                                                                className="form-control"
                                                                placeholder="Fax"
                                                                format="(###) ###-####"
                                                                value={formItem.fax}
                                                                onChange={(e) => handleChange(e, disclosureId, index)}

                                                            />
                                                            <label htmlFor="fax">{"Fax"}</label>
                                                            <em className="input-icon"></em>
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <div className="input-effect">
                                                            <input
                                                                className="form-control"
                                                                placeholder="Speciality"
                                                                name="speciality"
                                                                id="Speciality"
                                                                type="text"
                                                                value={formItem.speciality}
                                                                onChange={(e) => handleChange(e, disclosureId, index)}
                                                                maxLength={60}
                                                            />
                                                            <label htmlFor="Speciality">{"Speciality"}</label>
                                                            <em className="input-icon"></em>
                                                        </div>
                                                    </div>

                                                    <h4 className="text-medium">Date of last visit<sup className="text-danger">*</sup></h4>
                                                    <div className="col-md-14">
                                                        <div className="form-group custom-datepicker">
                                                            <DatePicker
                                                                selected={formItem.lastVisitDate}
                                                                maxDate={new Date()}
                                                                placeholderText="MM/DD/YYYY"
                                                                peekNextMonth
                                                                showMonthDropdown
                                                                showYearDropdown
                                                                dropdownMode="select"
                                                                dateFormatCalendar="MMMM"
                                                                value={formItem.lastVisitDate}
                                                                onChange={(date) =>
                                                                    handleChange({
                                                                        target: {
                                                                            name: "lastVisitDate",
                                                                            value: date,
                                                                        },
                                                                    }, disclosureId, index)
                                                                }
                                                            />
                                                            <span className="text-danger">
                                                                {errors?.lastVisitDate}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <h4 className="text-medium">Reason of last visit<sup className="text-danger">*</sup></h4>
                                                    <div className="form-group fixed-floating-label mb-4">
                                                        <div className="default-select with-border">
                                                            <Select
                                                                className={"custom-select-menu"}
                                                                classNamePrefix={"custom-select"}
                                                                options={getReasonList()}
                                                                onChange={(value) => {
                                                                    handleChange({
                                                                        target: {
                                                                            name: "lastVisitReason",
                                                                            value: value,
                                                                        },
                                                                    }, disclosureId, index);
                                                                }}
                                                                value={formItem.lastVisitReason}
                                                            />

                                                            {/* <span className="fixed-label">Reason</span> */}
                                                            <span className="text-danger ">
                                                                {errors?.lastVisitReason}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <h4 className="text-medium">
                                                        Reason of last visit (Description){formItem?.lastVisitReason?.value === "Other" ? <sup className="text-danger">*</sup> : null}
                                                    </h4>
                                                    <div className="form-group">
                                                        <div className="input-effect">
                                                            <input
                                                                className="form-control"
                                                                placeholder=""
                                                                name="lastVisitReasonDescription"
                                                                id="lastVisitReasonDescription"
                                                                type="text"
                                                                value={formItem.lastVisitReasonDescription}
                                                                onChange={(e) => handleChange(e, disclosureId, index)}
                                                            />
                                                            <label htmlFor="lastVisitReasonDescription">
                                                                {"Reason of last visit"}
                                                            </label>
                                                            <em className="input-icon"></em>
                                                            <span className="text-danger">
                                                                {errors?.lastVisitReasonDescription}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            {formItems.length - 1 === index && <a className="text-success" onClick={() => {
                                                setFormData({ ...formData, [disclosureId]: [...formItems, { ...initialFormData, healthQuestion: "yes" }] });
                                            }}><u>Add another</u></a>}

                                        </div>
                                    )
                                }
                                )}
                            </div>
                        </div>
                    </form>
                </div >
            </div >
        </>
    );
};

const mapStateToProps = (state) => ({
    underwriting: state.underwriting.underwriting,
});
export default connect(mapStateToProps, {
    commonApiCall,
})(withRouter(AssurityDisclosurePhysician));
