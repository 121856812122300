import React, { useState, useEffect } from "react";
import { NavLink, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { DashboardRoute, decryptme } from "../utils";
import { isEmpty } from "lodash";
const PolicyNavbar = ({
  profile_picture,
  consumer,
  redirectPathBack,
  t,
  ...props
}) => {
  const [formData, setFormData] = useState({
    profilePic: "",
    email: "",
    firstName: "",
  });

  useEffect(() => {
    setFormData({
      ...formData,
      profilePic: !isEmpty(profile_picture)
        ? profile_picture?.profilePic
        : !isEmpty(consumer)
        ? consumer?.profilePic
        : "",
      firstName: !isEmpty(profile_picture)
        ? profile_picture?.firstName
        : !isEmpty(consumer)
        ? decryptme(consumer?.firstName)
        : "",
      email: !isEmpty(profile_picture)
        ? profile_picture?.email
        : !isEmpty(consumer)
        ? decryptme(consumer?.email)
        : "",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile_picture, consumer]);

  const errorImageReplace = (id) => {
    document.getElementById(id).src = "/assets/images/user-dp-demo.jpg";
  };

  const closeSidebar = () => {
    document.body.classList.remove("sidebar-open");
  };
  const { profilePic, email, firstName } = formData;
  return (
    <div className='sidebar'>
      <div className='sidebar-profile text-center white-box2'>
        <div className='sidebar-profile-img'>
          <img
            src={profilePic ? profilePic : "/assets/images/user-dp-demo.jpg"}
            alt=''
            title='profile'
            id='side-img'
            onError={(e) => errorImageReplace("side-img")}
          />
        </div>
        <h3>
          {t("policy_bar.hello")} {firstName}!
        </h3>
        <h5>{email}</h5>
        <NavLink
          onClick={closeSidebar}
          to={
            redirectPathBack === "Dashboard"
              ? DashboardRoute.DASHBOARD
              : DashboardRoute.UNDERPROCESS
          }
          className='back-btn'
        >
          <span className='material-icons'>arrow_back_ios</span>
        </NavLink>
      </div>
      <div className='white-box2 sidebar-links-outer'>
        <ul>
          <li className='sidebar-link'>
            <NavLink
              to={DashboardRoute.BASICDETAILSDASHBOARD}
              onClick={closeSidebar}
            >
              <span className='material-icons'>dashboard</span>
              {t("policy_bar.basic_detail")}
            </NavLink>
          </li>
          <li className='sidebar-link'>
            <NavLink
              to={DashboardRoute.PLOICYINFORMATION}
              onClick={closeSidebar}
            >
              <span className='material-icons'>assignment</span>
              {t("policy_bar.policy_information")}
            </NavLink>
          </li>
          <li className='sidebar-link'>
            <NavLink to={DashboardRoute.POLICYDOCUMENTS} onClick={closeSidebar}>
              <span className='material-icons'>insert_drive_file</span>
              {t("policy_bar.documents")}
            </NavLink>
          </li>
          <li className='sidebar-link'>
            <NavLink
              to={DashboardRoute.CONVERSATION_DOCUMENTS}
              onClick={closeSidebar}
            >
              <span className='material-icons'>insert_drive_file</span>
              {t("policy_bar.conversation_documents")}
            </NavLink>
          </li>
        </ul>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  profile_picture: state.underwriting.profile_pic,
  consumer: state.consumer.consumerdetails,
});
export default connect(mapStateToProps, null)(withRouter(PolicyNavbar));
