import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Animated } from "react-animated-css";
import NavBar from "../../components/navBar";
import moment from "moment";
import * as _ from "lodash";
import PrivateChat from "../../components/privateChat/index";
import { commonApiCall, consoleLog } from "../../redux/actions/index";
import {
  API_URL_UNDERWRITING,
  API_URL_CHATSERVICE,
} from "../../config/configs";
import { decryptme, showErrorToast } from "../../utils/index";
import { UnderProcessLoader } from "../../components/skeletonLoaders/index";

let stompClient, SockJS;
const Conversations = ({ consumer, commonApiCall, t }) => {
  const [conversationData, setConversationData] = useState([]);
  const [formData, setFormData] = useState({
    application_Id: "",
    agentResponse: {},
    chatModel: false,
    isLoading: true,
  });

  const handleClickEvent = (conversations) => {
    setFormData({
      application_Id: conversations.applicationId,
      carrierName: conversations.carrierName,
      carrierLogo: conversations.carrierLogo,
      applicationCarrierId: conversations.applicationCarrierId,
      carrierId: conversations.carrierId,
      creditRating: conversations.creditRating,
      cashValueDividend: conversations.cashValueDividend,
      minInterestRate: conversations.minInterestRate,
      policyType: conversations.policyType,
      premium: conversations.premium,
      agentResponse: conversations.availableAgent,
      chatModel: true,
      productName: conversations.productName,
      pay: conversations.pay,
      productDescription: conversations.productDescription,
    });
  };
  const getConversationList = async () => {
    try {
      const authorization = true;
      let response = await commonApiCall(
        API_URL_UNDERWRITING + `client/conversations`,
        "get",
        "",
        "",
        authorization
      );
      if (response && response.status.code === 200) {
        response.data.map((conversationsList, key) => {
          if (!conversationsList.availableAgent.messageCreatedDate)
            conversationsList.availableAgent.messageCreatedDate = null;
        });
        response.data.sort(
          (a, b) =>
            b.availableAgent.messageCreatedDate -
            a.availableAgent.messageCreatedDate
        );
        response.data.sort(
          (a, b) =>
            b.availableAgent.messageCreatedDate -
            a.availableAgent.channelCreatedDate
        );

        setConversationData(response.data);
        setFormData({ ...formData, isLoading: false, chatModel: false });
      } else {
        setConversationData([]);
        setFormData({ ...formData, isLoading: false, chatModel: false });
      }
    } catch (err) {
      setConversationData([]);
      setFormData({ ...formData, isLoading: false, chatModel: false });
      showErrorToast(t("conversations.something_went_wrong"));
    }
  };

  const connect = async () => {
    try {
      const Stomp = require("stompjs");
      SockJS = require("sockjs-client");
      SockJS = new SockJS(API_URL_CHATSERVICE + "ws");
      stompClient = Stomp.over(SockJS);
      stompClient.debug = null;
      stompClient.connect(
        {
          clientUserId: consumer && consumer.id,
          type: "CONVERSATIONSCREEN",
        },
        function () {
          onConnected();
        },
        function () {
          onError();
        }
      );
    } catch (err) {
      showErrorToast(t("conversations.something_went_wrong"));
    }
  };

  const onConnected = () => {
    try {
      if (stompClient.connected) {
        //Subscribing to the private notification room
        stompClient.subscribe(
          "/user/client-" + consumer.id + "/conversation",
          function (responsePayload) {
            onMessageReceived(responsePayload);
          }
        );
        stompClient.subscribe(
          "/user/client-" + consumer.id + "/chatStatus",
          function (responsePayload) {
            onStatusReceived(responsePayload);
          }
        );
      }
    } catch (err) {
      showErrorToast(t("conversations.something_went_wrong"));
    }
  };

  const onError = (error) => {
    consoleLog(error, "error");
    consoleLog(
      "Could not connect you to the Chat Room Server. Please refresh this page and try again!"
    );
  };

  const onMessageReceived = (payload) => {
    try {
      if (payload) {
        const notification = JSON.parse(payload.body);
        if (notification) {
          setConversationData((prevConversationData) => {
            let updatedConversationDataArray = [];
            let updatedConversationData = _.find(prevConversationData, {
              availableAgent: { channelId: notification.channelId },
            });
            if (updatedConversationData) {
              updatedConversationData.availableAgent.lastMessage =
                notification.message;
              updatedConversationData.availableAgent.messageCreatedDate =
                notification.createdDate;
              updatedConversationData.availableAgent.messageUnreadCount =
                typeof updatedConversationData.availableAgent
                  .messageUnreadCount !== "undefined"
                  ? updatedConversationData.availableAgent.messageUnreadCount +
                    1
                  : 1;
            }
            updatedConversationDataArray.push(updatedConversationData);
            _.remove(prevConversationData, {
              availableAgent: { channelId: notification.channelId },
            });
            return [...updatedConversationDataArray, ...prevConversationData];
          });
        }
      }
    } catch (err) {
      showErrorToast(t("conversations.something_went_wrong"));
    }
  };

  useEffect(() => {
    getConversationList();
    connect();
  }, []);

  useEffect(() => {
    return () => {
      if (stompClient && stompClient.connected) {
        stompClient.disconnect(function () {
          consoleLog("Disconnected. See you next time!");
        });
      }
    };
  }, []);

  const handleModel = (name, isHide) => {
    setFormData({ ...formData, [name]: isHide });
    if (isHide) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
      getConversationList();
    }
  };

  const onStatusReceived = (payload) => {
    try {
      if (payload) {
        const statusPayload = JSON.parse(payload.body);
        if (statusPayload) {
          setConversationData((prevConversationData) => {
            let updatedConversationDataArray = [];
            let updatedConversationData = _.filter(
              prevConversationData,
              (conversationList) => {
                return (
                  conversationList.availableAgent?.agentId ==
                  statusPayload.selfId
                );
              }
            );

            updatedConversationData.map((conversations) => {
              if (typeof conversations !== "undefined") {
                if (conversations) {
                  conversations.availableAgent.status = statusPayload.status;
                }
                updatedConversationDataArray.push(conversations);
                _.remove(prevConversationData, {
                  availableAgent: {
                    channelId: conversations.availableAgent.channelId,
                  },
                });
              }
            });
            const newConversationList = [
              ...updatedConversationDataArray,
              ...prevConversationData,
            ];
            newConversationList.sort(
              (a, b) =>
                b.availableAgent?.messageCreatedDate -
                a.availableAgent?.messageCreatedDate
            );
            newConversationList.sort(
              (a, b) =>
                b.availableAgent?.messageCreatedDate -
                a.availableAgent?.channelCreatedDate
            );
            return newConversationList;
          });
        }
      }
    } catch (err) {}
  };

  const { chatModel, isLoading } = formData;
  return (
    <React.Fragment>
      <div className="row">
        <div className="col-3 sidebar-part">
          <NavBar t={t}></NavBar>
        </div>
        <div className="col-lg-9">
          <Animated
            animationIn="fadeIn"
            animationOut="faderOutLeft"
            animationInDuration={1200}
            animationOutDuration={800}
            isVisible={true}
          >
            <div className="conversation-page lead-conver">
              <div className="conversation-list">
                <div className="conversation-list-header">
                  <h3>{t("conversations.conversations")}</h3>
                </div>
                <div className="conversation-list-outer">
                  {isLoading ? (
                    <UnderProcessLoader />
                  ) : typeof conversationData !== undefined &&
                    conversationData.length ? (
                    conversationData.map((conversations, key) => {
                      return (
                        <div
                          key={key}
                          className={`conversation-person ${
                            conversations.availableAgent.messageUnreadCount !==
                            null
                              ? "unread"
                              : ""
                          }`}
                          onClick={() => handleClickEvent(conversations)}
                        >
                          <div className="conversation-person-img">
                            <img
                              src={
                                conversations.availableAgent.profilePic
                                  ? conversations.availableAgent.profilePic
                                  : "/assets/images/user-dp-demo.jpg"
                              }
                              alt=""
                              title=""
                            />

                            {conversations.availableAgent.status === "ON" ? (
                              <span className="status-online"></span>
                            ) : (
                              <span className="status-offline"></span>
                            )}
                          </div>
                          <div className="conversation-person-details">
                            <h4 className="mb-0">
                              {" "}
                              {decryptme(
                                conversations.availableAgent.firstName
                              )}{" "}
                              {decryptme(conversations.availableAgent.lastName)}{" "}
                              <span className="msg-text">
                                {conversations.availableAgent.lastMessage}
                              </span>
                            </h4>
                            <div className="policy-details">
                              <h3>
                                {conversations.productName &&
                                  conversations.productName.replace(
                                    "15",
                                    conversations.pay
                                  )}
                              </h3>
                              <div className="chips-outer">
                                <span className="chips-label">
                                  {conversations.carrierName}
                                </span>
                              </div>
                            </div>
                            {typeof conversations.availableAgent
                              .messageUnreadCount !== "undefined" &&
                              conversations.availableAgent
                                .messageUnreadCount !== null &&
                              conversations.availableAgent
                                .messageUnreadCount !== 0 && (
                                <span className="msg-counter">
                                  {
                                    conversations.availableAgent
                                      .messageUnreadCount
                                  }
                                </span>
                              )}
                            <div className="conver-time">
                              {conversations.availableAgent
                                .messageCreatedDate !== null &&
                              conversations.availableAgent
                                .messageCreatedDate !== undefined ? (
                                <span>
                                  {moment(
                                    conversations.availableAgent
                                      .messageCreatedDate
                                  ).format("MM/DD/YYYY")}{" "}
                                  {moment(
                                    conversations.availableAgent
                                      .messageCreatedDate
                                  ).format("hh:mm a")}
                                </span>
                              ) : conversations.availableAgent
                                  .channelCreatedDate !== null &&
                                conversations.availableAgent
                                  .channelCreatedDate !== undefined ? (
                                <span>
                                  {moment(
                                    conversations.availableAgent
                                      .channelCreatedDate
                                  ).format("MM/DD/YYYY")}{" "}
                                  {moment(
                                    conversations.availableAgent
                                      .channelCreatedDate
                                  ).format("hh:mm a")}
                                </span>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div className="text-center pt-3">
                      {" "}
                      {t("conversations.there_are_no_records_to_display")}{" "}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Animated>
        </div>
      </div>
      <div
        className={
          chatModel === false
            ? "conversation-popup"
            : "conversation-popup active"
        }
      >
        {chatModel && (
          <PrivateChat
            conversationDetails={formData}
            applicationId={formData.application_Id}
            handleModel={handleModel}
            chatModel={chatModel}
            t={t}
          />
        )}
      </div>
      <div
        className={chatModel === false ? "conv-overlay" : "conv-overlay active"}
      ></div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  consumer: state.consumer.consumerdetails,
});

export default connect(mapStateToProps, { commonApiCall })(
  withRouter(Conversations)
);
