import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

export const DocumentLoader = () => {
  return (
    <div className="skeleton-loader">
      <SkeletonTheme>
        <div className="row">
          <div className="col-md-4 col-sm-6">
            <div className="mb-4">
              <Skeleton count={1} height={140} />
            </div>
          </div>
          <div className="col-md-4 col-sm-6">
            <div className="mb-4">
              <Skeleton count={1} height={140} />
            </div>
          </div>
          <div className="col-md-4 col-sm-6">
            <div className="mb-4">
              <Skeleton count={1} height={140} />
            </div>
          </div>
        </div>
      </SkeletonTheme>
    </div>
  );
};
