import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import SBLIBeneficiary from "../../components/sbli-beneficiary";
import SbliCoverage from "./sbli-coverage";
import SbliDocuments from "./sbli-documents";
import SBLIForm from "./sbli-form";
import SbliPayment from "./sbli-payment";
import SBLITerms from "./sbli-terms";
import SBLIUserDetail from "./sbli-user-detail";
import ThirdPartyNotification from "./third-party-notification";

const SBLILayout = ({ t, progressBar, progressCount, state, ...props }) => {
  const [progresbar, setProgressbar] = useState(0);
  const [progresCount, setProgresCount] = useState(0);
  const screen = window.location.pathname.split("/")[1];

  useEffect(() => {
    setProgresCount(progressCount);
  }, [progressCount]);

  useEffect(() => {
    setProgressbar(progressBar + 5);
  }, [progressBar]);

  const showSbliScreen = () => {
    switch (screen) {
      case "sbli-page":
        return <SBLIForm />;

      case "sbli-user-detail":
        return <SBLIUserDetail t={t} />;

      case "sbli-terms":
        return <SBLITerms />;

      case "beneficiary":
        return <SBLIBeneficiary t={t} />;

      case "sbli-third-party":
        return <ThirdPartyNotification t={t} />;
      case "sbli-coverage":
        return <SbliCoverage t={t} />;
      case "sbli-payment":
        return <SbliPayment t={t} />;
      case "sbli-document":
        return <SbliDocuments t={t} />;
    }
  };

  return (
    <div className="sbli-main-layout">
      <div id="myProgress">
        <div
          id="myBar"
          style={{
            width: `${Math.round((100 * progresCount) / progresbar)}%`,
          }}
        ></div>
      </div>
      <div className="sbli-main-container">{showSbliScreen()}</div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  state: state,
  progressCount: state.underwriting.progressCount,
  progressBar: state.underwriting.progressBar,
});
export default connect(mapStateToProps, {})(withRouter(SBLILayout));
