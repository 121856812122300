import React, { useEffect, useState } from "react";
import { Animated } from "react-animated-css";

import Switch from "react-switch";
import NavBar from "./navBar";
import { commonApiCall } from "../redux/actions/common";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { showErrorToast, showSuccessToast } from "../utils";
import { API_URL_CLIENT } from "../config/configs";
import { getJwt } from "../redux/actions/auth";

const Settings = ({ commonApiCall }) => {
  const { t } = useTranslation();

  const [notificationStatus, setNotificationStatus] = useState({
    email: false,
    mobile: false,
  });

  const changeNotificationStatus = async (status, type, platform) => {
    let body = {};
    if (platform === "email") {
      body = {
        eventKey: type,
        status: status,
      };
    }
    if (platform === "mobile") {
      body = {
        eventKey: type,
        mobileStatus: status,
      };
    }
    try {
      const isAuthorized = getJwt() ? true : false;
      let response = await commonApiCall(
        API_URL_CLIENT + "notification",
        "post",
        body,
        "",
        isAuthorized
      );
      if (response.status.code === 200) {
        showSuccessToast(response.status.message);
        setNotificationStatus({
          email: response.data.status,
          mobile: response.data.mobileStatus,
        });
      } else {
        showErrorToast(response.status.message);
      }
    } catch (err) {
      showErrorToast("something went wrong");
    }
  };

  const getNotificationStatus = async () => {
    let response = await commonApiCall(
      API_URL_CLIENT + "notification",
      "get",
      {},
      "",
      true
    );

    if (response.status.code === 200) {
      setNotificationStatus({
        ...notificationStatus,
        email: response.data[0].status,
        mobile: response.data[0].mobileStatus,
      });
    }
  };

  useEffect(() => {
    getNotificationStatus();
  }, []);

  return (
    <div className="row">
      <div className="col-3 sidebar-part">
        <NavBar t={t}></NavBar>
      </div>
      <div className="col-lg-9">
        <Animated
          animationIn="fadeIn"
          animationOut="faderOutLeft"
          animationInDuration={1200}
          animationOutDuration={800}
          isVisible={true}
        >
          <div className="white-box2">
            <h3 className="font-24 mb-5">{t("setting.settings")}</h3>

            <div className="box-title mb-3">
              <h3 className="font-24">{t("setting.email_notification")}</h3>
            </div>
            <div className="d-flex align-items-center justify-content-between mb-5">
              Send me an email notification when my agent sends me a message
              <Switch
                height={24}
                width={48}
                onChange={(checked) =>
                  changeNotificationStatus(
                    checked,
                    "agent_sends_message",
                    "email"
                  )
                }
                checked={notificationStatus.email}
                handleDiameter={18}
                uncheckedIcon={
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                      fontSize: 16,
                      color: "#fff",
                      paddingRight: 2,
                      fontWeight: "500",
                    }}
                  />
                }
                checkedIcon={
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                      fontSize: 16,
                      color: "#fff",
                      paddingRight: 2,
                      fontWeight: "500",
                    }}
                  />
                }
              />
            </div>
            <div className="box-title mb-3">
              <h3 className="font-24">{t("setting.mobile_notification")}</h3>
            </div>
            <div className="d-flex align-items-center justify-content-between mb-3">
              Send me a text notification when my agent sends me a message
              <Switch
                height={24}
                width={48}
                onChange={(checked) =>
                  changeNotificationStatus(
                    checked,
                    "agent_sends_message",
                    "mobile"
                  )
                }
                checked={notificationStatus.mobile}
                handleDiameter={18}
                uncheckedIcon={
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                      fontSize: 16,
                      color: "#fff",
                      paddingRight: 2,
                      fontWeight: "500",
                    }}
                  />
                }
                checkedIcon={
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                      fontSize: 16,
                      color: "#fff",
                      paddingRight: 2,
                      fontWeight: "500",
                    }}
                  />
                }
              />
            </div>
          </div>
        </Animated>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  consumer: state.consumer.consumerdetails,
});
export default connect(mapStateToProps, { commonApiCall })(Settings);
