import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Select from "react-select";
import NumberFormat from "react-number-format";
import { Button } from "react-bootstrap";
import DatePicker from "react-datepicker";
// import { Portal } from "react-overlays";
import { isEmpty } from "lodash";
const SearchBar = ({
  searchData,
  goalOptions,
  policyOptions,
  insureBasedOptions,
  frequencyOptions,
  slideLabels,
  genderOptions,
  healthOptions,
  smokeOptions,
  handleChange,
  handleBlur,
  termLengthOptions,
  company_information,
  direct_link,
  doSubmit,
  onTermChange,
  t,
  applyLoading,
}) => {
  const whichAmount =
    searchData.yourAmount > slideLabels.max
      ? slideLabels.defaultValue
      : searchData.yourAmount;
  const {
    yourGoalType,
    termLength,
    yourPolicyType,
    yourInsureBasedOn,
    yourFrequency,
    yourGender,
    yourDOB,
    yourHealthRating,
    isSmoker,
    // openFilterOption,
  } = searchData;
  const [maxDateOfCalendar, setMaxDateOfCalendar] = useState(null);
  const [minDateOfCalendar, setMinDateOfCalendar] = useState(null);
  const [goalTypes, setGoalTypes] = useState([]);

  const maxDate = () => {
    let date = new Date();
    // if( yourGoalType.value === "INSURE" &&  yourPolicyType.value==="FINAL_EXPENSE_INSURANCE"){
    //   date.setFullYear(date.getFullYear() - 45 );
    //   setMaxDateOfCalendar(date)
    // }
    if (
      yourGoalType.value === "INSURE" &&
      yourPolicyType.value === "TERM_LIFE_INSURANCE"
    ) {
      date.setFullYear(date.getFullYear() - 18);
      setMaxDateOfCalendar(date);
    }
    if (yourGoalType.value === "SAVE") {
      date.setFullYear(date.getFullYear() - 15);
      setMaxDateOfCalendar(date);
    } else {
      setMaxDateOfCalendar(date);
    }
  };
  const minDate = () => {
    let date = new Date();
    if (
      yourGoalType.value === "INSURE" &&
      yourPolicyType.value === "FINAL_EXPENSE_INSURANCE"
    ) {
      date.setFullYear(date.getFullYear() - 85);
      setMinDateOfCalendar(date);
    } else {
      date.setFullYear(date.getFullYear() - 91);
      setMinDateOfCalendar(date);
    }
  };
  useEffect(() => {
    maxDate();
    minDate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleChange]);

  useEffect(() => {
    if (
      !isEmpty(company_information) &&
      company_information?.defaultPage === "investment"
    ) {
      let goalTypes = goalOptions.filter((option) => {
        return option.value !== "INSURE";
      });
      setGoalTypes(goalTypes);
    } else {
      let tempGoalType = [];
      tempGoalType =
        !isEmpty(company_information) &&
        goalOptions.filter(
          (x) => company_information?.carrierCategory[x.key] === true
        );

      if (
        !isEmpty(company_information) &&
        (company_information?.carrierCategory?.term ||
          company_information?.carrierCategory?.permanent ||
          company_information?.carrierCategory?.finalExpense)
      )
        tempGoalType.push({ value: "INSURE", label: "Insurance" });
      setGoalTypes(tempGoalType);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company_information]);

  return (
    <React.Fragment>
      <div className="filter-block">
        <ul className="filter-wrapper">
          {company_information?.defaultPage === "insurance" ||
          direct_link === "/insurance" ||
          company_information?.defaultPage === "final_expense" ||
          company_information?.defaultPage === "term" ||
          company_information?.defaultPage === "permanent" ||
          direct_link === "/final-expense" ||
          direct_link === "/term" ||
          direct_link === "/dividend" ||
          direct_link === "/index" ||
          direct_link === "/permanent" ? null : (
            <li className="filter-item">
              <span className="filter-item-lable">
                {t("plan_listing.financial_preferences")}
              </span>
              <div className="default-select select-goal-dropdown">
                <Select
                  value={yourGoalType}
                  defaultValue={{ value: "SAVE", label: "Dividend based" }}
                  options={goalTypes}
                  onChange={(e) =>
                    handleChange({
                      target: { name: "yourGoalType", value: e },
                    })
                  }
                  className={"custom-select-menu"}
                  classNamePrefix={"custom-select"}
                  isDisabled={
                    company_information?.defaultPage === "insurance" ||
                    direct_link === "/insurance" ||
                    company_information?.defaultPage === "final_expense" ||
                    company_information?.defaultPage === "term" ||
                    company_information?.defaultPage === "permanent" ||
                    direct_link === "/final-expense" ||
                    direct_link === "/term" ||
                    direct_link === "/permanent"
                      ? true
                      : false
                  }
                />
              </div>
            </li>
          )}
          {yourGoalType.value === "INSURE" && (
            <React.Fragment>
              {company_information?.defaultPage === "final_expense" ||
              company_information?.defaultPage === "term" ||
              company_information?.defaultPage === "permanent" ||
              direct_link === "/final-expense" ||
              direct_link === "/term" ||
              direct_link === "/permanent" ? null : (
                <li className="filter-item">
                  <span className="filter-item-lable">
                    {t("plan_listing.type")}
                  </span>
                  <div className="default-select select-Duration-dropdown">
                    <Select
                      value={yourPolicyType}
                      defaultValue={{
                        value: "TERM_LIFE_INSURANCE",
                        label: "Term",
                      }}
                      onChange={(e) =>
                        handleChange({
                          target: { name: "yourPolicyType", value: e },
                        })
                      }
                      options={
                        !isEmpty(company_information) &&
                        policyOptions.filter(
                          (x) =>
                            company_information?.carrierCategory[x.key] === true
                        )
                      }
                      className={"custom-select-menu"}
                      classNamePrefix={"custom-select"}
                      isDisabled={
                        company_information?.defaultPage === "final_expense" ||
                        company_information?.defaultPage === "term" ||
                        company_information?.defaultPage === "permanent" ||
                        direct_link === "/final-expense" ||
                        direct_link === "/term" ||
                        direct_link === "/permanent"
                          ? true
                          : false
                      }
                    />
                  </div>
                </li>
              )}

              {yourPolicyType.value !== "PERMANENT_LIFE_INSURANCE" &&
                yourPolicyType.value !== "TERM_LIFE_INSURANCE" &&
                yourPolicyType.value !== "FINAL_EXPENSE_INSURANCE" && (
                  <li className="filter-item">
                    <span className="filter-item-lable">
                      {t("plan_listing.based_on")}
                    </span>
                    <div className="default-select">
                      <Select
                        value={yourInsureBasedOn}
                        defaultValue={{ value: "COVERAGE", label: "Coverage" }}
                        onChange={(e) =>
                          handleChange({
                            target: {
                              name: "yourInsureBasedOn",
                              value: e,
                            },
                          })
                        }
                        options={insureBasedOptions}
                        className={"custom-select-menu"}
                        classNamePrefix={"custom-select"}
                      />
                    </div>
                  </li>
                )}
            </React.Fragment>
          )}

          <React.Fragment>
            {yourGoalType.value !== "INSURE" && (
              <li className="filter-item">
                <span className="filter-item-lable">
                  {t("plan_listing.frequency")}
                </span>
                <div className="default-select">
                  <Select
                    value={yourFrequency}
                    defaultValue={{ value: "MONTHLY", label: "Monthly" }}
                    options={frequencyOptions}
                    onChange={(e) =>
                      handleChange({
                        target: {
                          name: "yourFrequency",
                          value: e,
                        },
                      })
                    }
                    className={"custom-select-menu"}
                    classNamePrefix={"custom-select"}
                  />
                </div>
              </li>
            )}

            <li className="filter-item">
              <span className="filter-item-lable">
                {yourGoalType.value === "INSURE" &&
                yourInsureBasedOn.value === "COVERAGE"
                  ? t("plan_listing.coverage")
                  : t("plan_listing.amount")}
              </span>
              <div className="input-effect">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="basic-addon1">
                      $
                    </span>
                  </div>
                  <NumberFormat
                    thousandSeparator={true}
                    name="yourAmount"
                    id="yourAmount"
                    className="form-control"
                    inputMode="numeric"
                    allowNegative={false}
                    value={whichAmount}
                    isAllowed={(values) => {
                      const { formattedValue, floatValue } = values;
                      return (
                        formattedValue === "" || floatValue <= slideLabels.max
                      );
                    }}
                    onValueChange={(e) =>
                      handleChange({
                        target: { name: "yourAmount", value: e.value },
                      })
                    }
                    onBlur={(e) =>
                      handleBlur({
                        target: { name: "yourAmount", value: e.value },
                      })
                    }
                  />
                </div>
              </div>
            </li>
            {yourGoalType.value === "INSURE" &&
              yourPolicyType.value === "TERM_LIFE_INSURANCE" && (
                <React.Fragment>
                  <li className="filter-item">
                    <span className="filter-item-lable">
                      {t("plan_listing.term_length")}
                    </span>
                    <div className="default-select term-lenth">
                      <Select
                        value={termLength}
                        defaultValue={{ value: 15, label: "15 Years" }}
                        options={termLengthOptions}
                        onChange={(e) => {
                          onTermChange(true);
                          handleChange({
                            target: {
                              name: "termLength",
                              value: e,
                            },
                          });
                        }}
                        className={"custom-select-menu"}
                        classNamePrefix={"custom-select"}
                      />
                    </div>
                  </li>
                </React.Fragment>
              )}
            <li className="filter-item">
              <span className="filter-item-lable">
                {t("plan_listing.gender")}
              </span>
              <div className="default-select">
                <Select
                  value={yourGender}
                  onChange={(e) =>
                    handleChange({
                      target: {
                        name: "yourGender",
                        value: e,
                      },
                    })
                  }
                  options={genderOptions}
                  className={"custom-select-menu"}
                  classNamePrefix={"custom-select"}
                />
              </div>
            </li>
            <li className="filter-item">
              <span className="filter-item-lable">
                {t("plan_listing.date_of_birth")}
              </span>{" "}
              <div className="input-effect">
                <div className="form-group input-group custom-datepicker mb-0">
                  <DatePicker
                    selected={yourDOB}
                    onChange={(e) =>
                      handleChange({
                        target: {
                          name: "yourDOB",
                          value: e,
                        },
                      })
                    }
                    // maxDate={new Date()}
                    maxDate={maxDateOfCalendar}
                    minDate={minDateOfCalendar}
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    dateFormatCalendar="MMMM"
                    popperPlacement="top-start"
                  />
                </div>
              </div>
            </li>
            <li className="filter-item">
              <span className="filter-item-lable">
                {t("plan_listing.health")}
              </span>
              <div className="default-select">
                <Select
                  value={yourHealthRating}
                  defaultValue={{
                    value: "EXCELLENT",
                    label: "Excellent",
                  }}
                  onChange={(e) =>
                    handleChange({
                      target: {
                        name: "yourHealthRating",
                        value: e,
                      },
                    })
                  }
                  options={healthOptions}
                  className={"custom-select-menu"}
                  classNamePrefix={"custom-select"}
                />
              </div>
            </li>
            <li className="filter-item">
              <span className="filter-item-lable">
                {t("plan_listing.smoke")}
              </span>
              <div className="default-select">
                <Select
                  value={isSmoker}
                  defaultValue={{ value: true, label: "Yes" }}
                  onChange={(e) =>
                    handleChange({
                      target: {
                        name: "isSmoker",
                        value: e,
                      },
                    })
                  }
                  options={smokeOptions}
                  className={"custom-select-menu"}
                  classNamePrefix={"custom-select"}
                />
              </div>
            </li>
          </React.Fragment>
        </ul>
      </div>

      <div className="filter-block filter-block3">
        <ul className="filter-wrapper ">
          <li className="filter-item filter-btn-outer">
            {/* <Button
              type="btn"
              variant="secondary"
              className="filter-btn"
              onClick={(e) =>
                handleChange({
                  target: {
                    name: "openFilterOption",
                    value: !openFilterOption,
                  },
                })
              }
              >
              {!openFilterOption
                ? t("plan_listing.more_filters")
                : t("plan_listing.hide_filters")}
              </Button> */}
            <Button
              disabled={applyLoading}
              type="btn"
              variant="primary"
              className="filter-btn "
              onClick={() => doSubmit()}
            >
              {t("plan_listing.apply_filters")}
            </Button>
          </li>
        </ul>
      </div>
    </React.Fragment>
  );
};
const mapStateToProps = (state) => ({
  company_information: state.underwriting.companyInformation,
  direct_link: state.underwriting.direct_link,
});
export default connect(mapStateToProps, null)(SearchBar);
