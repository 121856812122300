import React from "react";
import { Modal } from "react-bootstrap";

export const AgreementModel = ({ agreementModel, handleModel, t }) => {
  return (
    <Modal
      className="pdf-modal"
      show={agreementModel}
      onHide={() => handleModel("agreementModel", false)}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>{t()}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <iframe
          title="PDF Viewer"
          src="/assets/agreement/iLife - Terms of Service - Privacy Policy.pdf"
          width="100%"
          height="600px"
        ></iframe>
      </Modal.Body>
    </Modal>
  );
};
