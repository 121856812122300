import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";

export const IllustrationModel = ({
  illustrationsModel,
  illustrationPdf,
  handleModel,
  t,
}) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  function changePage(offset) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  return (
    <Modal
      className={"illustrationModel"}
      show={illustrationsModel}
      size={"sm"}
      onHide={() => handleModel("illustrationsModel", false)}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>{t("plan_listing.view_illustration")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div style={{ height: 400, width: "100%", overflow: "auto" }}>
          {/* <embed
            // title="PDF Viewer"
            src={illustrationPdf}
            width="100%"
            height="3000px"
            type="application/pdf"
          ></embed> */}

          <div style={{ height: 500, width: "100%", overflow: "auto" }}>
            <DocViewer
              style={{
                height: 450,
              }}
              config={{
                header: {
                  disableFileName: true,
                  disableHeader: true,
                },
              }}
              pluginRenderers={DocViewerRenderers}
              documents={[{ uri: illustrationPdf }]}
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div>
          <p className="text-right">
            Page {pageNumber || (numPages ? 1 : "--")} of {numPages || "--"}
          </p>
          <button
            type="button"
            className="btn btn-default mr-3"
            disabled={pageNumber <= 1}
            onClick={previousPage}
          >
            Previous
          </button>
          <button
            type="button"
            className="btn btn-default"
            disabled={pageNumber >= numPages}
            onClick={nextPage}
          >
            Next
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};
