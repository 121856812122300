import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

const AccessTokenForDomain = (props) => {
  const location = useLocation();
  const checkDomain = new URLSearchParams(location.search).get("domain");
  const env = new URLSearchParams(location.search).get("env");
  const page = new URLSearchParams(location.search).get("page");
  const origin =
    env === "staging"
      ? "https://client.ilifestaging.tech"
      : "https://client.ilife.tech";

  useEffect(() => {
    if (checkDomain) {
      localStorage.setItem("assurityDomain", decodeURIComponent(checkDomain));
      window.location.href = `https://account-d.docusign.com/oauth/auth?response_type=token&scope=signature&client_id=e1631711-4f38-4ca4-98c5-e399f204ab58&redirect_uri=${origin}/assurity-${page}`;
    }
  }, []);
  return (
    <div className="assurity">
      <div className="assurity-loader text-center mt-5">
        <img src="/assets/images/iLifeLoader.gif" />
      </div>
    </div>
  );
};

export default AccessTokenForDomain;
