import React, { useState, useCallback } from "react";
import { withRouter } from "react-router-dom";
import ReactCrop from "react-image-crop";
import { Modal, Button } from "react-bootstrap";
import { connect } from "react-redux";
import { commonApiCall, profilePicture } from "../../redux/actions/index";
import { showErrorToast, showSuccessToast } from "../../utils";
import { API_URL_CLIENT } from "../../config/configs";

const ImageCrop = ({ commonApiCall, profilePicture, t, ...props }) => {
  const { email, firstName, setProfilePic } = props;
  const [upImg, setUpImg] = useState();
  const [imgRef, setImgRef] = useState(null);
  const [crop, setCrop] = useState({
    unit: "%",
    width: 30,
    aspect: 1,
  });
  const [previewUrl, setPreviewUrl] = useState();
  const [imageUrl, setImageUrl] = useState("");
  const [isModelOpen, setisModelOpen] = useState(false);

  const onSelectFile = (e) => {
    setisModelOpen(true);
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () => setUpImg(reader.result));
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const onLoad = useCallback((img) => {
    setImgRef(img);
  }, []);

  const makeClientCrop = async (crop) => {
    if (imgRef && crop.width && crop.height) {
      createCropPreview(imgRef, crop, "newFile.jpeg");
    }
  };

  const createCropPreview = async (image, crop, fileName) => {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        let fileUrl = "";
        if (!blob) {
          reject(new Error("Canvas is empty"));
          return;
        }
        blob.name = fileName;
        window.URL.revokeObjectURL(previewUrl);
        setPreviewUrl(window.URL.createObjectURL(blob));
        fileUrl = canvas.toDataURL("image/jpeg");
        setImageUrl(fileUrl);
        resolve(fileUrl);
      }, "image/jpeg");
    });
  };

  const fileUpload = async () => {
    let formData1 = new FormData();
    formData1.append("imageString", imageUrl);
    let authorization = true;
    const response = await commonApiCall(
      API_URL_CLIENT + "uploadImageFile?uploadedImageType=PROFILE",
      "post",
      formData1,
      "",
      authorization
    );
    if (response && response.status.code === 200) {
      setProfilePic(response.data.fileDownloadUri);
      let reduxStore = {
        profilePic: response.data.fileDownloadUri,
        email: email,
        firstName: firstName,
      };
      showSuccessToast("Profile image updated successfully");
      await profilePicture(reduxStore);
      setisModelOpen(false);
    } else {
      showErrorToast(response.status.message);
    }
  };

  return (
    <React.Fragment>
      <span className="material-icons profile-cam">photo_camera</span>
      <input
        className="profile-input"
        type="file"
        style={{ display: "block" }}
        name="image"
        id="file"
        accept="image/*"
        onChange={(e) => onSelectFile(e)}
        title=" "
      />
      <Modal
        className="dragdrop-modal"
        centered
        show={isModelOpen}
        onHide={() => setisModelOpen(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {t("my_profile.image_crop.set_carrier_image")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="crop-image">
          <div className="row">
            <div className="col-sm-6">
              <label className="crop-lable">
                {t("my_profile.image_crop.crop")}
              </label>
              <ReactCrop
                src={upImg}
                onImageLoaded={onLoad}
                crop={crop}
                onChange={(c) => setCrop(c)}
                onComplete={makeClientCrop}
              />
            </div>
            <div className="col-sm-6">
              <label className="crop-lable">
                {t("my_profile.image_crop.preview")}
              </label>
              {previewUrl && (
                <img alt="Crop preview" className="crp-img" src={previewUrl} />
              )}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex justify-content-end">
            <Button className="btn-lg" onClick={() => fileUpload()}>
              {t("my_profile.image_crop.set_image")}
            </Button>
            <Button
              onClick={() => setisModelOpen(false)}
              className="btn-lg ml-3 primary-outline"
            >
              {t("my_profile.image_crop.cancel")}
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

export default connect(null, {
  commonApiCall,
  profilePicture,
})(withRouter(ImageCrop));
