export const validationMessages = {
  //Manual Application Form
  licence: `Enter Driver's License Number`,
  fName: "Enter First Name",
  lName: "Enter Last Name",
  gender: "Select Gender",
  email: "Enter Email",
  validEmail: "Enter a valid Email",
  dob: "Select Date of Birth",
  heightFt: "Enter Height feet",
  heightIn: "Enter Height inch",
  weight: "Enter Weight lb",
  street: "Enter Street Address",
  address2: "Enter Address Line 2",
  city: "Enter City",
  state: "Select State",
  PIN: "Enter Postal/Zip Code",
  validPIN: "Enter valid Postal/Zip Code",
  SSN: "Enter Social Security Number",
  validSSN: "Enter 9 digits Social Security Number",
  mobile: "Enter Mobile ",
  validMobile: "Enter 10 digits Mobile Number",
  emailExists: "Email Already Exists.",
  mobileExists: "Mobile Already Exists.",

  //otp
  otp: "Enter valid login code.",
  validOtp: "Enter 6 digits login code.",
  successOtp: "The login code has sent successfully.",
  varifiedOtp: "The login code verified successfully.",

  // My Profile
  profileUpdate: "Profile updated successfully",

  //fact finder

  answers: "Please provide answers",

  PROVIDE_EMAIL: "Please enter email",
  PROVIDE_VALID_EMAIL: "Please enter valid email format",
  PROVIDE_NAME: "Please enter valid name",
  PROVIDE_MESSAGE: "Please enter valid message",
  PROVIDE_FIRST_NAME: "Please enter first name",
  PROVIDE_VALID_FIRST_NAME: "Please enter valid first name",
  PROVIDE_LAST_NAME: "Please enter last name",
  PROVIDE_VALID_LAST_NAME: "Please enter valid last name",

  PROVIDE_MOBILE_NUMBER: "Please enter mobile number",
  PROVIDE_VALID_MOBILE_NUMBER: "Please enter valid mobile number",
  PROVIDE_VERIFICATIONCODE_NUMBER: "Please enter verification code",
  PROVIDE_VALID_VERIFICATIONCODE_NUMBER:
    "Please enter valid 6 digit verification code",
  PROVIDE_DOB: "Please enter date of birth",
  PROVIDE_STREET_ADDRESS: "Please enter street address",
  PROVIDE_ADDRESSLINE_2: "Please enter address line 2",
  PROVIDE_REGISTERED_EMAIL: "Please enter registered email",
  PROVIDE_CITY: "Please enter city",
  PROVIDE_STATE: "Please select state",
  PROVIDE_POSTAL_CODE: "Please enter postal/zip code",
  SELECT_ISAGREE:
    "Please agree to the iLife Terms of Service and Privacy Policy",

  PROVIDE_COMPANY_NAME: "Please enter company name",

  PROVIDE_LICENSE_NUMBER: "Please enter license number",
  PROVIDE_CARRIERSID: "Please select carriers ",
  PROVIDE_AGENT_CODE: "Please enter agent code ",
  PROVIDE_TIN_NUMBER: "Please enter TIN number",
  SOMETHING_WENT_WRONG: "Something went wrong",
  title: "Please enter title",
  COMMENT: "Please enter comment",
  HOWLONG_YEARS: "Please enter years",
  HOWLONG_MONTHS: "Please enter months",
  VALID_HOWLONG_MONTHS: "Please enter months from 0-11",
  DRIVING_LICENCESTATE: "Please select driving",
  DOCUMENT_NAME: "Please enter document name",
  DOCUMENT_NAME_LENGTH: "Maximum 50 characters allowed",
  DOCUMENT_FILE: "Please select file",
  VALID_ATTACHMENT:
    "Only pdf, doc, jpg and png files are allowed as attachments",
  REVIEW_SUCCESS: "Review & Ratings added successfully",
  SESSTION_EXPIRED: "Your session has expired. Please log in again.",
  INTERNAL_SERVER_ERROR:
    "System is experiencing issues. Please come back later.",
};
