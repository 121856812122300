import React from "react";
import { withRouter, Link } from "react-router-dom";
import { Popover, OverlayTrigger } from "react-bootstrap";
import { DashboardRoute, showErrorToast, truncate } from "../../utils/index";

const ChatRightbar = ({
  commonApiCall,
  conversationDetails,
  attachmentListing,
  t,
  ...props
}) => {
  const openAttachments = (url) => {
    try {
      window.open(url, "_blank");
    } catch (err) {
      showErrorToast(t('validation_message.SOMETHING_WENT_WRONG'));
    }
  };

  const getFileIcon = (filetype, fileName) => {
    let fileExe = filetype.split(".").pop();
    switch (fileExe) {
      case "pdf":
        return (
          <div className="pdf-info">
            <span className="material-icons">picture_as_pdf</span>
            <span className="pdf-name">{fileName}</span>
          </div>
        );
      case "png":
        return (
          <div className="pdf-info">
            <span className="svg-icon">
              <svg viewBox="0 0 1024 1024">
                <g>
                  <g>
                    <path
                      d="M146.901,249.004V964c0,33,27,60,60,60h613.857c33,0,60-27,60-60V60c0-33-27-60-60-60H395.906"
                      fill="#F1C40F"
                    />
                    <g>
                      <path
                        d="M146.901,249.004h189.005c33,0,60-27,60-60V0"
                        fill="#F39C12"
                      />
                    </g>
                  </g>
                </g>
                <g>
                  <path
                    d="M81.83,670.833h65.071V585l-70.946,88.375h0.012C77.167,671.805,79.198,670.833,81.83,670.833z"
                    fill="#262626"
                  />
                  <path
                    d="M945.83,670.833h-65.071V585l70.946,88.375h-0.012C950.493,671.805,948.462,670.833,945.83,670.833z"
                    fill="#262626"
                  />
                  <path
                    d="M884.273,861.471c-1.934,5.149-8.015,9.362-13.515,9.362H156.901c-5.5,0-11.582-4.213-13.514-9.362   L75.344,680.195c-1.933-5.149,0.986-9.362,6.486-9.362h864c5.5,0,8.419,4.213,6.486,9.362L884.273,861.471z"
                    fill="#F39C12"
                  />
                </g>
                <g>
                  <path
                    d="M312.407,818.323c0-5.013,1.484-8.881,4.454-11.606c2.97-2.724,7.287-4.087,12.955-4.087   c5.395,0,9.535,1.363,12.424,4.087c2.888,2.726,4.332,6.594,4.332,11.606c0,4.903-1.485,8.732-4.455,11.483   c-2.971,2.751-7.069,4.127-12.301,4.127c-5.395,0-9.645-1.362-12.75-4.086C313.96,827.123,312.407,823.282,312.407,818.323z"
                    fill="#FFFFFF"
                  />
                  <path
                    d="M453.397,750.402c0,13.351-3.938,23.662-11.811,30.937c-7.874,7.273-19.058,10.911-33.552,10.911h-9.072   v39.641h-32.284V712.396h41.356c15.093,0,26.427,3.297,34.001,9.89C449.609,728.88,453.397,738.252,453.397,750.402z    M398.963,765.932h5.885c4.85,0,8.705-1.361,11.565-4.087c2.86-2.724,4.291-6.483,4.291-11.279c0-8.063-4.469-12.097-13.404-12.097   h-8.337V765.932z"
                    fill="#FFFFFF"
                  />
                  <path
                    d="M586.214,831.891h-42.338l-43.646-84.186h-0.735c1.034,13.241,1.553,23.349,1.553,30.323v53.862h-28.606   V712.396h42.175l43.482,83.041h0.49c-0.764-12.042-1.145-21.713-1.145-29.016v-54.025h28.77V831.891z"
                    fill="#FFFFFF"
                  />
                  <path
                    d="M660.021,761.927h51.572v64.406c-14.004,4.795-29.396,7.192-46.178,7.192   c-18.418,0-32.652-5.34-42.707-16.02c-10.053-10.68-15.08-25.91-15.08-45.689c0-19.289,5.504-34.3,16.512-45.035   c11.006-10.733,26.426-16.102,46.26-16.102c7.52,0,14.617,0.709,21.293,2.125c6.674,1.418,12.49,3.216,17.449,5.395l-10.217,25.338   c-8.609-4.251-18.063-6.376-28.361-6.376c-9.428,0-16.715,3.065-21.863,9.195c-5.15,6.13-7.725,14.89-7.725,26.277   c0,11.171,2.33,19.685,6.988,25.542s11.375,8.786,20.148,8.786c4.793,0,9.207-0.462,13.24-1.39v-18.717h-21.332V761.927z"
                    fill="#FFFFFF"
                  />
                </g>
                <g>
                  <g>
                    <path
                      d="M729.983,369.821c0,8.25-9,15-20,15H314.852c-11,0-20-6.75-20-15s9-15,20-15h395.132    C720.983,354.821,729.983,361.571,729.983,369.821z"
                      fill="#FFFFFF"
                    />
                  </g>
                  <g>
                    <path
                      d="M729.148,459.821c0,8.25-9,15-20,15H314.017c-11,0-20-6.75-20-15s9-15,20-15h395.132    C720.148,444.821,729.148,451.571,729.148,459.821z"
                      fill="#FFFFFF"
                    />
                  </g>
                  <g>
                    <path
                      d="M729.983,550.016c0,8.25-9,15-20,15H314.852c-11,0-20-6.75-20-15s9-15,20-15h395.132    C720.983,535.016,729.983,541.766,729.983,550.016z"
                      fill="#FFFFFF"
                    />
                  </g>
                </g>
              </svg>
            </span>
            <span className="pdf-name">{fileName}</span>
          </div>
        );
      case "jpg":
        return (
          <div className="pdf-info">
            <span className="svg-icon">
              <svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="m392 488h-272a48 48 0 0 1 -48-48v-368a48 48 0 0 1 48-48h224l96 96v320a48 48 0 0 1 -48 48z"
                  fill="#cfd2fc"
                />
                <path
                  d="m72 360h368a0 0 0 0 1 0 0v80a48 48 0 0 1 -48 48h-272a48 48 0 0 1 -48-48v-80a0 0 0 0 1 0 0z"
                  fill="#5153ff"
                />
                <path d="m440 120h-48a48 48 0 0 1 -48-48v-48z" fill="#8690fa" />
                <path d="m152 136h208v160h-208z" fill="#fff" />
                <path d="m216 216 40 48 48-16 56 48h-208z" fill="#8690fa" />
                <circle cx="288" cy="200" fill="#5153ff" r="16" />
                <g fill="#fff">
                  <path d="m248 384h-16a8 8 0 0 0 -8 8v64a8 8 0 0 0 16 0v-24h8a24 24 0 0 0 0-48zm0 32h-8v-16h8a8 8 0 0 1 0 16z" />
                  <path d="m200 384a8 8 0 0 0 -8 8v48a8 8 0 0 1 -16 0 8 8 0 0 0 -16 0 24 24 0 0 0 48 0v-48a8 8 0 0 0 -8-8z" />
                  <path d="m344 416h-16a8 8 0 0 0 0 16h7.049c-2.252 9.217-8.236 16-15.049 16-8.673 0-16-10.991-16-24s7.327-24 16-24a10.71 10.71 0 0 1 4.589 1.057 8 8 0 0 0 6.822-14.473 26.6 26.6 0 0 0 -11.411-2.584c-17.645 0-32 17.944-32 40s14.355 40 32 40 32-17.944 32-40a8 8 0 0 0 -8-8z" />
                </g>
              </svg>
            </span>
            <span className="pdf-name">{fileName}</span>
          </div>
        );
      case "jpeg":
        return (
          <div className="pdf-info">
            <span className="svg-icon">
              <svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="m392 488h-272a48 48 0 0 1 -48-48v-368a48 48 0 0 1 48-48h224l96 96v320a48 48 0 0 1 -48 48z"
                  fill="#cfd2fc"
                />
                <path
                  d="m72 360h368a0 0 0 0 1 0 0v80a48 48 0 0 1 -48 48h-272a48 48 0 0 1 -48-48v-80a0 0 0 0 1 0 0z"
                  fill="#5153ff"
                />
                <path d="m440 120h-48a48 48 0 0 1 -48-48v-48z" fill="#8690fa" />
                <path d="m152 136h208v160h-208z" fill="#fff" />
                <path d="m216 216 40 48 48-16 56 48h-208z" fill="#8690fa" />
                <circle cx="288" cy="200" fill="#5153ff" r="16" />
                <g fill="#fff">
                  <path d="m248 384h-16a8 8 0 0 0 -8 8v64a8 8 0 0 0 16 0v-24h8a24 24 0 0 0 0-48zm0 32h-8v-16h8a8 8 0 0 1 0 16z" />
                  <path d="m200 384a8 8 0 0 0 -8 8v48a8 8 0 0 1 -16 0 8 8 0 0 0 -16 0 24 24 0 0 0 48 0v-48a8 8 0 0 0 -8-8z" />
                  <path d="m344 416h-16a8 8 0 0 0 0 16h7.049c-2.252 9.217-8.236 16-15.049 16-8.673 0-16-10.991-16-24s7.327-24 16-24a10.71 10.71 0 0 1 4.589 1.057 8 8 0 0 0 6.822-14.473 26.6 26.6 0 0 0 -11.411-2.584c-17.645 0-32 17.944-32 40s14.355 40 32 40 32-17.944 32-40a8 8 0 0 0 -8-8z" />
                </g>
              </svg>
            </span>
            <span className="pdf-name">{fileName}</span>
          </div>
        );
      case "xlsx":
        return (
          <div className="pdf-info">
            <span className="svg-icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 48 48"
                width="22px"
                height="22px"
              >
                <path
                  fill="#4CAF50"
                  d="M41,10H25v28h16c0.553,0,1-0.447,1-1V11C42,10.447,41.553,10,41,10z"
                />
                <path
                  fill="#FFF"
                  d="M32 15H39V18H32zM32 25H39V28H32zM32 30H39V33H32zM32 20H39V23H32zM25 15H30V18H25zM25 25H30V28H25zM25 30H30V33H25zM25 20H30V23H25z"
                />
                <path fill="#2E7D32" d="M27 42L6 38 6 10 27 6z" />
                <path
                  fill="#FFF"
                  d="M19.129,31l-2.411-4.561c-0.092-0.171-0.186-0.483-0.284-0.938h-0.037c-0.046,0.215-0.154,0.541-0.324,0.979L13.652,31H9.895l4.462-7.001L10.274,17h3.837l2.001,4.196c0.156,0.331,0.296,0.725,0.42,1.179h0.04c0.078-0.271,0.224-0.68,0.439-1.22L19.237,17h3.515l-4.199,6.939l4.316,7.059h-3.74V31z"
                />
              </svg>
            </span>
            <span className="pdf-name">{fileName}</span>
          </div>
        );
      case "docx":
        return (
          <div className="pdf-info">
            <span className="svg-icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 48 48"
                width="22px"
                height="22px"
              >
                <path
                  fill="#2196F3"
                  d="M41,10H25v28h16c0.553,0,1-0.447,1-1V11C42,10.447,41.553,10,41,10z"
                />
                <path
                  fill="#FFF"
                  d="M25 15.001H39V17H25zM25 19H39V21H25zM25 23.001H39V25.001H25zM25 27.001H39V29H25zM25 31H39V33.001H25z"
                />
                <path fill="#0D47A1" d="M27 42L6 38 6 10 27 6z" />
                <path
                  fill="#FFF"
                  d="M21.167,31.012H18.45l-1.802-8.988c-0.098-0.477-0.155-0.996-0.174-1.576h-0.032c-0.043,0.637-0.11,1.162-0.197,1.576l-1.85,8.988h-2.827l-2.86-14.014h2.675l1.536,9.328c0.062,0.404,0.111,0.938,0.143,1.607h0.042c0.019-0.498,0.098-1.051,0.223-1.645l1.97-9.291h2.622l1.785,9.404c0.062,0.348,0.119,0.846,0.17,1.511h0.031c0.02-0.515,0.073-1.035,0.16-1.563l1.503-9.352h2.468L21.167,31.012z"
                />
              </svg>
            </span>
            <span className="pdf-name">{fileName}</span>
          </div>
        );
      case "doc":
        return (
          <div className="pdf-info">
            <span className="svg-icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 48 48"
                width="22px"
                height="22px"
              >
                <path
                  fill="#2196F3"
                  d="M41,10H25v28h16c0.553,0,1-0.447,1-1V11C42,10.447,41.553,10,41,10z"
                />
                <path
                  fill="#FFF"
                  d="M25 15.001H39V17H25zM25 19H39V21H25zM25 23.001H39V25.001H25zM25 27.001H39V29H25zM25 31H39V33.001H25z"
                />
                <path fill="#0D47A1" d="M27 42L6 38 6 10 27 6z" />
                <path
                  fill="#FFF"
                  d="M21.167,31.012H18.45l-1.802-8.988c-0.098-0.477-0.155-0.996-0.174-1.576h-0.032c-0.043,0.637-0.11,1.162-0.197,1.576l-1.85,8.988h-2.827l-2.86-14.014h2.675l1.536,9.328c0.062,0.404,0.111,0.938,0.143,1.607h0.042c0.019-0.498,0.098-1.051,0.223-1.645l1.97-9.291h2.622l1.785,9.404c0.062,0.348,0.119,0.846,0.17,1.511h0.031c0.02-0.515,0.073-1.035,0.16-1.563l1.503-9.352h2.468L21.167,31.012z"
                />
              </svg>
            </span>
            <span className="pdf-name">{fileName}</span>
          </div>
        );
      default:
        return (
          <div className="pdf-info">
            <span className="svg-icon material-icons">picture_as_pdf</span>
            <span className="pdf-name">{fileName}</span>
          </div>
        );
    }
  };

  const returnPopOver = (id, content) => {
    return (
      <Popover id={id}>
        <Popover.Content className="popover-content">{content}</Popover.Content>
      </Popover>
    );
  };

  return (
    <div className="conexp-links">
      {attachmentListing && (
        <div className="link-box documents mt-4">
          <h3>{t('private_chat.documents_sent_received')}</h3>
          {attachmentListing.map((attachments, key) => {
            return (
              <div className="document-row" key={key}>
                {getFileIcon(attachments.uploadedDoc, "")}
                <div className="document-content">
                  <div className="document-name">
                    <span className="list-label" style={{ cursor: "pointer" }}>
                      <OverlayTrigger
                        trigger={["hover", "focus"]}
                        overlay={returnPopOver(
                          "popover-basic2",
                          attachments.uploadedDocName
                        )}
                      >
                        <span>{truncate(attachments.uploadedDocName, 15)}</span>
                      </OverlayTrigger>
                    </span>
                  </div>
                  <div className="view-link">
                    <span
                      onClick={() => openAttachments(attachments.uploadedDoc)}
                    >
                      {t('private_chat.view')}
                    </span>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}

      {attachmentListing && attachmentListing.length >= 15 && (
        <Link
          to={{
            pathname: DashboardRoute.CONVERSATION_DOCUMENTS,
            policyId: conversationDetails.applicationCarrierId,
          }}
        >
          <button type="button" className="btn btn-primary-outline">
            {t('private_chat.view_all_documents')}
          </button>
        </Link>
      )}
    </div>
  );
};

export default withRouter(ChatRightbar);
