import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  commonApiCall,
  getJwt,
  UNDERWRITING_DETAILS,
  setSbliProgressCount,
} from "../../redux/actions";
import { isEmpty } from "lodash";
import {
  decryptme,
  encryptme,
  getState,
  segmentTracking,
  setProcessStep,
  showErrorToast,
  UnderWritingRoutes,
  validateEmail,
  validateMobileNumber,
} from "../../utils";
import { API_URL_CLIENT, API_URL_UNDERWRITING } from "../../config/configs";
import Select from "react-select";
import NumberFormat from "react-number-format";

const SBLIUserDetail = ({
  commonApiCall,
  underwriting,
  consumer,
  applicationCarrierInfo,
  progressCount,
  setSbliProgressCount,
  t,
  ...props
}) => {
  console.log(underwriting);
  const {
    applicationId,
    applicationType,
    goalType,
    policyType,
    firstName,
    lastName,
    carrierWiseLeads,
    dob,
  } = underwriting;

  const [isProcessing, setIsProcessing] = useState(false);
  const [formData, setFormData] = useState({
    first_name: firstName ? decryptme(firstName) : "",
    last_name: lastName ? decryptme(lastName) : "",
    email: underwriting.email ? decryptme(underwriting.email) : "",
    mobile: underwriting.mobile ? decryptme(underwriting.mobile) : "",
    dob: dob ? decryptme(dob) : null,
    applicationType: applicationType,
    applicationId: applicationId,
    goalType: goalType,
    address1: "",
    state: underwriting.state
      ? { label: underwriting.state.name, value: underwriting.state.id }
      : "",
    postalCode: underwriting?.postalCode ? underwriting?.postalCode : "",
    city: "",
    ssn: underwriting?.ssn ? decryptme(underwriting?.ssn) : "",
    gender: underwriting?.gender ? decryptme(underwriting?.gender) : "",
    errors: {},
  });

  const { errors } = formData;

  const segmentEventAfterRegister = (eventName, id) => {
    if (applicationCarrierInfo?.selectedPolicies && id) {
      let filterLists = [];
      let liaison_type =
        underwriting?.productSelection === "appliedOnline" ? "self" : "agent";
      for (let i = 0; i < applicationCarrierInfo.selectedPolicies.length; i++) {
        filterLists.push({
          policy_id:
            applicationCarrierInfo.selectedPolicies[i].applicationCarrierId,
          policy_type: applicationCarrierInfo.selectedPolicies[i].policyType,
        });
      }
      const eventObject = {
        agent_id: underwriting?.agentDetail?.agentId,
        application_id: underwriting.applicationId,
        policy_details: filterLists,
        client_id: id,
        policy_type: underwriting?.policyType,
        liaison_type: liaison_type,
      };
      segmentTracking(eventName, eventObject);
    }
  };

  useEffect(() => {
    if (getJwt()) {
      getUserDetails();
    }

    localStorage.removeItem("persist::bene");

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getUserDetails = async () => {
    const isAuthorized = getJwt() ? true : false;
    let response = await commonApiCall(
      API_URL_CLIENT + "getUsersProfile",
      "",
      "get",
      "",
      isAuthorized
    );

    if (response.status.code === 200) {
      let userData = response.data;
      let inputVal = {};
      inputVal.first_name = underwriting.firstName
        ? await decryptme(underwriting.firstName)
        : await decryptme(userData.firstName);
      inputVal.last_name = underwriting.lastName
        ? await decryptme(underwriting.lastName)
        : await decryptme(userData.lastName);
      inputVal.mobile = underwriting.mobile
        ? await decryptme(underwriting.mobile)
        : await decryptme(userData.mobile);

      inputVal.email = underwriting.email
        ? await decryptme(underwriting.email)
        : await decryptme(userData.email);
      inputVal.address1 = userData.address1 ? decryptme(userData.address1) : "";
      inputVal.city = userData.city ? decryptme(userData.city) : "";
      inputVal.dob = underwriting.dob
        ? decryptme(underwriting.dob)
        : decryptme(underwriting?.dob);

      inputVal.postalCode = underwriting?.postalCode
        ? underwriting?.postalCode
        : decryptme(userData.postalCode);

      inputVal.ssn = underwriting?.ssn
        ? decryptme(underwriting?.ssn)
        : decryptme(userData.ssn);

      setFormData(inputVal);
    }
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const validateField = () => {
    let {
      first_name,
      last_name,
      email,
      mobile,
      state,
      ssn,
      address1,
      city,
      postalCode,
    } = formData;
    let error = {};
    let validate = true;
    if (first_name === "") {
      error.first_name = t("validation_message.fName");
      validate = false;
    }
    if (last_name === "") {
      error.last_name = t("validation_message.lName");
      validate = false;
    }
    if (ssn === "") {
      error.ssn = t("validation_message.SSN");
      validate = false;
    }
    if (email === "") {
      error.email = t("validation_message.email");
      validate = false;
    }
    if (address1 === "") {
      error.address1 = t("validation_message.address2");
      validate = false;
    }

    if (city === "") {
      error.city = t("validation_message.city");
      validate = false;
    }

    if (postalCode === "") {
      error.postalCode = t("validation_message.PIN");
      validate = false;
    }

    if (email === "" || !validateEmail(email)) {
      error.email = t("validation_message.validEmail");
      validate = false;
    }
    if (mobile === "" || !validateMobileNumber(mobile)) {
      error.mobile = t("validation_message.validMobile");
      validate = false;
    }
    // if (state === "" || state.label === null || state.value === 0) {
    //   error.state = t("validation_message.state");
    //   validate = false;
    // }

    setFormData({
      ...formData,
      errors: error,
    });
    return validate;
  };

  const agentAssign = async (appId = "", res) => {
    try {
      const request = {
        applicationId: appId,
      };
      const isAuthorized = getJwt() ? true : false;
      const apiEndPoint = API_URL_UNDERWRITING + "saveAssignedAgent";
      const response = await commonApiCall(
        apiEndPoint,
        "post",
        request,
        "",
        isAuthorized
      );
      if (response.status.code === 200) {
        segmentEventAfterRegister("prospect_assign", res?.clientUserId);
        setSbliProgressCount(progressCount + 1);
        props.history.push(UnderWritingRoutes.SBLI_TERMS);
        // const timer = setTimeout(() => {
        //   showSuccessToast(res?.applicationMessage);
        //   if (
        //     carrierWiseLeads[0]?.carrierName === "SBLI" &&
        //     res?.nextStep?.toUpperCase() !== "BENE"
        //   ) {
        //     props.history.push(UnderWritingRoutes.SBLI_PAGE);
        //   } else if (res?.nextStep?.toUpperCase() == "BENE") {
        //     onSignUp();
        //   } else {
        //     props.history.push(ApprovalStatus.APPLICATION_STATUS);
        //   }
        // }, 1000);
        // return () => clearTimeout(timer);
      } else {
        showErrorToast(response.status.message);
      }
    } catch (err) {
      showErrorToast(t("validation_message.SOMETHING_WENT_WRONG"));
    }
  };

  const saveUnderwriteDetails = async (userId) => {
    const urlPath = window.location.pathname.split("/")[1];
    try {
      const splitpath = window.location.pathname.split("/");
      let {
        first_name,
        last_name,
        email,
        mobile,
        state,
        address1,
        city,
        postalCode,
      } = formData;

      !isEmpty(carrierWiseLeads) &&
        carrierWiseLeads.map((carrierWiseLeadsOptions, i) => {
          if (!carrierWiseLeadsOptions.agentAssigned) {
            carrierWiseLeadsOptions.leadLevel = "Level-1";
          }
        });

      let request = {
        applicationId: applicationId,
        applicationType: applicationType,
        goalType: goalType,
        firstName: await encryptme(first_name),
        lastName: await encryptme(last_name),
        clientUserId: userId,
        email: email ? await encryptme(email) : email,
        mobile: mobile ? await encryptme(mobile) : mobile,
        // stateId: getState(postalCode).id,
        resumeProcess: setProcessStep(splitpath[1], policyType, goalType),
        stateId: getState(postalCode).id,
        // countryCode: await encryptme(countryCode),
        carrierWiseLeads: carrierWiseLeads,
        ssn: formData.ssn ? await encryptme(formData.ssn) : null,
        addressLine1: await encryptme(address1),
        city: await encryptme(city),
        postalCode: postalCode,
      };
      const isAuthorized = getJwt() ? true : false;
      const apiEndPoint = API_URL_UNDERWRITING + "saveUnderWriting";
      const response = await commonApiCall(
        apiEndPoint,
        "post",
        request,
        UNDERWRITING_DETAILS,
        isAuthorized
      );

      if (response.status.code === 200) {
        agentAssign(applicationId, response?.data);
      } else {
        setIsProcessing(false);
        showErrorToast(response.status.message);
      }
    } catch (err) {
      setIsProcessing(false);
      showErrorToast(t("validation_message.SOMETHING_WENT_WRONG"));
    }
  };

  const saveNext = () => {
    if (validateField()) {
      setIsProcessing(true);
      segmentEventAfterRegister("prospect_create", underwriting?.clientUserId);
      saveUnderwriteDetails(underwriting?.clientUserId);
    }
  };

  return (
    <div className="sbli-user-detail">
      <div className="highlighted-header">
        <p>
          We need to confirm your personal information before we submit your
          application. We use this information to verify your identity and
          prevent fraud. We take every precaution to keep it safe and secure.
        </p>
      </div>
      <div className="container-fluid mt-5">
        <div className="row justify-content-center">
          <div className="col-md-8">
            <h4 className="mb-3">What's your name?</h4>
            <div className="form-group">
              <div className="input-effect">
                <input
                  className="form-control"
                  placeholder={"First Name"}
                  name="first_name"
                  id="first_name"
                  type="text"
                  value={formData.first_name}
                  onChange={handleChange}
                />
                <label htmlFor="first_name">{"First Name"}</label>
                <em className="input-icon"></em>
                <span className="text-danger ">{errors?.first_name}</span>
              </div>
            </div>
            <div className="form-group">
              <div className="input-effect">
                <input
                  className="form-control"
                  placeholder={"Last Name"}
                  name="last_name"
                  id="last_name"
                  type="text"
                  value={formData.last_name}
                  onChange={handleChange}
                />
                <label htmlFor="last_name">{"Last Name"}</label>
                <em className="input-icon"></em>
                <span className="text-danger ">{errors?.last_name}</span>
              </div>
            </div>
            <div className="form-group">
              <div className="input-effect">
                <input
                  className="form-control"
                  placeholder={"Gender"}
                  name="gender"
                  id="gender"
                  type="text"
                  value={formData.gender}
                  readOnly
                />
                <label htmlFor="last_name">{"Gender"}</label>
                <em className="input-icon"></em>
              </div>
            </div>
          </div>

          <div className="col-md-8">
            <h4 className="mb-3">What is your date of birth?</h4>
            <div className="form-group custom-datepicker">
              <DatePicker
                selected={formData.dob ? new Date(formData.dob) : ""}
                maxDate={new Date()}
                placeholderText={"MM/DD/YYYY"}
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                dateFormatCalendar="MMMM"
                onChange={(date) =>
                  setFormData({
                    ...formData,
                    dob: `${
                      date.getMonth() + 1
                    }-${date.getDate()}-${date.getFullYear()}`,
                  })
                }
              />
              <span className="datepicker-label">
                {t("beneficiaryPage.date_of_birth")}
              </span>
              <span className="text-danger ">{errors?.dob}</span>
            </div>
          </div>
          <div className="col-md-8">
            <h4 className="mb-3">What is your social security number?</h4>
            <div className="form-group">
              <div className="input-effect ">
                <NumberFormat
                  name="ssn"
                  id="ssn"
                  className="form-control"
                  placeholder={"Social Security Number (SSN#)"}
                  format="###-##-####"
                  value={formData.ssn}
                  onValueChange={(e) =>
                    handleChange({
                      target: { name: "ssn", value: e.value },
                    })
                  }
                />
                <label htmlFor="SSN">Social Security Number (SSN#)</label>
                <span className="text-danger ">{errors?.ssn}</span>
              </div>
            </div>
          </div>

          <div className="col-md-8">
            <h4 className="mb-3">What is your home address (no P.O. Boxes)?</h4>
            <div className="form-group">
              <div className="input-effect">
                <input
                  className="form-control"
                  placeholder={"Home address (no P.O. Boxes)"}
                  name="address1"
                  id="address1"
                  type="text"
                  onChange={handleChange}
                  value={formData.address1}
                />
                <label htmlFor="address1">
                  {"Home address (no P.O. Boxes)"}
                </label>
                <em className="input-icon"></em>
                <span className="text-danger ">{errors?.address1}</span>
              </div>
            </div>
          </div>
          <div className="col-md-8">
            <div className="form-group">
              <div className="input-effect">
                <input
                  className="form-control"
                  placeholder={"City"}
                  name="city"
                  id="city"
                  type="text"
                  onChange={handleChange}
                  value={formData.city}
                />
                <label htmlFor="city">{"City"}</label>
                <em className="input-icon"></em>
                <span className="text-danger ">{errors?.city}</span>
              </div>
            </div>
          </div>
          <div className="col-md-8">
            <div className="row">
              <div className="col-md-4">
                <div className="form-group fixed-floating-label mb-4">
                  <div className="default-select with-border">
                    <Select
                      className={"custom-select-menu"}
                      classNamePrefix={"custom-select"}
                      value={
                        formData.postalCode != ""
                          ? {
                              value: getState(formData.postalCode)?.id,
                              label: getState(formData.postalCode)?.state,
                            }
                          : ""
                      }
                      isDisabled={true}
                    />
                    <span className="fixed-label">
                      {t("manual_form.state")}
                    </span>
                    <em className="input-icon"></em>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <div className="input-effect">
                    <input
                      className="form-control"
                      placeholder={"Zip Code"}
                      name="postalCode"
                      onChange={handleChange}
                      id="postalCode"
                      type="number"
                      value={formData.postalCode}
                    />
                    <label htmlFor="postalCode">{"Zip Code"}</label>
                    <em className="input-icon"></em>
                    <span className="text-danger ">{errors?.postalCode}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-8">
            <h4 className="mb-3">
              What is your phone number and e-mail address?
            </h4>
            <div className="form-group">
              <div className="input-effect">
                <NumberFormat
                  name="phone"
                  id="mobile"
                  className="form-control"
                  placeholder="Phone Number"
                  format="(###) ###-####"
                  value={formData.mobile}
                  onValueChange={(e) => {
                    handleChange({
                      target: {
                        name: "mobile",
                        value: e.value,
                      },
                    });
                  }}
                />
                <label htmlFor="phone">{"Phone number"}</label>
                <em className="input-icon"></em>
                {errors?.mobile && (
                  <p className="sbli-validation-msg text-danger">
                    {errors?.mobile}
                  </p>
                )}
              </div>
            </div>

            <div className="form-group">
              <div className="input-effect">
                <input
                  className="form-control"
                  placeholder={"E-mail Address"}
                  name="email"
                  id="email"
                  onChange={handleChange}
                  value={formData.email}
                  type="email"
                />
                <label htmlFor="email">{"E-mail Address"}</label>
                <em className="input-icon"></em>
                <span className="text-danger ">{errors?.email}</span>
              </div>
            </div>
          </div>
          <div className="col-md-8">
            <div className="w-100 mb-4 text-center">
              <button
                disabled={isProcessing}
                className="btn btn-default px-5"
                onClick={saveNext}
              >
                {isProcessing ? "Processing..." : "Next"}
              </button>
            </div>
            {/* <div className="w-100 text-center">
              <button
                className="btn btn-link"
                onClick={() => props.history.push("/sbli-page")}
              >
                Back
              </button>
            </div> */}
          </div>
          {/* <div className="col-md-8 mt-3">
            <h4 className="mb-3">What's your date of birth?</h4>
            <div className="input-effect">
              <div className="form-group input-group custom-datepicker mb-0">
                <DatePicker
                  className={"form-control"}
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  dateFormatCalendar="MMMM"
                  popperPlacement="top-start"
                />
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  underwriting: state.underwriting.underwriting,
  consumer: state.consumer?.consumerdetails,
  applicationCarrierInfo: state.underwriting.applicationCarrierInfo,
  progressCount: state.underwriting.progressCount,
});

export default connect(mapStateToProps, {
  commonApiCall,
  setSbliProgressCount,
})(withRouter(SBLIUserDetail));
