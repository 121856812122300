import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as _ from "lodash";
import queryString from "query-string";
import moment from "moment";
//import analytics from "segment";

import {
  commonApiCall,
  UNDERWRITING_DETAILS,
  COMPANY_INFORMATION,
  APPLICATION_CARRIERS_DETAILS,
  applicationCarriersInfo,
  clearCompanyInformation,
  clearUnderWriting,
  getJwt,
} from "../../../redux/actions/index";
import {
  API_URL_CLIENT,
  API_URL_UNDERWRITING,
  CONSUMER_PORTAL_URL,
} from "../../../config/configs";
import {
  getSliderVariables,
  setProcessStep,
  getProcessStep,
  decryptme,
  encryptme,
  UnderWritingRoutes,
  showErrorToast,
  calcAge,
  calcAgeOnMonth,
  segmentTracking,
  getState,
} from "../../../utils/index";

import {
  AnalyticsModel,
  DividendGraphModel,
  IllustrationModel,
  ComparePlanModel,
} from "../../../components/underWritingModels";
import SearchBar from "./searchBar";
import PlanListing from "./planListing";
import { Button, Modal } from "react-bootstrap";

const PlanSuggestion = ({
  commonApiCall,
  clearUnderWriting,
  applicationCarriersInfo,
  underwriting,
  match,
  t,
  ...props
}) => {
  const urlParams = queryString.parse(props.location.search);
  let applicationId = "",
    applicationType = "Web",
    goalType = "",
    policyType = "",
    insureBasedOn = "",
    frequency = "",
    amount = "",
    gender = "",
    dob = "",
    age = "",
    healthRating = "",
    smoker = "",
    agentkey = "",
    pay = "",
    termLength = "",
    postalCode = "",
    stateCode = "",
    referenceFrom = document.referrer ? document.referrer : "",
    referalCode = "";

  const [pacificadvisorsDomain, setPacificadvisorsDomain] = useState(false);
  const [comparisonPlan, setComparisonPlan] = useState([]);
  const [showApplyModal, setShowApplyModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const getAgentCompanyInfo = async () => {
    if (agentkey) {
      try {
        const request = {};
        const apiEndPoint =
          API_URL_CLIENT + "getCompanyInfo?secretKey=" + agentkey;
        const isAuthorized = false;
        const response = await commonApiCall(
          apiEndPoint,
          "get",
          request,
          COMPANY_INFORMATION,
          isAuthorized
        );
        if (response.status.code === 200) {
          saveUnderWritingProcess();
        } else {
          await clearCompanyInformation();
          showErrorToast(response.status);
          // props.history.push(UnderWritingRoutes.HOME);
        }
      } catch (err) {
        console.log(err);
        showErrorToast(t("validation_message.SOMETHING_WENT_WRONG"));
      }
    } else {
      window.location.replace(CONSUMER_PORTAL_URL + "404");
    }
  };

  const saveUnderWritingProcess = async () => {
    try {
      const splitpath = window.location.pathname.split("/");
      const request = {
        applicationType: "Web",
        goalType: goalType,
        policyType: policyType,
        frequency: frequency,
        amount: amount.toString(),
        gender: gender,
        age: age.toString(),
        dob: dob ? await encryptme(moment(dob).format("L").toString()) : null,
        pay: pay.toString(),
        healthRating: healthRating,
        smoker: JSON.parse(smoker),
        resumeProcess: setProcessStep(splitpath[1], policyType, goalType),
        agentSecretKey: agentkey,
        postalCode: postalCode,
        stateName: getState(postalCode).state,
        referenceFrom: referenceFrom,
        reference: referenceFrom,
      };
      if (insureBasedOn) request.insureBasedOn = insureBasedOn;

      if (referalCode.length > 0) request.referalCode = referalCode;

      const isAuthorized = getJwt() ? true : false;
      const apiEndPoint = API_URL_UNDERWRITING + "saveUnderWriting";
      const response = await commonApiCall(
        apiEndPoint,
        "post",
        request,
        UNDERWRITING_DETAILS,
        isAuthorized
      );
      if (response.status.code === 200) {
        props.history.push(UnderWritingRoutes.PLANS);
      } else {
        window.location.replace(CONSUMER_PORTAL_URL + "404");
      }
    } catch (err) {
      console.log(err);
      showErrorToast(t("validation_message.SOMETHING_WENT_WRONG"));
    }
  };

  if (!_.isEmpty(urlParams)) {
    const paramsCheckCondition =
      !urlParams.goalType ||
      !urlParams.policyType ||
      !urlParams.frequency ||
      !urlParams.amount ||
      !urlParams.gender ||
      !urlParams.dob ||
      !urlParams.healthRating ||
      !urlParams.smoker ||
      !urlParams.postalCode ||
      !urlParams.agentkey;
    if (paramsCheckCondition)
      window.location.replace(CONSUMER_PORTAL_URL + "404");
    else {
      applicationType = "Web";
      goalType = urlParams.goalType;
      policyType = urlParams.policyType;
      insureBasedOn = urlParams.insureBasedOn;
      frequency = urlParams.frequency;
      amount = urlParams.amount > 3000000 ? 3000000 : urlParams.amount;
      gender = encryptme(urlParams.gender);
      age = urlParams.age
        ? urlParams.age
        : moment().diff(new Date(urlParams.dob), "years");
      dob = urlParams.dob ? new Date(urlParams.dob) : "";
      healthRating = urlParams.healthRating;
      smoker = urlParams.smoker;
      pay = urlParams.pay ? urlParams.pay : "";
      agentkey = urlParams.agentkey;
      referalCode = urlParams.agentcode ? urlParams.agentcode : "";
      termLength = urlParams.pay ? urlParams.pay : "";
      postalCode = urlParams.postalCode;
      stateCode = urlParams.stateCode ? urlParams.stateCode : "";
    }
  } else {
    if (underwriting) {
      applicationId = underwriting.applicationId;
      applicationType = underwriting.applicationType;
      goalType = underwriting.goalType;
      policyType = underwriting.policyType;
      insureBasedOn = underwriting.insureBasedOn;
      frequency = underwriting.frequency;
      amount = underwriting.amount;
      gender = underwriting.gender;
      age = underwriting.age;
      dob = underwriting.dob ? new Date(decryptme(underwriting.dob)) : "";
      healthRating = underwriting.healthRating;
      smoker = underwriting.smoker;
      pay = underwriting.pay;
      termLength = underwriting.termLength;
      postalCode = underwriting?.postalCode;
      stateCode = underwriting?.state?.code;
      if (!applicationId) window.location.replace(CONSUMER_PORTAL_URL + "404");
    }
  }
  const domainNamePath = window.location.hostname;
  const domainName = domainNamePath.split(".");
  const [goalOptions, setGoaloption] = useState([
    {
      value: "SAVE",
      label: domainName.includes("pws") ? "Conservative" : "Dividend based",
      key: "dividendBased",
    },
    {
      value: "INVEST",
      label: domainName.includes("pws")
        ? "Moderately Aggressive"
        : "Index based",
      key: "indexBased",
    },
    { value: "INSURE", label: "Insurance" },
  ]);

  const frequencyOptions = [
    { value: "MONTHLY", label: "Monthly" },
    // { value: "QUARTERLY", label: "Quarterly" },
    { value: "ANNUALLY", label: "Annually" },
    // { value: "ONETIME", label: "One Time" }
  ];

  const policyOptions = [
    { value: "TERM_LIFE_INSURANCE", label: "Term", key: "term" },
    { value: "PERMANENT_LIFE_INSURANCE", label: "Permanent", key: "permanent" },
    {
      value: "FINAL_EXPENSE_INSURANCE",
      label: "Final expense",
      key: "finalExpense",
    },
  ];

  const insureBasedOptions = [
    { value: "COVERAGE", label: "Coverage" },
    { value: "BUDGET", label: "Budget" },
  ];

  const genderOptions = [
    { value: "MALE", label: "Male" },
    { value: "FEMALE", label: "Female" },
  ];

  const healthOptions = [
    { value: "EXCELLENT", label: "Excellent" },
    { value: "GOOD", label: "Good" },
    { value: "AVERAGE", label: "Average" },
  ];

  const smokeOptions = [
    { value: true, label: "Yes" },
    { value: false, label: "No" },
  ];

  const termLengthOptions = [
    { value: 10, label: "10 Years" },
    { value: 15, label: "15 Years" },
    { value: 20, label: "20 Years" },
    { value: 30, label: "30 Years" },
  ];
  const [searchData, setSearchData] = useState({
    yourGoalType: goalType
      ? _.find(goalOptions, ["value", goalType])
      : { value: "SAVE", label: "Dividend based" },
    yourPolicyType: policyType
      ? _.find(policyOptions, ["value", policyType])
      : { value: "TERM_LIFE_INSURANCE", label: "Term" },
    yourInsureBasedOn: insureBasedOn
      ? _.find(insureBasedOptions, ["value", insureBasedOn])
      : { value: "COVERAGE", label: "Coverage" },
    yourFrequency: frequency
      ? _.find(frequencyOptions, ["value", frequency])
      : { value: "MONTHLY", label: "Monthly" },
    yourAmount: amount,
    yourGender: gender
      ? _.find(genderOptions, ["value", decryptme(gender)])
      : { value: "MALE", label: "Male" },
    yourAge: age ? age : "20",
    yourDOB: dob ? new Date(dob) : null,
    // yourPay: pay ? pay : "20",
    termLength: termLength
      ? _.find(termLengthOptions, ["value", Number(termLength)])
      : { value: 20, label: "20 Years" },
    yourHealthRating: healthRating
      ? _.find(healthOptions, ["value", healthRating])
      : { value: "EXCELLENT", label: "Excellent" },
    isSmoker:
      smoker && smoker.toString() === "true"
        ? { value: true, label: "Yes" }
        : { value: false, label: "No" },
  });
  const [nextStepData, setNextStepData] = useState({});
  const slideLabels =
    searchData.yourGoalType.value === "INSURE"
      ? getSliderVariables(
          "INSURE",
          searchData.yourAmount,
          searchData.yourPolicyType.value
        )
      : getSliderVariables(
          searchData.yourFrequency.value,
          searchData.yourAmount,
          searchData.yourPolicyType.value
        );
  const [suggestedPlans, setSuggestedPlans] = useState({});
  const [comparisonWith, setComparisonWith] = useState({
    id_: "",
    accountOptions: [],
    account: {},
    selectedInstrumentAnalytics: {},
    instrumentOptionsForAnalytics: [],
    coparisionData: [],
    // coparisionDataAnalytics: [],
    comparedPlans: [],
  });

  const [noOfPolicies, setNoOfPolicies] = useState("170,000");
  const [agentNoOfApplication, setAgentNoOfApplication] = useState("2");
  const [termFlag, setTermFlag] = useState(false);
  const [modelData, setModelData] = useState({
    isDividendSelected: "1",
    dividendCarrier: "",
    dividendCarrierId: "",
    illustrationPdf: "",
    analytics: [],
    analyticsCarrier: "",
    analyticsCarrierId: "",
    riskOptionSelected: { value: "riskAdjust", label: "Risk Adjusted Return" },
    isAdjustTax: false,
    cashDividendModal: false,
    analyticsModel: false,
    illustrationsModel: false,
    comparePlansModal: false,
    isFilterLoader: true,
  });

  const [riskData, setRiskData] = useState({
    riskAdjustedReturnList: [],
    riskVolatilityHistoryList: [],
  });
  const [insureData, setInsureData] = useState({
    yourInsure: {},
    termInsure: {},
    amountAtRisk: {},
  });

  const handleChange = (e) => {
    // if (e.target.name !== "yourAmount") {
    //   console.log("Entered in if");
    //   const defaultDepositAmount =
    //     searchData.yourAmount < slideLabels.min
    //       ? slideLabels.defaultValue
    //       : searchData.yourAmount;

    //   setSearchData({
    //     ...searchData,
    //     [e.target.name]: e.target.value,
    //     ["yourAmount"]: defaultDepositAmount,
    //   });
    // } else {
    setSearchData({
      ...searchData,
      [e.target.name]: e.target.value,
    });
    //}
  };
  const handleBlur = (e) => {
    if (e.target.name === "yourAmount") {
      const defaultDepositAmount =
        searchData.yourAmount < slideLabels.min
          ? slideLabels.defaultValue
          : searchData.yourAmount;
      setSearchData({
        ...searchData,
        // eslint-disable-next-line no-useless-computed-key
        ["yourAmount"]: defaultDepositAmount,
      });
    }
    // if (searchData[e.target.name] === "") {
    //   setSearchData({ ...searchData, [e.target.name]: 20 });
    // } else {
    //   setSearchData({
    //     ...searchData,
    //     [e.target.name]: yourAge,
    //   });
    // }
  };

  const handleComparision = (e, whichOption) => {
    let coparisionData = _.find(suggestedPlans.comparisonWith, ["id", e.value]);
    if (whichOption === "AdvanceAnalytics")
      setComparisonWith({
        ...comparisonWith,
        selectedInstrumentAnalytics: e,
        // coparisionDataAnalytics: coparisionData,
      });
    else
      setComparisonWith({
        ...comparisonWith,
        account: e,
        coparisionData: coparisionData,
      });
  };

  const handleModel = (name, isHide) => {
    setModelData({ ...modelData, [name]: isHide });
  };

  const handleApplyModal = () => {
    setShowApplyModal(!showApplyModal);
  };

  const handleComparePlans = (e, planData, check) => {
    const illustrationId = e.target.value;
    // let { comparedPlans } = comparisonWith;
    let comparedPlans = check
      ? [...comparisonPlan]
      : comparisonWith.comparedPlans;

    let ishandledata = _.find(comparedPlans, {
        illustration_id: illustrationId,
      }),
      handledata;
    handledata = {
      illustration_id: illustrationId,
      suggestionId: planData.suggestionId,
      carrierId: planData.carrier_id,
      premium:
        planData.frequency === "MONTHLY"
          ? planData.monthlyPremium
          : planData.premium,
      frequency: planData.frequency,
      artrAmount: planData.artrAmount,
      yearlyPremium: planData.premium,
      pay: planData.pay,
      policyType: planData.policy_type,
      viewIllustration: planData.view_illustration,
      // productName:planData.productName,
      // productDescription:planData.descriptions,
      productId: planData.productId,
      gauranteedDeathBenefit: planData.illustration_data
        ? planData.illustration_data.age10.gauranteed_death_benefit
        : planData.coverage
        ? planData.coverage
        : null,
      nonGauranteedDeathBenefit:
        planData.illustration_data &&
        planData.illustration_data.age10.non_gauranteed_death_benefit,
      carrierType: planData.carrierType,
    };
    if (!ishandledata) {
      // if (
      //   searchData.yourGoalType.value === "INSURE" &&
      //   searchData.yourPolicyType.value === "TERM_LIFE_INSURANCE" &&
      //   comparedPlans.length > 0
      // ) {
      //   let index = _.indexOf(comparedPlans, ishandledata);
      //   comparedPlans.splice(index, 1);
      // } else
      if (planData.carrierType == "online") {
        comparedPlans = [];
        // comparedPlans.shift();
        comparedPlans.push(handledata);
      }
      if (planData.carrierType == "offline") {
        if (comparedPlans.length == 0) {
          comparedPlans.push(handledata);
        } else {
          let result = comparedPlans.findIndex(
            (product) => product.carrierType == "online"
          );
          if (result > -1) {
            comparedPlans.splice(result, 1);
          }
          if (
            (!check && comparedPlans.length <= agentNoOfApplication) ||
            check
          ) {
            if (
              (!check && comparedPlans.length == agentNoOfApplication) ||
              (check && comparedPlans.length == 2)
            ) {
              comparedPlans.shift();
            }
          }
          comparedPlans.push(handledata);
        }
      }
      // if ( agentNoOfApplication == "1" && comparedPlans.length == 1) {
      //   comparedPlans.shift();
      // }
      //  if ( agentNoOfApplication == "2" && comparedPlans.length > 1) {
      //   comparedPlans.shift();
      // }
    } else {
      let index = _.indexOf(comparedPlans, ishandledata);
      comparedPlans.splice(index, 1);
    }
    if (check) {
      setComparisonPlan(comparedPlans);
    } else {
      setComparisonWith({ ...comparisonWith, comparedPlans: comparedPlans });
    }
  };

  const handleIllustration = (id) => {
    const suggestionData = _.find(suggestedPlans.suggestions, [
      "illustration_id",
      id,
    ]);
    if (suggestionData.view_illustration) {
      const illustrationPdfURL =
        API_URL_CLIENT + "getPdfFile?path=" + suggestionData.view_illustration;
      setModelData({
        ...modelData,
        illustrationPdf: illustrationPdfURL,
        illustrationsModel: true,
      });
    } else {
      showErrorToast("Illustration data not found.");
    }
  };

  const setDividendModel = (id, name, isDividendChecked) => {
    setModelData({
      ...modelData,
      isDividendSelected: isDividendChecked,
      dividendCarrierId: id,
      dividendCarrier: name.charAt(0).toUpperCase() + name.slice(1),
      cashDividendModal: true,
    });
  };

  const getAnalytics = async (
    id,
    illustration_id,
    carrierName,
    productName
  ) => {
    // try {
    const request = {
      carrierId: id,
    };
    if (carrierName !== "Lincoln Financial") {
      const apiEndPoint = API_URL_UNDERWRITING + "getCarrierHistoricalInfo";
      const response = await commonApiCall(apiEndPoint, "post", request);
      if (response.status.code === 200) {
        const yourInsure = _.find(suggestedPlans.suggestions, {
          illustration_id: illustration_id,
        });
        const termInsure = _.find(suggestedPlans.suggestions, {
          illustration_id: illustration_id,
        });

        setInsureData({
          ...insureData,
          yourInsure: yourInsure.illustration_data,
          termInsure: termInsure["compareTermPlans"],
          amountAtRisk: yourInsure.amountAtRisk,
        });
        setRiskData({
          ...riskData,
          riskAdjustedReturnList: response.data.riskAdjustedReturnList,
          riskVolatilityHistoryList: response.data.riskVolatilityHistoryList,
        });

        let riskDataValues =
          riskOptionSelected.value === "riskVolatility"
            ? response.data.riskVolatilityHistoryList
            : response.data.riskAdjustedReturnList;
        setAnalytics(
          riskDataValues,
          carrierName,
          id,
          "",
          "",
          null,
          productName
        );
      } else {
        showErrorToast("Advanced Analytics data not found!");
      }
    }
    // } catch (err) {
    //   toast.error(validationMessages.SOMETHING_WENT_WRONG);
    // }
  };

  const setAnalytics = (
    riskCompareData = "",
    carrierName,
    analyticsCarrierId,
    riskType = "",
    accountCompare = "",
    isAdjustTax,
    productName
  ) => {
    const accountCompareData =
      analyticsCarrierId === 3 || analyticsCarrierId === 4
        ? _.find(suggestedPlans.comparisonWith, ["id", 2])
        : accountCompare
        ? _.find(suggestedPlans.comparisonWith, [
            "id",
            parseInt(accountCompare),
          ])
        : _.find(suggestedPlans.comparisonWith, [
            "id",
            parseInt(comparisonWith.selectedInstrumentAnalytics.value),
          ]);
    const compareOptionTitle = accountCompareData.title;

    const analyticsCarrierTitle = carrierName;
    let accountComparision = [];
    let adjustTax;
    riskCompareData =
      riskType && riskType.value === "riskAdjust"
        ? riskData.riskAdjustedReturnList
        : riskType && riskType.value === "riskVolatility"
        ? riskData.riskVolatilityHistoryList
        : riskCompareData;

    if (isAdjustTax) {
      adjustTax = modelData.isAdjustTax ? false : true;
    } else {
      adjustTax = modelData.isAdjustTax;
    }
    if (riskCompareData) {
      if (
        riskCompareData.length > accountCompareData.chartComparisonData.length
      ) {
        riskCompareData.map((risk) => {
          let isAccountCompareData = _.find(
            accountCompareData.chartComparisonData,
            ["year", risk.year]
          );
          let riskTypeToCompare = riskType
            ? riskType.value
            : modelData.riskOptionSelected.value;
          let currentYield =
            isAccountCompareData &&
            accountCompareData.isSp500 &&
            riskTypeToCompare === "riskAdjust"
              ? adjustTax
                ? isAccountCompareData.returnAdjustTax
                : isAccountCompareData.riskAdjustedReturn
              : isAccountCompareData
              ? adjustTax
                ? isAccountCompareData.historyAdjustTax
                : isAccountCompareData.riskVolatilityHistory
              : null;
          accountComparision.push({
            Year: risk.year.toString(),
            [analyticsCarrierTitle]: risk.value,
            [compareOptionTitle]: currentYield,
          });
          return true;
        });
      } else {
        const { chartComparisonData } = accountCompareData;
        chartComparisonData.map((risk) => {
          let isAccountCompareData = _.find(riskCompareData, [
            "year",
            risk.year,
          ]);
          let riskTypeToCompare = riskType
            ? riskType.value
            : modelData.riskOptionSelected.value;

          let currentYield =
            accountCompareData.isSp500 && riskTypeToCompare === "riskAdjust"
              ? adjustTax
                ? risk.returnAdjustTax
                : risk.riskAdjustedReturn
              : adjustTax
              ? risk.historyAdjustTax
              : risk.riskVolatilityHistory;
          accountComparision.push({
            Year: risk.year.toString(),
            [analyticsCarrierTitle]: isAccountCompareData
              ? isAccountCompareData.value
              : null,
            [compareOptionTitle]: currentYield,
          });
          return true;
        });
      }
      if (riskType) {
        setModelData({
          ...modelData,
          analytics: accountComparision,
          analyticsCarrier: carrierName,
          analyticsCarrierId: analyticsCarrierId,
          isAdjustTax: adjustTax,
          analyticsModel: true,
          riskOptionSelected:
            productName === "Protection UL" && carrierName === "John Hancock"
              ? { value: "insureSavings", label: "Term vs Perm Comparison" }
              : riskType,
          productName: productName,
        });
      } else {
        setModelData({
          ...modelData,
          analytics: accountComparision,
          analyticsCarrier: carrierName,
          analyticsCarrierId: analyticsCarrierId,
          isAdjustTax: adjustTax,
          analyticsModel: true,
          riskOptionSelected:
            productName === "Protection UL" && carrierName === "John Hancock"
              ? { value: "insureSavings", label: "Term vs Perm Comparison" }
              : { value: "riskAdjust", label: "Risk Adjusted Return" },
          productName: productName,
        });
      }
    } else {
      setModelData({
        ...modelData,
        riskOptionSelected: riskType,
      });
    }
  };
  const doSubmit = async () => {
    setIsLoading(true);
    setModelData({
      ...modelData,
      isFilterLoader: true,
    });
    const {
      yourGoalType,
      yourPolicyType,
      yourInsureBasedOn,
      yourFrequency,
      yourAmount,
      yourGender,
      yourDOB,
      termLength,
      yourHealthRating,
      isSmoker,
      // yourPay,
    } = searchData;
    // const yourAge = moment().diff(yourDOB, "years");
    let yourAge;
    if (
      underwriting?.policyType === "FINAL_EXPENSE_INSURANCE" ||
      yourPolicyType.value === "FINAL_EXPENSE_INSURANCE"
    ) {
      yourAge = calcAge(yourDOB);
    } else {
      yourAge = calcAgeOnMonth(yourDOB);
    }
    const request = {
      gender: yourGender && yourGender.value,
      frequency: yourFrequency.value,
      healthRating: yourHealthRating.value,
      isSmoker: isSmoker.value ? "yes" : "no",
      amount: yourAmount ? yourAmount.toString() : "",
      age: yourAge.toString(),
      dob: yourDOB ? moment(yourDOB).format("L").toString() : null,
      goalType: yourGoalType.value,
      postalCode: urlParams.postalCode
        ? urlParams.postalCode
        : underwriting?.postalCode,
      stateCode: urlParams.stateCode
        ? urlParams.stateCode
        : underwriting?.state?.code,
      policyType:
        yourGoalType.value === "INSURE"
          ? yourPolicyType.value
          : "PERMANENT_LIFE_INSURANCE",
      applicationId: underwriting?.applicationId,
    };

    if (yourGoalType.value === "INSURE")
      request.insureBasedOn = yourInsureBasedOn.value;

    if (
      (yourGoalType.value === "INSURE" &&
        yourPolicyType.value === "TERM_LIFE_INSURANCE") ||
      (yourGoalType.value === "INSURE" &&
        yourInsureBasedOn.value === "COVERAGE")
    ) {
      request.frequency = "MONTHLY";
    }
    if (
      yourGoalType.value === "INSURE" &&
      yourInsureBasedOn.value === "FINAL_EXPENSE_INSURANCE"
    ) {
      request.frequency = "MONTHLY";
    }

    if (yourPolicyType.value === "TERM_LIFE_INSURANCE") {
      request.pay = termLength.value;
    }
    if (yourPolicyType.value !== "TERM_LIFE_INSURANCE") {
      request.pay = 15;
    }

    const apiEndPoint = API_URL_UNDERWRITING + "getCarriers";
    const response = await commonApiCall(apiEndPoint, "post", request);
    if (response.status.code === 200) {
      let policySuggestions = [];
      for (let i = 0; i < response.data.suggestions.length; i++) {
        policySuggestions.push({
          agent_id: underwriting?.agentDetail?.agentId,
          application_id: underwriting?.applicationId,
          carrier_id: response.data.suggestions[i].carrier_id,
          carrier_name: response.data.suggestions[i].carrier_name,
          product_name: response.data.suggestions[i].productName,
          monthly_premium:
            response.data.suggestions[i].frequency === "MONTHLY" &&
            response.data.suggestions[i].monthlyPremium,
          annually_premium:
            response.data.suggestions[i].frequency === "ANNUALLY" &&
            response.data.suggestions[i].premium,
          face_amount: response.data.suggestions[i].coverage
            ? response.data.suggestions[i].coverage
            : response.data.suggestions[i].coverage,
        });
      }
      let policySuggestionEvent = {
        policy_suggestions: policySuggestions,
      };
      segmentTracking("policy_suggestions", policySuggestionEvent);
      let eventObject = {
        gender: yourGender && yourGender.value,
        frequency:
          (yourGoalType.value === "INSURE" &&
            yourPolicyType.value === "TERM_LIFE_INSURANCE") ||
          (yourGoalType.value === "INSURE" &&
            yourInsureBasedOn.value === "COVERAGE") ||
          (yourGoalType.value === "INSURE" &&
            yourInsureBasedOn.value === "FINAL_EXPENSE_INSURANCE")
            ? "MONTHLY"
            : yourFrequency.value,
        health_rating: yourHealthRating.value,
        is_smoker: isSmoker.value ? "yes" : "no",
        amount: yourAmount ? yourAmount.toString() : "",
        age: yourAge.toString(),
        // dob: yourDOB ? moment(yourDOB).format("L").toString() : null,
        goal_type: yourGoalType.value,
        policy_type:
          yourGoalType.value === "INSURE"
            ? yourPolicyType.value
            : "PERMANENT_LIFE_INSURANCE",
        application_id: underwriting?.applicationId,
        agent_id: underwriting?.agentDetail?.agentId,
        insure_based_on:
          yourGoalType.value === "INSURE" && yourInsureBasedOn.value,
        pay: termLength ? termLength.value : 15,
      };
      segmentTracking("application_comparison_alter", eventObject);
      setNextStepData(searchData);
      setNoOfPolicies(
        response.data &&
          response.data.noOfPolicy &&
          response.data.noOfPolicy
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      );
      setAgentNoOfApplication(
        response.data && response.data?.agentNoOfApplication
      );
      let planList = response && response.data;
      planList.frequency = yourFrequency.value;
      setSuggestedPlans(planList);
      let selectedAccount,
        selectedInstrumentAnalytics,
        accountOptions = [],
        instrumentOptionsForAnalytics = [],
        coparisionData = {};
      planList &&
        planList.comparisonWith &&
        planList.comparisonWith.map((comparOptions, i) => {
          if (yourGoalType.value === "SAVE") {
            if (comparOptions.id !== 2)
              accountOptions.push({
                value: comparOptions.id,
                label: comparOptions.title,
              });
          } else {
            accountOptions.push({
              value: comparOptions.id,
              label: comparOptions.title,
            });
          }

          if (!comparOptions.hiddenForInstrument) {
            if (comparOptions.id === 2 || comparOptions.id === 3) {
              instrumentOptionsForAnalytics.push({
                value: comparOptions.id,
                label: comparOptions.title,
              });
              if (_.isEmpty(selectedInstrumentAnalytics)) {
                selectedInstrumentAnalytics = {
                  value: comparOptions.id,
                  label: comparOptions.title,
                };
              }
            }
          }
          if (i === 2) {
            coparisionData = comparOptions;
            selectedAccount = {
              value: comparOptions.id,
              label: comparOptions.title,
            };
          }
          return true;
        });
      setComparisonWith({
        ...comparisonWith,
        id_: planList.id,
        account: selectedAccount,
        selectedInstrumentAnalytics: selectedInstrumentAnalytics,
        accountOptions: accountOptions,
        instrumentOptionsForAnalytics: instrumentOptionsForAnalytics,
        coparisionData: coparisionData,
        comparedPlans: [],
      });
      setComparisonPlan([]);
    } else {
      setSuggestedPlans({});
      setComparisonPlan([]);
      setComparisonWith({
        ...comparisonWith,
        account: [],
        selectedInstrumentAnalytics: [],
        accountOptions: [],
        instrumentOptionsForAnalytics: [],
        coparisionData: [],
        comparedPlans: [],
      });
      showErrorToast(
        "We couldn't find any exact matches. Please update your filters for more policy options."
      );
    }
    setModelData({
      ...modelData,
      isFilterLoader: false,
    });

    setIsLoading(false);
    setTermFlag(false);
    setShowApplyModal(false);
    return;
  };

  useEffect(() => {
    if (_.isEmpty(urlParams) && !applicationId) {
      window.location.replace(CONSUMER_PORTAL_URL + "404");
    }
    if (!_.isEmpty(urlParams)) {
      clearUnderWriting();
      getAgentCompanyInfo();
    }

    if (
      _.isEmpty(urlParams) &&
      underwriting &&
      !_.isEmpty(underwriting.carrierWiseLeads) &&
      underwriting.carrierWiseLeads[0].agentAssigned
    ) {
      const whereToRoute = getProcessStep(
        underwriting.resumeProcess,
        underwriting.goalType,
        underwriting.policyType,
        underwriting.productSelection
      );

      props.history.push(whereToRoute);
    }

    let pathUrl = window.location.href;

    if (pathUrl.includes("pacificadvisors")) {
      setPacificadvisorsDomain(true);
      setGoaloption([
        { value: "INSURE", label: "Insurance" },
        { value: "SAVE", label: "Dividend based" },
        { value: "INVEST", label: "Index based" },
      ]);
    } else {
      setPacificadvisorsDomain(false);
    }

    doSubmit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      searchData.yourGoalType.value === "INSURE" &&
      searchData.yourPolicyType.value === "TERM_LIFE_INSURANCE" &&
      searchData.yourInsureBasedOn.value !== "COVERAGE"
    ) {
      setSearchData({
        ...searchData,
        // eslint-disable-next-line no-useless-computed-key
        ["yourInsureBasedOn"]: { value: "COVERAGE", label: "Coverage" },
        // eslint-disable-next-line no-useless-computed-key
        ["yourFrequency"]: { value: "ANNUALLY", label: "Annually" },
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchData]);

  const changeAmountToDefault = () => {
    setSearchData({
      ...searchData,
      // eslint-disable-next-line no-useless-computed-key
      ["yourAmount"]: slideLabels.defaultValue,
    });
  };

  useEffect(() => {
    changeAmountToDefault();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // eslint-disable-next-line react-hooks/exhaustive-deps
    searchData["yourGoalType"],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    searchData["yourPolicyType"],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    searchData["yourFrequency"],
  ]);

  useEffect(() => {
    segmentTracking("application_comparison", {
      agent_id: underwriting?.agentDetail?.agentId,
      application_id: applicationId,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [isProcessing, setIsProcessing] = useState(false);
  const nextStep = async () => {
    if (termFlag) {
      setShowApplyModal(true);
      return;
    }
    setIsProcessing(true);
    const {
      yourGoalType,
      yourPolicyType,
      yourInsureBasedOn,
      yourFrequency,
      yourAmount,
      yourGender,
      yourAge,
      yourDOB,
      // yourPay,
      termLength,
      yourHealthRating,
      isSmoker,
    } = nextStepData;

    const splitpath = window.location.pathname.split("/");

    const request = {
      applicationId: applicationId,
      applicationType: applicationType,
      gender: yourGender && encryptme(yourGender.value),
      frequency: yourFrequency.value,
      healthRating: yourHealthRating.value,
      smoker: JSON.parse(isSmoker.value),
      amount: yourAmount ? yourAmount.toString() : "",
      age: yourAge.toString(),
      dob: yourDOB
        ? await encryptme(moment(yourDOB).format("L").toString())
        : null,
      goalType: yourGoalType.value,
      policyType:
        yourGoalType.value === "INSURE"
          ? yourPolicyType.value
          : "PERMANENT_LIFE_INSURANCE",
      resumeProcess: setProcessStep(splitpath[1], policyType, goalType),
      reference: referenceFrom,
    };
    if (yourGoalType.value === "INSURE")
      request.insureBasedOn = yourInsureBasedOn.value;

    if (yourGoalType.value === "INSURE") {
      request.frequency = "MONTHLY";
    }
    if (yourPolicyType.value === "TERM_LIFE_INSURANCE") {
      request.pay = termLength && termLength.value;
    }
    if (yourPolicyType.value !== "TERM_LIFE_INSURANCE") {
      request.pay = 15;
    }

    if (referalCode.length > 0) request.referalCode = referalCode;

    const isAuthorized = getJwt() ? true : false;

    const apiEndPoint = API_URL_UNDERWRITING + "saveUnderWriting";
    const response = await commonApiCall(
      apiEndPoint,
      "post",
      request,
      UNDERWRITING_DETAILS,
      isAuthorized
    );
    if (response.status.code === 200) {
      let { comparedPlans } = comparisonWith;
      comparedPlans = _.map(comparedPlans, function (object) {
        return _.pick(object, [
          "frequency",
          "yearlyPremium",
          "premium",
          "artrAmount",
          "pay",
          "carrierId",
          "policyType",
          // "productDescription",
          "productId",
          // "productName",
          "viewIllustration",
          "gauranteedDeathBenefit",
          "nonGauranteedDeathBenefit",
        ]);
      });
      const requestParams = {
        applicationId: response.data.applicationId,
        selectedPolicies: comparedPlans,
        // policySuggestionId: id_,
        resumeProcess: setProcessStep(splitpath[1], policyType, goalType),
      };
      const authorization = true;
      const carrierResponse = await commonApiCall(
        API_URL_UNDERWRITING + `saveApplicationCarriers`,
        "post",
        requestParams,
        APPLICATION_CARRIERS_DETAILS,
        authorization
      );
      if (carrierResponse.status.code === 200) {
        setIsProcessing(false);
        let filterLists = [];
        for (let i = 0; i < carrierResponse.data.selectedPolicies.length; i++) {
          filterLists.push({
            policy_id:
              carrierResponse.data.selectedPolicies[i].applicationCarrierId,
            policy_type: carrierResponse.data.selectedPolicies[i].policyType,
          });
        }
        const eventObject = {
          agent_id: response?.data?.agentDetail?.agentId,
          application_id: applicationId,
          policy_details: filterLists,
        };
        segmentTracking("select_content", eventObject);
        props.history.push(UnderWritingRoutes.PLANS);
      }
      setIsProcessing(false);
      const appParams = {
        applicationId: response.data.applicationId,
      };

      const apiEndPoint = API_URL_UNDERWRITING + "getUnderWriting";
      const isAuthorized = getJwt() ? true : false;

      // const underwritingdetails = await commonApiCall(
      //   apiEndPoint,
      //   "post",
      //   appParams,
      //   UNDERWRITING_DETAILS,
      //   isAuthorized
      // );
      await commonApiCall(
        apiEndPoint,
        "post",
        appParams,
        UNDERWRITING_DETAILS,
        isAuthorized
      );

      const domainNamePath = window.location.hostname;
      const domainName = domainNamePath.split(".");
      if (domainName.includes("whiteswan"))
        window.location.href =
          "https://app.whiteswan.io/go_to_app?appId=" +
          response.data.applicationId;
      else props.history.push(UnderWritingRoutes.GUEST_BASIC_DETAILS);
    } else setIsProcessing(false);
  };

  // const getRedirect = async (slug) => {
  //   try {
  //     const { yourGoalType } = searchData;
  //     const splitpath = window.location.pathname.split("/");
  //     const request = {
  //       applicationId: applicationId,
  //       applicationType: applicationType,
  //       goalType: yourGoalType.value,
  //       productSelection: slug,
  //       resumeProcess: setProcessStep(splitpath[1], policyType, goalType),
  //     };
  //     const isAuthorized = getJwt() ? true : false;
  //     const apiEndPoint = API_URL_UNDERWRITING + "saveUnderWriting";
  //     const response = await commonApiCall(
  //       apiEndPoint,
  //       "post",
  //       request,
  //       UNDERWRITING_DETAILS,
  //       isAuthorized
  //     );
  //     if (response.status.code === 200) {
  //       // if (slug === "appliedOnline") {
  //       const token = getJwt();
  //       if (token === "" || token === null) {
  //         props.history.push(UnderWritingRoutes.REGISTER + "/myplans");
  //       } else {
  //         props.history.push(UnderWritingRoutes.MANUAL_FORM);
  //       }
  //       // } else {
  //       //   props.history.push(UnderWritingRoutes.GUEST_BASIC_DETAILS);
  //       // }
  //     }
  //   } catch (err) {
  //     console.log(err);
  //     showErrorToast(t("validation_message.SOMETHING_WENT_WRONG"));
  //   }
  // };

  const { suggestions } = suggestedPlans;
  const { accountOptions, account, coparisionData, comparedPlans } =
    comparisonWith;
  const {
    isDividendSelected,
    dividendCarrier,
    dividendCarrierId,
    illustrationPdf,
    riskOptionSelected,
    analyticsModel,
    cashDividendModal,
    illustrationsModel,
    comparePlansModal,
    isFilterLoader,
  } = modelData;
  return (
    <React.Fragment>
      {/*Begin: PLan List Page */}

      <div className="plan-list planspage">
        {/*Begin: Page Title */}
        <div className="title-header">
          <h3 className="text-center">
            {t("plan_listing.our_database_has_over")}{" "}
            <span className="text-primary">
              {noOfPolicies} {t("plan_listing.policy")}
            </span>{" "}
            {t(
              "plan_listing.options_so_that_you_can_be_sure_to_find_the_right_one_for_your_unique_needs"
            )}
          </h3>
          {/* <h2>
            {t(
              "plan_listing.based_on_your_preferences_here_are_the_best_plans_we_found_for_you"
            )}
          </h2> */}
        </div>
        {/*End: Page Title */}
        {/*Begin: PLan List Form Wrapper */}
        <div className="plan-list-wrapper">
          {/* Begin: Filter Block */}
          <SearchBar
            searchData={searchData}
            goalOptions={goalOptions}
            t={t}
            onTermChange={setTermFlag}
            termLengthOptions={termLengthOptions}
            policyOptions={policyOptions}
            insureBasedOptions={insureBasedOptions}
            frequencyOptions={frequencyOptions}
            slideLabels={slideLabels}
            genderOptions={genderOptions}
            healthOptions={healthOptions}
            smokeOptions={smokeOptions}
            handleChange={handleChange}
            handleBlur={handleBlur}
            doSubmit={doSubmit}
            applyLoading={isLoading}
          />
          {/* <p>
            The estimated rate of this coverage is between{" "}
            <span className="text-primary">${suggestedPlans?.minPremium}</span>{" "}
            and{" "}
            <span className="text-primary">${suggestedPlans?.maxPremium}</span>{" "}
            per month.
          </p> */}

          {/* End: Filter Block */}
          {/* Begin: Plan List Block */}
          <div className="plan-list-wrapper">
            {/* Begin: Suggested Plan */}
            <PlanListing
              isFilterLoader={isFilterLoader}
              nextStepData={nextStepData}
              t={t}
              suggestions={suggestions}
              coparisionData={coparisionData}
              comparedPlans={comparedPlans}
              isDividendSelected={isDividendSelected}
              accountOptions={accountOptions}
              account={account}
              handleComparePlans={handleComparePlans}
              setDividendModel={setDividendModel}
              handleIllustration={handleIllustration}
              getAnalytics={getAnalytics}
              handleComparision={handleComparision}
              handleModel={handleModel}
              nextStep={nextStep}
              comparisonPlans={comparisonPlan}
              isProcessing={isProcessing}
              applyLoading={isLoading}
            />
            {/* End: Compare Plan */}
          </div>
          {/* End: Plan List Block */}
        </div>
        {/*End: PLan List Form Wrapper */}
      </div>
      {analyticsModel && (
        <AnalyticsModel
          modelData={modelData}
          comparisonWith={comparisonWith}
          insureData={insureData}
          t={t}
          setAnalytics={setAnalytics}
          handleComparision={handleComparision}
          handleModel={handleModel}
          suggestions={suggestions}
        />
      )}
      {/*End: PLan List Page */}
      {suggestedPlans && cashDividendModal && (
        <DividendGraphModel
          isDividendSelected={isDividendSelected}
          dividendCarrierId={dividendCarrierId}
          dividendCarrier={dividendCarrier}
          t={t}
          cashDividendModal={cashDividendModal}
          comparisonWithOptions={suggestedPlans.comparisonWith}
          dividends={suggestedPlans.dividends}
          handleModel={handleModel}
        ></DividendGraphModel>
      )}

      {illustrationsModel && (
        <IllustrationModel
          illustrationsModel={illustrationsModel}
          illustrationPdf={illustrationPdf}
          t={t}
          handleModel={handleModel}
        ></IllustrationModel>
      )}

      {comparePlansModal && (
        <ComparePlanModel
          comparePlansModal={comparePlansModal}
          comparedPlans={comparisonPlan}
          suggestions={suggestions}
          t={t}
          frequency={suggestedPlans.frequency}
          handleModel={handleModel}
          searchData={searchData}
        ></ComparePlanModel>
      )}

      <Modal
        centered
        className="apply-modal"
        show={showApplyModal}
        onHide={handleApplyModal}
        animation={false}
      >
        <Modal.Body>
          <h4>Update Selection</h4>
          <p>
            Your selections have changed. Apply Filters to update selections.
          </p>
          <div className="text-center mt-3">
            <Button
              disabled={isLoading}
              type="btn"
              variant="primary"
              className="filter-btn "
              onClick={() => doSubmit()}
            >
              {isLoading ? "Processing..." : t("plan_listing.apply_filters")}
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};
const mapStateToProps = (state) => ({
  underwriting: state.underwriting.underwriting,
});

export default connect(mapStateToProps, {
  commonApiCall,
  clearUnderWriting,
  applicationCarriersInfo,
})(withRouter(PlanSuggestion));
