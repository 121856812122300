import React from "react";
import { Modal } from "react-bootstrap";
import Select from "react-select";
import * as _ from "lodash";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Text,
} from "recharts";
import { NumberFormatter } from "../../utils/index";

export const AnalyticsModel = ({
  modelData,
  comparisonWith,
  insureData,
  setAnalytics,
  handleComparision,
  handleModel,
  t,
}) => {
  const {
    analyticsModel,
    analyticsCarrier,
    analyticsCarrierId,
    riskOptionSelected,
    analytics,
    isAdjustTax,
    productName,
  } = modelData;

  const { selectedInstrumentAnalytics, instrumentOptionsForAnalytics } =
    comparisonWith;

  const { yourInsure, termInsure, amountAtRisk } = insureData;
  const CustomizedLabel = ({ labelName, ...rest }) => {
    return (
      <Text {...rest} textAnchor="start">
        {labelName}
      </Text>
    );
  };

  const graphValueFormatter = (item) => NumberFormatter(item, "", "%");
  const graphValueFormatterForRiskAdjust = (item) =>
    NumberFormatter(item, "", "");

  const riskReturnOptions = [
    { value: "riskAdjust", label: "Risk Adjusted Return" },
    { value: "riskVolatility", label: "Risk & Volatility History" },
    { value: "insureSavings", label: "Term vs Perm Comparison" },
  ];

  const hancockOptions = [
    { value: "insureSavings", label: "Term vs Perm Comparison" },
  ];

  return (
    <Modal
      className="plan-modal analytics-modal"
      show={analyticsModel}
      onHide={() => handleModel("analyticsModel", false)}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {t("plan_listing.advanced_analytics")} -{" "}
          <span>{analyticsCarrier}</span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="risk-return">
          <div className="default-select with-border">
            <Select
              value={
                productName === "Protection UL" &&
                analyticsCarrier === "John Hancock"
                  ? hancockOptions
                  : riskOptionSelected
              }
              onChange={(e) =>
                setAnalytics(
                  "",
                  analyticsCarrier,
                  analyticsCarrierId,
                  e,
                  instrumentOptionsForAnalytics.value
                )
              }
              options={
                productName === "Protection UL" &&
                analyticsCarrier === "John Hancock"
                  ? hancockOptions
                  : riskReturnOptions
              }
              className={"custom-select-menu"}
              classNamePrefix={"custom-select"}
            />
          </div>
          {riskOptionSelected.value === "riskAdjust" ||
          riskOptionSelected.value === "riskVolatility" ? (
            <div className="default-select with-border">
              <Select
                value={
                  analyticsCarrierId === 3 || analyticsCarrierId === 4
                    ? {
                        value: 2,
                        label: "S&P 500",
                      }
                    : selectedInstrumentAnalytics
                }
                onChange={(e) => {
                  return (
                    handleComparision(e, "AdvanceAnalytics"),
                    setAnalytics(
                      "",
                      analyticsCarrier,
                      analyticsCarrierId,
                      riskOptionSelected,
                      e.value
                    )
                  );
                }}
                isDisabled={
                  analyticsCarrierId === 3 || analyticsCarrierId === 4
                    ? true
                    : false
                }
                options={instrumentOptionsForAnalytics}
                className={"custom-select-menu"}
                classNamePrefix={"custom-select"}
              />
            </div>
          ) : riskOptionSelected.value === "insureSavings" &&
            !_.isEmpty(termInsure) ? (
            <h3>
              {t("plan_listing.your_selection_vs_term_insurance_for")}{" "}
              {NumberFormatter(
                termInsure["age10"]["gauranteed_death_benefit"],
                "$"
              )}{" "}
              {t("plan_listing.policy_coverage")}
            </h3>
          ) : (
            ""
          )}
        </div>
        {riskOptionSelected.value === "riskAdjust" ||
        riskOptionSelected.value === "riskVolatility" ? (
          <React.Fragment>
            <div className="chart-outer">
              <LineChart width={922} height={300} data={analytics}>
                <Legend />
                <XAxis
                  dataKey="Year"
                  label={
                    <CustomizedLabel
                      labelName={"Year"}
                      x={450}
                      y={285}
                      dx={0}
                      dy={0}
                      width={180}
                      transform=""
                    />
                  }
                />
                <YAxis
                  label={
                    <CustomizedLabel
                      labelName={
                        riskOptionSelected.value === "riskAdjust"
                          ? "Sharpe Ratio"
                          : "Return Variation"
                      }
                      x={0}
                      y={0}
                      dx={-180}
                      dy={12}
                      width={180}
                      transform={"rotate(-90)"}
                    />
                  }
                />
                <CartesianGrid linearray="1 1" />
                <Tooltip
                  formatter={
                    riskOptionSelected.value === "riskAdjust"
                      ? graphValueFormatterForRiskAdjust
                      : graphValueFormatter
                  }
                />
                <Line
                  type="monotone"
                  dataKey={analyticsCarrier}
                  stroke="#BC00A3"
                />
                <Line
                  type="monotone"
                  dataKey={
                    analyticsCarrierId === 3 || analyticsCarrierId === 4
                      ? "S&P 500"
                      : selectedInstrumentAnalytics.label
                  }
                  stroke="#2CA851"
                />
              </LineChart>
            </div>
            {analyticsCarrier !== "Mass Mutual" && (
              <span className="custom-checkbox">
                <label className="m-0">
                  <input
                    type="checkbox"
                    name="isAdjustTax"
                    id="isAdjustTax"
                    value={isAdjustTax}
                    onChange={(e) =>
                      setAnalytics(
                        "",
                        analyticsCarrier,
                        analyticsCarrierId,
                        riskOptionSelected,
                        instrumentOptionsForAnalytics.value,
                        e.target
                      )
                    }
                  />
                  <span></span> {t("plan_listing.adjust_for_tax")}
                </label>
              </span>
            )}
            {riskOptionSelected.value === "riskAdjust" && (
              <React.Fragment>
                <div className="notes">
                  <b>{t("plan_listing.note")}:</b>{" "}
                  {t(
                    "plan_listing.sharpe_ratio_is_the_return_after_adjusting_for_investment_risk"
                  )}
                </div>
              </React.Fragment>
            )}
          </React.Fragment>
        ) : riskOptionSelected.value === "insureSavings" ? (
          <React.Fragment>
            {!_.isEmpty(termInsure) ? (
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th>{t("plan_listing.year")}</th>
                      <th>{t("plan_listing.term_insurance")}</th>
                      <th>{t("plan_listing.your_selection")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Object.keys(yourInsure).map(function (key) {
                      return (
                        <tr key={key}>
                          <td>
                            {key === "age10"
                              ? 10
                              : key === "age15"
                              ? 15
                              : key === "age20"
                              ? 20
                              : key === "age30"
                              ? 30
                              : key === "age40"
                              ? 40
                              : ""}
                          </td>
                          <td>
                            {NumberFormatter(
                              termInsure[key]["total_deposit"],
                              termInsure[key]["total_deposit"] !== 0
                                ? "-$"
                                : "$"
                            )}

                            <ul>
                              <li>
                                <h5 className="d-flex">
                                  <span className="min-width">
                                    {t("plan_listing.total_premium")}:{" "}
                                  </span>
                                  <span className="term-value">
                                    {NumberFormatter(
                                      termInsure[key]["total_deposit"],
                                      termInsure[key]["total_deposit"] < 0
                                        ? "-$"
                                        : "$"
                                    )}
                                  </span>
                                </h5>
                              </li>
                              <li>
                                <h5 className="d-flex">
                                  <span className="min-width">
                                    {t("plan_listing.cash_value_account")}:
                                  </span>{" "}
                                  <span className="term-value">
                                    {t("not_applicable")}
                                  </span>
                                </h5>
                              </li>
                              {analyticsCarrier !== "Mass Mutual" && (
                                <li>
                                  <h5 className="d-flex">
                                    <span className="min-width">
                                      {t("plan_listing.tax_free_gains")}:
                                    </span>{" "}
                                    <span className="term-value">
                                      {t("not_applicable")}
                                    </span>
                                  </h5>
                                </li>
                              )}
                              <li>
                                <h5 className="d-flex">
                                  <span className="min-width">
                                    {t("plan_listing.insurance_coverage")}:{" "}
                                  </span>
                                  <span className="term-value">
                                    {key === "age10"
                                      ? NumberFormatter(
                                          termInsure[key][
                                            "gauranteed_death_benefit"
                                          ],
                                          "$"
                                        )
                                      : key === "age15"
                                      ? NumberFormatter(
                                          termInsure[key][
                                            "gauranteed_death_benefit"
                                          ],
                                          "$"
                                        )
                                      : "N/A"}{" "}
                                  </span>
                                </h5>
                              </li>
                            </ul>
                          </td>
                          <td>
                            {NumberFormatter(
                              yourInsure[key]["non_guaranteed_tax_free_gain"],
                              yourInsure[key]["non_guaranteed_tax_free_gain"] >
                                0
                                ? "+$"
                                : "$"
                            )}
                            <ul>
                              <li>
                                <h5 className="d-flex">
                                  <span className="min-width">
                                    {t("plan_listing.total_premium")}:
                                  </span>
                                  <span className="term-value">
                                    {NumberFormatter(
                                      yourInsure[key]["total_deposit"],
                                      "$"
                                    )}
                                  </span>
                                </h5>
                              </li>
                              <li>
                                <h5 className="d-flex">
                                  <span className="min-width">
                                    {t("plan_listing.cash_value_account")}:
                                  </span>
                                  <span className="term-value">
                                    {NumberFormatter(
                                      yourInsure[key][
                                        "non_gauranteed_cash_value"
                                      ],
                                      "$"
                                    )}
                                  </span>
                                </h5>
                              </li>
                              {analyticsCarrier !== "Mass Mutual" && (
                                <li>
                                  <h5 className="d-flex">
                                    <span className="min-width">
                                      {t("plan_listing.tax_free_gains")}:
                                    </span>
                                    <span className="term-value">
                                      {NumberFormatter(
                                        yourInsure[key][
                                          "non_guaranteed_tax_free_gain"
                                        ],
                                        "$"
                                      )}
                                    </span>
                                  </h5>
                                </li>
                              )}
                              <li>
                                <h5 className="d-flex">
                                  <span className="min-width">
                                    {t("plan_listing.insurance_coverage")}:{" "}
                                  </span>
                                  <span className="term-value">
                                    {NumberFormatter(
                                      yourInsure[key][
                                        "non_gauranteed_death_benefit"
                                      ],
                                      "$"
                                    )}
                                  </span>
                                </h5>
                              </li>
                            </ul>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            ) : (
              <div className="no-plan">
                {t(
                  "plan_listing.term_plan_is_not_applicable_for_this_selection"
                )}
              </div>
            )}
            {analyticsCarrier === "Mass Mutual" && (
              <React.Fragment>
                <div className="notes">
                  <b>{t("plan_listing.note")}: </b>
                  {t(
                    "plan_listing.cash_value_for_standard_design_of_massmutual_10_pay_legacy_is_not_tax_free"
                  )}
                </div>
              </React.Fragment>
            )}
          </React.Fragment>
        ) : (
          <div className="table-responsive">
            <table className="table risk-table">
              <thead>
                <tr>
                  <th>Year</th>
                  {analyticsCarrierId !== 3 && analyticsCarrierId !== 4 && (
                    <th>
                      {t("plan_listing.based_on_the_minimum_guaranteed_amount")}
                    </th>
                  )}
                  <th>{t("plan_listing.based_on_the_projected_amount")}</th>
                </tr>
              </thead>
              <tbody>
                {Object.keys(amountAtRisk).map(function (key) {
                  return (
                    <tr key={key}>
                      <td>{amountAtRisk[key]["year"]}</td>
                      {analyticsCarrierId !== 3 && analyticsCarrierId !== 4 && (
                        <td>
                          {NumberFormatter(
                            amountAtRisk[key]["gauranteed_amount"],
                            "$"
                          )}
                        </td>
                      )}

                      <td>
                        {NumberFormatter(
                          amountAtRisk[key]["non_gauranteed_amount"],
                          "$"
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};
