import React, { useState, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Button, Modal, Form } from "react-bootstrap";
import { Animated } from "react-animated-css";
import OtpInput from "react-otp-input";
import NumberFormat from "react-number-format";
import { isEmpty, upperFirst } from "lodash";
// import ReCAPTCHA from "react-google-recaptcha";
import {
  commonApiCall,
  CONSUMER_DETAILS,
  profilePicture,
  setJwt,
} from "../redux/actions/index";
import {
  encryptme,
  validateName,
  validateEmail,
  validateMobileNumber,
  UnderWritingRoutes,
  DashboardRoute,
  requireAuth,
  decryptme,
  showErrorToast,
  showSuccessToast,
  setItem,
  segmentTracking,
} from "../utils/index";
import { UseInterval } from "../hooks/index";
import { API_URL_CLIENT } from "../config/configs";
import { AgreementModel } from "../components/underWritingModels";

const Register = ({
  commonApiCall,
  profilePicture,
  underwriting,
  applicationCarrierInfo,
  t,
  ...props
}) => {
  const router = props.match.params;
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    mobileNumber: "",
    countryCode: "+1",
    otp: "",
    // isAgree: false,
    // isRecaptchaChecked: false,
    verificationModal: false,
    isRegister: false,
    counter: 0,
    errors: {},
    agreementModel: false,
  });
  const [isTextMessage, setIsTextMessage] = useState(false);
  useEffect(() => {
    async function doSubmit() {
      if (
        underwriting &&
        underwriting.productSelection === "professionalHelp"
      ) {
        formData.firstName = underwriting.firstName
          ? await decryptme(underwriting.firstName)
          : "";
        formData.lastName = underwriting.lastName
          ? await decryptme(underwriting.lastName)
          : "";
        formData.email = underwriting.email
          ? await decryptme(underwriting.email)
          : "";
        formData.mobileNumber = underwriting.mobile
          ? await decryptme(underwriting.mobile)
          : "";
      }
      setFormData({
        ...formData,
        formData,
      });
    }
    doSubmit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (requireAuth() && !isEmpty(router.name)) {
    props.history.push(UnderWritingRoutes.PLANS);
  }

  UseInterval(
    () => {
      setFormData({ ...formData, counter: formData.counter - 1 });
    },
    formData.counter > 0 ? 1000 : null
  );

  const handleChange = (e) => {
    if (e.target.value) {
      setFormData({
        ...formData,
        errors: Object.assign(formData.errors, { [e.target.name]: "" }),
      });
    }
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // const handleCheckbox = (e) => {
  //   if (e.target.value) {
  //     setFormData({
  //       ...formData,
  //       errors: Object.assign(formData.errors, { [e.target.name]: "" }),
  //     });
  //   }
  //   const isChecked = formData.isAgree ? false : true;
  //   setFormData({ ...formData, [e.target.name]: isChecked });
  // };

  const handleClose = (modelName) => {
    setFormData({ ...formData, [modelName]: false });
  };

  const handleModel = (name, isHide) => {
    setFormData({ ...formData, [name]: isHide });
  };

  const verifyOtp = async (e) => {
    e.preventDefault();
    const { mobileNumber, countryCode, otp } = formData;

    if (validateOtp()) {
      const request = {
        loginType: "MOBILE",
        countryCode: await encryptme(countryCode),
        value: await encryptme(mobileNumber),
        otp: otp,
        role: "ROLE_CLIENT",
        smsVerify: true,
        isRegister: formData.isRegister,
        applicationId: underwriting?.applicationId,
      };

      const apiEndPoint = API_URL_CLIENT + "signIn";
      const response = await commonApiCall(
        apiEndPoint,
        "post",
        request,
        CONSUMER_DETAILS
      );
      if (response.status.code === 200) {
        localStorage.setItem("clDetail", JSON.stringify(response.data));
        setJwt(response.data.token);
        const reduxStore = {
          profilePic: response.data.profilePic,
          email: await decryptme(response.data.email),
          firstName: await decryptme(response.data.firstName),
          lastName: await decryptme(response.data.lastName),
        };
        await profilePicture(reduxStore);
        if (applicationCarrierInfo?.selectedPolicies && response.data.id) {
          let filterLists = [];
          let liaison_type =
            underwriting?.productSelection === "appliedOnline"
              ? "self"
              : "agent";
          for (
            let i = 0;
            i < applicationCarrierInfo.selectedPolicies.length;
            i++
          ) {
            filterLists.push({
              policy_id:
                applicationCarrierInfo.selectedPolicies[i].applicationCarrierId,
              policy_type:
                applicationCarrierInfo.selectedPolicies[i].policyType,
            });
          }
          const eventObject = {
            agent_id: underwriting?.agentDetail?.agentId,
            application_id: underwriting.applicationId,
            policy_details: filterLists,
            client_id: response.data.id,
            liaison_type: liaison_type,
          };
          segmentTracking("prospect_create", eventObject);
        }

        if (!isEmpty(router.name)) {
          setItem("isFromUnderwritingProcess", true);
          props.history.push(UnderWritingRoutes.GUEST_BASIC_DETAILS);
        } else {
          props.history.push(DashboardRoute.DASHBOARD);
        }
      } else {
        showErrorToast(response.status.message);
      }
    }
  };

  const sendOtp = async () => {
    const { mobileNumber, countryCode } = formData;
    if (validation()) {
      const request = {
        countryCode: await encryptme(countryCode),
        mobile: await encryptme(mobileNumber),
        role: "ROLE_CLIENT",
      };
      const apiEndPoint = API_URL_CLIENT + "sendOtpViaSms";
      const response = await commonApiCall(apiEndPoint, "post", request, null);
      if (response.status.code === 200) {
        setFormData({ ...formData, counter: 30 });
        if (response.data && response.data.otp && response.data.otp !== "")
          showSuccessToast(
            "Please use this code to register:" + response.data.otp + " !"
          );
        else showSuccessToast(t("validation_message.successOtp"));
      } else {
        showErrorToast(response.status.message);
      }
    }
  };

  const doSubmit = async () => {
    let { firstName, lastName, email, countryCode } = formData;
    if (validation()) {
      // setFormData({
      //   ...formData,
      //   isAgree: false,
      // });
      let request = {
        firstName: await encryptme(upperFirst(firstName)),
        lastName: await encryptme(upperFirst(lastName)),
        email: await encryptme(email),
        mobile: await encryptme(mobileNumber),
        countryCode: await encryptme(countryCode),
        type: "REGISTERED",
        applicationId:
          underwriting && underwriting.applicationId
            ? underwriting.applicationId
            : "",
        isMarketingCommunication: true,
        isTextMessage: isTextMessage,
      };
      const apiEndPoint = API_URL_CLIENT + "saveUserClient";
      const response = await commonApiCall(
        apiEndPoint,
        "post",
        request,
        null,
        false,
        true
      );
      if (response.status.code === 200) {
        if (response.data && response.data.otp && response.data.otp !== "")
          showSuccessToast(
            "Please use this code to register:" + response.data.otp + " !"
          );
        else showSuccessToast(t("validation_message.successOtp"));

        setFormData({
          ...formData,
          otp: "",
          verificationModal: true,
          // isAgree: true,
          isRegister: response.data.isRegister,
        });
      } else {
        // setFormData({
        //   ...formData,
        //   isAgree: true,
        // });
        showErrorToast(response.status.message);
      }
    }
  };

  const validateOtp = () => {
    const { otp } = formData;
    let errors = {},
      isValid = true;
    if (!otp) {
      isValid = false;
      errors["otp"] = "Please enter login code";
    } else if (typeof otp !== "undefined") {
      if (otp.toString().length < 6) {
        isValid = false;
        errors["otp"] = "Please enter valid login code";
      }
    }

    setFormData({ ...formData, errors: errors });
    return isValid;
  };

  const validation = () => {
    const { firstName, lastName, email, mobileNumber } = formData;
    let errors = {};
    let isFormValid = true;

    if (formData.hasOwnProperty("firstName")) {
      if (isEmpty(firstName)) {
        errors.firstName = t("validation_message.PROVIDE_FIRST_NAME");
        isFormValid = false;
      } else if (!validateName(firstName)) {
        errors.firstName = t("validation_message.PROVIDE_VALID_FIRST_NAME");
        isFormValid = false;
      } else delete errors.firstName;
    }

    if (formData.hasOwnProperty("lastName")) {
      if (isEmpty(lastName)) {
        errors.lastName = t("validation_message.PROVIDE_LAST_NAME");
        isFormValid = false;
      } else if (!validateName(lastName)) {
        errors.lastName = t("validation_message.PROVIDE_VALID_LAST_NAME");
        isFormValid = false;
      } else delete errors.lastName;
    }

    if (formData.hasOwnProperty("email")) {
      if (isEmpty(email)) {
        errors.email = t("validation_message.PROVIDE_EMAIL");
        isFormValid = false;
      } else if (!validateEmail(email)) {
        errors.email = t("validation_message.PROVIDE_VALID_EMAIL");
        isFormValid = false;
      } else delete errors.email;
    }

    if (formData.hasOwnProperty("mobileNumber")) {
      if (isEmpty(mobileNumber)) {
        errors.mobileNumber = t("validation_message.PROVIDE_MOBILE_NUMBER");
        isFormValid = false;
      } else if (
        !validateMobileNumber(mobileNumber) &&
        mobileNumber.toString().length !== 10
      ) {
        errors.mobileNumber = t(
          "validation_message.PROVIDE_VALID_MOBILE_NUMBER"
        );
        isFormValid = false;
      } else delete errors.mobileNumber;
    }
    // if (!isAgree) isFormValid = false;
    // else isFormValid = Object.keys(errors).length !== 0 ? false : true;
    setFormData({ ...formData, errors: errors });
    return isFormValid;
  };

  // const handleRecaptcha = (value) => {
  //   if (value) {
  //     setFormData({ ...formData, isRecaptchaChecked: true });
  //   }
  // };

  const {
    firstName,
    lastName,
    email,
    mobileNumber,
    // isAgree,
    otp,
    verificationModal,
    counter,
    errors,
    agreementModel,
    // isRecaptchaChecked,
  } = formData;
  return (
    <React.Fragment>
      {/*Begin: Sign Up Page */}
      <div className="signup">
        <div className="row justify-content-center">
          <div className="col-lg-8">
            {/*Begin: Page Title */}
            <div className="title-header">
              <h2>{t("register.you_re_almost_there")}</h2>
              <h4>
                {t("register.create_your_free_account_to_finish_applying")}
              </h4>
            </div>
            {/*End: Page Title */}
            {/*Begin: Sign Up Form Wrapper */}
            <div className="signup-wrapper">
              {/*Begin: Sign Up Form with Animate.css Element */}
              <Animated
                animationIn="fadeInRight"
                animationOut="fadeOutLeft"
                animationInDuration={800}
                animationOutDuration={800}
                isVisible={true}
              >
                <div className="form-wrapper">
                  <form className="row">
                    <div className="col-md-6">
                      {/*Begin: Form Group - First Name */}
                      <div className="form-group">
                        <div className="input-effect with-icon">
                          <input
                            className="form-control"
                            placeholder="First Name"
                            name="firstName"
                            id="firstName"
                            type="text"
                            value={firstName}
                            onChange={(e) => handleChange(e)}
                          />
                          <label htmlFor="firstName">
                            {t("register.first_name")}
                          </label>
                          <em className="input-icon"></em>
                        </div>
                        {errors.firstName && (
                          <small className="text-danger err-msg">
                            {errors.firstName}
                          </small>
                        )}
                      </div>
                      {/*End: Form Group - First Name */}
                    </div>
                    <div className="col-md-6">
                      {/*Begin: Form Group - Last Name */}
                      <div className="form-group">
                        <div className="input-effect with-icon">
                          <input
                            className="form-control"
                            placeholder="Last Name"
                            name="lastName"
                            id="lastName"
                            type="text"
                            value={lastName}
                            onChange={(e) => handleChange(e)}
                          />
                          <label htmlFor="lastName">
                            {t("register.last_name")}
                          </label>
                          <em className="input-icon"></em>
                        </div>
                        {errors.lastName && (
                          <small className="text-danger err-msg">
                            {errors.lastName}
                          </small>
                        )}
                      </div>
                      {/*End: Form Group - Last Name */}
                    </div>
                    <div className="col-md-6">
                      {/*Begin: Form Group - Email */}
                      <div className="form-group">
                        <div className="input-effect with-icon">
                          <input
                            className="form-control"
                            placeholder="Email"
                            name="email"
                            id="email"
                            type="mail"
                            value={email}
                            onChange={(e) => handleChange(e)}
                          />
                          <label htmlFor="email">{t("register.email")}</label>
                          <em className="input-icon"></em>
                        </div>
                        {errors.email && (
                          <small className="text-danger err-msg">
                            {errors.email}
                          </small>
                        )}
                      </div>
                      {/*End: Form Group - Last Name */}
                    </div>
                    <div className="col-md-6">
                      {/*Begin: Form Group - Mobile */}
                      <div className="form-group">
                        <div className="input-effect with-icon">
                          <NumberFormat
                            name="mobileNumber"
                            id="mobileNumber"
                            className="form-control"
                            placeholder="Mobile number"
                            format="(###) ###-####"
                            value={mobileNumber}
                            onValueChange={(e) =>
                              handleChange({
                                target: {
                                  name: "mobileNumber",
                                  value: e.value,
                                },
                              })
                            }
                          />
                          <label htmlFor="mobile">
                            {t("register.mobile_number")}
                          </label>
                        </div>
                        {errors.mobileNumber && (
                          <small className="text-danger err-msg">
                            {errors.mobileNumber}
                          </small>
                        )}
                        <h6 className="mt-2 text-default">
                          {t("guest_sign_up.mobile_lable_text")}
                        </h6>
                      </div>
                      {/*End: Form Group - Mobile */}
                    </div>

                    <div className="col-12">
                      <div className="form-group">
                        <p className="pb-0">
                          <span className="custom-checkbox">
                            <label className="m-0 text-default">
                              <input
                                type="checkbox"
                                name="isTextMessage"
                                checked={isTextMessage}
                                onChange={(e) =>
                                  setIsTextMessage(e.target.checked)
                                }
                              />
                              <span></span> By checking this box, I consent to
                              receiving my agent’s texts related to my life
                              insurance policy*
                            </label>
                          </span>
                        </p>
                        <p className=" pb-0 text-default mt-2">
                          *The number of texts received per month may vary.
                          Message and data rates may apply. Reply STOP to
                          cancel.
                        </p>
                      </div>
                    </div>

                    {/* {reCAPTCHA} */}
                    {/* <ReCAPTCHA
                      sitekey={process.env.REACT_APP_RECAPTCHA_ID}
                      onChange={handleRecaptcha}
                      type="image"
                      className="recaptcha"
                      onExpired={() =>
                        setFormData({ ...formData, isRecaptchaChecked: false })
                      }
                    /> */}
                    <div className="col-12 mb-5">
                      <div className="text-center">
                        <Button
                          className="btn btn-primary btn-lg"
                          onClick={() => doSubmit()}
                          // disabled={!isAgree}
                        >
                          {!isEmpty(router.name) ? t("continue") : t("signUp")}
                        </Button>
                      </div>
                    </div>
                    <div className="col-12">
                      {/*Begin: Form Group - Terms and Policy */}
                      <div className="form-group">
                        <p className="text-center pb-0">
                          {/*Begin: Form Group - Custom Checkbox */}
                          <span className="custom-checkbox">
                            <label className="m-0">
                              {/* <input
                                type="checkbox"
                                name="isAgree"
                                onChange={(e) => handleCheckbox(e)}
                              /> */}
                              <span></span> By clicking Sign Up you agree to
                            </label>
                          </span>
                          {/*End: Form Group - Custom Checkbox */}
                          <Link
                            to="#"
                            className="text-link font-weight-normal"
                            title="Terms of Service"
                            onClick={() => handleModel("agreementModel", true)}
                          >
                            iLife’s Terms of Service
                          </Link>{" "}
                          and{""}
                          <Link
                            to="//ilife.tech/privacy-policy"
                            className="text-link font-weight-normal"
                            target="_blank"
                          >
                            Privacy Policy.
                          </Link>
                        </p>
                      </div>
                      {/*End: Form Group - Terms and Policy */}
                    </div>
                  </form>
                </div>
              </Animated>
              {/*End: Sign Up Form with Animate.css Element */}
              {/*Begin: Login Page Link */}
              <div className="not-member">
                <p className="pb-0">
                  {t("register.already_have_an_account")}
                  {!isEmpty(router.name) ? (
                    <Link
                      to={UnderWritingRoutes.LOGIN + "/myplans"}
                      className="text-link"
                      title="Sign Up"
                    >
                      {t("register.log_in")}
                    </Link>
                  ) : (
                    <Link
                      to={UnderWritingRoutes.LOGIN}
                      className="text-link ml-2"
                      title="Login"
                    >
                      {t("register.log_in")}
                    </Link>
                  )}
                </p>
              </div>

              {!isEmpty(router.name) && (
                <div className="col-12 text-center mt-3">
                  <Link
                    to={UnderWritingRoutes.LOGIN_AGENT_CHAT}
                    className="btn btn-link"
                    title="Back"
                  >
                    Back
                  </Link>
                </div>
              )}
              {/*End: Login Page Link */}
            </div>
            {/*End: Sign Up Form Wrapper */}
          </div>
        </div>
      </div>
      {/*End: Sign Up Form Wrapper */}
      <Modal
        className="plan-modal verification-modal"
        show={verificationModal}
        onHide={() => handleClose("verificationModal")}
        centered
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("register.verify_your_mobile_number")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="signup-otp">
            <Animated
              animationIn="fadeInRight"
              animationOut="fadeOutLeft"
              animationInDuration={800}
              animationOutDuration={800}
              isVisible={true}
            >
              <div className="form-wrapper">
                <div className="form-text">
                  <h3 className="card-text mb-5 text-center">
                    {t("register.we’ve_sent_an_otp_to")}{" "}
                    <span className="text-link">
                      <NumberFormat
                        format="### ### ####"
                        value={mobileNumber}
                        displayType={"text"}
                        renderText={(value) => <span>{value}</span>}
                      />
                    </span>{" "}
                    {t(
                      "register.to_verify_your_account_please_enter_six_digit_code_below"
                    )}
                  </h3>
                </div>
                <Form
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      verifyOtp(e);
                    }
                  }}
                >
                  <div className="otp-input-wrapper">
                    {/*Begin: Form Group - Mobile */}
                    <OtpInput
                      onChange={(otp) =>
                        handleChange({
                          target: { name: "otp", value: otp },
                        })
                      }
                      numInputs={6}
                      inputStyle={"otp-input"}
                      value={otp}
                      isInputNum={true}
                    />
                    {/*End: Form Group - Mobile */}
                    {errors.otp && (
                      <small className="text-danger err-msg">
                        {errors.otp}
                      </small>
                    )}
                  </div>
                  {/*Begin: Form Group - Terms and Policy */}
                  <div className="form-group">
                    <p className="text-center pb-0">
                      {t("register.didnt_receive_code_yet")}
                      {counter === 0 ? (
                        <button
                          className="btn btn-link text-primary"
                          type="button"
                          onClick={sendOtp}
                          style={{ textTransform: "none" }}
                        >
                          {t("register.resend_otp")}
                        </button>
                      ) : (
                        <span className="btn btn-link text-primary">
                          ({counter})
                        </span>
                      )}
                    </p>
                  </div>
                  {/*End: Form Group - Terms and Policy */}
                  {/*Begin: Sign Up Button */}
                  <div className="text-center">
                    <button
                      className="btn btn-primary btn-lg"
                      type="button"
                      onClick={(e) => verifyOtp(e)}
                    >
                      {t("submit")}
                    </button>
                  </div>
                  {/*End: Sign Up Button */}
                </Form>
              </div>
            </Animated>
          </div>
        </Modal.Body>
      </Modal>
      {agreementModel && (
        <AgreementModel
          agreementModel={agreementModel}
          t={t}
          handleModel={handleModel}
        ></AgreementModel>
      )}
      {/*End: Sign Up Page */}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  underwriting: state.underwriting.underwriting,
  applicationCarrierInfo: state.underwriting.applicationCarrierInfo,
});

export default connect(mapStateToProps, { commonApiCall, profilePicture })(
  withRouter(Register)
);
