import React, { useEffect, useState } from "react";
import Slider from "react-rangeslider";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { SelectDatepicker } from "react-select-datepicker";
import {
  commonApiCall,
  getJwt,
  UNDERWRITING_DETAILS,
} from "../redux/actions/index";
import {
  calcAge,
  calcAgeOnMonth,
  decryptme,
  encryptme,
  getItem,
  getProcessStep,
  getSliderVariables,
  getState,
  removeItem,
  segmentTracking,
  setCjeCookie,
  setProcessStep,
  showErrorToast,
  UnderWritingRoutes,
} from "../utils";
import { isEmpty } from "lodash";

import { API_URL_UNDERWRITING, CONSUMER_PORTAL_URL } from "../config/configs";
import moment from "moment";
import NumberFormat from "react-number-format";

const SinglePageApp = ({
  underwriting,
  commonApiCall,
  company_information,
  direct_link,
  t,
  ...props
}) => {
  const clickId = new URLSearchParams(window.location.search).get("clickid");
  if (clickId) {
    setCjeCookie("clickid", clickId);
  }

  const [maxDateOfCalendar, setMaxDateOfCalendar] = useState(null);
  const [minDateOfCalendar, setMinDateOfCalendar] = useState(null);
  const [slideLabels, setSlideLabels] = useState(
    underwriting?.goalType === "INSURE"
      ? getSliderVariables(
          underwriting?.goalType,
          underwriting?.amount,
          underwriting?.policyType
        )
      : getSliderVariables(
          underwriting?.frequency,
          underwriting?.amount,
          underwriting?.policyType
        )
  );

  const horizontalLabels = slideLabels.labels;

  const [formData, setFormData] = useState({
    value_type: true,
    applicationId: underwriting?.applicationId,
    yourAge: underwriting?.age ? underwriting?.age : "0",
    yourDOB: underwriting?.dob ? new Date(decryptme(underwriting?.dob)) : null,
    yourHealth: underwriting?.healthRating ? underwriting?.healthRating : "",
    applicationType: underwriting?.applicationType
      ? underwriting?.applicationType
      : "Web",
    depositAmount: slideLabels.defaultValue ? slideLabels.defaultValue : "",
    zipCode: underwriting?.postalCode ? underwriting?.postalCode : "",
    isSmoker: underwriting?.smoker === null ? null : underwriting?.smoker,
    yourGender: underwriting?.gender ? decryptme(underwriting?.gender) : "",
    yourFrequency: underwriting?.frequency ? underwriting?.frequency : "",
    yourTermLength: underwriting?.termLength ? underwriting?.termLength : null,
    errors: {},
    citizenShip: underwriting?.citizenship ? underwriting?.citizenship : "",
    medicarePolicyFlag:
      underwriting?.medicarePolicyFlag !== null
        ? underwriting?.medicarePolicyFlag
        : "",
  });
  const [isProcessing, setIsProcessing] = useState(false);
  const {
    yourDOB,
    depositAmount,
    yourGender,
    yourHealth,
    isSmoker,
    zipCode,
    errors,
    yourFrequency,
    yourTermLength,
    citizenShip,
    medicarePolicyFlag,
  } = formData;

  const onDateChange = async (date) => {
    setFormData({ ...formData, yourDOB: date, errors: {} });
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const maxDate = () => {
    let date = new Date();
    if (
      underwriting &&
      underwriting.goalType === "INSURE" &&
      underwriting.policyType === "TERM_LIFE_INSURANCE"
    ) {
      date.setFullYear(date.getFullYear() - 18);
      setMaxDateOfCalendar(date);
    }
    if (underwriting && underwriting.goalType === "SAVE") {
      date.setFullYear(date.getFullYear() - 15);
      setMaxDateOfCalendar(date);
    } else {
      setMaxDateOfCalendar(date);
    }
  };
  const minDate = () => {
    let date = new Date();
    if (
      underwriting &&
      underwriting.goalType === "INSURE" &&
      underwriting.policyType === "FINAL_EXPENSE_INSURANCE"
    ) {
      date.setFullYear(date.getFullYear() - 85);
      setMinDateOfCalendar(date);
    } else {
      date.setFullYear(date.getFullYear() - 91);
      setMinDateOfCalendar(date);
    }
  };

  const dollar = (value) => "$" + Number(value).toLocaleString();

  const handleChange = (e) => {
    if (e.target.name == "yourFrequency") {
      setSlideLabels(
        getSliderVariables(
          e.target.value,
          underwriting?.amount,
          underwriting?.policyType
        )
      );
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
        depositAmount: getSliderVariables(
          e.target.value,
          underwriting?.amount,
          underwriting?.policyType
        ).defaultValue,
      });
    } else if (e.target.name == "medicarePolicyFlag") {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value === "true" ? true : false,
      });
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  const handleSmoker = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value == "true" ? true : false,
    });
  };
  const handleFormData = (depositAmount, valueType) => {
    setFormData({
      ...formData,
      value_type: valueType,
      depositAmount: depositAmount,
    });
  };

  const handleHealthChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const checkDefault = () => {
    const validDirectLinks = [
      "/final-expense",
      "/term",
      "/permanent",
      "/dividend",
      "/index",
      "/medicare",
    ];
    const expectThisDirectLink = ["/financial", "/insurance"];
    const validDefaultPages = [
      "final_expense",
      "term",
      "permanent",
      "medicare",
    ];

    if (validDirectLinks.includes(direct_link)) {
      return true;
    }

    if (
      validDefaultPages.includes(company_information?.defaultPage) &&
      !expectThisDirectLink.includes(direct_link)
    ) {
      return true;
    }

    return false;
  };
  useEffect(() => {
    if (checkDefault()) {
      if (direct_link === "/dividend") {
        saveUnderwriting("SAVE");
      } else if (direct_link === "/index") {
        saveUnderwriting("INVEST");
      } else if (
        (company_information?.defaultPage === "medicare" &&
          direct_link !== "/permanent" &&
          direct_link !== "/term" &&
          direct_link !== "/dividend" &&
          direct_link !== "/index" &&
          direct_link !== "/final-expense") ||
        direct_link === "/medicare"
      ) {
        saveUnderwriting("MEDICARE");
      } else saveUnderwriting("INSURE");
    }
  }, []);

  useEffect(() => {
    if (checkDefault()) {
      return;
    } else {
      if (!underwriting?.applicationId && !underwriting?.goalType) {
        window.location.replace(CONSUMER_PORTAL_URL + "404");
      }
      if (underwriting && !isEmpty(underwriting.carrierWiseLeads)) {
        const whereToRoute = getProcessStep(
          underwriting.resumeProcess,
          underwriting.goalType,
          underwriting.policyType,
          underwriting.productSelection
        );
        props.history.push(whereToRoute);
      }
    }

    minDate();
    maxDate();
  }, []);

  const validateForm = () => {
    let errors = {},
      isValid = true;

    if (underwriting?.goalType === "MEDICARE" && medicarePolicyFlag === "") {
      errors.medicarePolicyFlag = t("single_page.errors.medicarePolicyFlag");
      isValid = false;
    }

    if (yourGender == "") {
      errors.yourGender = t("single_page.errors.gender");
      isValid = false;
    }

    if (yourDOB == "" || yourDOB === null) {
      errors.yourDOB = t("single_page.errors.dob");
      isValid = false;
    }

    if (zipCode.trim() == "") {
      errors.zipCode = t("single_page.errors.zipCode");
      isValid = false;
    }

    if (underwriting?.goalType !== "MEDICARE") {
      if (isSmoker === null) {
        errors.isSmoker = t("single_page.errors.smoke");
        isValid = false;
      }

      if (
        underwriting.policyType == "TERM_LIFE_INSURANCE" &&
        yourTermLength == null
      ) {
        errors.yourTermLength = t("single_page.errors.coverage");
        isValid = false;
      }
      if (
        (underwriting.goalType == "SAVE" ||
          underwriting.goalType == "INVEST") &&
        yourFrequency == ""
      ) {
        errors.yourFrequency = t("single_page.errors.coverage");
        isValid = false;
      }

      if (citizenShip.trim() == "") {
        errors.citizenShip = t("single_page.errors.citizenship");
        isValid = false;
      }
      if (depositAmount == "") {
        errors.depositAmount = t("single_page.errors.coverage");
        isValid = false;
      }

      if (yourHealth == "") {
        errors.yourHealth = t("single_page.errors.health");
        isValid = false;
      }
    }
    setFormData({ ...formData, errors: errors });
    return isValid;
  };
  async function doSubmit(req, eventObject) {
    try {
      setIsProcessing(true);
      const { applicationId, applicationType } = formData;
      let yourAge;
      if (underwriting?.policyType === "FINAL_EXPENSE_INSURANCE") {
        yourAge = calcAge(yourDOB);
      } else {
        yourAge = calcAgeOnMonth(yourDOB);
      }
      const isAuthorized = getJwt() ? true : false;
      let request = {
        applicationId: applicationId
          ? applicationId
          : underwriting?.applicationId,
        applicationType: applicationType,
        goalType: underwriting?.goalType,
        postalCode: zipCode,
        stateName: getState(zipCode).state,
        // healthRating: yourHealth,
        // smoker: JSON.parse(isSmoker),

        gender: await encryptme(yourGender),
        age: yourAge,
        dob: yourDOB
          ? await encryptme(moment(yourDOB).format("L").toString())
          : null,
        // amount: depositAmount,
        // citizenship: citizenShip,
        resumeProcess: setProcessStep(
          (underwriting?.goalType === "MEDICARE" && medicarePolicyFlag) ||
            underwriting?.goalType !== "MEDICARE"
            ? "zipcode"
            : "medicare-plan-lists",
          underwriting?.policyType,
          underwriting?.goalType,

          medicarePolicyFlag
        ),
      };

      if (underwriting?.goalType !== "MEDICARE") {
        request.healthRating = yourHealth;
        request.smoker = JSON.parse(isSmoker);
        request.amount = depositAmount;
        request.citizenship = citizenShip;
      }
      if (underwriting?.goalType === "MEDICARE") {
        request.medicarePolicyFlag = medicarePolicyFlag;
        // request.policyType = "MEDICARE_INSURANCE";
      }

      if (underwriting.policyType === "TERM_LIFE_INSURANCE") {
        request.termLength = yourTermLength;
      }
      if (
        underwriting.goalType == "SAVE" ||
        underwriting.goalType == "INVEST"
      ) {
        request.frequency = yourFrequency;
      }
      const apiEndPoint = API_URL_UNDERWRITING + "saveUnderWriting";
      const response = await commonApiCall(
        apiEndPoint,
        "post",
        request,
        UNDERWRITING_DETAILS,
        isAuthorized
      );
      if (response.status.code === 200) {
        setIsProcessing(false);
        let eventObject = {
          gender: yourGender,
          frequency: yourFrequency,
          health_rating: yourHealth,
          is_smoker: isSmoker ? "yes" : "no",
          amount: depositAmount.toString(),
          age: yourAge.toString(),
          citizenShip: citizenShip,
          postalCode: zipCode,
          goal_type: underwriting?.goalType,
          policy_type: underwriting?.policyType,
          application_id: underwriting?.applicationId,
          agent_id: underwriting?.agentDetail?.agentId,
          pay: yourTermLength,
        };

        segmentTracking("application_comparison_alter", eventObject);
        // showSuccessToast(t("validation_message.SAVED_SUCCESSFULLY"));
        setTimeout(() => {
          if (response?.data?.goalType === "MEDICARE") {
            if (response?.data?.medicarePolicyFlag) {
              props.history.push(UnderWritingRoutes.MEDICARE_PLAN_LIST);
            } else {
              props.history.push(UnderWritingRoutes.GUEST_BASIC_DETAILS);
            }
          } else {
            props.history.push(UnderWritingRoutes.PLANS);
          }
        }, 1000);
      } else {
        setIsProcessing(false);
        showErrorToast(response.status.message);
      }
    } catch (err) {
      setIsProcessing(false);
      showErrorToast(t("validation_message.SOMETHING_WENT_WRONG"));
    }
  }

  const submitForm = () => {
    if (validateForm()) {
      doSubmit();
    }
  };

  const saveUnderwriting = async (type) => {
    const { applicationType } = formData;
    const splitpath = window.location.pathname.split("/");
    const domainNamePath = window.location.hostname;
    const referenceFromUrl = getItem("referenceFromUrl");
    let eventPage;
    const request = {
      goalType: type,
      applicationId: "",
      applicationType: applicationType,
      referalCode: company_information?.refferalCode,
      // referenceLocation: await encryptme(address),
      resumeProcess: setProcessStep(splitpath[1]),
      reference: referenceFromUrl
        ? referenceFromUrl
        : underwriting?.reference
        ? underwriting?.reference
        : domainNamePath,
    };
    if (
      (company_information?.defaultPage === "term" &&
        direct_link !== "/final-expense" &&
        direct_link !== "/permanent" &&
        direct_link !== "/dividend" &&
        direct_link !== "/index" &&
        direct_link !== "/medicare") ||
      direct_link === "/term"
    ) {
      request.insureBasedOn = "COVERAGE";
      request.policyType = "TERM_LIFE_INSURANCE";
      eventPage = "Coverage Page - Term Only";
    }
    if (
      (company_information?.defaultPage === "permanent" &&
        direct_link !== "/final-expense" &&
        direct_link !== "/term" &&
        direct_link !== "/dividend" &&
        direct_link !== "/index" &&
        direct_link !== "/medicare") ||
      direct_link === "/permanent"
    ) {
      request.insureBasedOn = "COVERAGE";
      request.policyType = "PERMANENT_LIFE_INSURANCE";
      eventPage = "Coverage Page - Permanent Only";
    }
    if (
      (company_information?.defaultPage === "final_expense" &&
        direct_link !== "/permanent" &&
        direct_link !== "/term" &&
        direct_link !== "/dividend" &&
        direct_link !== "/index" &&
        direct_link !== "/medicare") ||
      direct_link === "/final-expense"
    ) {
      request.policyType = "FINAL_EXPENSE_INSURANCE";
      eventPage = "Coverage Page - Final Expense Only";
    }

    if (
      (company_information?.defaultPage === "medicare" &&
        direct_link !== "/permanent" &&
        direct_link !== "/term" &&
        direct_link !== "/dividend" &&
        direct_link !== "/index" &&
        direct_link !== "/final-expense") ||
      direct_link === "/medicare"
    ) {
      request.policyType = "MEDICARE_INSURANCE";
      eventPage = "Coverage Page - Medicare Only";
    }

    const isAuthorized = getJwt() ? true : false;
    const apiEndPoint = API_URL_UNDERWRITING + "saveUnderWriting";
    const response = await commonApiCall(
      apiEndPoint,
      "post",
      request,
      UNDERWRITING_DETAILS,
      isAuthorized
    );
    if (response.status.code === 200) {
      removeItem("referenceFromUrl");
      const eventObject = {
        agent_id: response?.data?.agentDetail?.agentId,
        application_id: response?.data?.applicationId,
        entry_page: eventPage,
        single_page: true,
      };
      if (response?.data?.goalType === "INSURE") {
        setSlideLabels(
          getSliderVariables(
            response?.data?.goalType,
            response?.data?.amount,
            response?.data?.policyType
          )
        );
        setFormData({
          ...formData,
          depositAmount: getSliderVariables(
            response?.data?.goalType,
            response?.data?.amount,
            response?.data?.policyType
          ).defaultValue,
        });
      }
      segmentTracking("application_visit", eventObject);
    }
  };

  const handleValue = () => {
    const whichAmount =
      formData.depositAmount < slideLabels.min
        ? slideLabels.min
        : formData.depositAmount > slideLabels.max
        ? slideLabels.max
        : formData.depositAmount;

    setFormData({ ...formData, value_type: true, depositAmount: whichAmount });
  };

  const onBackStep = () => {
    if (underwriting?.goalType == "INSURE") {
      props.history.push(UnderWritingRoutes.INSURE_TYPE);
    } else if (underwriting?.goalType == "MEDICARE") {
      props.history.push(UnderWritingRoutes.GET_START);
    } else {
      props.history.push(UnderWritingRoutes.INVESTMENT_TYPE);
    }
  };

  return (
    <div className="single-page-application mx-auto">
      <h2 className="mb-5">
        Let us help you find the best{" "}
        {underwriting?.goalType == "INSURE"
          ? underwriting?.policyType == "PERMANENT_LIFE_INSURANCE"
            ? "Permanent Life Insurance "
            : underwriting?.policyType == "FINAL_EXPENSE_INSURANCE"
            ? "Final Expense Insurance "
            : "Term Life Insurance "
          : underwriting?.goalType == "SAVE"
          ? "Dividend Based option "
          : underwriting?.goalType == "MEDICARE"
          ? "Medicare Based option "
          : "Index Based option "}
        for you.
      </h2>
      {underwriting?.goalType == "MEDICARE" && (
        <div className="single-page-box gender">
          <h4>{t("single_page.title.medicare")}</h4>
          <div className="d-flex">
            <label className="boxmodal-radio mb-0">
              <input
                type="radio"
                name="medicarePolicyFlag"
                id="medicarePolicyFlag"
                value={true}
                checked={medicarePolicyFlag}
                onChange={(e) => handleChange(e)}
                onClick={(e) => handleChange(e)}
              />
              <div
                className={`gender_selection ${
                  medicarePolicyFlag ? "active" : ""
                }`}
              >
                Yes
              </div>
            </label>
            <label className="boxmodal-radio mb-0">
              <input
                type="radio"
                name="medicarePolicyFlag"
                id="medicarePolicyFlag"
                value={false}
                checked={medicarePolicyFlag}
                onChange={(e) => handleChange(e)}
                onClick={(e) => handleChange(e)}
              />
              <div
                className={`gender_selection ${
                  medicarePolicyFlag === false ? "active" : ""
                }`}
              >
                No
              </div>
            </label>
          </div>
          {errors.medicarePolicyFlag && (
            <small className="text-danger err-msg">
              {t("single_page.errors.medicarePolicyFlag")}
            </small>
          )}
        </div>
      )}
      <div className="single-page-box gender">
        <h4>{t("single_page.title.gender")}</h4>
        <div className="d-flex">
          <label className="boxmodal-radio mb-0">
            <input
              type="radio"
              name="yourGender"
              id="yourGender"
              value="FEMALE"
              checked={yourGender === "FEMALE" ? true : false}
              onChange={(e) => handleChange(e)}
              onClick={(e) => handleChange(e)}
            />
            <div
              className={`gender_selection ${
                yourGender === "FEMALE" ? "active" : ""
              }`}
            >
              {t("your_gender.female")}
            </div>
          </label>
          <label className="boxmodal-radio mb-0">
            <input
              type="radio"
              name="yourGender"
              id="yourGender"
              value="MALE"
              checked={yourGender === "MALE" ? true : false}
              onChange={(e) => handleChange(e)}
              onClick={(e) => handleChange(e)}
            />
            <div
              className={`gender_selection ${
                yourGender === "MALE" ? "active" : ""
              }`}
            >
              {t("your_gender.male")}
            </div>
          </label>
        </div>
        {errors.yourGender && (
          <small className="text-danger err-msg">
            {t("single_page.errors.gender")}
          </small>
        )}
      </div>
      <div className="single-page-box birthday">
        <h4>{t("single_page.title.birthday")}</h4>
        <SelectDatepicker
          selectedDate={yourDOB}
          onDateChange={onDateChange}
          minDate={minDateOfCalendar ? minDateOfCalendar : new Date(1900, 0, 1)}
          maxDate={maxDateOfCalendar ? maxDateOfCalendar : new Date()}
          showLabels={false}
        />
        {errors.yourDOB && (
          <small className="text-danger err-msg">
            {" "}
            {t("single_page.errors.dob")}
          </small>
        )}
      </div>
      {underwriting?.goalType !== "MEDICARE" && (
        <div>
          <div className="single-page-box health">
            <h4>{t("single_page.title.health")}</h4>
            <div className="d-flex flex-wrap flex-md-nowrap">
              <label className="boxmodal-radio mb-3">
                <input
                  type="radio"
                  name="yourHealth"
                  id="yourHealth"
                  value="AVERAGE"
                  checked={yourHealth === "AVERAGE" ? true : false}
                  onChange={(e) => handleChange(e)}
                  onClick={(e) => handleChange(e)}
                />
                <div
                  className={`health-box ${
                    yourHealth === "AVERAGE" ? "active" : ""
                  }`}
                >
                  <div>
                    {" "}
                    <img
                      src="/assets/images/health-1.svg"
                      className="mb-2"
                      style={{ width: 60 }}
                      alt=""
                    />
                    <span>Average</span>
                  </div>
                </div>
              </label>
              <label className="boxmodal-radio   mb-3">
                <input
                  type="radio"
                  name="yourHealth"
                  id="yourHealth"
                  value="GOOD"
                  checked={yourHealth === "GOOD" ? true : false}
                  onChange={(e) => handleChange(e)}
                  onClick={(e) => handleChange(e)}
                />
                <div
                  className={`health-box ${
                    yourHealth === "GOOD" ? "active" : ""
                  }`}
                >
                  <div>
                    {" "}
                    <img
                      style={{ width: 70 }}
                      src="/assets/images/health-2.png"
                      className="mb-2"
                      alt=""
                    />
                    <span>Good</span>
                  </div>
                </div>
              </label>
              <label className="boxmodal-radio  mb-3">
                <input
                  type="radio"
                  name="yourHealth"
                  id="yourHealth"
                  value="EXCELLENT"
                  checked={yourHealth === "EXCELLENT" ? true : false}
                  onChange={(e) => handleHealthChange(e)}
                  onClick={(e) => handleHealthChange(e)}
                />
                <div
                  className={`health-box ${
                    yourHealth === "EXCELLENT" ? "active" : ""
                  }`}
                >
                  <div>
                    <img
                      style={{ width: 90 }}
                      src="/assets/images/health-3.png"
                      className="mb-1"
                      alt=""
                    />
                    <span>Excellent</span>
                  </div>
                </div>
              </label>
            </div>
            {errors.yourHealth && (
              <small className="text-danger err-msg">
                {" "}
                {t("single_page.errors.health")}
              </small>
            )}
          </div>
          <div className="single-page-box smoke">
            <h4>{t("single_page.title.smoke")}</h4>
            <div className="d-flex">
              <label className="boxmodal-radio mb-0">
                <input
                  type="radio"
                  name="isSmoker"
                  id="isSmoker"
                  value={true}
                  checked={isSmoker === true ? true : false}
                  onChange={(e) => handleSmoker(e)}
                  onClick={(e) => handleSmoker(e)}
                />
                <div
                  className={`smoke-box ${isSmoker === true ? "active" : ""}`}
                >
                  <div>
                    <img src="/assets/images/smoke-1.png" alt="" />
                    <span>Yes</span>
                  </div>
                </div>
              </label>

              <label className="boxmodal-radio mb-0">
                <input
                  type="radio"
                  name="isSmoker"
                  id="isSmoker"
                  value={false}
                  checked={isSmoker === false ? true : false}
                  onChange={(e) => handleSmoker(e)}
                  onClick={(e) => handleSmoker(e)}
                />
                <div
                  className={`smoke-box ${isSmoker === false ? "active" : ""}`}
                >
                  <div>
                    <img src="/assets/images/smoke-2.png" alt="" />
                    <span>No</span>
                  </div>
                </div>
              </label>
            </div>
            {errors.isSmoker && (
              <small className="text-danger err-msg">
                {t("single_page.errors.smoke")}
              </small>
            )}
          </div>
          <div className="single-page-box citizen">
            <h4>{t("single_page.title.citizen")}</h4>
            <div className="">
              <span className="custom-radio">
                <label className="">
                  <input
                    type="radio"
                    name="citizenShip"
                    id="citizenship"
                    value="us_citizen_reside"
                    checked={citizenShip == "us_citizen_reside"}
                    onChange={(e) => handleHealthChange(e)}
                    onClick={(e) => handleHealthChange(e)}
                  />
                  <span></span>
                  <span className="emailcheck">
                    {t("single_page.title.citizen_key.key1")}
                  </span>
                </label>
                <label className="">
                  <input
                    type="radio"
                    name="citizenShip"
                    id="citizenship"
                    value="us_citizen_outside"
                    checked={citizenShip == "us_citizen_outside"}
                    onChange={(e) => handleHealthChange(e)}
                    onClick={(e) => handleHealthChange(e)}
                  />
                  <span></span>
                  <span className="emailcheck">
                    {" "}
                    {t("single_page.title.citizen_key.key2")}
                  </span>
                </label>
                <label className="">
                  <input
                    type="radio"
                    name="citizenShip"
                    id="citizenship"
                    value="non_us_citizen_reside"
                    checked={citizenShip == "non_us_citizen_reside"}
                    onChange={(e) => handleHealthChange(e)}
                    onClick={(e) => handleHealthChange(e)}
                  />
                  <span></span>
                  <span className="emailcheck">
                    {" "}
                    {t("single_page.title.citizen_key.key3")}
                  </span>
                </label>
                <label className="">
                  <input
                    type="radio"
                    name="citizenShip"
                    id="citizenship"
                    value="non_resident_alien"
                    checked={citizenShip == "non_resident_alien"}
                    onChange={(e) => handleHealthChange(e)}
                    onClick={(e) => handleHealthChange(e)}
                  />
                  <span></span>
                  <span className="emailcheck">
                    {" "}
                    {t("single_page.title.citizen_key.key4")}
                  </span>
                </label>
                <label className="">
                  <input
                    type="radio"
                    name="citizenShip"
                    id="citizenship"
                    value="other"
                    checked={citizenShip == "other"}
                    onChange={(e) => handleHealthChange(e)}
                    onClick={(e) => handleHealthChange(e)}
                  />
                  <span></span>
                  <span className="emailcheck">
                    {" "}
                    {t("single_page.title.citizen_key.key5")}
                  </span>
                </label>
              </span>
            </div>
            {errors.citizenShip && (
              <small className="text-danger err-msg">
                {" "}
                {t("single_page.errors.citizenship")}
              </small>
            )}
          </div>
        </div>
      )}
      <div className="single-page-box zipcode">
        <h4>{t("single_page.title.zipcode")}</h4>
        <div className="row">
          <div className="col-md-4">
            <div className="form-group mb-0">
              <div className="input-effect with-icon">
                <input
                  className="form-control"
                  placeholder="Zipcode"
                  name="zipCode"
                  id="zipCode"
                  maxLength="5"
                  type="text"
                  value={zipCode}
                  onChange={(e) => handleChange(e)}
                />
                <label htmlFor="firstName">{t("zipCode.zipCode")}</label>
                <em className="input-icon"></em>
              </div>
              {errors.zipCode && (
                <small className="text-danger err-msg">
                  {t("single_page.errors.zipcode")}
                </small>
              )}
            </div>
          </div>
        </div>
      </div>
      {(underwriting?.goalType == "SAVE" ||
        underwriting?.goalType == "INVEST") && (
        <div className="single-page-box smoke">
          <h4>{t("single_page.title.scheduledTime")}</h4>
          <div className="d-flex">
            <label className="boxmodal-radio mb-0">
              <input
                type="radio"
                name="yourFrequency"
                id="yourFrequency"
                value="MONTHLY"
                checked={yourFrequency === "MONTHLY" ? true : false}
                onChange={(e) => handleChange(e)}
                onClick={(e) => handleChange(e)}
              />
              <div
                className={`smoke-box ${
                  yourFrequency == "MONTHLY" ? "active" : ""
                }`}
              >
                <div>
                  <img src="/assets/images/sp-1.png" alt="" />
                  <span>MONTHLY</span>
                </div>
              </div>
            </label>

            <label className="boxmodal-radio mb-0">
              <input
                type="radio"
                name="yourFrequency"
                id="yourFrequency"
                value="ANNUALLY"
                checked={yourFrequency === "ANNUALLY" ? true : false}
                onChange={(e) => handleChange(e)}
                onClick={(e) => handleChange(e)}
              />
              <div
                className={`smoke-box ${
                  yourFrequency == "ANNUALLY" ? "active" : ""
                }`}
              >
                <div>
                  {" "}
                  <img src="/assets/images/sp-2.png" alt="" />
                  <span>ANNUALLY</span>
                </div>
              </div>
            </label>
          </div>
          {errors.yourFrequency && (
            <small className="text-danger err-msg">
              {t("single_page.errors.scheduledPayment")}
            </small>
          )}
        </div>
      )}

      {underwriting?.goalType === "INSURE" && (
        <div className="single-page-box single-page-custom-slider">
          {underwriting?.goalType == "SAVE" ||
          underwriting?.goalType == "INVEST" ? (
            <h4>{t("single_page.title.scheduledPayment")}</h4>
          ) : (
            <h4>{t("single_page.title.coverage")}</h4>
          )}

          <div className="range-block">
            <Slider
              min={slideLabels.min}
              max={slideLabels.max}
              step={slideLabels.stepAmount ? slideLabels.stepAmount : 1}
              value={Number(depositAmount)}
              labels={horizontalLabels}
              format={dollar}
              handleLabel={String(depositAmount)}
              onChange={(e) =>
                handleChange({
                  target: {
                    name: "depositAmount",
                    value: slideLabels.defaultValue > 0 ? e : 0,
                  },
                })
              }
              onBlur={() => () => handleFormData(depositAmount, true)}
              className="deposit-range-slider"
              tooltip={false}
            />
            <div className="num_value">
              <NumberFormat
                // autoFocus
                prefix={"$"}
                thousandSeparator={true}
                name="number"
                id="depositAmount"
                className="form-control w-75 number-format-amount px-0"
                inputMode="numeric"
                allowNegative={false}
                value={depositAmount}
                isAllowed={(values) => {
                  const { formattedValue, floatValue } = values;
                  return formattedValue === "" || floatValue <= slideLabels.max;
                }}
                onBlur={(e) => handleValue()}
                onValueChange={(e) =>
                  handleChange({
                    target: {
                      name: "depositAmount",
                      value: e.value,
                    },
                  })
                }
              />

              {/* <p className="pb-0">{dollar(depositAmount)}</p> */}
            </div>
          </div>
          {errors.depositAmount && (
            <small className="text-danger err-msg">
              {t("single_page.errors.coverage")}
            </small>
          )}
        </div>
      )}
      {underwriting?.goalType === "INSURE" &&
        underwriting?.policyType === "TERM_LIFE_INSURANCE" && (
          <div className="single-page-box smoke coveragelast">
            <h4>{t("single_page.title.frequency")}</h4>
            <div className="d-flex flex-wrap">
              <label className="boxmodal-radio">
                <input
                  type="radio"
                  name="yourTermLength"
                  id="yourTermLength"
                  value={10}
                  checked={yourTermLength == 10 ? true : false}
                  onClick={(e) => handleChange(e)}
                  onChange={(e) => handleChange(e)}
                />
                <div
                  className={`smoke-box ${
                    yourTermLength == 10 ? "active" : ""
                  }`}
                >
                  <img src="/assets/images/coverage-1.png" alt="" />
                  <span>10 Years</span>
                </div>
              </label>

              <label className="boxmodal-radio">
                <input
                  type="radio"
                  name="yourTermLength"
                  id="yourTermLength"
                  value={15}
                  checked={yourTermLength == 10 ? true : false}
                  onClick={(e) => handleChange(e)}
                  onChange={(e) => handleChange(e)}
                />
                <div
                  className={`smoke-box ${
                    yourTermLength == 15 ? "active" : ""
                  }`}
                >
                  <img src="/assets/images/coverage-2.png" />
                  <span>15 Years</span>
                </div>
              </label>

              <label className="boxmodal-radio">
                <input
                  type="radio"
                  name="yourTermLength"
                  id="yourTermLength"
                  value={20}
                  checked={yourTermLength == 20 ? true : false}
                  onClick={(e) => handleChange(e)}
                  onChange={(e) => handleChange(e)}
                />
                <div
                  className={`smoke-box ${
                    yourTermLength == 20 ? "active" : ""
                  }`}
                >
                  <img src="/assets/images/coverage-3.png" />
                  <span>20 Years</span>
                </div>
              </label>

              <label className="boxmodal-radio">
                <input
                  type="radio"
                  name="yourTermLength"
                  id="yourTermLength"
                  value={30}
                  checked={yourTermLength == 30 ? true : false}
                  onClick={(e) => handleChange(e)}
                  onChange={(e) => handleChange(e)}
                />
                <div
                  className={`smoke-box ${
                    yourTermLength == 30 ? "active" : ""
                  }`}
                >
                  <img src="/assets/images/coverage-4.png" />
                  <span>30 Years</span>
                </div>
              </label>
            </div>
            {errors.yourTermLength && (
              <small className="text-danger err-msg">
                {t("single_page.errors.termLength")}
              </small>
            )}
          </div>
        )}

      <div className="single-page-box browse-btn">
        {!(
          company_information?.defaultPage == "final_expense" ||
          company_information?.defaultPage == "permanent" ||
          company_information?.defaultPage == "term" ||
          company_information?.defaultPage == "medicare" ||
          direct_link === "/final-expense" ||
          direct_link === "/term" ||
          direct_link === "/permanent" ||
          direct_link === "/dividend" ||
          direct_link === "/medicare" ||
          direct_link === "/index"
        ) && (
          <button
            className="btn btn-primary-outline btn-back-single-page px-5 py-3"
            onClick={onBackStep}
          >
            BACK
          </button>
        )}

        <button
          className="btn btn-gradient px-5 py-3"
          disabled={isProcessing}
          onClick={submitForm}
        >
          {isProcessing ? t("manual_form.processing") : "BROWSE QUOTES"}
        </button>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  underwriting: state.underwriting.underwriting,
  company_information: state.underwriting.companyInformation,
  direct_link: state.underwriting.direct_link,
});

export default connect(mapStateToProps, { commonApiCall })(
  withRouter(SinglePageApp)
);
