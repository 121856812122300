import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

export const ApplicationStatusLoader = () => {
  return (
    <div
      id="pre-loader-plan-suggestion"
      className="card-loader skeleton-loader"
    >
      <SkeletonTheme>
        <div className="mb-4">
          <Skeleton count={1} width={36} height={36} circle={true} />
          <span style={{ marginLeft: 20 }}>
            <Skeleton count={1} width={200} height={36} />
          </span>
        </div>
        <Skeleton count={1} height={1} />
        <div className="row mb-3">
          <div className="col-md-4">
            <Skeleton count={1} height={40} />
          </div>
          <div className="col-md-4">
            <Skeleton count={1} height={40} />
          </div>
          <div className="col-md-4">
            <Skeleton count={1} height={40} />
          </div>
        </div>
        <Skeleton count={1} height={1} />
        <div>
          <Skeleton count={1} height={28} />
        </div>
        <div className="mt-3">
          <Skeleton count={1} height={28} width={90} />
          <span className="ml-3">
            <Skeleton count={1} height={28} width={90} />
          </span>
        </div>
        <div className="mt-3 mb-3">
          <Skeleton count={1} height={42} width={160} />
        </div>
        <Skeleton count={1} height={1} />
        <div className="row mb-3">
          <div className="col-9 text-left">
            <Skeleton count={1} width={46} height={46} circle={true} />
            <span
              style={{
                width: "calc(100% - 50px)",
                display: "inline-block",
                paddingLeft: "20px",
              }}
            >
              <Skeleton count={1} height={46} />
            </span>
          </div>
          <div className="col-3">
            <Skeleton count={1} height={46} />
          </div>
        </div>
        <Skeleton count={1} height={40} width={120} />
      </SkeletonTheme>
    </div>
  );
};
