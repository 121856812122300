import React, { useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  commonApiCall,
  getJwt,
  UNDERWRITING_DETAILS,
  setSbliProgressCount,
} from "../../redux/actions";
import {
  ApprovalStatus,
  getState,
  setProcessStep,
  showErrorToast,
  UnderWritingRoutes,
  validateEmail,
} from "../../utils";
import { API_URL_UNDERWRITING } from "../../config/configs";
import Select from "react-select";
import moment from "moment";
import NumberFormat from "react-number-format";

const ThirdPartyNotification = ({
  arcId,
  underwriting,
  commonApiCall,
  setSbliProgressCount,
  progressCount,
  ...props
}) => {
  const { applicationId, applicationType, goalType, policyType } = underwriting;
  const [formData, setFormData] = useState({
    designeeFlag: "",
    designee: {
      relationshipType: "designee",
    },

    errors: {},
  });
  const [loading, setLoading] = useState(false);
  const { errors } = formData;

  const handleChange = (e) => {
    let obj = { ...formData };
    if (e.target.name === "designeeFlag") {
      obj.designeeFlag = e.target.value;
      if (e.target.value === "0") {
        delete obj.designee;
      }
    } else {
      if (e.target.name !== "designeeFlag") {
        obj.designee[e.target.name] = e.target.value;
      } else {
        obj[e.target.name] = e.target.value;
      }
    }

    setFormData(obj);
  };
  const { designeeFlag, designee } = formData;
  const validateForm = () => {
    let error = {};

    if (designeeFlag === "") {
      error.designeeFlag = "This field is required";
    } else {
      delete error.designeeFlag;
    }

    if (designeeFlag !== "0") {
      if (!designee.hasOwnProperty("firstName") || designee?.firstName === "") {
        error.first_name = "Please enter first name";
      } else {
        delete error.firstName;
      }

      if (!designee.hasOwnProperty("lastName") || designee?.lastName === "") {
        error.last_name = "Please enter last name";
      } else {
        delete error.last_name;
      }

      // if (
      //   !designee.hasOwnProperty("relationshipType") ||
      //   designee?.relationshipType === ""
      // ) {
      //   error.relationshipType = "Please enter relationship type";
      // } else {
      //   delete error.relationshipType;
      // }

      if (!designee.hasOwnProperty("address1") || designee?.address1 === "") {
        error.address1 = "Please enter address";
      } else {
        delete error.address1;
      }

      if (!designee.hasOwnProperty("city") || designee?.city === "") {
        error.city = "Please enter city";
      } else {
        delete error.city;
      }

      if (!designee.hasOwnProperty("zip") || designee?.zip === "") {
        error.zip = "Please enter zipcode";
      } else {
        delete error.zip;
      }

      if (
        !designee.hasOwnProperty("phone") ||
        designee?.phone === "" ||
        designee?.phone.length < 10
      ) {
        error.phone = "Please enter valid phone number";
      } else {
        delete error.phone;
      }

      if (
        !designee.hasOwnProperty("email") ||
        designee?.email === "" ||
        !validateEmail(designee?.email)
      ) {
        error.email = "Please enter valid email address";
      } else {
        delete error.email;
      }
    }

    const isValid = Object.keys(error).length !== 0 ? false : true;
    setFormData({
      ...formData,
      errors: error,
    });
    return isValid;
  };
  console.log(designee);
  const submitThirdParty = async () => {
    setLoading(true);
    try {
      if (validateForm()) {
        let temp = { ...formData.designee };
        temp.state = getState(designee?.zip)?.st;
        let body = {
          arcId: arcId,
          data: JSON.parse(localStorage.getItem("persist::bene")),
          designeeFlag: parseInt(designeeFlag),
          designee: temp,
          applicationId: applicationId,
          beneficiaryConsentFlag: 1,
          beneficiaryConsentTimeStamp: moment().format("MM-DD-YYYY h:mm:ss"),
        };

        const isAuthorized = getJwt() ? true : false;
        const response = await commonApiCall(
          API_URL_UNDERWRITING + "submitBeneficiary",
          "post",
          body,
          "",
          isAuthorized
        );
        if (response.status.code === 200) {
          saveUnderwriting();
        } else {
          showErrorToast(response.status.message);
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
      showErrorToast(err);
      setLoading(false);
    }
  };

  const saveUnderwriting = async () => {
    try {
      const path = window.location.pathname.split("/")[1];
      const request = {
        applicationId: applicationId,
        applicationType: applicationType,
        goalType: goalType,
        resumeProcess: setProcessStep(path, policyType, goalType, "online"),
      };

      const isAuthorized = getJwt() ? true : false;
      const apiEndPoint = API_URL_UNDERWRITING + "saveUnderWriting";
      const response = await commonApiCall(
        apiEndPoint,
        "post",
        request,
        UNDERWRITING_DETAILS,
        isAuthorized
      );
      if (response.status.code === 200) {
        setSbliProgressCount(progressCount + 1);
        props.history.push(UnderWritingRoutes.SBLI_COVERAGE);
      } else {
        showErrorToast(response.status.message);
      }
    } catch (err) {
      showErrorToast("Something went wrong");
    }
  };

  return (
    <div className="third-party-notification mt-4">
      <h3 className="text-center mb-5">Third Party Notification</h3>

      <div className="sbli-question-container pt-4">
        <h4>
          You have the right to designate someone to receive a copy of notices
          of cancellation or lapse of your policy. This designation does not
          constitute the acceptance of any liability on the part of this person
          or SBLI for services provided to you. Do wish to designate a third
          party?{" "}
        </h4>
        <span className="custom-radio mt-3 d-block">
          <label className="m-0 mr-3 d-block mb-2" htmlFor={"radio1"}>
            <input
              checked={formData.designeeFlag === "1"}
              type="radio"
              id={"radio1"}
              name={"designeeFlag"}
              onChange={handleChange}
              value={1}
            />
            <span></span> <span className="emailCheck">Yes</span>
          </label>
        </span>
        <span className="custom-radio mt-3 d-block">
          <label className="m-0 mr-3 d-block mb-2" htmlFor={"radio2"}>
            <input
              checked={formData.designeeFlag === "0"}
              type="radio"
              name={"designeeFlag"}
              id={"radio2"}
              onChange={handleChange}
              value={0}
            />
            <span></span> <span className="emailCheck">No</span>
          </label>
        </span>

        {errors.designeeFlag && (
          <p className="sbli-validation-msg text-danger">
            {errors.designeeFlag}
          </p>
        )}

        {formData.designeeFlag === "1" && (
          <div className="third-party-form mt-4">
            <h4 className="pt-3 mb-3">Who would you like us to contact?</h4>
            <div className="form-group">
              <div className="input-effect">
                <input
                  className="form-control"
                  placeholder={"First Name"}
                  name="firstName"
                  id="first_name"
                  onChange={handleChange}
                  value={formData?.contact_person?.firstName}
                  type="text"
                />
                <label htmlFor="first_name">{"First Name"}</label>
                <em className="input-icon"></em>
                <span className="text-danger "></span>
              </div>
              {errors.first_name && (
                <p className="sbli-validation-msg text-danger">
                  {errors.first_name}
                </p>
              )}
            </div>
            <div className="form-group">
              <div className="input-effect">
                <input
                  className="form-control"
                  placeholder={"Last Name"}
                  name="lastName"
                  id="last_name"
                  onChange={handleChange}
                  type="text"
                  value={formData?.contact_person?.lastName}
                />
                <label htmlFor="last_name">{"Last Name"}</label>
                <em className="input-icon"></em>
                <span className="text-danger "></span>
              </div>
              {errors.last_name && (
                <p className="sbli-validation-msg text-danger">
                  {errors.last_name}
                </p>
              )}
            </div>

            {/* <div className="form-group">
              <div className="input-effect">
                <input
                  className="form-control"
                  placeholder={"Relationship Type"}
                  name="relationshipType"
                  id="relationshipType"
                  onChange={handleChange}
                  type="text"
                  value={formData?.contact_person?.relationshipType}
                />
                <label htmlFor="relationshipType">{"Relationship Type"}</label>
                <em className="input-icon"></em>
                <span className="text-danger "></span>
              </div>
              {errors.relationshipType && (
                <p className="sbli-validation-msg text-danger">
                  {errors.relationshipType}
                </p>
              )}
            </div> */}

            <h4 className="mb-3 pt-3">
              What is their home address (no P.O Boxes)?
            </h4>
            <div className="form-group">
              <div className="input-effect">
                <input
                  className="form-control"
                  placeholder={"Home address (no P.O Boxes)"}
                  name="address1"
                  id="home_address"
                  onChange={handleChange}
                  type="text"
                />
                <label htmlFor="home_address">
                  {"Home address (no P.O Boxes)"}
                </label>
                <em className="input-icon"></em>
                <span className="text-danger "></span>
              </div>
              {errors.address1 && (
                <p className="sbli-validation-msg text-danger">
                  {errors.address1}
                </p>
              )}
            </div>
            <div className="form-group">
              <div className="input-effect">
                <input
                  className="form-control"
                  placeholder={"Zipcode"}
                  name="zip"
                  onChange={handleChange}
                  id="zipcode"
                  type="text"
                />
                <label htmlFor="city">{"Zipcode"}</label>
                <em className="input-icon"></em>
                <span className="text-danger "></span>
              </div>
              {errors.zip && (
                <p className="sbli-validation-msg text-danger">{errors.zip}</p>
              )}
            </div>
            <div className="form-group fixed-floating-label mb-4">
              <div className="default-select with-border">
                <Select
                  className={"custom-select-menu"}
                  classNamePrefix={"custom-select"}
                  value={
                    formData.zip != ""
                      ? {
                          value: getState(designee?.zip)?.id,
                          label: getState(designee?.zip)?.state,
                        }
                      : ""
                  }
                  isDisabled={true}
                />
                <span className="fixed-label">{"State"}</span>
                <em className="input-icon"></em>
              </div>
            </div>
            <div className="form-group">
              <div className="input-effect">
                <input
                  className="form-control"
                  placeholder={"City"}
                  name="city"
                  onChange={handleChange}
                  id="city"
                  type="text"
                />
                <label htmlFor="city">{"City"}</label>
                <em className="input-icon"></em>
                <span className="text-danger "></span>
              </div>
              {errors.city && (
                <p className="sbli-validation-msg text-danger">{errors.city}</p>
              )}
            </div>

            <h4 className="mb-3">
              What is their Day Phone and e-mail address?
            </h4>
            <div className="form-group">
              <div className="input-effect">
                <NumberFormat
                  name="phone"
                  id="mobile"
                  className="form-control"
                  placeholder="Day Phone"
                  format="(###) ###-####"
                  onValueChange={(e) => {
                    handleChange({
                      target: {
                        name: "phone",
                        value: e.value,
                      },
                    });
                  }}
                />
                <label htmlFor="phone">{"Day Phone"}</label>
                <em className="input-icon"></em>
                {errors.phone && (
                  <p className="sbli-validation-msg text-danger">
                    {errors.phone}
                  </p>
                )}
              </div>
            </div>
            <div className="form-group">
              <div className="input-effect">
                <input
                  className="form-control"
                  placeholder={"E-mail Address"}
                  name="email"
                  onChange={handleChange}
                  id="email"
                  type="text"
                />
                <label htmlFor="email">{"E-mail Address"}</label>
                <em className="input-icon"></em>
                <span className="text-danger "></span>
              </div>
              {errors.email && (
                <p className="sbli-validation-msg text-danger">
                  {errors.email}
                </p>
              )}
            </div>
          </div>
        )}
      </div>
      <div className="w-100 mb-4 text-center">
        <button
          className="btn btn-default px-5"
          type="button"
          onClick={submitThirdParty}
        >
          {loading ? "Processing..." : "Next"}
        </button>
      </div>
      <div className="w-100 text-center">
        <button
          className="btn btn-link"
          onClick={() => props.history.push("/beneficiary")}
        >
          Back
        </button>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  arcId: state.underwriting.arcId,
  underwriting: state.underwriting.underwriting,
  progressCount: state.underwriting.progressCount,
});

export default connect(mapStateToProps, {
  commonApiCall,
  setSbliProgressCount,
})(withRouter(ThirdPartyNotification));
