import {
  CONSUMER_DETAILS,
  CONSUMER_LOGOUT,
  SESSION_LOGOUT,
} from "../actions/types";

const INITIAL_STATE = {
  consumerdetails: {},
};
export const consumer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CONSUMER_DETAILS:
      return Object.assign({}, state, { consumerdetails: action.payload.data });
    case SESSION_LOGOUT:
      return Object.assign({}, state, {
        consumerdetails: {},
        profile_pic: {},
      });
    case CONSUMER_LOGOUT:
      return Object.assign({}, state, {
        consumerdetails: {},
        underwriting: {},
        profile_pic: {},
      });
    default:
      return { ...state };
  }
};
