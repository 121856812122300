import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { commonApiCall } from "../../../redux/actions";
import { showErrorToast } from "../../../utils";
import ApplicationStatus from "../../approvalStatus/applicationStatus";
import { API_URL_UNDERWRITING } from "../../../config/configs";
import { Modal } from "react-bootstrap";
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";

const DenialPage = ({ underwriting, commonApiCall, t, ...props }) => {
  const { applicationId } = underwriting;
  const appDecision = new URLSearchParams(window.location.search).get(
    "decision"
  );

  const [currentDocument, setCurrentDocument] = useState({
    index: 0,
    title: null,
    file: null,
  });

  const [documentLoading, setDocumentLoading] = useState(false);
  const [showDocuments, setShowDocument] = useState(false);
  const [documents, setDocuments] = useState([]);

  const onDocumentNext = () => {
    if (currentDocument.index + 1 <= documents.length) {
      setCurrentDocument({
        index: currentDocument.index + 1,
        title: Object.keys(documents[currentDocument.index + 1])[0],
        file: documents[currentDocument.index + 1][
          Object.keys(documents[currentDocument.index + 1])[0]
        ],
      });
    }
  };

  const onDocumentBack = () => {
    if (currentDocument.index > 0) {
      setCurrentDocument({
        index: currentDocument.index - 1,
        title: Object.keys(documents[currentDocument.index - 1])[0],
        file: documents[currentDocument.index - 1][
          Object.keys(documents[currentDocument.index - 1])[0]
        ],
      });
    }
  };

  const toggleModal = () => setShowDocument(!showDocuments);

  function isBase64(str = "") {
    try {
      return btoa(atob(str)) == str;
    } catch (err) {
      return false;
    }
  }

  const base64toBlob = (data) => {
    // Cut the prefix `data:application/pdf;base64` from the raw base 64
    const base64WithoutPrefix = data;

    // // .substr(
    //   "data:application/pdf;base64,".length
    // );

    const bytes = atob(base64WithoutPrefix);
    let length = bytes.length;
    let out = new Uint8Array(length);

    while (length--) {
      out[length] = bytes.charCodeAt(length);
    }

    return new Blob([out], { type: "application/pdf" });
  };

  // useEffect(() => {
  //   if (isBase64(currentDocument.file)) {
  //     let blob = base64toBlob(currentDocument.file);
  //     let url = URL.createObjectURL(blob);
  //     setCurrentDocument({ ...currentDocument, file: url });
  //   }
  // }, [currentDocument]);

  const getDocuments = async () => {
    setDocumentLoading(true);
    try {
      let response = await commonApiCall(
        `${API_URL_UNDERWRITING}getDocument?applicationId=${underwriting?.applicationId}&id=${appDecision}`,
        "get",
        {},
        ""
      );
      if (response.status.code === 200) {
        const data = JSON.parse(response.data.formsJson);
        if (data && data.length > 0) {
          setCurrentDocument({
            index: 0,
            title: Object.keys(data[0])[0],
            file: data[0][Object.keys(data[0])[0]],
          });
          setShowDocument(true);
          setDocuments(data);
        }
      } else {
        showErrorToast(response.status.message);
      }
    } catch (err) {
      showErrorToast(err);
    }
    setDocumentLoading(false);
  };
  return (
    <div className="denial-page-container">
      <div className="d-flex justify-content-center">
        <div>
          {appDecision == 2 || appDecision == 3 || appDecision == 4 ? (
            <>
              <p className="text-center">
                We're sorry. Based on the application, we are unable to offer
                you a policy.
              </p>
              <p>
                {" "}
                Please{" "}
                <button
                  className="bg-white btn-link text-primary p-0"
                  onClick={() => {
                    if (appDecision) {
                      getDocuments();
                    }
                  }}
                >
                  {documentLoading ? "Processing..." : "click here"}
                </button>{" "}
                for information about how to continue with your application
              </p>
            </>
          ) : (
            <p>
              We're sorry. Based on your application, we are unable to offer you
              a policy.
            </p>
          )}
          {/* <p className="p-0">
            Please <a className="text-link text-primary">click here</a> for
            information about how to continue with your application.
          </p> */}
        </div>
        <img
          className="ml-2"
          style={{ width: 50 }}
          src="/assets/images/umbrella.png"
        />
      </div>
      <ApplicationStatus t={t} sbli={true} />
      <Modal
        className="pdf-modal"
        show={showDocuments}
        onHide={() => toggleModal()}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{t()}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="pdf-container mt-0 mb-0">
            {documentLoading ? (
              <div className="text-center">
                <h2>Processsing Documents</h2>
                <p>This may take few minutes</p>
              </div>
            ) : documents.length > 0 ? (
              <div style={{ height: 500, width: "100%", overflow: "auto" }}>
                <DocViewer
                  style={{
                    height: 500,
                  }}
                  config={{
                    header: {
                      disableHeader: true,
                      disableFileName: true,
                    },
                  }}
                  pluginRenderers={DocViewerRenderers}
                  documents={[
                    {
                      uri:
                        "data:application/pdf;base64," + currentDocument.file,
                    },
                  ]}
                />
              </div>
            ) : (
              // <iframe
              //   src={currentDocument.file}
              //   width={"100%"}
              //   height={500}
              //   title={currentDocument.title}
              // ></iframe>
              <h2>No Documents</h2>
            )}
          </div>
          <div className="pdf-container-button py-2">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div className="d-flex align-items-center ml-2">
                {documents.length > 0 && currentDocument.index !== 0 && (
                  <img
                    onClick={onDocumentBack}
                    src="/assets/images/next.png"
                    className="mr-2"
                    style={{ width: 15, cursor: "pointer" }}
                  />
                )}
                <p className="p-0">
                  {currentDocument.index + 1 + "/" + documents.length}
                </p>
              </div>

              {/* <div>
              <button
                type="button"
                className="btn btn-link"
                // onClick={onSavePdf}
              >
                <img src="/assets/images/save.png" /> Save
              </button>
              <button className="btn btn-link">
                <img src="/assets/images/print.png" /> Print
              </button>
            </div> */}
            </div>
          </div>
          {documents.length > 1 && (
            <div className="w-100 text-center mt-4">
              <button
                disabled={currentDocument.index + 1 >= documents.length}
                className="btn btn-default"
                onClick={onDocumentNext}
              >
                Next
              </button>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => ({
  underwriting: state.underwriting.underwriting,
  progressCount: state.underwriting.progressCount,
});

export default connect(mapStateToProps, {
  commonApiCall,
})(withRouter(DenialPage));
