import React from "react";
import { Button, Modal } from "react-bootstrap";

const DeleteModal = ({
  show,
  onHide,
  size,
  title,
  bodyText,
  onConfirm,
  disabled,
}) => (
  <Modal show={show} onHide={onHide} centered size={size}>
    <Modal.Header closeButton>
      <Modal.Title>{title}</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <p>{bodyText}</p>
    </Modal.Body>
    <Modal.Footer>
      <Button variant='btn btn-primary' onClick={onHide} disabled={disabled}>
        Cancel
      </Button>
      <Button variant='btn btn-primary' onClick={onConfirm} disabled={disabled}>
        Confirm
      </Button>
    </Modal.Footer>
  </Modal>
);

export default DeleteModal;
