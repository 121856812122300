import React, { useState, useEffect, useRef } from "react";
import DatePicker from "react-datepicker";
import { Animated } from "react-animated-css";
import NumberFormat from "react-number-format";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import moment from "moment";
import { isEmpty, upperFirst } from "lodash";
import Select from "react-select";
import OtpInput from "react-otp-input";
import NavBar from "../../components/navBar";
import ImageCrop from "./imageCrop";
import {
  commonApiCall,
  profilePicture,
  updateConsumer,
} from "../../redux/actions/index";
import {
  encryptme,
  decryptme,
  customFilter,
  showErrorToast,
  showSuccessToast,
  getState,
  validateMobileNumber,
} from "../../utils/index";
import { API_URL_CLIENT } from "../../config/configs";
import DeleteModal from "../../components/deleteModal";

function MyProfile({
  commonApiCall,
  consumer,
  profilePicture,
  updateConsumer,
  t,
}) {
  const [formData, setFormData] = useState({
    profilePic: "",
    drivingLicense: "",
    firstName: "",
    lastName: "",
    gender: "",
    email: "",
    dob: null,
    heightFt: "",
    heightInch: "",
    weightLb: "",
    street: "",
    address2: "",
    cityId: "",
    city: "",
    stateId: "",
    postalCode: "",
    countryCode: "+1",
    securityNum: "",
    mobile: "",
    isAgree: false,
    otp: "",
    drivingLicenseStateId: "",
    errors: {},
    verificationModal: false,
    userId: "",
    formData_img: "",
    emailEdit: false,
    mobileEdit: false,
    setMobile: false,
    setEmail: false,
  });

  const [formInitialData, setFormInitialData] = useState({});
  const [stateDropdown, setStateDropdown] = useState([]);
  const [drivingStateDropdown, setDrivingStateDropdown] = useState([]);
  // const [cityDropdown, setCityDropdown] = useState([]);
  const [mobileValue, setMobileValue] = useState("");
  const [emailValue, setEmailValue] = useState("");
  const [isLoaderShowing, setLoaderShowing] = useState(false);
  const [showDeleteModal, setDeleteModal] = useState(false);
  const [isDeleteDisabled, setDeleteDisable] = useState(false);
  const [cropped] = useState("");

  const formRef = useRef(null);
  const firstNameRef = useRef(null);
  const lastNameRef = useRef(null);
  const emailRef = useRef(null);
  const heightFtRef = useRef(null);
  const heightInchRef = useRef(null);
  const weightLbRef = useRef(null);
  const streetRef = useRef(null);
  const postalCodeRef = useRef(null);
  const cityRef = useRef(null);

  useEffect(() => {
    getUserDetails();
  }, []);

  const scrollToInput = () => {
    const { errors } = formData;
    if (!isEmpty(errors)) {
      const formFields = [
        firstNameRef,
        lastNameRef,
        emailRef,
        heightFtRef,
        heightInchRef,
        weightLbRef,
        streetRef,
        postalCodeRef,
      ];
      const emptyField = formFields.find((ref) => !ref.current.value);
      if (emptyField) {
        const fieldRect = emptyField.current.getBoundingClientRect();
        const fieldTop = fieldRect.top + window.pageYOffset;
        const scrollOffset = fieldTop - window.innerHeight / 2;
        window.scrollTo({ top: scrollOffset, behavior: "smooth" });
        emptyField.current.focus();
      }
    }
  };

  useEffect(() => {
    let currentFormData = {
      drivingLicense: formData.drivingLicense,
      firstName: formData.firstName,
      lastName: formData.lastName,
      email: formData.email,
      gender: formData.gender,
      city: formData.city,
      dob: formData.dob,
      heightFt: formData.heightFt,
      heightInch: formData.heightInch,
      weightLb: formData.weightLb,
      street: formData.street,
      address2: formData.address2,
      stateId: formData.stateId,
      drivingLicenseStateId: formData.drivingLicenseStateId,
      postalCode: formData.postalCode,
      securityNum: formData.securityNum,
      mobile: formData.mobile,
    };

    if (JSON.stringify(currentFormData) == JSON.stringify(formInitialData)) {
      setLoaderShowing(true);
    } else {
      setLoaderShowing(false);
    }
  }, [formData, formInitialData]);

  const getUserDetails = async () => {
    const authorization = true;
    try {
      let response = await commonApiCall(
        API_URL_CLIENT + "getUsersProfile",
        "",
        "get",
        "",
        authorization
      );
      if (response.status.code === 200) {
        let {
          drivingLicense,
          firstName,
          lastName,
          email,
          id,
          gender,
          dob,
          height,
          weight,
          address1,
          address2,
          city,
          state,
          postalCode,
          ssn,
          mobile,
          drivingLicenseState,
          profilePic,
        } = response.data;
        let gender_ = {},
          state_ = {},
          city_ = {},
          drivingState_ = {};
        if (gender != null && (await decryptme(gender)) === "MALE") {
          gender_.value = "MALE";
          gender_.label = "Male";
        } else if (gender != null && (await decryptme(gender)) === "FEMALE") {
          gender_.value = "FEMALE";
          gender_.label = "Female";
        }

        if (!isEmpty(state)) {
          state_.value = state.stateId;
          state_.label = state.stateName;
        }

        if (!isEmpty(city)) {
          city_.value = city.cityId;
          city_.label = city.cityName;
        }

        if (!isEmpty(drivingLicenseState)) {
          drivingState_.value = drivingLicenseState.stateId;
          drivingState_.label = drivingLicenseState.stateName;
        }
        setFormInitialData({
          drivingLicense: drivingLicense ? await decryptme(drivingLicense) : "",
          firstName: firstName ? await decryptme(firstName) : "",
          lastName: lastName ? await decryptme(lastName) : "",
          email: email ? await decryptme(email) : "",
          gender: gender_,
          city: city ? await decryptme(city) : "",
          dob: dob ? new Date(await decryptme(dob)) : null,
          heightFt: height ? await decryptme(height).split(".")[0] : "",
          heightInch: height ? await decryptme(height).split(".")[1] : "",
          weightLb: weight ? await decryptme(weight) : "",
          street: address1 ? await decryptme(address1) : "",
          address2: address2 != null ? await decryptme(address2) : "",
          stateId: state_ ? await state_ : "",
          drivingLicenseStateId: drivingState_ ? drivingState_ : "",
          postalCode: postalCode ? await decryptme(postalCode) : "",
          securityNum: ssn ? await decryptme(ssn) : "",
          mobile: mobile ? await decryptme(mobile) : "",
        });
        setFormData({
          ...formData,
          profilePic: profilePic ? profilePic : "",
          drivingLicense: drivingLicense ? await decryptme(drivingLicense) : "",
          firstName: firstName ? await decryptme(firstName) : "",
          lastName: lastName ? await decryptme(lastName) : "",
          email: email ? await decryptme(email) : "",
          gender: gender_,
          city: city ? await decryptme(city) : "",
          dob: dob ? new Date(await decryptme(dob)) : null,
          heightFt: height ? await decryptme(height).split(".")[0] : "",
          heightInch: height ? await decryptme(height).split(".")[1] : "",
          weightLb: weight ? await decryptme(weight) : "",
          street: address1 ? await decryptme(address1) : "",
          address2: address2 != null ? await decryptme(address2) : "",
          stateId: state_ ? await state_ : "",
          drivingLicenseStateId: drivingState_ ? drivingState_ : "",
          postalCode: postalCode ? await decryptme(postalCode) : "",
          securityNum: ssn ? await decryptme(ssn) : "",
          mobile: mobile ? await decryptme(mobile) : "",
          userId: id,
        });
        setMobileValue(mobile ? await decryptme(mobile) : "");
        setEmailValue(email ? await decryptme(email) : "");
        let reduxStore = {
          profilePic: profilePic,
          email: email ? await decryptme(email) : "",
          firstName: firstName ? await decryptme(firstName) : "",
          lastName: lastName ? await decryptme(lastName) : "",
        };
        await profilePicture(reduxStore);
      }
    } catch (err) {
      showErrorToast(t("my_profile.something_went_wrong"));
    }
  };

  const GetCountryCodes = async () => {
    return await commonApiCall(API_URL_CLIENT + "getCountry", "", "get");
  };
  const handleState = async (whichState) => {
    await GetCountryCodes();
    let response = await commonApiCall(
      API_URL_CLIENT + "getState?countryId=" + 1,
      "",
      "get"
    );
    var new_options = [];
    response.data &&
      response.data.map((element) => {
        new_options.push({
          value: element.id,
          label: element.name,
        });
        return true;
      });
    if (whichState === "drivingState") setDrivingStateDropdown(new_options);
    else setStateDropdown(new_options);
    return new_options;
  };

  const handleChange = (e) => {
    if (e.target.value) {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
        errors: Object.assign(formData.errors, { [e.target.name]: "" }),
      });
    } else
      setFormData({
        ...formData,
        [e.target.name]: "",
      });
  };
  const validateForm = (specificField = "") => {
    let {
      drivingLicense,
      firstName,
      lastName,
      dob,
      gender,
      email,
      heightFt,
      heightInch,
      weightLb,
      street,
      city,
      // stateId,
      postalCode,
      mobile,
      securityNum,
    } = formData;
    let errors = {},
      isValid = true;
    var pattern = /^[ ]*([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})[ ]*$/i;
    var postral = new RegExp(/^.{5,6}$/);

    if (specificField === "") {
      if (!drivingLicense || drivingLicense.trim() === "") {
        if (moment().diff(dob, "years") >= 18) {
          isValid = false;
          errors["drivingLicense"] = t(
            "my_profile.enter_driver's_license_number"
          );
        }
      }
      if (!firstName || firstName.trim() === "") {
        isValid = false;
        errors["firstName"] = t("my_profile.enter_first_name");
      }
      if (!lastName || lastName.trim() === "") {
        isValid = false;
        errors["lastName"] = t("my_profile.enter_last_name");
      }
      if (isEmpty(gender)) {
        isValid = false;
        errors["gender"] = t("my_profile.select_gender");
      }
      if (!pattern.test(email)) {
        isValid = false;
        if (!email || email.trim() === "") {
          errors["email"] = t("my_profile.enter_email");
        } else errors["email"] = t("my_profile.enter_a_valid_email");
      }
      if (!heightFt) {
        isValid = false;
        errors["heightFt"] = t("my_profile.enter_height_feet");
      }
      if (!heightInch) {
        isValid = false;
        errors["heightInch"] = t("my_profile.enter_height_inch");
      }
      if (!weightLb) {
        isValid = false;
        errors["weightLb"] = t("my_profile.enter_Weight_lb");
      }
      if (!street || street.trim() === "") {
        isValid = false;
        errors["street"] = t("my_profile.enter_street_address");
      }

      if ((city && city === null) || isEmpty(city)) {
        isValid = false;
        errors["city"] = t("my_profile.enter_city");
      }
      // if ((stateId && stateId.label === null) || isEmpty(stateId)) {
      //   isValid = false;
      //   errors["stateId"] = t("my_profile.select_state");
      // }

      if (!postral.test(postalCode)) {
        isValid = false;
        if (!postalCode || postalCode.trim() === "") {
          errors["postalCode"] = t("my_profile.enter_postal/zip_code");
        } else
          errors["postalCode"] = t("my_profile.enter_valid_postal/zip_code");
      }
      if (postalCode.trim() !== "" && !getState(postalCode)) {
        isValid = false;
        errors["postalCode"] = t("my_profile.enter_valid_postal/zip_code");
      }
      if (mobile.toString().length !== 10) {
        isValid = false;
        if (!mobile) {
          errors["mobile"] = t("my_profile.enter_mobile");
        } else errors["mobile"] = t("my_profile.enter_10_digits_mobile_number");
      }

      if (!drivingLicenseStateId.value) {
        if (moment().diff(dob, "years") >= 18) {
          isValid = false;
          errors["drivingLicenseStateId"] = t(
            "my_profile.please_select_driving"
          );
        }
      }

      if (
        !isEmpty(securityNum) &&
        !validateMobileNumber(securityNum) &&
        securityNum.toString().length !== 9
      ) {
        isValid = false;
        errors["securityNum"] = t("my_profile.enter_valid_security_num");
      }
    } else if (specificField === "email") {
      if (!pattern.test(email)) {
        isValid = false;
        if (!email || email.trim() === "") {
          errors["email"] = t("my_profile.enter_email");
        } else errors["email"] = t("my_profile.enter_a_valid_email");
      }
    } else if (specificField === "mobile") {
      if (mobile.toString().length !== 10) {
        isValid = false;
        if (!mobile) {
          errors["mobile"] = t("my_profile.enter_mobile");
        } else errors["mobile"] = t("my_profile.enter_10_digits_mobile_number");
      }
    }
    scrollToInput();
    setFormData({ ...formData, errors: errors });
    return isValid;
  };

  const continueNext = async (event) => {
    event.preventDefault();
    setFormData({ ...formData, otp: "" });
    const {
      userId,
      drivingLicense,
      firstName,
      lastName,
      dob,
      email,
      gender,
      heightFt,
      heightInch,
      weightLb,
      street,
      address2,
      city,
      // stateId,
      postalCode,
      securityNum,
      drivingLicenseStateId,
    } = formData;

    if (validateForm()) {
      const request = {
        dob: dob ? await encryptme(moment(dob).format("L").toString()) : null,
        id: userId,
        address2: await encryptme(address2),
        city: await encryptme(city),
        stateId: getState(postalCode).id,
        firstName: await encryptme(upperFirst(firstName)),
        gender: await encryptme(gender.value),
        ssn: securityNum ? await encryptme(securityNum) : null,
        weight: await encryptme(weightLb),
        postalCode: await encryptme(postalCode),
        drivingLicense: await encryptme(drivingLicense),
        address1: await encryptme(street),
        lastName: await encryptme(upperFirst(lastName)),
        countryCode: await encryptme("+1"),
        height: await encryptme(heightFt + "." + heightInch),
        drivingLicenseStateId:
          drivingLicenseStateId && drivingLicenseStateId.value,
      };
      setLoaderShowing(true);
      const authorization = true;
      const response = await commonApiCall(
        API_URL_CLIENT + "saveUserClientProfile",
        "put",
        request,
        "",
        authorization
      );
      if (response.status && response.status.code === 200) {
        showSuccessToast(t("my_profile.profile_updated_successfully"));
        getUserDetails();
        var reduxStore1 = {
          profilePic: profilePic,
          email: email,
          firstName: firstName,
          lastName: lastName,
        };
        await profilePicture(reduxStore1);
      } else if (response.status && response.status.code === 409) {
        showErrorToast(response.status.message);
      }
      setLoaderShowing(false);
    }
  };
  const deleteFile = async () => {
    let authorization = true;
    setDeleteDisable(true);
    const response = await commonApiCall(
      API_URL_CLIENT + "deleteImageFile?uploadedImageType=PROFILE",
      "delete",
      "",
      "",
      authorization
    );
    if (response && response.status.code === 200) {
      showSuccessToast("Profile image deleted successfully");
      setFormData({ ...formData, profilePic: "", errors: {} });
    }
    setDeleteDisable(false);
    setDeleteModal(false);
    var reduxStore = {
      profilePic: "",
      email: email,
      firstName: firstName,
    };
    await profilePicture(reduxStore);
  };

  const editEmailorPhone = async (value) => {
    let authorization = true;
    let reqParams = {},
      isChanged = true;
    if (value === "email" || formData.setEmail) {
      if (formData.email === decryptme(consumer.email)) isChanged = false;
      reqParams = {
        type: "Email",
        email: await encryptme(formData.email),
      };
    }
    if (value === "mobile" || formData.setMobile) {
      if (formData.mobile === decryptme(consumer.mobile)) isChanged = false;
      reqParams = {
        type: "Mobile",
        mobile: await encryptme(formData.mobile),
        countryCode: await encryptme("+1"),
      };
    }
    if (isChanged) {
      if (validateForm(value)) {
        const response = await commonApiCall(
          API_URL_CLIENT + "profileUpdateMobileEmail",
          "post",
          reqParams,
          "",
          authorization
        );
        if (response && response.status.code === 200) {
          if (response.data && response.data.otp && response.data.otp !== "")
            showSuccessToast(
              `The login code sent successfully ${response.data.otp}`
            );
          else showSuccessToast(t("my_profile.OTP_has_sent_successfully"));

          if (value === "email") {
            setFormData({
              ...formData,
              verificationModal: true,
              setEmail: true,
              setMobile: false,
              emailEdit: false,
              mobileEdit: false,
            });
          } else if (value === "mobile") {
            setFormData({
              ...formData,
              verificationModal: true,
              setEmail: false,
              setMobile: true,
              emailEdit: false,
              mobileEdit: false,
            });
          }
        } else if (response.status.code === 409) {
          if (value === "email")
            showErrorToast(t("my_profile.email_already_exists"));
          else showErrorToast(t("my_profile.mobile_already_exists"));
        } else if (response.status.code === 202) {
          if (value === "email")
            showErrorToast(t("my_profile.email_already_exists"));
          else showErrorToast(t("my_profile.mobile_already_exists"));
        }
      }
    } else {
      if (value === "email") {
        setFormData({
          ...formData,
          setEmail: true,
          setMobile: false,
          emailEdit: false,
          mobileEdit: false,
        });
      } else if (value === "mobile") {
        setFormData({
          ...formData,
          setEmail: false,
          setMobile: true,
          emailEdit: false,
          mobileEdit: false,
        });
      }
    }
  };

  const validateEmailorPhone = async () => {
    const { email, mobile, setMobile, setEmail } = formData;
    let authorization = true,
      consumerDetails = consumer,
      reqParams = {};
    if (setMobile) {
      reqParams = {
        type: "Mobile",
        mobile: await encryptme(mobile),
        countryCode: await encryptme("+1"),
        otp: formData.otp,
      };
      consumerDetails.mobile = encryptme(mobile);
    } else if (setEmail) {
      reqParams = {
        type: "Email",
        email: await encryptme(email),
        otp: formData.otp,
      };
      consumerDetails.email = encryptme(email);
    }
    const response = await commonApiCall(
      API_URL_CLIENT + "verifiedMobileEmail",
      "post",
      reqParams,
      "",
      authorization
    );
    if (response && response.status.code === 200) {
      setFormData({
        ...formData,
        verificationModal: false,
        otp: "",
        emailEdit: false,
        mobileEdit: false,
        setMobile: false,
        setEmail: false,
      });

      await updateConsumer(consumerDetails);
    } else if (response.status.code === 400) {
      showErrorToast(response.status.message);
    }
  };

  const setProfilePic = (val) => {
    setFormData({ ...formData, profilePic: val });
  };
  let {
    profilePic,
    drivingLicense,
    firstName,
    lastName,
    dob,
    email,
    gender,
    heightFt,
    heightInch,
    weightLb,
    street,
    address2,
    verificationModal,
    city,
    // stateId,
    postalCode,
    securityNum,
    mobile,
    errors,
    emailEdit,
    mobileEdit,
    otp,
    setMobile,
    setEmail,
    drivingLicenseStateId,
  } = formData;
  return (
    <React.Fragment>
      <div className="row">
        <div className="col-3 sidebar-part">
          <NavBar t={t}></NavBar>
        </div>
        <div className="col-lg-9 my-profile-page">
          <Animated
            animationIn="fadeIn"
            animationOut="faderOutLeft"
            animationInDuration={1200}
            animationOutDuration={800}
            isVisible={true}
          >
            <div className="white-box2">
              <h3 className="font-24 box-main-title">
                {t("my_profile.my_profile")}
              </h3>

              {cropped && (
                <img alt="Crop" style={{ maxWidth: "100%" }} src={cropped} />
              )}
              <div className="profile-details">
                <div className="profile-pic">
                  <img
                    src={
                      profilePic !== ""
                        ? profilePic
                        : "/assets/images/user-dp-demo.jpg"
                    }
                    alt={t("my_profile.user_profile")}
                    title={t("my_profile.user_profile")}
                  />
                  <ImageCrop
                    email={email}
                    firstName={firstName}
                    setProfilePic={setProfilePic}
                    t={t}
                  />
                </div>
                {profilePic && profilePic !== "" ? (
                  <span
                    className="btn btn-link"
                    onClick={() => setDeleteModal(true)}
                  >
                    {t("my_profile.remove")}
                  </span>
                ) : null}
              </div>
              <div className="more-info-wrapper">
                <h4 className="text-medium">
                  {t("my_profile.personal_information")}
                </h4>
                <form className="row" ref={formRef}>
                  <div className="col-lg-6 col-md-6">
                    {/*Begin: Form Group - Driver's License Number */}
                    <div className="form-group">
                      <div className="input-effect">
                        <input
                          className="form-control"
                          placeholder={t("my_profile.driver's_license_number")}
                          name="drivingLicense"
                          id="Driver'sLicenseNumber"
                          type="text"
                          maxLength={15}
                          value={drivingLicense}
                          onChange={(e) => handleChange(e)}
                        />
                        <label htmlFor="Driver'sLicenseNumber">
                          {t("my_profile.driver's_license_number")}
                        </label>
                        <span className="text-danger ">
                          {errors.drivingLicense}
                        </span>
                      </div>
                    </div>
                    {/*End: Form Group - Driver's License Number */}
                  </div>
                  <div className="col-lg-6 col-md-6">
                    {/*Begin: Form Group - First Name */}
                    <div className="form-group">
                      <div className="input-effect">
                        <input
                          className="form-control"
                          placeholder={t("my_profile.first_name")}
                          name="firstName"
                          id="firstName"
                          type="text"
                          value={firstName}
                          onChange={(e) => handleChange(e)}
                          ref={firstNameRef}
                          maxLength={50}
                        />
                        <label htmlFor="firstName">
                          {t("my_profile.first_name")}
                        </label>
                        <span className="text-danger ">{errors.firstName}</span>
                      </div>
                    </div>
                    {/*End: Form Group - First Name */}
                  </div>
                  <div className="col-lg-6 col-md-6">
                    {/*Begin: Form Group - Last Name */}
                    <div className="form-group">
                      <div className="input-effect">
                        <input
                          className="form-control"
                          placeholder={t("my_profile.last_name")}
                          name="lastName"
                          id="lastName"
                          type="text"
                          value={lastName}
                          onChange={(e) => handleChange(e)}
                          ref={lastNameRef}
                          maxLength={50}
                        />
                        <label htmlFor="lastName">
                          {t("my_profile.last_name")}
                        </label>
                        <span className="text-danger ">{errors.lastName}</span>
                      </div>
                    </div>
                    {/*End: Form Group - Last Name */}
                  </div>
                  <div className="col-lg-6 col-md-6">
                    {/*Begin: Form Group - Email */}
                    <div className="form-group">
                      <div className="input-effect with-icon">
                        <input
                          className="form-control"
                          placeholder={t("my_profile.email")}
                          name="email"
                          id="email"
                          type="mail"
                          value={email}
                          onChange={(e) => handleChange(e)}
                          disabled={emailEdit ? false : true}
                          ref={emailRef}
                        />
                        <label htmlFor="email">{t("my_profile.email")}</label>
                        <span className="text-danger ">{errors.email}</span>
                        {emailEdit ? (
                          <React.Fragment>
                            <button
                              className="edit-input btn btn-primary btn-sm"
                              style={{ right: "80px" }}
                              type="button"
                              onClick={() => editEmailorPhone("email")}
                            >
                              {t("my_profile.save")}
                            </button>
                            <button
                              className="edit-input btn btn-secondary btn-sm"
                              type="button"
                              onClick={() =>
                                setFormData({
                                  ...formData,
                                  emailEdit: false,
                                  email: emailValue,
                                })
                              }
                            >
                              {t("my_profile.cancel")}
                            </button>
                          </React.Fragment>
                        ) : (
                          <button
                            className="edit-input btn btn-primary btn-sm"
                            type="button"
                            onClick={() =>
                              setFormData({ ...formData, emailEdit: true })
                            }
                          >
                            {t("my_profile.edit")}
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                  {/*End: Form Group - Last Name */}

                  <div className="col-lg-6 col-md-6">
                    {/*Begin: Form Group - Email */}
                    <div className="form-group fixed-floating-label mb-4">
                      <div className="default-select with-border">
                        <Select
                          options={[
                            { value: "MALE", label: "Male" },
                            { value: "FEMALE", label: "Female" },
                          ]}
                          className={"custom-select-menu"}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              gender: e,
                              errors: Object.assign(formData.errors, {
                                gender: "",
                              }),
                            })
                          }
                          isSearchable={false}
                          classNamePrefix={"custom-select"}
                          value={gender}
                        />
                        <span className="fixed-label">
                          {t("my_profile.gender")}
                        </span>
                        <span className="text-danger ">{errors.gender}</span>
                      </div>
                    </div>
                    {/*End: Form Group - Last Name */}
                  </div>

                  <div className="col-lg-6 col-md-6">
                    {/*Begin: Form Group - Date of Birth */}
                    <div className="form-group custom-datepicker">
                      <DatePicker
                        selected={dob}
                        onChange={(date) =>
                          setFormData({ ...formData, dob: date })
                        }
                        placeholderText={"MM/DD/YYYY"}
                        maxDate={new Date()}
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        dateFormatCalendar="MMMM"
                      />
                      <span className="datepicker-label">
                        {t("my_profile.date_of_birth")}
                      </span>
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-6">
                    <div className="form-group">
                      <div className="input-effect">
                        <NumberFormat
                          className="form-control"
                          placeholder={t("my_profile.height_feet")}
                          aria-describedby="basic-addon2"
                          format="#"
                          value={heightFt}
                          isAllowed={(values) => {
                            const { formattedValue, floatValue } = values;
                            return formattedValue === "" || floatValue <= 7;
                          }}
                          onValueChange={(e) =>
                            handleChange({
                              target: { name: "heightFt", value: e.value },
                            })
                          }
                          id="height_feet"
                          getInputRef={heightFtRef}
                        />
                        <label>{t("my_profile.height_feet")}</label>
                        <span className="text-danger ">{errors.heightFt}</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6">
                    <div className="form-group">
                      <div className="input-effect">
                        <NumberFormat
                          className="form-control"
                          placeholder={t("my_profile.height_inch")}
                          aria-describedby="basic-addon2"
                          format="##"
                          value={heightInch}
                          isAllowed={(values) => {
                            const { formattedValue, floatValue } = values;
                            return formattedValue === "" || floatValue <= 11;
                          }}
                          onValueChange={(e) =>
                            handleChange({
                              target: { name: "heightInch", value: e.value },
                            })
                          }
                          getInputRef={heightInchRef}
                          id="height_inch"
                        />
                        <label>{t("my_profile.height_inch")}</label>
                        <span className="text-danger ">
                          {errors.heightInch}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <div className="form-group">
                      <div className="input-effect">
                        <NumberFormat
                          className="form-control"
                          placeholder={t("my_profile.weight_lbs")}
                          aria-describedby="basic-addon2"
                          format="###"
                          value={weightLb}
                          isAllowed={(values) => {
                            const { formattedValue, floatValue } = values;
                            return formattedValue === "" || floatValue <= 500;
                          }}
                          onValueChange={(e) =>
                            handleChange({
                              target: { name: "weightLb", value: e.value },
                            })
                          }
                          id="weight_lbs"
                          getInputRef={weightLbRef}
                        />
                        <label>{t("my_profile.weight_lbs")}</label>
                        <span className="text-danger ">{errors.weightLb}</span>
                      </div>
                    </div>
                  </div>
                </form>
                <h4 className="text-medium">
                  {t("my_profile.address_information")}
                </h4>
                <form className="row">
                  <div className="col-lg-6 col-md-6">
                    <div className="form-group">
                      <div className="input-effect">
                        <input
                          className="form-control"
                          placeholder={t("my_profile.street_address")}
                          name="street"
                          id="street"
                          type="text"
                          value={street}
                          onChange={(e) => handleChange(e)}
                          ref={streetRef}
                        />
                        <label>{t("my_profile.street_address")}</label>
                        <span className="text-danger ">{errors.street}</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <div className="form-group">
                      <div className="input-effect">
                        <input
                          className="form-control"
                          placeholder={t("my_profile.address_line_2")}
                          name="address2"
                          id="addressLine2"
                          type="text"
                          value={address2}
                          onChange={(e) => handleChange(e)}
                        />
                        <label>{t("my_profile.address_line_2")}</label>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6">
                    <div className="form-group fixed-floating-label mb-4">
                      <div className="input-effect">
                        <input
                          className="form-control"
                          placeholder={t("my_profile.postal/zip_code")}
                          name="postalCode"
                          id="postalCode"
                          type="text"
                          maxLength="5"
                          value={postalCode}
                          onChange={(e) => handleChange(e)}
                          ref={postalCodeRef}
                        />
                        <label>{t("my_profile.postal/zip_code")}</label>
                        <span className="text-danger ">
                          {errors.postalCode}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <div className="form-group fixed-floating-label mb-4">
                      <div className="default-select with-border">
                        <Select
                          options={stateDropdown}
                          className={"custom-select-menu"}
                          isSearchable={true}
                          filterOption={(e, searchWith) =>
                            customFilter(e, searchWith)
                          }
                          onFocus={handleState}
                          onChange={(value) =>
                            setFormData({
                              ...formData,
                              stateId: value,
                              cityId: {},
                              errors: Object.assign(formData.errors, {
                                stateId: "",
                              }),
                            })
                          }
                          classNamePrefix={"custom-select"}
                          // value={stateId}
                          value={
                            postalCode != ""
                              ? {
                                  value: getState(postalCode)?.id,
                                  label: getState(postalCode)?.state,
                                }
                              : ""
                          }
                          isDisabled={true}
                        />
                        <span className="fixed-label">
                          {t("my_profile.state")}
                        </span>
                        <span className="text-danger ">{errors.stateId}</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6">
                    <div className="form-group fixed-floating-label mb-4">
                      <div className="input-effect">
                        <input
                          className="form-control"
                          placeholder={t("my_profile.city")}
                          name="city"
                          id="city"
                          type="text"
                          value={city}
                          onChange={(e) => handleChange(e)}
                          ref={cityRef}
                        />
                        <label>{t("my_profile.city")}</label>
                        <span className="text-danger ">{errors.city}</span>
                      </div>
                    </div>
                  </div>
                </form>
                <h4 className="text-medium">
                  {t("my_profile.other_required_information")}
                </h4>
                <form className="row">
                  <div className="col-lg-6 col-md-6">
                    {/*Begin: Form Group - Social Security Number */}
                    <div className="form-group">
                      <div className="input-effect with-icon">
                        <NumberFormat
                          name="securityNum"
                          id="socialSecurityNumber"
                          className="form-control"
                          placeholder={t("my_profile.social_security_number")}
                          format="###-##-####"
                          value={securityNum}
                          onValueChange={(e) =>
                            handleChange({
                              target: { name: "securityNum", value: e.value },
                            })
                          }
                        />
                        <label htmlFor="socialSecurityNumber">
                          {t("my_profile.social_security_number")}
                        </label>
                        <span className="text-danger ">
                          {errors.securityNum}
                        </span>
                      </div>
                    </div>
                    {/*End: Form Group - Social Security Number */}
                  </div>
                  <div className="col-lg-6 col-md-6">
                    {/*Begin: Form Group - Mobile */}
                    <div className="form-group">
                      <div className="input-effect with-icon">
                        <NumberFormat
                          name="mobile"
                          id="mobile"
                          className="form-control"
                          placeholder={t("my_profile.mobile")}
                          format="(###) ###-####"
                          value={mobile}
                          onValueChange={(e) =>
                            handleChange({
                              target: { name: "mobile", value: e.value },
                            })
                          }
                          disabled={mobileEdit ? false : true}
                        />
                        <label htmlFor="mobile">{t("my_profile.mobile")}</label>
                        <span className="text-danger ">{errors.mobile}</span>
                        {mobileEdit ? (
                          <React.Fragment>
                            <button
                              className="edit-input btn btn-primary btn-sm"
                              style={{ right: "80px" }}
                              type="button"
                              onClick={() => editEmailorPhone("mobile")}
                            >
                              {t("my_profile.save")}
                            </button>
                            <button
                              className="edit-input btn btn-secondary btn-sm"
                              type="button"
                              onClick={() => {
                                setFormData({
                                  ...formData,
                                  mobileEdit: false,
                                  mobile: mobileValue,
                                });
                              }}
                            >
                              {t("my_profile.cancel")}
                            </button>
                          </React.Fragment>
                        ) : (
                          <button
                            className="edit-input btn btn-primary btn-sm"
                            type="button"
                            onClick={() =>
                              setFormData({ ...formData, mobileEdit: true })
                            }
                          >
                            {t("my_profile.edit")}
                          </button>
                        )}
                      </div>
                    </div>
                    {/*End: Form Group - Mobile */}
                  </div>

                  <div className="col-lg-6 col-md-6">
                    <div className="form-group fixed-floating-label mb-4">
                      <div className="default-select with-border">
                        <Select
                          options={drivingStateDropdown}
                          className={"custom-select-menu"}
                          isSearchable={true}
                          filterOption={(e, searchWith) =>
                            customFilter(e, searchWith)
                          }
                          onFocus={() => handleState("drivingState")}
                          onChange={(value) =>
                            setFormData({
                              ...formData,
                              drivingLicenseStateId: value,
                              errors: Object.assign(formData.errors, {
                                drivingLicenseStateId: "",
                              }),
                            })
                          }
                          classNamePrefix={"custom-select"}
                          value={drivingLicenseStateId}
                        />

                        <span className="fixed-label">
                          {t("my_profile.driving_license_state")}
                        </span>
                        <em className="input-icon"></em>
                        <span className="text-danger ">
                          {errors.drivingLicenseStateId}
                        </span>
                      </div>
                    </div>
                  </div>
                </form>
                <div className="col-12 p-0">
                  <button
                    className="btn btn-primary"
                    type="button"
                    onClick={(e) => continueNext(e)}
                    disabled={
                      emailEdit || mobileEdit || isLoaderShowing ? true : false
                    }
                  >
                    {t("my_profile.save")}
                  </button>
                </div>
                {/*End: Sign Up Form with Animate.css Element */}
              </div>
            </div>
          </Animated>
        </div>
        <Modal
          className="plan-modal verification-modal"
          show={verificationModal}
          onHide={() =>
            setFormData({ ...formData, verificationModal: false, otp: "" })
          }
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {t("my_profile.verify_your")} {setEmail ? "email" : null}{" "}
              {setMobile ? "mobile number" : null}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="signup-otp">
              <Animated
                animationIn="fadeInRight"
                animationOut="fadeOutLeft"
                animationInDuration={800}
                animationOutDuration={800}
                isVisible={true}
              >
                <div className="form-wrapper">
                  <div className="form-text">
                    <h3 className="card-text mb-5 text-center">
                      {t("my_profile.we’ve_sent_an_oTP_(One Time Password)_to")}{" "}
                      {setEmail ? (
                        <span className="text-link"> {email} </span>
                      ) : null}
                      {setMobile ? (
                        <span className="text-link">
                          {" "}
                          +1{" "}
                          {mobile
                            ? [
                                mobile.slice(0, 3),
                                " ",
                                mobile.slice(3, 6),
                                " ",
                                mobile.slice(6),
                              ].join("")
                            : null}{" "}
                        </span>
                      ) : null}{" "}
                      {t(
                        "my_profile.to_verify_your_account_please_enter_six_digit_code_below"
                      )}
                    </h3>
                  </div>
                  <form>
                    <div className="otp-input-wrapper">
                      {/*Begin: Form Group - Mobile */}
                      <OtpInput
                        onChange={(otp) =>
                          setFormData({ ...formData, otp: otp })
                        }
                        numInputs={6}
                        inputStyle={"otp-input"}
                        value={otp}
                        isInputNum={true}
                      />
                      {/*End: Form Group - Mobile */}
                    </div>
                    {/*Begin: Form Group - Terms and Policy */}
                    <div className="form-group">
                      <p className="text-center pb-0">
                        {t("my_profile.didn’t_receive_code_yet?")}
                        <button
                          className="btn btn-link text-primary"
                          type="button"
                          onClick={() => editEmailorPhone()}
                          style={{ textTransform: "none" }}
                        >
                          {t("my_profile.resend_OTP")}
                        </button>
                      </p>
                    </div>
                    {/*End: Form Group - Terms and Policy */}
                    {/*Begin: Sign Up Button */}
                    <div className="text-center">
                      <button
                        className="btn btn-primary btn-lg"
                        type="button"
                        onClick={() => validateEmailorPhone()}
                        disabled={otp.toString().length === 6 ? false : true}
                      >
                        {" "}
                        {t("my_profile.submit")}{" "}
                      </button>
                    </div>
                    {/*End: Sign Up Button */}
                  </form>
                </div>
              </Animated>
            </div>
          </Modal.Body>
        </Modal>
        {/*End: Sign Up Form Wrapper */}

        {/*Start: Delete Modal */}
        {showDeleteModal && (
          <DeleteModal
            show={showDeleteModal}
            onHide={() => setDeleteModal(false)}
            size="sm"
            title="Remove Profile Picture?"
            bodyText="Are you sure you want to permanently remove this Profile Picture? This action cannot be undone."
            onConfirm={deleteFile}
            disabled={isDeleteDisabled}
          />
        )}
        {/*End: Delete Modal */}
      </div>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  underwriting: state.underwriting.underwriting,
  consumer: state.consumer.consumerdetails,
  person_age: state.underwriting.person_age,
});

export default connect(mapStateToProps, {
  commonApiCall,
  profilePicture,
  updateConsumer,
})(withRouter(MyProfile));
