import React, { useEffect, useState } from "react";
import { decryptme, validateEmail, validateMobileNumber } from "../../utils";
import { isEmpty } from "lodash";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import NumberFormat from "react-number-format";

const SendMessage = ({
  consumer,
  sendMessage,
  onMessageReceived = false,
  profilePic,
}) => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobile: "",
    message: "",
    errors: {},
  });

  useEffect(() => {
    if (consumer?.email || (profilePic && profilePic?.firstName)) {
      setFormData({
        name: profilePic?.firstName + " " + profilePic?.lastName,
        email: decryptme(consumer?.email) || "",
        mobile: decryptme(consumer?.mobile) || "",
        message: decryptme(consumer?.message) || "",
        errors: {},
      });
    }
  }, [consumer, profilePic]);

  const handleChange = (e) => {
    if (e.target.value) {
      setFormData({
        ...formData,
        errors: Object.assign(formData.errors, { [e.target.name]: "" }),
      });
    }
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const validation = () => {
    const { name, email, message, mobile } = formData;
    let errors = {};
    if (formData.hasOwnProperty("name")) {
      if (isEmpty(name)) errors.name = t("validation_message.PROVIDE_NAME");
      else delete errors.name;
    }
    if (formData.hasOwnProperty("email")) {
      if (isEmpty(email)) errors.email = t("validation_message.PROVIDE_EMAIL");
      else if (!validateEmail(email))
        errors.email = t("validation_message.PROVIDE_VALID_EMAIL");
      else delete errors.email;
    }
    if (formData.hasOwnProperty("mobile")) {
      if (mobile && !validateMobileNumber(mobile))
        errors.mobile = t("validation_message.PROVIDE_VALID_MOBILE_NUMBER");
      else delete errors.mobile;
    }
    if (formData.hasOwnProperty("message")) {
      if (isEmpty(message))
        errors.message = t("validation_message.PROVIDE_MESSAGE");
      else delete errors.message;
    }
    const isValid = Object.keys(errors).length !== 0 ? false : true;
    setFormData({
      ...formData,
      errors: errors,
    });
    return isValid;
  };

  const onSubmit = () => {
    if (validation()) {
      sendMessage(true, formData);
    }
  };

  const { errors } = formData;

  return (
    <div>
      <p className="offlinemessage">
        {!onMessageReceived ? (
          "I’m offline at the moment, please leave your name and email and I’ll respond to your message as soon as possible."
        ) : (
          <div className="text-center">
            {" "}
            <h3>Message received!</h3>
            <p>
              Thank you for your message. I look forward to <br />
              responding soon.
            </p>{" "}
          </div>
        )}
      </p>
      {!onMessageReceived && (
        <div className="enquiryForm">
          <form>
            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <div className="input-effect with-icon">
                    <input
                      className="form-control"
                      placeholder="Name"
                      name="name"
                      onChange={handleChange}
                      id="name"
                      type="text"
                      value={formData.name}
                    />
                    <label htmlFor="name">{t("send_message.name")}</label>
                  </div>
                  <span className="text-danger">{errors.name}</span>
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <div className="input-effect with-icon">
                    <input
                      className="form-control"
                      placeholder="Email"
                      name="email"
                      id="email"
                      type="email"
                      onChange={handleChange}
                      value={formData.email}
                    />
                    <label htmlFor="email">{t("send_message.email")}</label>
                  </div>
                  <span className="text-danger">{errors.email}</span>
                </div>
              </div>

              <div className="col-md-12">
                <div className="form-group">
                  <div className="input-effect otp-inputs">
                    <NumberFormat
                      name="mobileNumber"
                      id="mobileNumber"
                      className="form-control"
                      placeholder="Mobile"
                      format="(###) ###-####"
                      value={formData.mobile}
                      onValueChange={(e) =>
                        handleChange({
                          target: { name: "mobile", value: e.value },
                        })
                      }
                    />
                    <label htmlFor="phoneNumber">
                      {t("send_message.mobile")}
                    </label>
                    <em className="input-icon"></em>
                    <span className="text-danger">{errors.mobile}</span>
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <div className="input-effect with-icon">
                    <textarea
                      className="form-control"
                      name="message"
                      placeholder="Write a message..."
                      id="message"
                      onChange={handleChange}
                    ></textarea>
                    <label>{t("send_message.message")}</label>
                  </div>
                  <span className="text-danger">{errors.message}</span>
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <button
                    type="button"
                    className="btn btn-gradient btn-lg"
                    onClick={onSubmit}
                  >
                    Send Message
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  consumer: state.consumer.consumerdetails,
  profilePic: state.underwriting.profile_pic,
});

export default connect(mapStateToProps)(SendMessage);
