import React, { useState } from "react";
import { Modal, Table } from "react-bootstrap";
import { isEmpty } from "lodash";
import DatePicker from "react-datepicker";
import {
  commonApiCall,
  getJwt,
  UNDERWRITING_DETAILS,
  setSbliProgressCount,
} from "../redux/actions";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  ApprovalStatus,
  setProcessStep,
  showErrorToast,
  showSuccessToast,
  UnderWritingRoutes,
} from "../utils";

let initForm = {
  firstName: "",
  lastName: "",
  dob: "",
  relationship: "",
  relationshipType: "",
  percentage: "",
  errors: {},
};
const SBLIBeneficiary = ({
  t,
  commonApiCall,
  underwriting,
  setSbliProgressCount,
  progressCount,
  ...props
}) => {
  const { applicationId, applicationType, goalType, policyType } = underwriting;
  const localBeneficiaryPerson = localStorage.getItem("persist::bene");
  const [showBenefiaryForm, setShowBeneficiaryForm] = useState(false);
  const [beneficiaryPerson, setBeneficiaryPerson] = useState(
    localBeneficiaryPerson ? JSON.parse(localBeneficiaryPerson) : []
  );
  const [beneficiaryItem, setBeneficiaryItem] = useState(initForm);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [agree, setAgree] = useState(false);
  const { firstName, lastName, dob, relationship, percentage, errors } =
    beneficiaryItem;

  const handleChange = (e) => {
    let tempBeneficiary = { ...beneficiaryItem };
    if (e.target.name === "relationship") {
      if (e.target.value === "Spouse" || e.target.value === "Child") {
        tempBeneficiary.relationshipType = e.target.value;
      } else {
        tempBeneficiary.relationshipType = "OtherNamedIndividual";
      }
      tempBeneficiary["relationship"] = e.target.value;
    } else {
      tempBeneficiary[e.target.name] = e.target.value;
    }
    setBeneficiaryItem(tempBeneficiary);
  };

  const validateForm = () => {
    const { firstName, lastName, dob, relationship, percentage } =
      beneficiaryItem;
    let errors = {};
    if (beneficiaryItem.hasOwnProperty("firstName")) {
      if (isEmpty(firstName)) errors.first_name = t("validation_message.fName");
      else delete errors.first_name;
    }
    if (beneficiaryItem.hasOwnProperty("lastName")) {
      if (isEmpty(lastName)) errors.last_name = t("validation_message.lName");
      else delete errors.last_name;
    }
    if (beneficiaryItem.hasOwnProperty("dob")) {
      if (isEmpty(dob)) errors.dob = t("validation_message.dob");
      else delete errors.dob;
    }
    if (beneficiaryItem.hasOwnProperty("relationship")) {
      if (isEmpty(relationship))
        errors.relationship = t("validation_message.relationship");
      else delete errors.relationship;
    }
    if (beneficiaryItem.hasOwnProperty("percentage")) {
      if (isEmpty(percentage))
        errors.percentage = t("validation_message.percentage");
      else delete errors.percentage;
    }
    const isValid = Object.keys(errors).length !== 0 ? false : true;
    setBeneficiaryItem({
      ...beneficiaryItem,
      errors: errors,
    });
    return isValid;
  };

  const onSave = (e) => {
    e.preventDefault();
    if (validateForm()) {
      if (selectedIndex !== null) {
        let data = beneficiaryPerson.map((item, index) => {
          if (index === selectedIndex) {
            return { ...beneficiaryItem };
          }
          return item;
        });
        setBeneficiaryPerson(data);
        setShowBeneficiaryForm(false);
      } else {
        setBeneficiaryPerson([...beneficiaryPerson, beneficiaryItem]);
        setShowBeneficiaryForm(false);
      }
      setBeneficiaryItem({ ...initForm });
      setSelectedIndex(null);
    }
  };

  const onEdit = (item, index) => {
    setShowBeneficiaryForm(true);
    setBeneficiaryItem(item);
    setSelectedIndex(index);
  };

  const onDelete = (index) => {
    setBeneficiaryPerson(beneficiaryPerson.filter((_, i) => i !== index));
  };

  const validateTable = () => {
    let value = beneficiaryPerson.reduce(
      (total, obj) => parseInt(obj.percentage) + parseInt(total),
      0
    );
    if (!beneficiaryPerson.length) {
      showErrorToast("Please add beneficiary data first!!");
      return false;
    } else if (value !== 100) {
      showErrorToast("Percentage division should be equivalent to 100");
    } else {
      return true;
    }
  };

  const onTableSubmit = async () => {
    if (validateTable()) {
      localStorage.setItem("persist::bene", JSON.stringify(beneficiaryPerson));
      setSbliProgressCount(progressCount + 1);
      props.history.push(UnderWritingRoutes.SBLI_THIRD_PARTY);
    }
  };

  return (
    <div className="sbli-beneficiary-container mt-4">
      <Modal show={showBenefiaryForm} centered>
        <Modal.Header className="p-2"></Modal.Header>
        <Modal.Body className="beneficiary-body more-info">
          <div className="form-wrapper">
            <h4 className="mb-3">Who should we pay if you pass away?</h4>
            <form className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <div className="input-effect">
                    <input
                      className="form-control"
                      placeholder={"First Name"}
                      name="firstName"
                      id="first_name"
                      type="text"
                      onChange={handleChange}
                      value={firstName}
                    />
                    <label htmlFor="city">{"First name"}</label>
                    <em className="input-icon"></em>
                    <span className="text-danger "></span>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <div className="input-effect">
                    <input
                      className="form-control"
                      placeholder={"Last Name"}
                      name="lastName"
                      id="last_name"
                      type="text"
                      onChange={handleChange}
                      value={lastName}
                    />
                    <label htmlFor="city">{"Last name"}</label>
                    <em className="input-icon"></em>
                    <span className="text-danger "></span>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <h4 className="mb-3">
                  What is the beneficiary's date of birth?
                </h4>
                <div className="form-group custom-datepicker">
                  <DatePicker
                    selected={dob ? new Date(dob) : ""}
                    maxDate={new Date()}
                    onChange={(date) =>
                      setBeneficiaryItem({
                        ...beneficiaryItem,
                        dob: `${
                          date.getMonth() + 1 < 10
                            ? `0${date.getMonth() + 1}`
                            : date.getMonth() + 1
                        }/${
                          date.getDate() < 10
                            ? `0${date.getDate()}`
                            : date.getDate()
                        }/${date.getFullYear()}`,
                      })
                    }
                    placeholderText={"MM/DD/YYYY"}
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    dateFormatCalendar="MMMM"
                  />
                  <span className="datepicker-label">
                    {t("beneficiaryPage.date_of_birth")}
                  </span>
                  <span className="text-danger ">{errors.dob}</span>
                </div>
              </div>
              <div className="col-md-6">
                <h4 className="mb-3">What is their relationship to you?</h4>
                <div className="label-checkbox">
                  <label
                    htmlFor="spouse"
                    className={relationship === "Spouse" ? "active" : ""}
                  >
                    <input
                      type={"radio"}
                      id="spouse"
                      name="relationship"
                      onChange={handleChange}
                      checked={relationship === "Spouse"}
                      value={"Spouse"}
                    />{" "}
                    Spouse
                  </label>
                  <label
                    htmlFor="Child"
                    className={relationship === "Child" ? "active" : ""}
                  >
                    <input
                      type={"radio"}
                      id="Child"
                      name="relationship"
                      onChange={handleChange}
                      checked={relationship === "Child"}
                      value={"Child"}
                    />{" "}
                    Child
                  </label>
                  <label
                    htmlFor="Parent"
                    className={relationship === "Parent" ? "active" : ""}
                  >
                    <input
                      type={"radio"}
                      id="Parent"
                      name="relationship"
                      onChange={handleChange}
                      checked={relationship === "Parent"}
                      value={"Parent"}
                    />{" "}
                    Parent <sup>*</sup>
                  </label>
                  <label
                    htmlFor="sibling"
                    className={relationship === "Sibling" ? "active" : ""}
                  >
                    <input
                      type={"radio"}
                      id="sibling"
                      name="relationship"
                      onChange={handleChange}
                      checked={relationship === "Sibling"}
                      value={"Sibling"}
                    />{" "}
                    Sibling<sup>*</sup>
                  </label>
                  <label
                    htmlFor="domestic"
                    className={
                      relationship === "Domestic Partner" ? "active" : ""
                    }
                  >
                    <input
                      type={"radio"}
                      id="domestic"
                      name="relationship"
                      onChange={handleChange}
                      checked={relationship === "Domestic Partner"}
                      value={"Domestic Partner"}
                    />{" "}
                    Domestic Partner
                  </label>
                </div>
              </div>
              <div className="col-md-6">
                <h4 className="mb-3">What % share should they receive?</h4>
                <div className="form-group">
                  <div className="input-effect">
                    <input
                      className="form-control"
                      placeholder={"% share"}
                      name="percentage"
                      id="share"
                      type="number"
                      onChange={handleChange}
                      value={percentage}
                    />
                    <label htmlFor="share">{"% share"}</label>
                    <em className="input-icon"></em>
                    <span className="text-danger "></span>
                  </div>
                </div>
              </div>
              {(relationship === "Parent" || relationship === "Sibling") && (
                <div className="col-md-6">
                  <div className="custom-checkbox mt-4">
                    <label
                      className="m-0"
                      style={{ fontSize: 16, color: "#000" }}
                      htmlFor={"confirm_checkbox"}
                    >
                      <input
                        type="checkbox"
                        id={"confirm_checkbox"}
                        checked={agree}
                        onChange={(e) => setAgree(e.target.checked)}
                      />
                      <span></span> * I confirm that this beneficiary would be
                      financially impacted by my death.
                    </label>
                  </div>
                </div>
              )}

              <div className="col-md-12 text-right">
                <button
                  className="btn btn-primary mr-3"
                  type="button"
                  onClick={() => setShowBeneficiaryForm(false)}
                >
                  Cancel
                </button>
                <button
                  className="btn btn-default"
                  type="button"
                  onClick={(e) => onSave(e)}
                >
                  {selectedIndex !== null ? "Update" : "Save"}
                </button>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
      <h3 className="text-center mb-5">Beneficiary Information</h3>
      <Table responsive striped>
        <thead>
          <tr>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Date of Birth</th>
            <th>Relationship</th>
            <th>Percentage</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {beneficiaryPerson.map((person, index) => {
            return (
              <tr key={index}>
                <td>{person.firstName}</td>
                <td>{person.lastName}</td>
                <td>{person.dob}</td>
                <td>{person.relationship}</td>
                <td>{person.percentage}</td>
                <td>
                  <button
                    className="btn btn-link mw-auto p-0"
                    onClick={() => onEdit(person, index)}
                  >
                    <img src="/assets/images/pencil.png" />
                  </button>
                  <button
                    className="btn btn-link mw-auto p-0"
                    onClick={() => onDelete(index)}
                  >
                    <img src="/assets/images/delete.png" />
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      <button
        className="btn btn-link text-primary"
        onClick={() => setShowBeneficiaryForm(true)}
      >
        Add Beneficiary
      </button>
      <div className="w-100 text-center mt-5">
        <button className="btn btn-default" onClick={onTableSubmit}>
          Next
        </button>
      </div>
      {/* <div className="w-100 text-center mt-5">
        <button
          className="btn btn-link"
          onClick={() => props.history.push("/sbli-terms")}
        >
          Back
        </button>
      </div> */}
    </div>
  );
};
const mapStateToProps = (state) => ({
  underwriting: state.underwriting.underwriting,
  progressCount: state.underwriting.progressCount,
});

export default connect(mapStateToProps, {
  commonApiCall,
  setSbliProgressCount,
})(withRouter(SBLIBeneficiary));
