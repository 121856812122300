import { combineReducers } from "redux";

import { common } from "./common";
import { consumer } from "./consumer";
import { underwriting } from "./underWriting";

// Combine all reducers into root reducer
export default combineReducers({
  common,
  consumer,
  underwriting
});
