import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { UnderWritingRoutes } from "../../../../utils";
import ApplicationStatus from "../../../approvalStatus/applicationStatus";

const AssurityPolicyDecline = ({ t, underwriting, ...props }) => {
  const underWritingStatus = new URLSearchParams(props.location.search).get(
    "underWritingStatus"
  );

  return (
    <div className="assurity w-100 mw-100">
      <div className="container mt-5">
        <h2 className="text-center">Under Review</h2>
        <div className="heading-block d-flex justify-content-center align-items-start">
          <p className="w-50 ms-auto">
            {underWritingStatus === "declined"
              ? "Assurity is unable to offer coverage at this time. We try to find a fit for each applicant’s needs, but regrettably that is not always possible. You will receive a letter in the mail explaining the reason coverage was not offered. Thank you for your application."
              : "Your submitted application is being referred to Assurity’s experienced and knowledgeable underwriting team for a prompt review. Communication of the final underwriting decision and/or any additional requirements will be made following this review. Thank you for your application."}
          </p>
          {underWritingStatus === "declined" && (
            <img
              style={{ width: 61, transform: "rotate(45deg)" }}
              src="/assets/images/umbrella-grey.png"
            />
          )}
        </div>

        <div className="white-box mb-5 w-50 mx-auto mt-5 py-0">
          <ApplicationStatus t={t} sbli={true} assurity={true} />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  underwriting: state.underwriting.underwriting,
});
export default connect(mapStateToProps, {})(withRouter(AssurityPolicyDecline));
