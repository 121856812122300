import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import {} from "react-bootstrap";
import { Animated } from "react-animated-css";
import { isEmpty } from "lodash";

import {
  commonApiCall,
  UNDERWRITING_DETAILS,
  getJwt,
} from "../../../redux/actions/index";
import {
  setProcessStep,
  getProcessStep,
  UnderWritingRoutes,
  showErrorToast,
  segmentTracking,
  getState,
} from "../../../utils/index";
import {
  API_URL_UNDERWRITING,
  CONSUMER_PORTAL_URL,
} from "../../../config/configs";
import { validationMessages } from "../../../redux/actions/validationMessages";

const Citizenship = ({ commonApiCall, underwriting, t, ...props }) => {
  const {
    applicationId,
    applicationType,
    goalType,
    policyType,
    insureBasedOn,
    citizenship,
  } = underwriting;
  const [formData, setFormData] = useState({
    applicationId: applicationId,
    applicationType: applicationType ? applicationType : "Web",
    citizenShip: citizenship ? citizenship : "",
    errors: {},
  });

  const { citizenShip, errors } = formData;

  useEffect(() => {
    if (!applicationId || !goalType) {
      window.location.replace(CONSUMER_PORTAL_URL + "404");
    }

    if (underwriting && !isEmpty(underwriting.carrierWiseLeads)) {
      const whereToRoute = getProcessStep(
        underwriting.resumeProcess,
        underwriting.goalType,
        underwriting.policyType,
        underwriting.productSelection
      );
      props.history.push(whereToRoute);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const validateCitizen = () => {
    let errors = {},
      isValid = true;
    if (citizenShip.trim() == "") {
      isValid = false;
      errors.citizenShip = t("single_page.errors.citizenship");
    } else delete errors.citizenShip;

    setFormData({ ...formData, errors: errors });
    return isValid;
  };

  async function doSubmit() {
    try {
      const { zipCode, applicationId, applicationType } = formData;
      if (validateCitizen()) {
        const splitpath = window.location.pathname.split("/");
        const request = {
          applicationId: applicationId,
          applicationType: applicationType,
          goalType: goalType,
          citizenship: citizenShip,
          resumeProcess: setProcessStep(splitpath[1], policyType, goalType),
        };

        const isAuthorized = getJwt() ? true : false;
        const apiEndPoint = API_URL_UNDERWRITING + "saveUnderWriting";
        const response = await commonApiCall(
          apiEndPoint,
          "post",
          request,
          UNDERWRITING_DETAILS,
          isAuthorized
        );
        if (response.status.code === 200) {
          const eventObject = {
            agent_id: response?.data?.agentDetail?.agentId,
            application_id: applicationId,
            param_name: "Citizenship",
            param_value: response.data.citizenship,
          };
          segmentTracking("application_set_param", eventObject);
          props.history.push(UnderWritingRoutes.ZIPCODE);
        } else {
          showErrorToast(response.status.message);
        }
      }
    } catch (err) {
      showErrorToast(t("validation_message.SOMETHING_WENT_WRONG"));
    }
  }

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      doSubmit();
    }
  };

  return (
    <React.Fragment>
      <div className="steps">
        <div className="row justify-content-center">
          <div className="col-sm-7 col-8 mt-5">
            <div className="title-header mb-0">
              <h2 className="page-title text-center">
                {t("single_page.title.citizen")}
              </h2>

              {/* <h4>Tell us about your gender</h4> */}
            </div>
          </div>
        </div>
        <Animated
          animationIn="fadeInRight"
          animationOut="zoomOut"
          animationInDuration={800}
          animationOutDuration={800}
          isVisible={true}
        >
          <div className="row justify-content-center">
            <div className="col-sm-7 col-8 mt-5">
              <div className="single-page-box citizen">
                <div className="">
                  <span className="custom-radio">
                    <label className="">
                      <input
                        type="radio"
                        name="citizenShip"
                        id="citizenship"
                        value="us_citizen_reside"
                        checked={citizenShip == "us_citizen_reside"}
                        onChange={(e) => handleChange(e)}
                        onClick={(e) => handleChange(e)}
                      />
                      <span></span>
                      <span className="emailcheck">
                        {t("single_page.title.citizen_key.key1")}
                      </span>
                    </label>
                    <label className="">
                      <input
                        type="radio"
                        name="citizenShip"
                        id="citizenship"
                        value="us_citizen_outside"
                        checked={citizenShip == "us_citizen_outside"}
                        onChange={(e) => handleChange(e)}
                        onClick={(e) => handleChange(e)}
                      />
                      <span></span>
                      <span className="emailcheck">
                        {" "}
                        {t("single_page.title.citizen_key.key2")}
                      </span>
                    </label>
                    <label className="">
                      <input
                        type="radio"
                        name="citizenShip"
                        id="citizenship"
                        value="non_us_citizen_reside"
                        checked={citizenShip == "non_us_citizen_reside"}
                        onChange={(e) => handleChange(e)}
                        onClick={(e) => handleChange(e)}
                      />
                      <span></span>
                      <span className="emailcheck">
                        {" "}
                        {t("single_page.title.citizen_key.key3")}
                      </span>
                    </label>
                    <label className="">
                      <input
                        type="radio"
                        name="citizenShip"
                        id="citizenship"
                        value="non_resident_alien"
                        checked={citizenShip == "non_resident_alien"}
                        onChange={(e) => handleChange(e)}
                        onClick={(e) => handleChange(e)}
                      />
                      <span></span>
                      <span className="emailcheck">
                        {" "}
                        {t("single_page.title.citizen_key.key4")}
                      </span>
                    </label>
                    <label className="">
                      <input
                        type="radio"
                        name="citizenShip"
                        id="citizenship"
                        value="other"
                        checked={citizenShip == "other"}
                        onChange={(e) => handleChange(e)}
                        onClick={(e) => handleChange(e)}
                      />
                      <span></span>
                      <span className="emailcheck">
                        {" "}
                        {t("single_page.title.citizen_key.key5")}
                      </span>
                    </label>
                  </span>
                </div>
                {errors.citizenShip && (
                  <small className="text-danger err-msg">
                    {" "}
                    {t("single_page.errors.citizenship")}
                  </small>
                )}
              </div>
            </div>
            <div className="col-12 text-center my-3">
              <button
                className="btn btn-primary"
                type="button"
                onClick={doSubmit}
              >
                {t("next")}
              </button>
            </div>
            <div className="col-12 text-center mt-3">
              <Link
                to={UnderWritingRoutes.SOMKING_HABIT}
                className="btn btn-link"
                title="Back"
              >
                {t("back")}
              </Link>
            </div>
          </div>
        </Animated>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  underwriting: state.underwriting.underwriting,
});

export default connect(mapStateToProps, { commonApiCall })(
  withRouter(Citizenship)
);
