import React, { useEffect, useRef, useState } from "react";
import Slider from "react-rangeslider";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { showErrorToast, UnderWritingRoutes } from "../../utils";
import {
  commonApiCall,
  getJwt,
  setSbliProgressCount,
} from "../../redux/actions/index";
import { API_URL_UNDERWRITING } from "../../config/configs";
import NumberFormat from "react-number-format";
import Skeleton from "react-loading-skeleton";
import SBLICoverageLoader from "./assurity/coverage-loader";

const SBLICoverage = ({
  commonApiCall,
  underwriting,
  setSbliProgressCount,
  progressCount,
  t,
  ...props
}) => {
  const { applicationId } = underwriting;
  const [formData, setFormData] = useState({
    depositAmount: "",
    yourTermLength: underwriting?.termLength || "",
    premiumAnnually: 0,
    premiumMonthly: 0,
    maxCoverage: 0,
  });
  const [tempState, setTempState] = useState({
    depositAmount: "",
    yourTermLength: underwriting?.termLength || "",
    premiumAnnually: 0,
    premiumMonthly: 0,
    maxCoverage: 0,
  });
  const [coverageLoading, setCoverageLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [waitingTime, setWaitingTime] = useState(null);
  const timeout = useRef();

  const {
    depositAmount,
    yourTermLength,
    premiumAnnually,
    premiumMonthly,
    maxCoverage,
  } = formData;

  useEffect(() => {
    getDecision(true);
  }, []);

  useEffect(() => {
    if (
      depositAmount &&
      depositAmount !== "" &&
      yourTermLength &&
      yourTermLength !== ""
    ) {
      getDirectPremium();
    }
  }, [formData.yourTermLength]);

  const getDirectPremium = async () => {
    setLoading(true);
    try {
      if (
        depositAmount &&
        depositAmount !== "" &&
        yourTermLength &&
        yourTermLength !== ""
      ) {
        let response = await commonApiCall(
          `${API_URL_UNDERWRITING}getDirectPremium?applicationId=${applicationId}&coverage=${depositAmount}&term=${yourTermLength}`,
          "put",
          {},
          ""
        );
        if (response.status.code === 200) {
          if (response.data.statusCode === "205") {
            showErrorToast(response.data.msgTxt);
            setFormData(tempState);
          } else {
            setTempState({
              ...tempState,
              premiumAnnually: response.data.premiumAnnually,
              premiumMonthly: response.data.premiumMonthly,
              depositAmount: response.data.offeredCover,
              maxCoverage: response.data?.maxAllowedCoverage,
            });
            setFormData({
              ...formData,
              premiumAnnually: response.data.premiumAnnually,
              premiumMonthly: response.data.premiumMonthly,
              depositAmount: response.data.offeredCover,
              maxCoverage: response.data?.maxAllowedCoverage,
            });
          }

          // showSuccessToast(response.data.msgText);
          // saveUnderwriting();
        } else {
          showErrorToast(response.status.message);
        }
      } else {
        showErrorToast(
          yourTermLength === ""
            ? "Please select years of coverage"
            : "Please select coverage amount"
        );
      }
    } catch (err) {
      showErrorToast(err);
    }
    setLoading(false);
  };

  // const offerAcceptance = async () => {
  //   try {
  //     let response = await commonApiCall(
  //       `${API_URL_UNDERWRITING}offerAcceptance`,"post"
  //     );
  //   } catch (err) {
  //     showErrorToast(err);
  //   }
  // };

  const getDecision = async () => {
    setCoverageLoading(true);
    try {
      let response = await commonApiCall(
        `${API_URL_UNDERWRITING}getDecison?applicationId=${applicationId}`, //underwriting?.applicationId
        "get",
        {},
        ""
      );

      if (response.status.code === 200) {
        if (
          response.data.hasOwnProperty("statusCode") &&
          response.data.statusCode.hasOwnProperty("waitSeconds") &&
          response.data.statusCode.appDecisonCode === 99
        ) {
          setWaitingTime(parseInt(response.data.statusCode.waitSeconds) + 3);
          return;
        }
        if (
          !response.data.hasOwnProperty("submitForm") ||
          response.data?.submitForm?.data?.statusCode !== "200"
        ) {
          if (response.data.statusCode.appDecisonCode !== 1) {
            props.history.push(
              UnderWritingRoutes.SBLI_DENIAL +
                `?decision=${response.data.statusCode.appDecisonCode}`
            );
          }
          // showErrorToast(
          //   response.data.hasOwnProperty("submitForm")
          //     ? response.data?.submitForm?.data?.msgTxt
          //     : response.data?.statusCode.msgTxt
          // );
        }

        if (response.data.hasOwnProperty("submitForm")) {
          setTempState({
            ...tempState,
            premiumAnnually: response.data?.submitForm?.data?.premiumAnnually,
            premiumMonthly: response.data?.submitForm?.data?.premiumMonthly,
            depositAmount: response.data?.submitForm?.data?.offeredCover,
            maxCoverage: response.data?.submitForm?.data?.maxAllowedCoverage,
          });
          setFormData({
            ...formData,
            premiumAnnually: response.data?.submitForm?.data?.premiumAnnually,
            premiumMonthly: response.data?.submitForm?.data?.premiumMonthly,
            depositAmount: response.data?.submitForm?.data?.offeredCover,
            maxCoverage: response.data?.submitForm?.data?.maxAllowedCoverage,
          });
        }

        // showSuccessToast(response.data.msgText);
        // saveUnderwriting();
      } else {
        showErrorToast(response.status.message);
      }
    } catch (err) {
      console.log(err);
      showErrorToast("something went wrong");
    }
    setCoverageLoading(false);
  };

  const getPremium = async () => {
    try {
      const response = await commonApiCall(
        `${API_URL_UNDERWRITING}getPremium?applicationId=${applicationId}`, //underwriting?.applicationId
        "get",
        {},
        ""
      );
      if (response.status.code === 200) {
        if (response.data.statusCode === "205") {
          showErrorToast(response.data.msgTxt);
        }
        setFormData({
          ...formData,
          premiumAnnually: response.data.premiumAnnually,
          premiumMonthly: response.data.premiumMonthly,
          depositAmount: response.data.offeredCover,
        });
        // showSuccessToast(response.data.msgText);
        // saveUnderwriting();
      } else {
        showErrorToast(response.status.message);
      }
    } catch (err) {
      showErrorToast("something went wrong");
    }
  };

  const dollar = (value) => "$" + Number(value).toLocaleString();

  const handleFormData = (depositAmount, valueType) => {
    setFormData({
      ...formData,
      value_type: valueType,
      depositAmount: depositAmount,
    });
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const acceptOffer = async () => {
    setLoading(true);
    try {
      if (
        depositAmount &&
        depositAmount !== "" &&
        yourTermLength &&
        yourTermLength !== ""
      ) {
        let body = {
          applicationId: applicationId,
          coverage: depositAmount,
          term: yourTermLength,
          premium: premiumMonthly,
        };
        const isAuthorized = getJwt() ? true : false;
        let response = await commonApiCall(
          `${API_URL_UNDERWRITING}offerACCEPTANCE`,
          "post",
          body,
          "",
          isAuthorized
        );
        if (response.status.code === 200) {
          if (
            !response.data.hasOwnProperty("offerACCEPTANCE") &&
            response.data.hasOwnProperty("statusCode")
          ) {
            setLoading(false);
            if (
              response.data.statusCode.appDecisonCode === 3 ||
              response.data.statusCode.appDecisonCode === 4 ||
              response.data.statusCode.appDecisonCode === 2
            ) {
              props.history.push(
                UnderWritingRoutes.SBLI_DENIAL + "?decision=3"
              );
            } else {
              showErrorToast(
                response.data.hasOwnProperty("offerACCEPTANCE")
                  ? response.data?.submitForm?.data?.msgText
                  : response.data?.statusCode.msgText
              );
            }
          }

          if (response.data.hasOwnProperty("offerACCEPTANCE")) {
            setSbliProgressCount(progressCount + 1);
            props.history.push("/sbli-payment");
          }
        } else {
          setLoading(false);
          showErrorToast(response.status.message);
        }
      } else {
        setLoading(false);
        showErrorToast(
          yourTermLength === ""
            ? "Please select years of coverage"
            : "Please select coverage amount"
        );
      }
    } catch (err) {
      showErrorToast(err);
    }
    setLoading(false);
  };

  return (
    <div className="sbli-coverage mt-5">
      {/* <SBLICoverageLoader flag={depositAmount} /> */}
      {coverageLoading && (
        <SBLICoverageLoader
          flag={depositAmount}
          time={waitingTime}
          callback={() => {
            if (waitingTime !== 0) {
              getDecision();
            }
            setWaitingTime(0);
          }}
        />
      )}

      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-3">
            <div className="single-page-box single-page-custom-slider">
              <h4 className="text-dark text-center">{"Coverage Amount"}</h4>

              <div className="range-block">
                <div className="num_value w-100">
                  <NumberFormat
                    // autoFocus
                    prefix={"$"}
                    thousandSeparator={true}
                    name="number"
                    id="depositAmount"
                    className="form-control number-format-amount px-0"
                    inputMode="numeric"
                    allowNegative={false}
                    style={{ textAlign: "center" }}
                    value={depositAmount}
                    disabled
                    //   isAllowed={(values) => {
                    //     const { formattedValue, floatValue } = values;
                    //     return (
                    //       formattedValue === "" || floatValue <= slideLabels.max
                    //     );
                    //   }}

                    onValueChange={(e) => {
                      handleChange({
                        target: {
                          name: "depositAmount",
                          value: e.value,
                        },
                      });
                    }}
                  />

                  {/* <p className="pb-0">{dollar(depositAmount)}</p> */}
                </div>
                <Slider
                  min={25000}
                  max={maxCoverage}
                  step={50000}
                  disabled={loading}
                  format={dollar}
                  value={Number(depositAmount)}
                  handleLabel={String(depositAmount)}
                  onChange={(e) =>
                    handleChange({
                      target: {
                        name: "depositAmount",
                        value: e,
                      },
                    })
                  }
                  labels={{ 25000: "$25000", [maxCoverage]: "$" + maxCoverage }}
                  onBlur={() => () => handleFormData(depositAmount, true)}
                  className="deposit-range-slider"
                  tooltip={false}
                  onChangeComplete={getDirectPremium}
                  // onChangeStart={this.handleChangeStart}
                  // onChange={this.handleChange}
                  // onChangeComplete={this.handleChangeComplete}
                />
                {/* <div className="text-center w-100 mt-5">
                  <button className="btn btn-green" onClick={getDirectPremium}>
                    Update Coverage
                  </button>
                </div> */}
              </div>
              {/* {errors.depositAmount && (
              <small className="text-danger err-msg">
                {t("single_page.errors.coverage")}
              </small>
            )} */}
            </div>
          </div>
          <div className="col-md-6 ">
            <h3 className="font-weight-bold text-dark text-center">
              Confirm Your Coverage
            </h3>
            <div className="sbli-premium m-5 ">
              {/* <h4 className="text-dark">Your final price</h4> */}
              <div className="premium-box w-75 mx-auto">
                <h3 className="text-dark font-weight-bold mb-5">
                  Your final price
                </h3>
                <div>
                  <h1 className="mt-3">
                    {coverageLoading ? (
                      <Skeleton count={1} width={200} height={36} />
                    ) : (
                      <>
                        {" "}
                        $
                        {premiumMonthly > 0
                          ? String(premiumMonthly).split(".")[0]
                          : 0}
                        <sup>
                          .
                          {premiumMonthly > 0
                            ? String(premiumMonthly).split(".")[1]
                            : 0}
                        </sup>
                      </>
                    )}
                  </h1>
                  <p className="mb-3">per month</p>
                  {coverageLoading ? (
                    <Skeleton count={1} width={100} height={36} />
                  ) : (
                    <h4>
                      ${premiumAnnually}
                      <span>/year</span>
                    </h4>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="sbli-terms">
              <h4 className="text-center text-dark mb-3">
                {"Years of Coverage"}
              </h4>
              <div className="d-flex flex-wrap">
                <label
                  className={`boxmodal-radio ${
                    yourTermLength == 10 ? "active" : ""
                  }`}
                >
                  <input
                    type="radio"
                    name="yourTermLength"
                    id="yourTermLength"
                    value={10}
                    checked={yourTermLength == 10 ? true : false}
                    onClick={(e) => handleChange(e)}
                    onChange={(e) => handleChange(e)}
                  />
                  <div
                    className={`smoke-box ${
                      yourTermLength == 10 ? "active" : ""
                    }`}
                  >
                    <span>
                      <span>10</span> Year Term
                    </span>
                  </div>
                </label>

                <label
                  className={`boxmodal-radio ${
                    yourTermLength == 15 ? "active" : ""
                  }`}
                >
                  <input
                    type="radio"
                    name="yourTermLength"
                    id="yourTermLength"
                    value={15}
                    checked={yourTermLength == 10 ? true : false}
                    onClick={(e) => handleChange(e)}
                    onChange={(e) => handleChange(e)}
                  />
                  <div
                    className={`smoke-box ${
                      yourTermLength == 15 ? "active" : ""
                    }`}
                  >
                    <span>
                      <span>15</span> Year Term
                    </span>
                  </div>
                </label>

                <label
                  className={`boxmodal-radio ${
                    yourTermLength == 20 ? "active" : ""
                  }`}
                >
                  <input
                    type="radio"
                    name="yourTermLength"
                    id="yourTermLength"
                    value={20}
                    checked={yourTermLength == 20 ? true : false}
                    onClick={(e) => handleChange(e)}
                    onChange={(e) => handleChange(e)}
                  />
                  <div className={`smoke-box `}>
                    <span>
                      <span>20</span> Year Term
                    </span>
                  </div>
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className="highlighted-header w-75 mx-auto">
          <p style={{ fontSize: 14 }}>
            Your coverage may have changed from your original quote based on
            your application details. We strive to give you the best price for
            the best coverage based on your unique needs.
          </p>
        </div>
        <div className="w-100 mb-4 mt-4 text-center">
          <button className="btn btn-default px-5 py-3" onClick={acceptOffer}>
            {loading ? "Processing..." : "Next"}
          </button>
        </div>
        {/* <div className="w-100 text-center">
          <button
            className="btn btn-link"
            onClick={() => props.history.push("/sbli-third-party")}
          >
            Back
          </button>
        </div> */}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  underwriting: state.underwriting.underwriting,
  progressCount: state.underwriting.progressCount,
});

export default connect(mapStateToProps, {
  commonApiCall,
  setSbliProgressCount,
})(withRouter(SBLICoverage));
