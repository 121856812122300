import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import * as _ from "lodash";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Text,
} from "recharts";
import { NumberFormatter } from "../../utils/index";

export const DividendGraphModel = ({
  isDividendSelected,
  dividendCarrierId,
  dividendCarrier,
  cashDividendModal,
  comparisonWithOptions,
  dividends,
  t,
  handleModel,
}) => {
  const [dividendGraph, setDividendGraph] = useState({
    dividend: [],
    isDivChecked: isDividendSelected,
    comparedDataForDiv: comparisonWithOptions,
  });
  const graphValueFormatter = (item) => NumberFormatter(item, "", "%");
  const setDividendGraphData = (
    id,
    name,
    comparePlanId = "",
    isDividendChecked = ""
  ) => {
    const { comparedDataForDiv } = dividendGraph;
    let tempComparedDataForDividend = comparedDataForDiv;
    if (comparePlanId) {
      let isCompareDataSet = _.find(tempComparedDataForDividend, {
        id: parseInt(comparePlanId),
      });
      if (isCompareDataSet) {
        tempComparedDataForDividend = _.filter(
          tempComparedDataForDividend,
          function (o) {
            return o.id !== isCompareDataSet.id;
          }
        );
      } else {
        let findCompareWithData = _.find(comparisonWithOptions, {
          id: parseInt(comparePlanId),
        });
        comparedDataForDiv.push(findCompareWithData);
      }
    }

    let findDividend =
      isDividendChecked === "1" ? _.find(dividends, ["id", id]) : [];

    let sortedComparedData = tempComparedDataForDividend.sort(function (a, b) {
      return b.chartComparisonData.length - a.chartComparisonData.length;
    });

    let carDivData = [];
    if (_.isEmpty(sortedComparedData) && _.isEmpty(findDividend)) {
      carDivData = [];
    } else if (
      _.isEmpty(sortedComparedData) ||
      (!_.isEmpty(findDividend) &&
        findDividend.dividendData.length >
          sortedComparedData[0].chartComparisonData.length)
    ) {
      findDividend &&
        findDividend.dividendData.map((divData, key) => {
          let tempDictionary = {};
          sortedComparedData.map((compData) => {
            let compareOptionTitle = compData.title;
            let isAccountCompareData = _.find(compData.chartComparisonData, [
              "year",
              divData.year,
            ]);

            let currentYield = isAccountCompareData
              ? isAccountCompareData.currentYield
              : null;
            tempDictionary[compareOptionTitle] = currentYield;
            return true;
          });
          tempDictionary.Year = divData.year;

          tempDictionary[dividendCarrier] = divData.value;
          carDivData.push(tempDictionary);
          return true;
        });
    } else {
      sortedComparedData[0].chartComparisonData.map((mainData) => {
        let tempDictionary = {};
        sortedComparedData.map((compData) => {
          let compareOptionTitle = compData.title;
          let isDivCompareData = _.find(findDividend.dividendData, [
            "year",
            mainData.year,
          ]);

          let isCompareData = _.find(compData.chartComparisonData, [
            "year",
            mainData.year,
          ]);
          let currentYield = isDivCompareData ? isDivCompareData.value : null;
          tempDictionary[dividendCarrier] = currentYield;
          tempDictionary.Year = mainData.year;
          tempDictionary[compareOptionTitle] =
            isCompareData && isCompareData.currentYield
              ? isCompareData.currentYield
              : null;
          return true;
        });

        carDivData.push(tempDictionary);
        return true;
      });
    }
    setDividendGraph({
      ...dividendGraph,
      dividend: carDivData,
      isDivChecked: isDividendChecked,
      comparedDataForDiv: tempComparedDataForDividend,
    });
  };
  useEffect(() => {
    setDividendGraphData(
      dividendCarrierId,
      dividendCarrier,
      "",
      isDividendSelected
    );
  }, []);

  const CustomizedLabel = ({ labelName, ...rest }) => {
    return (
      <Text {...rest} textAnchor="start">
        {labelName}
      </Text>
    );
  };
  const { dividend, isDivChecked, comparedDataForDiv } = dividendGraph;
  let sortedComparisonWithOptions = _.orderBy(
    comparisonWithOptions,
    ["id"],
    ["asc"]
  );
  return (
    <Modal
      className="plan-modal analytics-modal"
      show={cashDividendModal}
      onHide={() => handleModel("cashDividendModal", false)}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {dividendCarrierId === 3 || dividendCarrierId === 4
            ? t("plan_listing.hypothetical_crediting_rate")
            : t("plan_listing.cash_value_dividend")}{" "}
          - {dividendCarrier}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <React.Fragment>
          <div className="text-center mb-4">
            <h3 className="mb-4">
              {dividendCarrierId === 3 || dividendCarrierId === 4
                ? t(
                    "plan_listing.hypothetical_crediting_rate_vs_snp_500_return"
                  )
                : t(
                    "plan_listing.cash_value_dividend_vs_investment_alternatives"
                  )}
            </h3>
            <span className="custom-checkbox checkbox-green">
              <label>
                <input
                  type="checkbox"
                  name="dividentOptions"
                  id="dividentOptions"
                  value={isDivChecked === "1" ? "0" : "1"}
                  checked={isDivChecked === "1" ? true : false}
                  onChange={(e) =>
                    setDividendGraphData(
                      dividendCarrierId,
                      dividendCarrier,
                      "",
                      e.target.value
                    )
                  }
                />
                <span></span>
                {dividendCarrier}
              </label>
            </span>
            {(dividendCarrierId === 3 || dividendCarrierId === 4) &&
            sortedComparisonWithOptions
              ? sortedComparisonWithOptions.map((comparisonOpt, key) => {
                  let isChecked = _.find(comparedDataForDiv, {
                    id: comparisonOpt.id,
                  })
                    ? true
                    : false;
                  return comparisonOpt.isSp500 === true ? (
                    <span
                      className={
                        comparisonOpt.id === 1
                          ? "custom-checkbox checkbox-red"
                          : comparisonOpt.id === 3
                          ? "custom-checkbox checkbox-black"
                          : "custom-checkbox checkbox-blue"
                      }
                      key={key}
                    >
                      <label>
                        <input
                          type="checkbox"
                          name="dividentOptions"
                          id="dividentOptions"
                          value={comparisonOpt.id}
                          checked={isChecked}
                          onChange={(e) =>
                            setDividendGraphData(
                              dividendCarrierId,
                              dividendCarrier,
                              e.target.value,
                              isDivChecked
                            )
                          }
                        />
                        <span></span>
                        {comparisonOpt.title}
                      </label>
                    </span>
                  ) : null;
                })
              : sortedComparisonWithOptions &&
                sortedComparisonWithOptions.map((comparisonOpt, key) => {
                  let isChecked = _.find(comparedDataForDiv, {
                    id: comparisonOpt.id,
                  })
                    ? true
                    : false;
                  return comparisonOpt.isSp500 === false ? (
                    <span
                      className={
                        comparisonOpt.id === 1
                          ? "custom-checkbox checkbox-red"
                          : comparisonOpt.id === 3
                          ? "custom-checkbox checkbox-black"
                          : "custom-checkbox checkbox-blue"
                      }
                      key={key}
                    >
                      <label>
                        <input
                          type="checkbox"
                          name="dividentOptions"
                          id="dividentOptions"
                          value={comparisonOpt.id}
                          checked={isChecked}
                          onChange={(e) =>
                            setDividendGraphData(
                              dividendCarrierId,
                              dividendCarrier,
                              e.target.value,
                              isDivChecked
                            )
                          }
                        />
                        <span></span>
                        {comparisonOpt.title}
                      </label>
                    </span>
                  ) : null;
                })}
          </div>
          {!_.isEmpty(dividend) ? (
            <div className="chart-outer">
              <LineChart width={922} height={300} data={dividend}>
                <Legend />
                <XAxis
                  dataKey="Year"
                  label={
                    <CustomizedLabel
                      labelName={"Year"}
                      x={450}
                      y={285}
                      dx={0}
                      dy={0}
                      width={180}
                      transform=""
                    />
                  }
                />
                <YAxis
                  label={
                    <CustomizedLabel
                      labelName={"Rate of Return"}
                      x={0}
                      y={0}
                      dx={-180}
                      dy={30}
                      width={180}
                      transform={"rotate(-90)"}
                    />
                  }
                />
                <CartesianGrid linearray="3 3" />
                <Tooltip formatter={graphValueFormatter} />

                <Line
                  type="monotone"
                  dataKey={dividendCarrier}
                  stroke="#2CA851"
                />

                {dividendCarrierId === 3 || dividendCarrierId === 4 ? (
                  <Line type="monotone" dataKey="S&P 500" stroke="#0000ff" />
                ) : (
                  <Line
                    type="monotone"
                    dataKey="Savings Account"
                    stroke="#E73D6C"
                  />
                )}
                {dividendCarrierId !== 3 && dividendCarrierId !== 4 && (
                  <Line
                    type="monotone"
                    dataKey="10-Year Treasury Bond"
                    stroke="#091e1e"
                  />
                )}
                {dividendCarrierId !== 3 && dividendCarrierId !== 4 && (
                  <Line type="monotone" dataKey="5-Year CD" stroke="#0000ff" />
                )}
              </LineChart>
            </div>
          ) : (
            <div className="no-chart">
              {t("plan_listing.no_chart_data_found")}
            </div>
          )}
        </React.Fragment>
      </Modal.Body>
    </Modal>
  );
};
