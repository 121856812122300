import React, { Component } from "react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./store";
import AppRouting from "./AppRouting";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "animate.css/animate.css";
import "react-toastify/dist/ReactToastify.css";
import "react-rangeslider/lib/index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-datepicker/dist/react-datepicker.css";
import "react-perfect-scrollbar/dist/css/styles.css";
import "./scss/styles.scss";
import "react-image-crop/dist/ReactCrop.css";
import { segmentTrackingMethod, socialMediaEmbeddedTag } from "./utils";

require("dotenv").config();
class App extends Component {
  componentDidMount() {
    segmentTrackingMethod(
      process.env.REACT_APP_SEGMENT_API_KEY,
      process.env.REACT_APP_SUREVESTED_SEGMENT_API_KEY
    );
    if (process.env.REACT_APP_NODE_ENV === "production") {
      socialMediaEmbeddedTag(process.env.REACT_APP_FACEBOOK_DOMAIN_SUREVESTED);
    }
  }

  render() {
    return (
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <div className="App">
            <AppRouting />
          </div>
        </PersistGate>
      </Provider>
    );
  }
}

export default App;
