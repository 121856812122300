import React, { useState } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { Popover, OverlayTrigger } from "react-bootstrap";
import {
  NumberFormatter,
  getProcessStep,
  DashboardRoute,
  showErrorToast,
  getSBLIProcessStep,
  getAssurityProcessStep,
  getAssurityProgressFromNextStep,
} from "../../utils/index";
import {
  commonApiCall,
  getJwt,
  UNDERWRITING_DETAILS,
  COMPANY_INFORMATION,
  clearCompanyInformation,
  consoleLog,
  setAssurityProgressCount,
} from "../../redux/actions/index";
import { UnderWritingRoutes } from "../../utils/constant";
import { API_URL_UNDERWRITING, API_URL_CLIENT } from "../../config/configs";
import PrivateChat from "../privateChat/index";

const UnderProcessListing = ({
  processList,
  commonApiCall,
  from,
  clearCompanyInformation,
  t,
  setAssurityProgressCount,
  assuProgressCount,
  ...props
}) => {
  const [formData, setFormData] = useState({
    policyDetails: {},
    application_Id: "",
    chatModel: false,
    isPolicySigned: false,
  });

  const resumeApplication = async (applicationId) => {
    const request = {
      applicationId: applicationId,
    };
    const apiEndPoint = API_URL_UNDERWRITING + "getUnderWriting";
    const isAuthorized = getJwt() ? true : false;
    const response = await commonApiCall(
      apiEndPoint,
      "post",
      request,
      UNDERWRITING_DETAILS,
      isAuthorized
    );
    if (response.status.code === 200) {
      if (response.data.referralCode) {
        const request = {};
        const apiEndPoint =
          API_URL_CLIENT +
          "getCompanyInfo?referralCode=" +
          response.data.referralCode;
        const isAuthorized = false;
        await commonApiCall(
          apiEndPoint,
          "get",
          request,
          COMPANY_INFORMATION,
          isAuthorized
        );

        if (
          response.data.agentDetail.prospectJourney === "single_page" &&
          response.data.carrierWiseLeads == null &&
          response.data.resumeProcess !== "25"
        ) {
          props.history.push(UnderWritingRoutes.SINGLE_PAGE);
        } else {
          if (
            response.data.carrierWiseLeads &&
            (response.data.carrierWiseLeads[0].carrierName === "SBLI" ||
              response.data.carrierWiseLeads[0].carrierName === "Centrian")
          ) {
            props.history.push(getSBLIProcessStep(response.data.nextStep));
          } else if (
            response.data.carrierWiseLeads &&
            response.data.carrierWiseLeads[0].carrierName === "Assurity"
          ) {
            const keyType = response.data.nextStep
              ? response.data.nextStep.split(",")
              : null;

            const nextWeekDate = new Date(
              (response.data.updatedDate
                ? response.data.updatedDate
                : response.data.createdDate) + 12096e5
            ).getTime();

            if (nextWeekDate < new Date().getTime() || keyType === null) {
              window.location.href = UnderWritingRoutes.PRE_QUESTIONS;
            }

            if (keyType && keyType[0] !== "24") {
              const whereToRoute = getProcessStep(
                response.data.resumeProcess,
                response.data.goalType,
                response.data.policyType,
                response.data.productSelection
              );
            } else if (
              keyType &&
              (keyType[1] === "ASSURITY_DOCUMENTS" ||
                keyType[1] === "ASSURITY_PREVIEW")
            ) {
              setAssurityProgressCount(
                getAssurityProgressFromNextStep(response.data.nextStep)
              );
              window.location.href = getAssurityProcessStep(
                response.data.nextStep
              );
            } else {
              setAssurityProgressCount(
                getAssurityProgressFromNextStep(response.data.nextStep)
              );
              props.history.push(
                getAssurityProcessStep(response.data.nextStep)
              );
            }
          } else {
            const whereToRoute = getProcessStep(
              response.data.resumeProcess,
              response.data.goalType,
              response.data.policyType,
              response.data.productSelection
            );
            props.history.push(whereToRoute);
          }
        }
      } else {
        await clearCompanyInformation();
        if (
          response.data.carrierWiseLeads[0].carrierName === "SBLI" ||
          response.data.carrierWiseLeads[0].carrierName === "Centrian"
        ) {
          props.history.push(getSBLIProcessStep(response.data.nextStep));
        }
        if (response.data.carrierWiseLeads[0].carrierName === "Assurity") {
          const keyType = response.data.nextStep.split(",");
          if (keyType[0] !== "24") {
            const whereToRoute = getProcessStep(
              response.data.resumeProcess,
              response.data.goalType,
              response.data.policyType,
              response.data.productSelection
            );
          } else {
            setAssurityProgressCount(
              getAssurityProgressFromNextStep(response.data.nextStep)
            );
            props.history.push(getAssurityProcessStep(response.data.nextStep));
          }
        } else {
          const whereToRoute = getProcessStep(
            response.data.resumeProcess,
            response.data.goalType,
            response.data.policyType,
            response.data.productSelection
          );
          props.history.push(whereToRoute);
        }
      }
    } else {
      showErrorToast(response.status.message);
    }
  };

  const getPolicyDetails = async (id, applicationId, isPolicySigned) => {
    try {
      let response = await commonApiCall(
        API_URL_UNDERWRITING +
          "client/policy/info?anonymousApplicationCarrierId=" +
          id,
        "get",
        "",
        "",
        true
      );
      if (response) {
        setFormData({
          ...formData,
          policyDetails: response.data,
          application_Id: applicationId,
          chatModel: true,
          isPolicySigned: isPolicySigned,
        });
      }
    } catch (err) {
      consoleLog(err);
    }
  };

  const handleModel = (name, isHide) => {
    setFormData({ ...formData, [name]: isHide });
  };

  const returnPopOver = (id, content) => {
    return (
      <Popover id={id}>
        <Popover.Content className="popover-content">{content}</Popover.Content>
      </Popover>
    );
  };
  const { chatModel, isPolicySigned } = formData;
  return processList && processList.length > 0 ? (
    <div className="under-process">
      {processList.map((each, key) => {
        let policyDetailsLength = each.policyDetails.length,
          isPolicySigned = false;
        if (policyDetailsLength > 0) {
          if (policyDetailsLength > 1) {
            if (
              each.policyDetails[0].isSigned &&
              each.policyDetails[1].isSigned
            ) {
              isPolicySigned = true;
            }
          } else {
            if (each.policyDetails[0].isSigned) {
              isPolicySigned = true;
            }
          }
        }
        return (
          <div
            className={
              each && each.policyDetails && each.policyDetails.length > 1
                ? "plan-outer app-center"
                : each && each.policyDetails && each.policyDetails.length > 0
                ? "plan-outer"
                : "plan-outer plan-outer2"
            }
            key={key}
          >
            {each.policyDetails.length === 0 ? (
              <React.Fragment>
                <div className="policy-info">
                  <div className="application-id">{each.applicationId} </div>
                </div>
                <div className="policy-right">
                  <div className="plan-icons-outer"></div>
                  <div className="policy-right-btn">
                    <button
                      className="btn btn-primary-outline"
                      type="button"
                      onClick={() => resumeApplication(each.applicationId)}
                    >
                      {t("dashboard.underprocess_list.resume")}
                    </button>
                  </div>
                </div>
              </React.Fragment>
            ) : (
              <React.Fragment>
                {each &&
                  each.policyDetails &&
                  each.policyDetails.map((policy, index) => {
                    return (
                      <React.Fragment key={index}>
                        <div
                          className={
                            index === 0
                              ? "policy-info"
                              : "policy-info same-policy"
                          }
                        >
                          <div className="application-id">
                            {" "}
                            {index === 0 ? each.applicationId : ""}{" "}
                          </div>
                          <div className="policy-details">
                            <div className="policy-name">
                              <div className="overviewbox">
                                <OverlayTrigger
                                  trigger={["hover", "focus"]}
                                  placement="auto"
                                  overlay={returnPopOver(
                                    "popover-basic",
                                    policy.carriername
                                  )}
                                >
                                  <img
                                    src={policy.carrierLogo}
                                    alt="Carrier Logo"
                                    title={policy.carrier_name}
                                    width="22"
                                    height="22"
                                    style={{ marginRight: "5px" }}
                                  />
                                </OverlayTrigger>
                              </div>
                              <h3>
                                {policy.productName
                                  ? policy.productName.replace("15", policy.pay)
                                  : "N/A"}
                              </h3>
                              <div className="chips-outer">
                                <span className="chips-label">
                                  {policy.frequency}
                                </span>
                                <span className="chips-label">
                                  {NumberFormatter(policy.premium, "$")}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className={
                            index === 0
                              ? "policy-right"
                              : "policy-right same-policy"
                          }
                        >
                          <div className="plan-icons-outer">
                            {policy.anonymousApplicationCarrierId &&
                              policy.anonymousApplicationCarrierId !== null && (
                                <Link
                                  className="btn btn-outline-with-icon"
                                  to={{
                                    pathname:
                                      DashboardRoute.BASICDETAILSDASHBOARD,
                                    policyId:
                                      policy.anonymousApplicationCarrierId,
                                    redirectPathBack: from,
                                  }}
                                >
                                  <span className="material-icons">
                                    remove_red_eye
                                  </span>
                                  {t("dashboard.underprocess_list.view")}
                                </Link>
                              )}
                            {/* {policy && policy.agentUserId ? (
                              <Link
                                to="#"
                                className="btn btn-outline-with-icon"
                                onClick={() =>
                                  getPolicyDetails(
                                    policy.anonymousApplicationCarrierId,
                                    each.applicationId,
                                    isPolicySigned
                                  )
                                }
                              >
                                <span className="material-icons">chat</span>
                                {t("dashboard.underprocess_list.chat")}
                              </Link>
                            ) : null} */}
                          </div>

                          <div className="policy-right-btn">
                            {index === 0 && !isPolicySigned ? (
                              <button
                                className="btn btn-primary-outline"
                                type="button"
                                onClick={() =>
                                  resumeApplication(each.applicationId)
                                }
                              >
                                {t("dashboard.underprocess_list.resume")}
                              </button>
                            ) : null}
                          </div>
                        </div>
                      </React.Fragment>
                    );
                  })}
              </React.Fragment>
            )}
          </div>
        );
      })}

      <div
        className={
          chatModel === false
            ? "conversation-popup"
            : "conversation-popup active"
        }
      >
        {chatModel && (
          <PrivateChat
            conversationDetails={formData.policyDetails}
            applicationId={formData.application_Id}
            isPolicySigned={isPolicySigned}
            handleModel={handleModel}
            t={t}
            chatModel={chatModel}
          />
        )}
      </div>
      <div
        className={chatModel === false ? "conv-overlay" : "conv-overlay active"}
      ></div>
    </div>
  ) : (
    <div className="text-center pt-3">
      {" "}
      {t("dashboard.underprocess_list.There_are_no_records_to_display")}{" "}
    </div>
  );
};

export default connect(null, {
  commonApiCall,
  clearCompanyInformation,
  setAssurityProgressCount,
})(withRouter(UnderProcessListing));
