import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import Select from "react-select";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  commonApiCall,
  getJwt,
  UNDERWRITING_DETAILS,
} from "../../../redux/actions";
import NumberFormat from "react-number-format";
import { API_URL_CLIENT, API_URL_UNDERWRITING } from "../../../config/configs";
import {
  getState,
  showErrorToast,
  customFilter,
  decryptme,
  UnderWritingRoutes,
  validateEmail,
} from "../../../utils";
import moment from "moment";
import security from "../../../assets/images/lock.png";
import {
  assurtiyCountryList,
  getAssurityCountry,
  purposeList,
  tobaccoTypeList,
} from "./userdetail-list";
import InputMask from "react-input-mask";
import NumberRangeInput from "../../../components/InputFormFields/NumberRangeInput";

const secondaryFormState = {
  address: "",
  lastName: "",
  firstName: "",
  city: "",
  country: "",
  stateAbbreviation: "",
  zipCode: "",
};

const states1998 = [
  "AL",
  "AK",
  "AZ",
  "AR",
  "CO",
  "CT",
  "HI",
  "IA",
  "KY",
  "LA",
  "ME",
  "MD",
  "MS",
  "MO",
  "MT",
  "NE",
  "NH",
  "NJ",
  "NM",
  "NC",
  "OH",
  "OR",
  "RI",
  "SC",
  "SD",
  "TX",
  "UT",
  "VT",
  "VA",
  "WV",
  "WI",
];
const AssurityUserDetail = ({
  commonApiCall,
  underwriting,
  consumer,
  onAnswered,
  t,
  ...props
}) => {
  const [countryDropDown, setCountryDropDown] = useState([]);
  const [stateLoading, setStateLoading] = useState(false);
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    dob: "",
    birthCountry: "",
    birthState: "",
    ssn: "",
    drivingLicenseNumber: "",
    drivingLicenseState: "",
    address: "",
    city: "",
    state: "",
    zipCode: "",
    country: "",
    errors: {},
    haveLicense: "",
    otherCoveragesInForce: "",
    thisInsuranceWillReplaceModifyOrBorrowAgainstExistingCoverages: "",
    existingCoverages: [{ companyName: "", typeOfInsurance: "", amount: "" }],
    useExistingFundToPayNewPolicies: "",
    terminatingExistingCoverages: "",
    emailAddress: "",
    currentWorkingHrs: "",
    employerName: "",
    employerCity: "",
    employerCountry: "",
    employerAddress: "",
    employerState: "",
    employerZipcode: "",
    selfEmployed: "",
    netMonthlyIncome: "",
    grossMonthlyIncome: "",
    occupationYear: "",
    occupationMonth: "",
    isUsCitizen: "",
    hasGreenCard: "",
    howLongYears: "",
    howLongMonth: "",
    greenCardNumber: "",
    purposeOfInsurance: "",
    purposeOfInsuranceOthers: "",
    intentToSellInsurance: "",
    agreementToAssignOrSellPolicy: "",
    hadLifeExpectancyOrHealthExams: "",
  });

  const [addSecondaryAddress, setAddSecondaryAddress] = useState(false);
  const [secondaryForm, setSecondaryForm] = useState(secondaryFormState);
  const [showAdditional, setAdditional] = useState(false);

  const handleSecondary = (event) => {
    setSecondaryForm({
      ...secondaryForm,
      [event.target.name]: event.target.value,
    });
  };

  useEffect(() => {
    if (underwriting?.firstName) {
      setFormData({
        ...formData,
        first_name: decryptme(underwriting?.firstName),
        last_name: decryptme(underwriting?.lastName),
        dob: decryptme(underwriting?.dob),

        zipCode: underwriting?.postalCode,
        emailAddress: decryptme(underwriting?.email),
      });
      setAdditional(
        states1998.indexOf(
          getState(underwriting?.postalCode)?.st?.toUpperCase()
        ) > -1
      );
    }
  }, [underwriting]);

  const [tobaccoValue, setTobaccoValue] = useState({
    uses: null,
    tobaccoType: null,
    dailyUseCount: null,
    lastUseDate: null,
  });

  const [countryList, setCountryList] = useState([]);
  const [stateDropdown, setStateDropdown] = useState(null);
  const [drivingStateDropdown, setDrivingStateDropdown] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const getCountryList = async () => {
    try {
      const isAuthorized = getJwt() ? true : false;
      let response = await commonApiCall(
        API_URL_CLIENT + `getCountry`,
        "get",
        "",
        "",
        isAuthorized
      );

      if (response.status.code === 200) {
        let getCountry = response.data.map((list) => {
          return {
            value: list.code,
            label: list.name,
          };
        });
        setCountryDropDown(response.data);
        setCountryList({
          ...countryList,
          getCountry,
        });
      }
    } catch (err) {
      showErrorToast(err);
    }
  };

  useEffect(() => {
    getCountryList();
  }, []);

  const getCountryCodes = async () => {
    return await commonApiCall(API_URL_CLIENT + "getCountry", "", "get");
  };

  const handleState = async (whichState, value) => {
    setStateLoading(true);
    if (
      value?.value !== "USA" &&
      value?.value !== "CANADA" &&
      whichState === "birthState"
    ) {
      setStateDropdown(null);
    } else {
      try {
        let response = await commonApiCall(
          API_URL_CLIENT +
            "getState?countryId=" +
            (whichState === "drivingState"
              ? 1
              : value?.value === "USA"
              ? 1
              : value?.value === "CANADA"
              ? 4
              : null),
          "",
          "get"
        );

        if (response && response !== "" && response.status.code === 200) {
          var new_options = [];
          response.data &&
            response.data.length > 0 &&
            response.data.map((element) => {
              new_options.push({
                value: element.code,
                label: element.name,
              });
              return true;
            });
          if (whichState === "drivingState") {
            setDrivingStateDropdown(new_options);
          } else {
            setStateDropdown(new_options);
          }
          setStateLoading(false);
          return new_options;
        } else if (
          response &&
          response !== "" &&
          response.status.code === 404
        ) {
          setStateDropdown(null);
        }
      } catch (e) {
        console.log(e);
      }
    }
    setStateLoading(false);
  };

  const saveUnderwriteDetails = async () => {
    try {
      const request = {
        applicationId: underwriting?.applicationId,
        applicationType: "Web",
        nextStep: "24,ASSURITY_UNDERWRITING,OCCUPATION_DETAILS",
      };

      const isAuthorized = getJwt() ? true : false;
      const apiEndPoint = API_URL_UNDERWRITING + "saveUnderWriting";
      const response = await commonApiCall(
        apiEndPoint,
        "post",
        request,
        UNDERWRITING_DETAILS,
        isAuthorized
      );

      if (response.status.code === 200) {
        window.location.href =
          UnderWritingRoutes.ASSURITY_UNDERWRITING +
          "?underwriting=OCCUPATION_DETAILS";
      }
    } catch (err) {
      showErrorToast(err);
    }
  };

  const { errors } = formData;

  const validateField = () => {
    let {
      first_name,
      last_name,
      ssn,
      address,
      city,
      state,
      zipCode,
      country,
      birthCountry,
      dob,
      drivingLicenseNumber,
      drivingLicenseState,
      birthState,
      haveLicense,
      emailAddress,
      selfEmployed,
      isUsCitizen,
      hasGreenCard,
      howLongYears,
      howLongMonth,
      greenCardNumber,
    } = formData;

    let error = {};
    let validate = true;
    if (first_name === "") {
      error.first_name = t("validation_message.fName");
      validate = false;
    }
    if (last_name === "") {
      error.last_name = t("validation_message.lName");
      validate = false;
    }
    if (dob === "") {
      error.dob = t("validation_message.dob");
      validate = false;
    }
    if (birthCountry === "") {
      error.birthCountry = t("validation_message.birthCountry");
      validate = false;
    }
    if (emailAddress === "" || !validateEmail(emailAddress)) {
      error.emailAddress = t("validation_message.validEmail");
      validate = false;
    }

    if (isUsCitizen === "") {
      error.isUsCitizen = "Please answer this question";
      validate = false;
    }

    if (isUsCitizen === "no" && hasGreenCard === "") {
      error.hasGreenCard = "This field is required";
      validate = false;
    }

    if (isUsCitizen === "no" && hasGreenCard === "yes") {
      if (greenCardNumber === "") {
        error.greenCardNumber = "This field is required";
        validate = false;
      }
      if (howLongMonth === "") {
        error.howLongMonth = "This field is required";
        validate = false;
      }
      if (howLongYears === "") {
        error.howLongYears = "This field is required";
        validate = false;
      }
    }

    if (stateDropdown) {
      if (birthState === "") {
        error.birthState = t("validation_message.birthstate");
        validate = false;
      }
    }

    if (haveLicense === "") {
      error.haveLicense = "Please answer this question";
      validate = false;
    }
    if (haveLicense === "yes" && haveLicense !== "") {
      if (drivingLicenseNumber === "") {
        error.drivingLicenseNumber = t("validation_message.licence");
        validate = false;
      }
      if (drivingLicenseState === "") {
        error.drivingLicenseState = t(
          "validation_message.driving_license_state"
        );
        validate = false;
      }
    }

    if (ssn === "") {
      error.ssn = t("validation_message.SSN");
      validate = false;
    }
    if (address === "") {
      error.address = t("validation_message.home_address");
      validate = false;
    }
    if (city === "") {
      error.city = t("validation_message.city");
      validate = false;
    }
    if (state === "") {
      error.state = t("validation_message.state");
      validate = false;
    }
    if (zipCode.trim() === "" && !getState(zipCode)) {
      error.zipCode = t("validation_message.PIN");
      validate = false;
    }

    if (addSecondaryAddress) {
      let secondaryError = {};
      if (secondaryForm.firstName === "") {
        secondaryError.firstName = t("validation_message.fName");
        validate = false;
      }
      if (secondaryForm.lastName === "") {
        secondaryError.lastName = t("validation_message.lName");
        validate = false;
      }
      if (secondaryForm.address === "") {
        secondaryError.address = t("validation_message.home_address");
        validate = false;
      }
      if (secondaryForm.city === "") {
        secondaryError.city = t("validation_message.city");
        validate = false;
      }
      if (secondaryForm.state === "") {
        secondaryError.state = t("validation_message.state");
        validate = false;
      }
      if (
        secondaryForm.zipCode.trim() === "" &&
        !getState(secondaryForm.zipCode)
      ) {
        secondaryError.zipCode = t("validation_message.PIN");
        validate = false;
      }
      if (secondaryForm.country.trim() === "") {
        secondaryError.country = t("validation_message.country");
        validate = false;
      }
      error.secondaryForm = secondaryError;
    }
    // if (country === "") {
    //   error.country = t("validation_message.country");
    //   validate = false;
    // }
    if (Object.keys(tobaccoValue).length === 0 || tobaccoValue.uses === null) {
      error.uses = "This field is required";
      validate = false;
    } else if (tobaccoValue?.uses === true) {
      if (
        !tobaccoValue.hasOwnProperty("tobaccoType") ||
        tobaccoValue?.tobaccoType === ""
      ) {
        error.tobaccoType = "This field is required";
        validate = false;
      }
      if (
        !tobaccoValue.hasOwnProperty("dailyUseCount") ||
        tobaccoValue?.dailyUseCount === ""
      ) {
        error.dailyUseCount = "This field is required";
        validate = false;
      }
      if (
        !tobaccoValue.hasOwnProperty("lastUseDate") ||
        tobaccoValue?.lastUseDate === ""
      ) {
        error.dailyUseCount = "This field is required";
        validate = false;
      }
    }

    if (selfEmployed === "") {
      error.selfEmployed = "This field is required";
      validate = false;
    }

    setFormData({
      ...formData,
      errors: error,
    });
    return validate;
  };

  const handleChange = (event) => {
    if (event.target.name === "haveLicense") {
      if (event.target.value === "yes") {
        handleState("drivingState", null);
      }
    }
    if (event.target.name === "zipCode") {
      setFormData({
        ...formData,
        zipCode: event.target.value,
        state: {
          value: getState(event.target.value)?.st,
          label: getState(event.target.value)?.state,
        },
      });
    } else if (event.target.name === "employerZipcode") {
      setFormData({
        ...formData,
        employerZipcode: event.target.value,
        employerState: {
          value: getState(event.target.value)?.st,
          label: getState(event.target.value)?.state,
        },
      });
    } else {
      setFormData({
        ...formData,
        [event.target.name]: event.target.value,
      });
    }
  };

  const handleCoverage = (index, e) => {
    let tempcoverage = [...formData.existingCoverages];
    let coverageItem = tempcoverage[index];
    coverageItem[e.target.name] = e.target.value;
    tempcoverage[index] = coverageItem;
    setFormData({ ...formData, existingCoverages: tempcoverage });
  };

  const handleTobaccoChange = (event) => {
    setTobaccoValue({
      ...tobaccoValue,
      [event.target.name]: event.target.value,
    });
  };

  const getTermYears = (pay) => {
    return pay === 10
      ? "TenYears"
      : pay === 15
      ? "FifteenYears"
      : pay === 20
      ? "TwentyYears"
      : pay === 30
      ? "ThirtyYears"
      : "";
  };

  const saveNext = async () => {
    setIsLoading(true);
    try {
      if (validateField()) {
        let request = {
          primaryInsuranceAgent: {
            emailAddress: "sampleEmail@assurity.xcom",
            identifier: underwriting?.applicationId,
            name: {
              first: "Primary",
              last: "Agent",
              middle: "I",
              suffix: "Suffix",
            },
          },
          product: {
            owners: {
              primaryOwner: {
                relationshipToPrimaryInsured: "Self",
              },
            },
            primaryInsured: {
              address: {
                city: formData.city,
                country: "USA",
                line1: formData.address,
                stateAbbreviation: formData.state.value,
                zipCode: formData.zipCode,
              },
              agreementToAssignOrSellPolicy:
                formData.agreementToAssignOrSellPolicy === "yes" ? true : false,
              otherInsurance: {
                otherCoveragesInForce:
                  formData.otherCoveragesInForce === "yes" ? true : false,
                thisInsuranceWillReplaceModifyOrBorrowAgainstExistingCoverages:
                  formData.thisInsuranceWillReplaceModifyOrBorrowAgainstExistingCoverages ===
                  "yes"
                    ? true
                    : false,

                useExistingFundToPayNewPolicies:
                  formData.useExistingFundToPayNewPolicies === "yes"
                    ? true
                    : false,
                terminatingExistingCoverages:
                  formData.terminatingExistingCoverages === "yes"
                    ? true
                    : false,
              },
              hadLifeExpectancyOrHealthExams:
                formData.hadLifeExpectancyOrHealthExams === "yes"
                  ? true
                  : false,
              person: {
                birthCountry: formData.birthCountry
                  ? formData.birthCountry.value
                  : "",
                emailAddress: formData.emailAddress,
                // formData.birthCountry.label == "United States of America"
                //   ? "USA"
                //   : formData.birthCountry.label,
                birthStateAbbreviation: formData.birthState.value,
                dateOfBirth: moment(formData.dob).format(),
                driversLicense: {
                  hasValidLicense: false,
                  number: null,
                  stateAbbreviation: null,
                },
                gender:
                  decryptme(underwriting?.gender) === "FEMALE"
                    ? "Female"
                    : "Male",
                isCitizen:
                  underwriting?.citizenship === "us_citizen_reside"
                    ? true
                    : false,
                height: {
                  feet: parseInt(decryptme(underwriting?.height).split(".")[0]),
                  inches: parseInt(
                    decryptme(underwriting?.height).split(".")[1]
                  ),
                },
                name: {
                  first: formData.first_name,
                  middle: "",
                  last: formData.last_name,
                  suffix: "",
                },
                socialSecurityNumber: formData.ssn,
                tobacco: {
                  uses: tobaccoValue.uses,
                  tobaccoType: tobaccoValue.tobaccoType?.value,

                  lastUseDate: tobaccoValue.lastUseDate,
                },
                weightInPounds: parseInt(decryptme(underwriting?.weight)),
                riskClass: {
                  applicationValue: "StandardTobacco",
                },
                occupation: {
                  isWorkingAtLeast30HoursAWeek: formData.currentWorkingHrs,
                  lengthOfEmployment: {
                    years: parseInt(formData.occupationYear),
                    months: parseInt(formData.occupationMonth),
                  },
                  employer: {
                    name: formData.employerName,
                    address: {
                      city: formData.employerCity,
                      country: formData.employerCountry?.value,
                      line1: formData.employerAddress,
                      stateAbbreviation: formData.employerState?.value,
                      zipCode: formData.employerZipcode,
                    },
                  },
                  income:
                    formData.selfEmployed === "yes"
                      ? {
                          netMonthlyIncome: formData.netMonthlyIncome,
                        }
                      : {
                          grossMonthlyIncome: formData.grossMonthlyIncome,
                        },
                  isSelfEmployed:
                    formData.selfEmployed === "yes" ? true : false,
                },
              },
              phone: {
                areaCode: decryptme(underwriting?.mobile).substring(0, 3), // decryptme(consumer?.countryCode).replace("+", "")
                number: decryptme(underwriting?.mobile).substring(3),
              },
              coverages: [
                {
                  coverageLookup: "Base",
                  benefitAmount: {
                    applicationValue: 50000,
                  },
                  options: {
                    termPeriod: {
                      applicationValue: getTermYears(underwriting?.pay),
                    },
                  },
                },
              ],
              purposeOfInsurance:
                formData.purposeOfInsurance?.value === "Other"
                  ? formData.purposeOfInsuranceOthers
                  : formData.purposeOfInsurance?.value,
              intentToSellInsurance:
                formData.intentToSellInsurance === "yes" ? true : false,
            },
            issueStateAbbreviation: formData.state.value,
          },
        };

        if (
          tobaccoValue.tobaccoType &&
          tobaccoValue.tobaccoType?.value === "Cigarettes"
        ) {
          request.product.primaryInsured.person.tobacco.dailyUseCount =
            parseInt(tobaccoValue.dailyUseCount);
        }
        if (
          tobaccoValue.tobaccoType &&
          (tobaccoValue.tobaccoType?.value === "Cigar" ||
            tobaccoValue.tobaccoType?.value === "Pipe" ||
            tobaccoValue.tobaccoType?.value === "Ecigarettes")
        ) {
          request.product.primaryInsured.person.tobacco.monthlyUseCount =
            parseInt(tobaccoValue.dailyUseCount);
        }
        if (formData.haveLicense == "yes") {
          request.product.primaryInsured.person.driversLicense = {
            hasValidLicense: true,
            number: formData.drivingLicenseNumber,
            stateAbbreviation: formData.drivingLicenseState.value,
          };
        }
        if (stateDropdown === null) {
          request.product.primaryInsured.person.citizenship = {};
          request.product.primaryInsured.person.citizenship.howLongInUnitedStates =
            {
              years: formData.howLongYears,
              months: formData.howLongMonth,
            };
          if (formData.hasGreenCard === "yes")
            request.product.primaryInsured.person.citizenship.greenCardNumber =
              formData.greenCardNumber;
        }

        if (formData.otherCoveragesInForce === "yes") {
          request.product.primaryInsured.otherInsurance.existingCoverages =
            formData.existingCoverages.map((coverage) => ({
              companyName: coverage.companyName,
              productType: coverage.typeOfInsurance?.value,
              benefitAmount: parseInt(coverage.amount),
            }));
        }

        const isAuthorized = getJwt() ? true : false;
        const apiEndPoint =
          API_URL_UNDERWRITING +
          "underWritingcase?applicationId=" +
          underwriting?.applicationId;
        const response = await commonApiCall(
          apiEndPoint,
          "post",
          request,
          null,
          isAuthorized
        );

        if (response.status.code === 200) {
          let decisonParam = {
            secondaryAddresseePayor: {
              address: {
                city: secondaryForm.city,
                country: secondaryForm.country,
                line1: secondaryForm.address,
                stateAbbreviation:
                  secondaryForm?.zipCode !== "" &&
                  secondaryForm?.country === "USA"
                    ? getState(parseInt(secondaryForm?.zipCode))?.st
                    : "",
                zipCode: secondaryForm.zipCode,
              },
              person: {
                name: {
                  first: secondaryForm.firstName,

                  last: secondaryForm.lastName,
                },
              },
              phone: {
                areaCode: "402",
                number: "345-6789",
              },
              relationshipToPrimaryInsured: "Self",
            },
            agreementToAssignOrSellPolicy:
              formData.agreementToAssignOrSellPolicy === "yes" ? true : false,
            hadLifeExpectancyOrHealthExams:
              formData.hadLifeExpectancyOrHealthExams === "yes" ? true : false,
            intentToSellInsurance:
              formData.intentToSellInsurance === "yes" ? true : false,
            purposeOfInsurance:
              formData.purposeOfInsurance?.value === "Other"
                ? formData.purposeOfInsuranceOthers
                : formData.purposeOfInsurance?.value,
            phone: {
              areaCode: decryptme(underwriting?.mobile).substring(0, 3),
              number: decryptme(underwriting?.mobile).substring(3),
            },
            number:
              formData.haveLicense == "yes"
                ? formData.drivingLicenseNumber
                : null,
            stateAbbreviation:
              formData.haveLicense == "yes"
                ? formData.drivingLicenseState.value
                : null,
            emailAddress: formData.emailAddress,
            otherInsurance: {
              otherCoveragesInForce:
                formData.otherCoveragesInForce === "yes" ? true : false,
              thisInsuranceWillReplaceModifyOrBorrowAgainstExistingCoverages:
                formData.thisInsuranceWillReplaceModifyOrBorrowAgainstExistingCoverages ===
                "yes"
                  ? true
                  : false,
            },
            occupation: {
              isWorkingAtLeast30HoursAWeek:
                formData.currentWorkingHrs === "yes" ? true : false,
              lengthOfEmployment: {
                years: parseInt(formData.occupationYear),
                months: parseInt(formData.occupationMonth),
              },
              employer: {
                name: formData.employerName,
                address: {
                  city: formData.employerCity,
                  country: formData.employerCountry?.value,
                  line1: formData.employerAddress,
                  stateAbbreviation: formData.employerState?.value,
                  zipCode: formData.employerZipcode,
                },
              },
              income:
                formData.selfEmployed === "yes"
                  ? {
                      netMonthlyIncome: formData.netMonthlyIncome,
                    }
                  : {
                      grossMonthlyIncome: formData.grossMonthlyIncome,
                    },
              isSelfEmployed: formData.selfEmployed === "yes" ? true : false,
            },
          };

          if (formData.otherCoveragesInForce === "yes") {
            decisonParam.otherInsurance.existingCoverages =
              formData.existingCoverages.map((coverage) => ({
                companyName: coverage.companyName,
                productType: coverage.typeOfInsurance?.value,
                benefitAmount: coverage.amount,
              }));
          }
          localStorage.setItem("decisionParam", JSON.stringify(decisonParam));
          onAnswered();
          saveUnderwriteDetails();
        } else {
          showErrorToast(response.status.message);
        }
      }
    } catch (err) {
      console.log(err);
      showErrorToast(err);
    }
    setIsLoading(false);
  };

  const onTobaccoChange = (e) => {
    let tempTobacco = { ...tobaccoValue };
    if (e.target.name === "uses") {
      tempTobacco[e.target.name] = e.target.value === "yes" ? true : false;
    } else {
      tempTobacco[e.target.name] = e.target.value;
    }
    setTobaccoValue(tempTobacco);
  };
  const showMonthlyCount = () => {
    return Object.keys(tobaccoValue).length !== 0
      ? tobaccoValue.hasOwnProperty("tobaccoType") &&
        tobaccoValue.tobaccoType &&
        tobaccoValue.tobaccoType?.value !== "Smokeless" &&
        tobaccoValue.tobaccoType?.value !== "Nicotine_Patches_Gum_Other"
        ? true
        : false
      : false;
  };

  // const getAssurityStateList = () => {
  //   const temp = stateDropdown.map((st) => {
  //     console.log(st);
  //     // assurityStateList.indexOf(st.value);
  //     if (assurityStateList.indexOf(st.value) > -1) {
  //       console.log(st);
  //       return st;
  //     }
  //   });
  //   return temp;
  // };

  const addMoreCoverages = () => {
    setFormData({
      ...formData,
      existingCoverages: [
        ...formData.existingCoverages,
        { companyName: "", typeOfInsurance: "", amount: "" },
      ],
    });
  };

  const removeExistingCoverage = (index) => {
    let tempExisting = [...formData.existingCoverages];
    tempExisting.splice(index, 1);
    setFormData({ ...formData, existingCoverages: tempExisting });
  };

  return (
    <div className="sbli-main-question sbli-main-user-detail-question">
      <div className="container-fluid my-5">
        <form>
          <div className="row justify-content-center">
            <div className="col-md-8">
              <h4 className="mb-3">What's your name?</h4>
              <div className="form-group">
                <div className="input-effect">
                  <input
                    className="form-control"
                    placeholder="First Name"
                    name="first_name"
                    id="first_name"
                    type="text"
                    value={formData.first_name}
                    onChange={handleChange}
                  />
                  <label htmlFor="first_name">
                    {"First Name"}
                    <sup className="text-danger">*</sup>
                  </label>
                  <em className="input-icon"></em>
                  <span className="text-danger">{errors?.first_name}</span>
                </div>
              </div>
              <div className="form-group">
                <div className="input-effect">
                  <input
                    className="form-control"
                    placeholder="Last Name"
                    name="last_name"
                    id="last_name"
                    type="text"
                    value={formData.last_name}
                    onChange={handleChange}
                  />
                  <label htmlFor="last_name">
                    {"Last Name"}
                    <sup className="text-danger">*</sup>
                  </label>
                  <em className="input-icon"></em>
                  <span className="text-danger">{errors?.last_name}</span>
                </div>
              </div>
            </div>
            <div className="col-md-8">
              <h4 className="mb-3">
                What is your date of birth?<sup className="text-danger">*</sup>
              </h4>
              <div className="form-group custom-datepicker">
                <DatePicker
                  selected={formData.dob ? new Date(formData.dob) : ""}
                  maxDate={new Date()}
                  placeholderText="MM/DD/YYYY"
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  disabled={true}
                  dateFormatCalendar="MMMM"
                  onChange={(date) =>
                    setFormData({
                      ...formData,
                      dob: `${
                        date.getMonth() + 1
                      }-${date.getDate()}-${date.getFullYear()}`,
                    })
                  }
                />
                <span className="datepicker-label">
                  {t("beneficiaryPage.date_of_birth")}
                </span>
                <span className="text-danger">{errors?.dob}</span>
              </div>
            </div>
            <div className="col-md-8">
              <h4 className="mb-3">
                What is your birth country?<sup className="text-danger">*</sup>
              </h4>
              <div className="form-group fixed-floating-label mb-4">
                <div className="default-select with-border">
                  <Select
                    className={"custom-select-menu"}
                    classNamePrefix={"custom-select"}
                    options={getAssurityCountry()}
                    onChange={(value) => {
                      setFormData({ ...formData, birthCountry: value });
                      handleState("birthState", value);
                    }}
                    // value={countryList.getCountry[0]}
                  />
                  <span className="fixed-label">
                    {t("manual_form.birthCountry")}
                  </span>
                  <span className="text-danger ">{errors?.birthCountry}</span>
                  {stateLoading && "Please wait..."}
                </div>
              </div>
            </div>
            {stateDropdown ? (
              <div className="col-md-8">
                <h4 className="mb-3">
                  What is your birth state?<sup className="text-danger">*</sup>
                </h4>
                <div className="form-group fixed-floating-label mb-4">
                  <div className="default-select with-border">
                    <Select
                      options={stateDropdown}
                      className={"custom-select-menu"}
                      isSearchable={true}
                      filterOption={(e, searchWith) =>
                        customFilter(e, searchWith)
                      }
                      //    onFocus={() => handleState("birthState")}
                      onChange={(value) =>
                        setFormData({
                          ...formData,
                          birthState: value,
                          errors: Object.assign(formData.errors, {
                            birthState: "",
                          }),
                        })
                      }
                      classNamePrefix={"custom-select"}
                    />
                    <span className="fixed-label">
                      {t("manual_form.birthstate")}
                    </span>
                    <span className="text-danger ">{errors?.birthState}</span>
                  </div>
                </div>
              </div>
            ) : (
              formData.birthCountry !== "" && <React.Fragment></React.Fragment>
            )}

            <div className="col-md-8">
              <div class="sbli-main-question mb-3">
                <div class="sbli-question-container">
                  <div
                    className={"sbli-parent-question  mb-2 question_"}
                    id="sbli_question_container"
                  >
                    <h4 className="mb-4 ">Are you a U.S. Citizen?<sup className="text-danger">*</sup></h4>
                    <span className="custom-radio mt-3 d-block">
                      <label className="m-0 mb-3" htmlFor={"isUsCitizenab"}>
                        <input
                          type="radio"
                          name={"isUsCitizen"}
                          id={"isUsCitizenab"}
                          value={"yes"}
                          onChange={handleChange}
                        />
                        <span></span> Yes
                      </label>

                      <label className="m-0" htmlFor={"isUsCitizenba"}>
                        <input
                          type="radio"
                          name={"isUsCitizen"}
                          id={"isUsCitizenba"}
                          value={"no"}
                          onChange={handleChange}
                        />
                        <span></span> No
                      </label>
                    </span>
                    <span className="text-danger ">{errors?.isUsCitizen}</span>
                  </div>
                </div>
              </div>
              {formData.isUsCitizen === "no" && (
                <div class="sbli-main-question mb-3">
                  <div class="sbli-question-container">
                    <div
                      className={"sbli-parent-question  mb-2 question_"}
                      id="sbli_question_container"
                    >
                      <h4 className="mb-4 ">
                        Are you a permanent resident of the United States?<sup className="text-danger">*</sup>
                      </h4>
                      <span className="custom-radio mt-3 d-block">
                        <label className="m-0 mb-3" htmlFor={"permanentab"}>
                          <input
                            type="radio"
                            name={"hasGreenCard"}
                            id={"permanentab"}
                            value={"yes"}
                            onChange={handleChange}
                          />
                          <span></span> Yes
                        </label>

                        <label className="m-0" htmlFor={"permanentba"}>
                          <input
                            type="radio"
                            name={"hasGreenCard"}
                            id={"permanentba"}
                            value={"no"}
                            onChange={handleChange}
                          />
                          <span></span> No
                        </label>
                      </span>
                      <span className="text-danger ">
                        {errors?.hasGreenCard}
                      </span>
                    </div>
                  </div>
                </div>
              )}
              {formData.isUsCitizen === "no" &&
                formData.hasGreenCard === "yes" && (
                  <div>
                    <div className="form-group">
                      <div className="input-effect">
                        <InputMask
                          mask="aaa9999999999"
                          className="form-control"
                          placeholder="Green Card Number"
                          name="greenCardNumber"
                          id="greenCardNumber"
                          type="text"
                          onChange={handleChange}
                          value={formData.greenCardNumber.toUpperCase()}
                        />
                        <label htmlFor="greenCardNumber">
                          {"Green Card Number"}
                          <sup className="text-danger">*</sup>
                        </label>
                        <em className="input-icon"></em>
                        <span className="text-danger ">
                          {errors?.greenCardNumber}
                        </span>
                      </div>
                    </div>
                    <div className="">
                      <div class="sbli-main-question mb-3">
                        <div class="sbli-question-container">
                          <div
                            className={"sbli-parent-question  mb-2 question_"}
                            id="sbli_question_container"
                          >
                            <h4 className="mb-4 ">
                              How long has the Proposed Insured been in the
                              United States?
                            </h4>
                            <div className="row">
                              <div className="form-group col-md-6">
                                <div className="input-effect">
                                  <NumberRangeInput
                                    className="form-control"
                                    placeholder="Years"
                                    name="howLongYears"
                                    id="howLongYears"
                                    type="text"
                                    onChange={(e) => handleChange(e)}
                                    min={0}
                                    max={100}
                                    value={formData.howLongYears}
                                  />
                                  <label htmlFor="howLongYears">
                                    {"Years"}
                                    <sup className="text-danger">*</sup>
                                  </label>
                                  <em className="input-icon"></em>
                                  <span className="text-danger ">
                                    {errors?.howLongYears}
                                  </span>
                                </div>
                              </div>
                              <div className="form-group col-md-6">
                                <div className="input-effect">
                                  <NumberRangeInput
                                    className="form-control"
                                    placeholder="Month"
                                    name="howLongMonth"
                                    id="howLongMonth"
                                    type="text"
                                    min={0}
                                    max={12}
                                    onChange={(e) => handleChange(e)}
                                    value={formData.howLongMonth}
                                  />
                                  <label htmlFor="howLongMonth">
                                    {"Month"}
                                    <sup className="text-danger">*</sup>
                                  </label>
                                  <em className="input-icon"></em>
                                  <span className="text-danger ">
                                    {errors?.howLongMonth}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
            </div>
            <div className="col-md-8">
              <div class="sbli-main-question mb-3">
                <div class="sbli-question-container">
                  <div
                    className={"sbli-parent-question  mb-2 question_"}
                    id="sbli_question_container"
                  >
                    <h4 className="mb-4 ">
                      Does any Proposed Insured have other annuity or life
                      insurance coverage in force?
                      <sup className="text-danger">*</sup>
                    </h4>
                    <span className="custom-radio mt-3 d-block">
                      <label
                        className="m-0 mb-3"
                        htmlFor={"otherCoveragesInForceab"}
                      >
                        <input
                          type="radio"
                          name={"otherCoveragesInForce"}
                          id={"otherCoveragesInForceab"}
                          value={"yes"}
                          onChange={handleChange}
                        />
                        <span></span> Yes
                      </label>

                      <label
                        className="m-0"
                        htmlFor={"otherCoveragesInForceba"}
                      >
                        <input
                          type="radio"
                          name={"otherCoveragesInForce"}
                          id={"otherCoveragesInForceba"}
                          value={"no"}
                          onChange={handleChange}
                        />
                        <span></span> No
                      </label>
                    </span>
                    {/* <span className="custom-radio mt-3 d-block">
                <label className="m-0 mb-3" for="1260ab">
                  <input type="radio" name="1260" id="1260ab" value="1" />
                  <span></span> Yes
                </label>
                <label className="m-0" for="1260ba">
                  <input type="radio" name="1260" id="1260ba" value="0" />
                  <span></span> No
                </label>
              </span>{" "} */}
                    <span className="text-danger">
                      {errors?.otherCoveragesInForce}
                    </span>
                  </div>
                </div>
              </div>
              {formData.otherCoveragesInForce === "yes" ? (
                <React.Fragment>
                  {formData.existingCoverages.map((coverage, index) => {
                    return (
                      <div className="coverageQuestion">
                        <h5 className="font-weight-bold d-flex align-items-center mb-3">
                          Existing Coveage {index + 1}
                          {formData.existingCoverages.length > 1 && (
                            <button
                              onClick={() => removeExistingCoverage(index)}
                              type="button"
                              className="btn btn-link ml-auto p-0 text-right"
                            >
                              <img src="/assets/images/delete.png" width={30} />
                            </button>
                          )}
                        </h5>
                        <div className="form-group">
                          <div className="input-effect">
                            <input
                              className="form-control"
                              placeholder="Company Name"
                              name="companyName"
                              id="carrierName"
                              type="text"
                              onChange={(e) => handleCoverage(index, e)}
                              value={coverage.carrierName}
                            />
                            <label htmlFor="carrierName">
                              {"Company Name"}
                              <sup className="text-danger">*</sup>
                            </label>
                            <em className="input-icon"></em>
                            <span className="text-danger ">
                              {errors?.carrierName}
                            </span>
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="input-effect">
                            <Select
                              options={[
                                {
                                  value: "Life",
                                  label: "Life",
                                },
                                {
                                  value: "Annuity",
                                  label: "Annuity",
                                },
                              ]}
                              className={"custom-select-menu"}
                              placeholder="Product Type"
                              onChange={(value) => {
                                handleCoverage(index, {
                                  target: {
                                    name: "typeOfInsurance",
                                    value: value,
                                  },
                                });
                              }}
                              value={coverage.typeOfInsurance}
                              classNamePrefix={"custom-select"}
                            />
                            {/* <span className="text-danger">
                        {errors?.drivingLicenseState}
                      </span> */}
                          </div>
                        </div>
                        {/* <div className="form-group">
                    <div className="input-effect">
                      <input
                        className="form-control"
                        placeholder="Product Type"
                        name="typeOfInsurance"
                        id="typeOfInsurance"
                        type="text"
                        onChange={handleChange}
                        value={formData.typeOfInsurance}
                      />
                      <label htmlFor="typeOfInsurance">{"Product Type"}</label>
                      <em className="input-icon"></em>
                      <span className="text-danger ">
                        {errors?.typeOfInsurance}
                      </span>
                    </div>
                  </div> */}
                        <div className="form-group">
                          <div className="input-effect">
                            <NumberFormat
                              prefix={"$"}
                              thousandSeparator={true}
                              className="form-control w-100 number-format-amount"
                              inputMode="numeric"
                              name="amount"
                              id="amount"
                              placeholder="Benefit Amount"
                              value={coverage.amount}
                              onValueChange={(e) =>
                                handleCoverage(index, {
                                  target: { name: "amount", value: e.value },
                                })
                              }
                            />

                            <label htmlFor="amount">
                              {"Benefit Amount"}
                              <sup className="text-danger">*</sup>
                            </label>
                            <em className="input-icon"></em>
                            <span className="text-danger ">
                              {errors?.amount}
                            </span>
                          </div>
                        </div>
                        {formData.existingCoverages.length > 0 && (
                          <hr className="mb-2" />
                        )}
                      </div>
                    );
                  })}
                  {formData.existingCoverages.length < 5 && (
                    <button
                      className="btn btn-link text-primary my-3"
                      type="button"
                      onClick={addMoreCoverages}
                    >
                      Add More Coverages
                    </button>
                  )}
                </React.Fragment>
              ) : null}

              <div class="sbli-main-question mb-3">
                <div class="sbli-question-container">
                  <div
                    className={"sbli-parent-question  mb-2 question_"}
                    id="sbli_question_container"
                  >
                    <h4 className="mb-4 ">
                      If this insurance is issued, will it replace, modify or
                      borrow against existing or pending annuity or life
                      insurance coverage?<sup className="text-danger">*</sup>
                    </h4>
                    <span className="custom-radio mt-3 d-block">
                      <label
                        className="m-0 mb-3"
                        htmlFor={
                          "thisInsuranceWillReplaceModifyOrBorrowAgainstExistingCoveragesab"
                        }
                      >
                        <input
                          type="radio"
                          name={
                            "thisInsuranceWillReplaceModifyOrBorrowAgainstExistingCoverages"
                          }
                          id={
                            "thisInsuranceWillReplaceModifyOrBorrowAgainstExistingCoveragesab"
                          }
                          value={"yes"}
                          onChange={handleChange}
                        />
                        <span></span> Yes
                      </label>

                      <label
                        className="m-0"
                        htmlFor={
                          "thisInsuranceWillReplaceModifyOrBorrowAgainstExistingCoveragesba"
                        }
                      >
                        <input
                          type="radio"
                          name={
                            "thisInsuranceWillReplaceModifyOrBorrowAgainstExistingCoverages"
                          }
                          id={
                            "thisInsuranceWillReplaceModifyOrBorrowAgainstExistingCoveragesba"
                          }
                          value={"no"}
                          onChange={handleChange}
                        />
                        <span></span> No
                      </label>
                    </span>
                    {/* <span className="custom-radio mt-3 d-block">
                <label className="m-0 mb-3" for="1260ab">
                  <input type="radio" name="1260" id="1260ab" value="1" />
                  <span></span> Yes
                </label>
                <label className="m-0" for="1260ba">
                  <input type="radio" name="1260" id="1260ba" value="0" />
                  <span></span> No
                </label>
              </span>{" "} */}
                    <span className="text-danger">
                      {
                        errors?.thisInsuranceWillReplaceModifyOrBorrowAgainstExistingCoverages
                      }
                    </span>
                  </div>
                </div>
              </div>
              {formData.otherCoveragesInForce === "yes" &&
                formData.thisInsuranceWillReplaceModifyOrBorrowAgainstExistingCoverages ===
                  "yes" &&
                showAdditional && (
                  <React.Fragment>
                    <div class="sbli-main-question mb-3">
                      <div class="sbli-question-container">
                        <div
                          className={"sbli-parent-question  mb-2 question_"}
                          id="sbli_question_container"
                        >
                          <h4 className="mb-4 ">
                            Are you considering discontinuing making premium
                            payments, surrendering, forfeiting, assigning to the
                            insurer or otherwise terminating your existing
                            policy or contract?
                            <sup className="text-danger">*</sup>
                          </h4>
                          <span className="custom-radio mt-3 d-block">
                            <label
                              className="m-0 mb-3"
                              htmlFor={"useExistingFundToPayNewPoliciesab"}
                            >
                              <input
                                type="radio"
                                name={"useExistingFundToPayNewPolicies"}
                                id={"useExistingFundToPayNewPoliciesab"}
                                value={"yes"}
                                onChange={handleChange}
                              />
                              <span></span> Yes
                            </label>

                            <label
                              className="m-0"
                              htmlFor={"useExistingFundToPayNewPoliciesba"}
                            >
                              <input
                                type="radio"
                                name={"useExistingFundToPayNewPolicies"}
                                id={"useExistingFundToPayNewPoliciesba"}
                                value={"no"}
                                onChange={handleChange}
                              />
                              <span></span> No
                            </label>
                          </span>
                          {/* <span className="custom-radio mt-3 d-block">
                <label className="m-0 mb-3" for="1260ab">
                  <input type="radio" name="1260" id="1260ab" value="1" />
                  <span></span> Yes
                </label>
                <label className="m-0" for="1260ba">
                  <input type="radio" name="1260" id="1260ba" value="0" />
                  <span></span> No
                </label>
              </span>{" "} */}
                          <span className="text-danger">
                            {errors?.useExistingFundToPayNewPolicies}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="sbli-main-question mb-3">
                      <div class="sbli-question-container">
                        <div
                          className={"sbli-parent-question  mb-2 question_"}
                          id="sbli_question_container"
                        >
                          <h4 className="mb-4 ">
                            Are you considering using funds from your existing
                            policies or contracts to pay premiums due on the new
                            policy or contract?
                            <sup className="text-danger">*</sup>
                          </h4>
                          <span className="custom-radio mt-3 d-block">
                            <label
                              className="m-0 mb-3"
                              htmlFor={"terminatingExistingCoveragesab"}
                            >
                              <input
                                type="radio"
                                name={"terminatingExistingCoverages"}
                                id={"terminatingExistingCoveragesab"}
                                value={"yes"}
                                onChange={handleChange}
                              />
                              <span></span> Yes
                            </label>

                            <label
                              className="m-0"
                              htmlFor={"terminatingExistingCoveragesba"}
                            >
                              <input
                                type="radio"
                                name={"terminatingExistingCoverages"}
                                id={"terminatingExistingCoveragesba"}
                                value={"no"}
                                onChange={handleChange}
                              />
                              <span></span> No
                            </label>
                          </span>

                          <span className="text-danger">
                            {errors?.terminatingExistingCoverages}
                          </span>
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                )}
            </div>
            <div className="col-md-8">
              <div className="form-group">
                <div className="input-effect">
                  <input
                    className="form-control"
                    placeholder="Email Address"
                    name="emailAddress"
                    id="emailAddress"
                    type="email"
                    onChange={handleChange}
                    disabled
                    value={formData.emailAddress}
                  />
                  <label htmlFor="emailAddress">
                    {"Email Address"}
                    <sup className="text-danger">*</sup>
                  </label>
                  <em className="input-icon"></em>
                  <span className="text-danger ">{errors?.emailAddress}</span>
                </div>
              </div>
            </div>
            <div className="col-md-8">
              <h4 className="mb-3">
                What is your social security number?
                <sup className="text-danger">*</sup>
              </h4>
              <div className="form-group">
                <div className="input-effect ">
                  <NumberFormat
                    name="ssn"
                    id="ssn"
                    className="form-control"
                    placeholder="Social Security Number (SSN#)"
                    format="###-##-####"
                    value={formData.ssn}
                    onValueChange={(e) =>
                      handleChange({
                        target: { name: "ssn", value: e.value },
                      })
                    }
                  />
                  <label htmlFor="SSN">Social Security Number (SSN#)</label>
                  <span className="text-danger ">{errors?.ssn}</span>
                </div>
                <div className="d-flex">
                  <img
                    className="mr-1 mt-1"
                    width="20"
                    height="20"
                    src={security}
                    alt="Password Protection"
                  />
                  <p className="mb-0 pb-0 mt-1" style={{ fontSize: "13px" }}>
                    {" "}
                    Your social security number is used to verify your identity
                    and is never saved or shared.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-8">
              <div class="sbli-main-question mb-3">
                <div class="sbli-question-container">
                  <div
                    className={"sbli-parent-question  mb-2 question_"}
                    id="sbli_question_container"
                  >
                    <h4 className="mb-4 ">
                      Do you have a driver’s license?
                      <sup className="text-danger">*</sup>
                    </h4>
                    <span className="custom-radio mt-3 d-block">
                      <label className="m-0 mb-3" htmlFor={"haveLicenseAb"}>
                        <input
                          type="radio"
                          name={"haveLicense"}
                          id={"haveLicenseAb"}
                          value={"yes"}
                          onChange={handleChange}
                        />
                        <span></span> Yes
                      </label>

                      <label className="m-0" htmlFor={"haveLicenseBa"}>
                        <input
                          type="radio"
                          name={"haveLicense"}
                          id={"haveLicenseBa"}
                          value={"no"}
                          onChange={handleChange}
                        />
                        <span></span> No
                      </label>
                    </span>
                    {/* <span className="custom-radio mt-3 d-block">
                <label className="m-0 mb-3" for="1260ab">
                  <input type="radio" name="1260" id="1260ab" value="1" />
                  <span></span> Yes
                </label>
                <label className="m-0" for="1260ba">
                  <input type="radio" name="1260" id="1260ba" value="0" />
                  <span></span> No
                </label>
              </span>{" "} */}
                    <span className="text-danger">{errors?.haveLicense}</span>
                  </div>
                </div>
              </div>
              {formData.haveLicense === "yes" && (
                <div>
                  <h4 className="mb-3">
                    What is your driving license number?
                    <sup className="text-danger">*</sup>
                  </h4>
                  <div className="form-group">
                    <div className="input-effect">
                      <input
                        className="form-control"
                        placeholder="First Name"
                        name="drivingLicenseNumber"
                        id="lincenseNumber"
                        type="text"
                        value={formData.drivingLicenseNumber}
                        onChange={handleChange}
                      />
                      <label htmlFor="first_name">
                        {"Driving License Number"}
                      </label>
                      <em className="input-icon"></em>
                      <span className="text-danger">
                        {" "}
                        {errors?.drivingLicenseNumber}
                      </span>
                    </div>
                  </div>

                  <h4 className="mb-3">
                    What is your driving license state?
                    <sup className="text-danger">*</sup>
                  </h4>
                  <div className="form-group">
                    <div className="input-effect">
                      <Select
                        options={drivingStateDropdown}
                        className={"custom-select-menu"}
                        isSearchable={true}
                        filterOption={(e, searchWith) =>
                          customFilter(e, searchWith)
                        }
                        // onFocus={() => handleState("drivingState", null)}
                        onChange={(value) => {
                          setFormData({
                            ...formData,
                            drivingLicenseState: value,

                            errors: Object.assign(formData.errors, {
                              drivingLicenseState: "",
                            }),
                          });
                        }}
                        classNamePrefix={"custom-select"}
                      />
                      <span className="text-danger">
                        {errors?.drivingLicenseState}
                      </span>
                    </div>
                  </div>
                </div>
              )}
              {/* <div className="form-group">
                <div className="input-effect">
                  <NumberFormat
                    name="lincenseNumber"
                    id="lincenseNumber"
                    className="form-control"
                    placeholder="Driving License Number"
                    value={formData.drivingLicenseNumber}
                    onValueChange={(e) =>
                      handleChange({
                        target: {
                          name: "drivingLicenseNumber",
                          value: e.value,
                        },
                      })
                    }
                  />
                  <label htmlFor="lincenseNumber">Driving License Number</label>
                  <span className="text-danger ">
                    {errors?.drivingLicenseNumber}
                  </span>
                </div>
              </div> */}
            </div>
            <div className="col-md-8">
              <h4 className="mb-3">
                What is your home address (no P.O. Boxes)?
              </h4>
              <div className="form-group">
                <div className="input-effect">
                  <input
                    className="form-control"
                    placeholder="Home address (no P.O. Boxes)"
                    name="address"
                    id="address"
                    type="text"
                    onChange={handleChange}
                    value={formData.address}
                  />
                  <label htmlFor="address">
                    {"Home address (no P.O. Boxes)"}
                    <sup className="text-danger">*</sup>
                  </label>
                  <em className="input-icon"></em>
                  <span className="text-danger ">{errors?.address}</span>
                </div>
              </div>
            </div>
            <div className="col-md-8">
              <div className="form-group">
                <div className="input-effect">
                  <input
                    className="form-control"
                    placeholder="City"
                    name="city"
                    id="city"
                    type="text"
                    onChange={handleChange}
                    value={formData.city}
                  />
                  <label htmlFor="city">
                    {"City"}
                    <sup className="text-danger">*</sup>
                  </label>
                  <em className="input-icon"></em>
                  <span className="text-danger ">{errors?.city}</span>
                </div>
              </div>
            </div>
            <div className="col-md-8">
              <div className="form-group">
                <div className="input-effect ">
                  <NumberFormat
                    name="ssn"
                    id="ssn"
                    className="form-control"
                    placeholder="Zipcode"
                    format="#####"
                    disabled
                    value={formData.zipCode}
                    onValueChange={(e) =>
                      handleChange({
                        target: { name: "zipCode", value: e.value },
                      })
                    }
                  />
                  <label htmlFor="zipCode">
                    {"Zip Code"}
                    <sup className="text-danger">*</sup>
                  </label>
                  <span className="text-danger">{errors?.zipCode}</span>
                </div>
                {/* <div className="input-effect">
                  <input
                    className="form-control"
                    placeholder="Zip Code"
                    name="zipCode"
                    id="zipCode"
                    type="number"
                    maxLength="5"
                    onChange={handleChange}
                    value={formData.zipCode}
                  />
                  <label htmlFor="zipCode">{"Zip Code"}</label>
                  <em className="input-icon"></em>
                  <span className="text-danger">{errors?.zipCode}</span>
                </div> */}
              </div>
            </div>
            <div className="col-md-8">
              <div className="form-group fixed-floating-label mb-4">
                <div className="default-select with-border">
                  <Select
                    className={"custom-select-menu"}
                    classNamePrefix={"custom-select"}
                    isDisabled={true}
                    value={
                      formData?.zipCode !== ""
                        ? {
                            value: getState(parseInt(formData?.zipCode))?.st,
                            label: getState(parseInt(formData?.zipCode))?.state,
                          }
                        : ""
                    }
                  />
                  <span className="fixed-label">{t("manual_form.state")}</span>
                  <span className="fixed-label">
                    State<sup className="text-danger">*</sup>
                  </span>
                  <em className="input-icon"></em>
                </div>
              </div>
              <div className="custom-checkbox my-4">
                <label
                  className="m-0"
                  style={{ fontSize: 16, color: "#000" }}
                  htmlFor={"confirm_checkbox"}
                >
                  <input
                    type="checkbox"
                    id={"confirm_checkbox"}
                    checked={addSecondaryAddress}
                    onChange={(e) => {
                      setAddSecondaryAddress(e.target.checked);
                      if (!e.target.checked) {
                        setSecondaryForm(secondaryFormState);
                      }
                    }}
                  />
                  <span></span> Add Secondary Addressee
                </label>
              </div>
            </div>

            {addSecondaryAddress && (
              <React.Fragment>
                <div className="col-md-8 mt-3">
                  <h4 className="mb-3">
                    What is your Secondary home address (no P.O. Boxes)?
                  </h4>
                  <div className="form-group">
                    <div className="input-effect">
                      <input
                        className="form-control"
                        placeholder="First Name"
                        name="firstName"
                        id="firstName"
                        type="text"
                        onChange={handleSecondary}
                        value={secondaryForm.firstName}
                      />
                      <label htmlFor="address">
                        {"First Name"}
                        <sup className="text-danger">*</sup>
                      </label>
                      <em className="input-icon"></em>
                      <span className="text-danger ">
                        {errors?.secondaryForm?.firstName}
                      </span>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="input-effect">
                      <input
                        className="form-control"
                        placeholder="Last Name"
                        name="lastName"
                        id="lastName"
                        type="text"
                        onChange={handleSecondary}
                        value={secondaryForm.lastName}
                      />
                      <label htmlFor="address">
                        {"Last Name"}
                        <sup className="text-danger">*</sup>
                      </label>
                      <em className="input-icon"></em>
                      <span className="text-danger ">
                        {errors?.secondaryForm?.lastName}
                      </span>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="input-effect">
                      <input
                        className="form-control"
                        placeholder="Home address (no P.O. Boxes)"
                        name="address"
                        id="address"
                        type="text"
                        onChange={handleSecondary}
                        value={secondaryForm.address}
                      />
                      <label htmlFor="address">
                        {"Home address (no P.O. Boxes)"}
                        <sup className="text-danger">*</sup>
                      </label>
                      <em className="input-icon"></em>
                      <span className="text-danger ">
                        {errors?.secondaryForm?.address}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-md-8">
                  <div className="form-group fixed-floating-label mb-4">
                    <div className="default-select with-border">
                      <Select
                        className={"custom-select-menu"}
                        classNamePrefix={"custom-select"}
                        options={getAssurityCountry()}
                        onChange={(value) => {
                          handleSecondary({
                            target: { name: "country", value: value.value },
                          });
                        }}
                        // value={countryList.getCountry[0]}
                      />
                      <span className="fixed-label">Country</span>
                      <span className="text-danger ">
                        {errors?.secondaryForm?.country}
                      </span>
                      {stateLoading && "Please wait..."}
                    </div>
                  </div>
                </div>

                <div className="col-md-8">
                  <div className="form-group">
                    <div className="input-effect">
                      <input
                        className="form-control"
                        placeholder="City"
                        name="city"
                        id="city"
                        type="text"
                        onChange={handleSecondary}
                        value={secondaryForm.city}
                      />
                      <label htmlFor="city">
                        {"City"}
                        <sup className="text-danger">*</sup>
                      </label>
                      <em className="input-icon"></em>
                      <span className="text-danger ">
                        {errors?.secondaryForm?.city}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-md-8">
                  <div className="form-group">
                    <div className="input-effect ">
                      <NumberFormat
                        name="ssn"
                        id="ssn"
                        className="form-control"
                        placeholder="Zipcode"
                        format="#####"
                        value={secondaryForm?.zipCode}
                        onValueChange={(e) =>
                          handleSecondary({
                            target: { name: "zipCode", value: e.value },
                          })
                        }
                      />
                      <label htmlFor="zipCode">
                        {"Zip Code"}
                        <sup className="text-danger">*</sup>
                      </label>
                      <span className="text-danger">
                        {errors?.secondaryForm?.zipCode}
                      </span>
                    </div>
                    {/* <div className="input-effect">
                  <input
                    className="form-control"
                    placeholder="Zip Code"
                    name="zipCode"
                    id="zipCode"
                    type="number"
                    maxLength="5"
                    onChange={handleChange}
                    value={formData.zipCode}
                  />
                  <label htmlFor="zipCode">{"Zip Code"}</label>
                  <em className="input-icon"></em>
                  <span className="text-danger">{errors?.zipCode}</span>
                </div> */}
                  </div>
                </div>
                {secondaryForm.country === "USA" && (
                  <div className="col-md-8">
                    <div className="form-group fixed-floating-label mb-4">
                      <div className="default-select with-border">
                        <Select
                          className={"custom-select-menu"}
                          classNamePrefix={"custom-select"}
                          isDisabled={true}
                          value={
                            secondaryForm?.zipCode !== ""
                              ? {
                                  value: getState(
                                    parseInt(secondaryForm?.zipCode)
                                  )?.st,
                                  label: getState(
                                    parseInt(secondaryForm?.zipCode)
                                  )?.state,
                                }
                              : ""
                          }
                        />
                        <span className="fixed-label">
                          {t("manual_form.state")}
                        </span>
                        <span className="fixed-label">State</span>
                        <em className="input-icon"></em>
                      </div>
                    </div>
                  </div>
                )}
              </React.Fragment>
            )}
            {/* <div className="col-md-8">
              <div className="form-group fixed-floating-label mb-4">
                <div className="default-select with-border">
                  <Select
                    className={"custom-select-menu"}
                    classNamePrefix={"custom-select"}
                    options={countryList.getCountry}
                    onChange={(value) => {
                      setFormData({ ...formData, country: value });
                    }}
                    value={formData.country}
                  />
                  <span className="fixed-label">
                    {t("manual_form.country")}
                  </span>
                  <span className="fixed-label">Country</span>
                  <em className="input-icon"></em>
                  <span className="text-danger">{errors?.country}</span>
                </div>
              </div>
            </div> */}

            <div className="col-md-8">
              <div class="sbli-main-question mb-3">
                <div class="sbli-question-container">
                  <div
                    className={"sbli-parent-question  mb-2 question_"}
                    id="sbli_question_container"
                  >
                    <h4 className="mb-4 ">
                      Has the Proposed Insured ever used any form of tobacco or
                      nicotine-based products, or substitutes such as patches or
                      gum?<sup className="text-danger">*</sup>
                    </h4>
                    <span className="custom-radio mt-3 d-block">
                      <label className="m-0 mb-3" htmlFor={"ab"}>
                        <input
                          type="radio"
                          name={"uses"}
                          id={"ab"}
                          value={"yes"}
                          onChange={onTobaccoChange}
                        />
                        <span></span> Yes
                      </label>

                      <label className="m-0" htmlFor={"ba"}>
                        <input
                          type="radio"
                          name={"uses"}
                          id={"ba"}
                          value={"no"}
                          onChange={onTobaccoChange}
                        />
                        <span></span> No
                      </label>
                    </span>
                    {/* <span className="custom-radio mt-3 d-block">
                <label className="m-0 mb-3" for="1260ab">
                  <input type="radio" name="1260" id="1260ab" value="1" />
                  <span></span> Yes
                </label>
                <label className="m-0" for="1260ba">
                  <input type="radio" name="1260" id="1260ba" value="0" />
                  <span></span> No
                </label>
              </span>{" "} */}
                    <span className="text-danger">{errors?.uses}</span>
                  </div>
                </div>
              </div>
            </div>
            {tobaccoValue?.uses && (
              <React.Fragment>
                <div className="col-md-8 pl-3">
                  <h4 className="mb-3">
                    Tobacco Type<sup className="text-danger">*</sup>
                  </h4>
                  <div className="form-group fixed-floating-label mb-4">
                    <div className="default-select with-border">
                      <Select
                        className={"custom-select-menu"}
                        classNamePrefix={"custom-select"}
                        options={tobaccoTypeList}
                        value={tobaccoValue?.tobaccoType}
                        onChange={(value) => {
                          setTobaccoValue({
                            ...tobaccoValue,
                            tobaccoType: value,
                          });
                        }}
                      />
                      <span className="text-danger ">
                        {errors?.tobaccoType}
                      </span>
                      {/* <span className="fixed-label">
                    {t("manual_form.birthCountry")}
                  </span>
                */}
                    </div>
                  </div>
                </div>

                {showMonthlyCount() && (
                  <div className="col-md-8 pl-3">
                    <h4 className="mb-3">
                      Amount{" "}
                      {tobaccoValue?.tobaccoType?.value === "Cigarettes" ||
                      tobaccoValue?.tobaccoType?.value === "Ecigarettes"
                        ? "Daily"
                        : "Per Month"}
                      <sup className="text-danger">*</sup>
                    </h4>
                    <div className="form-group mb-4">
                      <div className="input-effect">
                        <input
                          className="form-control"
                          placeholder=""
                          name="dailyUseCount"
                          id="dailyUseCount"
                          type="number"
                          value={tobaccoValue?.dailyUseCount}
                          onChange={handleTobaccoChange}
                        />
                        {/* <label htmlFor="first_name">{"Amount Per Day"}</label>
                  <em className="input-icon"></em> */}
                        <span className="text-danger">
                          {errors?.dailyUseCount}
                        </span>
                      </div>
                    </div>
                  </div>
                )}

                <div className="col-md-8 pl-3">
                  <h4 className="mb-3">
                    Last Date of Use<sup className="text-danger">*</sup>
                  </h4>
                  <div className="form-group custom-datepicker">
                    <DatePicker
                      selected={
                        tobaccoValue?.lastUseDate
                          ? new Date(tobaccoValue?.lastUseDate)
                          : ""
                      }
                      maxDate={new Date()}
                      placeholderText="MM/DD/YYYY"
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      dateFormatCalendar="MMMM"
                      onChange={(date) =>
                        setTobaccoValue({
                          ...tobaccoValue,
                          lastUseDate: `${
                            date.getMonth() + 1
                          }-${date.getDate()}-${date.getFullYear()}`,
                        })
                      }
                    />
                    {/* <span className="datepicker-label">
                  {t("beneficiaryPage.date_of_birth")}
                </span> */}
                    <span className="text-danger">{errors?.lastUseDate}</span>
                  </div>
                </div>
              </React.Fragment>
            )}
            <div className="col-md-8">
              <div class="sbli-main-question mb-3">
                <div class="sbli-question-container">
                  <div
                    className={"sbli-parent-question  mb-2 question_"}
                    id="sbli_question_container"
                  >
                    <h4 className="mb-4 ">
                      Are you currently working at least 30 hours in your
                      primary occupation?<sup className="text-danger">*</sup>
                    </h4>
                    <span className="custom-radio mt-3 d-block">
                      <label
                        className="m-0 mb-3"
                        htmlFor={"abcurrentWorkingHrs"}
                      >
                        <input
                          type="radio"
                          name={"currentWorkingHrs"}
                          id={"abcurrentWorkingHrs"}
                          value={"yes"}
                          onChange={handleChange}
                        />
                        <span></span> Yes
                      </label>

                      <label className="m-0" htmlFor={"bacurrentWorkingHrs"}>
                        <input
                          type="radio"
                          name={"currentWorkingHrs"}
                          id={"bacurrentWorkingHrs"}
                          value={"no"}
                          onChange={handleChange}
                        />
                        <span></span> No
                      </label>
                    </span>
                    {/* <span className="custom-radio mt-3 d-block">
                <label className="m-0 mb-3" for="1260ab">
                  <input type="radio" name="1260" id="1260ab" value="1" />
                  <span></span> Yes
                </label>
                <label className="m-0" for="1260ba">
                  <input type="radio" name="1260" id="1260ba" value="0" />
                  <span></span> No
                </label>
              </span>{" "} */}
                    <span className="text-danger">
                      {errors?.currentWorkingHrs}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-8">
              <div class="sbli-main-question mb-3">
                <div class="sbli-question-container">
                  <div
                    className={"sbli-parent-question  mb-2 question_"}
                    id="sbli_question_container"
                  >
                    <h4 className="mb-4 ">
                      Are you self employed?<sup className="text-danger">*</sup>
                    </h4>
                    <span className="custom-radio mt-3 d-block">
                      <label className="m-0 mb-3" htmlFor={"abSelfEmployed"}>
                        <input
                          type="radio"
                          name={"selfEmployed"}
                          id={"abSelfEmployed"}
                          value={"yes"}
                          onChange={handleChange}
                        />
                        <span></span> Yes
                      </label>

                      <label className="m-0" htmlFor={"baSelfEmployed"}>
                        <input
                          type="radio"
                          name={"selfEmployed"}
                          id={"baSelfEmployed"}
                          value={"no"}
                          onChange={handleChange}
                        />
                        <span></span> No
                      </label>
                    </span>

                    <span className="text-danger">{errors?.selfEmployed}</span>
                  </div>
                </div>
              </div>
            </div>
            {formData.selfEmployed !== "" && (
              <React.Fragment>
                <div className="col-md-8">
                  <div class="sbli-main-question mb-3">
                    <div class="sbli-question-container">
                      <div
                        className={"sbli-parent-question  mb-2 question_"}
                        id="sbli_question_container"
                      >
                        <h4 className="mb-4 ">
                          What’s the name of your employer?
                        </h4>
                        <div className="form-group">
                          <div className="input-effect">
                            <input
                              className="form-control"
                              placeholder="Employer Name"
                              name="employerName"
                              id="employerName"
                              type="text"
                              onChange={handleChange}
                              value={formData.employerName}
                            />
                            <label htmlFor="employerName">
                              {"Employer Name"}
                            </label>
                            <em className="input-icon"></em>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-8">
                  <div class="sbli-main-question mb-3">
                    <div class="sbli-question-container">
                      <div
                        className={"sbli-parent-question  mb-2 question_"}
                        id="sbli_question_container"
                      >
                        <h4 className="mb-4 ">Length of Employment?</h4>
                        <div className="row">
                          <div className="form-group col-md-6">
                            <div className="input-effect">
                              <NumberRangeInput
                                className="form-control"
                                placeholder="Years"
                                name="occupationYear"
                                id="occupationYear"
                                type="number"
                                onChange={(e) => handleChange(e)}
                                value={formData.occupationYear}
                                min={0}
                                max={100}
                              />
                              <label htmlFor="occupationYear">{"Years"}</label>
                              <em className="input-icon"></em>
                            </div>
                          </div>
                          <div className="form-group col-md-6">
                            <div className="input-effect">
                              <NumberRangeInput
                                className="form-control"
                                placeholder="Month"
                                name="occupationMonth"
                                id="occupationMonth"
                                type="number"
                                onChange={(e) => handleChange(e)}
                                value={formData.occupationMonth}
                                min={0}
                                max={12}
                              />
                              <label htmlFor="occupationMonth">{"Month"}</label>
                              <em className="input-icon"></em>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="col-md-8">
                  <div class="sbli-main-question mb-3">
                    <div class="sbli-question-container">
                      <div
                        className={"sbli-parent-question  mb-2 question_"}
                        id="sbli_question_container"
                      >
                        <div className="form-group">
                          <div className="input-effect">
                            <input
                              className="form-control"
                              placeholder="Address Line"
                              name="employerAddress"
                              id="employerAddress"
                              type="text"
                              onChange={handleChange}
                              value={formData.employerAddress}
                            />
                            <label htmlFor="employerAddress">
                              {"Address Line"}
                            </label>
                            <em className="input-icon"></em>
                            <span className="text-danger ">
                              {errors?.employerAddress}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-8">
                  <div class="sbli-main-question mb-3">
                    <div class="sbli-question-container">
                      <div
                        className={"sbli-parent-question  mb-2 question_"}
                        id="sbli_question_container"
                      >
                        <div className="form-group">
                          <div className="input-effect">
                            <input
                              className="form-control"
                              placeholder="City"
                              name="employerCity"
                              id="employerCity"
                              type="text"
                              onChange={handleChange}
                              value={formData.employerCity}
                            />
                            <label htmlFor="employerCity">{"City"}</label>
                            <em className="input-icon"></em>
                            <span className="text-danger ">
                              {errors?.employerCity}
                            </span>
                          </div>
                        </div>

                        <span className="text-danger">
                          {errors?.employerCity}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-8">
                  <div class="sbli-main-question mb-3">
                    <div class="sbli-question-container">
                      <div
                        className={"sbli-parent-question  mb-2 question_"}
                        id="sbli_question_container"
                      >
                        <div className="form-group fixed-floating-label mb-4">
                          <div className="default-select with-border">
                            <Select
                              className={"custom-select-menu"}
                              classNamePrefix={"custom-select"}
                              options={countryList.getCountry}
                              onChange={(value) => {
                                setFormData({
                                  ...formData,
                                  employerCountry: value,
                                });
                              }}
                              // value={countryList.getCountry[0]}
                            />
                            
                            <span className="text-danger ">
                              {errors?.employerCountry}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-8">
                  <div class="sbli-main-question mb-3">
                    <div class="sbli-question-container">
                      <div
                        className={"sbli-parent-question  mb-2 question_"}
                        id="sbli_question_container"
                      >
                        <div className="form-group">
                          <div className="input-effect">
                            <input
                              className="form-control"
                              placeholder="Zipcode"
                              name="employerZipcode"
                              id="employerZipcode"
                              type="text"
                              onChange={handleChange}
                              value={formData.employerZipcode}
                            />
                            <label htmlFor="employerCity">{"Zipcode"}</label>
                            <em className="input-icon"></em>
                            <span className="text-danger ">
                              {errors?.employerZipcode}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-8">
                  <div class="sbli-main-question mb-3">
                    <div class="sbli-question-container">
                      <div
                        className={"sbli-parent-question  mb-2 question_"}
                        id="sbli_question_container"
                      >
                        <div className="form-group">
                          <div className="input-effect">
                            <input
                              className="form-control"
                              placeholder="State"
                              name="employerState"
                              id="employerState"
                              type="text"
                              disabled
                              onChange={handleChange}
                              value={formData.employerState?.label}
                            />
                            <label htmlFor="employerState">{"State"}</label>
                            <em className="input-icon"></em>
                            <span className="text-danger ">
                              {errors?.employerState}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
              </React.Fragment>
            )}
            {formData.selfEmployed === "yes" && (
              <div className="col-md-8">
                <div class="sbli-main-question mb-3">
                  <div class="sbli-question-container">
                    <div
                      className={"sbli-parent-question  mb-2 question_"}
                      id="sbli_question_container"
                    >
                      <h4 className="mb-4 ">What’s your net monthly income?</h4>
                      <div className="form-group">
                        <div className="input-effect">
                          <NumberFormat
                            prefix={"$"}
                            thousandSeparator={true}
                            className="form-control w-100 number-format-amount"
                            inputMode="numeric"
                            name="netMonthlyIncome"
                            id="netMonthlyIncome"
                            placeholder="Net Monthly Income"
                            value={formData.netMonthlyIncome}
                            onValueChange={(e) =>
                              handleChange({
                                target: {
                                  name: "netMonthlyIncome",
                                  value: e.value,
                                },
                              })
                            }
                          />
                          <label htmlFor="netMonthlyIncome"></label>
                          <em className="input-icon"></em>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {formData.selfEmployed === "no" && (
              <div className="col-md-8">
                <div class="sbli-main-question mb-3">
                  <div class="sbli-question-container">
                    <div
                      className={"sbli-parent-question  mb-2 question_"}
                      id="sbli_question_container"
                    >
                      <h4 className="mb-4 ">
                        What’s your gross monthly income?
                      </h4>
                      <div className="form-group">
                        <div className="input-effect">
                          <NumberFormat
                            prefix={"$"}
                            thousandSeparator={true}
                            className="form-control w-100 number-format-amount"
                            inputMode="numeric"
                            name="grossMonthlyIncome"
                            id="grossMonthlyIncome"
                            placeholder="Net Monthly Income"
                            value={formData.grossMonthlyIncome}
                            onValueChange={(e) =>
                              handleChange({
                                target: {
                                  name: "grossMonthlyIncome",
                                  value: e.value,
                                },
                              })
                            }
                          />
                          <label htmlFor="grossMonthlyIncome"></label>
                          <em className="input-icon"></em>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="col-md-8">
              <h4 className="mb-4 ">
                What is the purpose of this insurance?
                <sup className="text-danger">*</sup>{" "}
              </h4>
              <div className="form-group fixed-floating-label mb-4">
                <div className="default-select with-border">
                  <Select
                    className={"custom-select-menu"}
                    classNamePrefix={"custom-select"}
                    options={purposeList.map((purpose) => ({
                      value: purpose,
                      label: purpose,
                    }))}
                    onChange={(value) => {
                      setFormData({ ...formData, purposeOfInsurance: value });
                    }}
                    // value={countryList.getCountry[0]}
                  />

                  <span className="text-danger ">
                    {errors?.purposeOfInsurance}
                  </span>
                </div>
              </div>
              {formData.purposeOfInsurance?.value === "Other" && (
                <div className="form-group">
                  <div className="input-effect">
                    <input
                      className="form-control"
                      placeholder="Enter Purpose"
                      name="purposeOfInsuranceOthers"
                      id="purposeOfInsuranceOthers"
                      type="text"
                      onChange={handleChange}
                      value={formData.purposeOfInsuranceOthers}
                    />
                    <label htmlFor="purposeOfInsuranceOthers">
                      {"Enter Purpose"}
                      <sup className="text-danger">*</sup>
                    </label>
                    <em className="input-icon"></em>
                    <span className="text-danger ">
                      {errors?.purposeOfInsuranceOthers}
                    </span>
                  </div>
                </div>
              )}
            </div>
            <div className="col-md-8">
              <div class="sbli-main-question mb-3">
                <div class="sbli-question-container">
                  <div
                    className={"sbli-parent-question  mb-2 question_"}
                    id="sbli_question_container"
                  >
                    <h4 className="mb-4 ">
                      Are there any agreements in place to assign/sell the
                      policy?<sup className="text-danger">*</sup>
                    </h4>
                    <span className="custom-radio mt-3 d-block">
                      <label
                        className="m-0 mb-3"
                        htmlFor={"abagreementToAssignOrSellPolicy"}
                      >
                        <input
                          type="radio"
                          name={"agreementToAssignOrSellPolicy"}
                          id={"abagreementToAssignOrSellPolicy"}
                          value={"yes"}
                          onChange={handleChange}
                        />
                        <span></span> Yes
                      </label>

                      <label
                        className="m-0"
                        htmlFor={"baagreementToAssignOrSellPolicy"}
                      >
                        <input
                          type="radio"
                          name={"agreementToAssignOrSellPolicy"}
                          id={"baagreementToAssignOrSellPolicy"}
                          value={"no"}
                          onChange={handleChange}
                        />
                        <span></span> No
                      </label>
                    </span>

                    <span className="text-danger">
                      {errors?.agreementToAssignOrSellPolicy}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-8">
              <div class="sbli-main-question mb-3">
                <div class="sbli-question-container">
                  <div
                    className={"sbli-parent-question  mb-2 question_"}
                    id="sbli_question_container"
                  >
                    <h4 className="mb-4 ">
                      Is there any intent to sell the policy after issuance?
                      <sup className="text-danger">*</sup>
                    </h4>
                    <span className="custom-radio mt-3 d-block">
                      <label
                        className="m-0 mb-3"
                        htmlFor={"abintentToSellInsurance"}
                      >
                        <input
                          type="radio"
                          name={"intentToSellInsurance"}
                          id={"abintentToSellInsurance"}
                          value={"yes"}
                          onChange={handleChange}
                        />
                        <span></span> Yes
                      </label>

                      <label
                        className="m-0"
                        htmlFor={"baintentToSellInsurance"}
                      >
                        <input
                          type="radio"
                          name={"intentToSellInsurance"}
                          id={"baintentToSellInsurance"}
                          value={"no"}
                          onChange={handleChange}
                        />
                        <span></span> No
                      </label>
                    </span>

                    <span className="text-danger">
                      {errors?.intentToSellInsurance}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-8">
              <div class="sbli-main-question mb-3">
                <div class="sbli-question-container">
                  <div
                    className={"sbli-parent-question  mb-2 question_"}
                    id="sbli_question_container"
                  >
                    <h4 className="mb-4 ">
                      Has the insured undergone any life expectancy or health
                      exams in conjunction with a life insurance application or
                      settlement option contract?
                      <sup className="text-danger">*</sup>
                    </h4>
                    <span className="custom-radio mt-3 d-block">
                      <label
                        className="m-0 mb-3"
                        htmlFor={"abhadLifeExpectancyOrHealthExams"}
                      >
                        <input
                          type="radio"
                          name={"hadLifeExpectancyOrHealthExams"}
                          id={"abhadLifeExpectancyOrHealthExams"}
                          value={"yes"}
                          onChange={handleChange}
                        />
                        <span></span> Yes
                      </label>

                      <label
                        className="m-0"
                        htmlFor={"bahadLifeExpectancyOrHealthExams"}
                      >
                        <input
                          type="radio"
                          name={"hadLifeExpectancyOrHealthExams"}
                          id={"bahadLifeExpectancyOrHealthExams"}
                          value={"no"}
                          onChange={handleChange}
                        />
                        <span></span> No
                      </label>
                    </span>

                    <span className="text-danger">
                      {errors?.hadLifeExpectancyOrHealthExams}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="w-100 mb-4 mt-2 mb-5 text-center">
            <button
              className="btn btn-default py-3 px-5 mb-5"
              type="button"
              onClick={saveNext}
              disabled={isLoading}
            >
              {isLoading ? "Processing..." : "Next"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  underwriting: state.underwriting.underwriting,
  consumer: state.consumer?.consumerdetails,
  applicationCarrierInfo: state.underwriting.applicationCarrierInfo,
});

export default connect(mapStateToProps, { commonApiCall })(
  withRouter(AssurityUserDetail)
);

// let getFinancialYear = [];

// getCurrentFinancialYear = () =>{
//   const date = new Date();
//   let intialMonth = 4;
//   const initialYear = date.getFullYear();
//   for (let index = inti; index < 12; index++) {
//     const element = array[index];

//   }
// }
