import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { Animated } from "react-animated-css";
import NumberFormat from "react-number-format";
import Select from "react-select";
import moment from "moment";
import {
  commonApiCall,
  questionaries,
  getJwt,
  UNDERWRITING_DETAILS,
  FACT_FINDER_QUESTION,
  factFinderQuestion,
} from "../../../redux/actions/index";
import {
  setProcessStep,
  UnderWritingRoutes,
  showErrorToast,
} from "../../../utils/index";
import { API_URL_MUNICH, API_URL_UNDERWRITING } from "../../../config/configs";

const ManualForm = ({
  commonApiCall,
  underwriting,
  question_answers,
  consumer,
  questionaries,
  factFinderQuestionList,
  t,
  ...props
}) => {
  const { applicationId, applicationType, goalType, policyType } = underwriting;
  const [formData] = useState({
    applicationId: applicationId ? applicationId : "",
    applicationType: applicationType ? applicationType : "Web",
    goalType: goalType ? goalType : "",
    openOption: false,
    openOption1: false,
    openOption2: false,
    openOption3: false,
    openOption4: false,
    answers: [
      {
        option2: "",
        option1: "",
        radio: null,
        option3: new Date(),
      },
      {
        option1: "",
        option4: "",
        option2: "",
        radio: null,
        option3: "",
      },
      {
        option1: "",
        radio: null,
      },
      {
        option2: "",
        radio: null,
        option3: "",
        option1: "",
      },
    ],
  });

  const [isProcessing, setIsProcessing] = useState(false);

  const [factFinderQuestions, setFactFinderQuestions] = useState({
    applicationId: applicationId ? applicationId : "",
    applicationType: applicationType ? applicationType : "Web",
    data: [
      {
        question: "have_you_used_tobacco_last_three_year",
        answer: "",
        options: [
          {
            question: "tobacco_type_of_product",
            answer: "",
          },
          {
            question: "tobacco_frequency",
            answer: "",
          },
          {
            question: "tobacco_quantity",
            answer: "",
          },
          {
            question: "tobacco_time_last_used",
            answer: "",
          },
          {
            question: "tobacco_unit_type",
            answer: "",
          },
        ],
      },
      {
        question: "are_you_prescription_drugs_last_ten_year",
        answer: "",
        options: [
          {
            question: "prescription_drugs_type",
            answer: "",
          },
          {
            question: "prescription_drugs_frequency",
            answer: "",
          },
          {
            question: "prescription_drugs_quantity",
            answer: "",
          },
          {
            question: "prescription_drugs_time_last_used",
            answer: "",
          },
          {
            question: "prescription_drugs_unit_type",
            answer: "",
          },
        ],
      },
      {
        question: "do_you_participate_any_activities",
        answer: "",
        options: [],
      },
      {
        question: "have_you_travelled_outside_us_last_two_year",
        answer: "",
        options: [],
      },
    ],
  });
  useEffect(() => window.scrollTo(0, 0), []);

  const [error, setError] = useState({
    errorData: ["", "", "", "", "", "", "", "", "", ""],
  });

  useEffect(() => {
    async function doSubmit() {
      const { applicationId } = formData;
      if (applicationId !== "") {
        const isAuthorized = getJwt() ? true : false;
        var requestParams = {
          applicationId: applicationId,
        };
        let response = await commonApiCall(
          API_URL_MUNICH + `getApplicationQuestionnaire`,
          "post",
          requestParams,
          "",
          isAuthorized
        );
        if (response && response.status.code === 200) {
          setFactFinderQuestions({
            ...factFinderQuestions,
            data: response.data.data,
          });
        }
      }
    }
    doSubmit();
  }, []);

  const typeOfProduct = [
    { value: "Cigarettes", label: "Cigarettes" },
    { value: "E-cigarettes", label: "E-cigarettes" },
    { value: "Cigars", label: "Cigars" },
    { value: "Pipe", label: "Pipe" },
    { value: "Hookah", label: "Hookah" },
    { value: "Others", label: "Others" },
  ];

  const Frequency = [
    { value: "Day", label: "Day" },
    { value: "Month", label: "Month" },
    { value: "Year", label: "Year" },
  ];
  const Quantity = [
    { value: "0", label: "0" },
    { value: "1", label: "1" },
    { value: "2", label: "2" },
    { value: "3", label: "3" },
    { value: "4", label: "4" },
    { value: "5", label: "5" },
    { value: "6", label: "6" },
    { value: "7", label: "7" },
    { value: "8", label: "8" },
    { value: "9", label: "9" },
    { value: "10", label: "10" },
    { value: "10 +", label: "10 +" },
  ];

  const typeOfDrugs = [
    {
      value: "Opium based pain medication",
      label: "Opium based pain medication",
    },
    { value: "Barbiturates", label: "Barbiturates" },
    { value: "Sedatives/ tranquilizers", label: "Sedatives/ tranquilizers" },
    { value: "Amphetamines/ stimulants", label: "Amphetamines/ stimulants" },
    { value: "Others", label: "Others" },
  ];

  const validateFields = () => {
    if (!getErrorCheck()) {
      return getErrorCheck();
    } else {
      const result = data.filter((word) => word.answer === "Yes");
      if (result.length === 0) {
        return true;
      } else {
        if (data[0].answer === "Yes") {
          if (data[0].options[0].answer) {
            error.errorData[0] = "";
          } else {
            error.errorData[0] = t("fact_finder.please_select_type_of_product");
          }

          if (data[0].options[1].answer) {
            error.errorData[1] = "";
          } else {
            error.errorData[1] = t("fact_finder.please_select_frequency");
          }
          if (data[0].options[2].answer) {
            error.errorData[2] = "";
          } else {
            error.errorData[2] = t("fact_finder.please_select_quantity");
          }
          if (data[0].options[3].answer) {
            const currentYear = moment(new Date()).format("YY");
            const currentMonth = moment(new Date()).format("MM");
            const lastTimeUsed = data[0].options[3].answer.split("/");
            if (lastTimeUsed[1] > currentYear)
              error.errorData[3] = t(
                "fact_finder.please_enter_valid_time_last_used"
              );
            else if (
              lastTimeUsed[1] === currentYear &&
              lastTimeUsed[0] > currentMonth
            )
              error.errorData[3] = t(
                "fact_finder.please_enter_valid_time_last_used"
              );
            else error.errorData[3] = "";
          } else {
            error.errorData[3] = t("fact_finder.please_enter_time_last_used");
          }
        }

        if (data[1].answer === "Yes") {
          if (data[1].options[0].answer) {
            error.errorData[4] = "";
          } else {
            error.errorData[4] = t(
              "fact_finder.please_select_type_of_medication"
            );
          }

          if (data[1].options[1].answer) {
            error.errorData[5] = "";
          } else {
            error.errorData[5] = t("fact_finder.please_select_frequency");
          }
          if (data[1].options[2].answer) {
            error.errorData[6] = "";
          } else {
            error.errorData[6] = t("fact_finder.please_select_quantity");
          }
          if (data[1].options[3].answer) {
            const currentYear = moment(new Date()).format("YY");
            const currentMonth = moment(new Date()).format("MM");
            const lastTimeUsedFromTo = data[1].options[3].answer.split("-");

            lastTimeUsedFromTo.forEach((lastTimeDateRange) => {
              const lastTimeUsed = lastTimeDateRange.trim().split("/");
              if (lastTimeUsed[1] > currentYear) {
                error.errorData[7] = t(
                  "fact_finder.please_enter_valid_time_last_used"
                );
                return false;
              } else if (
                lastTimeUsed[1] === currentYear &&
                lastTimeUsed[0] > currentMonth
              ) {
                error.errorData[7] = t(
                  "fact_finder.please_enter_valid_time_last_used"
                );
                return false;
              } else error.errorData[7] = "";
            });
          } else {
            error.errorData[7] = t("fact_finder.please_enter_time_last_used");
          }
        }
        setError({
          ...error,
          error,
        });
      }
      return getErrorCheck();
    }
  };

  const getErrorCheck = () => {
    let result = error.errorData.filter((word) => word !== "");
    if (result.length === 0) {
      return true;
    } else {
      return false;
    }
  };

  const continueNext = async () => {
    let { applicationId, applicationType } = formData;
    const isAuthorized = getJwt() ? true : false;
    let { data } = factFinderQuestions;
    const result = data.filter((word) => word.answer === "");
    if (result.length === 0) {
      if (validateFields()) {
        const splitpath = window.location.pathname.split("/");
        const request = {
          resumeProcess: setProcessStep(splitpath[1], policyType, goalType),
          applicationType: applicationType,
          applicationId: applicationId,
          data: data,
          clientUserId: consumer && consumer.id ? consumer.id : "",
        };
        setIsProcessing(true);
        const apiEndPoint = API_URL_MUNICH + "saveApplicationQuestionnaire";
        const response = await commonApiCall(
          apiEndPoint,
          "post",
          request,
          FACT_FINDER_QUESTION,
          isAuthorized
        );
        if (response.status && response.status.code === 200) {
          const request_params = {
            resumeProcess: setProcessStep(splitpath[1], policyType, goalType),
            applicationType: applicationType,
            applicationId: applicationId,
            goalType: goalType,
          };
          const apiEndPoint_underwriting =
            API_URL_UNDERWRITING + "saveUnderWriting";
          await commonApiCall(
            apiEndPoint_underwriting,
            "post",
            request_params,
            UNDERWRITING_DETAILS,
            isAuthorized
          );

          const timer = setTimeout(() => {
            props.history.push(UnderWritingRoutes.FACT_FINDER_2);
          }, 1000);
          return () => clearTimeout(timer);
        } else {
          setIsProcessing(true);
        }
      }
    } else {
      showErrorToast(t("validation_message.answers"));
    }
  };

  const handleChange = (value, index, valIndex) => {
    data[index].options[valIndex].answer = value;
    if (index === 0 && valIndex !== 4) {
      error.errorData[valIndex] = "";
    }
    if (index === 1 && valIndex !== 4) {
      error.errorData[valIndex + 4] = "";
    }

    if (index === 0 && valIndex === 4) {
      error.errorData[8] = "";
    }
    if (index === 1 && valIndex === 4) {
      error.errorData[9] = "";
    }

    setError({
      ...error,
      error,
    });
    setFactFinderQuestions({
      ...factFinderQuestions,
      data,
    });
  };
  let { data } = factFinderQuestions;
  return (
    <React.Fragment>
      <div className="register-steps">
        <div className="row justify-content-center">
          <div className="col-lg-10">
            {/*Begin: Page Title */}
            <div className="title-header">
              <h2>
                {t("fact_finder.tell_us_more_about_your_health_lifestyle")}
              </h2>
            </div>
            {/*End: Page Title */}
            {/*Begin: About your health Form Wrapper */}
            <div className="register-steps-wrapper">
              {/*Begin: Animate.css Element */}
              <Animated
                animationIn="fadeInRight"
                animationOut="fadeOutLeft"
                animationInDuration={800}
                animationOutDuration={800}
                isVisible={true}
              >
                <div className="form-wrapper">
                  <form>
                    <div className="register-form-block">
                      <h3 className="form-text card-text mt-0 mb-3 text-center">
                        {t(
                          "fact_finder.have_you_used_any_form_of_tobacco_or_nicotine_in_the_last_3_years"
                        )}
                      </h3>
                      {/*Begin: Form Group */}
                      <div className="form-block radio-block">
                        <div className="form-group">
                          <div className="custom-radio">
                            <label className="m-0" htmlFor="yes">
                              <input
                                type="radio"
                                id="yes"
                                name="tobacco"
                                checked={
                                  data[0].answer === "Yes" ? true : false
                                }
                                onChange={() => {
                                  data[0].answer = "Yes";
                                  setFactFinderQuestions({
                                    ...factFinderQuestions,
                                    data,
                                  });
                                }}
                              />
                              <span></span> {t("fact_finder.yes")}
                            </label>
                          </div>
                        </div>

                        <div className="form-group">
                          <div className="custom-radio">
                            <label className="m-0" htmlFor="no">
                              <input
                                type="radio"
                                id="no"
                                name="tobacco"
                                checked={data[0].answer === "No" ? true : false}
                                onChange={() => {
                                  data[0].answer = "No";
                                  data[0].options[0].answer = "";
                                  data[0].options[1].answer = "";
                                  data[0].options[2].answer = "";
                                  data[0].options[3].answer = "";
                                  data[0].options[4].answer = "";
                                  error.errorData[0] = "";
                                  error.errorData[1] = "";
                                  error.errorData[2] = "";
                                  error.errorData[3] = "";
                                  error.errorData[8] = "";
                                  setFactFinderQuestions({
                                    ...factFinderQuestions,
                                    data,
                                  });
                                  setError({
                                    ...error,
                                    error,
                                  });
                                }}
                              />
                              <span></span> {t("fact_finder.no")}
                            </label>
                          </div>
                        </div>
                      </div>
                      {/*End: Form Group */}
                      {data[0].answer === "Yes" ? (
                        <div className="form-block-questions register-step3">
                          <div className="questions-wrapper">
                            <div className="form-group fixed-floating-label">
                              <div className="default-select with-border">
                                <Select
                                  value={data[0].options[0].answer}
                                  onChange={(val) => handleChange(val, 0, 0)}
                                  options={typeOfProduct}
                                  className={"custom-select-menu"}
                                  classNamePrefix={"custom-select"}
                                  placeholder={t("fact_finder.type_of_product")}
                                />
                              </div>
                              <span className="fixed-label">
                                {t("fact_finder.type_of_product")}
                              </span>
                              <span className="text-danger ">
                                {error.errorData[0]}
                              </span>
                            </div>
                            <div className="form-group fixed-floating-label">
                              <div className="default-select with-border">
                                <Select
                                  value={data[0].options[1].answer}
                                  onChange={(val) => handleChange(val, 0, 1)}
                                  options={Frequency}
                                  className={"custom-select-menu"}
                                  classNamePrefix={"custom-select"}
                                  placeholder={t("fact_finder.frequency")}
                                />
                              </div>
                              <span className="fixed-label">
                                {t("fact_finder.frequency")}
                              </span>
                              <span className="text-danger ">
                                {error.errorData[1]}
                              </span>
                            </div>
                            <div className="form-group fixed-floating-label">
                              <div className="default-select with-border">
                                <Select
                                  value={data[0].options[2].answer}
                                  onChange={(val) => handleChange(val, 0, 2)}
                                  options={Quantity}
                                  className={"custom-select-menu"}
                                  classNamePrefix={"custom-select"}
                                  placeholder={t("fact_finder.quantity")}
                                />
                              </div>
                              <span className="fixed-label">
                                {t("fact_finder.quantity")}
                              </span>
                              <span className="text-danger ">
                                {error.errorData[2]}
                              </span>
                            </div>
                            <div className="form-group">
                              <div className="input-effect with-icon custom-datepicker">
                                <NumberFormat
                                  value={data[0].options[3].answer}
                                  format="##/##"
                                  className="form-control"
                                  name="face-amount"
                                  inputMode="numeric"
                                  placeholder="Face amount"
                                  onValueChange={(e) => {
                                    data[0].options[3].answer =
                                      e.formattedValue;
                                    let string1 = e.formattedValue.split("/");
                                    let year = moment().format("YY");
                                    setFactFinderQuestions({
                                      ...factFinderQuestions,
                                      data,
                                    });
                                    error.errorData[3] = "";
                                    if (
                                      (string1.length > 1 &&
                                        (Number(string1[0]) > 12 ||
                                          Number(string1[0]) === 0)) ||
                                      (string1.length > 1 &&
                                        Number(string1[1]) > Number(year)) ||
                                      Number(string1[1]) === 0
                                    )
                                      error.errorData[3] =
                                        "Please enter valid Month and Year";
                                    else error.errorData[3] = "";
                                    setError({
                                      ...error,
                                      error,
                                    });
                                  }}
                                />
                                <label htmlFor="time-last">
                                  {t("fact_finder.time_last_used_month_year")}
                                </label>
                                <span className="text-danger ">
                                  {error.errorData[3]}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </form>
                </div>
              </Animated>
              {/*End: Animate.css Element */}
            </div>
            {/*End: About your health Form Wrapper */}
          </div>
        </div>
      </div>
      <div className="register-steps register-step3">
        <div className="row justify-content-center">
          <div className="col-lg-10">
            {/*End: Page Title */}
            {/*Begin: About your health Form Wrapper */}
            <div className="register-steps-wrapper">
              {/*Begin: Animate.css Element */}
              <Animated
                animationIn="fadeInRight"
                animationOut="fadeOutLeft"
                animationInDuration={800}
                animationOutDuration={800}
                isVisible={true}
              >
                <div className="form-wrapper">
                  <form>
                    <div className="register-form-block">
                      <h3 className="form-text card-text mt-0 mb-3 text-center">
                        {t(
                          "fact_finder.are_you_currently_using_any_prescription_drugs_medication_in_the_last_10_years"
                        )}
                      </h3>
                      {/*Begin: Form Group */}
                      <div className="form-block radio-block">
                        <div className="form-group">
                          <div className="custom-radio">
                            <label className="m-0" htmlFor="drugs">
                              <input
                                type="radio"
                                id="drugs"
                                name="drugs"
                                checked={
                                  data[1].answer === "Yes" ? true : false
                                }
                                onChange={() => {
                                  data[1].answer = "Yes";
                                  setFactFinderQuestions({
                                    ...factFinderQuestions,
                                    data,
                                  });
                                }}
                              />
                              <span></span> {t("fact_finder.yes")}
                            </label>
                          </div>
                        </div>

                        <div className="form-group">
                          <div className="custom-radio">
                            <label className="m-0" htmlFor="nodrugs">
                              <input
                                type="radio"
                                id="nodrugs"
                                name="drugs"
                                checked={data[1].answer === "No" ? true : false}
                                onChange={() => {
                                  data[1].answer = "No";
                                  data[1].options[0].answer = "";
                                  data[1].options[1].answer = "";
                                  data[1].options[2].answer = "";
                                  data[1].options[3].answer = "";
                                  error.errorData[4] = "";
                                  error.errorData[5] = "";
                                  error.errorData[6] = "";
                                  error.errorData[7] = "";
                                  setFactFinderQuestions({
                                    ...factFinderQuestions,
                                    data,
                                  });
                                  setError({
                                    ...error,
                                    error,
                                  });
                                }}
                              />
                              <span></span> {t("fact_finder.no")}
                            </label>
                          </div>
                        </div>
                      </div>
                      {/*End: Form Group */}
                      {data[1].answer === "Yes" ? (
                        <div className="form-block-questions register-step3">
                          <div className="questions-wrapper">
                            <div className="form-group fixed-floating-label">
                              <div className="default-select with-border">
                                <Select
                                  value={data[1].options[0].answer}
                                  onChange={(val) => handleChange(val, 1, 0)}
                                  options={typeOfDrugs}
                                  className={"custom-select-menu"}
                                  classNamePrefix={"custom-select"}
                                  placeholder={t(
                                    "fact_finder.type_of_medication"
                                  )}
                                />
                              </div>
                              <span className="fixed-label">
                                {t("fact_finder.type_of_medication")}
                              </span>
                              <span className="text-danger ">
                                {error.errorData[4]}
                              </span>
                            </div>
                            <div className="form-group fixed-floating-label">
                              <div className="default-select with-border">
                                <Select
                                  value={data[1].options[1].answer}
                                  onChange={(val) => handleChange(val, 1, 1)}
                                  options={Frequency}
                                  className={"custom-select-menu"}
                                  classNamePrefix={"custom-select"}
                                  placeholder={t("fact_finder.frequency")}
                                />
                              </div>
                              <span className="fixed-label">
                                {t("fact_finder.frequency")}
                              </span>
                              <span className="text-danger ">
                                {error.errorData[5]}
                              </span>
                            </div>
                            {/* <div className="form-group fixed-floating-label">
                              <div className="default-select with-border">
                                <Select
                                  value={data[1].options[4].answer}
                                  onChange={(val) => handleChange(val, 1, 4)}
                                  options={unitType}
                                  className={"custom-select-menu"}
                                  classNamePrefix={"custom-select"}
                                  placeholder="Unit Type"
                                />
                              </div>
                              <span className="fixed-label">Unit Type</span>
                              <span className="text-danger ">
                                {error.errorData[9]}
                              </span>
                            </div> */}
                            <div className="form-group fixed-floating-label">
                              <div className="default-select with-border">
                                <Select
                                  value={data[1].options[2].answer}
                                  onChange={(val) => handleChange(val, 1, 2)}
                                  options={Quantity}
                                  className={"custom-select-menu"}
                                  classNamePrefix={"custom-select"}
                                  placeholder={t("fact_finder.quantity")}
                                />
                              </div>
                              <span className="fixed-label">
                                {t("fact_finder.quantity")}
                              </span>
                              <span className="text-danger ">
                                {error.errorData[6]}
                              </span>
                            </div>
                            <div className="form-group">
                              <div className="input-effect with-icon">
                                <NumberFormat
                                  value={data[1].options[3].answer}
                                  format="##/## - ##/##"
                                  className="form-control"
                                  name="face-amount"
                                  inputMode="numeric"
                                  placeholder="Face amount"
                                  onValueChange={(e) => {
                                    let string = e.formattedValue.split(" - ");
                                    let string1 = string[0].split("/");
                                    let string2 = [];
                                    let year = moment().format("YY");

                                    if (string.length > 1)
                                      string2 = string[1].split("/");

                                    data[1].options[3].answer =
                                      e.formattedValue;
                                    setFactFinderQuestions({
                                      ...factFinderQuestions,
                                      data,
                                    });
                                    error.errorData[7] = "";
                                    if (
                                      (string1.length > 1 &&
                                        (Number(string1[0]) > 12 ||
                                          Number(string1[0]) === 0)) ||
                                      (string2.length > 1 &&
                                        (Number(string2[0]) > 12 ||
                                          Number(string2[0]) === 0)) ||
                                      (string1.length > 1 &&
                                        (Number(string1[1]) > Number(year) ||
                                          Number(string1[1]) === 0)) ||
                                      (string2.length > 1 &&
                                        (Number(string2[1]) > Number(year) ||
                                          Number(string2[1]) === 0))
                                    )
                                      error.errorData[7] =
                                        "Please enter valid Month and Year";
                                    else if (
                                      (string1.length > 1 &&
                                        string2.length > 1 &&
                                        Number(string2[1]) ===
                                          Number(string1[1]) &&
                                        Number(string1[0]) >
                                          Number(string2[0])) ||
                                      Number(string1[1]) > Number(string2[1])
                                    )
                                      error.errorData[7] =
                                        "Please enter valid Month and Year";
                                    else error.errorData[7] = "";
                                    setError({
                                      ...error,
                                      error,
                                    });
                                  }}
                                />
                                <label htmlFor="Time last used">
                                  {t("fact_finder.time_last_used_from_to")}
                                </label>
                                <span className="text-danger ">
                                  {error.errorData[7]}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </form>
                </div>
              </Animated>
              {/*End: Animate.css Element */}
            </div>
            {/*End: About your health Form Wrapper */}
          </div>
        </div>
      </div>
      <div className="register-steps register-step3">
        <div className="row justify-content-center">
          <div className="col-lg-10">
            {/*End: Page Title */}
            {/*Begin: About your health Form Wrapper */}
            <div className="register-steps-wrapper">
              {/*Begin: Animate.css Element */}
              <Animated
                animationIn="fadeInRight"
                animationOut="fadeOutLeft"
                animationInDuration={800}
                animationOutDuration={800}
                isVisible={true}
              >
                <div className="form-wrapper">
                  <form>
                    <div className="register-form-block">
                      <h3 className="form-text card-text mt-0 mb-3 text-center">
                        {t(
                          "fact_finder.do_you_participate_in_activities_such_as_flying_as_a_pilot_parachute_jumping_scuba_diving_auto_motorboat_motorcycle_racing_hang_gliding_or_mountain_climbing"
                        )}
                      </h3>
                      {/*Begin: Form Group */}
                      <div className="form-block radio-block">
                        <div className="form-group">
                          <div className="custom-radio">
                            <label className="m-0" htmlFor="Avocation">
                              <input
                                type="radio"
                                id="Avocation"
                                name="Avocation"
                                checked={
                                  data[2].answer === "Yes" ? true : false
                                }
                                onChange={() => {
                                  data[2].answer = "Yes";
                                  setFactFinderQuestions({
                                    ...factFinderQuestions,
                                    data,
                                  });
                                }}
                              />
                              <span></span> {t("fact_finder.yes")}
                            </label>
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="custom-radio">
                            <label className="m-0" htmlFor="noAvocation">
                              <input
                                type="radio"
                                id="noAvocation"
                                name="Avocation"
                                checked={data[2].answer === "No" ? true : false}
                                onChange={() => {
                                  data[2].answer = "No";
                                  setFactFinderQuestions({
                                    ...factFinderQuestions,
                                    data,
                                  });
                                }}
                              />
                              <span></span> {t("fact_finder.no")}
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </Animated>
              {/*End: Animate.css Element */}
            </div>
            {/*End: About your health Form Wrapper */}
          </div>
        </div>
      </div>
      <div className="register-steps register-step3">
        <div className="row justify-content-center">
          <div className="col-lg-10">
            {/*Begin: About your health Form Wrapper */}
            <div className="register-steps-wrapper">
              {/*Begin: Animate.css Element */}
              <Animated
                animationIn="fadeInRight"
                animationOut="fadeOutLeft"
                animationInDuration={800}
                animationOutDuration={800}
                isVisible={true}
              >
                <div className="form-wrapper">
                  <form>
                    <div className="register-form-block">
                      <h3 className="form-text card-text mt-0 mb-3 text-center">
                        {t(
                          "fact_finder.have_you_travelled_frequently_lived_or_worked_outside_of_the_u_s_in_the_past_2_years"
                        )}
                      </h3>
                      {/*Begin: Form Group */}
                      <div className="form-block radio-block">
                        <div className="form-group">
                          <div className="custom-radio">
                            <label className="m-0" htmlFor="travelled">
                              <input
                                type="radio"
                                id="travelled"
                                name="travelled"
                                checked={
                                  data[3].answer === "Yes" ? true : false
                                }
                                onChange={() => {
                                  data[3].answer = "Yes";
                                  setFactFinderQuestions({
                                    ...factFinderQuestions,
                                    data,
                                  });
                                }}
                              />
                              <span></span> {t("fact_finder.yes")}
                            </label>
                          </div>
                        </div>

                        <div className="form-group">
                          <div className="custom-radio">
                            <label className="m-0" htmlFor="notravelled">
                              <input
                                type="radio"
                                id="notravelled"
                                name="travelled"
                                checked={data[3].answer === "No" ? true : false}
                                onClick={() => {
                                  data[3].answer = "No";
                                  setFactFinderQuestions({
                                    ...factFinderQuestions,
                                    data,
                                  });
                                }}
                              />
                              <span></span> {t("fact_finder.no")}
                            </label>
                          </div>
                        </div>
                      </div>
                      {/*End: Form Group */}
                    </div>
                  </form>
                </div>
              </Animated>
              {/*End: Animate.css Element */}
            </div>
            {/*End: About your health Form Wrapper */}
          </div>
        </div>
        <div className="text-center mt-5">
          {isProcessing ? (
            <span className="btn btn-primary" title="Settings">
              {t("fact_finder.processing")}
            </span>
          ) : (
            <span
              className="btn btn-primary"
              title="Settings"
              onClick={continueNext}
            >
              {" "}
              {t("next")}{" "}
            </span>
          )}
        </div>
        <div className="text-center mt-5">
          <Link
            to={UnderWritingRoutes.MANUAL_FORM}
            className="btn btn-link"
            title="back"
          >
            {" "}
            {t("back")}{" "}
          </Link>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  underwriting: state.underwriting.underwriting,
  consumer: state.consumer.consumerdetails,
  question_answers: state.underwriting,
  factFinderQuestionList: state.underwriting.factFinderQuestion,
});

export default connect(mapStateToProps, {
  commonApiCall,
  questionaries,
  factFinderQuestion,
})(withRouter(ManualForm));
