import React from "react";
import { isEmpty } from "lodash";
import { Form } from "react-bootstrap";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {
    showSuccessToast,
} from "../utils";
import queryString from "query-string";

const UnderwritingApplicationFooter = ({ t, underwriting, showApplicationId, ...props }) => {
    return (
        <Form>
            {showApplicationId && isEmpty(queryString.parse(props?.location?.search)) &&
                underwriting?.applicationId ? (
                <h4>
                    {t("header.application_iD")} :{" "}
                    <span className="text-primary">
                        {underwriting.applicationId}
                    </span>
                    <span
                        className="copy-icon ml-2"
                        title={t("header.copy")}
                    >
                        <CopyToClipboard
                            text={underwriting.applicationId}
                            onCopy={() =>
                                showSuccessToast(
                                    t("header.application_iD_copied!")
                                )
                            }
                        >
                            <svg
                                width="19px"
                                height="22px"
                                viewBox="0 0 19 22"
                                version="1.1"
                            >
                                <g
                                    stroke="none"
                                    strokeWidth="1"
                                    fill="none"
                                    fillRule="evenodd"
                                >
                                    <g transform="translate(-2.000000, -1.000000)">
                                        <polygon points="0 0 24 0 24 24 0 24"></polygon>
                                        <path
                                            d="M16,1 L4,1 C2.9,1 2,1.9 2,3 L2,17 L4,17 L4,3 L16,3 L16,1 Z M15,5 L8,5 C6.9,5 6.01,5.9 6.01,7 L6,21 C6,22.1 6.89,23 7.99,23 L19,23 C20.1,23 21,22.1 21,21 L21,11 L15,5 Z M8,21 L8,7 L14,7 L14,12 L19,12 L19,21 L8,21 Z"
                                            fill="#839C9F"
                                            fillRule="nonzero"
                                        ></path>
                                    </g>
                                </g>
                            </svg>
                        </CopyToClipboard>
                    </span>
                </h4>
            ) : null}
        </Form>
    );
};

export default UnderwritingApplicationFooter;
