import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

export const ChatLoader = () => {
  return (
    <SkeletonTheme>
      <div className="mb-2">
        <Skeleton count={1} height={46} width={220} />
      </div>
      <div className="mb-2 text-right">
        <Skeleton count={1} height={46} width={240} />
      </div>
      <div className="mb-2">
        <Skeleton count={1} height={46} width={240} />
      </div>
      <div className="mb-2 text-right">
        <Skeleton count={1} height={46} width={240} />
      </div>
      <div className="mb-2">
        <Skeleton count={1} height={46} width={220} />
      </div>
      <div className="mb-2 text-right">
        <Skeleton count={1} height={46} width={240} />
      </div>
    </SkeletonTheme>
  );
};
