import React, { useState, useEffect } from "react";
import StarRatingComponent from "react-star-rating-component";
import { Button } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { decryptme, showErrorToast, showSuccessToast } from "../../utils/index";
import { commonApiCall } from "../../redux/actions/index";
import { API_URL_CHATSERVICE } from "../../config/configs";

const ChatLeftbar = ({
  conversationDetails,
  chatStatus,
  channelId,
  consumer,
  commonApiCall,
  t,
  underwriting,
  ...props
}) => {
  const errorImageReplace = (id) => {
    document.getElementById(id).src = "/assets/images/user-dp-demo.jpg";
  };

  const senderDetails = conversationDetails?.agentResponse
    ? conversationDetails.agentResponse
    : conversationDetails?.senderDetail;
  const senderFirstName = conversationDetails?.agentResponse?.firstName
    ? decryptme(conversationDetails.agentResponse?.firstName)
    : senderDetails?.firstName;
  const senderLastName = conversationDetails?.agentResponse?.lastName
    ? decryptme(conversationDetails.agentResponse?.lastName)
    : senderDetails?.lastName;

  const agentUserId = conversationDetails?.agentResponse?.agentId
    ? conversationDetails.agentResponse?.agentId
    : conversationDetails?.agentUserId;

  const [feedBack, setFeedback] = useState({
    rating: 0,
    comment: "",
    isReviewed: false,
    errors: {},
  });

  const validateFeedback = () => {
    const { comment } = feedBack;
    let errors = {},
      isValid = true;
    if (!comment || comment.trim() === "") {
      isValid = false;
      errors["comment"] = t("validation_message.COMMENT");
    }

    setFeedback({ ...feedBack, errors: errors });
    return isValid;
  };

  const submitFeedback = async () => {
    try {
      const { comment } = feedBack;
      if (validateFeedback()) {
        const request = {
          rating: rating,
          comment: comment,
          agentUserId: agentUserId,
          clientUserId:
            consumer && consumer.id ? consumer.id : underwriting.clientUserId,
          channelId: channelId,
          applicationCarrierId: conversationDetails.applicationCarrierId,
        };
        const apiEndPoint = API_URL_CHATSERVICE + "agentRating";
        const response = await commonApiCall(apiEndPoint, "post", request, "");
        if (response.status && response.status.code === 200) {
          setFeedback({ ...feedBack, isReviewed: true });
          showSuccessToast(t("validation_message.REVIEW_SUCCESS"));
          return true;
        }
      }
    } catch (err) {
      showErrorToast(t("validation_message.SOMETHING_WENT_WRONG"));
    }
  };

  const getReviewRating = async () => {
    try {
      const apiEndPoint = API_URL_CHATSERVICE + "agentRating/" + channelId;
      const response = await commonApiCall(apiEndPoint, "get", {}, "", true);
      if (response.status && response.status.code === 200 && response.data) {
        setFeedback({
          ...feedBack,
          rating: response.data.rating,
          comment: response.data.comment,
          isReviewed: true,
        });
        return true;
      }
    } catch (err) {
      showErrorToast(t("validation_message.SOMETHING_WENT_WRONG"));
    }
  };

  const handleChange = (e) => {
    try {
      if (e.target.value) {
        setFeedback({
          ...feedBack,
          [e.target.name]: e.target.value,
          errors: Object.assign(feedBack.errors, { [e.target.name]: "" }),
        });
      } else
        setFeedback({
          ...feedBack,
          [e.target.name]: "",
        });
    } catch (err) {
      showErrorToast(t("validation_message.SOMETHING_WENT_WRONG"));
    }
  };

  useEffect(() => {
    if (channelId) getReviewRating();
  }, [channelId]);

  const { errors, comment, rating, isReviewed } = feedBack;
  return (
    <div className="col-lg-3">
      <div className="sidebar">
        <div className="sidebar-profile text-center white-box2">
          <div className="sidebar-profile-img">
            <img
              src={
                senderDetails && senderDetails.profilePic
                  ? senderDetails.profilePic
                  : "/assets/images/user-dp-demo.jpg"
              }
              alt="agentProfile"
              title="profile"
              id="chat-img-user"
              onError={(e) => errorImageReplace("chat-img-user")}
            />
            {chatStatus === "ON" ? (
              <span className="status-online"></span>
            ) : (
              <span className="status-offline"></span>
            )}
          </div>
          <h3>{senderFirstName + " " + senderLastName}</h3>
        </div>
      </div>

      <div className="chat-notification-text">
        <h5>
          {t(
            "private_chat.hey_your_agent_will_validate_your_information_to_provide_you_with_an_e_app_link_to_apply_resume_your_agent_live_chat_anytime_by_logging_back_in"
          )}
        </h5>
      </div>
      <div className="rating-review mt-3">
        <div className="card-outer">
          <h4>{t("private_chat.review_and_ratings")}</h4>
          <div className="rating-stars-wrapper">
            <div>
              {isReviewed ? (
                <StarRatingComponent
                  name="rating"
                  starCount={5}
                  value={rating}
                  editing={false}
                />
              ) : (
                <StarRatingComponent
                  name="rating"
                  starCount={5}
                  value={rating}
                  onStarClick={(e) =>
                    handleChange({
                      target: { name: "rating", value: e },
                    })
                  }
                />
              )}
            </div>
          </div>
          <form>
            <div className="form-group">
              <div className="input-effect">
                {isReviewed ? (
                  <textarea
                    className="form-control"
                    name="comment"
                    placeholder="Description"
                    id="comment"
                    rows="4"
                    value={comment}
                    disabled
                  ></textarea>
                ) : (
                  <textarea
                    className="form-control"
                    name="comment"
                    placeholder="Description"
                    id="comment"
                    rows="4"
                    value={comment}
                    onChange={(e) => handleChange(e)}
                  ></textarea>
                )}
                <label htmlFor="comment">{t("private_chat.comment")}</label>
                <span className="text-danger">{errors.comment}</span>
              </div>
            </div>
            {!isReviewed && (
              <div className="form-group mb-0">
                <Button
                  variant="primary-outline"
                  className="btn-lg"
                  onClick={() => submitFeedback()}
                >
                  {t("submit")}
                </Button>
              </div>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  consumer: state.consumer.consumerdetails,
  underwriting: state.underwriting.underwriting,
});

export default connect(mapStateToProps, { commonApiCall })(
  withRouter(ChatLeftbar)
);
