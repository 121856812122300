import moment from "moment";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getJwt, UNDERWRITING_DETAILS } from "../../../redux/actions";
import {
  customDomainTokenGenerate,
  showErrorToast,
  UnderWritingRoutes,
} from "../../../utils";
import { commonApiCall } from "../../../redux/actions";

import { API_URL_UNDERWRITING } from "../../../config/configs";

const customDomain = "sbliterm.ilifestaging.tech";
const AssurityPreNotice = ({ commonApiCall, underwriting, ...props }) => {
 
  const [agree, setAgree] = useState(false);
  const [documentLoading, setDocumentLoading] = useState(false);

  const createSignature = () => {
    setDocumentLoading(true);
    let data = JSON.parse(localStorage.getItem("persist::ass::bene"));

    data.signedInformation = {
      location: {
        stateAbbreviation:
          data.product.primaryInsured.address.stateAbbreviation,
        city: data.product.primaryInsured.address.city,
      },
      date: moment().format(),
    };
    localStorage.setItem("persist::ass::bene", JSON.stringify(data));
    handlePackageApi();
    // window.location.href = `https://account-d.docusign.com/oauth/auth?response_type=code&scope=signature&client_id=e1631711-4f38-4ca4-98c5-e399f204ab58&redirect_uri=${window.location.origin}/assurity-preview`;
    // props.history.push(UnderWritingRoutes.ASSURITY_DOCUMENTS);
  };

  const saveUnderwriteDetails = async () => {
    try {
      const request = {
        applicationId: underwriting?.applicationId,
        applicationType: "Web",
        nextStep: "24,ASSURITY_DOCUMENTS",
      };

      const isAuthorized = getJwt() ? true : false;
      const apiEndPoint = API_URL_UNDERWRITING + "saveUnderWriting";
      const response = await commonApiCall(
        apiEndPoint,
        "post",
        request,
        UNDERWRITING_DETAILS,
        isAuthorized
      );

      if (response.status.code === 200) {
        props.history.push("/assurity-documents");
        //   window.location.href = `https://account-d.docusign.com/oauth/auth?response_type=token&scope=signature&client_id=e1631711-4f38-4ca4-98c5-e399f204ab58&redirect_uri=${window.location.origin}/assurity-documents`;
      }
    } catch (err) {
      showErrorToast(err);
    }
  };

  const handlePackageApi = async () => {
    try {
      let request = JSON.parse(localStorage.getItem("persist::ass::bene"));
      request.product.issueStateAbbreviation =
        request.product.primaryInsured.address.stateAbbreviation;
      const isAuthorized = getJwt() ? true : false;
      const apiEndPoint =
        API_URL_UNDERWRITING +
        "submitAssurityPackages?applicationId=" +
        underwriting?.applicationId;

      const response = await commonApiCall(
        apiEndPoint,
        "post",
        request,
        null,
        isAuthorized
      );

      if (response.status.code === 200) {
        customDomainTokenGenerate(saveUnderwriteDetails(), "documents");
      } else {
        setTimeout(() => {
          handlePackageApi();
        }, 30000);
        return;
      }
    } catch (err) {
      console.log(err);

      showErrorToast(err);
    }
    setDocumentLoading(false);
  };

  useEffect(() => {
    createSignature();
  }, []);
  return (
    <div className="assurity">
      {/* <div className="container-fluid mt-5">
        <div className="heading-block">
          <h3>Congratulations! You’ve been pre-approved.</h3>
          <h4>
            We need to ask you a few more questions to confirm your policy
          </h4>
        </div>

        <div className="white-box mb-5">
          <h4 className="text-center">Pre-Notice</h4>
          <p>
            To determine your eligibility for insurance, Assurity Life Insurance
            Company (Assurity) must collect certain information about you from
            third parties. These third parties may include, but are not limited
            to, MIB Inc., LexisNexis Risk Solutions, pharmacies and pharmacy
            benefit managers and may include information such as to your
            medical/prescription drug history/conditions (health information),
            driving records and credit information. This notice is to inform you
            that if you proceed to application, at the time of signature, you
            will be presented with full details about collection and use of
            third-party data in the Consumer Notice and Confidential Information
            Authorization which will require your electronic signature for
            application submission prior to any third party data being obtained.
            If you refuse to sign the Confidential Information Authorization at
            time of application, Assurity will not be able to process your
            application for insurance.
          </p>
          <p>
            By checking “I have read and agreed to the Pre-Notice” checkbox
            below, you acknowledge understanding of the information presented
            above.
          </p>
        </div>
        <div className="mt-2 text-center">
          <span className="custom-checkbox">
            <label className="mb-3">
              <input
                type="checkbox"
                checked={agree}
                onChange={(e) => setAgree(e.target.checked)}
                name="status"
              />
              <span></span>I have read and agreed to the Pre-Notice
            </label>
          </span>
        </div>
        <div className="w-100 my-4 text-center">
          <button
            className="btn btn-primary px-5"
            onClick={() => {
              createSignature();
            }}
            disabled={!agree || documentLoading}
          >
            {documentLoading ? "Processing..." : " Next"}
          </button>
        </div>
      </div> */}
      <div className="assurity-loader text-center mt-5">
        <img src="/assets/images/iLifeLoader.gif" />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  underwriting: state.underwriting.underwriting,
});
export default connect(mapStateToProps, { commonApiCall })(
  withRouter(AssurityPreNotice)
);
