import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import MedicarePlanListings from "./medicarePlanListings";
import { withRouter, useHistory } from "react-router-dom";
import {
  UnderWritingRoutes,
  calcAgeOnMonth,
  decryptme,
  encryptme,
  getProcessStep,
  segmentTracking,
  setProcessStep,
  showErrorToast,
} from "../../../utils";
import {
  UNDERWRITING_DETAILS,
  commonApiCall,
  getJwt,
} from "../../../redux/actions";
import * as _ from "lodash";
import moment from "moment";
import {
  API_URL_UNDERWRITING,
  CONSUMER_PORTAL_URL,
} from "../../../config/configs";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { isEmpty } from "lodash";

const MedicarePlanSuggestion = ({
  t,
  underwriting,
  commonApiCall,
  company_information,
  ...props
}) => {
  const _history = useHistory();
  const [loading, setIsLoading] = useState(false);
  const [selectedCarrier, setSelectedCarrier] = useState(null);
  const [searchData, setSearchData] = useState({
    applicationId: "",
    applicationType: "Web",
    goalType: "",
    policyType: "",
    insureBasedOn: "",
    frequency: "",
    amount: "",
    gender: "",
    dob: "",
    age: "",
    healthRating: "",
    smoker: "",
    agentkey: "",
    medicarePolicyFlag: "",
    pay: "",
    termLength: "",
    postalCode: "",
    stateCode: "",
    referenceFrom: document.referrer ? document.referrer : "",
    referalCode: "",
  });

  const [suggestionList, setSuggestionList] = useState([]);

  const doSubmit = async () => {
    setIsLoading(true);
    const { goalType, gender, dob, medicarePolicyFlag } = searchData;
    const request = {
      gender: decryptme(gender),
      medicarePolicyFlag: medicarePolicyFlag,
      age: calcAgeOnMonth(dob).toString(),
      dob: dob ? moment(dob).format("L").toString() : null,
      goalType: goalType,
      postalCode: underwriting?.postalCode,
      policyType: "MEDICARE_INSURANCE",
      applicationId: underwriting?.applicationId,
    };

    const apiEndPoint = API_URL_UNDERWRITING + "getCarriers";
    const response = await commonApiCall(apiEndPoint, "post", request);

    if (response.status.code === 200) {
      let policySuggestionEvent = {
        policy_suggestions: response.data.suggestions,
      };
      setSuggestionList(response.data.suggestions);
      segmentTracking("policy_suggestions", policySuggestionEvent);
    } else {
      showErrorToast(
        "We couldn't find any exact matches. Please update your filters for more policy options."
      );
    }
    setIsLoading(false);
    return;
  };

  useEffect(() => {
    if (!underwriting?.applicationId || !underwriting?.goalType) {
      window.location.replace(CONSUMER_PORTAL_URL + "404");
    }

    if (
      underwriting &&
      !isEmpty(underwriting.carrierWiseLeads) &&
      underwriting.carrierWiseLeads[0].agentAssigned
    ) {
      const whereToRoute = getProcessStep(
        underwriting.resumeProcess,
        underwriting.goalType,
        underwriting.policyType,
        underwriting.productSelection
      );
      props.history.push(whereToRoute);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (underwriting) {
      setSearchData({
        ...searchData,
        applicationId: underwriting.applicationId,
        applicationType: underwriting.applicationType,
        goalType: underwriting.goalType,
        policyType: underwriting.policyType,
        insureBasedOn: underwriting.insureBasedOn,
        frequency: underwriting.frequency,
        amount: underwriting.amount,
        gender: underwriting.gender,
        age: underwriting.age,
        dob: underwriting.dob ? new Date(decryptme(underwriting.dob)) : "",
        healthRating: underwriting.healthRating,
        smoker: underwriting.smoker,
        pay: underwriting.pay,
        termLength: underwriting.termLength,
        postalCode: underwriting?.postalCode,
        stateCode: underwriting?.state?.code,
        medicarePolicyFlag: underwriting?.medicarePolicyFlag,
      });
      // if (!searchData?.applicationId)
      //   window.location.replace(CONSUMER_PORTAL_URL + "404");
    }
    //doSubmit();
  }, [underwriting]);

  useEffect(() => {
    if (searchData.goalType !== "" && searchData.medicarePolicyFlag) {
      doSubmit();
    }
  }, [searchData]);

  const saveMedicarePlan = async () => {
    try {
      const {
        age,
        gender,
        dob,
        applicationId,
        applicationType,
        policyType,
        goalType,
        referalCode,
      } = searchData;
      const splitpath = window.location.pathname.split("/");

      const request = {
        applicationId: applicationId,
        applicationType: applicationType,
        gender: gender,

        age: age,
        dob: dob ? await encryptme(moment(dob).format("L").toString()) : null,
        goalType: goalType,
        policyType: policyType,
        resumeProcess: setProcessStep(splitpath[1], policyType, goalType),
      };

      if (referalCode.length > 0) request.referalCode = referalCode;

      const isAuthorized = getJwt() ? true : false;

      const apiEndPoint = API_URL_UNDERWRITING + "saveUnderWriting";
      const response = await commonApiCall(
        apiEndPoint,
        "post",
        request,
        UNDERWRITING_DETAILS,
        isAuthorized
      );
      if (response.status.code === 200) {
        const request = {
          applicationId: searchData.applicationId,
          selectedPolicies: [
            {
              carrierId: selectedCarrier.carrier_id,
              policyType: "MEDICARE_INSURANCE",
              productId: selectedCarrier.productId,
            },
          ],
          resumeProcess: setProcessStep(splitpath[1], policyType, goalType),
          medicarePolicyFlag: searchData.medicarePolicyFlag,
        };

        const apiEndPoint = API_URL_UNDERWRITING + "saveApplicationCarriers";
        const response = await commonApiCall(apiEndPoint, "post", request);

        if (response.status.code === 200) {
          const appParams = {
            applicationId: response.data.applicationId,
          };

          const apiEndPoint = API_URL_UNDERWRITING + "getUnderWriting";
          const isAuthorized = getJwt() ? true : false;

          await commonApiCall(
            apiEndPoint,
            "post",
            appParams,
            UNDERWRITING_DETAILS,
            isAuthorized
          );

          props.history.push(UnderWritingRoutes.GUEST_BASIC_DETAILS);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };
  const onBackStep = () => {
    if (company_information?.prospectJourney === "multi_page") {
      _history.push(UnderWritingRoutes.ZIPCODE);
    } else {
      _history.push(UnderWritingRoutes.SINGLE_PAGE);
    }
  };
  return (
    <React.Fragment>
      <div className="plan-list planspage">
        <div className="title-header">
          <h2 className="text-center">Available Plans</h2>
          <h3>
            Here are some popular insurers in your area. Please select the ones
            that interest you.
          </h3>
        </div>
        <div className="plan-list-wrapper">
          <div className="plan-list-wrapper">
            <MedicarePlanListings
              planList={suggestionList}
              onPlanSelect={setSelectedCarrier}
              selected={selectedCarrier}
            />
          </div>
          <div className="col-12 text-center my-3">
            <button
              className="btn btn-primary"
              type="button"
              disabled={selectedCarrier ? false : true}
              onClick={saveMedicarePlan}
            >
              {t("next")}
            </button>
          </div>
          {underwriting && !underwriting?.carrierWiseLeads && (
            <div className="col-12 text-center mt-3">
              <Link
                to={"#"}
                onClick={() => onBackStep()}
                className="btn btn-link"
                title="Back"
              >
                {t("back")}
              </Link>
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  company_information: state.underwriting.companyInformation,
  underwriting: state.underwriting.underwriting,
});
export default connect(mapStateToProps, {
  commonApiCall,
})(withRouter(MedicarePlanSuggestion));
