import React, { useState } from "react";
import HealthRecords from "./healthRecords";
import HealthRecordsLabs from "./healthRecordsLabs";
import MedicalCare from "./medicalCare";
import MedicalLogin from "./medicalLogin";

import StepZilla from "react-stepzilla";

const MedicalRecords = ({ handleClose }) => {
  const [steps] = useState([
    { name: "Step 1", component: <HealthRecords closeModal={handleClose} /> },
    { name: "Step 2", component: <HealthRecordsLabs /> },
    { name: "Step 3", component: <MedicalCare /> },
    { name: "Step 4", component: <MedicalLogin /> }
  ]);
  return (
    <div className="step-progress">
      <StepZilla
        showSteps={false}
        steps={steps}
        stepsNavigation={false}
        prevBtnOnLastStep={true}
        nextButtonCls="btn btn-prev btn-primary"
        backButtonCls="btn btn-prev btn-link"
        backButtonText="Back"
      />
    </div>
  );
};

export default MedicalRecords;
