import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
    commonApiCall,
    getJwt,
    setAssurityProgressCount,
    UNDERWRITING_DETAILS,
} from "../../../../redux/actions";
import {
    AssurityBaseBoolean,
    AssurityTextField,
    ShowDisclosurePickList,
    showErrorToast,
    UnderWritingRoutes,
} from "../../../../utils";
import { API_URL_UNDERWRITING } from "../../../../config/configs";

const GeneralSection = ({
    t,
    baseQuestion = null,
    commonApiCall,
    underwriting,
    onAnswered,
    assurityCount,
    setAssurityProgressCount,
    onGetUnderWritingCase,
    ...props
}) => {
    const [baseAnswer, setBaseAnswer] = useState([]);
    const [reflexiveQuestionList, setReflexiveQuestionList] = useState([]);
    const [reflexiveArr, setReflexiveArr] = useState([]);
    const [loading, setLoading] = useState(false);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [currentQuestionHeader, setCurrentQuestionHeader] = useState(null);
    const [disclosure, setDisclosure] = useState({});
    const [questionLoading, setQuestionLoading] = useState({
        questionId: null,
        loading: false,
    });
    const [enableNextButton, setEnableNextButton] = useState(false);

    const [showSearchType, setSearchType] = useState({});

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [currentQuestionIndex]);



    const screen = new URLSearchParams(props.location.search).get("underwriting");
    const getRoute = () => {
        if (screen) {
            switch (screen) {
                case "GENERAL_SECTION_1":
                    return "GENERAL_SECTION_2";
                case "GENERAL_SECTION_2":
                    return "HEALTH_SECTION_1"
                default:
                    return "";
            }
        }
    };

    const getPreviousRoute = () => {
        if (screen) {
            switch (screen) {
                case "GENERAL_SECTION_2":
                    return "GENERAL_SECTION_1";
                default:
                    return "";
            }
        }
    };



    const updateBaseQuestion = async (obj) => {
        setLoading(true);
        try {
            const request = {
                baseQuestionAnswers: [
                    {
                        baseQuestionId: obj.BaseQuestionId,
                        baseQuestionAnswerValue: obj.answerValue,
                    },
                ],
            };

            const isAuthorized = getJwt() ? true : false;
            const apiEndPoint =
                API_URL_UNDERWRITING +
                "updatebaseQuestions?applicationId=" +
                underwriting?.applicationId;

            const response = await commonApiCall(
                apiEndPoint,
                "put",
                request,
                null,
                isAuthorized
            );
            if (response.status.code !== 200) {
                showErrorToast(response.status.message);
            }
        } catch (err) {
            showErrorToast(err);
        }
        setLoading(false);
    };

    const updateReflexiveQuestion = useCallback(
        async (question, disclosure, reflexiveAns = []) => {
            setLoading(true);
            const disclosureArray = disclosure[question.BaseQuestionId].filter(element => Object.keys(element).length > 0);
            try {
                const apiCall = [];
                disclosureArray.forEach((element) => {
                    let obj;
                    obj = {
                        baseQuestionId: question.BaseQuestionId,
                        reflexiveQuestionAnswers: reflexiveAns.filter(item => item.BaseQuestionId === question.BaseQuestionId && item.DisclosureId === element.disclosureId).map(
                            ({ BaseQuestionId, DisclosureId, ...rest }) => ({ ...rest })
                        ),
                    };
                    const isAuthorized = getJwt() ? true : false;
                    const apiEndPoint =
                        API_URL_UNDERWRITING +
                        "updateReflexiveQuestions?applicationId=" +
                        underwriting?.applicationId +
                        "&disclosureId=" +
                        element.disclosureId;
                    obj.reflexiveQuestionAnswers.length > 0 && apiCall.push(commonApiCall(
                        apiEndPoint,
                        "put",
                        obj,
                        null,
                        isAuthorized
                    ));
                });
                const response = await Promise.all(apiCall);

                if (response.every((res) => res.status.code === 200)) {
                    moveToNextQuestion();
                }
                else {
                    response.map(res => {
                        if (res.status.code !== 200) {
                            showErrorToast(res.status.message);
                        }
                    });
                }
            } catch (err) {
                console.log(err);
                showErrorToast(err);
            }
            setLoading(false);
        },
        [reflexiveArr]
    );

    const handleReflexiveList = useCallback(
        (list, baseId) => {
            let tempReflexive = Array.isArray(list) ? [...reflexiveQuestionList.filter((item) => item.BaseQuestionId !== baseId)] : [...reflexiveQuestionList];
            if (!Array.isArray(list)) {
                let tempIndex = tempReflexive.findIndex(
                    (x) => x.BaseQuestionId === baseId && x.DisclosureId === list.DecisionTree.DisclosureId
                );

                if (tempIndex > -1) {
                    tempReflexive[tempIndex].ReflexiveQuestion = list.DecisionTree.ReflexiveQuestion;
                    tempReflexive[tempIndex].DisclosureId = list.DecisionTree.DisclosureId;
                } else {
                    tempReflexive.push({
                        BaseQuestionId: baseId,
                        DisclosureId: list.DecisionTree.DisclosureId,
                        ReflexiveQuestion: list.DecisionTree.ReflexiveQuestion,
                    });
                }
            }
            else {

                list.map((reflexiveList) => {
                    tempReflexive.push({
                        BaseQuestionId: baseId,
                        DisclosureId: reflexiveList.DecisionTree.DisclosureId,
                        ReflexiveQuestion: reflexiveList.DecisionTree.ReflexiveQuestion,
                    });
                })
            }
            setQuestionLoading({
                questionId: baseId,
                loading: { ...questionLoading.loading, [Array.isArray(list) ? list[0].DecisionTree.DisclosureId : list.DecisionTree.DisclosureId]: false },
            });
            setReflexiveQuestionList(tempReflexive);

        },
        [reflexiveQuestionList]
    );

    const getReflexiveQuestions = async (baseId, disclosureItem) => {
        const loadingDisclosure = Array.isArray(disclosureItem) ? disclosureItem[0] : disclosureItem;
        setQuestionLoading({
            questionId: baseId,
            loading: { ...questionLoading.loading, [loadingDisclosure]: true },
        });
        try {
            //  if (dn bisclosure.includes(",")) disclosure = encodeURIComponent(disclosure);
            if (Array.isArray(disclosureItem)) {
                const reflexiveList = [];
                const apiCall = disclosureItem.map((id, index) => {
                    const apiEndPoint =
                        API_URL_UNDERWRITING +
                        "assurityReflexiveQuestions?applicationId=" +
                        underwriting?.applicationId +
                        "&disclosureId=" +
                        id;

                    return commonApiCall(apiEndPoint, "get", {}, "");
                });

                const response = await Promise.all(apiCall);
                response.map(item => {
                    reflexiveList.push(JSON.parse(item.data));
                });
                handleReflexiveList(
                    reflexiveList,
                    baseId
                );
            }
            else {
                const apiEndPoint =
                    API_URL_UNDERWRITING +
                    "assurityReflexiveQuestions?applicationId=" +
                    underwriting?.applicationId +
                    "&disclosureId=" +
                    disclosureItem;

                const response = await commonApiCall(apiEndPoint, "get", {}, "");

                if (response.status.code === 200) {
                    // setOptionList(re)
                    const list = JSON.parse(response.data);
                    handleReflexiveList(
                        list,
                        baseId
                    );
                }
            }
        } catch (err) {
            console.log(err);
            showErrorToast(err);
        }

    };

    const getReflexiveList = (question, disclosureId) => {
        let itemIndex = reflexiveQuestionList.filter(
            (x) => x.BaseQuestionId === question.BaseQuestionId && x.DisclosureId === disclosureId
        );
        if (itemIndex.length > 0) {
            return itemIndex[0];
        }
        return null;
    };


    const getQuestionFromBranch = (reflex, value) => {
        if (reflex.ReflexiveQuestionType === "Text") {
            if (reflex.Branches.length > 1) {
                const currentBranch =
                    reflex.Branches && value
                        ? reflex.Branches.filter(
                            (x) => x.BranchValue.toLowerCase() == value.toLowerCase()
                        )
                        : null;
                return currentBranch && currentBranch.length > 0
                    ? currentBranch[0]
                    : null;
            } else {
                return reflex.Branches.length > 0 && value !== "" && value
                    ? reflex.Branches[0]
                    : null;
            }
        } else if (reflex.ReflexiveQuestionType === "Numeric") {
            let currentBranch = null;
            if (reflex.Branches && value && reflex.Branches.length > 0) {
                for (let i = 0; i < reflex.Branches.length; i++) {
                    if (parseInt(value) >= parseInt(reflex.Branches[i].BranchValue)) {
                        currentBranch = reflex.Branches[i];
                    } else {
                        break;
                    }
                }
            }
            return currentBranch ? currentBranch : null;
        } else {
            const currentBranch =
                reflex.Branches && value
                    ? reflex.Branches.filter((x) => x.BranchValue == value)
                    : null;

            return currentBranch && currentBranch.length > 0
                ? currentBranch[0]
                : null;
        }
    };

    const renderReflexiveQuestion = (question, reflex, disclosureId = null) => {
        const onValueChange = (id, e, branch = null) => {
            let tempReflexiveArr = [...reflexiveArr];
            let tempReflexiveForDisclosure = tempReflexiveArr.filter((x) => x.BaseQuestionId === question.BaseQuestionId && x.DisclosureId === disclosureId);
            let reflexIndex = tempReflexiveForDisclosure.findIndex(
                (x) => x.reflexiveQuestionId === id.ReflexiveQuestionId
            );
            if (reflexIndex > -1) {
                tempReflexiveForDisclosure[reflexIndex].reflexiveQuestionAnswerValue =
                    e.target.value;
                tempReflexiveForDisclosure.splice(reflexIndex + 1);
            } else {
                const obj = {
                    reflexiveQuestionId: id.ReflexiveQuestionId,
                    reflexiveQuestionAnswerValue: e.target.value,
                    BaseQuestionId: question.BaseQuestionId,
                    DisclosureId: disclosureId,
                };
                tempReflexiveForDisclosure.push(obj);
            }

            setReflexiveArr([...tempReflexiveForDisclosure, ...tempReflexiveArr.filter((x) => x.BaseQuestionId === question.BaseQuestionId && x.DisclosureId !== disclosureId)]);
            if (branch === null || branch?.ReflexiveQuestion === null) {
                setEnableNextButton(true);
            }
        };
        const getCurrentSelectedValue = (item) => {
            const filterReflex = reflexiveArr.filter(
                (x) => x.reflexiveQuestionId == item.ReflexiveQuestionId && x.BaseQuestionId === question.BaseQuestionId && x.DisclosureId === disclosureId
            );
            if (filterReflex.length > 0) {
                return filterReflex[0].reflexiveQuestionAnswerValue;
            }
            return "";
        };

        return (
            <>
                <div
                    className={
                        `sbli-child-question reflexive_child_question_${disclosureId} mt-4 question_` + reflex.ReflexiveQuestionId
                    }
                >
                    <h4 className="mb-4 parent_question_head">
                        {reflex.ReflexiveQuestionText}
                    </h4>
                    {(reflex.ReflexiveQuestionType === "Date" ||
                        reflex.ReflexiveQuestionType === "Boolean" ||
                        reflex.ReflexiveQuestionType === "List") && (
                            <span className="custom-radio mt-3 d-block">
                                {reflex.Branches.map((branch, index) => {
                                    return (
                                        <React.Fragment>
                                            <label
                                                className="m-0 mb-3"
                                                htmlFor={reflex.ReflexiveQuestionId + "ab" + index}
                                            >
                                                <input
                                                    type="radio"
                                                    name={reflex.ReflexiveQuestionId}
                                                    id={reflex.ReflexiveQuestionId + "ab" + index}
                                                    value={branch.BranchValue}
                                                    checked={
                                                        getCurrentSelectedValue(reflex) === branch.BranchValue
                                                    }
                                                    onChange={(e) => onValueChange(reflex, e, branch)}
                                                />
                                                <span></span> {branch.BranchText}
                                            </label>

                                        </React.Fragment>
                                    );
                                })}
                                {getQuestionFromBranch(reflex, getCurrentSelectedValue(reflex)) &&
                                    getQuestionFromBranch(reflex, getCurrentSelectedValue(reflex))
                                        ?.ReflexiveQuestion
                                    ? renderReflexiveQuestion(
                                        question,
                                        getQuestionFromBranch(reflex, getCurrentSelectedValue(reflex))
                                            ?.ReflexiveQuestion,
                                        disclosureId
                                    )
                                    : null}

                            </span>
                        )}
                    {reflex.ReflexiveQuestionType === "Text"
                        && (
                            <React.Fragment>
                                <div className="form-group ">
                                    <input
                                        className="form-control form-control-search"
                                        onChange={(e) =>
                                            onValueChange(
                                                reflex,
                                                e,
                                                getQuestionFromBranch(reflex, e.target.value)
                                            )
                                        }
                                        type="text"
                                        value={getCurrentSelectedValue(reflex)}
                                        placeholder="Enter Text"
                                    />
                                </div>
                                {getQuestionFromBranch(reflex, getCurrentSelectedValue(reflex)) &&
                                    getQuestionFromBranch(reflex, getCurrentSelectedValue(reflex))
                                        ?.ReflexiveQuestion
                                    ? renderReflexiveQuestion(
                                        question,
                                        getQuestionFromBranch(reflex, getCurrentSelectedValue(reflex))
                                            ?.ReflexiveQuestion,
                                        disclosureId
                                    )
                                    : null}


                            </React.Fragment>
                        )}
                    {reflex.ReflexiveQuestionType === "Numeric" && (
                        <React.Fragment>
                            <div className="form-group ">
                                <input
                                    className="form-control form-control-search"
                                    onChange={(e) =>
                                        onValueChange(
                                            reflex,
                                            e,
                                            getQuestionFromBranch(reflex, e.target.value)
                                        )
                                    }
                                    type="number"
                                    value={getCurrentSelectedValue(reflex)}
                                    placeholder="Enter numeric data"
                                />
                            </div>
                            {getQuestionFromBranch(reflex, getCurrentSelectedValue(reflex)) &&
                                getQuestionFromBranch(reflex, getCurrentSelectedValue(reflex))
                                    ?.ReflexiveQuestion
                                ? renderReflexiveQuestion(
                                    question,
                                    getQuestionFromBranch(reflex, getCurrentSelectedValue(reflex))
                                        ?.ReflexiveQuestion,
                                    disclosureId
                                )
                                : null}


                        </React.Fragment>
                    )}

                </div>
            </>
        );
    };

    const baseBooleanTrigger = (question, value) => {
        if (question.BaseQuestionDisclosures.length > 0) {
            if (question.BaseQuestionDisclosures[0].TriggerOn === value) {
                setDisclosure({ ...disclosure, [question.BaseQuestionId]: [{ disclosureId: question.BaseQuestionDisclosures[0].DisclosureId, disclosureName: question.BaseQuestionDisclosures[0].DisclosureName }] });
                getReflexiveQuestions(
                    question.BaseQuestionId,
                    question.BaseQuestionDisclosures[0].DisclosureId
                );
            } else {
                let tempArr = [...reflexiveQuestionList];
                let r_item = reflexiveQuestionList.findIndex(
                    (x) => x.BaseQuestionId === question.BaseQuestionId
                );
                if (r_item > -1) {
                    tempArr.splice(r_item, 1);
                }

                setReflexiveQuestionList(tempArr);
                setReflexiveArr(
                    reflexiveArr.filter(
                        (x) => x.BaseQuestionId !== question.BaseQuestionId
                    )
                );
            }
        }
    };

    const handleBaseAnswer = (question, value, type) => {
        if (value === "true" && (question.SearchType[0].Value !== "Pick_List" && question.SearchType[0].Value !== "Search")) {
            setEnableNextButton(true);
        }
        if (value === "false") {
            setEnableNextButton(true);
        }
        let tempReflexiveQuestionList = [...reflexiveQuestionList];
        let tempReflexiveArr = [...reflexiveArr];
        let tempBaseAnswer = [...baseAnswer];
        let tempDisclosure = { ...disclosure };
        let tempSearchType = { ...showSearchType };
        tempReflexiveQuestionList = tempReflexiveQuestionList.filter(item => item.BaseQuestionId !== question.BaseQuestionId);
        tempReflexiveArr = tempReflexiveArr.filter(item => item.BaseQuestionId !== question.BaseQuestionId);
        tempDisclosure[question.BaseQuestionId] = [{}];
        tempSearchType[question.BaseQuestionId] = { 0: {} };
        let itemIndex = baseAnswer.findIndex(
            (x) => x.BaseQuestionId === question.BaseQuestionId
        );
        if (itemIndex > -1) {
            tempBaseAnswer[itemIndex][type] = value;
            if (type === "answerValue") {
                updateBaseQuestion(tempBaseAnswer[itemIndex]);
            }
        } else {
            const obj = {
                BaseQuestionId: question.BaseQuestionId,
                [type]: value,
            };
            if (type === "answerValue") {
                updateBaseQuestion(obj);
            }
            tempBaseAnswer.push(obj);
        }
        setDisclosure({ ...tempDisclosure });
        setReflexiveQuestionList([...tempReflexiveQuestionList]);
        setReflexiveArr([...tempReflexiveArr]);
        setSearchType(tempSearchType);
        setBaseAnswer(tempBaseAnswer);

    };

    const handleDisclosurePickList = async (value, question) => {
        let tempDisclosure = { ...disclosure };
        if (value) {
            const disclosureIds = value.map(item => item.value);
            await getReflexiveQuestions(
                question.BaseQuestionId,
                disclosureIds
            );
            tempDisclosure = { ...disclosure, [question.BaseQuestionId]: [...value.map((item => ({ disclosureId: item.value, disclosureName: item.label })))] }
            setDisclosure({ ...tempDisclosure });
        }
        else {
            let tempReflexiveQuestionList = [...reflexiveQuestionList];
            let tempReflexiveArr = [...reflexiveArr];
            let tempDisclosure = { ...disclosure }
            tempReflexiveQuestionList = tempReflexiveQuestionList.filter(item => item.BaseQuestionId !== question.BaseQuestionId);
            tempReflexiveArr = tempReflexiveArr.filter(item => item.BaseQuestionId !== question.BaseQuestionId);
            tempDisclosure[question.BaseQuestionId] = [{}];
            setReflexiveArr(tempReflexiveArr);
            setReflexiveQuestionList(tempReflexiveQuestionList);
            setDisclosure({ ...tempDisclosure });
        }
    }

    const getPickListClosureId = (question) => {
        return baseAnswer.filter(
            (x) => x.BaseQuestionId === question.BaseQuestionId
        ).length > 0
            ? baseAnswer.filter(
                (x) => x.BaseQuestionId === question.BaseQuestionId
            )[0].disclosureId
            : "";
    };

    const getBaseAnswer = (question) => {
        if (
            baseAnswer.filter((x) => x.BaseQuestionId === question.BaseQuestionId)
                .length > 0
        ) {
            return baseAnswer.filter(
                (x) => x.BaseQuestionId === question.BaseQuestionId
            )[0].answerValue;
        }
        return "";
    };

    const validateQuestions = (disclosureId) => {
        let baseErrors = {};
        let reflexiveErrors = {};
        const baseQuestions = document.getElementsByClassName("question");

        if (baseQuestions.length > 0) {
            for (let index = 0; index < baseQuestions.length; index++) {
                const element = baseQuestions[index];
                let classList = element.classList[
                    Object.keys(element.classList)[2]
                ].replace("question_", "");

                if (baseAnswer.findIndex((y) => y.BaseQuestionId == classList) < 0) {
                    element.classList.add("validate-question-parent");
                    baseErrors = {
                        ...baseErrors,
                        [classList]: "required",
                    };
                    // baseErrors[questionId] = "required";
                } else {
                    element.classList.remove("validate-question-parent");
                }

                let childReflexiveList = element.getElementsByClassName(
                    `reflexive_child_question_${disclosureId}`
                );
                if (childReflexiveList.length > 0) {
                    for (let index = 0; index < childReflexiveList.length; index++) {
                        const childElement = childReflexiveList[index];
                        let childClassList = childElement.classList[
                            3
                        ].replace("question_", "");
                        if (
                            reflexiveArr.findIndex(
                                (y) => y.reflexiveQuestionId == childClassList
                            ) < 0
                        ) {
                            childElement.classList.add("validate-question-child");
                            reflexiveErrors = {
                                ...reflexiveErrors,
                                [childClassList]: "required",
                            };
                        } else {
                            childElement.classList.remove("validate-question-child");
                        }
                    }
                }
            }
        }

        return Object.keys(baseErrors).length === 0 &&
            Object.keys(reflexiveErrors).length === 0
            ? true
            : false;
    };

    const handleInputChange = async (question, value, index) => {
        try {
            let tempSearchType = { ...showSearchType };
            tempSearchType[question.BaseQuestionId][index] = {
                ...tempSearchType[question.BaseQuestionId][index],
                value: value,
                options: [],
                questionId: question.BaseQuestionId
            };
            setSearchType({ ...tempSearchType });
            if (value !== "") {
                const apiEndPoint =
                    API_URL_UNDERWRITING +
                    "assurityDisclosures?applicationId=" +
                    underwriting?.applicationId +
                    "&categoryId=" +
                    question?.CategoryId +
                    "&searchText=" +
                    value;
                const response = await commonApiCall(apiEndPoint, "get", {}, "");

                if (response.status.code === 200) {
                    // setOptionList(re)
                    const list = JSON.parse(response.data);
                    tempSearchType[question.BaseQuestionId][index] = {
                        ...tempSearchType[question.BaseQuestionId][index],
                        options: list.DisclosureMatches,
                    };
                    setSearchType({ ...tempSearchType });
                }
            }
        } catch (err) {
            console.log(err);
            showErrorToast(err);
        }
    };

    const getProgressCount = () => {
        let count = 0;
        assurityCount = assurityCount ? assurityCount : { underWritingProgress: 7 };
        if (
            screen === "GENERAL_SECTION_1" &&
            assurityCount.underWritingProgress < 3
        ) {
            count = 3;
        }
        if (
            screen === "GENERAL_SECTION_2" &&
            assurityCount.underWritingProgress < 4
        ) {
            count = 4;
        }
        if (
            screen === "HEALTH_SECTION_1" &&
            assurityCount.underWritingProgress < 5
        ) {
            count = 5;
        }
        if (
            screen === "HEALTH_SECTION_2" &&
            assurityCount.underWritingProgress < 6
        ) {
            count = 6;
        }
        if (
            screen === "HEALTH_SECTION_3" &&
            assurityCount.underWritingProgress < 7
        ) {
            count = 7;
        }
        if (
            screen === "HEALTH_SECTION_4" &&
            assurityCount.underWritingProgress < 8
        ) {
            count = 8;
        }
        if (
            screen === "COVID_QUESTIONS" &&
            assurityCount.underWritingProgress < 9
        ) {
            count = 9;
        }
        return count;
    };

    useEffect(() => {
        if (baseQuestion && baseQuestion.length > 0) {
            for (let index = 0; index < baseQuestion.length; index++) {
                const element = baseQuestion[index];
                if (element.BaseQuestionType !== "Heading") {
                    setSearchType({ ...showSearchType, [baseQuestion[index].BaseQuestionId]: {} });
                    setDisclosure({ ...disclosure, [baseQuestion[index].BaseQuestionId]: [{}] });
                    setCurrentQuestionIndex(index);
                    break;
                }
            }
            let updatedQuestionHeader =
                baseQuestion &&
                baseQuestion.length > 0 &&
                baseQuestion.find((question) => question.BaseQuestionType === "Heading")
                    ?.BaseQuestionText;
            if (
                updatedQuestionHeader &&
                updatedQuestionHeader !== currentQuestionHeader
            ) {
                setCurrentQuestionHeader(updatedQuestionHeader);
            } else {
                let isSubBaseQuestion =
                    baseQuestion &&
                    baseQuestion.length > 0 &&
                    baseQuestion[0]?.BaseQuestionId.includes("SubBase");
                if (getPreviousRoute() !== "" && isSubBaseQuestion) {
                    onGetUnderWritingCase(getPreviousRoute()).then((response) => {
                        updatedQuestionHeader =
                            response &&
                            response.length > 0 &&
                            response?.findLast(
                                (question) => question.BaseQuestionType === "Heading"
                            )?.BaseQuestionText;
                        if (
                            updatedQuestionHeader &&
                            updatedQuestionHeader !== currentQuestionHeader
                        ) {
                            setCurrentQuestionHeader(updatedQuestionHeader);
                        }
                    });
                }
            }
        }
    }, [baseQuestion]);

    const moveToNextQuestion = async () => {
        if (currentQuestionIndex < baseQuestion.length - 1) {
            for (
                let index = currentQuestionIndex + 1;
                index < baseQuestion.length;
                index++
            ) {
                const element = baseQuestion[index];
                if (element.BaseQuestionType === "Heading") {
                    setCurrentQuestionHeader(element?.BaseQuestionText);
                }
                if (element.BaseQuestionType !== "Heading") {
                    setSearchType({ ...showSearchType, [baseQuestion[index].BaseQuestionId]: {} })
                    setDisclosure({ ...disclosure, [baseQuestion[index].BaseQuestionId]: [{}] });
                    setQuestionLoading({ ...questionLoading, loading: {} });
                    baseAnswer.findIndex(x => x.BaseQuestionId === baseQuestion[index].BaseQuestionId) < 0 && setEnableNextButton(false);
                    setCurrentQuestionIndex(index);
                    break;
                }
            }
        } else {
            setAssurityProgressCount({
                ...assurityCount,
                underWritingProgress: getProgressCount(),
            });
            saveUnderwriteDetails(
                "24,ASSURITY_UNDERWRITING," + getRoute(),
                getRoute()
            );
        }
    };

    const onNextHandler = async () => {
        const validation = disclosure[baseQuestion[currentQuestionIndex].BaseQuestionId].map((id) =>
            validateQuestions(id.disclosureId)
        );
        if (!validation.includes(false)) {
            const baseAnswerValue = baseAnswer.find(
                (x) =>
                    x.BaseQuestionId ===
                    baseQuestion[currentQuestionIndex].BaseQuestionId
            )?.answerValue;
            if (baseAnswerValue === "true") {
                updateReflexiveQuestion(
                    baseQuestion[currentQuestionIndex],
                    disclosure,
                    reflexiveArr
                );
            } else {
                moveToNextQuestion();
            }
        }
    };

    const saveUnderwriteDetails = async (step, route) => {
        try {
            const request = {
                applicationId: underwriting?.applicationId,
                applicationType: "Web",
                nextStep: step,
            };

            const isAuthorized = getJwt() ? true : false;
            const apiEndPoint = API_URL_UNDERWRITING + "saveUnderWriting";
            const response = await commonApiCall(
                apiEndPoint,
                "post",
                request,
                UNDERWRITING_DETAILS,
                isAuthorized
            );

            if (response.status.code === 200) {
                props.history.push(
                    `${UnderWritingRoutes.ASSURITY_UNDERWRITING}?underwriting=${route}`
                );
            }
        } catch (err) {
            showErrorToast(err);
        }
    };

    const onBackHandler = () => {
        for (let index = currentQuestionIndex - 1; index >= 0; index--) {
            const element = baseQuestion[index];
            if (element.BaseQuestionType === "Heading") {
                setCurrentQuestionHeader(element?.BaseQuestionText);
            }
            if (element.BaseQuestionType !== "Heading") {
                setEnableNextButton(true);
                setCurrentQuestionIndex(index);
                break;
            }
        }
    };

    const removeDisclosure = (question, index) => {
        let tempDisclosure = { ...disclosure };
        const disclosureId = tempDisclosure[question.BaseQuestionId][index];
        let tempSearchType = { ...showSearchType };
        let tempReflexiveQuestionList = [...reflexiveQuestionList];
        let tempReflexiveArr = [...reflexiveArr];
        index === 0 ? tempDisclosure[question.BaseQuestionId][index] = {} : tempDisclosure[question.BaseQuestionId].splice(index, 1);
        index === 0 ? tempSearchType[question.BaseQuestionId][index] = {} : delete tempSearchType[question.BaseQuestionId][index];
        tempReflexiveQuestionList = tempReflexiveQuestionList.filter(item => !(item.BaseQuestionId === question.BaseQuestionId && item.DisclosureId === disclosureId));
        tempReflexiveArr = tempReflexiveArr.filter(item => !(item.BaseQuestionId === question.BaseQuestionId && item.DisclosureId === disclosureId));
        setDisclosure({ ...disclosure });
        setSearchType({ ...tempSearchType });
        setReflexiveQuestionList([...tempReflexiveQuestionList]);
        setReflexiveArr([...tempReflexiveArr]);
    };

    return (
        <div className="underwriting-detail sbli-question-container pb-5">
            {baseQuestion &&
                baseQuestion[currentQuestionIndex]?.BaseQuestionId.includes("Sub") && (
                    <div
                        className={"sbli-parent-question  mb-3"}
                        id="sbli_question_container"
                        key={baseQuestion[currentQuestionIndex]?.BaseQuestionId}
                    >
                        <h4
                            style={{
                                fontWeight: 600,
                            }}
                            className="mb-4 parent_question_head"
                        >
                            {currentQuestionHeader}
                        </h4>
                    </div>
                )}
            {baseQuestion &&
                baseQuestion.map((question, index) => {
                    if (
                        index === currentQuestionIndex &&
                        question.BaseQuestionType !== "Heading"
                    ) {
                        return (
                            <div
                                className={
                                    "sbli-parent-question " +
                                    // (baseQuestionErrors.hasOwnProperty(question.BaseQuestionId)
                                    //   ? "validate-question-parent "
                                    //   : "") +
                                    `question question_${question.BaseQuestionId} mb-5`
                                }
                                id="sbli_question_container"
                                key={question.BaseQuestionId}
                            >
                                <h4
                                    style={{
                                        fontWeight: 500,
                                    }}
                                    className="mb-4 parent_question_head"
                                >
                                    {question.BaseQuestionText}
                                </h4>

                                {question.BaseQuestionType === "Trigger_On_Yes" && (
                                    <AssurityBaseBoolean
                                        question={question}
                                        value={
                                            getBaseAnswer(question)
                                                ? getBaseAnswer(question) === "true"
                                                    ? "Yes"
                                                    : "No"
                                                : ""
                                        }
                                        disabled={Object.values(questionLoading.loading).includes(true)}
                                        onValueChange={(question, value) => {
                                            handleBaseAnswer(
                                                question,
                                                value.target.value.toLowerCase() === "yes"
                                                    ? "true"
                                                    : "false",
                                                "answerValue"
                                            );
                                            if (question.SearchType[0].Value !== "Pick_List" && question.SearchType[0].Value !== "Search") {
                                                baseBooleanTrigger(question, value.target.value);
                                            }
                                        }}
                                    />
                                )}

                                {disclosure[question.BaseQuestionId] && disclosure[question.BaseQuestionId].length > 0 &&
                                    disclosure[question.BaseQuestionId].map((disclosureDetail, disclosureIndex) =>
                                        <div className={`${(baseAnswer.find(
                                            (x) =>
                                                x.BaseQuestionId ===
                                                question.BaseQuestionId
                                        )?.answerValue === "true" && question.SearchType[0].Value === "Search") ? "card text-left mb-3" : ""}`}>
                                            {question.SearchType[0]?.TriggerOn ===
                                                (getBaseAnswer(question)
                                                    ? getBaseAnswer(question) === "true"
                                                        ? "Yes"
                                                        : "No"
                                                    : "") &&
                                                question.SearchType[0]?.Value === "Search" && (
                                                    <div className="mt-4">
                                                        <AssurityTextField
                                                            options={
                                                                showSearchType[question.BaseQuestionId][disclosureIndex]
                                                                    ? showSearchType[question.BaseQuestionId][disclosureIndex].options
                                                                    : []
                                                            }
                                                            value={
                                                                showSearchType[question.BaseQuestionId][disclosureIndex]
                                                                    ? showSearchType[question.BaseQuestionId][disclosureIndex].value
                                                                    : ""
                                                            }
                                                            showRemove={disclosureIndex === 0 ? (Object.keys(disclosure[question.BaseQuestionId][disclosureIndex]).length > 0 && (showSearchType[question.BaseQuestionId][disclosureIndex]?.value)) : disclosure[question.BaseQuestionId][disclosureIndex]}
                                                            removeDisclosure={() => removeDisclosure(question, disclosureIndex)}
                                                            onValueChange={(e, item) => {
                                                                if (disclosure[question.BaseQuestionId].filter(disclosureItem => disclosureItem.disclosureId === item.DisclosureId).length > 0) {
                                                                    let tempSearchType = { ...showSearchType };
                                                                    let tempDisclosure = { ...disclosure };
                                                                    tempDisclosure[question.BaseQuestionId].splice(disclosureIndex, 1);
                                                                    disclosureIndex === 0 ? tempSearchType[question.BaseQuestionId][disclosureIndex] = {} : delete tempSearchType[question.BaseQuestionId][disclosureIndex];
                                                                    setDisclosure({ ...tempDisclosure })
                                                                    setSearchType({ ...tempSearchType });
                                                                }
                                                                else {
                                                                    let tempSearchType = { ...showSearchType };
                                                                    let tempDisclosure = { ...disclosure };
                                                                    tempDisclosure[question.BaseQuestionId][disclosureIndex] = { disclosureId: item.DisclosureId, disclosureName: item.DisclosureName };
                                                                    tempSearchType[question.BaseQuestionId][disclosureIndex] = {
                                                                        ...tempSearchType[question.BaseQuestionId][disclosureIndex],
                                                                        value: e,
                                                                        disclosureId: item.DisclosureId
                                                                    }
                                                                    setDisclosure({ ...tempDisclosure });
                                                                    setSearchType({ ...tempSearchType });
                                                                    getReflexiveQuestions(
                                                                        question.BaseQuestionId,
                                                                        item.DisclosureId
                                                                    );
                                                                }

                                                            }}
                                                            onInputChange={(d) => handleInputChange(question, d, disclosureIndex)}
                                                        />
                                                    </div>
                                                )}


                                            {(question.SearchType[0].Value !== "Pick_List" || disclosureIndex === 0) && getBaseAnswer(question) &&
                                                question.SearchType[0].Value === "Pick_List" &&
                                                (question.SearchType[0].TriggerOn === "Yes"
                                                    ? "true"
                                                    : "false") === getBaseAnswer(question) && (
                                                    <ShowDisclosurePickList
                                                        list={question.BaseQuestionDisclosures}
                                                        onValueChange={(id) =>
                                                            handleDisclosurePickList(id, question)
                                                        }
                                                        value={getPickListClosureId(question)}
                                                        baseAnswer={getBaseAnswer(question)}
                                                    />
                                                )}
                                            {question.BaseQuestionType !== "Heading" && (question.SearchType[0].Value !== "Pick_List" || disclosureIndex === 0) &&
                                                questionLoading.questionId === question.BaseQuestionId &&
                                                (question.SearchType[0].Value === "Pick_List" ? Object.values(questionLoading.loading).includes(true) : questionLoading.loading[disclosureDetail.disclosureId]) && (
                                                    <p className="mt-3">Please wait....</p>
                                                )}

                                            {((question.SearchType[0].Value === "Search" && (showSearchType[question.BaseQuestionId][disclosureIndex]?.value)) || question.SearchType[0].Value !== "Search") && getReflexiveList(question, disclosureDetail.disclosureId) && getReflexiveList(question, disclosureDetail.disclosureId).ReflexiveQuestion &&
                                                <>
                                                    {question.SearchType[0].Value === "Pick_List" && <h4 className="d-flex justify-content-around">{disclosureDetail.disclosureName}</h4>}
                                                    {renderReflexiveQuestion(
                                                        question,
                                                        getReflexiveList(question, disclosureDetail.disclosureId).ReflexiveQuestion,
                                                        disclosureDetail.disclosureId
                                                    )}
                                                </>}
                                            {question.SearchType[0].Value === "Search" && Object.keys(disclosure[question.BaseQuestionId][disclosureIndex]).length > 0 && (showSearchType[question.BaseQuestionId][disclosureIndex]?.value) && disclosureIndex === disclosure[question.BaseQuestionId].length - 1 && <a className="d-inline-block mt-4 text-success" onClick={() => {
                                                setDisclosure({ ...disclosure, [question.BaseQuestionId]: [...disclosure[question.BaseQuestionId], {}] });
                                            }}><u>Add another</u></a>}
                                        </div>
                                    )}

                            </div>
                        );
                    }
                })}

            <div className="w-100 my-4 pb-5 text-center">
                <button
                    className="btn btn-default px-5 d-block mx-auto mb-3"
                    disabled={
                        (loading || !enableNextButton)
                    }
                    onClick={onNextHandler}
                >
                    {loading
                        ? "Processing..."
                        : "Next"}
                </button>
                {(baseQuestion && baseQuestion[0]?.BaseQuestionType === "Heading"
                    ? currentQuestionIndex > 1
                    : currentQuestionIndex > 0) && (
                        <button
                            className="btn btn-link px-5 d-block mx-auto"
                            disabled={loading}
                            onClick={onBackHandler}
                        >
                            {loading ? "Processing..." : "Back"}
                        </button>
                    )}
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    underwriting: state.underwriting.underwriting,
    assurityCount: state.underwriting.assuProgressCount,
});
export default connect(mapStateToProps, {
    commonApiCall,
    setAssurityProgressCount,
})(withRouter(GeneralSection));
