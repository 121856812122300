import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Animated } from "react-animated-css";
import { Link, withRouter } from "react-router-dom";
import NumberFormat from "react-number-format";
import {
  UnderWritingRoutes,
  setProcessStep,
  showErrorToast,
} from "../../../utils";
import { API_URL_MUNICH, API_URL_UNDERWRITING } from "../../../config/configs";
import {
  commonApiCall,
  factFinderQuestion,
  getJwt,
  FACT_FINDER_QUESTION,
  UNDERWRITING_DETAILS,
} from "../../../redux/actions/index";
const FactFinderSecond = ({
  underwriting,
  commonApiCall,
  consumer,
  factFinderQuestionList,
  t,
  ...props
}) => {
  const { applicationId, applicationType, policyType, goalType } = underwriting;
  useEffect(() => window.scrollTo(0, 0), []);
  useEffect(() => {
    async function doSubmit() {
      if (applicationId !== "") {
        const isAuthorized = getJwt() ? true : false;
        var requestParams = {
          applicationId: applicationId,
        };
        let response = await commonApiCall(
          API_URL_MUNICH + `getApplicationQuestionnaire`,
          "post",
          requestParams,
          "",
          isAuthorized
        );
        if (response && response.status.code === 200) {
          if (response.data.data.length > 4) {
            let splitArr = response.data.data.slice(4, 8);
            let err = splitArr[3].options;
            err.map((list, index) => {
              error.errorData.push([{ c_name: "" }, { face_amount: "" }]);
              return true;
            });

            setError({
              ...error,
              error,
            });
            setFactFinderQuestions({
              ...factFinderQuestions,
              data: splitArr,
            });
          }
        } else if (response && response.status.code === 404) {
          props.history.push(UnderWritingRoutes.FACT_FINDER);
        }
      }
    }
    doSubmit();
  }, []);

  const [isProcessing, setIsProcessing] = useState(false);

  const continueNext = async () => {
    const isAuthorized = getJwt() ? true : false;
    let { data } = factFinderQuestions;
    const result = data.filter((word) => word.answer === "");
    if (result.length === 0) {
      if (validateFields()) {
        const splitpath = window.location.pathname.split("/");
        let arrMerrge = [];
        if (factFinderQuestionList.data.data.length <= 4) {
          arrMerrge = factFinderQuestionList.data.data.concat(data);
        } else if (factFinderQuestionList.data.data.length > 4) {
          let arrForShort4 = factFinderQuestionList.data.data.slice(0, 4);
          let arrForShort3 = factFinderQuestionList.data.data.slice(8, 11);
          arrMerrge = arrForShort4.concat(data);
          arrMerrge = arrMerrge.concat(arrForShort3);
        }
        const request = {
          resumeProcess: setProcessStep(splitpath[1], policyType, goalType),
          applicationType: applicationType,
          applicationId: applicationId,
          data: arrMerrge,
          clientUserId: consumer && consumer.id ? consumer.id : "",
        };
        setIsProcessing(true);
        const apiEndPoint = API_URL_MUNICH + "saveApplicationQuestionnaire";
        const response = await commonApiCall(
          apiEndPoint,
          "post",
          request,
          FACT_FINDER_QUESTION,
          isAuthorized
        );
        if (response.status && response.status.code === 200) {
          const request_params = {
            resumeProcess: setProcessStep(splitpath[1], policyType, goalType),
            applicationType: applicationType,
            applicationId: applicationId,
            goalType: goalType,
          };
          const apiEndPoint_underwriting =
            API_URL_UNDERWRITING + "saveUnderWriting";
          await commonApiCall(
            apiEndPoint_underwriting,
            "post",
            request_params,
            UNDERWRITING_DETAILS,
            isAuthorized
          );
          const timer = setTimeout(() => {
            props.history.push(UnderWritingRoutes.FACT_FINDER_3);
          }, 1000);
          return () => clearTimeout(timer);
        } else {
          setIsProcessing(false);
        }
      }
    } else {
      showErrorToast(t("validation_message.answers"));
    }
  };

  const [factFinderQuestions, setFactFinderQuestions] = useState({
    data: [
      {
        question: "have_you_pending_criminal_charges",
        answer: "",
        options: [],
      },
      {
        question: "had_you_driving_violations_last_five_year",
        answer: "",
        options: [],
      },
      {
        question: "marital_status",
        answer: "",
        options: [],
      },
      {
        question: "have_you_existing_pending_life_insurance",
        answer: "",
        options: [
          [
            {
              question: "existing_policy_carrier_name",
              answer: "",
            },
            {
              question: "existing_policy_face_amount",
              answer: "",
            },
          ],
        ],
      },
    ],
  });

  const [error, setError] = useState({
    errorData: [[{ c_name: "" }, { face_amount: "" }]],
  });

  const validateFields = () => {
    const result = data.filter((word) => word.answer === "Yes");
    if (result.length === 0) {
      return true;
    } else {
      if (data[3].answer === "Yes") {
        data[3].options.forEach((list, index) => {
          if (list[0].answer) {
            error.errorData[index][0].c_name = "";
          } else {
            error.errorData[index][0].c_name = t(
              "fact_finder_second.please_enter_carrier_name"
            );
          }
          if (list[1].answer) {
            error.errorData[index][1].face_amount = "";
          } else {
            error.errorData[index][1].face_amount = t(
              "fact_finder_second.please_enter_face_amount"
            );
          }
          return true;
        });
      }
      setError({
        ...error,
        error,
      });
    }

    return getErrorCheck();
  };

  const getErrorCheck = () => {
    let result = error.errorData.filter(
      (list) => list[0].c_name !== "" || list[1].face_amount !== ""
    );
    if (result.length === 0) {
      return true;
    } else {
      return false;
    }
  };

  const addMorePolicyDetails = () => {
    data[3].options.push([
      {
        question: "existing_policy_carrier_name",
        answer: "",
      },
      {
        question: "existing_policy_face_amount",
        answer: "",
      },
    ]);
    error.errorData.push([{ c_name: "" }, { face_amount: "" }]);
    setError({
      ...error,
      error,
    });
    setFactFinderQuestions({
      ...factFinderQuestions,
      data,
    });
  };

  const removePolicyDetails = (index) => {
    data[3].options = data[3].options.filter((list, i) => i !== index);
    error.errorData = error.errorData.filter((list, i) => i !== index);
    setError({
      ...error,
      error,
    });
    setFactFinderQuestions({
      ...factFinderQuestions,
      data,
    });
  };

  let { data } = factFinderQuestions;
  return (
    <React.Fragment>
      <div className="register-steps">
        <div className="row justify-content-center">
          <div className="col-lg-10">
            {/*Begin: Page Title */}
            <div className="title-header">
              <h2>
                {t(
                  "fact_finder_second.tell_us_more_about_your_health_lifestyle"
                )}
              </h2>
              {/* <p className="pb-0">Some fact finder questions to provide best results</p> */}
            </div>
            {/*End: Page Title */}
            {/*Begin: About your health Form Wrapper */}
            <div className="register-steps-wrapper">
              {/*Begin: Animate.css Element */}
              <Animated
                animationIn="fadeInRight"
                animationOut="fadeOutLeft"
                animationInDuration={800}
                animationOutDuration={800}
                isVisible={true}
              >
                <div className="form-wrapper">
                  <form>
                    <div className="register-form-block">
                      <h3 className="form-text card-text mt-0 mb-3 text-center">
                        {t(
                          "fact_finder_second.do_you_have_any_or_pending_misdemeanor_felony_probationary_parole_program_or_criminal_charges"
                        )}
                      </h3>
                      {/*Begin: Form Group */}
                      <div className="form-block radio-block">
                        <div className="form-group">
                          <div className="custom-radio">
                            <label className="m-0" htmlFor="yes">
                              <input
                                type="radio"
                                id="yes"
                                name="tobacco"
                                checked={
                                  data[0].answer === "Yes" ? true : false
                                }
                                onChange={() => {
                                  data[0].answer = "Yes";
                                  setFactFinderQuestions({
                                    ...factFinderQuestions,
                                    data,
                                  });
                                }}
                              />
                              <span></span> {t("fact_finder_second.yes")}
                            </label>
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="custom-radio">
                            <label className="m-0" htmlFor="no">
                              <input
                                type="radio"
                                id="no"
                                name="tobacco"
                                checked={data[0].answer === "No" ? true : false}
                                onChange={() => {
                                  data[0].answer = "No";
                                  setFactFinderQuestions({
                                    ...factFinderQuestions,
                                    data,
                                  });
                                }}
                              />
                              <span></span> {t("fact_finder_second.no")}
                            </label>
                          </div>
                        </div>
                      </div>
                      {/*End: Form Group */}
                    </div>
                  </form>
                </div>
              </Animated>
              {/*End: Animate.css Element */}
            </div>
            {/*End: About your health Form Wrapper */}
          </div>
        </div>
      </div>

      <div className="register-steps register-step3">
        <div className="row justify-content-center">
          <div className="col-lg-10">
            {/*End: Page Title */}
            {/*Begin: About your health Form Wrapper */}
            <div className="register-steps-wrapper">
              {/*Begin: Animate.css Element */}
              <Animated
                animationIn="fadeInRight"
                animationOut="fadeOutLeft"
                animationInDuration={800}
                animationOutDuration={800}
                isVisible={true}
              >
                <div className="form-wrapper">
                  <form>
                    <div className="register-form-block">
                      <h3 className="form-text card-text mt-0 mb-3 text-center">
                        {t(
                          "fact_finder_second.have_you_had_any_driving_moving_violations_in_the_last_5_years"
                        )}
                      </h3>
                      {/*Begin: Form Group */}
                      <div className="form-block radio-block">
                        <div className="form-group">
                          <div className="custom-radio">
                            <label className="m-0" htmlFor="drugs">
                              <input
                                type="radio"
                                id="drugs"
                                name="drugs"
                                checked={
                                  data[1].answer === "Yes" ? true : false
                                }
                                onChange={() => {
                                  data[1].answer = "Yes";
                                  setFactFinderQuestions({
                                    ...factFinderQuestions,
                                    data,
                                  });
                                }}
                              />
                              <span></span> {t("fact_finder_second.yes")}
                            </label>
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="custom-radio">
                            <label className="m-0" htmlFor="nodrugs">
                              <input
                                type="radio"
                                id="nodrugs"
                                name="drugs"
                                checked={data[1].answer === "No" ? true : false}
                                onChange={() => {
                                  data[1].answer = "No";
                                  setFactFinderQuestions({
                                    ...factFinderQuestions,
                                    data,
                                  });
                                }}
                              />
                              <span></span> {t("fact_finder_second.no")}
                            </label>
                          </div>
                        </div>
                      </div>
                      {/*End: Form Group */}
                    </div>
                  </form>
                </div>
              </Animated>
              {/*End: Animate.css Element */}
            </div>
            {/*End: About your health Form Wrapper */}
          </div>
        </div>
      </div>

      <div className="register-steps register-step3">
        <div className="row justify-content-center">
          <div className="col-lg-10">
            {/*End: Page Title */}
            {/*Begin: About your health Form Wrapper */}
            <div className="register-steps-wrapper">
              {/*Begin: Animate.css Element */}
              <Animated
                animationIn="fadeInRight"
                animationOut="fadeOutLeft"
                animationInDuration={800}
                animationOutDuration={800}
                isVisible={true}
              >
                <div className="form-wrapper">
                  <form>
                    <div className="register-form-block">
                      <h3 className="form-text card-text mt-0 mb-3 text-center">
                        {t("fact_finder_second.marital_status")}
                      </h3>
                      {/*Begin: Form Group */}
                      <div className="form-block radio-block">
                        <div className="form-group">
                          <div className="custom-radio">
                            <label className="m-0" htmlFor="married">
                              <input
                                type="radio"
                                id="married"
                                name="marital-status"
                                checked={
                                  data[2].answer === "Married" ? true : false
                                }
                                onChange={() => {
                                  data[2].answer = "Married";
                                  setFactFinderQuestions({
                                    ...factFinderQuestions,
                                    data,
                                  });
                                }}
                              />
                              <span></span> {t("fact_finder_second.married")}
                            </label>
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="custom-radio">
                            <label className="m-0" htmlFor="single">
                              <input
                                type="radio"
                                id="single"
                                name="marital-status"
                                checked={
                                  data[2].answer === "Single" ? true : false
                                }
                                onChange={() => {
                                  data[2].answer = "Single";
                                  setFactFinderQuestions({
                                    ...factFinderQuestions,
                                    data,
                                  });
                                }}
                              />
                              <span></span> {t("fact_finder_second.single")}
                            </label>
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="custom-radio">
                            <label className="m-0" htmlFor="separated">
                              <input
                                type="radio"
                                id="separated"
                                name="marital-status"
                                checked={
                                  data[2].answer === "Separated" ? true : false
                                }
                                onChange={() => {
                                  data[2].answer = "Separated";
                                  setFactFinderQuestions({
                                    ...factFinderQuestions,
                                    data,
                                  });
                                }}
                              />
                              <span></span> {t("fact_finder_second.separated")}
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </Animated>
              {/*End: Animate.css Element */}
            </div>
            {/*End: About your health Form Wrapper */}
          </div>
        </div>
      </div>

      <div className="register-steps register-step3">
        <div className="row justify-content-center">
          <div className="col-lg-10">
            {/*Begin: About your health Form Wrapper */}
            <div className="register-steps-wrapper">
              {/*Begin: Animate.css Element */}
              <Animated
                animationIn="fadeInRight"
                animationOut="fadeOutLeft"
                animationInDuration={800}
                animationOutDuration={800}
                isVisible={true}
              >
                <div className="form-wrapper">
                  <form>
                    <div className="register-form-block">
                      <h3 className="form-text card-text mt-0 mb-3 text-center">
                        {t(
                          "fact_finder_second.do_you_have_any_existing_life_insurance_policies"
                        )}
                      </h3>
                      {/*Begin: Form Group */}
                      <div className="form-block radio-block">
                        <div className="form-group">
                          <div className="custom-radio">
                            <label className="m-0" htmlFor="travelled">
                              <input
                                type="radio"
                                id="travelled"
                                name="travelled"
                                checked={
                                  data[3].answer === "Yes" ? true : false
                                }
                                onChange={() => {
                                  data[3].answer = "Yes";
                                  setFactFinderQuestions({
                                    ...factFinderQuestions,
                                    data,
                                  });
                                }}
                              />
                              <span></span> {t("fact_finder_second.yes")}
                            </label>
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="custom-radio">
                            <label className="m-0" htmlFor="notravelled">
                              <input
                                type="radio"
                                id="notravelled"
                                name="travelled"
                                checked={data[3].answer === "No" ? true : false}
                                onChange={() => {
                                  data[3].answer = "No";

                                  data[3].options.forEach((list, index) => {
                                    data[3].options[index][0].answer = "";
                                    data[3].options[index][1].answer = "";
                                    error.errorData[index][0].c_name = "";
                                    error.errorData[index][1].face_amount = "";
                                    return true;
                                  });
                                  data[3].options.splice(1);

                                  setError({
                                    ...error,
                                    error,
                                  });
                                  setFactFinderQuestions({
                                    ...factFinderQuestions,
                                    data,
                                  });
                                }}
                              />
                              <span></span> {t("fact_finder_second.no")}
                            </label>
                          </div>
                        </div>
                      </div>
                      {/*End: Form Group */}
                      {data[3].answer === "Yes"
                        ? data[3].options &&
                          data[3].options.map((list, index) => {
                            return (
                              <div
                                className="form-block-questions register-step3"
                                key={index}
                              >
                                <div className="questions-wrapper pr-4">
                                  <div className="form-group">
                                    <div className="input-effect">
                                      <input
                                        className="form-control"
                                        placeholder="Carrier name"
                                        name={"carriername" + index}
                                        id={"carriername" + index}
                                        type="text"
                                        value={list[0].answer}
                                        onChange={(e) => {
                                          data[3].options[index][0].answer =
                                            e.target.value;
                                          setFactFinderQuestions({
                                            ...factFinderQuestions,
                                            data,
                                          });
                                          error.errorData[index][0].c_name = "";
                                          setError({
                                            ...error,
                                            error,
                                          });
                                        }}
                                      />
                                      <label htmlFor="carriername">
                                        {t("fact_finder_second.carrier_name")}
                                      </label>
                                      <span className="text-danger ">
                                        {error.errorData[index][0].c_name}
                                      </span>
                                    </div>
                                  </div>
                                  <div className="form-group">
                                    <div className="input-effect">
                                      <NumberFormat
                                        value={list[1].answer}
                                        thousandSeparator={true}
                                        className="form-control"
                                        name="face-amount"
                                        inputmode="numeric"
                                        placeholder="Face amount"
                                        onValueChange={(e) => {
                                          data[3].options[index][1].answer =
                                            e.formattedValue.replace("-", "");
                                          setFactFinderQuestions({
                                            ...factFinderQuestions,
                                            data,
                                          });
                                          error.errorData[
                                            index
                                          ][1].face_amount = "";
                                          setError({
                                            ...error,
                                            error,
                                          });
                                        }}
                                      />

                                      <label htmlFor="face-amount">
                                        {t("fact_finder_second.face_amount")}
                                      </label>
                                      <span className="text-danger ">
                                        {error.errorData[index][1].face_amount}
                                      </span>
                                    </div>
                                  </div>
                                  <div className="add-remove">
                                    {index === 0 ? (
                                      ""
                                    ) : (
                                      <button
                                        className="btn-link"
                                        type="button"
                                        onClick={() =>
                                          removePolicyDetails(index)
                                        }
                                      >
                                        <span className="material-icons">
                                          delete
                                        </span>
                                      </button>
                                    )}
                                    {index === data[3].options.length - 1 ? (
                                      <button
                                        className="btn-link"
                                        type="button"
                                        onClick={() => addMorePolicyDetails()}
                                      >
                                        <span className="material-icons">
                                          add
                                        </span>
                                      </button>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                              </div>
                            );
                          })
                        : null}
                    </div>
                  </form>
                </div>
              </Animated>
              {/*End: Animate.css Element */}
            </div>
            {/*End: About your health Form Wrapper */}
          </div>
        </div>
        <div className="text-center mt-5">
          {isProcessing ? (
            <Link className="btn btn-primary" to="#">
              {t("fact_finder_second.processing")}
            </Link>
          ) : (
            <Link
              className="btn btn-primary"
              to="#"
              onClick={() => continueNext()}
            >
              {t("next")}
            </Link>
          )}
        </div>
        <div className="text-center mt-5">
          <Link className="btn btn-link" to={UnderWritingRoutes.FACT_FINDER}>
            {t("back")}
          </Link>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  underwriting: state.underwriting.underwriting,
  consumer: state.consumer.consumerdetails,
  question_answers: state.underwriting,
  factFinderQuestionList: state.underwriting.factFinderQuestion,
});

export default connect(mapStateToProps, {
  commonApiCall,
  factFinderQuestion,
})(withRouter(FactFinderSecond));
