import React, { useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { PAYMENT_URL } from "../config/configs";
import { commonApiCall, getJwt } from "../redux/actions";
import { showErrorToast } from "../utils";

const PaymentGateway = ({ t, commonApiCall }) => {
  const createWalletSession = async () => {
    try {
      const isAuthorized = getJwt() ? true : false;
      const apiEndPoint = PAYMENT_URL + "CreateWalletSession";
      const response = await commonApiCall(
        apiEndPoint,
        "post",
        {},
        null,
        isAuthorized
      );
      if (response.status.code === 200) {
      } else {
        showErrorToast(response.status.message);
      }
    } catch (err) {
      showErrorToast("something went wrong");
    }
  };

  useEffect(() => {
    createWalletSession();
  }, []);
  return (
    <div className="card shadow">
      <div className="container-fluid">
        <h2>Complete Payment</h2>
        <p>You can now be more efficient with shorter sales cycles!</p>
        <h5 className="text-left mt-5">Credit Card Information</h5>
        <div className="card shadow">
          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <div className="input-effect with-icon">
                  <input
                    className="form-control"
                    placeholder="Card Holder Name"
                    name="cardName"
                    id="cardName"
                    type="text"
                  />
                  <label htmlFor="cardName">{t("cardDetails.cardName")}</label>
                  <em className="input-icon"></em>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <div className="input-effect with-icon">
                  <input
                    className="form-control"
                    placeholder="Card Number"
                    name="cardNumber"
                    id="cardNumber"
                    type="number"
                  />
                  <label htmlFor="cardNumber">
                    {t("cardDetails.cardNumber")}
                  </label>
                  <em className="input-icon"></em>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <div className="input-effect with-icon">
                  <input
                    className="form-control"
                    placeholder="Expiration Date"
                    name="expiryDate"
                    id="expiryDate"
                    type="number"
                  />
                  <label htmlFor="expiryDate">{t("cardDetails.expiry")}</label>
                  <em className="input-icon"></em>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <div className="input-effect with-icon">
                  <input
                    className="form-control"
                    placeholder="CVC Number"
                    name="cvcNumber"
                    id="cvcNumber"
                    type="number"
                  />
                  <label htmlFor="expiryDate">
                    {t("cardDetails.cvcNumber")}
                  </label>
                  <em className="input-icon"></em>
                </div>
              </div>
            </div>
          </div>
        </div>
        <h5 className="text-left mt-5">Billing Address</h5>
        <div className="card shadow">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <div className="input-effect with-icon">
                    <input
                      className="form-control"
                      placeholder="Billing Address"
                      name="billingAddress"
                      id="billingAddress"
                      type="text"
                    />
                    <label htmlFor="billingAddress">
                      {t("cardDetails.billing")}
                    </label>
                    <em className="input-icon"></em>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <div className="input-effect with-icon">
                    <input
                      className="form-control"
                      placeholder="City"
                      name="city"
                      id="city"
                      type="text"
                    />
                    <label htmlFor="city">{t("cardDetails.city")}</label>
                    <em className="input-icon"></em>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <div className="input-effect with-icon">
                    <input
                      className="form-control"
                      placeholder="State"
                      name="state"
                      id="state"
                      type="text"
                    />
                    <label htmlFor="state">{t("cardDetails.state")}</label>
                    <em className="input-icon"></em>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <div className="input-effect with-icon">
                    <input
                      className="form-control"
                      placeholder="Zip Code"
                      name="zipcode"
                      id="zipcode"
                      type="number"
                    />
                    <label htmlFor="zipcode">{t("cardDetails.zipcode")}</label>
                    <em className="input-icon"></em>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  consumer: state.consumer.consumerdetails,
  underwriting: state.underwriting.underwriting,
});

export default connect(mapStateToProps, { commonApiCall })(
  withRouter(PaymentGateway)
);
