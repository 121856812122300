import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import _ from "lodash";
import {
    commonApiCall,
    getJwt,
    setAssurityProgressCount,
    UNDERWRITING_DETAILS,

} from "../../../../redux/actions";
import {
    AssurityBaseBoolean,
    AssurityTextField,
    ShowDisclosurePickList,
    showErrorToast,
    UnderWritingRoutes,
    getState,
} from "../../../../utils";
import { API_URL_UNDERWRITING } from "../../../../config/configs";
import AssurityDisclosurePhysician from "../assurity-disclosure-physician";
import moment from 'moment';

const GeneralSection = ({
    t,
    baseQuestion = null,
    commonApiCall,
    underwriting,
    onAnswered,
    assurityCount,
    setAssurityProgressCount,
    onGetUnderWritingCase,
    ...props
}) => {
    const [baseAnswer, setBaseAnswer] = useState([]);
    const [reflexiveQuestionList, setReflexiveQuestionList] = useState([]);
    const [reflexiveArr, setReflexiveArr] = useState([]);
    const [loading, setLoading] = useState(false);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [currentQuestionHeader, setCurrentQuestionHeader] = useState(null);
    const [disclosure, setDisclosure] = useState({});
    const [questionLoading, setQuestionLoading] = useState({
        questionId: null,
        loading: false,
    });
    const [enableNextButton, setEnableNextButton] = useState(false);
    const [physicianQuestion, setPhysicianQuestion] = useState({});
    const [showSearchType, setSearchType] = useState({});
    const [showPhysicianQuestion, setShowPhysicianQuestion] = useState({});

    const initialFormData = {
        first_name: "",
        last_name: "",
        middle_name: "",
        suffix: "",
        address: "",
        address_2: "",
        city: "",
        country: "",
        state: "",
        zipCode: "",
        email: "",
        mobile: "",
        fax: "",
        speciality: "",
        lastVisitDate: "",
        lastVisitReason: "",
        healthQuestion: "",
        lastVisitReasonDescription: "",
        errors: {},
    };
    const [formData, setFormData] = useState({});

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [currentQuestionIndex]);

    const screen = new URLSearchParams(props.location.search).get("underwriting");
    const getRoute = () => {
        if (screen) {
            switch (screen) {
                case "HEALTH_SECTION_1":
                    return "HEALTH_SECTION_2";
                case "HEALTH_SECTION_2":
                    return "HEALTH_SECTION_3";
                case "HEALTH_SECTION_3":
                    return "HEALTH_SECTION_4";
                case "HEALTH_SECTION_4":
                    return "COVID_QUESTIONS";

                default:
                    return "";
            }
        }
    };

    const getPreviousRoute = () => {
        if (screen) {
            switch (screen) {
                case "HEALTH_SECTION_2":
                    return "HEALTH_SECTION_1";
                case "HEALTH_SECTION_3":
                    return "HEALTH_SECTION_2";
                case "HEALTH_SECTION_4":
                    return "HEALTH_SECTION_3";
                default:
                    return "";
            }
        }
    };

    const validateField = (disclosureId) => {
        let tempFormData = { ...formData };
        const validateArray = formData[disclosureId].map((formItem, index) => {
            let {
                first_name,
                last_name,
                address,
                city,
                state,
                country,
                zipCode,
                mobile,
                lastVisitDate,
                lastVisitReason,
                healthQuestion,
                lastVisitReasonDescription,
            } = formItem;

            let error = {};
            let validate = true;

            if (healthQuestion !== "") {
                if (healthQuestion === "yes") {
                    if (first_name === "") {
                        error.first_name = t("validation_message.fName");
                        validate = false;
                    }
                    if (last_name === "") {
                        error.last_name = t("validation_message.lName");
                        validate = false;
                    }
                    if (address === "") {
                        error.address = t("validation_message.address");
                        validate = false;
                    }
                    if (city === "") {
                        error.city = t("validation_message.city");
                        validate = false;
                    }
                    if (country === "") {
                        error.country = t("validation_message.country");
                        validate = false;
                    }
                    if (state === "" && (country === "USA" || country === "CANADA")) {
                        error.state = t("validation_message.state");
                        validate = false;
                    }
                    if (zipCode.trim() === "" && !getState(zipCode)) {
                        error.zipCode = t("validation_message.PIN");
                        validate = false;
                    }
                    if (zipCode.trim() !== "" && (zipCode.length < 5 || zipCode.length > 10)) {
                        error.zipCode = t("validation_message.validPIN");
                        validate = false;
                    }
                    if (mobile === "") {
                        error.mobile = t("validation_message.mobile");
                        validate = false;
                    }
                    if (lastVisitDate === "") {
                        error.lastVisitDate = t("validation_message.lastVisitDate");
                        validate = false;
                    }
                    if (lastVisitReason === "") {
                        error.lastVisitReason = t("validation_message.lastVisitReason");
                        validate = false;
                    }
                    if (
                        lastVisitReasonDescription === "" &&
                        lastVisitReason?.value === "Other"
                    ) {
                        error.lastVisitReasonDescription = t(
                            "validation_message.lastVisitReasonDescription"
                        );
                        validate = false;
                    }
                } else if (healthQuestion === "no") {
                    validate = true;
                }
            } else {
                error.healthQuestion = "this field is required";
                validate = false;
            }

            formData[disclosureId][index] = { ...formData[disclosureId][index], errors: error };

            return validate;
        });
        setFormData({ ...tempFormData });
        return validateArray.includes(false) ? false : true;

    };

    const handleChange = (event, disclosureId, index) => {
        let tempFormData = { ...formData };
        formData[disclosureId][index] = { ...formData[disclosureId][index], [event.target.name]: event.target.value, };
        setFormData({
            ...tempFormData
        });
        setEnableNextButton(true);
    };

    /*   const updatePhysicianDisclosureHeath = async (formData, disclosureId) => {
          setLoading(true);
          try {
            
  
              const response = await Promise.all(apiCall);
              if (response.every((res) => res.status.code === 200)) {
                  getDisclosureDetails(baseQuestion[currentQuestionIndex]);
              }
              else {
                  response.map(res => {
                      if (res.status.code !== 200) {
                          showErrorToast(res.status.message);
                      }
                  });
              }
          } catch (err) {
              showErrorToast(err);
          }
          setLoading(false);
      }; */



    // Get the disclosure details for the application
    const getDisclosureDetails = async (question) => {
        try {
            const apiEndPoint =
                API_URL_UNDERWRITING +
                "disclosureDetails?applicationId=" +
                underwriting?.applicationId;
            const response = await commonApiCall(apiEndPoint, "get", {}, "");
            if (response.status.code === 200) {
                const list = JSON.parse(response.data);
                const disclosureId = disclosure[question.BaseQuestionId].map(item => item.disclosureId);
                //check whether we have same base question id in disclosure details with array of base question ids which matched base question ids from base question array of objects with one key as base question iDs.

                let status = null;
                disclosureId.map((id, index) => {
                    const disclosureItem = list.DisclosureDetails.find(
                        (item) =>
                            item.BaseQuestionIds.includes(question.BaseQuestionId) &&
                            item.DisclosureId === id
                    );
                    if (disclosureItem && disclosureItem?.DisclosureStatus === "In_Progress" && disclosureItem?.PhysicianDisclosureRequired) {
                        setEnableNextButton(false);
                        status = "In_Progress";
                    }
                    else {
                        status = "Confirmed";
                    }
                });

                if (status === "Confirmed") {
                    moveToNextQuestion();
                }

            }
        } catch (err) {
            showErrorToast(err);
        }
    };

    const updateBaseQuestion = async (obj) => {
        setLoading(true);
        try {
            const request = {
                baseQuestionAnswers: [
                    {
                        baseQuestionId: obj.BaseQuestionId,
                        baseQuestionAnswerValue: obj.answerValue,
                    },
                ],
            };

            const isAuthorized = getJwt() ? true : false;
            const apiEndPoint =
                API_URL_UNDERWRITING +
                "updatebaseQuestions?applicationId=" +
                underwriting?.applicationId;

            const response = await commonApiCall(
                apiEndPoint,
                "put",
                request,
                null,
                isAuthorized
            );
            if (response.status.code !== 200) {
                showErrorToast(response.status.message);
            }
        } catch (err) {
            showErrorToast(err);
        }
        setLoading(false);
    };

    const updateReflexiveQuestion =
        async (question, disclosure, reflexiveAns = []) => {
            setLoading(true);
            const disclosureArray = disclosure[question.BaseQuestionId].filter(element => Object.keys(element).length > 0);
            try {
                const apiCall = [];
                disclosureArray.forEach((element) => {
                    let obj;
                    obj = {
                        baseQuestionId: question.BaseQuestionId,
                        reflexiveQuestionAnswers: reflexiveAns.filter(item => item.BaseQuestionId === question.BaseQuestionId && item.DisclosureId === element.disclosureId).map(
                            ({ BaseQuestionId, DisclosureId, ...rest }) => ({ ...rest })
                        ),
                    };
                    const isAuthorized = getJwt() ? true : false;
                    const apiEndPoint =
                        API_URL_UNDERWRITING +
                        "updateReflexiveQuestions?applicationId=" +
                        underwriting?.applicationId +
                        "&disclosureId=" +
                        element.disclosureId;
                    if (obj.reflexiveQuestionAnswers.length > 0) {
                        apiCall.push(commonApiCall(
                            apiEndPoint,
                            "put",
                            obj,
                            null,
                            isAuthorized
                        ));
                    }

                    //physician call
                    apiCall.push(...formData[element.disclosureId].map((formItem, index) => {
                        let obj = {};
                        if (formItem.healthQuestion === "yes") {
                            obj = {
                                person: {
                                    name: {
                                        first: formItem.first_name,
                                        last: formItem.last_name,
                                        middle: formItem.middle_name,
                                        suffix: formItem.suffix,
                                    },
                                },
                                specialty: formItem.speciality,
                                address: {
                                    city: formItem.city,
                                    country: formItem.country?.value,
                                    line1: formItem.address,
                                    line2: formItem.address_2,
                                    stateAbbreviation: formItem.state?.value,
                                    zipCode: formItem.zipCode,
                                },
                                phone: {
                                    areaCode: formItem.mobile.split(" ")[0].replace(/[()]/g, ""),
                                    number: formItem.mobile.split(" ")[1].replace("-", ""),
                                },
                                fax: {
                                    areaCode:
                                        formItem.fax !== ""
                                            ? formItem.fax.split(" ")[0].replace(/[()]/g, "")
                                            : null,
                                    number:
                                        formItem.fax !== ""
                                            ? formItem.fax.split(" ")[1].replace("-", "")
                                            : null,
                                },
                                hasMostRecords: true,
                                lastVisitDate: moment(formItem.lastVisitDate).format(),
                                lastVisitReason: formItem.lastVisitReason?.value,
                                lastVisitReasonDescription: formItem.lastVisitReasonDescription,
                            };
                            if (formItem.email !== "") {
                                obj.person.emailAddress = formItem.email;
                            }
                        }

                        const isAuthorized = getJwt() ? true : false;
                        const apiEndPoint =
                            API_URL_UNDERWRITING +
                            "physician?applicationId=" +
                            underwriting?.applicationId +
                            "&disclosureId=" +
                            element.disclosureId;
                        const physicianBody = {
                            baseQuestionId: baseQuestion[currentQuestionIndex]?.BaseQuestionId,
                            physician: Object.keys(obj).length > 0 ? { ...obj } : null,
                        };
                        return commonApiCall(
                            apiEndPoint,
                            "post",
                            physicianBody,
                            null,
                            isAuthorized
                        );

                    }));
                });
                const response = await Promise.all(apiCall);

                if (response.every((res) => res.status.code === 200)) {
                    getDisclosureDetails(question);
                }
                else {
                    response.map(res => {
                        if (res.status.code !== 200) {
                            showErrorToast(res.status.message);
                        }
                    });
                }
            } catch (err) {
                console.log(err);
                showErrorToast(err);
            }
            setLoading(false);
        };

    const handleReflexiveList =
        (list, baseId) => {
            let tempShowPhysician = { ...showPhysicianQuestion };
            let tempPhysicianQuestion = { ...physicianQuestion };
            let tempFormData = { ...formData };
            let tempReflexive = Array.isArray(list) ? [...reflexiveQuestionList.filter((item) => item.BaseQuestionId !== baseId)] : [...reflexiveQuestionList];
            if (!Array.isArray(list)) {
                let tempIndex = tempReflexive.findIndex(
                    (x) => x.BaseQuestionId === baseId && x.DisclosureId === list.DecisionTree.DisclosureId
                );

                if (tempIndex > -1) {
                    tempReflexive[tempIndex].ReflexiveQuestion = list.DecisionTree.ReflexiveQuestion;
                    tempReflexive[tempIndex].DisclosureId = list.DecisionTree.DisclosureId;
                } else {
                    tempReflexive.push({
                        BaseQuestionId: baseId,
                        DisclosureId: list.DecisionTree.DisclosureId,
                        ReflexiveQuestion: list.DecisionTree.ReflexiveQuestion,
                    });
                }
                if (list.DecisionTree.ReflexiveQuestion === null) {
                    if (!tempFormData[list.DecisionTree.DisclosureId])
                        tempFormData[list.DecisionTree.DisclosureId] = [{ ...initialFormData }];
                    tempShowPhysician[baseId] = { ...tempShowPhysician[baseId], [list.DecisionTree.DisclosureId]: true };
                    tempPhysicianQuestion[list.DecisionTree.DisclosureId] = true;
                    setEnableNextButton(true);
                }
            }
            else {

                list.map((reflexiveList) => {
                    tempReflexive.push({
                        BaseQuestionId: baseId,
                        DisclosureId: reflexiveList.DecisionTree.DisclosureId,
                        ReflexiveQuestion: reflexiveList.DecisionTree.ReflexiveQuestion,
                    });
                    if (reflexiveList.DecisionTree.ReflexiveQuestion === null) {
                        if (!tempFormData[list.DecisionTree.DisclosureId])
                            tempFormData[list.DecisionTree.DisclosureId] = [{ ...initialFormData }];
                        tempShowPhysician[baseId] = { ...tempShowPhysician[baseId], [list.DecisionTree.DisclosureId]: true };
                        tempPhysicianQuestion[list.DecisionTree.DisclosureId] = true;
                        setEnableNextButton(true);
                    }
                })
            }
            setPhysicianQuestion({ ...tempPhysicianQuestion });
            setShowPhysicianQuestion({ ...tempShowPhysician });
            setFormData({ ...tempFormData });
            setQuestionLoading({
                questionId: baseId,
                loading: { ...questionLoading.loading, [Array.isArray(list) ? list[0].DecisionTree.DisclosureId : list.DecisionTree.DisclosureId]: false },
            });
            setReflexiveQuestionList(tempReflexive);

        }

    const getReflexiveQuestions = async (baseId, disclosureIds) => {
        const loadingDisclosure = Array.isArray(disclosureIds) ? disclosureIds[0] : disclosureIds;
        setQuestionLoading({
            questionId: baseId,
            loading: { ...questionLoading.loading, [loadingDisclosure]: true },
        });
        try {
            //  if (dn bisclosure.includes(",")) disclosure = encodeURIComponent(disclosure);
            if (Array.isArray(disclosureIds)) {
                const reflexiveList = [];
                const apiCall = disclosureIds.map((id, index) => {
                    const apiEndPoint =
                        API_URL_UNDERWRITING +
                        "assurityReflexiveQuestions?applicationId=" +
                        underwriting?.applicationId +
                        "&disclosureId=" +
                        id;

                    return commonApiCall(apiEndPoint, "get", {}, "");
                });

                const response = await Promise.all(apiCall);
                response.map(item => {
                    reflexiveList.push(JSON.parse(item.data));
                });
                handleReflexiveList(
                    reflexiveList,
                    baseId
                );
            }
            else {
                const apiEndPoint =
                    API_URL_UNDERWRITING +
                    "assurityReflexiveQuestions?applicationId=" +
                    underwriting?.applicationId +
                    "&disclosureId=" +
                    disclosureIds;

                const response = await commonApiCall(apiEndPoint, "get", {}, "");

                if (response.status.code === 200) {
                    // setOptionList(re)
                    const list = JSON.parse(response.data);
                    handleReflexiveList(
                        list,
                        baseId
                    );
                }
            }
        } catch (err) {
            console.log(err);
            showErrorToast(err);
        }

    };

    const getReflexiveList = (question, disclosureId) => {
        let itemIndex = reflexiveQuestionList.filter(
            (x) => x.BaseQuestionId === question.BaseQuestionId && x.DisclosureId === disclosureId
        );
        if (itemIndex.length > 0) {
            return itemIndex[0];
        }
        return null;
    };


    const getQuestionFromBranch = (reflex, value) => {
        if (reflex.ReflexiveQuestionType === "Text") {
            if (reflex.Branches.length > 1) {
                const currentBranch =
                    reflex.Branches && value
                        ? reflex.Branches.filter(
                            (x) => x.BranchValue.toLowerCase() == value.toLowerCase()
                        )
                        : null;
                return currentBranch && currentBranch.length > 0
                    ? currentBranch[0]
                    : null;
            } else {
                return reflex.Branches.length > 0 && value !== "" && value
                    ? reflex.Branches[0]
                    : null;
            }
        } else if (reflex.ReflexiveQuestionType === "Numeric") {
            let currentBranch = null;
            if (reflex.Branches && value && reflex.Branches.length > 0) {
                for (let i = 0; i < reflex.Branches.length; i++) {
                    if (parseInt(value) >= parseInt(reflex.Branches[i].BranchValue)) {
                        currentBranch = reflex.Branches[i];
                    } else {
                        break;
                    }
                }
            }
            return currentBranch ? currentBranch : null;
        } else {
            const currentBranch =
                reflex.Branches && value
                    ? reflex.Branches.filter((x) => x.BranchValue == value)
                    : null;

            return currentBranch && currentBranch.length > 0
                ? currentBranch[0]
                : null;
        }
    };

    const renderReflexiveQuestion = (question, reflex, disclosureId = null) => {
        const onValueChange = (id, e, branch = null) => {
            let tempReflexiveArr = [...reflexiveArr];
            let tempReflexiveForDisclosure = tempReflexiveArr.filter((x) => x.BaseQuestionId === question.BaseQuestionId && x.DisclosureId === disclosureId);
            let reflexIndex = tempReflexiveForDisclosure.findIndex(
                (x) => x.reflexiveQuestionId === id.ReflexiveQuestionId
            );
            if (reflexIndex > -1) {
                tempReflexiveForDisclosure[reflexIndex].reflexiveQuestionAnswerValue =
                    e.target.value;
                tempReflexiveForDisclosure.splice(reflexIndex + 1);
            } else {
                const obj = {
                    reflexiveQuestionId: id.ReflexiveQuestionId,
                    reflexiveQuestionAnswerValue: e.target.value,
                    BaseQuestionId: question.BaseQuestionId,
                    DisclosureId: disclosureId,
                };
                tempReflexiveForDisclosure.push(obj);
            }

            setReflexiveArr([...tempReflexiveForDisclosure, ...tempReflexiveArr.filter((x) => x.BaseQuestionId === question.BaseQuestionId && x.DisclosureId !== disclosureId)]);
            if (branch === null || branch?.ReflexiveQuestion === null) {
                if (!formData[disclosureId])
                    setFormData({ ...formData, [disclosureId]: [{ ...initialFormData }] });
                setShowPhysicianQuestion({ ...showPhysicianQuestion, [question.BaseQuestionId]: { ...showPhysicianQuestion[question.BaseQuestionId], [disclosureId]: true } });
                setPhysicianQuestion({ ...physicianQuestion, [disclosureId]: true });
                setEnableNextButton(true);
            }
        };
        const getCurrentSelectedValue = (item) => {
            const filterReflex = reflexiveArr.filter(
                (x) => x.reflexiveQuestionId == item.ReflexiveQuestionId && x.BaseQuestionId === question.BaseQuestionId && x.DisclosureId === disclosureId
            );
            if (filterReflex.length > 0) {
                return filterReflex[0].reflexiveQuestionAnswerValue;
            }
            return "";
        };

        return (
            <>
                <div
                    className={
                        `sbli-child-question reflexive_child_question_${disclosureId} mt-4 question_` + reflex.ReflexiveQuestionId
                    }
                >
                    <h4 className="mb-4 parent_question_head">
                        {reflex.ReflexiveQuestionText}
                    </h4>
                    {(reflex.ReflexiveQuestionType === "Date" ||
                        reflex.ReflexiveQuestionType === "Boolean" ||
                        reflex.ReflexiveQuestionType === "List") && (
                            <span className="custom-radio mt-3 d-block">
                                {reflex.Branches.map((branch, index) => {
                                    return (
                                        <React.Fragment>
                                            <label
                                                className="m-0 mb-3"
                                                htmlFor={reflex.ReflexiveQuestionId + "_" + disclosureId + "ab" + index}
                                            >
                                                <input
                                                    type="radio"
                                                    name={reflex.ReflexiveQuestionId + "_" + disclosureId}
                                                    id={reflex.ReflexiveQuestionId + "_" + disclosureId + "ab" + index}
                                                    value={branch.BranchValue}
                                                    checked={
                                                        getCurrentSelectedValue(reflex) === branch.BranchValue
                                                    }
                                                    onChange={(e) => onValueChange(reflex, e, branch)}
                                                />
                                                <span></span> {branch.BranchText}
                                            </label>

                                        </React.Fragment>
                                    );
                                })}
                                {getQuestionFromBranch(reflex, getCurrentSelectedValue(reflex)) &&
                                    getQuestionFromBranch(reflex, getCurrentSelectedValue(reflex))
                                        ?.ReflexiveQuestion
                                    ? renderReflexiveQuestion(
                                        question,
                                        getQuestionFromBranch(reflex, getCurrentSelectedValue(reflex))
                                            ?.ReflexiveQuestion,
                                        disclosureId
                                    )
                                    : null}

                            </span>
                        )}
                    {reflex.ReflexiveQuestionType === "Text" && (
                        <React.Fragment>
                            <div className="form-group ">
                                <input
                                    className="form-control form-control-search"
                                    onChange={(e) =>
                                        onValueChange(
                                            reflex,
                                            e,
                                            getQuestionFromBranch(reflex, e.target.value)
                                        )
                                    }
                                    type="text"
                                    value={getCurrentSelectedValue(reflex)}
                                    placeholder="Enter Text"
                                />
                            </div>
                            {getQuestionFromBranch(reflex, getCurrentSelectedValue(reflex)) &&
                                getQuestionFromBranch(reflex, getCurrentSelectedValue(reflex))
                                    ?.ReflexiveQuestion
                                ? renderReflexiveQuestion(
                                    question,
                                    getQuestionFromBranch(reflex, getCurrentSelectedValue(reflex))
                                        ?.ReflexiveQuestion,
                                    disclosureId
                                )
                                : null}


                        </React.Fragment>
                    )}
                    {
                        reflex.ReflexiveQuestionType === "Numeric" && (
                            <React.Fragment>
                                <div className="form-group ">
                                    <input
                                        className="form-control form-control-search"
                                        onChange={(e) =>
                                            onValueChange(
                                                reflex,
                                                e,
                                                getQuestionFromBranch(reflex, e.target.value)
                                            )
                                        }
                                        type="number"
                                        value={getCurrentSelectedValue(reflex)}
                                        placeholder="Enter numeric data"
                                    />
                                </div>
                                {getQuestionFromBranch(reflex, getCurrentSelectedValue(reflex)) &&
                                    getQuestionFromBranch(reflex, getCurrentSelectedValue(reflex))
                                        ?.ReflexiveQuestion
                                    ? renderReflexiveQuestion(
                                        question,
                                        getQuestionFromBranch(reflex, getCurrentSelectedValue(reflex))
                                            ?.ReflexiveQuestion,
                                        disclosureId
                                    )
                                    : null}


                            </React.Fragment>
                        )}

                </div>
            </>
        );
    };

    const baseBooleanTrigger = (question, value) => {
        if (question.BaseQuestionDisclosures.length > 0) {
            if (question.BaseQuestionDisclosures[0].TriggerOn === value) {
                setDisclosure({ ...disclosure, [question.BaseQuestionId]: [{ disclosureId: question.BaseQuestionDisclosures[0].DisclosureId, disclosureName: question.BaseQuestionDisclosures[0].DisclosureName }] });
                getReflexiveQuestions(
                    question.BaseQuestionId,
                    question.BaseQuestionDisclosures[0].DisclosureId
                );
            } else {
                let tempArr = [...reflexiveQuestionList];
                let r_item = reflexiveQuestionList.findIndex(
                    (x) => x.BaseQuestionId === question.BaseQuestionId
                );
                if (r_item > -1) {
                    tempArr.splice(r_item, 1);
                }

                setReflexiveQuestionList(tempArr);
                setReflexiveArr(
                    reflexiveArr.filter(
                        (x) => x.BaseQuestionId !== question.BaseQuestionId
                    )
                );
            }
        }
    };

    const handleBaseAnswer = (question, value, type) => {
        if (value === "true" && (question.SearchType[0].Value !== "Pick_List" && question.SearchType[0].Value !== "Search")) {
            setEnableNextButton(true);
        }
        if (value === "false") {
            setEnableNextButton(true);
        }
        let tempReflexiveQuestionList = [...reflexiveQuestionList];
        let tempReflexiveArr = [...reflexiveArr];
        let tempBaseAnswer = [...baseAnswer];
        let tempDisclosure = { ...disclosure };
        let tempSearchType = { ...showSearchType };
        tempReflexiveQuestionList = tempReflexiveQuestionList.filter(item => item.BaseQuestionId !== question.BaseQuestionId);
        tempReflexiveArr = tempReflexiveArr.filter(item => item.BaseQuestionId !== question.BaseQuestionId);
        tempDisclosure[question.BaseQuestionId] = [{}];
        tempSearchType[question.BaseQuestionId] = { 0: {} };
        let itemIndex = baseAnswer.findIndex(
            (x) => x.BaseQuestionId === question.BaseQuestionId
        );
        if (itemIndex > -1) {
            tempBaseAnswer[itemIndex][type] = value;
            if (type === "answerValue") {
                updateBaseQuestion(tempBaseAnswer[itemIndex]);
            }
        } else {
            const obj = {
                BaseQuestionId: question.BaseQuestionId,
                [type]: value,
            };
            if (type === "answerValue") {
                updateBaseQuestion(obj);
            }
            tempBaseAnswer.push(obj);
        }
        setDisclosure({ ...tempDisclosure });
        setReflexiveQuestionList([...tempReflexiveQuestionList]);
        setReflexiveArr([...tempReflexiveArr]);
        setSearchType(tempSearchType);
        setBaseAnswer(tempBaseAnswer);

    };

    const handleDisclosurePickList = (question, value) => {
        let tempDisclosure = { ...disclosure };
        if (value) {
            const disclosureIds = value.map(item => item.value);
            getReflexiveQuestions(
                question.BaseQuestionId,
                disclosureIds
            );
            tempDisclosure = { ...disclosure, [question.BaseQuestionId]: [...value.map(item => ({ disclosureId: item.value, disclosureName: item.label }))] }
            setDisclosure({ ...tempDisclosure });
        }
        else {
            let tempReflexiveQuestionList = [...reflexiveQuestionList];
            let tempReflexiveArr = [...reflexiveArr];
            let tempDisclosure = { ...disclosure }
            tempReflexiveQuestionList = tempReflexiveQuestionList.filter(item => item.BaseQuestionId !== question.BaseQuestionId);
            tempReflexiveArr = tempReflexiveArr.filter(item => item.BaseQuestionId !== question.BaseQuestionId);
            tempDisclosure[question.BaseQuestionId] = [{}];
            setReflexiveArr(tempReflexiveArr);
            setReflexiveQuestionList(tempReflexiveQuestionList);
            setDisclosure({ ...tempDisclosure });
        }
    }

    const getPickListClosureId = (question) => {
        return baseAnswer.filter(
            (x) => x.BaseQuestionId === question.BaseQuestionId
        ).length > 0
            ? baseAnswer.filter(
                (x) => x.BaseQuestionId === question.BaseQuestionId
            )[0].disclosureId
            : "";
    };

    const getBaseAnswer = (question) => {
        if (
            baseAnswer.filter((x) => x.BaseQuestionId === question.BaseQuestionId)
                .length > 0
        ) {
            return baseAnswer.filter(
                (x) => x.BaseQuestionId === question.BaseQuestionId
            )[0].answerValue;
        }
        return "";
    };

    const validateQuestions = (disclosureId) => {
        let baseErrors = {};
        let reflexiveErrors = {};
        const baseQuestions = document.getElementsByClassName("question");

        if (baseQuestions.length > 0) {
            for (let index = 0; index < baseQuestions.length; index++) {
                const element = baseQuestions[index];
                let classList = element.classList[
                    Object.keys(element.classList)[2]
                ].replace("question_", "");

                if (baseAnswer.findIndex((y) => y.BaseQuestionId == classList) < 0) {
                    element.classList.add("validate-question-parent");
                    baseErrors = {
                        ...baseErrors,
                        [classList]: "required",
                    };
                    // baseErrors[questionId] = "required";
                } else {
                    element.classList.remove("validate-question-parent");
                }

                let childReflexiveList = element.getElementsByClassName(
                    `reflexive_child_question_${disclosureId}`
                );
                if (childReflexiveList.length > 0) {
                    for (let index = 0; index < childReflexiveList.length; index++) {
                        const childElement = childReflexiveList[index];
                        let childClassList = childElement.classList[
                            3
                        ].replace("question_", "");
                        if (
                            reflexiveArr.findIndex(
                                (y) => y.reflexiveQuestionId == childClassList
                            ) < 0
                        ) {
                            childElement.classList.add("validate-question-child");
                            reflexiveErrors = {
                                ...reflexiveErrors,
                                [childClassList]: "required",
                            };
                        } else {
                            childElement.classList.remove("validate-question-child");
                        }
                    }
                }
            }
        }

        return Object.keys(baseErrors).length === 0 &&
            Object.keys(reflexiveErrors).length === 0
            ? true
            : false;
    };

    const handleInputChange = async (question, value, index) => {
        try {
            let tempSearchType = { ...showSearchType };
            tempSearchType[question.BaseQuestionId][index] = {
                ...tempSearchType[question.BaseQuestionId][index],
                value: value,
                options: [],
                questionId: question.BaseQuestionId
            };
            setSearchType({ ...tempSearchType });
            if (value !== "") {
                const apiEndPoint =
                    API_URL_UNDERWRITING +
                    "assurityDisclosures?applicationId=" +
                    underwriting?.applicationId +
                    "&categoryId=" +
                    question?.CategoryId +
                    "&searchText=" +
                    value;
                const response = await commonApiCall(apiEndPoint, "get", {}, "");

                if (response.status.code === 200) {
                    // setOptionList(re)
                    const list = JSON.parse(response.data);
                    tempSearchType[question.BaseQuestionId][index] = {
                        ...tempSearchType[question.BaseQuestionId][index],
                        options: list.DisclosureMatches,
                    };
                    setSearchType({ ...tempSearchType });
                }
            }
        } catch (err) {
            console.log(err);
            showErrorToast(err);
        }
    };

    const getProgressCount = () => {
        let count = 0;
        assurityCount = assurityCount ? assurityCount : { underWritingProgress: 7 };
        if (
            screen === "GENERAL_SECTION_1" &&
            assurityCount.underWritingProgress < 3
        ) {
            count = 3;
        }
        if (
            screen === "GENERAL_SECTION_2" &&
            assurityCount.underWritingProgress < 4
        ) {
            count = 4;
        }
        if (
            screen === "HEALTH_SECTION_1" &&
            assurityCount.underWritingProgress < 5
        ) {
            count = 5;
        }
        if (
            screen === "HEALTH_SECTION_2" &&
            assurityCount.underWritingProgress < 6
        ) {
            count = 6;
        }
        if (
            screen === "HEALTH_SECTION_3" &&
            assurityCount.underWritingProgress < 7
        ) {
            count = 7;
        }
        if (
            screen === "HEALTH_SECTION_4" &&
            assurityCount.underWritingProgress < 8
        ) {
            count = 8;
        }
        if (
            screen === "COVID_QUESTIONS" &&
            assurityCount.underWritingProgress < 9
        ) {
            count = 9;
        }
        return count;
    };

    useEffect(() => {
        if (baseQuestion && baseQuestion.length > 0) {
            for (let index = 0; index < baseQuestion.length; index++) {
                const element = baseQuestion[index];
                if (element.BaseQuestionType !== "Heading") {
                    setSearchType({ ...showSearchType, [baseQuestion[index].BaseQuestionId]: {} });
                    setDisclosure({ ...disclosure, [baseQuestion[index].BaseQuestionId]: [{}] });
                    setCurrentQuestionIndex(index);
                    break;
                }
            }
            let updatedQuestionHeader =
                baseQuestion &&
                baseQuestion.length > 0 &&
                baseQuestion.find((question) => question.BaseQuestionType === "Heading")
                    ?.BaseQuestionText;
            if (
                updatedQuestionHeader &&
                updatedQuestionHeader !== currentQuestionHeader
            ) {
                setCurrentQuestionHeader(updatedQuestionHeader);
            } else {
                let isSubBaseQuestion =
                    baseQuestion &&
                    baseQuestion.length > 0 &&
                    baseQuestion[0]?.BaseQuestionId.includes("SubBase");
                if (getPreviousRoute() !== "" && isSubBaseQuestion) {
                    onGetUnderWritingCase(getPreviousRoute()).then((response) => {
                        updatedQuestionHeader =
                            response &&
                            response.length > 0 &&
                            response?.findLast(
                                (question) => question.BaseQuestionType === "Heading"
                            )?.BaseQuestionText;
                        if (
                            updatedQuestionHeader &&
                            updatedQuestionHeader !== currentQuestionHeader
                        ) {
                            setCurrentQuestionHeader(updatedQuestionHeader);
                        }
                    });
                }
            }
        }
    }, [baseQuestion]);

    const moveToNextQuestion = async () => {
        if (currentQuestionIndex < baseQuestion.length - 1) {
            for (
                let index = currentQuestionIndex + 1;
                index < baseQuestion.length;
                index++
            ) {
                const element = baseQuestion[index];
                if (element.BaseQuestionType === "Heading") {
                    setCurrentQuestionHeader(element?.BaseQuestionText);
                }
                if (element.BaseQuestionType !== "Heading") {
                    setSearchType({ ...showSearchType, [baseQuestion[index].BaseQuestionId]: {} })
                    setDisclosure({ ...disclosure, [baseQuestion[index].BaseQuestionId]: [{}] });
                    setQuestionLoading({ ...questionLoading, loading: {} });
                    baseAnswer.findIndex(x => x.BaseQuestionId === baseQuestion[index].BaseQuestionId) < 0 && setEnableNextButton(false);
                    setCurrentQuestionIndex(index);
                    break;
                }
            }
        } else {
            setAssurityProgressCount({
                ...assurityCount,
                underWritingProgress: getProgressCount(),
            });
            saveUnderwriteDetails(
                "24,ASSURITY_UNDERWRITING," + getRoute(),
                getRoute()
            );
        }
    };

    const onNextHandler = async () => {
        const validation = disclosure[baseQuestion[currentQuestionIndex].BaseQuestionId].map((id) => {
            return validateQuestions(id.disclosureId);
        });
        if (!validation.includes(false)) {
            const baseAnswerValue = baseAnswer.find(
                (x) =>
                    x.BaseQuestionId ===
                    baseQuestion[currentQuestionIndex].BaseQuestionId
            )?.answerValue;
            if (baseAnswerValue === "true") {
                const validationField = disclosure[baseQuestion[currentQuestionIndex].BaseQuestionId].map((id) => {
                    return validateField(id.disclosureId);
                });
                if (!validationField.includes(false)) {
                    updateReflexiveQuestion(
                        baseQuestion[currentQuestionIndex],
                        disclosure,
                        reflexiveArr,
                    );
                }
            }
            else {
                moveToNextQuestion();
            }
        }
    };

    const saveUnderwriteDetails = async (step, route) => {
        try {
            const request = {
                applicationId: underwriting?.applicationId,
                applicationType: "Web",
                nextStep: step,
            };

            const isAuthorized = getJwt() ? true : false;
            const apiEndPoint = API_URL_UNDERWRITING + "saveUnderWriting";
            const response = await commonApiCall(
                apiEndPoint,
                "post",
                request,
                UNDERWRITING_DETAILS,
                isAuthorized
            );

            if (response.status.code === 200) {
                props.history.push(
                    `${UnderWritingRoutes.ASSURITY_UNDERWRITING}?underwriting=${route}`
                );
            }
        } catch (err) {
            showErrorToast(err);
        }
    };

    const onBackHandler = () => {
        for (let index = currentQuestionIndex - 1; index >= 0; index--) {
            const element = baseQuestion[index];
            if (element.BaseQuestionType === "Heading") {
                setCurrentQuestionHeader(element?.BaseQuestionText);
            }
            if (element.BaseQuestionType !== "Heading") {
                setEnableNextButton(true);
                setCurrentQuestionIndex(index);
                break;
            }
        }
    };

    const removeDisclosure = (question, index) => {
        let tempDisclosure = { ...disclosure };
        const disclosureId = tempDisclosure[question.BaseQuestionId][index];
        let tempSearchType = { ...showSearchType };
        let tempReflexiveQuestionList = [...reflexiveQuestionList];
        let tempReflexiveArr = [...reflexiveArr];
        index === 0 ? tempDisclosure[question.BaseQuestionId][index] = {} : tempDisclosure[question.BaseQuestionId].splice(index, 1);
        index === 0 ? tempSearchType[question.BaseQuestionId][index] = {} : delete tempSearchType[question.BaseQuestionId][index];
        tempReflexiveQuestionList = tempReflexiveQuestionList.filter(item => !(item.BaseQuestionId === question.BaseQuestionId && item.DisclosureId === disclosureId));
        tempReflexiveArr = tempReflexiveArr.filter(item => !(item.BaseQuestionId === question.BaseQuestionId && item.DisclosureId === disclosureId));
        setDisclosure({ ...disclosure });
        setSearchType({ ...tempSearchType });
        setReflexiveQuestionList([...tempReflexiveQuestionList]);
        setReflexiveArr([...tempReflexiveArr]);
    };

    return (
        <div className="underwriting-detail sbli-question-container pb-5">
            {baseQuestion &&
                baseQuestion[currentQuestionIndex]?.BaseQuestionId.includes("Sub") && (
                    <div
                        className={"sbli-parent-question  mb-3"}
                        id="sbli_question_container"
                        key={baseQuestion[currentQuestionIndex]?.BaseQuestionId}
                    >
                        <h4
                            style={{
                                fontWeight: 600,
                            }}
                            className="mb-4 parent_question_head"
                        >
                            {currentQuestionHeader}
                        </h4>
                    </div>
                )}
            {baseQuestion &&
                baseQuestion.map((question, index) => {
                    if (
                        index === currentQuestionIndex &&
                        question.BaseQuestionType !== "Heading"
                    ) {
                        return (
                            <div
                                className={
                                    "sbli-parent-question " +
                                    // (baseQuestionErrors.hasOwnProperty(question.BaseQuestionId)
                                    //   ? "validate-question-parent "
                                    //   : "") +
                                    `question question_${question.BaseQuestionId} mb-5`
                                }
                                id="sbli_question_container"
                                key={question.BaseQuestionId}
                            >
                                <h4
                                    style={{
                                        fontWeight: 500,
                                    }}
                                    className="mb-4 parent_question_head"
                                >
                                    {question.BaseQuestionText}
                                </h4>

                                {question.BaseQuestionType === "Trigger_On_Yes" && (
                                    <AssurityBaseBoolean
                                        question={question}
                                        value={
                                            getBaseAnswer(question)
                                                ? getBaseAnswer(question) === "true"
                                                    ? "Yes"
                                                    : "No"
                                                : ""
                                        }
                                        disabled={Object.values(questionLoading.loading).includes(true)}
                                        onValueChange={(question, value) => {
                                            handleBaseAnswer(
                                                question,
                                                value.target.value.toLowerCase() === "yes"
                                                    ? "true"
                                                    : "false",
                                                "answerValue"
                                            );
                                            if (question.SearchType[0].Value !== "Pick_List" && question.SearchType[0].Value !== "Search") {
                                                baseBooleanTrigger(question, value.target.value);
                                            }
                                        }}
                                    />
                                )}

                                {disclosure[question.BaseQuestionId] && disclosure[question.BaseQuestionId].length > 0 &&
                                    disclosure[question.BaseQuestionId].map((disclosureItem, disclosureIndex) =>
                                        <div className={`${(baseAnswer.find(
                                            (x) =>
                                                x.BaseQuestionId ===
                                                question.BaseQuestionId
                                        )?.answerValue === "true" && question.SearchType[0].Value === "Search") ? "card text-left mb-3" : ""}`}>
                                            {question.SearchType[0]?.TriggerOn ===
                                                (getBaseAnswer(question)
                                                    ? getBaseAnswer(question) === "true"
                                                        ? "Yes"
                                                        : "No"
                                                    : "") &&
                                                question.SearchType[0]?.Value === "Search" && (
                                                    <div className="mt-4">
                                                        <AssurityTextField
                                                            options={
                                                                showSearchType[question.BaseQuestionId][disclosureIndex]
                                                                    ? showSearchType[question.BaseQuestionId][disclosureIndex].options
                                                                    : []
                                                            }
                                                            value={
                                                                showSearchType[question.BaseQuestionId][disclosureIndex]
                                                                    ? showSearchType[question.BaseQuestionId][disclosureIndex].value
                                                                    : ""
                                                            }
                                                            showRemove={disclosureIndex === 0 ? (Object.keys(disclosure[question.BaseQuestionId][disclosureIndex]).length > 0 && (showSearchType[question.BaseQuestionId][disclosureIndex]?.value)) : disclosure[question.BaseQuestionId][disclosureIndex]}
                                                            removeDisclosure={() => removeDisclosure(question, disclosureIndex)}
                                                            onValueChange={(e, item) => {
                                                                setEnableNextButton(true);
                                                                if (disclosure[question.BaseQuestionId].filter(disclosureItem => disclosureItem.disclosureId === item.DisclosureId).length > 0) {
                                                                    let tempSearchType = { ...showSearchType };
                                                                    let tempDisclosure = { ...disclosure };
                                                                    tempDisclosure[question.BaseQuestionId].splice(disclosureIndex, 1);
                                                                    disclosureIndex === 0 ? tempSearchType[question.BaseQuestionId][disclosureIndex] = {} : delete tempSearchType[question.BaseQuestionId][disclosureIndex];
                                                                    setDisclosure({ ...tempDisclosure })
                                                                    setSearchType({ ...tempSearchType });
                                                                }
                                                                else {
                                                                    let tempSearchType = { ...showSearchType };
                                                                    let tempDisclosure = { ...disclosure };
                                                                    tempDisclosure[question.BaseQuestionId][disclosureIndex] = { disclosureId: item.DisclosureId, disclosureName: item.DisclosureName };
                                                                    tempSearchType[question.BaseQuestionId][disclosureIndex] = {
                                                                        ...tempSearchType[question.BaseQuestionId][disclosureIndex],
                                                                        value: e,
                                                                        disclosureId: item.DisclosureId
                                                                    }
                                                                    // setEnableNextButton(true);
                                                                    setDisclosure({ ...tempDisclosure });
                                                                    setSearchType({ ...tempSearchType });
                                                                    getReflexiveQuestions(
                                                                        question.BaseQuestionId,
                                                                        item.DisclosureId
                                                                    );

                                                                }

                                                            }}
                                                            onInputChange={(d) => handleInputChange(question, d, disclosureIndex)}
                                                        />
                                                    </div>
                                                )}


                                            {(question.SearchType[0].Value !== "Pick_List" || disclosureIndex === 0) && getBaseAnswer(question) &&
                                                question.SearchType[0].Value === "Pick_List" &&
                                                (question.SearchType[0].TriggerOn === "Yes"
                                                    ? "true"
                                                    : "false") === getBaseAnswer(question) && (
                                                    <ShowDisclosurePickList
                                                        list={question.BaseQuestionDisclosures}
                                                        onValueChange={(id) =>
                                                            handleDisclosurePickList(question, id)
                                                        }
                                                        value={getPickListClosureId(question)}
                                                        baseAnswer={getBaseAnswer(question)}
                                                    />
                                                )}
                                            {question.BaseQuestionType !== "Heading" && (question.SearchType[0].Value !== "Pick_List" || disclosureIndex === 0) &&
                                                questionLoading.questionId === question.BaseQuestionId && questionLoading.loading[disclosureItem.disclosureId] &&
                                                (question.SearchType[0].Value === "Pick_List" ? Object.values(questionLoading.loading).includes(true) : questionLoading.loading[disclosureItem.disclosureId]) && (
                                                    <p className="mt-3">Please wait....</p>
                                                )}

                                            {((question.SearchType[0].Value === "Search" && (showSearchType[question.BaseQuestionId][disclosureIndex]?.value)) || question.SearchType[0].Value !== "Search") &&
                                                getReflexiveList(question, disclosureItem.disclosureId) && getReflexiveList(question, disclosureItem.disclosureId).ReflexiveQuestion &&
                                                <>
                                                    {/* {question.SearchType[0].Value ===" Pick_List" && <h4>{disclosureName[disclosureId]}</h4>} */}
                                                    {renderReflexiveQuestion(
                                                        question,
                                                        getReflexiveList(question, disclosureItem.disclosureId).ReflexiveQuestion,
                                                        disclosureItem.disclosureId,
                                                    )}
                                                </>}
                                            {physicianQuestion[disclosureItem.disclosureId] && showPhysicianQuestion[question.BaseQuestionId] && showPhysicianQuestion[question.BaseQuestionId][disclosureItem.disclosureId] && <AssurityDisclosurePhysician
                                                formData={formData}
                                                handleChange={handleChange}
                                                disclosureId={disclosureItem.disclosureId}
                                                setFormData={setFormData}
                                                initialFormData={initialFormData}
                                            />}
                                            {question.SearchType[0].Value === "Search" && Object.keys(disclosure[question.BaseQuestionId][disclosureIndex]).length > 0 && showSearchType[question.BaseQuestionId][disclosureIndex]?.value && disclosureIndex === disclosure[question.BaseQuestionId].length - 1 && <a className="d-inline-block mt-4 text-success" onClick={() => {
                                                setDisclosure({ ...disclosure, [question.BaseQuestionId]: [...disclosure[question.BaseQuestionId], {}] });
                                            }}><u>Add another</u></a>}
                                        </div>
                                    )}

                                {/*  {Object.keys(physicianQuestion).length > 0 && physicianQuestion[question.BaseQuestionId] && Object.keys(physicianQuestion[question.BaseQuestionId]).length > 0 && Object.keys(physicianQuestion[question.BaseQuestionId]).map((key, index) =>
                                    <>

                                        {(question.SearchType[0].Value !== "Search" || showSearchType[question.BaseQuestionId][index]?.value) && <AssurityDisclosurePhysician
                                            formData={formData}
                                            handleChange={handleChange}
                                            disclosureId={key}
                                            disclosureName={disclosure[question.BaseQuestionId].filter(item => item.disclosureId === key)[0]?.disclosureName}
                                            isPhysician
                                            setFormData={setFormData}
                                            initialFormData={initialFormData}
                                        />} 
                                    </>)
                                } */}

                            </div>
                        );
                    }
                })}

            <div className="w-100 my-4 pb-5 text-center">
                <button
                    className="btn btn-default px-5 d-block mx-auto mb-3"
                    disabled={
                        (loading || !enableNextButton)
                    }
                    onClick={onNextHandler}
                >
                    {loading
                        ? "Processing..."
                        : "Next"}
                </button>
                {(baseQuestion && baseQuestion[0]?.BaseQuestionType === "Heading"
                    ? currentQuestionIndex > 1
                    : currentQuestionIndex > 0) && (
                        <button
                            className="btn btn-link px-5 d-block mx-auto"
                            disabled={loading}
                            onClick={onBackHandler}
                        >
                            {loading ? "Processing..." : "Back"}
                        </button>
                    )}
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    underwriting: state.underwriting.underwriting,
    assurityCount: state.underwriting.assuProgressCount,
});
export default connect(mapStateToProps, {
    commonApiCall,
    setAssurityProgressCount,
})(withRouter(GeneralSection));
