import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

export const ApprovalProcessLoader = () => {
  return (
    <div className="skeleton-loader">
      <SkeletonTheme>
        <div className="row justify-content-center">
          <div className="col-md-10 mb-5">
            <Skeleton count={1} height={40} />
          </div>
        </div>
        <div className="card-outer approved-steps">
          <div className="card-body">
            <div className="guardian-outer">
              <div className="guardian-details">
                <div className="guardian-img">
                  <Skeleton count={1} width={48} height={48} circle={true} />
                </div>
                <div className="guardian-name">
                  <Skeleton count={1} height={42} width={185} />
                </div>
              </div>
              <div className="guardian-info">
                <ul>
                  <li>
                    <Skeleton count={1} height={50} width={120} />
                  </li>
                  <li>
                    <Skeleton count={1} height={50} width={120} />
                  </li>
                  <li>
                    <Skeleton count={1} height={50} width={120} />
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="card-body">
            <div className="policy-outer">
              <div className="policy-name">
                <div>
                  <Skeleton count={1} height={26} width={150} />
                  <span className="ml-2">
                    <Skeleton count={1} height={26} width={85} />
                    <span className="ml-2">
                      <Skeleton count={1} height={26} width={55} />
                    </span>
                  </span>
                </div>
                <div className="ml-sm-auto">
                  <Skeleton count={1} height={40} width={160} />
                  <span className="ml-3">
                    <Skeleton count={1} height={40} width={160} />
                  </span>
                </div>
              </div>
              <div className="mt-2">
                <Skeleton count={1} height={26} />
              </div>
            </div>
          </div>
          <div className="card-body">
            <div className="policy-holder-outer">
              <div className="policy-holder-details">
                <div className="policy-holder-img">
                  <Skeleton count={1} height={30} width={30} circle={true} />
                </div>
                <div className="policy-holder-name">
                  <Skeleton count={1} height={30} width={150} />
                </div>
              </div>
              <div className="policy-holder-info">
                <ul>
                  <li>
                    <Skeleton count={1} height={30} width={80} />
                  </li>
                  <li>
                    <Skeleton count={1} height={30} width={80} />
                  </li>
                </ul>
              </div>
              <div>
                <Skeleton count={1} height={30} width={140} />
              </div>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-md-10 mb-5 mt-2">
            <Skeleton count={1} height={30} />
          </div>
        </div>
        <div className="card-outer mt-2">
          <div className="agent-outer agent-shortchat m-0">
            <div className="agent-details">
              <div className="agent-img">
                <Skeleton count={1} height={48} width={48} circle={true} />
              </div>
              <div className="agent-help text-left">
                <Skeleton count={1} height={48} />
              </div>
            </div>
            <div className="ml-auto">
              <Skeleton count={1} height={48} width={100} />
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-md-6 mb-3 mt-2">
            <Skeleton count={1} height={36} />
          </div>
          <div className="col-md-11 mt-2">
            <Skeleton count={1} height={44} />
          </div>
        </div>
      </SkeletonTheme>
    </div>
  );
};
