import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import queryString from "query-string";
import { Animated } from "react-animated-css";
import NumberFormat from "react-number-format";
import Header from "../../../components/header";
import {
  decryptme,
  UnderWritingRoutes,
  showErrorToast,
  segmentTracking,
  // setCjeCookie,
  checkIsInIframe,
  setItem,
} from "../../../utils/index";
import {
  commonApiCall,
  COMPANY_INFORMATION,
  clearUnderWriting,
  clearCompanyInformation,
  directLinkPath,
} from "../../../redux/actions/index";
import {
  API_URL_CLIENT,
  CLIENT_DOMAIN_NAME,
  CONSUMER_PORTAL_URL,
} from "../../../config/configs";
import WelcomePageSkeleton from "../../../components/skeletonLoaders/welcomePageSkeleton";
import ReactPlayer from "react-player/lazy";

const WelCome = ({
  companyInformation,
  commonApiCall,
  clearUnderWriting,
  clearCompanyInformation,
  directLinkPath,
  t,
  ...props
}) => {
  const domainNamePath = window.location.hostname;
  const domainName = domainNamePath.split(".");
  const urlParams = queryString.parse(props.location.search);
  const referenceFrom = urlParams?.referenceFrom
    ? urlParams?.referenceFrom
    : document.referrer
    ? document.referrer
    : "";
  const [isLoading, setLoading] = useState(true);
  const [colorObject] = useState({
    backgroundColor: "#fbfcfc",
    fontPreference: "Manrope",
    hoverEffectColor: "#2ba84a",
    inputTextColor: "#091f1f",
    labelBackgroundColor: "#ffffff",
    primaryColor: "#2ba84a",
    screenBackgroundColor: "#ffffff",
    underwritingTextHoverColor: "#ecfdf1",
    headerBkg: "#ffffff",
  });

  let domainLink =
    urlParams["domain"] !== "" &&
    "https://" +
      urlParams["domain"] +
      "" +
      CLIENT_DOMAIN_NAME +
      "/?referenceFrom=" +
      urlParams["referenceFrom"];

  useEffect(() => {
    setItem("referenceFromUrl", referenceFrom);
    if (urlParams["domain"]) {
      window.location.replace(domainLink);
    } else {
      getAgentCompanyInfo();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkVideoPermission = (arr = []) => {
    const item = arr.filter((x) => x.itemSlugName === "HOME_PAGE_BG_VIDEO");
    return item.length > 0 ? true : false;
  };

  // const getId = (url) => {
  //   const regExp =
  //     /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
  //   const match = url && url.match(regExp);

  //   return match && match[2].length === 11 ? match[2] : null;
  // };

  const setDefaultTheme = (obj) => {
    document.body.style.setProperty(
      "--color-primary",
      obj.primaryColor ? obj.primaryColor : colorObject.primaryColor
    );
    document.body.style.setProperty(
      "--color-background",
      obj.screenBackgroundColor
        ? obj.screenBackgroundColor
        : colorObject.screenBackgroundColor
    );
    document.body.style.setProperty(
      "--color-headerapply",
      obj.backgroundColor ? obj.backgroundColor : colorObject.headerBkg
    );
    document.body.style.setProperty(
      "--color-white",
      obj.labelBackgroundColor
        ? obj.labelBackgroundColor
        : colorObject.labelBackgroundColor
    );
    document.body.style.setProperty(
      "--color-txt-df",
      obj.inputTextColor ? obj.inputTextColor : colorObject.inputTextColor
    );
    document.body.style.setProperty(
      "--color-over-effect",
      obj.hoverEffectColor ? obj.hoverEffectColor : colorObject.hoverEffectColor
    );
    document.body.style.setProperty(
      "--color-success-lgt",
      obj.underwritingTextHoverColor
        ? obj.underwritingTextHoverColor
        : colorObject.underwritingTextHoverColor
    );
    document.body.style.setProperty(
      "--font-var",
      obj.fontPreference ? obj.fontPreference : colorObject.fontPreference
    );
  };

  const getAgentCompanyInfo = async () => {
    const pathname = window.location.pathname;

    try {
      const request = {};
      let apiEndPoint;
      if (urlParams["Ref"]) {
        apiEndPoint =
          API_URL_CLIENT + "getCompanyInfo?referralCode=" + urlParams["Ref"];
      } else if (companyInformation?.refferalCode) {
        apiEndPoint =
          API_URL_CLIENT +
          "getCompanyInfo?referralCode=" +
          companyInformation.refferalCode;
      } else {
        apiEndPoint =
          API_URL_CLIENT + "getCompanyInfo?domainName=" + domainName[0];
      }

      const isAuthorized = false;
      const response = await commonApiCall(
        apiEndPoint,
        "get",
        request,
        COMPANY_INFORMATION,
        isAuthorized
      );

      if (response.status.code === 200) {
        directLinkPath(pathname);
        setDefaultTheme(response.data.agentSettings);
        await clearUnderWriting();
        document
          .querySelector('meta[name="facebook-domain-verification"]')
          .setAttribute("content", response.data.fbDomainVerification);
        if (pathname === "/insurance") {
          props.history.push(UnderWritingRoutes.INSURE_TYPE);
        } else if (pathname === "/financial" || pathname === "/finacial") {
          props.history.push(UnderWritingRoutes.INVESTMENT_TYPE);
        } else if (
          pathname === "/final-expense" ||
          pathname === "/term" ||
          pathname === "/permanent" ||
          pathname === "/dividend" ||
          pathname === "/index"
        ) {
          if (response?.data?.prospectJourney === "single_page") {
            props.history.push(UnderWritingRoutes.SINGLE_PAGE);
          } else {
            props.history.push(UnderWritingRoutes.YOUR_GENDER);
          }
        } else if (pathname === "/medicare") {
          if (response?.data?.prospectJourney === "single_page") {
            props.history.push(UnderWritingRoutes.SINGLE_PAGE);
          } else {
            props.history.push(UnderWritingRoutes.MEDICARE_ENROLL);
          }
        } else if (response?.data?.defaultPage === "welcome_screen") {
          let startObject = {
            agent_id: response.data.agentId,
            entry_page: "Customized Landing Page",
            single_page:
              response?.data?.prospectJourney === "single_page" ? true : false,
          };
          segmentTracking("application_visit", startObject);
        } else if (response?.data?.defaultPage === "goal_type") {
          props.history.push(UnderWritingRoutes.GET_START);
        } else if (response?.data?.defaultPage === "insurance") {
          props.history.push(UnderWritingRoutes.INSURE_TYPE);
        } else if (response?.data?.defaultPage === "investment") {
          props.history.push(UnderWritingRoutes.INVESTMENT_TYPE);
        } else if (
          (response?.data?.defaultPage &&
            response?.data?.defaultPage === "final_expense") ||
          (response?.data?.defaultPage &&
            response?.data?.defaultPage === "term") ||
          (response?.data?.defaultPage &&
            response?.data?.defaultPage === "permanent")
        ) {
          if (response?.data?.prospectJourney === "single_page") {
            props.history.push(UnderWritingRoutes.SINGLE_PAGE);
          } else {
            props.history.push(UnderWritingRoutes.YOUR_GENDER);
          }
        } else if (
          response?.data?.defaultPage &&
          response?.data?.defaultPage === "medicare"
        ) {
          if (response?.data?.prospectJourney === "single_page") {
            props.history.push(UnderWritingRoutes.SINGLE_PAGE);
          } else {
            props.history.push(UnderWritingRoutes.MEDICARE_ENROLL);
          }
        }
        setLoading(false);
      } else if (response.status.code === 404) {
        await clearUnderWriting();
        await clearCompanyInformation();
        setDefaultTheme("");
        window.location.replace(CONSUMER_PORTAL_URL + "404");
      }
    } catch (err) {
      showErrorToast(t("validation_message.SOMETHING_WENT_WRONG"));
    }
  };

  useEffect(() => {
    let eventObject = {
      agent_id: companyInformation?.agentId,
    };
    segmentTracking("application_prestart", eventObject);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isLoading === true && urlParams.page === "about") {
      getAgentCompanyInfo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  const handleNavigation = async () => {
    await clearUnderWriting();

    const navigationURL = urlParams["Ref"]
      ? UnderWritingRoutes.GET_START +
        "?Ref=" +
        urlParams["Ref"] +
        "&referenceFrom=" +
        referenceFrom
      : UnderWritingRoutes.GET_START +
        "?Ref=" +
        companyInformation?.refferalCode +
        "&referenceFrom=" +
        referenceFrom;
    props.history.push(navigationURL);
  };

  const bgImage = companyInformation?.agentSettings?.backgroundImage
    ? companyInformation?.agentSettings?.backgroundImage
    : "assets/images/bg.jpg";

  const errorImageReplace = (id) => {
    document.getElementById(id).src = "/assets/images/no-user.png";
  };

  return (
    <>
      {companyInformation?.defaultPage &&
      companyInformation?.defaultPage !== "welcome_screen" ? (
        <WelcomePageSkeleton />
      ) : (
        <>
          {isLoading ? (
            <WelcomePageSkeleton />
          ) : (
            <div
              className={
                companyInformation?.hideIlifeHeader === true &&
                checkIsInIframe() == true
                  ? "page-wrapper-welcome pt-0"
                  : "page-wrapper-welcome"
              }
            >
              <Header
                isWelcomePage={true}
                isAboutPage={urlParams.page === "about"}
                isLoading={isLoading}
                t={t}
                props={props}
              ></Header>

              <div className="welcome-mode">
                <div className="text-center">
                  <Animated
                    animationIn="fadeInRight"
                    animationOut="zoomOut"
                    animationInDuration={800}
                    animationOutDuration={800}
                    isVisible={true}
                  >
                    <div className="demopage ">
                      <div className="demopagewrapper">
                        <div
                          className={
                            urlParams.page === "about"
                              ? "welcomeContainer position-relative"
                              : "welcomeContainer position-relative welcome-background"
                          }
                          style={
                            urlParams.page === "about"
                              ? {}
                              : {
                                  backgroundColor:
                                    companyInformation?.agentSettings
                                      ?.homePageBgColor,
                                }
                          }
                        >
                          {urlParams.page !== "about" && (
                            <div
                              className="welcomePageBg"
                              style={
                                urlParams.page === "about" && bgImage
                                  ? {}
                                  : {
                                      backgroundImage: `url(${bgImage})`,
                                      opacity: companyInformation?.bgOpacity,
                                    }
                              }
                            ></div>
                          )}
                          <div className={"demopagebody position-relative"}>
                            {urlParams.page === "about" ? (
                              <div className="about-main-body">
                                <div className="about-top">
                                  <div className="left-content">
                                    <h3 className="username">
                                      {companyInformation &&
                                        companyInformation.firstName !==
                                          undefined &&
                                        decryptme(companyInformation.firstName)}
                                      {companyInformation &&
                                        companyInformation.lastName !==
                                          undefined &&
                                        " " +
                                          decryptme(
                                            companyInformation.lastName
                                          )}
                                    </h3>
                                    <div className="userbio">
                                      <p className="bio-desc">
                                        <span
                                          style={{ whiteSpace: "pre-line" }}
                                        >
                                          {companyInformation &&
                                            companyInformation.bio !==
                                              undefined &&
                                            companyInformation.bio.replace(
                                              /<br>/g,
                                              "\n"
                                            )}
                                        </span>
                                      </p>
                                    </div>
                                  </div>
                                  <div className="right-content">
                                    <div className="aboutimg">
                                      <img
                                        id="aboutImage"
                                        src={
                                          companyInformation &&
                                          companyInformation.aboutPageImage
                                            ? companyInformation.aboutPageImage
                                            : "/assets/images/no-user.png"
                                        }
                                        alt="About User"
                                        onError={() =>
                                          errorImageReplace("aboutImage")
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="yourquote-block">
                                  <p className="yourquote-msg">
                                    Let me help you get the best life insurance
                                    for your needs.
                                  </p>
                                  <button
                                    className="btn btn-primary"
                                    onClick={handleNavigation}
                                  >
                                    Browse Quotes
                                  </button>
                                </div>
                              </div>
                            ) : (
                              <>
                                {!companyInformation?.videoLink ||
                                companyInformation?.videoLink === "" ||
                                companyInformation?.showVideo ||
                                !checkVideoPermission(
                                  companyInformation?.addOns
                                ) ? (
                                  <div className="pre-main-body">
                                    <div
                                      className={`body-block ${
                                        companyInformation?.transparentBox
                                          ? "bg-white"
                                          : "bg-transparent"
                                      }`}
                                    >
                                      <h2
                                        className="body-title"
                                        style={{
                                          color:
                                            companyInformation?.agentSettings
                                              ?.headlineTextColor,
                                        }}
                                      >
                                        {" "}
                                        {companyInformation &&
                                          companyInformation.headline !==
                                            undefined &&
                                          decryptme(
                                            companyInformation.headline
                                          )}
                                      </h2>
                                      <p className="body-msg">
                                        <span
                                          style={{
                                            whiteSpace: "pre-line",
                                            color:
                                              companyInformation?.agentSettings
                                                ?.welcomeMessageColor,
                                          }}
                                        >
                                          {companyInformation &&
                                            companyInformation.welcomeMessage !==
                                              undefined &&
                                            decryptme(
                                              companyInformation.welcomeMessage
                                            ).replace(/<br>/g, "\n")}
                                        </span>
                                      </p>
                                      <button
                                        className="btn btn-primary gets-quote-btn"
                                        onClick={handleNavigation}
                                      >
                                        Browse Quotes
                                      </button>
                                    </div>
                                  </div>
                                ) : (
                                  <div className="pre-main-body with-video">
                                    <div className="container-fluid">
                                      <div className="row">
                                        <div className="col-md-6">
                                          <div
                                            className={`body-block justify-content-center ${
                                              companyInformation?.transparentBox
                                                ? "bg-white"
                                                : "bg-transparent"
                                            }`}
                                          >
                                            <h2
                                              className="body-title"
                                              style={{
                                                color:
                                                  companyInformation
                                                    ?.agentSettings
                                                    ?.headlineTextColor,
                                              }}
                                            >
                                              {" "}
                                              {companyInformation &&
                                                companyInformation.headline !==
                                                  undefined &&
                                                decryptme(
                                                  companyInformation.headline
                                                )}
                                            </h2>
                                            <p className="body-msg">
                                              <span
                                                style={{
                                                  whiteSpace: "pre-line",
                                                  color:
                                                    companyInformation
                                                      ?.agentSettings
                                                      ?.welcomeMessageColor,
                                                }}
                                              >
                                                {companyInformation &&
                                                  companyInformation.welcomeMessage !==
                                                    undefined &&
                                                  decryptme(
                                                    companyInformation.welcomeMessage
                                                  ).replace(/<br>/g, "\n")}
                                              </span>
                                            </p>
                                            <button
                                              className="btn btn-primary gets-quote-btn"
                                              onClick={handleNavigation}
                                            >
                                              Browse Quotes
                                            </button>
                                          </div>
                                        </div>

                                        {companyInformation?.videoLink !== "" &&
                                          !companyInformation?.showVideo &&
                                          checkVideoPermission(
                                            companyInformation?.addOns
                                          ) && (
                                            <div className="col-md-6">
                                              <ReactPlayer
                                                url={
                                                  companyInformation?.videoLink
                                                }
                                                controls={true}
                                                playing={
                                                  companyInformation?.autoPlay ===
                                                  true
                                                    ? true
                                                    : false
                                                }
                                                loop={
                                                  companyInformation?.playInLoop ===
                                                  true
                                                    ? true
                                                    : false
                                                }
                                                // width="540px"
                                                // height="360px"
                                                volume={1}
                                                muted={true}
                                                className="iframe-video"
                                              />

                                              {/* <iframe
                                                className="iframe-video"
                                                src={`https://www.youtube.com/embed/${getId(
                                                  companyInformation?.videoLink
                                                )}?autoplay=${
                                                  companyInformation?.autoPlay
                                                    ? "1"
                                                    : "0"
                                                }&mute=${
                                                  companyInformation?.autoPlay
                                                    ? "1"
                                                    : "0"
                                                }&loop=${
                                                  companyInformation?.playInLoop
                                                    ? "1"
                                                    : "0"
                                                }&playlist=${getId(
                                                  companyInformation?.videoLink
                                                )}`}
                                                frameborder="0"
                                                allow={`accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture;${
                                                  companyInformation?.autoPlay
                                                    ? "autoplay;"
                                                    : ""
                                                }`}
                                                allowfullscreen="allowfullscreen"
                                              ></iframe>*/}
                                            </div>
                                          )}
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </>
                            )}
                          </div>
                        </div>
                        <div className="premodel-footer modal-footer">
                          <div className="ftitle-block text-center">
                            <h3 className="footerTitle">
                              {companyInformation?.showCompany ? (
                                <>
                                  {companyInformation &&
                                    companyInformation.companyName !==
                                      undefined &&
                                    decryptme(companyInformation?.companyName)}
                                </>
                              ) : (
                                <>
                                  {companyInformation &&
                                    companyInformation.firstName !==
                                      undefined &&
                                    decryptme(companyInformation?.firstName)}
                                  {companyInformation &&
                                    companyInformation.lastName !== undefined &&
                                    " " +
                                      decryptme(companyInformation?.lastName)}
                                </>
                              )}
                            </h3>
                          </div>
                          <div className="fcontact">
                            <p className="f-address">
                              {companyInformation &&
                                companyInformation.location !== undefined &&
                                decryptme(companyInformation.location)}
                            </p>
                            <p className="f-tell">
                              <NumberFormat
                                displayType={"text"}
                                format="###-###-####"
                                value={
                                  companyInformation &&
                                  companyInformation.phoneNumber !==
                                    undefined &&
                                  decryptme(companyInformation.phoneNumber)
                                }
                              />
                            </p>
                            <p className="f-web">
                              {companyInformation &&
                                companyInformation.website !== undefined &&
                                decryptme(companyInformation.website)}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Animated>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};
const mapStateToProps = (state) => ({
  companyInformation: state.underwriting.companyInformation,
});
export default connect(mapStateToProps, {
  commonApiCall,
  clearUnderWriting,
  clearCompanyInformation,
  directLinkPath,
})(withRouter(WelCome));
