import React from "react";
import { Link } from "react-router-dom";

const MedicalLogin = () => {
  return (
    <div className="modal-part">
      <div className="title-header">
        <h3 className="page-title">Connect your account</h3>
        <h4>
          Log into your account below to connect and share your health records:
        </h4>
      </div>
      <p className="powered-by position-top">
        Powered by{" "}
        <span>
          <img
            src="/assets/images/icon/human-api.png"
            alt="HumanApi"
            title="HumanApi"
          />
        </span>
      </p>
      <div className="row hospital-portal">
        <div className="col-md-3">
          <div className="card-inner with-border text-center">
            <div className="hospital-logo">
              <img
                src="/assets/images/hospital-icon/riverside-medical.svg"
                alt=""
                title=""
              />
            </div>
            <h4 className="hospital-name">Riverside Medical Clinic</h4>
            <Link to="#" className="text-link">
              Visit Website
            </Link>
          </div>
        </div>
        <div className="col-md-9">
          <div className="card-inner with-border text-center">
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <div className="input-effect with-icon">
                    <input
                      className="form-control"
                      placeholder="Email"
                      name="email"
                      id="email"
                      type="mail"
                    />
                    <label htmlFor="email">Email</label>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <div className="input-effect with-icon">
                    <input
                      className="form-control"
                      placeholder="Password"
                      name="password"
                      id="password"
                      type="password"
                    />
                    <label htmlFor="password">Password</label>
                  </div>
                </div>
              </div>
            </div>
            <p className="login-info">
              By clicking ‘Login’, I declare that health information connected
              from Children’s Omaha is mine or I have permission from the owner
              of this data to access it.
            </p>
            <Link to="#" className="btn btn-primary mb-3">
              Login
            </Link>
            <h4>
              Don’t have an account?{" "}
              <Link className="text-link" to="#">
                Create New Account
              </Link>
            </h4>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MedicalLogin;
