import http from "./http";
import {
  EMPTY_UNDERWRITING_DETAILS,
  COMPANY_INFORMATION,
  CONSUMER_LOGOUT,
  LANGUAGE,
  QUESTIONARIES,
  PROFILE_PIC,
  DIRECT_LINK,
} from "./types";

import { consoleLog } from "./index";

const tokenKey = "iLifeConsumerToken";

export function loginWithJwt(jwt) {
  localStorage.setItem(tokenKey, jwt);
}

export const getJwt = () => {
  return localStorage.getItem(tokenKey);
};

export const setJwt = (clientToken) => {
  return localStorage.setItem(tokenKey, clientToken);
};

//logout
export const logout = (url, method, isAuthorized = false) => (dispatch) => {
  if (isAuthorized) http.setJwtAuthorization(getJwt());
  return new Promise((resolve, reject) => {
    http
      .axios({ method, url, isAuthorized })
      .then((response) => {
        if (response && response.status === 200) {
          const responseData = response.data;
          localStorage.clear();
          dispatch({ type: PROFILE_PIC, payload: {} });
          dispatch({type:DIRECT_LINK, payload:{}});
          dispatch({ type: CONSUMER_LOGOUT, payload: {} });
          dispatch({ type: EMPTY_UNDERWRITING_DETAILS, payload: {} });
          dispatch({ type: COMPANY_INFORMATION, payload: {} });
          dispatch({ type: QUESTIONARIES, payload: {} });
          resolve(responseData);
        }
      })
      .catch((error) => {
        consoleLog("error is ", error);
        reject(error);
      });
  });
};

export const changeLanguage = (body) => async (dispatch) => {
  const payload = {
    data: body.language,
  };
  dispatch({ type: LANGUAGE, payload });
};
