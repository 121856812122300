import moment from "moment";
import React from "react";

import { decryptme, getState } from "../utils";

const ProgressLayout = ({ underWritingData }) => {
  const titleCase = (str) => {
    var splitStr = str.toLowerCase().split(" ");
    for (var i = 0; i < splitStr.length; i++) {
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }

    return splitStr.join(" ");
  };

  const dollar = (value) => "$" + Number(value).toLocaleString();
  const currentPath = window.location.pathname.split("/")[1];

  const journey = [
    // {
    //   label: underWritingData?.goalType ? underWritingData?.goalType : "",
    //   className: underWritingData?.goalType
    //     ? "active"
    //     : currentPath === "medicare-enroll"
    //     ? "focus"
    //     : "",
    //   show: underWritingData?.goalType === "MEDICARE" ? true : false,
    // },
    {
      label:
        underWritingData?.medicarePolicyFlag !== null
          ? underWritingData?.medicarePolicyFlag
            ? "Yes"
            : "No"
          : "Medicare",
      className:
        underWritingData?.medicarePolicyFlag !== null
          ? "active"
          : currentPath === "medicare-enroll"
          ? "focus"
          : "",
      show: underWritingData?.goalType === "MEDICARE" ? true : false,
    },
    {
      label: underWritingData?.gender
        ? titleCase(decryptme(underWritingData?.gender))
        : "Gender",
      className: underWritingData?.gender
        ? "active"
        : currentPath === "your-gender"
        ? "focus"
        : "",
      show: true,
    },
    {
      label: underWritingData?.dob
        ? moment(decryptme(underWritingData?.dob)).format("MMM D, YYYY")
        : "Date of Birth",
      className: underWritingData?.dob
        ? "active"
        : currentPath === "your-age"
        ? "focus"
        : "",
      show: true,
    },
    {
      label: underWritingData?.healthRating
        ? titleCase(underWritingData?.healthRating)
        : "Health",
      className: underWritingData?.healthRating
        ? "active"
        : currentPath === "your-health"
        ? "focus"
        : "",
      show: underWritingData?.goalType !== "MEDICARE" ? true : false,
    },
    {
      label:
        underWritingData?.smoker !== null
          ? underWritingData?.smoker
            ? "Smoker"
            : "Non-Smoker"
          : "Smoking Status",
      className:
        underWritingData?.smoker !== null
          ? "active"
          : currentPath === "smoking-habit"
          ? "focus"
          : "",
      show: underWritingData?.goalType !== "MEDICARE" ? true : false,
    },
    {
      label:
        underWritingData?.citizenship !== null
          ? underWritingData?.citizenship == "us_citizen_reside"
            ? "U.S.A"
            : underWritingData?.citizenship == "us_citizen_outside"
            ? "U.S.A Non-Res."
            : underWritingData?.citizenship == "non_us_citizen_reside"
            ? "Green Card"
            : underWritingData?.citizenship == "non_resident_alien"
            ? "Non-U.S.A"
            : "Other"
          : "Citizenship",
      className:
        underWritingData?.citizenship !== null
          ? "active"
          : currentPath === "citizenship"
          ? "focus"
          : "",
      show: underWritingData?.goalType !== "MEDICARE" ? true : false,
    },
    {
      label: underWritingData?.postalCode
        ? `${underWritingData?.postalCode} (${
            getState(underWritingData?.postalCode).state
          })`
        : "ZIP Code",
      className: underWritingData?.postalCode
        ? "active"
        : currentPath === "zipcode"
        ? "focus"
        : "",
      show: true,
    },
    {
      label: underWritingData?.frequency
        ? titleCase(underWritingData?.frequency)
        : "Payment Frequency",
      className: underWritingData?.frequency
        ? "active"
        : currentPath === "your-frequency"
        ? "focus"
        : "",
      show:
        underWritingData?.goalType === "INVEST" ||
        underWritingData?.goalType === "SAVE"
          ? true
          : false,
    },
    {
      label: underWritingData?.amount
        ? dollar(underWritingData?.amount)
        : underWritingData?.goalType === "INVEST" ||
          underWritingData?.goalType === "SAVE"
        ? "Premium"
        : "Coverage",
      className: underWritingData?.amount
        ? "active"
        : currentPath === "your-deposit"
        ? "focus"
        : "",
      show: underWritingData?.goalType !== "MEDICARE" ? true : false,
    },
    {
      label: underWritingData?.termLength
        ? `${underWritingData?.termLength} Years`
        : "Coverage Period",
      className: underWritingData?.termLength
        ? "active"
        : currentPath === "policy-term"
        ? "focus"
        : "",
      show:
        underWritingData?.goalType === "INSURE" &&
        underWritingData?.policyType === "TERM_LIFE_INSURANCE"
          ? true
          : false,
    },
    {
      label: underWritingData?.carrierWiseLeads
        ? underWritingData?.carrierWiseLeads[0].carrierName
        : "Plans",
      className: underWritingData?.carrierWiseLeads
        ? "active"
        : currentPath === "policy-term"
        ? "focus"
        : "",
      show:
        underWritingData?.goalType === "MEDICARE" &&
        underWritingData?.medicarePolicyFlag
          ? true
          : false,
    },
    {
      label: "Contact Details",
      className: underWritingData?.termLength
        ? "active"
        : currentPath === "policy-term"
        ? "focus"
        : "",
      show: underWritingData?.goalType === "MEDICARE" ? true : false,
    },
  ];

  return (
    <ul className="progressbar">
      {journey.map((item, index) => {
        if (item.show) {
          return (
            <li key={index} className={item.className}>
              <span> {item.label}</span>
            </li>
          );
        }
      })}
    </ul>
  );
};

export default ProgressLayout;
