import React, { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { Animated } from "react-animated-css";
import moment from "moment";
import { isEmpty } from "lodash";
import {SelectDatepicker} from "react-select-datepicker";
//import {SelectDatepicker} from "react-select-datepicker"

import {
  commonApiCall,
  UNDERWRITING_DETAILS,
  getJwt,
  getAge,
} from "../../../redux/actions/index";
import {
  setProcessStep,
  getProcessStep,
  UnderWritingRoutes,
  showErrorToast,
  encryptme,
  decryptme,
  calcAge,
  calcAgeOnMonth,
  segmentTracking,
} from "../../../utils/index";
import {
  API_URL_UNDERWRITING,
  CONSUMER_PORTAL_URL,
} from "../../../config/configs";

const Age = ({ commonApiCall, getAge, t, underwriting, ...props }) => {
  const { applicationId, applicationType, goalType, policyType, age, dob } =
    underwriting;
  const [formData, setFormData] = useState({
    value_type: true,
    applicationId: applicationId,
    yourAge: age ? age : "0",
    yourDOB: dob ? new Date(decryptme(dob)) : null,
    applicationType: applicationType ? applicationType : "Web",
    errors: {},
  });
  const [maxDateOfCalendar, setMaxDateOfCalendar] = useState(null);
  const [minDateOfCalendar, setMinDateOfCalendar] = useState(null);

  const onDateChange = useCallback((date) => {
    setFormData({ ...formData, yourDOB: date, errors: {} });
  }, []);

  useEffect(() => {
    if (!applicationId) {
      window.location.replace(CONSUMER_PORTAL_URL + "404");
    }

    if (underwriting && !isEmpty(underwriting.carrierWiseLeads)) {
      const whereToRoute = getProcessStep(
        underwriting.resumeProcess,
        underwriting.goalType,
        underwriting.policyType,
        underwriting.productSelection
      );
      props.history.push(whereToRoute);
    }
    maxDate();
    minDate();
  }, []);

  const validateDob = () => {
    const { yourDOB } = formData;
    let errors = {},
      isValid = true;
    if (!yourDOB) {
      isValid = false;
      errors.yourDOB = t("my_profile.select_date_of_birth");
    } else delete errors.yourDOB;

    setFormData({ ...formData, errors: errors });
    return isValid;
  };

  const doSubmit = async () => {
    try {
      if (validateDob()) {
        const { yourDOB, applicationId, applicationType } = formData;
        let yourAge;
        if (underwriting?.policyType === "FINAL_EXPENSE_INSURANCE") {
          yourAge = calcAge(yourDOB);
        } else {
          yourAge = calcAgeOnMonth(yourDOB);
        }
        const splitpath = window.location.pathname.split("/");
        const request = {
          applicationId: applicationId,
          applicationType: applicationType,
          goalType: goalType,
          age: yourAge && yourAge,
          dob: yourDOB
            ? await encryptme(moment(yourDOB).format("L").toString())
            : null,
          resumeProcess: setProcessStep(splitpath[1], policyType, goalType),
        };
        const isAuthorized = getJwt() ? true : false;
        const apiEndPoint = API_URL_UNDERWRITING + "saveUnderWriting";
        const response = await commonApiCall(
          apiEndPoint,
          "post",
          request,
          UNDERWRITING_DETAILS,
          isAuthorized
        );
        if (response.status.code === 200) {
          const reduxStore = {
            person_age: yourAge,
          };
          await getAge(reduxStore);
          const eventObject = {
            agent_id: response?.data?.agentDetail?.agentId,
            application_id: applicationId,
            param_name: "Age",
            param_value: response?.data?.age,
          };
          segmentTracking("application_set_param", eventObject);
          if (goalType === "MEDICARE") {
            props.history.push(UnderWritingRoutes.ZIPCODE);
          } else {
            props.history.push(UnderWritingRoutes.YOUR_HEALTH);
          }
        } else {
          showErrorToast(response.status.message);
        }
      }
    } catch (err) {
      showErrorToast(t("validation_message.SOMETHING_WENT_WRONG"));
    }
  };
  const maxDate = () => {
    let date = new Date();
    if (
      underwriting &&
      underwriting.goalType === "INSURE" &&
      underwriting.policyType === "TERM_LIFE_INSURANCE"
    ) {
      date.setFullYear(date.getFullYear() - 18);
      setMaxDateOfCalendar(date);
    }
    if (underwriting && underwriting.goalType === "SAVE") {
      date.setFullYear(date.getFullYear() - 15);
      setMaxDateOfCalendar(date);
    } else {
      setMaxDateOfCalendar(date);
    }
  };
  const minDate = () => {
    let date = new Date();
    if (
      underwriting &&
      underwriting.goalType === "INSURE" &&
      underwriting.policyType === "FINAL_EXPENSE_INSURANCE"
    ) {
      date.setFullYear(date.getFullYear() - 85);
      setMinDateOfCalendar(date);
    } else {
      date.setFullYear(date.getFullYear() - 91);
      setMinDateOfCalendar(date);
    }
  };
  const { yourDOB, errors } = formData;
  return (
    <React.Fragment>
      <div className="steps your-age-page">
        <div className="title-header">
          <h2 className="page-title">{t("your_age.whats_your_birthday")}</h2>
          <h4>{t("your_age.we_ask_this_because")}</h4>
          {goalType === "INSURE" && policyType === "FINAL_EXPENSE_INSURANCE" ? (
            <h4>{t("your_age.final_expense_title")}</h4>
          ) : null}
        </div>
        <div className="row justify-content-center">
          <div className="col-12">
            <Animated
              animationIn="fadeInRight"
              animationOut="zoomOut"
              animationInDuration={800}
              animationOutDuration={800}
              isVisible={true}
            >
              <div className="white-box">
                <div className="col-md-6 mx-auto">
                  {/*Begin: Form Group - Date of Birth */}
                  <div className="form-group custom-datepicker">
                    <SelectDatepicker
                      selectedDate={yourDOB}
                      onDateChange={onDateChange}
                      minDate={
                        minDateOfCalendar
                          ? minDateOfCalendar
                          : new Date(1900, 0, 1)
                      }
                      maxDate={
                        maxDateOfCalendar ? maxDateOfCalendar : new Date()
                      }
                      labels={{ year: "YEAR", month: "MONTH", day: "DATE" }}
                    />
                    <span className="datepicker-label">
                      {t("my_profile.date_of_birth")}
                    </span>
                    <span className="text-danger ">{errors.yourDOB}</span>
                  </div>
                </div>
              </div>
            </Animated>
          </div>
          <div className="col-12 text-center my-3">
            <button
              className="btn btn-primary"
              type="button"
              onClick={doSubmit}
            >
              {t("next")}
            </button>
          </div>
          <div className="col-12 text-center mt-3">
            <Link
              to={UnderWritingRoutes.YOUR_GENDER}
              className="btn btn-link"
              title="Back"
            >
              {t("back")}
            </Link>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  underwriting: state.underwriting.underwriting,
});

export default connect(mapStateToProps, { commonApiCall, getAge })(
  withRouter(Age)
);
