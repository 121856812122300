import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

export const PolicyInfoLoader = () => {
  return (
    <div className="skeleton-loader mb-4">
      <SkeletonTheme>
        <div className="d-flex-between mt-3">
          <div style={{ lineHeight: 1 }}>
            <Skeleton count={1} height={36} width={200} />
          </div>
          <div style={{ lineHeight: 1 }}>
            <Skeleton count={1} height={36} width={40} />
            <spna className="ml-3">
              <Skeleton count={1} height={36} width={40} />
            </spna>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="mt-4 mb-4">
              <Skeleton count={1} height={70} />
            </div>
            <Skeleton count={1} height={1} />
          </div>
        </div>
        <div className="row" style={{ lineHeight: 4 }}>
          <div className="col-sm-6">
            <Skeleton count={2} height={40} />
          </div>
          <div className="col-sm-6">
            <Skeleton count={2} height={40} />
          </div>
        </div>

        <div className="mt-5" style={{ lineHeight: 1 }}>
          <Skeleton count={1} height={36} width={200} />
        </div>
        <div className="row">
          <div className="col-12">
            <div className="mt-4 mb-4">
              <Skeleton count={1} height={70} />
            </div>
            <Skeleton count={1} height={1} />
          </div>
        </div>
        <div className="row" style={{ lineHeight: 4 }}>
          <div className="col-sm-6">
            <Skeleton count={2} height={40} />
          </div>
          <div className="col-sm-6">
            <Skeleton count={2} height={40} />
          </div>
        </div>
      </SkeletonTheme>
    </div>
  );
};
