import React, { useEffect, useState } from "react";
import { Animated } from "react-animated-css";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { commonApiCall, getJwt } from "../../redux/actions/index";
import { API_URL_UNDERWRITING } from "../../config/configs";
import PolicyNavbar from "../../components/policyNavbar";
import { DashboardRoute, NumberFormatter, showErrorToast } from "../../utils";
import { PolicyInfoLoader } from "../../components/skeletonLoaders";

const PolicyInformation = ({ commonApiCall, t, ...props }) => {
  const [policyDetails, setPolicyDetails] = useState({
    carrierId: "",
    companyName: "",
    productName: "",
    productDescription: "",
    carrierName: "",
    frequency: "",
    basePremium: "",
    faceAmount: "",
    deathBenefit: "",
    url: "",
    pay: "",
    riderDisclaimerText: "",
  });
  const [riderInfo, setRiderInfo] = useState({});
  const [isLoading, setLoading] = useState(true);
  let id = localStorage.getItem("policyId");
  if (!id) {
    props.history.push(DashboardRoute.DASHBOARD);
  }

  const isAuthorized = getJwt() ? true : false;
  useEffect(() => {
    async function getBasicDetails() {
      try {
        const apiEndPoint =
          API_URL_UNDERWRITING +
          `policyDetail?anonymousApplicationCarrierId=${id}&role=client`;
        let response = await commonApiCall(
          apiEndPoint,
          "get",
          null,
          null,
          isAuthorized
        );
        if (response && response.status.code === 200) {
          let {
            companyName,
            productName,
            carrierName,
            frequency,
            basePremium,
            faceAmount,
            deathBenefit,
            pay,
            url,
            carrierId,
            productDescription,
            policyNumber,
            riderDisclaimerText,
          } = response.data;
          setRiderInfo(response.data.riderInfo);
          setPolicyDetails({
            ...policyDetails,
            companyName: companyName ? companyName : carrierName,
            productName: productName ? productName : "N/A",
            carrierName: carrierName ? carrierName : "N/A",
            frequency: frequency ? frequency : "N/A",
            basePremium: basePremium ? basePremium : "N/A",
            faceAmount: faceAmount ? faceAmount : "N/A",
            deathBenefit: deathBenefit ? deathBenefit : "N/A",
            policyNumber: policyNumber ? policyNumber : "N/A",
            productDescription: productDescription ? productDescription : "N/A",
            url: url ? url : "",
            pay: pay ? pay : "",
            carrierId: carrierId ? carrierId : "",
            riderDisclaimerText: riderDisclaimerText ? riderDisclaimerText : "",
          });
          setLoading(false);
        } else {
          showErrorToast(t("policy_information.something_went_wrong"));
        }
      } catch (err) {
        showErrorToast(t("policy_information.something_went_wrong"));
      }
    }
    getBasicDetails();
  }, []);

  let {
    companyName,
    frequency,
    basePremium,
    pay,
    deathBenefit,
    url,
    faceAmount,
    productName,
    productDescription,
    policyNumber,
    riderDisclaimerText,
  } = policyDetails;

  // const downLoadFile = (url) => {
  //   try {
  //     if (url) {
  //       window.open(url, "_blank");
  //     } else {
  //       return false;
  //     }
  //   } catch (err) {
  //     showErrorToast(t("policy_information.something_went_wrong"));
  //   }
  // };

  return (
    <React.Fragment>
      <div className="row">
        <div className="col-3 sidebar-part">
          <PolicyNavbar t={t}></PolicyNavbar>
        </div>
        <div className="col-lg-9">
          <Animated
            animationIn="fadeIn"
            animationOut="faderOutLeft"
            animationInDuration={1200}
            animationOutDuration={800}
            isVisible={true}
          >
            <div className="white-box2">
              {isLoading ? (
                <PolicyInfoLoader />
              ) : (
                <React.Fragment>
                  <div className="box-title no-border">
                    <h3 className="font-24">
                      {t("policy_information.policy_information")}
                    </h3>
                    {/* <div className="plan-icons-outer">
                      {url === "" || url === null ? (
                        <button className="btn btn-primary btn-lg disabled">
                          {t("policy_information.download_application")}
                        </button>
                      ) : (
                        <button
                          className="btn btn-primary btn-lg"
                          onClick={() => downLoadFile(url)}
                        >
                          {t("policy_information.download_application")}
                        </button>
                      )}
                    </div> */}
                  </div>
                  <div className="white-box2 with-border pb-0">
                    <div className="box-title">
                      <div className="with-semititle">
                        <h3>{productName.replace("15", pay)}</h3>
                        <h4 className="mb-0">
                          {productDescription ? productDescription : "N/A"}
                        </h4>
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-sm-6">
                        <div className="form-group filled-value">
                          <label>
                            {t("policy_information.issuing_company")}
                          </label>
                          <h4>{companyName}</h4>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group filled-value">
                          <label>{t("policy_information.policy_number")}</label>
                          <h4>{policyNumber}</h4>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="white-box2 with-border pb-0">
                    <div className="box-title">
                      <div className="with-semititle">
                        <h3>
                          {t("policy_information.initial_premium_information")}
                        </h3>
                      </div>
                      <h3 className="text-primary">
                        {NumberFormatter(basePremium, "$")}
                      </h3>
                    </div>
                    <div className="row mt-3">
                      <div className="col-sm-6">
                        <div className="form-group filled-value">
                          <label>
                            {t("policy_information.premium_payment_mode")}
                          </label>
                          <h4>{frequency}</h4>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group filled-value">
                          <label>{t("policy_information.base_premium")}</label>
                          <h4>{NumberFormatter(basePremium, "$")}</h4>
                        </div>
                      </div>
                      {riderInfo &&
                        riderInfo?.map((item) => (
                          <div className="col-sm-6">
                            <div className="form-group filled-value">
                              <label>{item}</label>
                              <h4>
                                {t("policy_information.included_in_premium")}
                              </h4>
                            </div>
                          </div>
                        ))}
                      <div className="col-sm-12">
                        <div className="form-group filled-value">
                          <label>{riderDisclaimerText}</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="white-box2 with-border pb-0 mb-0">
                    <div className="box-title">
                      <div className="with-semititle">
                        <h3>
                          {t("policy_information.base_policy_face_amount")}
                        </h3>
                      </div>
                      <h3 className="text-primary">
                        {NumberFormatter(
                          faceAmount ? faceAmount : deathBenefit,
                          "$"
                        )}
                      </h3>
                    </div>
                  </div>
                </React.Fragment>
              )}
            </div>
          </Animated>
        </div>
      </div>
    </React.Fragment>
  );
};

export default connect(null, { commonApiCall })(withRouter(PolicyInformation));
