import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import PolicyNavbar from "../../components/policyNavbar";
import { Animated } from "react-animated-css";
import { DocumentLoader } from "../../components/skeletonLoaders";
import { connect } from "react-redux";
import {
  getFileType,
  DashboardRoute,
  showErrorToast,
  truncate,
} from "../../utils";
import { API_URL_CHATSERVICE } from "../../config/configs";
import {
  commonApiCall,
  getJwt,
} from "../../redux/actions/index";

const ConversationDocument = ({ commonApiCall, t, ...props }) => {
  if (props.location.policyId) {
    localStorage.setItem("policyId", props.location.policyId);
  } else if (!localStorage.getItem("policyId")) {
    props.history.push(DashboardRoute.DASHBOARD);
  }
  let id = localStorage.getItem("policyId");

  useEffect(() => {
    getAttachments(id);
  }, []);

  const [fileListing, setFileListing] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const openDocuments = (url) => {
    try {
      window.open(url, "_blank");
    } catch (err) {
      showErrorToast(t("conversation_document.something_went_wrong"));
    }
  };

  const getAttachments = async (appId) => {
    try {
      const request = {};

      const apiEndPoint =
        API_URL_CHATSERVICE +
        "getDocuments?applicationCarrierId=" +
        appId +
        "&pageNo=0&pageSize=";
      const isAuthorized = getJwt() ? true : false;
      const response = await commonApiCall(
        apiEndPoint,
        "post",
        request,
        false,
        isAuthorized
      );
      if (response && response.status.code === 200) {
        if (response.data && response.data.chatConversation) {
          setFileListing(response.data.chatConversation);
          setIsLoading(false);
        }
      } else {
        setIsLoading(false);
      }
    } catch (err) {
      showErrorToast(t("conversation_document.something_went_wrong"));
    }
  };

  return (
    <React.Fragment>
      <div className="row">
        <div className="col-3 sidebar-part">
          <PolicyNavbar t={t}></PolicyNavbar>
        </div>
        <div className="col-lg-9">
          <Animated
            animationIn="fadeIn"
            animationOut="faderOutLeft"
            animationInDuration={1200}
            animationOutDuration={800}
            isVisible={true}
          >
            <div className="white-box2">
              <React.Fragment>
                <h3 className="font-24 mb-4 mt-3">{t("conversation_document.conversation_documents")}</h3>
                {fileListing && fileListing.length > 0 ? (
                  <div className="documents documents-card">
                    {isLoading ? (
                      <DocumentLoader />
                    ) : (
                        <div className="row">
                          {fileListing &&
                            fileListing.map((list, index) => {
                              return (
                                <div
                                  className="col-md-4 col-sm-6"
                                  onClick={() => openDocuments(list.uploadedDoc)}
                                  key={"doc" + index}
                                >
                                  <div className="w-100">
                                    {getFileType(
                                      list.uploadedDoc,
                                      truncate(list.uploadedDocName, 20),
                                      t("conversation_document.author") + " : " + list.ownerName
                                    )}
                                  </div>
                                </div>
                              );
                            })}
                        </div>
                      )}
                  </div>
                ) : (
                    ""
                  )}
              </React.Fragment>

              {fileListing && fileListing.length <= 0 && isLoading === false ? (
                <div className="text-center pt-3">
                  {" "}
                  {t("conversation_document.there_are_no_records_to_display")}{" "}
                </div>
              ) : (
                  ""
                )}
            </div>
          </Animated>
        </div>
      </div>
    </React.Fragment>
  );
};

export default connect(null, { commonApiCall })(
  withRouter(ConversationDocument)
);
