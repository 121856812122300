import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

export const BasicDetailLoader = () => {
  return (
    <div className="skeleton-loader mb-4">
      <SkeletonTheme>
        <div style={{ lineHeight: 1 }}>
          <Skeleton count={1} height={36} width={160} />
        </div>
        <div className="row" style={{ lineHeight: 4 }}>
          <div className="col-sm-4">
            <Skeleton count={3} height={40} />
          </div>
          <div className="col-sm-4">
            <Skeleton count={3} height={40} />
          </div>
          <div className="col-sm-4">
            <Skeleton count={3} height={40} />
          </div>
        </div>
      </SkeletonTheme>
    </div>
  );
};
