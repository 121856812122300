import {
  UNDERWRITING_DETAILS,
  COMPANY_INFORMATION,
  EMPTY_UNDERWRITING_DETAILS,
  ADD_UNDERWRITING_APP,
  PROFILE_PIC,
  DIRECT_LINK,
  QUESTIONARIES,
  AGE,
  ASSIGNED_AGENT,
  SBLI_PROGRESS_BAR,
  FACT_FINDER_QUESTION,
  SBLI_ARC_ID,
  SBLI_PROGRESS_COUNT,
  APPLICATION_CARRIERS_DETAILS,
  ASSURITY_PROGRESS_COUNT,
} from "../actions/types";

const INITIAL_STATE = {
  underwriting: {},
  companyInformation: {},
  profile_pic: {},
  direct_link: "",
  person_age: "",
  application: [],
  agentList: [],
  factFinderQuestion: {},
  progressBar: 0,
  progressCount: 0,
  assuProgressCount: {},
  arcId: null,
};

export const underwriting = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UNDERWRITING_DETAILS:
      return Object.assign({}, state, { underwriting: action.payload.data });

    case COMPANY_INFORMATION:
      return Object.assign({}, state, {
        companyInformation: action.payload.data,
      });
    case PROFILE_PIC:
      return Object.assign({}, state, { profile_pic: action.payload.value });
    case DIRECT_LINK:
      return Object.assign({}, state, { direct_link: action.payload });
    case QUESTIONARIES:
      return Object.assign({}, state, { questions: action.payload.value });
    case AGE:
      return Object.assign({}, state, {
        person_age: action.payload.value.person_age,
      });
    case EMPTY_UNDERWRITING_DETAILS:
      return Object.assign({}, state, { underwriting: {} });
    case ADD_UNDERWRITING_APP:
      return Object.assign({}, state, {
        application: "",
      });
    case ASSIGNED_AGENT:
      return Object.assign({}, state, {
        agentList: action.payload,
      });
    case FACT_FINDER_QUESTION:
      return Object.assign({}, state, {
        factFinderQuestion: action.payload,
      });
    case APPLICATION_CARRIERS_DETAILS:
      return Object.assign({}, state, {
        applicationCarrierInfo: action.payload.data,
      });
    case SBLI_PROGRESS_BAR:
      return Object.assign({}, state, {
        progressBar: action.payload.data,
      });
    case SBLI_PROGRESS_COUNT:
      return Object.assign({}, state, {
        progressCount: action.payload.data,
      });
    case SBLI_ARC_ID:
      return Object.assign({}, state, {
        arcId: action.payload.data,
      });
    case ASSURITY_PROGRESS_COUNT:
      return Object.assign({}, state, {
        assuProgressCount: action.payload.data,
      });
    default:
      return state;
  }
};
