import React, { useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

const AssurityPolicy = ({ ...props }) => {
  const policyNumber = new URLSearchParams(props.location.search).get("policy");
  const status = new URLSearchParams(props.location.search).get(
    "underWritingStatus"
  );
  return (
    <div className="sbli-main-question">
      <div className="text-center">
        <h1 className="page-title txt-black">Congratulations!</h1>
        <img
          src="/assets/images/trophy.png"
          className="img-fluid my-2"
          alt="trophy"
          title="Trophy"
          width="450"
        />
        <h3 className="text-black font-weight-bold">
          Policy No: {policyNumber}
        </h3>

        {status.toLowerCase() === "approved" ? (
          <p className="text-black py-4 w-75 mx-auto">
            The application submitted is approved as applied for, pending a
            quick internal review. Assuming everything is in order, the policy
            will be issued and out for delivery in the next few business days.
          </p>
        ) : (
          <p>
            The application submitted with the changes in coverage previously
            presented and as applied for is approved, pending a quick internal
            review. Assuming everything is in order, the policy will be issued
            and out for delivery in the next few business days.
          </p>
        )}

        {/* <button
        className="btn btn-green-outline transprant"
        onClick={() => history.push("/dashboard")}
      >
        Go to my account
      </button> */}
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {})(withRouter(AssurityPolicy));
