import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Animated } from "react-animated-css";
import moment from "moment";
import { isEmpty } from "lodash";
import NavBar from "../../components/navBar";
import {
  API_URL_CHATSERVICE,
  API_URL_UNDERWRITING,
} from "../../config/configs";
import { getJwt, commonApiCall, consoleLog } from "../../redux/actions/index";
import { showErrorToast } from "../../utils";
import { dateFormate } from "../../hooks/index";
import PrivateChat from "../../components/privateChat/index";

let stompClient, SockJS;

function Notifications({ commonApiCall, consumer, underwriting, t }) {
  const [notificationListing, setNotificationListing] = useState([]);
  const [formData, setFormData] = useState({
    policyData: {},
    chatModel: false,
  });

  const connect = async () => {
    let clientId =
      consumer && consumer.id ? consumer.id : underwriting?.clientUserId;
    try {
      const Stomp = require("stompjs");
      SockJS = require("sockjs-client");
      SockJS = new SockJS(API_URL_CHATSERVICE + "ws");
      stompClient = Stomp.over(SockJS);
      stompClient.debug = null;
      stompClient.connect(
        {
          clientUserId: clientId,
          type: "OTHERSCREEN",
        },
        function () {
          onConnected();
        },
        function () {
          onError();
        }
      );
    } catch (err) {
      showErrorToast(t("notification.something_went_wrong"));
    }
  };

  const onConnected = () => {
    let clientId =
      consumer && consumer.id ? consumer.id : underwriting?.clientUserId;
    try {
      if (stompClient.connected) {
        stompClient.subscribe(
          "/user/client-" + clientId + "/notify",
          function (responsePayload) {
            onMessageReceived(responsePayload);
          }
        );
      }
    } catch (err) {
      showErrorToast(t("notification.something_went_wrong"));
    }
  };

  const onError = (error) => {
    consoleLog(error, "error");
    consoleLog(
      "Could not connect you to the Chat Room Server. Please refresh this page and try again!"
    );
  };

  const onMessageReceived = (payload) => {
    try {
      if (payload) {
        const notification = JSON.parse(payload.body);
        if (notification) {
          let notificationArray = [];
          notificationArray.push(notification);
          setNotificationListing((prevNotificationListing) => {
            let returnNotificationArray = [];
            if (prevNotificationListing.length >= 3) {
              prevNotificationListing.pop();
              returnNotificationArray = [
                ...notificationArray,
                ...prevNotificationListing,
              ];
            } else {
              returnNotificationArray = [
                ...notificationArray,
                ...prevNotificationListing,
              ];
            }
            return returnNotificationArray;
          });
        }
      }
    } catch (err) {
      showErrorToast(t("notification.something_went_wrong"));
    }
  };

  const getNotifications = async () => {
    let clientId =
      consumer && consumer.id ? consumer.id : underwriting?.clientUserId;
    try {
      const request = {};
      const apiEndPoint =
        API_URL_CHATSERVICE +
        "notifications?clientUserId=" +
        clientId +
        "&isNotificationRead=true";
      const isAuthorized = getJwt() ? true : false;
      const response = await commonApiCall(
        apiEndPoint,
        "get",
        request,
        false,
        isAuthorized
      );
      if (response.status.code === 200) {
        setNotificationListing(response.data.notifications);
      } else {
        showErrorToast(response.status.message);
      }
    } catch (err) {
      showErrorToast(t("notification.something_went_wrong"));
    }
  };

  async function getPolicyDetails(id) {
    try {
      if (id) {
        let response = await commonApiCall(
          API_URL_UNDERWRITING +
            "client/policy/info?anonymousApplicationCarrierId=" +
            id,
          "get",
          "",
          "",
          true
        );
        if (response.status.code === 200) {
          setFormData({
            ...formData,
            policyData: response.data,
            chatModel: true,
          });
        }
      }
    } catch (err) {
      showErrorToast(t("notification.something_went_wrong"));
    }
  }

  const handleModel = (name, isHide) => {
    setFormData({ ...formData, [name]: isHide });
  };

  useEffect(() => {
    const isAuthorized = getJwt() ? true : false;

    if (isAuthorized && !isEmpty(consumer)) {
      connect();
      getNotifications();
    }
  }, [consumer]);

  useEffect(() => {
    return () => {
      if (stompClient && stompClient.connected) {
        stompClient.disconnect(function () {
          consoleLog("Disconnected. See you next time!");
        });
      }
    };
  }, []);

  const { policyData, chatModel } = formData;
  return (
    <div className="row">
      <div className="col-3 sidebar-part">
        <NavBar t={t}></NavBar>
      </div>
      <div className="col-lg-9">
        <Animated
          animationIn="fadeIn"
          animationOut="faderOutLeft"
          animationInDuration={1200}
          animationOutDuration={800}
          isVisible={true}
        >
          <div className="white-box2">
            <div className="box-title">
              <h3 className="font-24">{t("notification.notifications")}</h3>
            </div>
            {notificationListing && !isEmpty(notificationListing) ? (
              notificationListing.map((notification, key) => {
                return (
                  <div className="notification-outer" key={key}>
                    <div
                      className="notification-msg"
                      style={{
                        cursor: notification.applicationCarrierId
                          ? "pointer"
                          : "auto",
                      }}
                      onClick={() =>
                        getPolicyDetails(notification.applicationCarrierId)
                      }
                    >
                      <h4 className="notification-title">
                        {notification.data.subject}
                      </h4>
                      <h4 style={{ wordBreak: "break-word" }}>
                        {notification.data.message}
                      </h4>
                    </div>
                    <div className="notification-date">
                      <h6>
                        {moment(notification.createdDate).format("hh:mm a")} •{" "}
                        {dateFormate(notification.createdDate, "MM/DD/YYYY")}
                      </h6>
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="notification-outer">
                <div className="notification-msg">
                  {t("notification.there_are_no_notifications_to_display")}
                </div>
              </div>
            )}
          </div>
        </Animated>
        <div
          className={
            chatModel === false
              ? "conversation-popup"
              : "conversation-popup active"
          }
        >
          {chatModel && (
            <PrivateChat
              conversationDetails={policyData}
              handleModel={handleModel}
              chatModel={chatModel}
              t={t}
            />
          )}
        </div>
        <div
          className={
            chatModel === false ? "conv-overlay" : "conv-overlay active"
          }
        ></div>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => ({
  consumer: state.consumer.consumerdetails,
  underwriting: state.underwriting.underwriting,
});
export default connect(mapStateToProps, { commonApiCall })(Notifications);
