import React, { memo, useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  commonApiCall,
  getJwt,
  UNDERWRITING_DETAILS,
} from "../../../redux/actions";
import { encryptme, showErrorToast, UnderWritingRoutes } from "../../../utils";
import { API_URL_UNDERWRITING } from "../../../config/configs";

const TextFieldBlock = ({ question, onValueChange, answer }) => {
  return (
    <div className="form-group ">
      <input
        className="form-control"
        onChange={(e) => onValueChange(question, e)}
        type="text"
        // value={
        //   checkAnswerValue(question)
        //     ? checkAnswerValue(question).answerValue
        //     : ""
        // }
        placeholder="Enter Text"
      />
    </div>
  );
};

const YesNoBlock = ({ question, onValueChange, answer }) => {
  const checkAnswerValue = (question) => {
    const answerString = JSON.stringify(answer);
    if (answerString.indexOf(question.questionKey) > -1) {
      return question.subGroup
        ? answer[question.group][question.subGroup][question.questionKey]
        : answer[question.group][question.questionKey];
    } else {
      return null;
    }
  };

  return (
    <span className="custom-radio mt-3 d-block">
      <label className="m-0 mb-3" htmlFor={question.QuestionId + "ab"}>
        <input
          type="radio"
          name={question.QuestionId}
          onChange={(e) => onValueChange(question, e)}
          id={question.QuestionId + "ab"}
          value={"true"}
          checked={checkAnswerValue(question) === "true"}
        />
        <span></span> Yes
      </label>

      <label className="m-0" htmlFor={question.QuestionId + "ba"}>
        <input
          type="radio"
          name={question.QuestionId}
          id={question.QuestionId + "ba"}
          value={"false"}
          onChange={(e) => onValueChange(question, e)}
          checked={checkAnswerValue(question) === "false"}
        />
        <span></span> No
      </label>
    </span>
  );
};

const PreQuestions = ({
  t,
  commonApiCall,
  underwriting,
  onAnswered,
  ...props
}) => {
  const { applicationId, applicationType } = underwriting;
  const [currentIndex, setCurrentIndex] = useState(0);
  const [answers, setAnswers] = useState({});
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentIndex]);

  const questionsArr = [
    {
      QuestionId: 1,
      QuestionText:
        "During the past 12 months, has the insured been diagnosed with or treated for any type of internal cancer or melanoma?",
      AnswerCategory: 1,
      group: "healthBackground",
      subGroup: null,
      questionKey: "diagnosedOrTreatedForCancerOrMelanomaInPastYear",
    },
    {
      QuestionId: 2,
      QuestionText:
        "Has the insured ever been diagnosed with or treated for any of the following: <ul><li> Alzheimer’s disease </li> <li>Amyotrophic Lateral Sclerosis(ALS)</li> <li> Huntington’s Chorea </li> <li> Cystic Fibrosis </li>  <li> Down Syndrome </li> <li> AIDS/HIV </li>  <li>Cirrhosis of the liver </li><li> Multiple Myeloma </li> <li>Quadriplegia </li>",
      AnswerCategory: 1,
      group: "healthBackground",
      subGroup: null,
      questionKey: "hadAnyListedMedicalConditions",
    },
    {
      QuestionId: 3,
      QuestionText:
        "During the past six months, has the insured been diagnosed with or treated for a heart attack, stroke, or irregular heartbeat/arrhythmia?",
      AnswerCategory: 1,
      group: "healthBackground",
      subGroup: null,
      questionKey: "heartAttackOrStrokeOrIrregularHeartbeatInPast6Months",
    },
    {
      QuestionId: 4,
      QuestionText:
        "Does the insured currently require assistance with any activities of daily living (ADLs) including eating, bathing, dressing, toileting, or transferring?",
      AnswerCategory: 1,
      group: "healthBackground",
      subGroup: null,
      questionKey: "requiredAssistanceWithDailyActivities",
    },
    {
      QuestionId: 5,
      QuestionText:
        "During the past 5 years, has the insured used narcotics, barbiturates, amphetamines, hallucinogens, heroin, cocaine, or other habit forming drugs?",
      AnswerCategory: 1,
      group: "healthBackground",
      subGroup: null,
      questionKey: "usedControlledSubstancesInPast5Years",
    },
    {
      QuestionId: 6,
      QuestionText:
        "Is the insured currently an active member of the military, military reserve, or National Guard?",
      AnswerCategory: 1,
      group: "militaryBackground",
      subGroup: null,
      questionKey: "isActiveMember",
    },
    {
      QuestionId: 7,
      QuestionText:
        "Has the insured entered into a written agreement to become an active member of the military, military reserve, or National Guard?",
      AnswerCategory: 1,
      group: "militaryBackground",
      subGroup: null,
      questionKey: "hasWrittenAgreementToBecomeAnActiveMember",
    },
    /*  {
       QuestionId: 8,
       QuestionText: "Does the insured have permanent resident status in the United States?",
       AnswerCategory: 1,
       group: "person",
       subGroup: null,
       questionKey: "isCitizen",
     }, */
    {
      QuestionId: 8,
      QuestionText: "What is your current height?",
      AnswerCategory: 4,
      group: "person",
      subGroup: "height",
      questionKey: ["feet", "inches"],
    },
    {
      QuestionId: 9,
      QuestionText: "What is your current weight?",
      AnswerCategory: 4,
      group: "person",
      subGroup: null,
      questionKey: "weightInPounds",
    },

    {
      QuestionId: 10,
      QuestionText:
        "During the past 10 years, has the primary insured plead guilty to or been convicted of a felony?",
      AnswerCategory: 1,
      group: "criminalBackground",
      subGroup: null,
      questionKey: "felonyInPast10Years",
    },
  ];

  const getFieldType = (question) => {
    switch (question.AnswerCategory) {
      case 1:
        return (
          <YesNoBlock
            onValueChange={onAnswerChange}
            answer={answers}
            question={question}
          />
        );
      case 4:
        return (
          <TextFieldBlock answer={answers} onValueChange={onAnswerChange} />
        );

      default:
        return console.log("error");
    }
  };

  const checkWeightAnswerValue = (question) => {
    const answerString = JSON.stringify(answers);
    if (answerString.indexOf(question.questionKey) > -1) {
      return question.subGroup
        ? answers[question.group][question.subGroup][question.questionKey]
        : answers[question.group][question.questionKey];
    } else {
      return "";
    }
  };

  const onQuestionChange = (question) => {
    if (currentIndex < questionsArr.length - 1 && validateQuestions(question)) {
      setCurrentIndex(currentIndex === 7 ? currentIndex + 2 : currentIndex + 1);
    }

    if (currentIndex === questionsArr.length - 1) {
      onAnswered(questionsArr.length);
      onSubmit();
      //   props.history.push(UnderWritingRoutes.ASSURITY_USER_DETAIL);
    }
  };

  const onBackQuestion = () => {
    if (currentIndex > 0) {
      setCurrentIndex(
        currentIndex === 9 ? currentIndex - 2 : currentIndex - 1
      );
    }
  };

  const onAnswerChange = (question, e) => {
    let tempAnswers = { ...answers };
    if (!JSON.stringify(tempAnswers).includes(question.questionKey)) {
    }
    if (!tempAnswers.hasOwnProperty(question.group))
      tempAnswers[question.group] = {};

    if (question.subGroup && !tempAnswers.hasOwnProperty(question.subGroup))
      tempAnswers[question.group][question.subGroup] = {};

    if (question.subGroup) {
      e.target.value === ""
        ? delete tempAnswers[question.group][question.subGroup][
        question.questionKey
        ]
        : (tempAnswers[question.group][question.subGroup][
          question.questionKey
        ] = e.target.value);
    } else {
      e.target.value === ""
        ? delete tempAnswers[question.group][question.questionKey]
        : (tempAnswers[question.group][question.questionKey] = e.target.value);
    }
    onAnswered(currentIndex + 1);
    setAnswers(tempAnswers);
  };

  const validateQuestions = (question) => {
    let tempErrors = { ...errors };
    const answerString = JSON.stringify(answers);
    if (
      !Array.isArray(question.questionKey) &&
      answerString.indexOf(question.questionKey) > -1
    ) {
      delete tempErrors["question_" + question.QuestionId];
      setErrors(tempErrors);
      return true;
    }
    if (currentIndex === 7) {
      let filterNon = question.questionKey.filter(
        (x) => answerString.indexOf(x) < 0
      );
      let validateWeight = answerString.indexOf("weightInPounds") > -1;
      if (filterNon.length === 0) {
        delete tempErrors["question_" + question.QuestionId];
        document
          .getElementById("attr-question-head-inches")
          .classList.remove("text-danger");
        document
          .getElementById("attr-question-head-feet")
          .classList.remove("text-danger");
      }
      else {
        if (filterNon.includes("feet") && !filterNon.includes("inches")) {
          document
            .getElementById("attr-question-head-feet")
            .classList.add("text-danger");
          document
            .getElementById("attr-question-head-inches")
            .classList.remove("text-danger");
        }
        else if (!filterNon.includes("feet") && filterNon.includes("inches")) {
          document
            .getElementById("attr-question-head-inches")
            .classList.add("text-danger");
          document
            .getElementById("attr-question-head-feet")
            .classList.remove("text-danger");
        }
        else {
          document
            .getElementById("attr-question-head-inches")
            .classList.add("text-danger");
          document
            .getElementById("attr-question-head-feet")
            .classList.add("text-danger");
        }
      }
      if (validateWeight) {
      
        delete tempErrors["question_" + 9];
        setErrors(tempErrors);
        document
          .getElementById("attr-question-head-weight")
          .classList.remove("text-danger");
      } else {
        tempErrors["question_" + 9] = "field required";
        document
          .getElementById("attr-question-head-weight")
          .classList.add("text-danger");
      }
      return filterNon.length === 0 && validateWeight ? true : false;
    };
    tempErrors["question_" + question.QuestionId] = "field required";

    setErrors(tempErrors);
    return false;

  };

  const onSubmit = async () => {
    setLoading(true);
    try {
      let request = {};
      request.id = underwriting?.applicationId;
      request.insured = answers;
      request.insured.person = { ...request.insured.person, isCitizen: "true" };

      const isAuthorized = getJwt() ? true : false;
      const apiEndPoint =
        API_URL_UNDERWRITING +
        "prequalifications?applicationId=" +
        underwriting?.applicationId;
      const response = await commonApiCall(
        apiEndPoint,
        "post",
        [request],
        null,
        isAuthorized
      );
      if (response.status.code === 200) {
        const data = response.data?.data
          ? JSON.parse(response.data.data)
          : false;
        if (data && data[0].isEligible) {
          saveUnderwriteDetails();
        } else {
          props.history.push(UnderWritingRoutes.ASSURITY_PRE_DENIED);
        }
      }
    } catch (err) {
      showErrorToast(err);
    }
    setLoading(false);
  };

  const onHeightChange = (question, type, value) => {
    let tempAnswers = { ...answers };
    if (
      !JSON.stringify(tempAnswers).includes("inches") &&
      !JSON.stringify(tempAnswers).includes("feet")
    ) {
    }
    if (!tempAnswers.hasOwnProperty(question.group))
      tempAnswers[question.group] = {};

    if (question.subGroup && tempAnswers.hasOwnProperty("person")) {
      if (!tempAnswers["person"].hasOwnProperty("height"))
        tempAnswers[question.group][question.subGroup] = {};
    }

    let questionKey = type === "inch" ? "inches" : "feet";
    if (question.subGroup) {
      tempAnswers[question.group][question.subGroup][questionKey] = value;
    } else {
      tempAnswers[question.group][questionKey] =
        type === "inch"
          ? tempAnswers[question.group][question.subGroup][questionKey].split(
            "."
          )[0] +
          "." +
          value
          : value + ".";
    }

    onAnswered(currentIndex);
    setAnswers(tempAnswers);
  };

  const getHeightAnswers = (type) => {
    if (
      answers?.hasOwnProperty("person") &&
      answers?.person.hasOwnProperty("height") &&
      answers.person.height.hasOwnProperty(type)
    ) {
      return answers["person"]["height"][type];
    }
  };
  const saveUnderwriteDetails = async () => {
    try {
      const request = {
        applicationId: underwriting?.applicationId,
        applicationType: "Web",
        nextStep: "24,ASSURITY_PRE_APPROVAL",
        weight: encryptme(answers.person?.weightInPounds),
        height: encryptme(
          answers.person.height.feet + "." + answers.person.height.inches
        ),
      };

      const isAuthorized = getJwt() ? true : false;
      const apiEndPoint = API_URL_UNDERWRITING + "saveUnderWriting";
      const response = await commonApiCall(
        apiEndPoint,
        "post",
        request,
        UNDERWRITING_DETAILS,
        isAuthorized
      );

      if (response.status.code === 200) {
        props.history.push(UnderWritingRoutes.ASSURITY_PRE_APPROVAL);
      }
    } catch (err) {
      showErrorToast(err);
    }
  };

  // const getHeightValue = () => {};

  return (
    <div className="pre-questions-container sbli-question-container">
      <div className="container">
        {currentIndex !== 7 && (
          <div class="sbli-main-question  mt-5 ">
            <div class="sbli-question-container">
              <div
                className={
                  "sbli-parent-question mb-4 question_" +
                  questionsArr[currentIndex].QuestionId +
                  (errors.hasOwnProperty(
                    `question_${questionsArr[currentIndex].QuestionId}`
                  )
                    ? " validate-question-parent"
                    : " ")
                }
                id="sbli_question_container"
              >
                {currentIndex === 1 ? (
                  <h4
                    className="mb-4 parent_question_head"
                    dangerouslySetInnerHTML={{
                      __html: questionsArr[currentIndex].QuestionText,
                    }}
                  />
                ) : (
                  <h4 className="mb-4 parent_question_head">
                    {questionsArr[currentIndex].QuestionText}
                  </h4>
                )}

                {getFieldType(questionsArr[currentIndex])}
                {/* <span className="custom-radio mt-3 d-block">
                <label className="m-0 mb-3" for="1260ab">
                  <input type="radio" name="1260" id="1260ab" value="1" />
                  <span></span> Yes
                </label>
                <label className="m-0" for="1260ba">
                  <input type="radio" name="1260" id="1260ba" value="0" />
                  <span></span> No
                </label>
              </span>{" "} */}
              </div>
            </div>
          </div>
        )}

        {currentIndex === 7 && (
          <div
            className={
              "attr-question mt-5 " +
              (errors.hasOwnProperty(
                `question_${questionsArr[currentIndex].QuestionId}`
              )
                ? " validate-question-parent"
                : " ")
            }
          >
            <h4 className={"text-center mb-5 "} id="attr-question-head">
              {questionsArr[currentIndex].QuestionText}
            </h4>
            <h4 className="text-center mb-4" id="attr-question-head-feet">
              Feet
            </h4>
            <div className="height-box mb-5 ">
              {[...Array(9).keys()].map((_, index) => {
                return (
                  <div
                    className={
                      "unit-box text-dark " +
                      (getHeightAnswers("feet")
                        ? getHeightAnswers("feet") === index + 1
                          ? "active"
                          : ""
                        : "")
                    }
                    onClick={() =>
                      onHeightChange(
                        questionsArr[currentIndex],
                        "feet",
                        index + 1
                      )
                    }
                    key={index}
                  >
                    {index + 1}
                  </div>
                );
              })}
            </div>
            <h4 className="text-center mb-4" id="attr-question-head-inches">Inches</h4>
            <div className="height-box">
              {[...Array(12).keys()].map((_, index) => {
                return (
                  <div
                    className={
                      "unit-box text-dark " +
                      (getHeightAnswers("inches") !== null ||
                        getHeightAnswers("inches") !== undefined
                        ? getHeightAnswers("inches") === index
                          ? "active"
                          : ""
                        : "")
                    }
                    onClick={() =>
                      onHeightChange(questionsArr[currentIndex], "inch", index)
                    }
                    key={index}
                  >
                    {index}
                  </div>
                );
              })}
            </div>
            <div className="form-group mt-5 w-50 mx-auto pt-4">
              <div className="input-effect">
                <input
                  className={
                    "form-control " +
                    (errors && errors["question_9"] ? "is-invalid" : "")
                  }
                  placeholder={"Weight (lbs)"}
                  id="weight_ques"
                  type="number"
                  onChange={(e) =>
                    onAnswerChange(questionsArr[currentIndex + 1], e)
                  }
                  value={checkWeightAnswerValue(questionsArr[currentIndex + 1])}
                />
                <label htmlFor="weight_ques" id="attr-question-head-weight">{"Weight (lbs)"}</label>
                <em className="input-icon"></em>
              </div>
            </div>
          </div>
        )}

        <div className="w-100 mb-4 mt-2 text-center">
          <button
            className="btn btn-default px-5"
            disabled={loading}
            onClick={() => onQuestionChange(questionsArr[currentIndex])}
          >
            {loading ? "Processing..." : "Next"}
          </button>
        </div>
        {currentIndex > 0 && (
          <div className="w-100 text-center">
            <button className="btn btn-link px-5" onClick={onBackQuestion}>
              Back
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  underwriting: state.underwriting.underwriting,
});
export default connect(mapStateToProps, {
  commonApiCall,
})(withRouter(PreQuestions));
