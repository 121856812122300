import React from "react";
import { toast } from "react-toastify";
import NumberFormat from "react-number-format";
import Select from "react-select";

import Autocomplete from "react-autocomplete";
import moment from "moment-timezone";
import { PDFDocument, StandardFonts } from 'pdf-lib';

toast.configure();

/**********************************************************************
 @PURPOSE : We can use following function to store data in localstorage
//Validations and
//Messages
//*********************************************************************/

/*************************************************************************************
                            To Format Numbers
  **************************************************************************************/
export const NumberFormatter = (amount, preFix = "", sufFix = "") => {
  const revisedAmount = amount === 0 || amount === -0 ? 0 : amount;
  return (
    <NumberFormat
      prefix={preFix}
      suffix={sufFix}
      decimalScale={2}
      value={revisedAmount}
      displayType={"text"}
      thousandSeparator={true}
      fixedDecimalScale={true}
      renderText={(value) => <span>{value}</span>}
    />
  );
};
/*************************************************************************************/
/*************************************************************************************
                                 Name Validation
  **************************************************************************************/
export const validateName = (name) => {
  const pattern = new RegExp(/^[ A-Za-z0-9_@./'#&+-+\w\s]{1,50}$/);
  return pattern.test(name);
};
/*************************************************************************************
                                  Store Data To local Storage
  **************************************************************************************/
export const setItem = (key, value) => {
  localStorage.setItem(key, value);
};
/*************************************************************************************/

/*************************************************************************************
                             Get Data From local Storage
 **************************************************************************************/
export const getItem = (key) => {
  return localStorage.getItem(key);
};
/*************************************************************************************
                               Remove Data in local Storage
 **************************************************************************************/
export const removeItem = (key) => {
  localStorage.removeItem(key);
};
/*************************************************************************************/

/*************************************************************************************
                             Email Validation
  **************************************************************************************/
export const validateEmail = (email) => {
  const pattern = new RegExp(
    /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
  return pattern.test(email);
};
/*************************************************************************************/

/*************************************************************************************
                             Otp Validation
  **************************************************************************************/
export const validateOtp = (otp) => {
  const pattern = new RegExp(/^[0-9]{1,6}$/);
  return pattern.test(otp);
};
/*************************************************************************************/

/*************************************************************************************
                             Password Validation
  **************************************************************************************/
export const validatePassword = (pass) => {
  // var pattern = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d@$!%*?&]{4,}$/);

  const pattern = new RegExp(/^.{6,}$/);
  return pattern.test(pass);
};
/*************************************************************************************/

/*************************************************************************************
                             Mobile Number Validation
  **************************************************************************************/
export const validateMobileNumber = (mobileNo) => {
  var pattern = new RegExp(/^[0-9]\d{9}$|^[0-9]\d{9}$/);

  return pattern.test(mobileNo);
};
/*************************************************************************************/

/*************************************************************************************
                              Character Validation
  **************************************************************************************/
export const allowChar = (e) => {
  const pattern = new RegExp(/^[a-zA-Z\s]{0,255}$/);
  return pattern.test(e);
};
/*************************************************************************************/

/*************************************************************************************
                              Number Validation
  **************************************************************************************/
export const allowNumbers = (e) => {
  const pattern = new RegExp(/^[0-9\b]+$/);
  return pattern.test(e);
};
/*************************************************************************************/
/*******************************************************************************************/

// /*************************************************************************************
//                            PinCode Validate
// **************************************************************************************/
// // for pincode number validate
// validatePincodeNumber(mobileNo) {
//     var pattern = new RegExp(/^[0-9]{5}(?:-[0-9]{4})?$/);
//     return pattern.test(mobileNo);
// },
/*************************************************************************************
                            Set success Toast Message
  **************************************************************************************/
export const showSuccessToast = (msg) => {
  toast.success(msg, {
    position: "top-right",
    autoClose: 10000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
  });
};
/*************************************************************************************/

/*************************************************************************************
                            Set Error Toast Message
  **************************************************************************************/
export const showErrorToast = (msg) => {
  toast.error(msg, {
    position: "top-right",
    autoClose: 10000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
  });
};
/*************************************************************************************/

/*************************************************************************************
                            Set WARNING Toast Message
  **************************************************************************************/
export const showWarnToast = (msg) => {
  toast.warn(msg, {
    position: "top-right",
    autoClose: 10000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
  });
};
/*************************************************************************************/

export const addDefaultSrcForChat = (ev) => {
  ev.target.src = "/assets/images/noimage-1.jpg";
};

/*************************************************************************************
                            Custom Filter for Selectbox
  **************************************************************************************/
export const customFilter = (option, searchText) => {
  if (option.data.label.toLowerCase().startsWith(searchText.toLowerCase())) {
    return true;
  } else {
    return false;
  }
};
/*************************************************************************************/

/*************************************************************************************
                            Truncate Name
  **************************************************************************************/
export const truncate = (textstring, n) => {
  if (textstring.length <= n) {
    return textstring;
  }
  let subString = textstring.substr(0, n - 1);
  return subString + "...";
};

// get cookie value

export const getCookieValue = (cookie = null) => {
  if (cookie) {
    const _cookie = document.cookie.split(";");
    const value = _cookie.filter((x) => x.includes(cookie));
    const trimValue = value.length > 0 ? value[0].trim().split("=")[1] : "";
    return trimValue;
  } else {
    return "";
  }
};

export const googleTagManager = () => {
  let intercom = document.createElement("script");
  intercom.type = "text/javascript";
  intercom.async = true;
  intercom.onload = (function () {
    (function (w, d, s, l, i) {
      w[l] = w[l] || [];
      w[l].push({
        "gtm.start": new Date().getTime(),
        event: "gtm.js",
      });
      var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s),
        dl = l != "dataLayer" ? "&l=" + l : "";
      j.async = true;
      j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
      f.parentNode.insertBefore(j, f);
    })(window, document, "script", "dataLayer", "GTM-NW889FK");
  })();
  document.head.appendChild(intercom);
};

export const socialMediaEmbeddedTag = (
  REACT_APP_FACEBOOK_DOMAIN_SUREVESTED
) => {
  let socialMedia = document.createElement("script");
  socialMedia.type = "text/javascript";
  socialMedia.async = true;
  socialMedia.onload = (function () {
    if (process.env.REACT_APP_NODE_ENV === "production") {
      let meta = document.createElement("meta");
      meta.setAttribute("name", "facebook-domain-verification");
      meta.setAttribute("content", REACT_APP_FACEBOOK_DOMAIN_SUREVESTED);
      document.getElementsByTagName("head")[0].appendChild(meta);
    }
  })();
  document.head.appendChild(socialMedia);
};

export const segmentTrackingMethod = (
  iLifeSegmentKey,
  surevestedSegmentKey
) => {
  let segment = document.createElement("script");
  segment.type = "text/javascript";
  segment.async = true;
  let segmentAPIKey = iLifeSegmentKey;
  const domainNamePath = window.location.hostname;
  const domainName = domainNamePath.split(".");
  if (domainName.includes("surevested")) {
    segmentAPIKey = surevestedSegmentKey;
  }
  segment.onload = !(function () {
    var analytics = (window.analytics = window.analytics || []);
    if (!analytics.initialize)
      if (analytics.invoked)
        window.console &&
          console.error &&
          console.error("Segment snippet included twice.");
      else {
        analytics.invoked = !0;
        analytics.methods = [
          "trackSubmit",
          "trackClick",
          "trackLink",
          "trackForm",
          "pageview",
          "identify",
          "reset",
          "group",
          "track",
          "ready",
          "alias",
          "debug",
          "page",
          "once",
          "off",
          "on",
          "addSourceMiddleware",
          "addIntegrationMiddleware",
          "setAnonymousId",
          "addDestinationMiddleware",
        ];
        analytics.factory = function (e) {
          return function () {
            var t = Array.prototype.slice.call(arguments);
            t.unshift(e);
            analytics.push(t);
            return analytics;
          };
        };
        for (var e = 0; e < analytics.methods.length; e++) {
          var key = analytics.methods[e];
          analytics[key] = analytics.factory(key);
        }
        analytics.load = function (key, e) {
          var t = document.createElement("script");
          t.type = "text/javascript";
          t.async = !0;
          t.src =
            "https://cdn.segment.com/analytics.js/v1/" +
            key +
            "/analytics.min.js";
          var n = document.getElementsByTagName("script")[0];
          n.parentNode.insertBefore(t, n);
          analytics._loadOptions = e;
        };
        analytics._writeKey = segmentAPIKey;
        analytics.SNIPPET_VERSION = "4.13.2";
        analytics.load(segmentAPIKey, { obfuscate: true });
        analytics.page();
      }
  })();
  document.head.appendChild(segment);
};

/*************************************************************************************/
/**
 *
 * @param {fileType, fileName}
 */
export const getFileType = (fileType, fileName, authName = "") => {
  let fileExe = fileType.slice(((fileType.lastIndexOf(".") - 1) >>> 0) + 2);
  switch (fileExe) {
    case "pdf":
      return (
        <React.Fragment>
          <div className="document-row with-border">
            <div className="document-icon material-icons pdf-icon">
              picture_as_pdf
            </div>
            <div className="document-content">
              <div className="document-name">{fileName}</div>
            </div>
            <div className="document-content">
              <div className="document-name">{authName}</div>
            </div>
          </div>
        </React.Fragment>
      );
    // break;
    case "doc":
      return (
        <React.Fragment>
          <div className="document-row with-border">
            <div className="document-icon svg-icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 48 48"
                width="22px"
                height="22px"
              >
                <path
                  fill="#2196F3"
                  d="M41,10H25v28h16c0.553,0,1-0.447,1-1V11C42,10.447,41.553,10,41,10z"
                />
                <path
                  fill="#FFF"
                  d="M25 15.001H39V17H25zM25 19H39V21H25zM25 23.001H39V25.001H25zM25 27.001H39V29H25zM25 31H39V33.001H25z"
                />
                <path fill="#0D47A1" d="M27 42L6 38 6 10 27 6z" />
                <path
                  fill="#FFF"
                  d="M21.167,31.012H18.45l-1.802-8.988c-0.098-0.477-0.155-0.996-0.174-1.576h-0.032c-0.043,0.637-0.11,1.162-0.197,1.576l-1.85,8.988h-2.827l-2.86-14.014h2.675l1.536,9.328c0.062,0.404,0.111,0.938,0.143,1.607h0.042c0.019-0.498,0.098-1.051,0.223-1.645l1.97-9.291h2.622l1.785,9.404c0.062,0.348,0.119,0.846,0.17,1.511h0.031c0.02-0.515,0.073-1.035,0.16-1.563l1.503-9.352h2.468L21.167,31.012z"
                />
              </svg>
            </div>
            <div className="document-content">
              <div className="document-name">{fileName}</div>
            </div>
            <div className="document-content">
              <div className="document-name">{authName}</div>
            </div>
          </div>
        </React.Fragment>
      );
    // break;

    case "docx":
      return (
        <React.Fragment>
          <div className="document-row with-border">
            <div className="document-icon svg-icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 48 48"
                width="22px"
                height="22px"
              >
                <path
                  fill="#2196F3"
                  d="M41,10H25v28h16c0.553,0,1-0.447,1-1V11C42,10.447,41.553,10,41,10z"
                />
                <path
                  fill="#FFF"
                  d="M25 15.001H39V17H25zM25 19H39V21H25zM25 23.001H39V25.001H25zM25 27.001H39V29H25zM25 31H39V33.001H25z"
                />
                <path fill="#0D47A1" d="M27 42L6 38 6 10 27 6z" />
                <path
                  fill="#FFF"
                  d="M21.167,31.012H18.45l-1.802-8.988c-0.098-0.477-0.155-0.996-0.174-1.576h-0.032c-0.043,0.637-0.11,1.162-0.197,1.576l-1.85,8.988h-2.827l-2.86-14.014h2.675l1.536,9.328c0.062,0.404,0.111,0.938,0.143,1.607h0.042c0.019-0.498,0.098-1.051,0.223-1.645l1.97-9.291h2.622l1.785,9.404c0.062,0.348,0.119,0.846,0.17,1.511h0.031c0.02-0.515,0.073-1.035,0.16-1.563l1.503-9.352h2.468L21.167,31.012z"
                />
              </svg>
            </div>
            <div className="document-content">
              <div className="document-name">{fileName}</div>
            </div>
            <div className="document-content">
              <div className="document-name">{authName}</div>
            </div>
          </div>
        </React.Fragment>
      );

    case "xlsx":
      return (
        <React.Fragment>
          <div className="document-row with-border">
            <div className="document-icon svg-icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 48 48"
                width="22px"
                height="22px"
              >
                <path
                  fill="#4CAF50"
                  d="M41,10H25v28h16c0.553,0,1-0.447,1-1V11C42,10.447,41.553,10,41,10z"
                />
                <path
                  fill="#FFF"
                  d="M32 15H39V18H32zM32 25H39V28H32zM32 30H39V33H32zM32 20H39V23H32zM25 15H30V18H25zM25 25H30V28H25zM25 30H30V33H25zM25 20H30V23H25z"
                />
                <path fill="#2E7D32" d="M27 42L6 38 6 10 27 6z" />
                <path
                  fill="#FFF"
                  d="M19.129,31l-2.411-4.561c-0.092-0.171-0.186-0.483-0.284-0.938h-0.037c-0.046,0.215-0.154,0.541-0.324,0.979L13.652,31H9.895l4.462-7.001L10.274,17h3.837l2.001,4.196c0.156,0.331,0.296,0.725,0.42,1.179h0.04c0.078-0.271,0.224-0.68,0.439-1.22L19.237,17h3.515l-4.199,6.939l4.316,7.059h-3.74V31z"
                />
              </svg>
            </div>
            <div className="document-content">
              <div className="document-name">{fileName}</div>
            </div>
            <div className="document-content">
              <div className="document-name">{authName}</div>
            </div>
          </div>
        </React.Fragment>
      );
    // break;
    case "zip":
      return (
        <React.Fragment>
          <div className="document-row with-border">
            <div className="document-icon svg-icon">
              <svg height="40px" width="40px" version="1.1" viewBox="0 0 40 40">
                <title>ZIP</title>
                <desc>Created with Sketch.</desc>
                <defs>
                  <path
                    id="path-1"
                    d="m0 39.814v-39.814l13.567 0.42274v1.6666h1.854v1.6665h-1.854v1.6666h1.854v1.6666h-1.854v1.6665h1.854v1.6665h-1.854v1.667h1.854v1.666h-1.854v1.667h1.854v1.666h-1.854v1.667h1.854v1.666h-1.854v1.667h0.927c-0.512 0-0.927 0.373-0.927 0.833v8.333c0 0.461 0.415 0.833 0.927 0.833h3.707c0.512 0 0.926-0.372 0.926-0.833v-8.333c0-0.46-0.414-0.833-0.926-0.833h-0.927v-1.667h1.853v-1.666h-1.853v-1.667h1.853v-1.666h-1.853v-1.667h1.853v-1.666h-1.853v-1.667h1.853v-1.6665h-1.853v-1.6665h1.853v-1.6666h-1.853v-1.6666h1.853v-1.6665h-1.853v-1.6666l1.853 0.00004h5.561 8.267c0 0.92036-1.024 1.6666 0 1.6666v8.3327 29.392h-32.955z"
                  />
                </defs>
                <g fillRule="evenodd" fill="none">
                  <g transform="translate(4)">
                    <mask id="mask-2" fill="white">
                      <use href="#path-1" />
                    </mask>
                    <path
                      fill="#F4AD49"
                      mask="url(#mask-2)"
                      d="m0 4.9161v31.329c0 2.154 1.7723 3.916 3.9384 3.916h23.631c2.166 0 3.939-1.762 3.939-3.916v-23.497l-11.816-11.748h-15.754c-2.1657 0-3.938 1.7622-3.938 3.9161z"
                    />
                  </g>
                </g>
              </svg>
            </div>
            <div className="document-content">
              <div className="document-name">{fileName}</div>
            </div>
            <div className="document-content">
              <div className="document-name">{authName}</div>
            </div>
          </div>
        </React.Fragment>
      );
    // break;
    case "png":
      return (
        <React.Fragment>
          <div className="document-row with-border">
            <div className="document-icon svg-icon">
              <svg viewBox="0 0 1024 1024">
                <g>
                  <g>
                    <path
                      d="M146.901,249.004V964c0,33,27,60,60,60h613.857c33,0,60-27,60-60V60c0-33-27-60-60-60H395.906"
                      fill="#F1C40F"
                    />
                    <g>
                      <path
                        d="M146.901,249.004h189.005c33,0,60-27,60-60V0"
                        fill="#F39C12"
                      />
                    </g>
                  </g>
                </g>
                <g>
                  <path
                    d="M81.83,670.833h65.071V585l-70.946,88.375h0.012C77.167,671.805,79.198,670.833,81.83,670.833z"
                    fill="#262626"
                  />
                  <path
                    d="M945.83,670.833h-65.071V585l70.946,88.375h-0.012C950.493,671.805,948.462,670.833,945.83,670.833z"
                    fill="#262626"
                  />
                  <path
                    d="M884.273,861.471c-1.934,5.149-8.015,9.362-13.515,9.362H156.901c-5.5,0-11.582-4.213-13.514-9.362   L75.344,680.195c-1.933-5.149,0.986-9.362,6.486-9.362h864c5.5,0,8.419,4.213,6.486,9.362L884.273,861.471z"
                    fill="#F39C12"
                  />
                </g>
                <g>
                  <path
                    d="M312.407,818.323c0-5.013,1.484-8.881,4.454-11.606c2.97-2.724,7.287-4.087,12.955-4.087   c5.395,0,9.535,1.363,12.424,4.087c2.888,2.726,4.332,6.594,4.332,11.606c0,4.903-1.485,8.732-4.455,11.483   c-2.971,2.751-7.069,4.127-12.301,4.127c-5.395,0-9.645-1.362-12.75-4.086C313.96,827.123,312.407,823.282,312.407,818.323z"
                    fill="#FFFFFF"
                  />
                  <path
                    d="M453.397,750.402c0,13.351-3.938,23.662-11.811,30.937c-7.874,7.273-19.058,10.911-33.552,10.911h-9.072   v39.641h-32.284V712.396h41.356c15.093,0,26.427,3.297,34.001,9.89C449.609,728.88,453.397,738.252,453.397,750.402z    M398.963,765.932h5.885c4.85,0,8.705-1.361,11.565-4.087c2.86-2.724,4.291-6.483,4.291-11.279c0-8.063-4.469-12.097-13.404-12.097   h-8.337V765.932z"
                    fill="#FFFFFF"
                  />
                  <path
                    d="M586.214,831.891h-42.338l-43.646-84.186h-0.735c1.034,13.241,1.553,23.349,1.553,30.323v53.862h-28.606   V712.396h42.175l43.482,83.041h0.49c-0.764-12.042-1.145-21.713-1.145-29.016v-54.025h28.77V831.891z"
                    fill="#FFFFFF"
                  />
                  <path
                    d="M660.021,761.927h51.572v64.406c-14.004,4.795-29.396,7.192-46.178,7.192   c-18.418,0-32.652-5.34-42.707-16.02c-10.053-10.68-15.08-25.91-15.08-45.689c0-19.289,5.504-34.3,16.512-45.035   c11.006-10.733,26.426-16.102,46.26-16.102c7.52,0,14.617,0.709,21.293,2.125c6.674,1.418,12.49,3.216,17.449,5.395l-10.217,25.338   c-8.609-4.251-18.063-6.376-28.361-6.376c-9.428,0-16.715,3.065-21.863,9.195c-5.15,6.13-7.725,14.89-7.725,26.277   c0,11.171,2.33,19.685,6.988,25.542s11.375,8.786,20.148,8.786c4.793,0,9.207-0.462,13.24-1.39v-18.717h-21.332V761.927z"
                    fill="#FFFFFF"
                  />
                </g>
                <g>
                  <g>
                    <path
                      d="M729.983,369.821c0,8.25-9,15-20,15H314.852c-11,0-20-6.75-20-15s9-15,20-15h395.132    C720.983,354.821,729.983,361.571,729.983,369.821z"
                      fill="#FFFFFF"
                    />
                  </g>
                  <g>
                    <path
                      d="M729.148,459.821c0,8.25-9,15-20,15H314.017c-11,0-20-6.75-20-15s9-15,20-15h395.132    C720.148,444.821,729.148,451.571,729.148,459.821z"
                      fill="#FFFFFF"
                    />
                  </g>
                  <g>
                    <path
                      d="M729.983,550.016c0,8.25-9,15-20,15H314.852c-11,0-20-6.75-20-15s9-15,20-15h395.132    C720.983,535.016,729.983,541.766,729.983,550.016z"
                      fill="#FFFFFF"
                    />
                  </g>
                </g>
              </svg>
            </div>
            <div className="document-content">
              <div className="document-name">{fileName}</div>
            </div>
            <div className="document-content">
              <div className="document-name">{authName}</div>
            </div>
          </div>
        </React.Fragment>
      );
    //break;
    case "jpeg":
      return (
        <React.Fragment>
          <div className="document-row with-border">
            <div className="document-icon svg-icon">
              <svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="m392 488h-272a48 48 0 0 1 -48-48v-368a48 48 0 0 1 48-48h224l96 96v320a48 48 0 0 1 -48 48z"
                  fill="#cfd2fc"
                />
                <path
                  d="m72 360h368a0 0 0 0 1 0 0v80a48 48 0 0 1 -48 48h-272a48 48 0 0 1 -48-48v-80a0 0 0 0 1 0 0z"
                  fill="#5153ff"
                />
                <path d="m440 120h-48a48 48 0 0 1 -48-48v-48z" fill="#8690fa" />
                <path d="m152 136h208v160h-208z" fill="#fff" />
                <path d="m216 216 40 48 48-16 56 48h-208z" fill="#8690fa" />
                <circle cx="288" cy="200" fill="#5153ff" r="16" />
                <g fill="#fff">
                  <path d="m248 384h-16a8 8 0 0 0 -8 8v64a8 8 0 0 0 16 0v-24h8a24 24 0 0 0 0-48zm0 32h-8v-16h8a8 8 0 0 1 0 16z" />
                  <path d="m200 384a8 8 0 0 0 -8 8v48a8 8 0 0 1 -16 0 8 8 0 0 0 -16 0 24 24 0 0 0 48 0v-48a8 8 0 0 0 -8-8z" />
                  <path d="m344 416h-16a8 8 0 0 0 0 16h7.049c-2.252 9.217-8.236 16-15.049 16-8.673 0-16-10.991-16-24s7.327-24 16-24a10.71 10.71 0 0 1 4.589 1.057 8 8 0 0 0 6.822-14.473 26.6 26.6 0 0 0 -11.411-2.584c-17.645 0-32 17.944-32 40s14.355 40 32 40 32-17.944 32-40a8 8 0 0 0 -8-8z" />
                </g>
              </svg>
            </div>
            <div className="document-content">
              <div className="document-name">{fileName}</div>
            </div>
            <div className="document-content">
              <div className="document-name">{authName}</div>
            </div>
          </div>
        </React.Fragment>
      );
    // break;
    case "gif":
      return (
        <React.Fragment>
          <div className="document-row with-border">
            <div className="document-icon svg-icon">
              <svg viewBox="0 0 1024 1024">
                <g>
                  <g>
                    <path
                      d="M146.901,249.004V964c0,33,27,60,60,60h613.857c33,0,60-27,60-60V60c0-33-27-60-60-60H395.906"
                      fill="#F1C40F"
                    />
                    <g>
                      <path
                        d="M146.901,249.004h189.005c33,0,60-27,60-60V0"
                        fill="#F39C12"
                      />
                    </g>
                  </g>
                </g>
                <g>
                  <path
                    d="M81.83,670.833h65.071V585l-70.946,88.375h0.012C77.167,671.805,79.198,670.833,81.83,670.833z"
                    fill="#262626"
                  />
                  <path
                    d="M945.83,670.833h-65.071V585l70.946,88.375h-0.012C950.493,671.805,948.462,670.833,945.83,670.833z"
                    fill="#262626"
                  />
                  <path
                    d="M884.273,861.471c-1.934,5.149-8.015,9.362-13.515,9.362H156.901c-5.5,0-11.582-4.213-13.514-9.362   L75.344,680.195c-1.933-5.149,0.986-9.362,6.486-9.362h864c5.5,0,8.419,4.213,6.486,9.362L884.273,861.471z"
                    fill="#F39C12"
                  />
                </g>
                <g>
                  <path
                    d="M312.407,818.323c0-5.013,1.484-8.881,4.454-11.606c2.97-2.724,7.287-4.087,12.955-4.087   c5.395,0,9.535,1.363,12.424,4.087c2.888,2.726,4.332,6.594,4.332,11.606c0,4.903-1.485,8.732-4.455,11.483   c-2.971,2.751-7.069,4.127-12.301,4.127c-5.395,0-9.645-1.362-12.75-4.086C313.96,827.123,312.407,823.282,312.407,818.323z"
                    fill="#FFFFFF"
                  />
                  <path
                    d="M453.397,750.402c0,13.351-3.938,23.662-11.811,30.937c-7.874,7.273-19.058,10.911-33.552,10.911h-9.072   v39.641h-32.284V712.396h41.356c15.093,0,26.427,3.297,34.001,9.89C449.609,728.88,453.397,738.252,453.397,750.402z    M398.963,765.932h5.885c4.85,0,8.705-1.361,11.565-4.087c2.86-2.724,4.291-6.483,4.291-11.279c0-8.063-4.469-12.097-13.404-12.097   h-8.337V765.932z"
                    fill="#FFFFFF"
                  />
                  <path
                    d="M586.214,831.891h-42.338l-43.646-84.186h-0.735c1.034,13.241,1.553,23.349,1.553,30.323v53.862h-28.606   V712.396h42.175l43.482,83.041h0.49c-0.764-12.042-1.145-21.713-1.145-29.016v-54.025h28.77V831.891z"
                    fill="#FFFFFF"
                  />
                  <path
                    d="M660.021,761.927h51.572v64.406c-14.004,4.795-29.396,7.192-46.178,7.192   c-18.418,0-32.652-5.34-42.707-16.02c-10.053-10.68-15.08-25.91-15.08-45.689c0-19.289,5.504-34.3,16.512-45.035   c11.006-10.733,26.426-16.102,46.26-16.102c7.52,0,14.617,0.709,21.293,2.125c6.674,1.418,12.49,3.216,17.449,5.395l-10.217,25.338   c-8.609-4.251-18.063-6.376-28.361-6.376c-9.428,0-16.715,3.065-21.863,9.195c-5.15,6.13-7.725,14.89-7.725,26.277   c0,11.171,2.33,19.685,6.988,25.542s11.375,8.786,20.148,8.786c4.793,0,9.207-0.462,13.24-1.39v-18.717h-21.332V761.927z"
                    fill="#FFFFFF"
                  />
                </g>
                <g>
                  <g>
                    <path
                      d="M729.983,369.821c0,8.25-9,15-20,15H314.852c-11,0-20-6.75-20-15s9-15,20-15h395.132    C720.983,354.821,729.983,361.571,729.983,369.821z"
                      fill="#FFFFFF"
                    />
                  </g>
                  <g>
                    <path
                      d="M729.148,459.821c0,8.25-9,15-20,15H314.017c-11,0-20-6.75-20-15s9-15,20-15h395.132    C720.148,444.821,729.148,451.571,729.148,459.821z"
                      fill="#FFFFFF"
                    />
                  </g>
                  <g>
                    <path
                      d="M729.983,550.016c0,8.25-9,15-20,15H314.852c-11,0-20-6.75-20-15s9-15,20-15h395.132    C720.983,535.016,729.983,541.766,729.983,550.016z"
                      fill="#FFFFFF"
                    />
                  </g>
                </g>
              </svg>
            </div>
            <div className="document-content">
              <div className="document-name">{fileName}</div>
            </div>
          </div>
        </React.Fragment>
      );
    //break;
    case "jpg":
      return (
        <React.Fragment>
          <div className="document-row with-border">
            <div className="document-icon svg-icon">
              <svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="m392 488h-272a48 48 0 0 1 -48-48v-368a48 48 0 0 1 48-48h224l96 96v320a48 48 0 0 1 -48 48z"
                  fill="#cfd2fc"
                />
                <path
                  d="m72 360h368a0 0 0 0 1 0 0v80a48 48 0 0 1 -48 48h-272a48 48 0 0 1 -48-48v-80a0 0 0 0 1 0 0z"
                  fill="#5153ff"
                />
                <path d="m440 120h-48a48 48 0 0 1 -48-48v-48z" fill="#8690fa" />
                <path d="m152 136h208v160h-208z" fill="#fff" />
                <path d="m216 216 40 48 48-16 56 48h-208z" fill="#8690fa" />
                <circle cx="288" cy="200" fill="#5153ff" r="16" />
                <g fill="#fff">
                  <path d="m248 384h-16a8 8 0 0 0 -8 8v64a8 8 0 0 0 16 0v-24h8a24 24 0 0 0 0-48zm0 32h-8v-16h8a8 8 0 0 1 0 16z" />
                  <path d="m200 384a8 8 0 0 0 -8 8v48a8 8 0 0 1 -16 0 8 8 0 0 0 -16 0 24 24 0 0 0 48 0v-48a8 8 0 0 0 -8-8z" />
                  <path d="m344 416h-16a8 8 0 0 0 0 16h7.049c-2.252 9.217-8.236 16-15.049 16-8.673 0-16-10.991-16-24s7.327-24 16-24a10.71 10.71 0 0 1 4.589 1.057 8 8 0 0 0 6.822-14.473 26.6 26.6 0 0 0 -11.411-2.584c-17.645 0-32 17.944-32 40s14.355 40 32 40 32-17.944 32-40a8 8 0 0 0 -8-8z" />
                </g>
              </svg>
            </div>
            <div className="document-content">
              <div className="document-name">{fileName}</div>
            </div>
            <div className="document-content">
              <div className="document-name">{authName}</div>
            </div>
          </div>
        </React.Fragment>
      );
    // break;
    case "htm":
      return (
        <React.Fragment>
          <div className="document-row with-border">
            <div className="document-icon svg-icon">
              <svg
                width="33px"
                height="40px"
                viewBox="0 0 33 40"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xlink="http://www.w3.org/1999/xlink"
              >
                <defs></defs>
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                  <g transform="translate(-4.000000, 0.000000)">
                    <g transform="translate(4.000000, 0.000000)">
                      <path
                        d="M4.04761905,0 C1.82142857,0 0,1.8 0,4 L0,36 C0,38.2 1.82142857,40 4.04761905,40 L28.3333333,40 C30.5595238,40 32.3809524,38.2 32.3809524,36 L32.3809524,12 L20.2380952,0 L4.04761905,0 L4.04761905,0 Z"
                        id="Shape"
                        fill="#708A9F"
                      ></path>
                      <text
                        fontFamily="Roboto-Regular, Roboto"
                        fontSize="19.047619"
                        fontWeight="normal"
                        fill="#FFFFFF"
                      >
                        <tspan x="4" y="28">
                          &lt;/&gt;
                        </tspan>
                      </text>
                    </g>
                  </g>
                </g>
              </svg>
            </div>
            <div className="document-content">
              <div className="document-name">{fileName}</div>
            </div>
            <div className="document-content">
              <div className="document-name">{authName}</div>
            </div>
          </div>
        </React.Fragment>
      );
    // break;
    case "html":
      return (
        <React.Fragment>
          <div className="document-row with-border">
            <div className="document-icon svg-icon">
              <svg
                width="33px"
                height="40px"
                viewBox="0 0 33 40"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xlink="http://www.w3.org/1999/xlink"
              >
                <defs></defs>
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                  <g transform="translate(-4.000000, 0.000000)">
                    <g transform="translate(4.000000, 0.000000)">
                      <path
                        d="M4.04761905,0 C1.82142857,0 0,1.8 0,4 L0,36 C0,38.2 1.82142857,40 4.04761905,40 L28.3333333,40 C30.5595238,40 32.3809524,38.2 32.3809524,36 L32.3809524,12 L20.2380952,0 L4.04761905,0 L4.04761905,0 Z"
                        id="Shape"
                        fill="#708A9F"
                      ></path>
                      <text
                        fontFamily="Roboto-Regular, Roboto"
                        fontSize="19.047619"
                        fontWeight="normal"
                        fill="#FFFFFF"
                      >
                        <tspan x="4" y="28">
                          &lt;/&gt;
                        </tspan>
                      </text>
                    </g>
                  </g>
                </g>
              </svg>
            </div>
            <div className="document-content">
              <div className="document-name">{fileName}</div>
            </div>
            <div className="document-content">
              <div className="document-name">{authName}</div>
            </div>
          </div>
        </React.Fragment>
      );
    // break;
    case "mp4":
      return (
        <React.Fragment>
          <div className="document-row with-border">
            <div className="document-icon svg-icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="40px"
                width="40px"
                version="1.1"
                xlink="http://www.w3.org/1999/xlink"
                viewBox="0 0 40 40"
              >
                <title>files/video</title>
                <desc>Created with Sketch.</desc>
                <g fillRule="evenodd" fill="none">
                  <path
                    fill="#DB4564"
                    d="m32 4h8v28c0 2.2-1.8 4-4 4h-32c-2.2 0-4-1.8-4-4v-24c0-2.2 1.8-4 4-4h2l4 8h6l-4-8h4l4 8h6l-4-8h4l4 8h6l-4-8zm-16 14v12l9.429-6-9.429-6z"
                  />
                </g>
              </svg>
            </div>
            <div className="document-content">
              <div className="document-name">{fileName}</div>
            </div>
            <div className="document-content">
              <div className="document-name">{authName}</div>
            </div>
          </div>
        </React.Fragment>
      );
    // break;
    default:
      return (
        <React.Fragment>
          <div className="document-row with-border">
            <div className="document-icon svg-icon">
              <svg
                width="33px"
                height="40px"
                viewBox="0 0 33 40"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xlink="http://www.w3.org/1999/xlink"
              >
                <defs></defs>
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                  <g transform="translate(-4.000000, 0.000000)">
                    <g transform="translate(4.000000, 0.000000)">
                      <path
                        d="M4.04761905,0 C1.82142857,0 0,1.8 0,4 L0,36 C0,38.2 1.82142857,40 4.04761905,40 L28.3333333,40 C30.5595238,40 32.3809524,38.2 32.3809524,36 L32.3809524,12 L20.2380952,0 L4.04761905,0 L4.04761905,0 Z"
                        id="Shape"
                        fill="#708A9F"
                      ></path>
                      <text
                        fontFamily="Roboto-Regular, Roboto"
                        fontSize="19.047619"
                        fontWeight="normal"
                        fill="#FFFFFF"
                      >
                        <tspan x="4" y="28">
                          &lt;/&gt;
                        </tspan>
                      </text>
                    </g>
                  </g>
                </g>
              </svg>
            </div>
            <div className="document-content">
              <div className="document-name">{fileName}</div>
            </div>
            <div className="document-content">
              <div className="document-name">{authName}</div>
            </div>
          </div>
        </React.Fragment>
      );
    // break;
  }
};

/**
 *
 * @param {attachmentURL}
 */
export const getAttchmentHtml = (attachmentURL) => {
  let fileExe = attachmentURL.split(".").pop();
  switch (true) {
    case fileExe === "pdf":
      return (
        <div className="document-icon material-icons pdf-icon svg-icon">
          <a
            className="view_application"
            href={attachmentURL}
            target="_blank"
            rel="noopener noreferrer"
          >
            picture_as_pdf
          </a>
        </div>
      );
    // break;
    case fileExe === "csv":
    case fileExe === "xlsx":
      return (
        <div className="document-icon pdf-icon svg-icon">
          <a
            className="view_application"
            href={attachmentURL}
            target="_blank"
            rel="noopener noreferrer"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 48 48"
              width="22px"
              height="22px"
            >
              <path
                fill="#4CAF50"
                d="M41,10H25v28h16c0.553,0,1-0.447,1-1V11C42,10.447,41.553,10,41,10z"
              ></path>
              <path
                fill="#FFF"
                d="M32 15H39V18H32zM32 25H39V28H32zM32 30H39V33H32zM32 20H39V23H32zM25 15H30V18H25zM25 25H30V28H25zM25 30H30V33H25zM25 20H30V23H25z"
              ></path>
              <path fill="#2E7D32" d="M27 42L6 38 6 10 27 6z"></path>
              <path
                fill="#FFF"
                d="M19.129,31l-2.411-4.561c-0.092-0.171-0.186-0.483-0.284-0.938h-0.037c-0.046,0.215-0.154,0.541-0.324,0.979L13.652,31H9.895l4.462-7.001L10.274,17h3.837l2.001,4.196c0.156,0.331,0.296,0.725,0.42,1.179h0.04c0.078-0.271,0.224-0.68,0.439-1.22L19.237,17h3.515l-4.199,6.939l4.316,7.059h-3.74V31z"
              ></path>
            </svg>
          </a>
        </div>
      );

    case fileExe === "jpg":
    case fileExe === "jpeg":
    case fileExe === "png":
      return (
        <div className="document-icon image_type_jpg">
          <a
            className="view_application"
            href={attachmentURL}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={attachmentURL}
              alt="chat"
              onError={(e) => addDefaultSrcForChat(e)}
            />
          </a>
        </div>
      );

    // break;
    case fileExe === "docx":
    case fileExe === "doc" :  
      return (
        <div className="document-icon pdf-icon svg-icon">
          <a
            className="view_application"
            href={attachmentURL}
            target="_blank"
            rel="noopener noreferrer"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 48 48"
              width="22px"
              height="22px"
            >
              <path
                fill="#2196F3"
                d="M41,10H25v28h16c0.553,0,1-0.447,1-1V11C42,10.447,41.553,10,41,10z"
              ></path>
              <path
                fill="#FFF"
                d="M25 15.001H39V17H25zM25 19H39V21H25zM25 23.001H39V25.001H25zM25 27.001H39V29H25zM25 31H39V33.001H25z"
              ></path>
              <path fill="#0D47A1" d="M27 42L6 38 6 10 27 6z"></path>
              <path
                fill="#FFF"
                d="M21.167,31.012H18.45l-1.802-8.988c-0.098-0.477-0.155-0.996-0.174-1.576h-0.032c-0.043,0.637-0.11,1.162-0.197,1.576l-1.85,8.988h-2.827l-2.86-14.014h2.675l1.536,9.328c0.062,0.404,0.111,0.938,0.143,1.607h0.042c0.019-0.498,0.098-1.051,0.223-1.645l1.97-9.291h2.622l1.785,9.404c0.062,0.348,0.119,0.846,0.17,1.511h0.031c0.02-0.515,0.073-1.035,0.16-1.563l1.503-9.352h2.468L21.167,31.012z"
              ></path>
            </svg>
          </a>
        </div>
      );

    default:
      return null;
    // break;
  }
};

/**
 *
 * @param {attachmentName}
 */
export const getAttachmentName = (attachmentURL) => {
  let fileExe = attachmentURL.split(".").pop();
  switch (true) {
    case fileExe === "pdf":
      return true;
    // break;
    case fileExe === "csv":
    case fileExe === "xlsx":
      return true;

    case fileExe === "jpg":
    case fileExe === "jpeg":
    case fileExe === "png":
      return false;

    // break;
    case fileExe === "docx":
      return true;

    default:
      return null;
    // break;
  }
};

/**
 * Segment Tracking
 */

export const segmentTracking = (eventName, eventObject) => {
  window.analytics.track(eventName, eventObject, {
    integrations: {
      All: true,
      Hubspot: false,
    },
  });
};

export const checkIsInIframe = () => {
  if (window.location !== window.parent.location) {
    return true;
  } else {
    return false;
  }
};

/**
 * Calculate Age
 * 1. if 0< next birthday date - current date <6 months, then insurance age = current age+1
 * 2. if next birthday date - current date >6 months, then insurance age = current age
 *  @param {dateString}
 */
export const calcAge = (dateString) => {
  let birthday = +new Date(dateString);
  return ~~((Date.now() - birthday) / 31557600000);
};

export const calcAgeOnMonth = (dateString) => {
  let today = new Date();
  dateString = new Date(dateString);
  let y = [today.getFullYear(), dateString.getFullYear()];
  let ydiff = y[0] - y[1];
  let m = [today.getMonth(), dateString.getMonth()];
  let mdiff = m[0] - m[1];
  let d = [today.getDate(), dateString.getDate()];
  let ddiff = d[0] - d[1];

  if (mdiff < 0 || (mdiff === 0 && ddiff < 0)) --ydiff;
  if (mdiff < 0) mdiff += 12;
  if (ddiff < 0) {
    dateString.setMonth(m[1] + 1, 0);
    ddiff = dateString.getDate() - d[1] + d[0];
    --mdiff;
  }
  if (mdiff > 0 && mdiff < 6 && ddiff > 0 && ddiff < 30 && ydiff > 0) {
    return ydiff;
  } else if (mdiff < 0 && ydiff > 0) {
    return ydiff + 1;
  } else if (mdiff >= 6 && ydiff > 0) {
    return ydiff + 1;
  }
  // else if(mdiff == 5 && ddiff==30 && ydiff > 0) {
  //   return ydiff + 1 ;
  // }
  else {
    return ydiff;
  }
};

export const clearCacheData = () => {
  caches.keys().then((names) => {
    names.forEach((name) => {
      caches.delete(name);
    });
  });
};

// Validate State

export const getState = (zipcode) => {
  /* Ensure param is a string to prevent unpredictable parsing results */
  // if (typeof zipString !== "string") {
  //   console.log("Must pass the zipcode as a string.");
  //   return;
  // }

  /* Ensure we have exactly 5 characters to parse */
  // if (zipString.length !== 5) {
  //   console.log("Must pass a 5-digit zipcode.");
  //   return;
  // }

  /* Ensure we don't parse strings starting with 0 as octal values */
  // const zipcode = parseInt(zipString, 10);

  let st;
  let state;
  let id;

  /* Code cases alphabetized by state */
  if (zipcode >= 35000 && zipcode <= 36999) {
    id = 1;
    st = "AL";
    state = "Alabama";
  } else if (zipcode >= 99500 && zipcode <= 99999) {
    id = 2;
    st = "AK";
    state = "Alaska";
  } else if (zipcode >= 85000 && zipcode <= 86999) {
    id = 3;
    st = "AZ";
    state = "Arizona";
  } else if (zipcode >= 71600 && zipcode <= 72999) {
    id = 4;
    st = "AR";
    state = "Arkansas";
  } else if (zipcode >= 90000 && zipcode <= 96699) {
    id = 5;
    st = "CA";
    state = "California";
  } else if (zipcode >= 80000 && zipcode <= 81999) {
    id = 6;
    st = "CO";
    state = "Colorado";
  } else if (
    (zipcode >= 6000 && zipcode <= 6389) ||
    (zipcode >= 6391 && zipcode <= 6999)
  ) {
    id = 7;
    st = "CT";
    state = "Connecticut";
  } else if (zipcode >= 19700 && zipcode <= 19999) {
    id = 8;
    st = "DE";
    state = "Delaware";
  } else if (zipcode >= 32000 && zipcode <= 34999) {
    id = 10;
    st = "FL";
    state = "Florida";
  } else if (
    (zipcode >= 30000 && zipcode <= 31999) ||
    (zipcode >= 39800 && zipcode <= 39999)
  ) {
    id = 11;
    st = "GA";
    state = "Georgia";
  } else if (zipcode >= 96700 && zipcode <= 96999) {
    id = 12;
    st = "HI";
    state = "Hawaii";
  } else if (zipcode >= 83200 && zipcode <= 83999) {
    id = 13;
    st = "ID";
    state = "Idaho";
  } else if (zipcode >= 60000 && zipcode <= 62999) {
    id = 14;
    st = "IL";
    state = "Illinois";
  } else if (zipcode >= 46000 && zipcode <= 47999) {
    id = 15;
    st = "IN";
    state = "Indiana";
  } else if (zipcode >= 50000 && zipcode <= 52999) {
    id = 16;
    st = "IA";
    state = "Iowa";
  } else if (zipcode >= 66000 && zipcode <= 67999) {
    id = 17;
    st = "KS";
    state = "Kansas";
  } else if (zipcode >= 40000 && zipcode <= 42999) {
    id = 18;
    st = "KY";
    state = "Kentucky";
  } else if (zipcode >= 70000 && zipcode <= 71599) {
    id = 19;
    st = "LA";
    state = "Louisiana";
  } else if (zipcode >= 3900 && zipcode <= 4999) {
    id = 20;
    st = "ME";
    state = "Maine";
  } else if (zipcode >= 20600 && zipcode <= 21999) {
    id = 21;
    st = "MD";
    state = "Maryland";
  } else if (
    (zipcode >= 1000 && zipcode <= 2799) ||
    zipcode == 5501 ||
    zipcode == 5544
  ) {
    id = 22;
    st = "MA";
    state = "Massachusetts";
  } else if (zipcode >= 48000 && zipcode <= 49999) {
    id = 23;
    st = "MI";
    state = "Michigan";
  } else if (zipcode >= 55000 && zipcode <= 56899) {
    id = 24;
    st = "MN";
    state = "Minnesota";
  } else if (zipcode >= 38600 && zipcode <= 39999) {
    id = 25;
    st = "MS";
    state = "Mississippi";
  } else if (zipcode >= 63000 && zipcode <= 65999) {
    id = 26;
    st = "MO";
    state = "Missouri";
  } else if (zipcode >= 59000 && zipcode <= 59999) {
    id = 27;
    st = "MT";
    state = "Montana";
  } else if (zipcode >= 27000 && zipcode <= 28999) {
    id = 34;
    st = "NC";
    state = "North Carolina";
  } else if (zipcode >= 58000 && zipcode <= 58999) {
    id = 35;
    st = "ND";
    state = "North Dakota";
  } else if (zipcode >= 68000 && zipcode <= 69999) {
    id = 28;
    st = "NE";
    state = "Nebraska";
  } else if (zipcode >= 88900 && zipcode <= 89999) {
    id = 29;
    st = "NV";
    state = "Nevada";
  } else if (zipcode >= 3000 && zipcode <= 3899) {
    id = 30;
    st = "NH";
    state = "New Hampshire";
  } else if (zipcode >= 7000 && zipcode <= 8999) {
    id = 31;
    st = "NJ";
    state = "New Jersey";
  } else if (zipcode >= 87000 && zipcode <= 88499) {
    id = 32;
    st = "NM";
    state = "New Mexico";
  } else if (
    (zipcode >= 10000 && zipcode <= 14999) ||
    zipcode == 6390 ||
    zipcode == 501 ||
    zipcode == 544
  ) {
    id = 33;
    st = "NY";
    state = "New York";
  } else if (zipcode >= 43000 && zipcode <= 45999) {
    id = 36;
    st = "OH";
    state = "Ohio";
  } else if (
    (zipcode >= 73000 && zipcode <= 73199) ||
    (zipcode >= 73400 && zipcode <= 74999)
  ) {
    id = 37;
    st = "OK";
    state = "Oklahoma";
  } else if (zipcode >= 97000 && zipcode <= 97999) {
    id = 38;
    st = "OR";
    state = "Oregon";
  } else if (zipcode >= 15000 && zipcode <= 19699) {
    id = 39;
    st = "PA";
    state = "Pennsylvania";
  } else if (zipcode >= 300 && zipcode <= 999) {
    id = 40;
    st = "PR";
    state = "Puerto Rico";
  } else if (zipcode >= 2800 && zipcode <= 2999) {
    id = 41;
    st = "RI";
    state = "Rhode Island";
  } else if (zipcode >= 29000 && zipcode <= 29999) {
    id = 42;
    st = "SC";
    state = "South Carolina";
  } else if (zipcode >= 57000 && zipcode <= 57999) {
    id = 43;
    st = "SD";
    state = "South Dakota";
  } else if (zipcode >= 37000 && zipcode <= 38599) {
    id = 44;
    st = "TN";
    state = "Tennessee";
  } else if (
    (zipcode >= 75000 && zipcode <= 79999) ||
    (zipcode >= 73301 && zipcode <= 73399) ||
    (zipcode >= 88500 && zipcode <= 88599)
  ) {
    id = 45;
    st = "TX";
    state = "Texas";
  } else if (zipcode >= 84000 && zipcode <= 84999) {
    id = 46;
    st = "UT";
    state = "Utah";
  } else if (zipcode >= 5000 && zipcode <= 5999) {
    id = 47;
    st = "VT";
    state = "Vermont";
  } else if (
    (zipcode >= 20100 && zipcode <= 20199) ||
    (zipcode >= 22000 && zipcode <= 24699) ||
    zipcode == 20598
  ) {
    id = 48;
    st = "VA";
    state = "Virginia";
  } else if (
    (zipcode >= 20000 && zipcode <= 20099) ||
    (zipcode >= 20200 && zipcode <= 20599) ||
    (zipcode >= 56900 && zipcode <= 56999)
  ) {
    id = 9;
    st = "DC";
    state = "District of Columbia";
  } else if (zipcode >= 98000 && zipcode <= 99499) {
    id = 49;
    st = "WA";
    state = "Washington";
  } else if (zipcode >= 24700 && zipcode <= 26999) {
    id = 50;
    st = "WV";
    state = "West Virginia";
  } else if (zipcode >= 53000 && zipcode <= 54999) {
    id = 51;
    st = "WI";
    state = "Wisconsin";
  } else if (zipcode >= 82000 && zipcode <= 83199) {
    id = 52;
    st = "WY";
    state = "Wyoming";
  } else {
    return null;
  }

  return { id, st, state };
};

// ================ SET COOKIE ================//
export const setCjeCookie = (cname, cvalue) => {
  const d = new Date();
  d.setMonth(d.getMonth() + 14);
  let expires = "expires=" + d.toUTCString();

  document.cookie =
    cname + "=" + cvalue + ";" + expires + ";path=/;secure=true";
};

// ============== SBLI Resume Process ============= //

export const getSBLIProcessStep = (key) => {
  switch (key) {
    case "UWQ":
      return "/sbli-page";
    case "PII":
      return "/sbli-user-detail";
    case "HIPPA":
      return "/sbli-terms";
    case "BENE":
      return "/beneficiary";
    case "CVG":
      return "/sbli-coverage";
    case "PAY":
      return "/sbli-payment";
    case "CONGRATULATIONS":
      return "/sbli-congratulations";
    case "SUBMIT":
      return "/sbli-document";
    case "DECLINED":
      return "/not-found";
  }
};

// ============== Assurity Resume Process ============= //
export const getAssurityProcessStep = (key) => {
  const keyName = key.split(",");

  const path = keyName[1];
  switch (path) {
    case "PRE_QUESTIONS":
      return "/pre-questions";
    case "ASSURITY_PRE_APPROVAL":
      return "/assurity-pre-approval";
    case "ASSURITY_USER_DETAIL":
      return "/assurity-user-detail";
    case "ASSURITY_UNDERWRITING":
      return "/assurity-underwriting?underwriting=" + keyName[2];
    case "ASSURITY_WITH_CHANGES":
      return "/assurity-changes?status=" + keyName[2];
    case "ASSURITY_BENE":
      return "/assurity-beneficiary";
    case "ASSURITY_PAY":
      return "/assurity-payment";
    case "ASSURITY_NOTICE":
      return "/assurity-notice";
    case "ASSURITY_DOCUMENTS":
      return `https://account-d.docusign.com/oauth/auth?response_type=token&scope=signature&client_id=e1631711-4f38-4ca4-98c5-e399f204ab58&redirect_uri=${window.location.origin}/assurity-documents`;
    case "ASSURITY_PREVIEW":
      return `https://account-d.docusign.com/oauth/auth?response_type=token&scope=signature&client_id=e1631711-4f38-4ca4-98c5-e399f204ab58&redirect_uri=${window.location.origin}/assurity-preview`;
    case "DECLINED":
      return "/not-found";
  }
};

export const getUnderwritingProgressFromNextStep = (path) => {
  const progressObject = {
    preQuestion: 10,
    basicDetail: 100,
    underWritingProgress: 0,
    beneficiaryProgress: 0,
    paymentProgress: 0,
    documentSignProgress: 0,
  };
  switch (path) {
    case "OCCUPATION_DETAILS":
      return {
        ...progressObject,
      };
    case "PROPOSED_INSURED":
      return {
        ...progressObject,
        underWritingProgress: 1,
      };
    case "GENERAL_SECTION_1":
      return {
        ...progressObject,
        underWritingProgress: 2,
      };
    case "GENERAL_SECTION_2":
      return {
        ...progressObject,
        underWritingProgress: 3,
      };
    case "HEALTH_SECTION_1":
      return {
        ...progressObject,
        underWritingProgress: 4,
      };
    case "HEALTH_SECTION_2":
      return {
        ...progressObject,
        underWritingProgress: 5,
      };
    case "HEALTH_SECTION_3":
      return {
        ...progressObject,
        underWritingProgress: 6,
      };
    case "HEALTH_SECTION_4":
      return {
        ...progressObject,
        underWritingProgress: 7,
      };
    case "COVID_QUESTIONS":
      return {
        ...progressObject,
        underWritingProgress: 8,
      };
    case "default":
      return {
        ...progressObject,
      };
  }
};

export const getAssurityProgressFromNextStep = (key) => {
  const keyName = key.split(",");

  const path = keyName[1];
  switch (path) {
    case "PRE_QUESTIONS":
      return {
        preQuestion: 0,
        basicDetail: 0,
        underWritingProgress: 0,
        beneficiaryProgress: 0,
        paymentProgress: 0,
        documentSignProgress: 0,
      };
    case "ASSURITY_USER_DETAIL":
      return {
        preQuestion: 10,
        basicDetail: 0,
        underWritingProgress: 0,
        beneficiaryProgress: 0,
        paymentProgress: 0,
        documentSignProgress: 0,
      };
    case "ASSURITY_UNDERWRITING":
      return getUnderwritingProgressFromNextStep(keyName[2]);
    case "ASSURITY_BENE":
      return {
        preQuestion: 10,
        basicDetail: 100,
        underWritingProgress: 9,
        beneficiaryProgress: 0,
        paymentProgress: 0,
        documentSignProgress: 0,
      };
    case "ASSURITY_PAY":
      return {
        preQuestion: 10,
        basicDetail: 100,
        underWritingProgress: 9,
        beneficiaryProgress: 100,
        paymentProgress: 0,
        documentSignProgress: 0,
      };
    case "ASSURITY_DOCUMENTS":
      return {
        preQuestion: 10,
        basicDetail: 100,
        underWritingProgress: 9,
        beneficiaryProgress: 100,
        paymentProgress: 100,
        documentSignProgress: 0,
      };
    case "default":
      return {
        preQuestion: 0,
        basicDetail: 0,
        underWritingProgress: 0,
        beneficiaryProgress: 0,
        paymentProgress: 0,
        documentSignProgress: 0,
      };
  }
};

export const customDomainTokenGenerate = (clientCallBack, page) => {
  const hostName = window.location.host.split(".");
  const checkForLocal = document.domain === "localhost";
  // const tempOrigin = "https://sblitem.ilifestaging.tech";
  // const hostName = customDomain.split(".");
  if (
    hostName[0] === "client" ||
    hostName[0] === "theta-client" ||
    checkForLocal ||
    hostName[0] === "ilife-client"
  ) {
    clientCallBack();
  } else {
    const env = hostName[1] === "ilifestaging" ? "staging" : "production";
    const domain = encodeURIComponent(window.location.origin); //encodeURIComponent(window.location.origin)
    const origin =
      env === "staging"
        ? `https://${
            hostName[0] === "theta-client" ? "theta-client" : "client"
          }.ilifestaging.tech/tokenGenerate`
        : `https://client.ilife.tech/tokenGenerate`;
    window.location.href =
      origin + "?domain=" + domain + "&env=" + env + "&page=" + page;
  }
};

const getMenu = (items, value, style) => {
  return <div className="menu-list" children={items}></div>;
};

// ====================== Assurity Components =================== //
export const AssurityTextField = ({
  options = [],
  onValueChange,
  onInputChange,
  value,
  removeDisclosure,
  showRemove,
}) => {
  return (
    <div className="form-group fixed-floating-label mb-4">
      <div className="d-flex justify-content-between mt-4">
        <label htmlFor={"disclosure"} className={"m-0 mb-3"}>
          Search all that apply
        </label>
        {showRemove && (
          <a className="text-danger" onClick={removeDisclosure}>
            <u>Remove</u>
          </a>
        )}
      </div>
      <div className="default-select with-border">
        <Autocomplete
          getItemValue={(item) => item.DisclosureName}
          items={options}
          renderItem={(item) => <div>{item.DisclosureName}</div>}
          value={value}
          wrapperStyle={{ width: "100%" }}
          onChange={(e) => {
            onInputChange(e.target.value);
          }}
          renderMenu={getMenu}
          inputProps={{
            placeholder: "Search",
            className: "form-control form-control-search w-100",
          }}
          onSelect={onValueChange}
        />
        {/* <Select
          className={"custom-select-menu"}
          classNamePrefix={"custom-select"}
          options={options}
          value={value}
          onChange={onValueChange}
          onInputChange={onInputChange}
        /> */}
        {/* <span className="fixed-label">{t("manual_form.state")}</span>
        <span className="fixed-label">State</span>
        <em className="input-icon"></em> */}
      </div>
    </div>
  );
};

export const AssurityBaseBoolean = ({
  question,
  onValueChange,
  value,
  disabled,
}) => {
  return (
    <span className="custom-radio mt-3 d-block mb-3">
      <label className="m-0 mb-3" htmlFor={question.BaseQuestionId + "ab"}>
        <input
          type="radio"
          name={question.BaseQuestionId}
          onChange={(e) => onValueChange(question, e)}
          id={question.BaseQuestionId + "ab"}
          value={"Yes"}
          disabled={disabled}
          checked={value == "Yes"}
        />
        <span></span> Yes
      </label>

      <label className="m-0" htmlFor={question.BaseQuestionId + "ba"}>
        <input
          type="radio"
          name={question.BaseQuestionId}
          id={question.BaseQuestionId + "ba"}
          value={"No"}
          disabled={disabled}
          onChange={(e) => onValueChange(question, e)}
          checked={value === "No"}
        />
        <span></span> No
      </label>
    </span>
  );
};

// export const ShowDisclosurePickList = ({
//   list,
//   onValueChange,
//   value,
//   baseAnswer,
// }) => {
//   console.log("list::::::", list);
//   return (
//     <span className="custom-radio mt-3 d-block">
//       {list &&
//         list?.length > 0 &&
//         list.map((item, index) => {
//           if ((item.TriggerOn === "Yes" ? "true" : "false") === baseAnswer) {
//             return (
//               <label
//                 className="m-0 mb-3"
//                 key={index}
//                 htmlFor={item.DisclosureId + "ab"}
//               >
//                 <input
//                   type="checkbox"
//                   name={item.DisclosureId}
//                   onChange={(e) => onValueChange(e.target.value)}
//                   id={item.DisclosureId + "ab"}
//                   value={item.DisclosureId}
//                   checked={value == item.DisclosureId}
//                 />
//                 <span></span> {item.DisclosureName}
//               </label>
//             );
//           }
//         })}{" "}
//     </span>
//   );
// };

export const ShowDisclosurePickList = ({
  list,
  onValueChange,
  value,
  baseAnswer,
}) => {
  const getDisclosureList = () => {
    return (
      list &&
      list.length > 0 &&
      list.map((item) => {
        if ((item.TriggerOn === "Yes" ? "true" : "false") === baseAnswer) {
          return { value: item.DisclosureId, label: item.DisclosureName };
        }
      })
    );
  };

  return (
    <div className="form-group fixed-floating-label my-4">
      <label htmlFor={"disclosure"} className={"m-0 mb-3"}>
        Select all that apply
      </label>
      <div className="default-select with-border">
        <Select
          className={"custom-select-menu"}
          classNamePrefix={"custom-select"}
          options={getDisclosureList()}
          isMulti
          onChange={(value) => {
            onValueChange(value);
          }}
          value={value}
        />
        {/* <span className="fixed-label">{}</span> */}
        {/* <span className="text-danger ">{errors?.birthCountry}</span> */}
        {/* {stateLoading && "Please wait..."} */}
      </div>
    </div>
    // <span className="custom-radio mt-3 d-block">
    //   {list &&
    //     list?.length > 0 &&
    //     list.map((item, index) => {
    //       if ((item.TriggerOn === "Yes" ? "true" : "false") === baseAnswer) {
    //         return (
    //           <label
    //             className="m-0 mb-3"
    //             key={index}
    //             htmlFor={item.DisclosureId + "ab"}
    //           >
    //             <input
    //               type="checkbox"
    //               name={item.DisclosureId}
    //               onChange={(e) => onValueChange(e.target.value)}
    //               id={item.DisclosureId + "ab"}
    //               value={item.DisclosureId}
    //               checked={value == item.DisclosureId}
    //             />
    //             <span></span> {item.DisclosureName}
    //           </label>
    //         );
    //       }
    //     })}{" "}
    // </span>
  );
};

//function to calculate no of days between selected date and local date.
export const calculateDaysFromNow = (date) => {
  // Convert the dates to local timezone
  const localTime = moment.tz(
    new Date(),
    Intl.DateTimeFormat().resolvedOptions().timeZone
  );
  const convertedDate = moment.tz(
    date,
    Intl.DateTimeFormat().resolvedOptions().timeZone
  );

  // Calculate the difference in days
  const differenceDays = localTime.diff(convertedDate, "days");

  return differenceDays;
};

export const generatePdfBase64 = async (text) => {
  const pdfDoc = await PDFDocument.create();
  const page = pdfDoc.addPage();

  const font = await pdfDoc.embedFont(StandardFonts.Helvetica);
  const { width, height } = page.getSize();
  const fontSize = 12;

  page.drawText(text, {
    x: 50,
    y: height - 50,
    size: fontSize,
    font,
  });

  const pdfBytes = await pdfDoc.save();

  const base64String = Buffer.from(pdfBytes).toString('base64');
  return base64String
};
