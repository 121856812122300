import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  commonApiCall,
  getJwt,
  setAssurityProgressCount,
  UNDERWRITING_DETAILS,
} from "../../../../redux/actions";
import {
  AssurityTextField,
  showErrorToast,
  UnderWritingRoutes,
} from "../../../../utils";
import Select from "react-select";
import { API_URL_UNDERWRITING } from "../../../../config/configs";

const OccupationDetails = ({
  baseQuestion = null,
  commonApiCall,
  underwriting,
  assurityCount,
  setAssurityProgressCount,
  ...props
}) => {
  const [occupationList, setOccupationList] = useState([]);
  const [occupationValue, setOccupationValue] = useState({ 0: "" });
  const [reflexiveQuestionList, setReflexiveQuestionList] = useState({});
  const [reflexiveArr, setReflexiveArr] = useState({});
  const [loading, setLoading] = useState(false);
  const [disclosure, setDisclosure] = useState([]);
  const [questionLoading, setQuestionLoading] = useState({});
  const [errors, setError] = useState({});
  const saveUnderwriteDetails = async () => {
    try {
      const request = {
        applicationId: underwriting?.applicationId,
        applicationType: "Web",
        nextStep: "24,ASSURITY_UNDERWRITING,PROPOSED_INSURED",
      };

      const isAuthorized = getJwt() ? true : false;
      const apiEndPoint = API_URL_UNDERWRITING + "saveUnderWriting";
      const response = await commonApiCall(
        apiEndPoint,
        "post",
        request,
        UNDERWRITING_DETAILS,
        isAuthorized
      );

      if (response.status.code === 200) {
        props.history.push(`${UnderWritingRoutes.ASSURITY_UNDERWRITING}?underwriting=PROPOSED_INSURED`);
        /* window.location.href =
          UnderWritingRoutes.ASSURITY_UNDERWRITING +
          "?underwriting=PROPOSED_INSURED"; */
      }
    } catch (err) {
      showErrorToast(err);
    }
  };

  const handleInputChange = async (question, value = "", index) => {
    setOccupationValue({ ...occupationValue, [index]: value });
    try {
      if (value !== "") {
        const apiEndPoint =
          API_URL_UNDERWRITING +
          "assurityDisclosures?applicationId=" +
          underwriting?.applicationId +
          "&categoryId=" +
          question?.CategoryId +
          "&searchText=" +
          value;
        const response = await commonApiCall(apiEndPoint, "get", {}, "");

        if (response.status.code === 200) {
          const list = JSON.parse(response.data);
          setOccupationList(list.DisclosureMatches);
        }
      }
      else {
        setOccupationList([]);
        setReflexiveQuestionList({ ...reflexiveQuestionList, [index]: null });
      }
    } catch (err) {
      showErrorToast(err);
    }
  };

  const updateReflexiveQuestion = async (question, index, disclosure) => {
    setLoading(true);
    try {
      const request = {
        baseQuestionId: question.BaseQuestionId,
        reflexiveQuestionAnswers: reflexiveArr[index],
      };
      const isAuthorized = getJwt() ? true : false;
      const apiEndPoint =
        API_URL_UNDERWRITING +
        "updateReflexiveQuestions?applicationId=" +
        underwriting?.applicationId +
        "&disclosureId=" +
        disclosure;
      const response = await commonApiCall(
        apiEndPoint,
        "put",
        request,
        null,
        isAuthorized
      );
      if (response.status.code === 200) {
        if (parseInt(index) === Object.keys(occupationValue).length - 1) {
          saveUnderwriteDetails();
        }
      }
    } catch (err) {
      showErrorToast(err);
    }
    setLoading(false);
  };

  const updateBaseQuestion = async (obj) => {
    try {
      const request = {
        baseQuestionAnswers: [
          {
            baseQuestionId: obj.BaseQuestionId,
            baseQuestionAnswerValue: obj.value,
          },
        ],
      };

      const isAuthorized = getJwt() ? true : false;
      const apiEndPoint =
        API_URL_UNDERWRITING +
        "updatebaseQuestions?applicationId=" +
        underwriting?.applicationId;

      const response = await commonApiCall(
        apiEndPoint,
        "put",
        request,
        null,
        isAuthorized
      );
      if (response.status.code !== 200) {
        showErrorToast(response.status.message);
      }
      else {
        // setIsNextDisabled(true);
      }
    } catch (err) {
      showErrorToast(err);
    }
  };

  const getReflexiveQuestions = async (item, index) => {
    setQuestionLoading({ ...questionLoading, [index]: true });
    try {
      const apiEndPoint =
        API_URL_UNDERWRITING +
        "assurityReflexiveQuestions?applicationId=" +
        underwriting?.applicationId +
        "&disclosureId=" +
        item.DisclosureId;

      const response = await commonApiCall(apiEndPoint, "get", {}, "");

      if (response.status.code === 200) {
        // setOptionList(re)
        const list = JSON.parse(response.data);
        setReflexiveArr({ ...reflexiveArr, [index]: [] });
        setReflexiveQuestionList({ ...reflexiveQuestionList, [index]: { disclosureId: item.DisclosureId, list: list.DecisionTree.ReflexiveQuestion } });
      }
    } catch (err) {
      console.log(err);
      showErrorToast(err);
    }
    setQuestionLoading({ ...questionLoading, [index]: false });
  };

  const getReflexiveFromList = (value, list) => {
    return list.filter((x) => x.BranchValue === value)[0];
  };

  const getQuestionFromBranch = (reflex, value) => {
    if (reflex.ReflexiveQuestionType === "Text") {
      if (reflex.Branches.length > 1) {
        const currentBranch =
          reflex.Branches && value
            ? reflex.Branches.filter(
              (x) => x.BranchValue.toLowerCase() == value.toLowerCase()
            )
            : null;
        return currentBranch && currentBranch.length > 0
          ? currentBranch[0]
          : null;
      } else {
        return reflex.Branches.length > 0 && value !== "" && value
          ? reflex.Branches[0]
          : null;
      }
    } else if (reflex.ReflexiveQuestionType === "Numeric") {
      let currentBranch = null;
      if (reflex.Branches && value && reflex.Branches.length > 0) {
        for (let i = 0; i < reflex.Branches.length; i++) {
          if (parseInt(value) >= parseInt(reflex.Branches[i].BranchValue)) {
            currentBranch = reflex.Branches[i];
          } else {
            break;
          }
        }
      }
      return currentBranch ? currentBranch : null;
    } else {
      const currentBranch =
        reflex.Branches && value
          ? reflex.Branches.filter((x) => x.BranchValue == value)
          : null;

      return currentBranch && currentBranch.length > 0
        ? currentBranch[0]
        : null;
    }
  };

  const submitReflexive = async (question, index) => {
    try {
      if (
        Object.keys(reflexiveQuestionList)
        &&
        Object.keys(reflexiveQuestionList).length > 0) {
        reflexiveArr[index].length > 0 && await updateReflexiveQuestion(question, index, disclosure[index]);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const renderReflexiveQuestion = (reflex, disclosureIndex) => {
    const onValueChange = (id, value, branch = null) => {
      let tempReflexiveArr = [...reflexiveArr[disclosureIndex]];
      let reflexIndex = tempReflexiveArr.findIndex(
        (x) => x.reflexiveQuestionId === id.ReflexiveQuestionId
      );
      if (reflexIndex > -1) {
        tempReflexiveArr[reflexIndex].reflexiveQuestionAnswerValue =
          value;
        tempReflexiveArr.splice(reflexIndex + 1);
        // setIsNextDisabled(true);
      } else {
        const obj = {
          reflexiveQuestionId: id.ReflexiveQuestionId,
          reflexiveQuestionAnswerValue: value,
        };
        tempReflexiveArr.push(obj);
      }
      setReflexiveArr({ ...reflexiveArr, [disclosureIndex]: tempReflexiveArr });
      if (branch === null || branch?.ReflexiveQuestion === null) {
        // setIsNextDisabled(false);
      }
    };
    const getCurrentSelectedValue = (item) => {

      const filterReflex = reflexiveArr[disclosureIndex].filter(
        (x) => x.reflexiveQuestionId == item.ReflexiveQuestionId
      );
      if (filterReflex.length > 0) {
        return filterReflex[0].reflexiveQuestionAnswerValue;
      }
      return "";
    };

    return (
      <div
        className={
          `sbli-child-question_${disclosureIndex} question_` + reflex.ReflexiveQuestionId
        }
      >
        <h4 className="mb-4 ">{reflex.ReflexiveQuestionText}</h4>
        {reflex.ReflexiveQuestionType === "Boolean" && (
          <span className="custom-radio mt-3 d-block">
            {reflex.Branches.map((branch, index) => {
              return (
                <React.Fragment>
                  <label
                    className="m-0 mb-3"
                    htmlFor={reflex.ReflexiveQuestionId + "ab" + index}
                  >
                    <input
                      type="radio"
                      name={reflex.ReflexiveQuestionId}
                      id={reflex.ReflexiveQuestionId + "ab" + index}
                      value={branch.BranchValue}
                      checked={
                        getCurrentSelectedValue(reflex) === branch.BranchValue
                      }
                      onChange={(e) => onValueChange(reflex, e.target.value, branch)}
                    />
                    <span></span> {branch.BranchText}
                  </label>
                </React.Fragment>
              );
            })}
            {getQuestionFromBranch(reflex, getCurrentSelectedValue(reflex)) && getQuestionFromBranch(reflex, getCurrentSelectedValue(reflex))?.ReflexiveQuestion
              ? renderReflexiveQuestion(
                getQuestionFromBranch(reflex, getCurrentSelectedValue(reflex))?.ReflexiveQuestion, disclosureIndex
              )
              : null}
          </span>
        )}
        {reflex.ReflexiveQuestionType === "List" && (
          <React.Fragment>
            <Select
              className="mb-3"
              options={reflex.Branches.map((option) => ({
                value: option.BranchValue,
                label: option.BranchText,
              }))}
              value={
                getCurrentSelectedValue(reflex)
                  ? {
                    value: reflex.Branches.filter(
                      (x) => x.BranchValue === getCurrentSelectedValue(reflex)
                    )[0].BranchValue,
                    label: reflex.Branches.filter(
                      (x) => x.BranchValue === getCurrentSelectedValue(reflex)
                    )[0].BranchText,
                  }
                  : null
              }
              onChange={(e) =>
                onValueChange(reflex, e.value)
              }
            />
            {getCurrentSelectedValue(reflex) &&
              getReflexiveFromList(
                getCurrentSelectedValue(reflex),
                reflex.Branches
              ).ReflexiveQuestion !== null &&
              renderReflexiveQuestion(
                getReflexiveFromList(
                  getCurrentSelectedValue(reflex),
                  reflex.Branches
                ).ReflexiveQuestion, disclosureIndex
              )}
          </React.Fragment>
        )}
        {reflex.ReflexiveQuestionType === "Text" && (
        <React.Fragment>
          <div className="form-group ">
            <input
              className="form-control form-control-search"
              onChange={(e) =>
                onValueChange(
                  reflex,
                  e.target.value,
                  getQuestionFromBranch(reflex, e.target.value)
                )
              }
              type="text"
              value={getCurrentSelectedValue(reflex)}
              placeholder="Enter Text"
            />
          </div>
          {getQuestionFromBranch(reflex, getCurrentSelectedValue(reflex)) &&
            getQuestionFromBranch(reflex, getCurrentSelectedValue(reflex))
              ?.ReflexiveQuestion
            ? renderReflexiveQuestion(
              getQuestionFromBranch(reflex, getCurrentSelectedValue(reflex))
                ?.ReflexiveQuestion,
              disclosureIndex
            )
            : null}


        </React.Fragment>)}
        {reflex.ReflexiveQuestionType === "Numeric" && (
        <React.Fragment>
          <div className="form-group ">
            <input
              className="form-control form-control-search"
              onChange={(e) =>
                onValueChange(
                  reflex,
                  e.target.value,
                  getQuestionFromBranch(reflex, e.target.value)
                )
              }
              type="number"
              value={getCurrentSelectedValue(reflex)}
              placeholder="Enter numeric data"
            />
          </div>
          {getQuestionFromBranch(reflex, getCurrentSelectedValue(reflex)) &&
            getQuestionFromBranch(reflex, getCurrentSelectedValue(reflex))
              ?.ReflexiveQuestion
            ? renderReflexiveQuestion(
              getQuestionFromBranch(reflex, getCurrentSelectedValue(reflex))
                ?.ReflexiveQuestion,
              disclosureIndex
            )
            : null}


        </React.Fragment>
        )}
        
      </div>
    );
  };

  const validateForm = (key) => {
    let error = { ...errors };

    if (occupationValue[key]) {
      delete error[`occupation_${key}`];
      const reflexiveList = document.getElementsByClassName(
        `sbli-child-question_${key}`
      );

      for (let index = 0; index < reflexiveList.length; index++) {
        const element = reflexiveList[index];

        const questionId = element.classList[1].split("question_")[1];

        const answerIndex =
          reflexiveArr[key].findIndex((x) => x.reflexiveQuestionId == questionId) <
          0;
        if (answerIndex) {
          element.classList.add("validate-question-child");
          error["question_" + questionId] = "required";
        } else {
          element.classList.remove("validate-question-child");
          delete error["question_" + questionId];
        }
      }
    } else {
      error[`occupation_${key}`] = "this field is required";
    }
    console.log(error)
    setError(error);
    return Object.keys(error).length === 0;
  };

  const onSubmitForm = async () => {
    try {
      if (Object.keys(occupationValue).map((key) => validateForm(key)).every((x) => x === true)) {
        Object.keys(occupationValue).forEach(key => submitReflexive(baseQuestion[0], key));
        setAssurityProgressCount({
          ...assurityCount,
          underWritingProgress:
            assurityCount.underWritingProgress < 1
              ? 1
              : assurityCount.underWritingProgress,
        });
      }
    }
    catch (error) {
      console.log(error);
    }
  };

  const removeOccupation = (index) => {
    if (Object.keys(occupationValue).length > 1 && parseInt(index) !== Object.keys(occupationValue).length - 1) {
      Object.keys(occupationValue).forEach((key) => {
        if (key > index) {
          occupationValue[key - 1] = occupationValue[key];
          delete occupationValue[key];
          reflexiveArr[key - 1] = reflexiveArr[key];
          delete reflexiveArr[key];
          reflexiveQuestionList[key - 1] = reflexiveQuestionList[key];
          delete reflexiveQuestionList[key];
          disclosure[key - 1] = disclosure[key];
          delete disclosure[key];
        }
      });
    }
    else {
      parseInt(index) === 0 ? occupationValue[index] = "" : delete occupationValue[index];
      parseInt(index) === 0 ? reflexiveArr[index] = [] : delete reflexiveArr[index];
      parseInt(index) === 0 ? reflexiveQuestionList[index] = null : delete reflexiveQuestionList[index];
      parseInt(index) === 0 ? disclosure[index] = null : delete disclosure[index];
    }
    setError({});
    setOccupationValue({ ...occupationValue });
    setReflexiveArr({ ...reflexiveArr });
    setReflexiveQuestionList({ ...reflexiveQuestionList });
    setDisclosure({ ...disclosure });
  }

  return (
    <div className="underwriting-detail sbli-question-container pb-5">
      {baseQuestion &&
        baseQuestion.map((question) => {
          return (
            <div
              className={
                "sbli-parent-question " +
                " question_"
              }
              id="sbli_question_container"
              key={question.BaseQuestionId}
            >
              <h4 className="mb-4 parent_question_head">
                {question.BaseQuestionText}
              </h4>
              {Object.keys(occupationValue).map((index) =>
                <div className={"card text-left mb-3"}>
                  {question.BaseQuestionType === "Enter_Details" && (
                    <AssurityTextField
                      options={occupationList}
                      value={occupationValue[index]}
                      showRemove={parseInt(index) === 0 ? occupationValue[index] : (occupationValue[index] !== null && occupationValue[index] !== undefined)}
                      removeDisclosure={() => removeOccupation(index)}
                      onValueChange={(e, item) => {
                        if (Object.values(disclosure).includes(item.DisclosureId)) {
                          removeOccupation(index);
                        }
                        else {
                          updateBaseQuestion({
                            BaseQuestionId: question.BaseQuestionId,
                            value: e,
                          });
                          setOccupationValue({ ...occupationValue, [index]: e });
                          getReflexiveQuestions(item, index);
                          setDisclosure({ ...disclosure, [index]: item.DisclosureId });
                        }
                      }}
                      onInputChange={(d) => handleInputChange(question, d, index)}
                    />
                  )}
                  {reflexiveQuestionList && reflexiveQuestionList[index] &&
                    renderReflexiveQuestion(reflexiveQuestionList[index].list, index)}
                  {questionLoading[index] && <p className="mt-3">Please wait...</p>}

                  {occupationValue[Object.keys(occupationValue).length - 1] && parseInt(index) === Object.keys(occupationValue).length - 1 && <a className="d-inline-block mt-4 text-success" onClick={() => {
                    // setIsNextDisabled(true);
                    setOccupationValue({ ...occupationValue, [parseInt(index) + 1]: "" });
                  }}><u>Add another</u></a>}
                </div>)
              }
            </div>
          );
        })}

      <div className="w-100 my-4 pb-5 text-center">
        <button
          className="btn btn-default px-5"
          disabled={loading}
          onClick={
            onSubmitForm
          }
        >
          {loading ? "Processing..." : "Next"}
        </button>
      </div>
    </div >
  );
};

const mapStateToProps = (state) => ({
  underwriting: state.underwriting.underwriting,
  assurityCount: state.underwriting.assuProgressCount,
});
export default connect(mapStateToProps, {
  commonApiCall,
  setAssurityProgressCount,
})(withRouter(OccupationDetails));
