import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import {} from "react-bootstrap";
import { Animated } from "react-animated-css";
import { isEmpty } from "lodash";

import {
  commonApiCall,
  UNDERWRITING_DETAILS,
  getJwt,
} from "../../../redux/actions/index";
import {
  setProcessStep,
  getProcessStep,
  UnderWritingRoutes,
  showErrorToast,
  segmentTracking,
  getState,
} from "../../../utils/index";
import {
  API_URL_UNDERWRITING,
  CONSUMER_PORTAL_URL,
} from "../../../config/configs";

const Zipcode = ({ commonApiCall, underwriting, t, ...props }) => {
  const {
    applicationId,
    applicationType,
    goalType,
    policyType,
    postalCode,
    state,
  } = underwriting;
  const [formData, setFormData] = useState({
    applicationId: applicationId,
    applicationType: applicationType ? applicationType : "Web",
    zipCode: postalCode ? postalCode : "",
    state: state ? state : "",
    errors: {},
  });

  useEffect(() => {
    if (!applicationId || !goalType) {
      window.location.replace(CONSUMER_PORTAL_URL + "404");
    }

    if (underwriting && !isEmpty(underwriting.carrierWiseLeads)) {
      const whereToRoute = getProcessStep(
        underwriting.resumeProcess,
        underwriting.goalType,
        underwriting.policyType,
        underwriting.productSelection
      );
      props.history.push(whereToRoute);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const validateZip = () => {
    const { zipCode } = formData;
    let errors = {},
      isValid = true;
    if (zipCode.trim() == "") {
      isValid = false;
      errors.zipCode = t("zipCode.validation_msg");
    } else if (!getState(zipCode)) {
      isValid = false;
      errors.zipCode = t("zipCode.validate_zipCode");
    } else delete errors.zipCode;

    setFormData({ ...formData, errors: errors });
    return isValid;
  };

  async function doSubmit() {
    try {
      const splitpath = window.location.pathname.split("/");
      const { zipCode, applicationId, applicationType } = formData;
      let path = splitpath[1];
      if (goalType == "INSURE") {
        path = "insure-type";
      } else if (goalType == "SAVE" || goalType == "INVEST") {
        path = "investment-type";
      }
      if (validateZip()) {
        const request = {
          applicationId: applicationId,
          applicationType: applicationType,
          goalType: goalType,
          postalCode: zipCode,
          stateName: getState(zipCode).state,
          resumeProcess: setProcessStep(
            path,
            policyType,
            goalType,

            underwriting?.medicarePolicyFlag
          ),
        };

        const isAuthorized = getJwt() ? true : false;
        const apiEndPoint = API_URL_UNDERWRITING + "saveUnderWriting";
        const response = await commonApiCall(
          apiEndPoint,
          "post",
          request,
          UNDERWRITING_DETAILS,
          isAuthorized
        );
        if (response.status.code === 200) {
          const eventObject = {
            agent_id: response?.data?.agentDetail?.agentId,
            application_id: applicationId,
            param_name: "Zipcode",
            param_value: response.data.postalCode,
          };
          segmentTracking("application_set_param", eventObject);
          if (
            response?.data?.goalType == "SAVE" ||
            response?.data?.goalType == "INVEST"
          ) {
            props.history.push(UnderWritingRoutes.YOUR_FREQUENCY);
          } else if (response?.data?.goalType == "MEDICARE") {
            if (response?.data?.medicarePolicyFlag) {
              props.history.push(UnderWritingRoutes.MEDICARE_PLAN_LIST);
            } else {
              props.history.push(UnderWritingRoutes.GUEST_BASIC_DETAILS);
            }
          } else {
            props.history.push(UnderWritingRoutes.YOUR_DEPOSIT);
          }
        } else {
          showErrorToast(response.status.message);
        }
      }
    } catch (err) {
      showErrorToast(t("validation_message.SOMETHING_WENT_WRONG"));
    }
  }

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      doSubmit();
    }
  };

  return (
    <React.Fragment>
      <div className="steps">
        <div className="title-header">
          <h2 className="page-title">
            {underwriting.goalType === "MEDICARE"
              ? t("zipCode.enter_your_zipcode")
              : t("zipCode.whats_your_zipcode")}
          </h2>
          <h4>
            {underwriting.goalType === "MEDICARE"
              ? t("zipCode.medicare_zipcode_disc")
              : t("zipCode.we_ask_this_because")}
          </h4>
        </div>
        <Animated
          animationIn="fadeInRight"
          animationOut="zoomOut"
          animationInDuration={800}
          animationOutDuration={800}
          isVisible={true}
        >
          <div className="row justify-content-center">
            <div className="col-sm-4 col-8 mt-5">
              <div className="form-group">
                <div className="input-effect with-icon">
                  <input
                    className="form-control"
                    placeholder="First Name"
                    name="zipCode"
                    id="zipCode"
                    maxLength="5"
                    type="text"
                    value={formData.zipCode}
                    onKeyDown={(e) => handleKeyDown(e)}
                    onChange={(e) => handleChange(e)}
                  />
                  <label htmlFor="firstName">{t("zipCode.zipCode")}</label>
                  <em className="input-icon"></em>
                </div>
                {formData.errors.zipCode && (
                  <small className="text-danger err-msg">
                    {formData.errors.zipCode}
                  </small>
                )}
              </div>
            </div>
            <div className="col-12 text-center my-3">
              <button
                className="btn btn-primary"
                type="button"
                onClick={doSubmit}
              >
                {t("next")}
              </button>
            </div>
            <div className="col-12 text-center mt-3">
              <Link
                to={
                  goalType === "MEDICARE"
                    ? UnderWritingRoutes.YOUR_AGE
                    : UnderWritingRoutes.CITIZENSHIP
                }
                className="btn btn-link"
                title="Back"
              >
                {t("back")}
              </Link>
            </div>
          </div>
        </Animated>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  underwriting: state.underwriting.underwriting,
});

export default connect(mapStateToProps, { commonApiCall })(withRouter(Zipcode));
