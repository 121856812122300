import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import * as _ from "lodash";
import { NumberFormatter } from "../../utils/index";

export const ComparePlanModel = ({
  comparePlansModal,
  comparedPlans,
  suggestions,
  handleModel,
  searchData,
  t,
}) => {
  const [comparisionData, setComparisionData] = useState({
    comparedPlansWith: "death",
  });

  const handleComparePlanWith = (name, value) => {
    setComparisionData({ ...comparisionData, [name]: value });
  };

  const { comparedPlansWith } = comparisionData;
  comparedPlans = _.orderBy(comparedPlans, ["suggestionId"], ["asc"]);
  let comparePlanData1, comparePlanData2;

  if (comparedPlans.length > 1) {
    comparePlanData1 = _.find(suggestions, [
      "illustration_id",
      comparedPlans[0].illustration_id,
    ]);
    comparePlanData2 = _.find(suggestions, [
      "illustration_id",
      comparedPlans[1].illustration_id,
    ]);
  }

  if (
    searchData.yourGoalType.value === "INSURE" &&
    searchData.yourInsureBasedOn.value === "BUDGET"
  ) {
    const projectedCashValue10Yearp1 =
      comparedPlans[0].carrierId === 3 || comparedPlans[0].carrierId === 4
        ? comparePlanData1.illustration_data.age10.non_gauranteed_cash_surrender
        : comparePlanData1.illustration_data.age10.non_gauranteed_cash_value;

    const projectedCashValue10Yearp2 =
      comparedPlans[1].carrierId === 3 || comparedPlans[1].carrierId === 4
        ? comparePlanData2.illustration_data.age10.non_gauranteed_cash_surrender
        : comparePlanData2.illustration_data.age10.non_gauranteed_cash_value;

    if (projectedCashValue10Yearp2 > projectedCashValue10Yearp1) {
      comparePlanData2 = [
        comparePlanData1,
        comparePlanData1 == comparePlanData2,
      ][0];
    }
  }
  let isDisclaimer = "";
  if (
    comparePlanData1.disclaimer !== "" &&
    comparePlanData1.disclaimer !== "null" &&
    comparePlanData1.disclaimer !== null
  ) {
    isDisclaimer = comparePlanData1.disclaimer;
  }
  if (
    comparePlanData2.disclaimer !== "" &&
    comparePlanData2.disclaimer !== "null" &&
    comparePlanData2.disclaimer !== null
  ) {
    isDisclaimer = comparePlanData2.disclaimer;
  }

  const isIUL =
    (comparePlanData1.carrier_id === 3 || comparePlanData1.carrier_id === 4) &&
    (comparePlanData2.carrier_id === 3 || comparePlanData2.carrier_id === 4)
      ? true
      : false;
  const isComparePlanData1IUL =
    comparePlanData1.carrier_id === 3 || comparePlanData1.carrier_id === 4
      ? true
      : false;
  const isComparePlanData2IUL =
    comparePlanData2.carrier_id === 3 || comparePlanData2.carrier_id === 4
      ? true
      : false;

  return (
    <Modal
      className="plan-modal verification-modal"
      show={comparePlansModal}
      onHide={() => handleModel("comparePlansModal", false)}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>{t("plan_listing.compare_plans")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="text-right mb-4">
          <span className="custom-radio">
            <label className="">
              <input
                type="radio"
                name="comparedPlansWith"
                id="comparedPlansWith"
                value="death"
                onClick={() =>
                  handleComparePlanWith("comparedPlansWith", "death")
                }
                defaultChecked={comparedPlansWith === "death" ? true : false}
              />
              <span></span>
              <span className="emailcheck">
                {t("plan_listing.death_benefit")}
              </span>
            </label>
            <label className="">
              <input
                type="radio"
                name="comparedPlansWith"
                id="comparedPlansWith"
                value="cash"
                onClick={() =>
                  handleComparePlanWith("comparedPlansWith", "cash")
                }
                defaultChecked={comparedPlansWith === "cash" ? true : false}
              />
              <span></span>
              <span className="emailcheck">{t("plan_listing.cash_value")}</span>
            </label>
          </span>
        </div>
        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th colSpan="8">{t("plan_listing.carrier")}</th>
                <th colSpan="2" className="text-right">
                  <div className="carrier-info">
                    <span className="carrier-logo">
                      <img
                        src={comparePlanData1.carrierLogo}
                        alt="Carrier Logo"
                        title={comparePlanData1.carrier_name}
                      />
                    </span>{" "}
                    <span> {comparePlanData1.carrier_name} </span>
                  </div>
                </th>
                <th colSpan="2" className="text-right">
                  <div className="carrier-info">
                    <span className="carrier-logo">
                      <img
                        src={comparePlanData2.carrierLogo}
                        alt="Carrier Logo"
                        title={comparePlanData2.carrier_name}
                      />
                    </span>{" "}
                    <span>{comparePlanData2.carrier_name} </span>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td colSpan="8">{t("plan_listing.product_name")}</td>
                <td colSpan="2" className="text-right">
                  {comparePlanData1.productName}
                </td>
                <td colSpan="2" className="text-right">
                  {comparePlanData2.productName}
                </td>
              </tr>
              <tr>
                <td colSpan="8">{t("plan_listing.credit_rating")}</td>
                <td colSpan="2" className="text-right">
                  {comparePlanData1.credit_rating}
                </td>
                <td colSpan="2" className="text-right">
                  {comparePlanData2.credit_rating}
                </td>
              </tr>
              {!isIUL && (
                <tr>
                  <td colSpan="8">
                    {t("plan_listing.current_cash_value_dividend")}
                  </td>
                  <td colSpan="2" className="text-right">
                    {isComparePlanData1IUL
                      ? "N/A"
                      : comparePlanData1.cash_value_dividend
                      ? NumberFormatter(
                          comparePlanData1.cash_value_dividend.toString(),
                          "",
                          "%"
                        )
                      : "-"}
                  </td>
                  <td colSpan="2" className="text-right">
                    {isComparePlanData2IUL
                      ? "N/A"
                      : comparePlanData2.cash_value_dividend
                      ? NumberFormatter(
                          comparePlanData2.cash_value_dividend.toString(),
                          "",
                          "%"
                        )
                      : "-"}
                  </td>
                </tr>
              )}
              {!isIUL && (
                <tr>
                  <td colSpan="8">
                    {t("plan_listing.minimum_guaranteed_interest_rate")}
                  </td>

                  <td colSpan="2" className="text-right">
                    {isComparePlanData1IUL
                      ? "N/A"
                      : NumberFormatter(
                          comparePlanData1.min_interest_rate.toString(),
                          "",
                          "%"
                        )}
                  </td>
                  <td colSpan="2" className="text-right">
                    {isComparePlanData2IUL
                      ? "N/A"
                      : NumberFormatter(
                          comparePlanData2.min_interest_rate.toString(),
                          "",
                          "%"
                        )}
                  </td>
                </tr>
              )}
              <tr>
                <td colSpan="8">
                  {t("plan_listing.premium_contribution_period")}
                </td>
                <td colSpan="2" className="text-right">
                  {comparePlanData1.contributionPeriod}
                </td>
                <td colSpan="2" className="text-right">
                  {comparePlanData2.contributionPeriod}
                </td>
              </tr>
              <tr>
                <td colSpan="8">{t("plan_listing.annual_premium")}</td>
                <td colSpan="2" className="text-right">
                  <div className="compare-plan-data-same">
                    {NumberFormatter(comparePlanData1.premium, "$")}
                  </div>
                </td>
                <td colSpan="2" className="text-right">
                  <div className="compare-plan-data-same-right">
                    {NumberFormatter(comparePlanData2.premium, "$")}
                  </div>
                </td>
              </tr>
              {comparedPlansWith === "cash" ? (
                <React.Fragment>
                  <tr>
                    <td colSpan="8">
                      {t("plan_listing.projected_cash_value_at_year_10")}
                    </td>
                    <td colSpan="2" className="text-right">
                      {comparePlanData1.carrier_id === 3 ||
                      comparePlanData1.carrier_id === 4 ? (
                        <React.Fragment>
                          <div
                            className={
                              comparePlanData1.illustration_data.age10
                                .non_gauranteed_cash_surrender >
                                comparePlanData2.illustration_data.age10
                                  .non_gauranteed_cash_surrender &&
                              comparePlanData1.illustration_data.age10
                                .non_gauranteed_cash_surrender >
                                comparePlanData2.illustration_data.age10
                                  .non_gauranteed_cash_value
                                ? "compare-plan-data-greater"
                                : comparePlanData1.illustration_data.age10
                                    .non_gauranteed_cash_surrender ===
                                    comparePlanData2.illustration_data.age10
                                      .non_gauranteed_cash_surrender &&
                                  comparePlanData1.illustration_data.age10
                                    .non_gauranteed_cash_surrender ===
                                    comparePlanData2.illustration_data.age10
                                      .non_gauranteed_cash_value
                                ? "compare-plan-data-same"
                                : "compare-plan-data-lesser"
                            }
                          >
                            {comparePlanData1.productName ===
                            "Protection UL" ? (
                              <>
                                {comparePlanData1.illustration_data.age10
                                  .non_gauranteed_cash_value
                                  ? NumberFormatter(
                                      comparePlanData1.illustration_data.age10
                                        .non_gauranteed_cash_value,
                                      "$"
                                    )
                                  : "$0.00"}
                              </>
                            ) : (
                              <>
                                {comparePlanData1.illustration_data.age10
                                  .non_gauranteed_cash_surrender
                                  ? NumberFormatter(
                                      comparePlanData1.illustration_data.age10
                                        .non_gauranteed_cash_surrender,
                                      "$"
                                    )
                                  : "$0.00"}
                              </>
                            )}
                          </div>
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          <div
                            className={
                              comparePlanData1.illustration_data.age10
                                .non_gauranteed_cash_value >
                                comparePlanData2.illustration_data.age10
                                  .non_gauranteed_cash_value &&
                              comparePlanData1.illustration_data.age10
                                .non_gauranteed_cash_value >
                                comparePlanData2.illustration_data.age10
                                  .non_gauranteed_cash_surrender
                                ? "compare-plan-data-greater"
                                : comparePlanData1.illustration_data.age10
                                    .non_gauranteed_cash_value ===
                                    comparePlanData2.illustration_data.age10
                                      .non_gauranteed_cash_value &&
                                  comparePlanData1.illustration_data.age10
                                    .non_gauranteed_cash_value ===
                                    comparePlanData2.illustration_data.age10
                                      .non_gauranteed_cash_surrender
                                ? "compare-plan-data-same"
                                : "compare-plan-data-lesser"
                            }
                          >
                            {comparePlanData1.illustration_data.age10
                              .non_gauranteed_cash_value
                              ? NumberFormatter(
                                  comparePlanData1.illustration_data.age10
                                    .non_gauranteed_cash_value,
                                  "$"
                                )
                              : "$0.00"}
                          </div>
                        </React.Fragment>
                      )}
                    </td>
                    <td colSpan="2" className="text-right">
                      {comparePlanData1.carrier_id === 3 ||
                      comparePlanData1.carrier_id === 4 ? (
                        <React.Fragment>
                          <div
                            className={
                              comparePlanData2.illustration_data.age10
                                .non_gauranteed_cash_surrender >
                                comparePlanData1.illustration_data.age10
                                  .non_gauranteed_cash_surrender &&
                              comparePlanData2.illustration_data.age10
                                .non_gauranteed_cash_surrender >
                                comparePlanData1.illustration_data.age10
                                  .non_gauranteed_cash_value
                                ? "compare-plan-data-greater-right"
                                : comparePlanData2.illustration_data.age10
                                    .non_gauranteed_cash_surrender ===
                                    comparePlanData1.illustration_data.age10
                                      .non_gauranteed_cash_surrender &&
                                  comparePlanData2.illustration_data.age10
                                    .non_gauranteed_cash_surrender ===
                                    comparePlanData1.illustration_data.age10
                                      .non_gauranteed_cash_value
                                ? "compare-plan-data-same-right"
                                : "compare-plan-data-lesser-right"
                            }
                          >
                            {comparePlanData2.productName ===
                            "Protection UL" ? (
                              <>
                                {comparePlanData2.illustration_data.age10
                                  .non_gauranteed_cash_value
                                  ? NumberFormatter(
                                      comparePlanData2.illustration_data.age10
                                        .non_gauranteed_cash_value,
                                      "$"
                                    )
                                  : "$0.00"}
                              </>
                            ) : (
                              <>
                                {comparePlanData2.illustration_data.age10
                                  .non_gauranteed_cash_surrender
                                  ? NumberFormatter(
                                      comparePlanData2.illustration_data.age10
                                        .non_gauranteed_cash_surrender,
                                      "$"
                                    )
                                  : "$0.00"}
                              </>
                            )}
                          </div>
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          <div
                            className={
                              comparePlanData2.illustration_data.age10
                                .non_gauranteed_cash_value >
                                comparePlanData1.illustration_data.age10
                                  .non_gauranteed_cash_value &&
                              comparePlanData2.illustration_data.age10
                                .non_gauranteed_cash_value >
                                comparePlanData1.illustration_data.age10
                                  .non_gauranteed_cash_surrender
                                ? "compare-plan-data-greater-right"
                                : comparePlanData2.illustration_data.age10
                                    .non_gauranteed_cash_value ===
                                    comparePlanData1.illustration_data.age10
                                      .non_gauranteed_cash_value &&
                                  comparePlanData2.illustration_data.age10
                                    .non_gauranteed_cash_value ===
                                    comparePlanData1.illustration_data.age10
                                      .non_gauranteed_cash_surrender
                                ? "compare-plan-data-same-right"
                                : "compare-plan-data-lesser-right"
                            }
                          >
                            {comparePlanData2.illustration_data.age10
                              .non_gauranteed_cash_value
                              ? NumberFormatter(
                                  comparePlanData2.illustration_data.age10
                                    .non_gauranteed_cash_value,
                                  "$"
                                )
                              : "$0.00"}
                          </div>
                        </React.Fragment>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="8">
                      {t("plan_listing.projected_cash_value_at_year_20")}
                    </td>
                    <td colSpan="2" className="text-right">
                      {comparePlanData1.carrier_id === 3 ||
                      comparePlanData1.carrier_id === 4 ? (
                        <React.Fragment>
                          <div
                            className={
                              comparePlanData1.illustration_data.age20
                                .non_gauranteed_cash_surrender >
                                comparePlanData2.illustration_data.age20
                                  .non_gauranteed_cash_surrender &&
                              comparePlanData1.illustration_data.age20
                                .non_gauranteed_cash_surrender >
                                comparePlanData2.illustration_data.age20
                                  .non_gauranteed_cash_value
                                ? "compare-plan-data-greater"
                                : comparePlanData1.illustration_data.age20
                                    .non_gauranteed_cash_surrender ===
                                    comparePlanData2.illustration_data.age20
                                      .non_gauranteed_cash_surrender &&
                                  comparePlanData1.illustration_data.age20
                                    .non_gauranteed_cash_surrender ===
                                    comparePlanData2.illustration_data.age20
                                      .non_gauranteed_cash_value
                                ? "compare-plan-data-same"
                                : "compare-plan-data-lesser"
                            }
                          >
                            {comparePlanData1.productName ===
                            "Protection UL" ? (
                              <>
                                {comparePlanData1.illustration_data.age20
                                  .non_gauranteed_cash_value
                                  ? NumberFormatter(
                                      comparePlanData1.illustration_data.age20
                                        .non_gauranteed_cash_value,
                                      "$"
                                    )
                                  : "$0.00"}
                              </>
                            ) : (
                              <>
                                {comparePlanData1.illustration_data.age20
                                  .non_gauranteed_cash_surrender
                                  ? NumberFormatter(
                                      comparePlanData1.illustration_data.age20
                                        .non_gauranteed_cash_surrender,
                                      "$"
                                    )
                                  : "$0.00"}
                              </>
                            )}
                          </div>
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          <div
                            className={
                              comparePlanData1.illustration_data.age20
                                .non_gauranteed_cash_value >
                                comparePlanData2.illustration_data.age20
                                  .non_gauranteed_cash_value &&
                              comparePlanData1.illustration_data.age20
                                .non_gauranteed_cash_value >
                                comparePlanData2.illustration_data.age20
                                  .non_gauranteed_cash_surrender
                                ? "compare-plan-data-greater"
                                : comparePlanData1.illustration_data.age20
                                    .non_gauranteed_cash_value ===
                                    comparePlanData2.illustration_data.age20
                                      .non_gauranteed_cash_value &&
                                  comparePlanData1.illustration_data.age20
                                    .non_gauranteed_cash_value ===
                                    comparePlanData2.illustration_data.age20
                                      .non_gauranteed_cash_surrender
                                ? "compare-plan-data-same"
                                : "compare-plan-data-lesser"
                            }
                          >
                            {comparePlanData1.illustration_data.age20
                              .non_gauranteed_cash_value
                              ? NumberFormatter(
                                  comparePlanData1.illustration_data.age20
                                    .non_gauranteed_cash_value,
                                  "$"
                                )
                              : "$0.00"}
                          </div>
                        </React.Fragment>
                      )}
                    </td>
                    <td colSpan="2" className="text-right">
                      {comparePlanData1.carrier_id === 3 ||
                      comparePlanData1.carrier_id === 4 ? (
                        <React.Fragment>
                          <div
                            className={
                              comparePlanData2.illustration_data.age20
                                .non_gauranteed_cash_surrender >
                                comparePlanData1.illustration_data.age20
                                  .non_gauranteed_cash_surrender &&
                              comparePlanData2.illustration_data.age20
                                .non_gauranteed_cash_surrender >
                                comparePlanData1.illustration_data.age20
                                  .non_gauranteed_cash_value
                                ? "compare-plan-data-greater-right"
                                : comparePlanData2.illustration_data.age20
                                    .non_gauranteed_cash_surrender ===
                                    comparePlanData1.illustration_data.age20
                                      .non_gauranteed_cash_surrender &&
                                  comparePlanData2.illustration_data.age20
                                    .non_gauranteed_cash_surrender ===
                                    comparePlanData1.illustration_data.age20
                                      .non_gauranteed_cash_value
                                ? "compare-plan-data-same-right"
                                : "compare-plan-data-lesser-right"
                            }
                          >
                            {comparePlanData2.productName ===
                            "Protection UL" ? (
                              <>
                                {comparePlanData2.illustration_data.age20
                                  .non_gauranteed_cash_value
                                  ? NumberFormatter(
                                      comparePlanData2.illustration_data.age20
                                        .non_gauranteed_cash_value,
                                      "$"
                                    )
                                  : "$0.00"}
                              </>
                            ) : (
                              <>
                                {comparePlanData2.illustration_data.age20
                                  .non_gauranteed_cash_surrender
                                  ? NumberFormatter(
                                      comparePlanData2.illustration_data.age20
                                        .non_gauranteed_cash_surrender,
                                      "$"
                                    )
                                  : "$0.00"}
                              </>
                            )}
                          </div>
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          <div
                            className={
                              comparePlanData2.illustration_data.age20
                                .non_gauranteed_cash_value >
                                comparePlanData1.illustration_data.age20
                                  .non_gauranteed_cash_value &&
                              comparePlanData2.illustration_data.age20
                                .non_gauranteed_cash_value >
                                comparePlanData1.illustration_data.age20
                                  .non_gauranteed_cash_surrender
                                ? "compare-plan-data-greater-right"
                                : comparePlanData2.illustration_data.age20
                                    .non_gauranteed_cash_value ===
                                    comparePlanData1.illustration_data.age20
                                      .non_gauranteed_cash_value &&
                                  comparePlanData2.illustration_data.age20
                                    .non_gauranteed_cash_value ===
                                    comparePlanData1.illustration_data.age20
                                      .non_gauranteed_cash_surrender
                                ? "compare-plan-data-same-right"
                                : "compare-plan-data-lesser-right"
                            }
                          >
                            {comparePlanData2.illustration_data.age20
                              .non_gauranteed_cash_value
                              ? NumberFormatter(
                                  comparePlanData2.illustration_data.age20
                                    .non_gauranteed_cash_value,
                                  "$"
                                )
                              : "$0.00"}
                          </div>
                        </React.Fragment>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="8">
                      {t("plan_listing.projected_cash_value_at_year_30")}
                    </td>
                    <td colSpan="2" className="text-right">
                      {comparePlanData1.carrier_id === 3 ||
                      comparePlanData1.carrier_id === 4 ? (
                        <React.Fragment>
                          <div
                            className={
                              comparePlanData1.illustration_data.age30
                                .non_gauranteed_cash_surrender >
                                comparePlanData2.illustration_data.age30
                                  .non_gauranteed_cash_surrender &&
                              comparePlanData1.illustration_data.age30
                                .non_gauranteed_cash_surrender >
                                comparePlanData2.illustration_data.age30
                                  .non_gauranteed_cash_value
                                ? "compare-plan-data-greater"
                                : comparePlanData1.illustration_data.age30
                                    .non_gauranteed_cash_surrender ===
                                    comparePlanData2.illustration_data.age30
                                      .non_gauranteed_cash_surrender &&
                                  comparePlanData1.illustration_data.age30
                                    .non_gauranteed_cash_surrender ===
                                    comparePlanData2.illustration_data.age30
                                      .non_gauranteed_cash_value
                                ? "compare-plan-data-same"
                                : "compare-plan-data-lesser"
                            }
                          >
                            {comparePlanData1.productName ===
                            "Protection UL" ? (
                              <>
                                {comparePlanData1.illustration_data.age30
                                  .non_gauranteed_cash_value
                                  ? NumberFormatter(
                                      comparePlanData1.illustration_data.age30
                                        .non_gauranteed_cash_value,
                                      "$"
                                    )
                                  : "$0.00"}
                              </>
                            ) : (
                              <>
                                {comparePlanData1.illustration_data.age30
                                  .non_gauranteed_cash_surrender
                                  ? NumberFormatter(
                                      comparePlanData1.illustration_data.age30
                                        .non_gauranteed_cash_surrender,
                                      "$"
                                    )
                                  : "$0.00"}
                              </>
                            )}
                          </div>
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          <div
                            className={
                              comparePlanData1.illustration_data.age30
                                .non_gauranteed_cash_value >
                                comparePlanData2.illustration_data.age30
                                  .non_gauranteed_cash_value &&
                              comparePlanData1.illustration_data.age30
                                .non_gauranteed_cash_value >
                                comparePlanData2.illustration_data.age30
                                  .non_gauranteed_cash_surrender
                                ? "compare-plan-data-greater"
                                : comparePlanData1.illustration_data.age30
                                    .non_gauranteed_cash_value ===
                                    comparePlanData2.illustration_data.age30
                                      .non_gauranteed_cash_value &&
                                  comparePlanData1.illustration_data.age30
                                    .non_gauranteed_cash_value ===
                                    comparePlanData2.illustration_data.age30
                                      .non_gauranteed_cash_surrender
                                ? "compare-plan-data-same"
                                : "compare-plan-data-lesser"
                            }
                          >
                            {comparePlanData1.illustration_data.age30
                              .non_gauranteed_cash_value
                              ? NumberFormatter(
                                  comparePlanData1.illustration_data.age30
                                    .non_gauranteed_cash_value,
                                  "$"
                                )
                              : "$0.00"}
                          </div>
                        </React.Fragment>
                      )}
                    </td>
                    <td colSpan="2" className="text-right">
                      {comparePlanData1.carrier_id === 3 ||
                      comparePlanData1.carrier_id === 4 ? (
                        <React.Fragment>
                          <div
                            className={
                              comparePlanData2.illustration_data.age30
                                .non_gauranteed_cash_surrender >
                                comparePlanData1.illustration_data.age30
                                  .non_gauranteed_cash_surrender &&
                              comparePlanData2.illustration_data.age30
                                .non_gauranteed_cash_surrender >
                                comparePlanData1.illustration_data.age30
                                  .non_gauranteed_cash_value
                                ? "compare-plan-data-greater-right"
                                : comparePlanData2.illustration_data.age30
                                    .non_gauranteed_cash_surrender ===
                                    comparePlanData1.illustration_data.age30
                                      .non_gauranteed_cash_surrender &&
                                  comparePlanData2.illustration_data.age30
                                    .non_gauranteed_cash_surrender ===
                                    comparePlanData1.illustration_data.age30
                                      .non_gauranteed_cash_value
                                ? "compare-plan-data-same-right"
                                : "compare-plan-data-lesser-right"
                            }
                          >
                            {comparePlanData2.productName ===
                            "Protection UL" ? (
                              <>
                                {comparePlanData2.illustration_data.age30
                                  .non_gauranteed_cash_value
                                  ? NumberFormatter(
                                      comparePlanData2.illustration_data.age30
                                        .non_gauranteed_cash_value,
                                      "$"
                                    )
                                  : "$0.00"}
                              </>
                            ) : (
                              <>
                                {comparePlanData2.illustration_data.age30
                                  .non_gauranteed_cash_surrender
                                  ? NumberFormatter(
                                      comparePlanData2.illustration_data.age30
                                        .non_gauranteed_cash_surrender,
                                      "$"
                                    )
                                  : "$0.00"}
                              </>
                            )}
                          </div>
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          <div
                            className={
                              comparePlanData2.illustration_data.age30
                                .non_gauranteed_cash_value >
                                comparePlanData1.illustration_data.age30
                                  .non_gauranteed_cash_value &&
                              comparePlanData2.illustration_data.age30
                                .non_gauranteed_cash_value >
                                comparePlanData1.illustration_data.age30
                                  .non_gauranteed_cash_surrender
                                ? "compare-plan-data-greater-right"
                                : comparePlanData2.illustration_data.age30
                                    .non_gauranteed_cash_value ===
                                    comparePlanData1.illustration_data.age30
                                      .non_gauranteed_cash_value &&
                                  comparePlanData2.illustration_data.age30
                                    .non_gauranteed_cash_value ===
                                    comparePlanData1.illustration_data.age30
                                      .non_gauranteed_cash_surrender
                                ? "compare-plan-data-same-right"
                                : "compare-plan-data-lesser-right"
                            }
                          >
                            {comparePlanData2.illustration_data.age30
                              .non_gauranteed_cash_value
                              ? NumberFormatter(
                                  comparePlanData2.illustration_data.age30
                                    .non_gauranteed_cash_value,
                                  "$"
                                )
                              : "$0.00"}
                          </div>
                        </React.Fragment>
                      )}
                    </td>
                  </tr>
                  {!isIUL && (
                    <tr>
                      <td colSpan="8">
                        {t(
                          "plan_listing.minimum_guaranteed_cash_value_at_year_10"
                        )}
                      </td>
                      <td colSpan="2" className="text-right">
                        {(comparePlanData1.carrier_id === 3 ||
                          comparePlanData1.carrier_id === 4) &&
                        (comparePlanData1.productName !== "Protection UL" ||
                          comparePlanData2.productName !== "Protection UL") ? (
                          <React.Fragment>
                            <div
                              className={
                                comparePlanData1.illustration_data.age10
                                  .gauranteed_cash_surrender >
                                  comparePlanData2.illustration_data.age10
                                    .gauranteed_cash_surrender &&
                                comparePlanData1.illustration_data.age10
                                  .gauranteed_cash_surrender >
                                  comparePlanData2.illustration_data.age10
                                    .gauranteed_cash_value
                                  ? "compare-plan-data-greater"
                                  : comparePlanData1.illustration_data.age10
                                      .gauranteed_cash_surrender ===
                                      comparePlanData2.illustration_data.age10
                                        .gauranteed_cash_surrender &&
                                    comparePlanData1.illustration_data.age10
                                      .gauranteed_cash_surrender ===
                                      comparePlanData2.illustration_data.age10
                                        .gauranteed_cash_value
                                  ? "compare-plan-data-same"
                                  : "compare-plan-data-lesser"
                              }
                            >
                              {isComparePlanData1IUL
                                ? "N/A"
                                : comparePlanData1.illustration_data.age10
                                    .gauranteed_cash_surrender
                                ? NumberFormatter(
                                    comparePlanData1.illustration_data.age10
                                      .gauranteed_cash_surrender,
                                    "$"
                                  )
                                : "$0.00"}
                            </div>
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                            <div
                              className={
                                comparePlanData1.illustration_data.age10
                                  .gauranteed_cash_value >
                                  comparePlanData2.illustration_data.age10
                                    .gauranteed_cash_value &&
                                comparePlanData1.illustration_data.age10
                                  .gauranteed_cash_value >
                                  comparePlanData2.illustration_data.age10
                                    .gauranteed_cash_surrender
                                  ? "compare-plan-data-greater"
                                  : comparePlanData1.illustration_data.age10
                                      .gauranteed_cash_value ===
                                      comparePlanData2.illustration_data.age10
                                        .gauranteed_cash_value &&
                                    comparePlanData1.illustration_data.age10
                                      .gauranteed_cash_value ===
                                      comparePlanData2.illustration_data.age10
                                        .gauranteed_cash_surrender
                                  ? "compare-plan-data-same"
                                  : "compare-plan-data-lesser"
                              }
                            >
                              {isComparePlanData1IUL
                                ? "N/A"
                                : NumberFormatter(
                                    comparePlanData1.illustration_data.age10
                                      .gauranteed_cash_value,
                                    "$"
                                  )}
                            </div>
                          </React.Fragment>
                        )}
                      </td>
                      <td colSpan="2" className="text-right">
                        {(comparePlanData1.carrier_id === 3 ||
                          comparePlanData1.carrier_id === 4) &&
                        (comparePlanData1.productName !== "Protection UL" ||
                          comparePlanData2.productName !== "Protection UL") ? (
                          <React.Fragment>
                            <div
                              className={
                                comparePlanData2.illustration_data.age10
                                  .gauranteed_cash_surrender >
                                  comparePlanData1.illustration_data.age10
                                    .gauranteed_cash_surrender &&
                                comparePlanData2.illustration_data.age10
                                  .gauranteed_cash_surrender >
                                  comparePlanData1.illustration_data.age10
                                    .gauranteed_cash_value
                                  ? "compare-plan-data-greater-right"
                                  : comparePlanData2.illustration_data.age10
                                      .gauranteed_cash_surrender ===
                                      comparePlanData1.illustration_data.age10
                                        .gauranteed_cash_surrender &&
                                    comparePlanData2.illustration_data.age10
                                      .gauranteed_cash_surrender ===
                                      comparePlanData1.illustration_data.age10
                                        .gauranteed_cash_value
                                  ? "compare-plan-data-same-right"
                                  : "compare-plan-data-lesser-right"
                              }
                            >
                              {isComparePlanData2IUL
                                ? "N/A"
                                : comparePlanData2.illustration_data.age10
                                    .gauranteed_cash_surrender
                                ? NumberFormatter(
                                    comparePlanData2.illustration_data.age10
                                      .gauranteed_cash_surrender,
                                    "$"
                                  )
                                : "$0.00"}
                            </div>
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                            <div
                              className={
                                comparePlanData2.illustration_data.age10
                                  .gauranteed_cash_value >
                                  comparePlanData1.illustration_data.age10
                                    .gauranteed_cash_value &&
                                comparePlanData2.illustration_data.age10
                                  .gauranteed_cash_value >
                                  comparePlanData1.illustration_data.age10
                                    .gauranteed_cash_surrender
                                  ? "compare-plan-data-greater-right"
                                  : comparePlanData2.illustration_data.age10
                                      .gauranteed_cash_value ===
                                      comparePlanData1.illustration_data.age10
                                        .gauranteed_cash_value &&
                                    comparePlanData2.illustration_data.age10
                                      .gauranteed_cash_value ===
                                      comparePlanData1.illustration_data.age10
                                        .gauranteed_cash_surrender
                                  ? "compare-plan-data-same-right"
                                  : "compare-plan-data-lesser-right"
                              }
                            >
                              {isComparePlanData2IUL
                                ? "N/A"
                                : NumberFormatter(
                                    comparePlanData2.illustration_data.age10
                                      .gauranteed_cash_value,
                                    "$"
                                  )}
                            </div>
                          </React.Fragment>
                        )}
                      </td>
                    </tr>
                  )}
                  {!isIUL && (
                    <tr>
                      <td colSpan="8">
                        {t(
                          "plan_listing.minimum_guaranteed_cash_value_at_year_20"
                        )}
                      </td>
                      <td colSpan="2" className="text-right">
                        {(comparePlanData1.carrier_id === 3 ||
                          comparePlanData1.carrier_id === 4) &&
                        (comparePlanData1.productName !== "Protection UL" ||
                          comparePlanData2.productName !== "Protection UL") ? (
                          <React.Fragment>
                            <div
                              className={
                                comparePlanData1.illustration_data.age20
                                  .gauranteed_cash_surrender >
                                  comparePlanData2.illustration_data.age20
                                    .gauranteed_cash_surrender &&
                                comparePlanData1.illustration_data.age20
                                  .gauranteed_cash_surrender >
                                  comparePlanData2.illustration_data.age20
                                    .gauranteed_cash_value
                                  ? "compare-plan-data-greater"
                                  : comparePlanData1.illustration_data.age20
                                      .gauranteed_cash_surrender ===
                                      comparePlanData2.illustration_data.age20
                                        .gauranteed_cash_surrender &&
                                    comparePlanData1.illustration_data.age20
                                      .gauranteed_cash_surrender ===
                                      comparePlanData2.illustration_data.age20
                                        .gauranteed_cash_value
                                  ? "compare-plan-data-same"
                                  : "compare-plan-data-lesser"
                              }
                            >
                              {isComparePlanData1IUL
                                ? "N/A"
                                : comparePlanData1.illustration_data.age20
                                    .gauranteed_cash_surrender
                                ? NumberFormatter(
                                    comparePlanData1.illustration_data.age20
                                      .gauranteed_cash_surrender,
                                    "$"
                                  )
                                : "$0.00"}
                            </div>
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                            <div
                              className={
                                comparePlanData1.illustration_data.age20
                                  .gauranteed_cash_value >
                                  comparePlanData2.illustration_data.age20
                                    .gauranteed_cash_value &&
                                comparePlanData1.illustration_data.age20
                                  .gauranteed_cash_value >
                                  comparePlanData2.illustration_data.age20
                                    .gauranteed_cash_surrender
                                  ? "compare-plan-data-greater"
                                  : comparePlanData1.illustration_data.age20
                                      .gauranteed_cash_value ===
                                      comparePlanData2.illustration_data.age20
                                        .gauranteed_cash_value &&
                                    comparePlanData1.illustration_data.age20
                                      .gauranteed_cash_value ===
                                      comparePlanData2.illustration_data.age20
                                        .gauranteed_cash_surrender
                                  ? "compare-plan-data-same"
                                  : "compare-plan-data-lesser"
                              }
                            >
                              {isComparePlanData1IUL
                                ? "N/A"
                                : NumberFormatter(
                                    comparePlanData1.illustration_data.age20
                                      .gauranteed_cash_value,
                                    "$"
                                  )}
                            </div>
                          </React.Fragment>
                        )}
                      </td>
                      <td colSpan="2" className="text-right">
                        {(comparePlanData1.carrier_id === 3 ||
                          comparePlanData1.carrier_id === 4) &&
                        (comparePlanData1.productName !== "Protection UL" ||
                          comparePlanData2.productName !== "Protection UL") ? (
                          <React.Fragment>
                            <div
                              className={
                                comparePlanData2.illustration_data.age20
                                  .gauranteed_cash_surrender >
                                  comparePlanData1.illustration_data.age20
                                    .gauranteed_cash_surrender &&
                                comparePlanData2.illustration_data.age20
                                  .gauranteed_cash_surrender >
                                  comparePlanData1.illustration_data.age20
                                    .gauranteed_cash_value
                                  ? "compare-plan-data-greater-right"
                                  : comparePlanData2.illustration_data.age20
                                      .gauranteed_cash_surrender ===
                                      comparePlanData1.illustration_data.age20
                                        .gauranteed_cash_surrender &&
                                    comparePlanData2.illustration_data.age20
                                      .gauranteed_cash_surrender ===
                                      comparePlanData1.illustration_data.age20
                                        .gauranteed_cash_value
                                  ? "compare-plan-data-same-right"
                                  : "compare-plan-data-lesser-right"
                              }
                            >
                              {isComparePlanData2IUL
                                ? "N/A"
                                : comparePlanData2.illustration_data.age20
                                    .gauranteed_cash_surrender
                                ? NumberFormatter(
                                    comparePlanData2.illustration_data.age20
                                      .gauranteed_cash_surrender,
                                    "$"
                                  )
                                : "$0.00"}
                            </div>
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                            <div
                              className={
                                comparePlanData2.illustration_data.age20
                                  .gauranteed_cash_value >
                                  comparePlanData1.illustration_data.age20
                                    .gauranteed_cash_value &&
                                comparePlanData2.illustration_data.age20
                                  .gauranteed_cash_value >
                                  comparePlanData1.illustration_data.age20
                                    .gauranteed_cash_surrender
                                  ? "compare-plan-data-greater-right"
                                  : comparePlanData2.illustration_data.age20
                                      .gauranteed_cash_value ===
                                      comparePlanData1.illustration_data.age20
                                        .gauranteed_cash_value &&
                                    comparePlanData2.illustration_data.age20
                                      .gauranteed_cash_value ===
                                      comparePlanData1.illustration_data.age20
                                        .gauranteed_cash_surrender
                                  ? "compare-plan-data-same-right"
                                  : "compare-plan-data-lesser-right"
                              }
                            >
                              {isComparePlanData2IUL
                                ? "N/A"
                                : NumberFormatter(
                                    comparePlanData2.illustration_data.age20
                                      .gauranteed_cash_value,
                                    "$"
                                  )}
                            </div>
                          </React.Fragment>
                        )}
                      </td>
                    </tr>
                  )}
                  {!isIUL && (
                    <tr>
                      <td colSpan="8">
                        {t(
                          "plan_listing.minimum_guaranteed_cash_value_at_year_30"
                        )}
                      </td>
                      <td colSpan="2" className="text-right">
                        {(comparePlanData1.carrier_id === 3 ||
                          comparePlanData1.carrier_id === 4) &&
                        (comparePlanData1.productName !== "Protection UL" ||
                          comparePlanData2.productName !== "Protection UL") ? (
                          <React.Fragment>
                            <div
                              className={
                                comparePlanData1.illustration_data.age30
                                  .gauranteed_cash_surrender >
                                  comparePlanData2.illustration_data.age30
                                    .gauranteed_cash_surrender &&
                                comparePlanData1.illustration_data.age30
                                  .gauranteed_cash_surrender >
                                  comparePlanData2.illustration_data.age30
                                    .gauranteed_cash_value
                                  ? "compare-plan-data-greater"
                                  : comparePlanData1.illustration_data.age30
                                      .gauranteed_cash_surrender ===
                                      comparePlanData2.illustration_data.age30
                                        .gauranteed_cash_surrender &&
                                    comparePlanData1.illustration_data.age30
                                      .gauranteed_cash_surrender ===
                                      comparePlanData2.illustration_data.age30
                                        .gauranteed_cash_value
                                  ? "compare-plan-data-same"
                                  : "compare-plan-data-lesser"
                              }
                            >
                              {isComparePlanData1IUL
                                ? "N/A"
                                : comparePlanData1.illustration_data.age30
                                    .gauranteed_cash_surrender
                                ? NumberFormatter(
                                    comparePlanData1.illustration_data.age30
                                      .gauranteed_cash_surrender,
                                    "$"
                                  )
                                : "$0.00"}
                            </div>
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                            <div
                              className={
                                comparePlanData1.illustration_data.age30
                                  .gauranteed_cash_value >
                                  comparePlanData2.illustration_data.age30
                                    .gauranteed_cash_value &&
                                comparePlanData1.illustration_data.age30
                                  .gauranteed_cash_value >
                                  comparePlanData2.illustration_data.age30
                                    .gauranteed_cash_surrender
                                  ? "compare-plan-data-greater"
                                  : comparePlanData1.illustration_data.age30
                                      .gauranteed_cash_value ===
                                      comparePlanData2.illustration_data.age30
                                        .gauranteed_cash_value &&
                                    comparePlanData1.illustration_data.age30
                                      .gauranteed_cash_value ===
                                      comparePlanData2.illustration_data.age30
                                        .gauranteed_cash_surrender
                                  ? "compare-plan-data-same"
                                  : "compare-plan-data-lesser"
                              }
                            >
                              {isComparePlanData1IUL
                                ? "N/A"
                                : NumberFormatter(
                                    comparePlanData1.illustration_data.age30
                                      .gauranteed_cash_value,
                                    "$"
                                  )}
                            </div>
                          </React.Fragment>
                        )}
                      </td>
                      <td colSpan="2" className="text-right">
                        {(comparePlanData1.carrier_id === 3 ||
                          comparePlanData1.carrier_id === 4) &&
                        (comparePlanData1.productName !== "Protection UL" ||
                          comparePlanData2.productName !== "Protection UL") ? (
                          <React.Fragment>
                            <div
                              className={
                                comparePlanData2.illustration_data.age30
                                  .gauranteed_cash_surrender >
                                  comparePlanData1.illustration_data.age30
                                    .gauranteed_cash_surrender &&
                                comparePlanData2.illustration_data.age30
                                  .gauranteed_cash_surrender >
                                  comparePlanData1.illustration_data.age30
                                    .gauranteed_cash_value
                                  ? "compare-plan-data-greater-right"
                                  : comparePlanData2.illustration_data.age30
                                      .gauranteed_cash_surrender ===
                                      comparePlanData1.illustration_data.age30
                                        .gauranteed_cash_surrender &&
                                    comparePlanData2.illustration_data.age30
                                      .gauranteed_cash_surrender ===
                                      comparePlanData1.illustration_data.age30
                                        .gauranteed_cash_value
                                  ? "compare-plan-data-same-right"
                                  : "compare-plan-data-lesser-right"
                              }
                            >
                              {isComparePlanData2IUL
                                ? "N/A"
                                : comparePlanData2.illustration_data.age30
                                    .gauranteed_cash_surrender
                                ? NumberFormatter(
                                    comparePlanData2.illustration_data.age30
                                      .gauranteed_cash_surrender,
                                    "$"
                                  )
                                : "$0.00"}
                            </div>
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                            <div
                              className={
                                comparePlanData2.illustration_data.age30
                                  .gauranteed_cash_value >
                                  comparePlanData1.illustration_data.age30
                                    .gauranteed_cash_value &&
                                comparePlanData2.illustration_data.age30
                                  .gauranteed_cash_value >
                                  comparePlanData1.illustration_data.age30
                                    .gauranteed_cash_surrender
                                  ? "compare-plan-data-greater-right"
                                  : comparePlanData2.illustration_data.age30
                                      .gauranteed_cash_value ===
                                      comparePlanData1.illustration_data.age30
                                        .gauranteed_cash_value &&
                                    comparePlanData2.illustration_data.age30
                                      .gauranteed_cash_value ===
                                      comparePlanData1.illustration_data.age30
                                        .gauranteed_cash_surrender
                                  ? "compare-plan-data-same-right"
                                  : "compare-plan-data-lesser-right"
                              }
                            >
                              {isComparePlanData2IUL
                                ? "N/A"
                                : NumberFormatter(
                                    comparePlanData2.illustration_data.age30
                                      .gauranteed_cash_value,
                                    "$"
                                  )}
                            </div>
                          </React.Fragment>
                        )}
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <tr>
                    <td colSpan="8">
                      {t("plan_listing.projected_death_benefit_at_year_10")}
                    </td>
                    <td colSpan="2" className="text-right">
                      <div
                        className={
                          comparePlanData1.illustration_data.age10
                            .non_gauranteed_death_benefit >
                          comparePlanData2.illustration_data.age10
                            .non_gauranteed_death_benefit
                            ? "compare-plan-data-greater"
                            : comparePlanData1.illustration_data.age10
                                .non_gauranteed_death_benefit ===
                              comparePlanData2.illustration_data.age10
                                .non_gauranteed_death_benefit
                            ? "compare-plan-data-same"
                            : "compare-plan-data-lesser"
                        }
                      >
                        {comparePlanData1.illustration_data.age10
                          .non_gauranteed_death_benefit
                          ? NumberFormatter(
                              comparePlanData1.illustration_data.age10
                                .non_gauranteed_death_benefit,
                              "$"
                            )
                          : "$0.00"}
                      </div>
                    </td>
                    <td colSpan="2" className="text-right">
                      <div
                        className={
                          comparePlanData2.illustration_data.age10
                            .non_gauranteed_death_benefit >
                          comparePlanData1.illustration_data.age10
                            .non_gauranteed_death_benefit
                            ? "compare-plan-data-greater-right"
                            : comparePlanData2.illustration_data.age10
                                .non_gauranteed_death_benefit ===
                              comparePlanData1.illustration_data.age10
                                .non_gauranteed_death_benefit
                            ? "compare-plan-data-same-right"
                            : "compare-plan-data-lesser-right"
                        }
                      >
                        {comparePlanData2.illustration_data.age10
                          .non_gauranteed_death_benefit
                          ? NumberFormatter(
                              comparePlanData2.illustration_data.age10
                                .non_gauranteed_death_benefit,
                              "$"
                            )
                          : "$0.00"}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="8">
                      {t("plan_listing.projected_death_benefit_at_year_20")}
                    </td>
                    <td colSpan="2" className="text-right">
                      <div
                        className={
                          comparePlanData1.illustration_data.age20
                            .non_gauranteed_death_benefit >
                          comparePlanData2.illustration_data.age20
                            .non_gauranteed_death_benefit
                            ? "compare-plan-data-greater"
                            : comparePlanData1.illustration_data.age20
                                .non_gauranteed_death_benefit ===
                              comparePlanData2.illustration_data.age20
                                .non_gauranteed_death_benefit
                            ? "compare-plan-data-same"
                            : "compare-plan-data-lesser"
                        }
                      >
                        {comparePlanData1.illustration_data.age20
                          .non_gauranteed_death_benefit
                          ? NumberFormatter(
                              comparePlanData1.illustration_data.age20
                                .non_gauranteed_death_benefit,
                              "$"
                            )
                          : "$0.00"}
                      </div>
                    </td>
                    <td colSpan="2" className="text-right">
                      <div
                        className={
                          comparePlanData2.illustration_data.age20
                            .non_gauranteed_death_benefit >
                          comparePlanData1.illustration_data.age20
                            .non_gauranteed_death_benefit
                            ? "compare-plan-data-greater-right"
                            : comparePlanData2.illustration_data.age20
                                .non_gauranteed_death_benefit ===
                              comparePlanData1.illustration_data.age20
                                .non_gauranteed_death_benefit
                            ? "compare-plan-data-same-right"
                            : "compare-plan-data-lesser-right"
                        }
                      >
                        {comparePlanData2.illustration_data.age20
                          .non_gauranteed_death_benefit
                          ? NumberFormatter(
                              comparePlanData2.illustration_data.age20
                                .non_gauranteed_death_benefit,
                              "$"
                            )
                          : "$0.00"}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="8">
                      {t("plan_listing.projected_death_benefit_at_year_30")}
                    </td>
                    <td colSpan="2" className="text-right">
                      <div
                        className={
                          comparePlanData1.illustration_data.age30
                            .non_gauranteed_death_benefit >
                          comparePlanData2.illustration_data.age30
                            .non_gauranteed_death_benefit
                            ? "compare-plan-data-greater"
                            : comparePlanData1.illustration_data.age30
                                .non_gauranteed_death_benefit ===
                              comparePlanData2.illustration_data.age30
                                .non_gauranteed_death_benefit
                            ? "compare-plan-data-same"
                            : "compare-plan-data-lesser"
                        }
                      >
                        {comparePlanData1.illustration_data.age30
                          .non_gauranteed_death_benefit
                          ? NumberFormatter(
                              comparePlanData1.illustration_data.age30
                                .non_gauranteed_death_benefit,
                              "$"
                            )
                          : "$0.00"}
                      </div>
                    </td>
                    <td colSpan="2" className="text-right">
                      <div
                        className={
                          comparePlanData2.illustration_data.age30
                            .non_gauranteed_death_benefit >
                          comparePlanData1.illustration_data.age30
                            .non_gauranteed_death_benefit
                            ? "compare-plan-data-greater-right"
                            : comparePlanData2.illustration_data.age30
                                .non_gauranteed_death_benefit ===
                              comparePlanData1.illustration_data.age30
                                .non_gauranteed_death_benefit
                            ? "compare-plan-data-same-right"
                            : "compare-plan-data-lesser-right"
                        }
                      >
                        {comparePlanData2.illustration_data.age30
                          .non_gauranteed_death_benefit
                          ? NumberFormatter(
                              comparePlanData2.illustration_data.age30
                                .non_gauranteed_death_benefit,
                              "$"
                            )
                          : "$0.00"}
                      </div>
                    </td>
                  </tr>
                  {!isIUL && (
                    <>
                      <tr>
                        <td colSpan="8">
                          {t(
                            "plan_listing.minimum_guaranteed_death_benefit_at_year_10"
                          )}
                        </td>
                        <td colSpan="2" className="text-right">
                          <div
                            className={
                              comparePlanData1.illustration_data.age10
                                .gauranteed_death_benefit >
                              comparePlanData2.illustration_data.age10
                                .gauranteed_death_benefit
                                ? "compare-plan-data-greater"
                                : comparePlanData1.illustration_data.age10
                                    .gauranteed_death_benefit ===
                                  comparePlanData2.illustration_data.age10
                                    .gauranteed_death_benefit
                                ? "compare-plan-data-same"
                                : "compare-plan-data-lesser"
                            }
                          >
                            {isComparePlanData1IUL
                              ? "N/A"
                              : NumberFormatter(
                                  comparePlanData1.illustration_data.age10
                                    .gauranteed_death_benefit,
                                  "$"
                                )}
                          </div>
                        </td>
                        <td colSpan="2" className="text-right">
                          <div
                            className={
                              comparePlanData2.illustration_data.age10
                                .gauranteed_death_benefit >
                              comparePlanData1.illustration_data.age10
                                .gauranteed_death_benefit
                                ? "compare-plan-data-greater-right"
                                : comparePlanData2.illustration_data.age10
                                    .gauranteed_death_benefit ===
                                  comparePlanData1.illustration_data.age10
                                    .gauranteed_death_benefit
                                ? "compare-plan-data-same-right"
                                : "compare-plan-data-lesser-right"
                            }
                          >
                            {isComparePlanData2IUL
                              ? "N/A"
                              : NumberFormatter(
                                  comparePlanData2.illustration_data.age10
                                    .gauranteed_death_benefit,
                                  "$"
                                )}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan="8">
                          {t(
                            "plan_listing.minimum_guaranteed_death_benefit_at_year_20"
                          )}
                        </td>
                        <td colSpan="2" className="text-right">
                          <div
                            className={
                              comparePlanData1.illustration_data.age20
                                .gauranteed_death_benefit >
                              comparePlanData2.illustration_data.age20
                                .gauranteed_death_benefit
                                ? "compare-plan-data-greater"
                                : comparePlanData1.illustration_data.age20
                                    .gauranteed_death_benefit ===
                                  comparePlanData2.illustration_data.age20
                                    .gauranteed_death_benefit
                                ? "compare-plan-data-same"
                                : "compare-plan-data-lesser"
                            }
                          >
                            {isComparePlanData1IUL
                              ? "N/A"
                              : NumberFormatter(
                                  comparePlanData1.illustration_data.age20
                                    .gauranteed_death_benefit,
                                  "$"
                                )}
                          </div>
                        </td>
                        <td colSpan="2" className="text-right">
                          <div
                            className={
                              comparePlanData2.illustration_data.age20
                                .gauranteed_death_benefit >
                              comparePlanData1.illustration_data.age20
                                .gauranteed_death_benefit
                                ? "compare-plan-data-greater-right"
                                : comparePlanData2.illustration_data.age20
                                    .gauranteed_death_benefit ===
                                  comparePlanData1.illustration_data.age20
                                    .gauranteed_death_benefit
                                ? "compare-plan-data-same-right"
                                : "compare-plan-data-lesser-right"
                            }
                          >
                            {isComparePlanData2IUL
                              ? "N/A"
                              : NumberFormatter(
                                  comparePlanData2.illustration_data.age20
                                    .gauranteed_death_benefit,
                                  "$"
                                )}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan="8">
                          {t(
                            "plan_listing.minimum_guaranteed_death_benefit_at_year_30"
                          )}
                        </td>
                        <td colSpan="2" className="text-right">
                          <div
                            className={
                              comparePlanData1.illustration_data.age30
                                .gauranteed_death_benefit >
                              comparePlanData2.illustration_data.age30
                                .gauranteed_death_benefit
                                ? "compare-plan-data-greater"
                                : comparePlanData1.illustration_data.age30
                                    .gauranteed_death_benefit ===
                                  comparePlanData2.illustration_data.age30
                                    .gauranteed_death_benefit
                                ? "compare-plan-data-same"
                                : "compare-plan-data-lesser"
                            }
                          >
                            {isComparePlanData1IUL
                              ? "N/A"
                              : NumberFormatter(
                                  comparePlanData1.illustration_data.age30
                                    .gauranteed_death_benefit,
                                  "$"
                                )}
                          </div>
                        </td>
                        <td colSpan="2" className="text-right">
                          <div
                            className={
                              comparePlanData2.illustration_data.age30
                                .gauranteed_death_benefit >
                              comparePlanData1.illustration_data.age30
                                .gauranteed_death_benefit
                                ? "compare-plan-data-greater-right"
                                : comparePlanData2.illustration_data.age30
                                    .gauranteed_death_benefit ===
                                  comparePlanData1.illustration_data.age30
                                    .gauranteed_death_benefit
                                ? "compare-plan-data-same-right"
                                : "compare-plan-data-lesser-right"
                            }
                          >
                            {isComparePlanData2IUL
                              ? "N/A"
                              : NumberFormatter(
                                  comparePlanData2.illustration_data.age30
                                    .gauranteed_death_benefit,
                                  "$"
                                )}
                          </div>
                        </td>
                      </tr>
                    </>
                  )}
                </React.Fragment>
              )}
            </tbody>
          </table>
          {isDisclaimer && (
            <span>
              <label>*Disclaimer:</label> {isDisclaimer}
            </span>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};
