import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

export const PlanFilterLoader = () => {
  return (
    <div
      id="pre-loader-plan-suggestion"
      className="card-loader skeleton-loader"
    >
      <SkeletonTheme>
        <div className="mb-3">
          <Skeleton count={1} width={36} height={36} circle={true} />
          <span style={{ marginLeft: 20 }}>
            <Skeleton count={1} width={180} height={36} />
          </span>
        </div>
        <Skeleton count={1} height={2} />
        <div className="mb-2">
          <Skeleton count={1} width={90} height={28} />
          <span style={{ marginLeft: 10 }}>
            <Skeleton count={1} width={90} height={28} />
          </span>
        </div>
        <div className="d-flex-between">
          <div className="text-left" style={{ lineHeight: 2 }}>
            <Skeleton count={3} width={150} height={27} />
          </div>
          <div className="text-right" style={{ lineHeight: 2 }}>
            <Skeleton count={3} width={80} height={27} />
          </div>
        </div>
        <div className="mt-4">
          <Skeleton count={1} width={105} height={70} circle={true} />
          <span style={{ marginLeft: 30 }}>
            <Skeleton count={1} width={105} height={70} circle={true} />
          </span>
        </div>
        <div className="mt-2 mb-1">
          <Skeleton count={1} width={45} height={45} circle={true} />
        </div>
      </SkeletonTheme>
    </div>
  );
};
