import React from "react";
import { connect } from "react-redux";

const MedicarePlanListings = ({ t, planList, onPlanSelect, selected }) => {
  return (
    <div className={"planlist-tab-content offline"}>
      <div className="row">
        {planList &&
          planList.length > 0 &&
          planList.map((plan, index) => {
            let descriptionList = plan.descriptions
              ? plan.descriptions
              : [];
            return (
              <div className="col-md-4 mb-3">
                <div
                  className="item item-banner w-100 h-100"
                  onClick={() => onPlanSelect(plan)}
                  key={`${plan.carrier_name}-${index}`}
                >
                  <div className="plan-block h-100">
                    <label className="boxmodal-radio mb-0 h-100">
                      <input
                        type="checkbox"
                        name="comparePlansWith"
                        id="comparePlansWith"
                        value={plan.carrier_id}
                        checked={selected?.carrier_id === plan.carrier_id}
                      />
                      <div className="card-inner">
                        <h2
                          className="page-title"
                          style={{ minHeight: "105px" }}
                        >
                          <div className="page-title-logo planlist-logo">
                            <img
                              alt="Carrier Logo"
                              src={plan.carrierLogo}
                              width="32"
                              height="32"
                            />
                          </div>
                        </h2>
                        <ul style={{ minHeight: 200 }}>
                          {descriptionList &&
                            descriptionList.length > 0 &&
                            descriptionList.map((description, descIndex) => {
                              return (
                                <li
                                  className="text-left"
                                  key={`${description}-${descIndex}`}
                                >
                                  <div className="carrier-desc-check">
                                    <span className="material-icons">
                                      check
                                    </span>
                                    {description}
                                  </div>
                                </li>
                              );
                            })}
                        </ul>

                        <div className="boxmodal-radiobtn">
                          <span></span>
                        </div>
                      </div>
                    </label>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  company_information: state.underwriting.companyInformation,
});
export default connect(mapStateToProps, null)(MedicarePlanListings);
