import React, { memo, useEffect } from "react";
import {
  UnderWritingRoutes,
  ApprovalStatus,
  DashboardRoute,
  CmsPageRoutes,
  decryptme,
  checkIsInIframe,
} from "../utils/index";
import Header from "./header";
import Footer from "./footer";
import DisclaimerFooter from "./disclaimerFooter";
import AnonymousChat from "./privateChat/anonymousChat";
import { connect, useDispatch } from "react-redux";
import Index from "../screens/index";
import EmailLogin from "../screens/loginWithEmail";
import Login from "../screens/login";
import Registration from "../screens/register";
import WelCome from "../screens/underWriting/questionnaireSteps/welcome";
import GetStart from "../screens/underWriting/questionnaireSteps/getStart";
import Investment from "../screens/underWriting/questionnaireSteps/investment";
import InsureType from "../screens/underWriting/questionnaireSteps/insureType";
import PolicyTerm from "../screens/underWriting/questionnaireSteps/policyTerm";
import Frequency from "../screens/underWriting/questionnaireSteps/frequency";
import PolicyType from "../screens/underWriting/questionnaireSteps/policyType";
import YourDeposit from "../screens/underWriting/questionnaireSteps/deposit";
import YourGender from "../screens/underWriting/questionnaireSteps/gender";
import MedicareEnroll from "../screens/underWriting/questionnaireSteps/medicareEnroll";
import YourAge from "../screens/underWriting/questionnaireSteps/age";
import YourHealth from "../screens/underWriting/questionnaireSteps/health";
import SmokeHabit from "../screens/underWriting/questionnaireSteps/smokingHabit";
import Plan from "../screens/underWriting/planSuggestions/planSuggestion";
import MedicarePlanList from "../screens/underWriting/planSuggestions/medicarePlanSuggestions";
import ManualForm from "../screens/underWriting/manualForm";
import ClientForm from "../screens/underWriting/clientForm";
import FactFinder from "../screens/underWriting/factFInder/factFinder";
import FactFinderSecond from "../screens/underWriting/factFInder/factFinderSecond";
import FactFinderThird from "../screens/underWriting/factFInder/factFinderThird";
import InformationOption from "../screens/underWriting/informationOption";
import ApplicationStatus from "../screens/approvalStatus/applicationStatus";
import ApprovalStatusProcess from "../screens/approvalStatus/approvalProcess";
import ThankYou from "../screens/approvalStatus/thankYou";
import LoginBeforeAgent from "../screens/underWriting/loginAgentAssign";
import AffiliateBannerLifePage from "../screens/underWriting/affiliateBannerLifePage";
import GuestSignUp from "../screens/guestSignUp";
import Dashboard from "../screens/dashboard/index";
import MyProfile from "../screens/dashboard/myProfile";
import UnderProcessApp from "../screens/dashboard/underProcess";
import Conversations from "../screens/dashboard/conversations";
import PolicyInfo from "../screens/dashboard/policyInfo";
import PolicyDocs from "../screens/dashboard/policyDocs";
import BasicDetails from "../screens/dashboard/basicDetails";
import Notifications from "../screens/dashboard/notifications";
import AboutUs from "../screens/cms/aboutUs";
import ContactUs from "../screens/cms/contactUs";
import Faq from "../screens/cms/faq";
import ConversationDocument from "../screens/dashboard/conversationDocument";
import NotFound from "../screens/notFound";
import Homepage from "../screens/index";
import { useTranslation } from "react-i18next";
import { commonApiCall, CONSUMER_DETAILS } from "../redux/actions/index";
import { CONSUMER_PORTAL_URL } from "../config/configs";
import ZipCode from "../screens/underWriting/questionnaireSteps/zipcode";
import ProgressLayout from "./progressLayout";
import Settings from "./settings";
import SinglePageApp from "./singlePageApplication";
import Citizenship from "../screens/underWriting/questionnaireSteps/citizenship";
// import SBLIForm from "../screens/underWriting/sbli-form";
// import BeneficiaryPage from "./beneficiaryPage";
import PaymentGateway from "./payment";
import SBLILayout from "../screens/underWriting/sbli-layout";
// import SbliDocuments from "../screens/underWriting/sbli-documents";
// import SbliPayment from "../screens/underWriting/sbli-payment";
import DenialPage from "../screens/underWriting/questionnaireSteps/denialPage";
import AssurityLayout from "../screens/underWriting/assurity/assurity-layout";
// import PreQuestions from "../screens/underWriting/assurity/pre-questions";
import AssurityPreApproval from "../screens/underWriting/assurity/assurity-pre-approval";
import AssurityDenial from "../screens/underWriting/assurity/assurity-denial";
import SBLICongratulations from "../screens/underWriting/sbli-congratulations";
import AssurityPreNotice from "../screens/underWriting/assurity/assurity-pre-notice";
import AssurityWithChanges from "../screens/underWriting/assurity/approved-with-changes";
import AssurityLimitation from "../screens/underWriting/assurity/assurity-limitation";

const Layout = ({ consumer, company_information, underwriting, ...props }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    const _consumer = localStorage.getItem("clDetail");
    if (_consumer) {
      const payload = { data: JSON.parse(_consumer) };

      dispatch({ type: CONSUMER_DETAILS, payload });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getChatIcon = () => {
    if (company_information) {
      if (
        company_information?.accountType == "ambassador" &&
        !company_information?.chatIcon
      ) {
        return false;
      }
      return true;
    }
  };

  const pathName = props.match.url.split("/");

  const pageUrlPath = "/" + pathName[1];

  const showApplicationId =
    pageUrlPath === UnderWritingRoutes.GET_START ||
    pageUrlPath === UnderWritingRoutes.INVESTMENT_TYPE ||
    pageUrlPath === UnderWritingRoutes.YOUR_FREQUENCY ||
    pageUrlPath === UnderWritingRoutes.INSURE_TYPE ||
    pageUrlPath === UnderWritingRoutes.POLICY_TERM ||
    pageUrlPath === UnderWritingRoutes.POLICY_TYPE ||
    pageUrlPath === UnderWritingRoutes.YOUR_DEPOSIT ||
    pageUrlPath === UnderWritingRoutes.YOUR_GENDER ||
    pageUrlPath === UnderWritingRoutes.MEDICARE_ENROLL ||
    pageUrlPath === UnderWritingRoutes.MEDICARE_PLAN_LIST ||
    pageUrlPath === UnderWritingRoutes.YOUR_AGE ||
    pageUrlPath === UnderWritingRoutes.YOUR_HEALTH ||
    pageUrlPath === UnderWritingRoutes.SOMKING_HABIT ||
    pageUrlPath === UnderWritingRoutes.PLANS ||
    pageUrlPath === UnderWritingRoutes.FACT_FINDER ||
    pageUrlPath === UnderWritingRoutes.FACT_FINDER_2 ||
    pageUrlPath === UnderWritingRoutes.FACT_FINDER_3 ||
    pageUrlPath === UnderWritingRoutes.LOGIN_AGENT_CHAT ||
    pageUrlPath === UnderWritingRoutes.GUEST_BASIC_DETAILS ||
    pageUrlPath === UnderWritingRoutes.MANUAL_FORM ||
    pageUrlPath === UnderWritingRoutes.SBLI_PAGE ||
    pageUrlPath === UnderWritingRoutes.CLIENT_FORM ||
    pageUrlPath === UnderWritingRoutes.INFORMATION_OPTION ||
    pageUrlPath === ApprovalStatus.APPLICATION_STATUS ||
    pageUrlPath === UnderWritingRoutes.LOGIN_MANUALFORM ||
    pageUrlPath === UnderWritingRoutes.REGISTER_MANUALFORM ||
    pageUrlPath === UnderWritingRoutes.ZIPCODE ||
    pageUrlPath === UnderWritingRoutes.CITIZENSHIP ||
    pageUrlPath === ApprovalStatus.APPROVAL_PROCESS ||
    pageUrlPath === UnderWritingRoutes.ASSURITY_BENE ||
    pageUrlPath === UnderWritingRoutes.ASSURITY_LIMITATION ||
    pageUrlPath === UnderWritingRoutes.PRE_QUESTIONS ||
    pageUrlPath === UnderWritingRoutes.ASSURITY_USER_DETAIL ||
    pageUrlPath === UnderWritingRoutes.ASSURITY_PRE_APPROVAL ||
    pageUrlPath === UnderWritingRoutes.ASSURITY_PRE_DENIED ||
    pageUrlPath === UnderWritingRoutes.ASSURITY_NOTICE ||
    pageUrlPath === UnderWritingRoutes.ASSURITY_UNDERWRITING ||
    pageUrlPath === UnderWritingRoutes.ASSURITY_PAY ||
    pageUrlPath === UnderWritingRoutes.ASSURITY_DOCUMENTS ||
    pageUrlPath === UnderWritingRoutes.ASSURITY_PREVIEW ||
    pageUrlPath === UnderWritingRoutes.ASSURITY_POLICY ||
    pageUrlPath === UnderWritingRoutes.ASSURITY_POLICY_DECLINE ||
    pageUrlPath === UnderWritingRoutes.ASSURITY_WITH_CHANGES;

  let innerScreen,
    isFooter = false,
    isUnderWritingFooter = false,
    isSbliFooter = false,
    isAssurityFooter = false;

  switch (pageUrlPath) {
    case UnderWritingRoutes.LOGIN_EMAIL:
      innerScreen = <EmailLogin t={t} />;
      if (pathName[2] && pathName[2] === "myplans") isUnderWritingFooter = true;
      break;

    case UnderWritingRoutes.LOGIN_EMAIL_MANUALFORM:
      innerScreen = <EmailLogin t={t} />;
      break;

    case UnderWritingRoutes.LOGIN:
      innerScreen = <Login t={t} />;
      if (pathName[2] && pathName[2] === "myplans") isUnderWritingFooter = true;
      break;

    case UnderWritingRoutes.LOGIN_MANUALFORM:
      innerScreen = <Login t={t} />;
      break;

    case UnderWritingRoutes.REGISTER_MANUALFORM:
      innerScreen = <Registration t={t} />;
      break;

    case UnderWritingRoutes.REGISTER:
      innerScreen = <Registration t={t} />;
      if (pathName[2] && pathName[2] === "myplans") isUnderWritingFooter = true;
      break;

    case UnderWritingRoutes.UNDERWRITING_WELCOME:
      innerScreen = <WelCome t={t} />;
      break;

    case UnderWritingRoutes.SINGLE_PAGE:
      innerScreen = <SinglePageApp t={t} />;
      isUnderWritingFooter = true;
      break;

    case UnderWritingRoutes.BENEFICIARY:
      innerScreen = <SBLILayout t={t} />;
      isUnderWritingFooter = true;
      isSbliFooter = true;
      break;

    case UnderWritingRoutes.GET_START:
      innerScreen = <GetStart t={t} />;
      isUnderWritingFooter = true;
      break;

    case UnderWritingRoutes.INVESTMENT_TYPE:
      innerScreen = <Investment t={t} />;
      isUnderWritingFooter = true;
      break;

    case UnderWritingRoutes.INSURE_TYPE:
      innerScreen = <InsureType t={t} />;
      isUnderWritingFooter = true;
      break;
    case UnderWritingRoutes.POLICY_TERM:
      innerScreen = <PolicyTerm t={t} />;
      isUnderWritingFooter = true;
      break;

    case UnderWritingRoutes.YOUR_FREQUENCY:
      innerScreen = <Frequency t={t} />;
      isUnderWritingFooter = true;
      break;

    case UnderWritingRoutes.POLICY_TYPE:
      innerScreen = <PolicyType t={t} />;
      isUnderWritingFooter = true;
      break;
    case UnderWritingRoutes.ASSURITY_LIMITATION:
      innerScreen = <AssurityLimitation t={t} />;
      isUnderWritingFooter = true;
      isAssurityFooter = true;
      break;

    case UnderWritingRoutes.YOUR_DEPOSIT:
      innerScreen = <YourDeposit t={t} />;
      isUnderWritingFooter = true;
      break;

    case UnderWritingRoutes.YOUR_GENDER:
      innerScreen = <YourGender t={t} />;
      isUnderWritingFooter = true;
      break;
    case UnderWritingRoutes.MEDICARE_ENROLL:
      innerScreen = <MedicareEnroll t={t} />;
      isUnderWritingFooter = true;
      break;
    case UnderWritingRoutes.MEDICARE_PLAN_LIST:
      innerScreen = <MedicarePlanList t={t} />;
      isUnderWritingFooter = true;
      break;

    case UnderWritingRoutes.YOUR_AGE:
      innerScreen = <YourAge t={t} />;
      isUnderWritingFooter = true;
      break;

    case UnderWritingRoutes.YOUR_HEALTH:
      innerScreen = <YourHealth t={t} />;

      isUnderWritingFooter = true;
      break;

    case UnderWritingRoutes.SOMKING_HABIT:
      innerScreen = <SmokeHabit t={t} />;
      isUnderWritingFooter = true;
      break;

    case UnderWritingRoutes.CITIZENSHIP:
      innerScreen = <Citizenship t={t} />;
      isUnderWritingFooter = true;
      break;

    case UnderWritingRoutes.ZIPCODE:
      innerScreen = <ZipCode t={t} />;
      isUnderWritingFooter = true;
      break;

    case UnderWritingRoutes.PLANS:
      innerScreen = <Plan t={t} />;
      isUnderWritingFooter = true;
      break;

    case UnderWritingRoutes.LOGIN_AGENT_CHAT:
      innerScreen = <LoginBeforeAgent t={t} />;
      isUnderWritingFooter = true;
      break;
    case UnderWritingRoutes.AFFILIATE_BANNER_FLOW:
      innerScreen = <AffiliateBannerLifePage t={t} />;
      isUnderWritingFooter = true;
      break;
    case UnderWritingRoutes.GUEST_BASIC_DETAILS:
      innerScreen = <GuestSignUp t={t} />;
      isUnderWritingFooter = true;
      break;

    case UnderWritingRoutes.MANUAL_FORM:
      innerScreen = <ManualForm t={t} />;
      isUnderWritingFooter = true;
      break;
    case UnderWritingRoutes.CLIENT_FORM:
      innerScreen = <ClientForm t={t} />;
      isUnderWritingFooter = true;
      break;

    case UnderWritingRoutes.FACT_FINDER:
      innerScreen = <FactFinder t={t} />;
      isUnderWritingFooter = true;
      break;

    case UnderWritingRoutes.FACT_FINDER_2:
      innerScreen = <FactFinderSecond t={t} />;
      isUnderWritingFooter = true;
      break;

    case UnderWritingRoutes.FACT_FINDER_3:
      innerScreen = <FactFinderThird t={t} />;
      isUnderWritingFooter = true;
      break;

    case UnderWritingRoutes.INFORMATION_OPTION:
      innerScreen = <InformationOption t={t} />;
      isUnderWritingFooter = true;
      break;

    case UnderWritingRoutes.SBLI_PAGE:
      innerScreen = <SBLILayout t={t} />;
      isUnderWritingFooter = true;
      isSbliFooter = true;
      break;

    case UnderWritingRoutes.SBLI_USER_DETAIL:
      innerScreen = <SBLILayout t={t} />;
      isUnderWritingFooter = true;
      isSbliFooter = true;
      break;
    case UnderWritingRoutes.SBLI_COVERAGE:
      innerScreen = <SBLILayout t={t} />;
      isUnderWritingFooter = true;
      isSbliFooter = true;
      break;

    case UnderWritingRoutes.SBLI_THIRD_PARTY:
      innerScreen = <SBLILayout t={t} />;
      isUnderWritingFooter = true;
      isSbliFooter = true;
      break;

    case UnderWritingRoutes.SBLI_TERMS:
      innerScreen = <SBLILayout t={t} />;
      isUnderWritingFooter = true;
      isSbliFooter = true;
      break;
    case UnderWritingRoutes.SBLI_DOC:
      innerScreen = <SBLILayout t={t} />;
      isUnderWritingFooter = true;
      isSbliFooter = true;
      break;
    case UnderWritingRoutes.SBLI_DENIAL:
      innerScreen = <DenialPage t={t} />;
      isUnderWritingFooter = true;
      break;
    case UnderWritingRoutes.SBLI_PAY:
      innerScreen = <SBLILayout t={t} />;
      isUnderWritingFooter = true;
      isSbliFooter = true;

      break;
    case UnderWritingRoutes.SBLI_CONGRATULATIONS:
      innerScreen = <SBLICongratulations t={t} />;
      isUnderWritingFooter = true;
      break;
    case UnderWritingRoutes.PAYMENT:
      innerScreen = <PaymentGateway t={t} />;
      isUnderWritingFooter = true;
      break;
    case UnderWritingRoutes.ASSURITY_LAYOUT:
      innerScreen = <AssurityLayout t={t} />;
      isUnderWritingFooter = true;
      isAssurityFooter = true;

      break;
    case UnderWritingRoutes.ASSURITY_BENE:
      innerScreen = <AssurityLayout t={t} />;
      isUnderWritingFooter = true;
      isAssurityFooter = true;
      break;
    case UnderWritingRoutes.ASSURITY_WITH_CHANGES:
      innerScreen = <AssurityWithChanges t={t} />;
      isUnderWritingFooter = true;
      isAssurityFooter = true;
      break;
    case UnderWritingRoutes.ASSURITY_PREVIEW:
      innerScreen = <AssurityLayout t={t} />;
      isUnderWritingFooter = true;
      isAssurityFooter = true;
      break;
    case UnderWritingRoutes.ASSURITY_TOKEN_DOMAIN:
      innerScreen = <AssurityLayout t={t} />;
      isUnderWritingFooter = true;
      isAssurityFooter = true;
      break;

    case UnderWritingRoutes.ASSURITY_USER_DETAIL:
      innerScreen = <AssurityLayout t={t} />;
      isUnderWritingFooter = true;
      isAssurityFooter = true;
      break;
    case UnderWritingRoutes.ASSURITY_PHYSICIAN_REQUEST:
      innerScreen = <AssurityLayout t={t} />;
      isUnderWritingFooter = true;
      isAssurityFooter = true;
      break;
    case UnderWritingRoutes.ASSURITY_DOCUMENTS:
      innerScreen = <AssurityLayout t={t} />;
      isUnderWritingFooter = true;
      isAssurityFooter = true;
      break;
    case UnderWritingRoutes.ASSURITY_PRE_APPROVAL:
      innerScreen = <AssurityLayout t={t} />;
      isUnderWritingFooter = true;
      isAssurityFooter = true;
      break;
    case UnderWritingRoutes.ASSURITY_POLICY_DECLINE:
      innerScreen = <AssurityLayout t={t} />;
      isUnderWritingFooter = true;
      isAssurityFooter = true;
      break;
    case UnderWritingRoutes.ASSURITY_NOTICE:
      innerScreen = <AssurityLayout t={t} />;
      isUnderWritingFooter = true;
      isAssurityFooter = true;
      break;
    case UnderWritingRoutes.ASSURITY_PRE_DENIED:
      innerScreen = <AssurityLayout t={t} />;
      isUnderWritingFooter = true;
      isAssurityFooter = true;
      break;

    case UnderWritingRoutes.ASSURITY_UNDERWRITING:
      innerScreen = <AssurityLayout t={t} />;
      isUnderWritingFooter = true;
      isAssurityFooter = true;
      break;
    case UnderWritingRoutes.ASSURITY_PAY:
      innerScreen = <AssurityLayout t={t} />;
      isUnderWritingFooter = true;
      isAssurityFooter = true;
      break;

    case UnderWritingRoutes.PRE_QUESTIONS:
      innerScreen = <AssurityLayout t={t} />;
      isUnderWritingFooter = true;
      isAssurityFooter = true;
      break;
    case UnderWritingRoutes.ASSURITY_POLICY:
      innerScreen = <AssurityLayout t={t} />;
      isUnderWritingFooter = true;
      isAssurityFooter = true;
      break;

    case ApprovalStatus.APPLICATION_STATUS:
      innerScreen = <ApplicationStatus t={t} />;
      break;

    case ApprovalStatus.APPROVAL_PROCESS:
      innerScreen = <ApprovalStatusProcess t={t} />;
      break;

    case ApprovalStatus.THANKYOU:
      innerScreen = <ThankYou t={t} />;
      break;

    case DashboardRoute.DASHBOARD:
      innerScreen = <Dashboard t={t} />;
      break;

    case DashboardRoute.MYPROFILE:
      innerScreen = <MyProfile t={t} />;
      break;

    case DashboardRoute.UNDERPROCESS:
      innerScreen = <UnderProcessApp t={t} />;
      break;

    case DashboardRoute.BASICDETAILSDASHBOARD:
      innerScreen = <BasicDetails t={t} />;
      break;

    case DashboardRoute.PLOICYINFORMATION:
      innerScreen = <PolicyInfo t={t} />;
      break;

    case DashboardRoute.POLICYDOCUMENTS:
      innerScreen = <PolicyDocs t={t} />;
      break;

    case DashboardRoute.CONVERSATIONS:
      innerScreen = <Conversations t={t} />;
      break;

    case DashboardRoute.CONVERSATION_DOCUMENTS:
      innerScreen = <ConversationDocument t={t} />;
      break;

    case DashboardRoute.HELP:
      innerScreen = <InformationOption t={t} />;
      break;

    case DashboardRoute.NOTIFICATIONS:
      innerScreen = <Notifications t={t} />;
      break;
    case DashboardRoute.SETTING:
      innerScreen = <Settings t={t} />;
      break;

    case CmsPageRoutes.ABOUT_US:
      innerScreen = <AboutUs t={t} />;
      isFooter = true;
      break;

    case CmsPageRoutes.CONTACT_US:
      innerScreen = <ContactUs t={t} />;
      isFooter = true;
      break;

    case CmsPageRoutes.FAQ:
      innerScreen = <Faq t={t} />;
      isFooter = true;
      break;

    case CmsPageRoutes.NOTFOUND:
      innerScreen = <NotFound t={t} />;
      // isFooter = true;
      break;

    case UnderWritingRoutes.INSURENCE:
      innerScreen = <WelCome t={t} />;
      break;
    case UnderWritingRoutes.FINACIAL:
      innerScreen = <WelCome t={t} />;
      break;
    case UnderWritingRoutes.FINANCIAL:
      innerScreen = <WelCome t={t} />;
      break;
    case UnderWritingRoutes.DIVIDEND:
      innerScreen = <WelCome t={t} />;
      break;
    case UnderWritingRoutes.MEDICARE:
      innerScreen = <WelCome t={t} />;
      break;
    case UnderWritingRoutes.INDEX:
      innerScreen = <WelCome t={t} />;
      break;
    case UnderWritingRoutes.TERM:
      innerScreen = <WelCome t={t} />;
      break;
    case UnderWritingRoutes.FINAL_EXPENSE:
      innerScreen = <WelCome t={t} />;
      break;
    case UnderWritingRoutes.PERMANENT:
      innerScreen = <WelCome t={t} />;
      break;
    case UnderWritingRoutes.HOME:
      if (window.location.href !== CONSUMER_PORTAL_URL)
        innerScreen = <WelCome t={t} />;
      else {
        innerScreen = <Homepage />;
        isFooter = false;
      }
      break;

    default:
      innerScreen = <Index />;
      isFooter = true;
      break;
  }

  const getPolicyType = () => {
    if (
      (underwriting?.goalType === "INSURE" &&
        underwriting?.policyType === "PERMANENT_LIFE_INSURANCE") ||
      underwriting?.goalType === "SAVE" ||
      underwriting?.goalType === "INVEST"
    ) {
      // if (
      //   underwriting?.goalType == "INSURE" &&
      //   underwriting?.policyType == "PERMANENT_LIFE_INSURANCE"
      // ) {
      return "Permanent Policy";
    } else if (
      underwriting?.goalType === "INSURE" &&
      underwriting?.policyType === "TERM_LIFE_INSURANCE"
    ) {
      return "Term Policy";
    } else if (
      underwriting?.goalType === "INSURE" &&
      underwriting?.policyType === "FINAL_EXPENSE_INSURANCE"
    ) {
      return "Final Expense Policy";
    }
    // } else if (underwriting?.goalType == "SAVE") {
    //   return "Dividend Based Growth";
    // } else if (underwriting?.goalType == "INVEST") {
    //   return "Index Based Growth";
    // }
  };

  return (
    <React.Fragment>
      {pageUrlPath !== UnderWritingRoutes.UNDERWRITING_WELCOME && (
        <Header t={t} props={props}></Header>
      )}

      {isFooter ? (
        innerScreen
      ) : (
        <div
          className={
            pageUrlPath === UnderWritingRoutes.UNDERWRITING_WELCOME ||
            pageUrlPath === "/" ||
            pageUrlPath === "/financial" ||
            pageUrlPath === "/finacial"
              ? ""
              : company_information?.hideIlifeHeader === true &&
                checkIsInIframe() === true
              ? "page-wrapper pt-0"
              : "page-wrapper"
          }
        >
          {(pageUrlPath === UnderWritingRoutes.YOUR_FREQUENCY ||
            pageUrlPath === UnderWritingRoutes.POLICY_TERM ||
            pageUrlPath === UnderWritingRoutes.POLICY_TYPE ||
            pageUrlPath === UnderWritingRoutes.YOUR_DEPOSIT ||
            pageUrlPath === UnderWritingRoutes.YOUR_GENDER ||
            pageUrlPath === UnderWritingRoutes.MEDICARE_ENROLL ||
            pageUrlPath === UnderWritingRoutes.YOUR_AGE ||
            pageUrlPath === UnderWritingRoutes.YOUR_HEALTH ||
            pageUrlPath === UnderWritingRoutes.SOMKING_HABIT ||
            pageUrlPath === UnderWritingRoutes.CITIZENSHIP ||
            pageUrlPath === UnderWritingRoutes.MEDICARE_PLAN_LIST ||
            (pageUrlPath === UnderWritingRoutes.GUEST_BASIC_DETAILS &&
              underwriting?.goalType === "MEDICARE") ||
            pageUrlPath === UnderWritingRoutes.ZIPCODE) && (
            <div className=" text-center">
              <h3 className="mb-4 policy-type-title">{getPolicyType()}</h3>
              <ProgressLayout underWritingData={underwriting} />
            </div>
          )}

          <div
            className={
              pageUrlPath === UnderWritingRoutes.UNDERWRITING_WELCOME ||
              pageUrlPath === "/" ||
              pageUrlPath === "/financial" ||
              pageUrlPath === "/finacial" ||
              pageUrlPath === UnderWritingRoutes.ASSURITY_LAYOUT ||
              pageUrlPath === UnderWritingRoutes.PRE_QUESTIONS ||
              pageUrlPath === UnderWritingRoutes.ASSURITY_USER_DETAIL ||
              pageUrlPath === UnderWritingRoutes.ASSURITY_PHYSICIAN_REQUEST ||
              pageUrlPath === UnderWritingRoutes.ASSURITY_UNDERWRITING ||
              pageUrlPath === UnderWritingRoutes.ASSURITY_BENE ||
              pageUrlPath === UnderWritingRoutes.ASSURITY_PAY ||
              pageUrlPath === UnderWritingRoutes.ASSURITY_DOCUMENTS ||
              pageUrlPath === UnderWritingRoutes.ASSURITY_PREVIEW ||
              pageUrlPath === UnderWritingRoutes.ASSURITY_LIMITATION ||
              pageUrlPath === UnderWritingRoutes.ASSURITY_POLICY ||
              pageUrlPath === UnderWritingRoutes.ASSURITY_PRE_DENIED ||
              pageUrlPath === UnderWritingRoutes.ASSURITY_NOTICE ||
              pageUrlPath === UnderWritingRoutes.ASSURITY_PRE_APPROVAL ||
              pageUrlPath === UnderWritingRoutes.ASSURITY_POLICY_DECLINE ||
              pageUrlPath === UnderWritingRoutes.ASSURITY_WITH_CHANGES
                ? ""
                : "container"
            }
          >
            {innerScreen}
          </div>
        </div>
      )}
      {isFooter && <Footer t={t}></Footer>}
      {/* {getChatIcon() &&
        underwriting?.agentDetail &&
        isUnderWritingFooter &&
        underwriting?.agentDetail?.hideIlifeChat === false &&
        checkIsInIframe() === false && (
          <AnonymousChat t={t} isChat={false}></AnonymousChat>
        )} */}
      {((company_information &&
        company_information.disclaimer &&
        decryptme(company_information.disclaimer).length > 0) ||
        (company_information &&
          showApplicationId &&
          underwriting?.applicationId)) && (
        <DisclaimerFooter
          disclaimer={decryptme(company_information.disclaimer)}
          underwriting={underwriting}
          showApplicationId={showApplicationId}
          isSbliFooter={isSbliFooter}
          isAssurityFooter={isAssurityFooter}
          t={t}
        ></DisclaimerFooter>
      )}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  company_information: state.underwriting.companyInformation,
  underwriting: state.underwriting.underwriting,
  consumer: state.consumer.consumerdetails,
});
export default memo(connect(mapStateToProps, { commonApiCall })(Layout));
