import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import * as _ from "lodash";
import { Animated } from "react-animated-css";
import Select from "react-select";
import { Button, Popover, OverlayTrigger } from "react-bootstrap";
import { PlanFilterLoader } from "../../../components/skeletonLoaders";
import {
  NumberFormatter,
  UnderWritingRoutes,
  decryptme,
} from "../../../utils/index";

const PlanListing = ({
  isFilterLoader,
  nextStepData,
  suggestions,
  coparisionData,
  comparedPlans,
  isDividendSelected,
  accountOptions,
  account,
  handleComparePlans,
  setDividendModel,
  handleIllustration,
  getAnalytics,
  handleComparision,
  handleModel,
  nextStep,
  isProcessing,
  company_information,
  applyLoading,
  comparisonPlans,
  t,
}) => {
  const _history = useHistory();
  const { yourGoalType, yourPolicyType, yourInsureBasedOn } = nextStepData;
  const domainNamePath = window.location.hostname;
  const [numberOfitemsShown, setNumberOfItemsToShown] = useState(6);
  const domainName = domainNamePath.split(".");
  const returnPopOver = (id, content) => {
    return (
      <Popover id={id}>
        <Popover.Content className="popover-content">{content}</Popover.Content>
      </Popover>
    );
  };

  const renderDescription = (id, content) => {
    return (
      <Popover id={id}>
        <Popover.Content className="tooltip-outer">{content}</Popover.Content>
      </Popover>
    );
  };

  const chipsOuterFontSize =
    (yourGoalType &&
      yourGoalType.value === "INSURE" &&
      yourPolicyType.value === "TERM_LIFE_INSURANCE") ||
    (yourGoalType &&
      yourGoalType.value === "INSURE" &&
      yourPolicyType.value === "FINAL_EXPENSE_INSURANCE")
      ? "18px"
      : "14px";

  const showMore = () => {
    if (numberOfitemsShown + 6 <= suggestions.length) {
      setNumberOfItemsToShown(numberOfitemsShown + 6);
    } else {
      setNumberOfItemsToShown(suggestions.length);
    }
  };

  const onBackStep = () => {
    if (company_information?.prospectJourney === "multi_page") {
      if (
        yourPolicyType.value === "FINAL_EXPENSE_INSURANCE" ||
        yourPolicyType.value === "PERMANENT_LIFE_INSURANCE" ||
        yourGoalType.value !== "INSURE"
      ) {
        _history.push(UnderWritingRoutes.YOUR_DEPOSIT);
      } else {
        _history.push(UnderWritingRoutes.POLICY_TERM);
      }
    } else {
      _history.push(UnderWritingRoutes.SINGLE_PAGE);
    }
  };

  const [showOnlineCarrier, setshowOnlineCarrier] = useState("online");
  // const [onlineCarrier, setOnlineCarrier] = useState([]);
  const hideComponent = (name) => {
    setshowOnlineCarrier(name);
  };

  const [isSbli, setIsSbli] = useState(false);

  useEffect(() => {
    if (suggestions && suggestions !== undefined) {
      const checkOnline = suggestions.filter((x) => x.carrierType === "online");
      setIsSbli(checkOnline.length > 0 ? true : false);
    }
  }, [suggestions]);

  return (
    <React.Fragment>
      <div className={isSbli ? "planlist-main" : ""}>
        {!isFilterLoader && isSbli && (
          <div className="planList-tab">
            <button
              type="button"
              disabled={isFilterLoader ? true : false}
              className={
                showOnlineCarrier === "online"
                  ? "online-carriers-btn active"
                  : "online-carriers-btn"
              }
              onClick={() => hideComponent("online")}
            >
              <img
                width="20"
                height="30"
                className="mr-2"
                src={
                  showOnlineCarrier === "online"
                    ? "/assets/images/onlinewhiteImg.png"
                    : "/assets/images/onlineOrgImg.png"
                }
                alt=""
              />{" "}
              <span>Fast online decision </span>
            </button>

            <button
              type="button"
              disabled={isFilterLoader ? true : false}
              className={
                showOnlineCarrier === "offline"
                  ? "offline-carriers-btn active"
                  : "offline-carriers-btn"
              }
              onClick={() => hideComponent("offline")}
            >
              Free agent support
            </button>
          </div>
        )}
        <div
          className={
            isSbli && showOnlineCarrier === "online"
              ? "planlist-tab-content online"
              : "planlist-tab-content offline"
          }
        >
          <div className="row">
            {isFilterLoader ? (
              <React.Fragment>
                <div className="col-lg-8 col-md-6">
                  <div className="plan-loaders">
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="card-inner with-border">
                          <PlanFilterLoader />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="card-inner with-border">
                          <PlanFilterLoader />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="plan-loaders p-0">
                    <div className="card-inner with-border text-center">
                      <PlanFilterLoader />
                    </div>
                  </div>
                </div>
              </React.Fragment>
            ) : (
              <React.Fragment>
                {/* Begin: Compare Plan */}

                <div className={"col-lg-12 col-md-12 p-0"}>
                  {isSbli && (
                    <h3 className="text-center mb-lg-4 mb-3">
                      {showOnlineCarrier === "online" ? (
                        <strong>
                          {" "}
                          Apply online to quickly and easily obtain life
                          insurance coverage.
                        </strong>
                      ) : (
                        <strong>
                          {company_information?.firstName
                            ? `Select policies that interest you and get expert advice from ${decryptme(
                                company_information?.firstName
                              )}`
                            : `Select policies that interest you and get expert advice from iLife`}
                        </strong>
                      )}
                    </h3>
                  )}
                  {suggestions && suggestions !== undefined && (
                    <React.Fragment>
                      {/* {suggestions && suggestions !== undefined && })} */}
                      {numberOfitemsShown > 0 &&
                        suggestions &&
                        suggestions !== undefined &&
                        suggestions
                          .filter(
                            (x) =>
                              (x.carrierType === "offline" &&
                                showOnlineCarrier === "offline") ||
                              (x.carrierType === "online" &&
                                showOnlineCarrier === "online") ||
                              (x.carrierType === "offline" && !isSbli)
                          )
                          .slice(0, numberOfitemsShown)
                          .map((plans, index) => {
                            if (
                              (showOnlineCarrier === "offline" &&
                                plans.carrierType === "offline") ||
                              (plans.carrierType === "online" &&
                                showOnlineCarrier === "online") ||
                              (plans.carrierType === "offline" && !isSbli)
                            ) {
                              let isChecked = _.find(comparedPlans, {
                                illustration_id: plans.illustration_id,
                              });
                              let isCheckedPlan = _.find(comparisonPlans, {
                                illustration_id: plans.illustration_id,
                              });
                              return (
                                <div className="item item-banner" key={index}>
                                  <div className="plan-block">
                                    <label className="boxmodal-radio">
                                      <input
                                        type="checkbox"
                                        name="comparePlansWith"
                                        id="comparePlansWith"
                                        checked={isChecked ? true : false}
                                        value={plans.illustration_id}
                                        onChange={(e) =>
                                          handleComparePlans(e, plans, false)
                                        }
                                      />
                                      <div className="card-inner">
                                        {plans?.bannerText && (
                                          <div
                                            style={{
                                              backgroundColor:
                                                plans?.bannerBgColor,
                                            }}
                                            className="card-strip px-2 d-flex justify-content-center align-items-center"
                                          >
                                            <p className="py-1 text-white">
                                              {plans?.bannerText}
                                            </p>
                                            {plans?.bannerToolTipText && (
                                              <OverlayTrigger
                                                trigger={["hover", "focus"]}
                                                placement="bottom"
                                                overlay={returnPopOver(
                                                  "popover-basic",
                                                  plans?.bannerToolTipText
                                                )}
                                              >
                                                <svg
                                                  style={{
                                                    filter: "invert(1)",
                                                  }}
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  xmlnsXlink="http://www.w3.org/1999/xlink"
                                                  version="1.1"
                                                  width={15}
                                                  height={15}
                                                  viewBox="0 0 256 256"
                                                  xmlSpace="preserve"
                                                  className="ml-2 flex-shrink-0"
                                                >
                                                  <defs></defs>
                                                  <g
                                                    transform="translate(128 128) scale(0.72 0.72)"
                                                    style={{}}
                                                  >
                                                    <g
                                                      style={{
                                                        stroke: "none",
                                                        strokeWidth: 0,
                                                        strokeDasharray: "none",
                                                        strokeLinecap: "butt",
                                                        strokeLinejoin: "miter",
                                                        strokeMiterlimit: 10,
                                                        fill: "none",
                                                        fillRule: "nonzero",
                                                        opacity: 1,
                                                      }}
                                                      transform="translate(-175.05 -175.05000000000004) scale(3.89 3.89)"
                                                    >
                                                      <path
                                                        d="M 45 61.898 c -1.657 0 -3 -1.344 -3 -3 v -6.612 c 0 -0.825 0.34 -1.614 0.94 -2.182 l 9.553 -9.019 c 1.873 -1.946 2.903 -4.502 2.903 -7.206 v -0.357 c 0 -2.827 -1.115 -5.471 -3.141 -7.445 c -2.025 -1.974 -4.693 -3.031 -7.532 -2.947 c -5.58 0.144 -10.12 4.941 -10.12 10.694 c 0 1.657 -1.343 3 -3 3 s -3 -1.343 -3 -3 c 0 -8.978 7.162 -16.465 15.965 -16.692 c 4.467 -0.122 8.68 1.536 11.874 4.648 c 3.194 3.113 4.953 7.283 4.953 11.742 v 0.357 c 0 4.295 -1.649 8.356 -4.646 11.434 c -0.029 0.03 -0.06 0.06 -0.09 0.089 L 48 53.579 v 5.319 C 48 60.555 46.657 61.898 45 61.898 z"
                                                        style={{
                                                          stroke: "none",
                                                          strokeWidth: 1,
                                                          strokeDasharray:
                                                            "none",
                                                          strokeLinecap: "butt",
                                                          strokeLinejoin:
                                                            "miter",
                                                          strokeMiterlimit: 10,
                                                          fill: "rgb(0,0,0)",
                                                          fillRule: "nonzero",
                                                          opacity: 1,
                                                        }}
                                                        transform=" matrix(1 0 0 1 0 0) "
                                                        strokeLinecap="round"
                                                      />
                                                      <path
                                                        d="M 45 73.87 c -0.26 0 -0.52 -0.021 -0.78 -0.07 c -0.26 -0.06 -0.51 -0.13 -0.75 -0.23 c -0.24 -0.1 -0.47 -0.229 -0.69 -0.369 c -0.22 -0.15 -0.42 -0.311 -0.61 -0.5 C 41.43 71.96 41 70.93 41 69.87 c 0 -0.261 0.03 -0.521 0.08 -0.78 c 0.05 -0.25 0.13 -0.51 0.23 -0.75 s 0.22 -0.47 0.36 -0.69 c 0.15 -0.22 0.32 -0.42 0.5 -0.609 c 0.19 -0.181 0.39 -0.351 0.61 -0.49 c 0.22 -0.15 0.45 -0.27 0.69 -0.37 c 0.24 -0.1 0.49 -0.18 0.75 -0.229 c 0.51 -0.101 1.05 -0.101 1.56 0 c 0.26 0.05 0.51 0.13 0.75 0.229 c 0.239 0.101 0.47 0.22 0.689 0.37 c 0.22 0.14 0.42 0.31 0.61 0.49 c 0.18 0.189 0.35 0.39 0.5 0.609 c 0.14 0.221 0.26 0.45 0.359 0.69 c 0.101 0.24 0.181 0.5 0.23 0.75 c 0.05 0.26 0.08 0.52 0.08 0.78 c 0 1.06 -0.431 2.09 -1.17 2.83 C 47.08 73.45 46.05 73.87 45 73.87 z"
                                                        style={{
                                                          stroke: "none",
                                                          strokeWidth: 1,
                                                          strokeDasharray:
                                                            "none",
                                                          strokeLinecap: "butt",
                                                          strokeLinejoin:
                                                            "miter",
                                                          strokeMiterlimit: 10,
                                                          fill: "rgb(0,0,0)",
                                                          fillRule: "nonzero",
                                                          opacity: 1,
                                                        }}
                                                        transform=" matrix(1 0 0 1 0 0) "
                                                        strokeLinecap="round"
                                                      />
                                                      <path
                                                        d="M 45 90 C 20.187 90 0 69.813 0 45 S 20.187 0 45 0 s 45 20.187 45 45 S 69.813 90 45 90 z M 45 6 C 23.495 6 6 23.495 6 45 s 17.495 39 39 39 s 39 -17.495 39 -39 S 66.505 6 45 6 z"
                                                        style={{
                                                          stroke: "none",
                                                          strokeWidth: 1,
                                                          strokeDasharray:
                                                            "none",
                                                          strokeLinecap: "butt",
                                                          strokeLinejoin:
                                                            "miter",
                                                          strokeMiterlimit: 10,
                                                          fill: "rgb(0,0,0)",
                                                          fillRule: "nonzero",
                                                          opacity: 1,
                                                        }}
                                                        transform=" matrix(1 0 0 1 0 0) "
                                                        strokeLinecap="round"
                                                      />
                                                    </g>
                                                  </g>
                                                </svg>
                                              </OverlayTrigger>
                                            )}
                                          </div>
                                        )}
                                        <h2
                                          className="page-title"
                                          style={{ minHeight: "105px" }}
                                        >
                                          <div className="page-title-logo planlist-logo">
                                            <img
                                              src={plans.carrierLogo}
                                              alt="Carrier Logo"
                                              title={plans.carrier_name}
                                              width="32"
                                              height="32"
                                            />
                                          </div>
                                          {/* <span>
                                            {plans.carrier_name
                                              .charAt(0)
                                              .toUpperCase() +
                                              plans.carrier_name.slice(1)}
                                          </span> */}
                                        </h2>
                                        <div className="policy-outer mb-3">
                                          <div className="policy-name justify-content-center">
                                            <h3>
                                              {plans.productName.replace(
                                                "15",
                                                plans.pay
                                              )}
                                            </h3>
                                          </div>
                                          <div className="chips-outer">
                                            <span
                                              className="chips-label"
                                              style={{
                                                fontSize: chipsOuterFontSize,
                                              }}
                                            >
                                              {yourGoalType.value ===
                                                "INSURE" &&
                                              (yourPolicyType.value ===
                                                "TERM_LIFE_INSURANCE" ||
                                                (yourPolicyType.value ===
                                                  "PERMANENT_LIFE_INSURANCE" &&
                                                  yourInsureBasedOn.value ===
                                                    "COVERAGE") ||
                                                (yourGoalType.value ===
                                                  "INSURE" &&
                                                  yourPolicyType.value ===
                                                    "FINAL_EXPENSE_INSURANCE"))
                                                ? "MONTHLY"
                                                : plans.frequency}
                                            </span>
                                            <span
                                              className="chips-label"
                                              style={{
                                                fontSize: chipsOuterFontSize,
                                              }}
                                            >
                                              {yourGoalType.value ===
                                                "INSURE" &&
                                              yourPolicyType.value ===
                                                "TERM_LIFE_INSURANCE"
                                                ? NumberFormatter(
                                                    plans.monthly_deposit,
                                                    "$"
                                                  )
                                                : yourGoalType.value ===
                                                    "INSURE" &&
                                                  yourPolicyType.value ===
                                                    "FINAL_EXPENSE_INSURANCE"
                                                ? NumberFormatter(
                                                    plans.monthlyPremium,
                                                    "$"
                                                  )
                                                : (yourGoalType.value ===
                                                    "INSURE" &&
                                                    yourPolicyType.value ===
                                                      "PERMANENT_LIFE_INSURANCE" &&
                                                    yourInsureBasedOn.value ===
                                                      "COVERAGE") ||
                                                  (plans.frequency ===
                                                    "MONTHLY" &&
                                                    plans.monthlyPremium)
                                                ? NumberFormatter(
                                                    plans.monthlyPremium.toString(),
                                                    "$"
                                                  )
                                                : NumberFormatter(
                                                    plans.premium.toString(),
                                                    "$"
                                                  )}
                                            </span>
                                          </div>
                                        </div>
                                        <ul
                                          className={
                                            yourGoalType.value === "INSURE" &&
                                            yourPolicyType.value ===
                                              "FINAL_EXPENSE_INSURANCE"
                                              ? "card-value-list border-0 card-value-list-min-height"
                                              : "card-value-list border-0"
                                          }
                                        >
                                          {yourGoalType.value === "INSURE" &&
                                          yourPolicyType.value ===
                                            "FINAL_EXPENSE_INSURANCE" ? (
                                            <li className="value-list-item">
                                              <span className="list-label">
                                                {t(
                                                  "plan_listing.credit_rating"
                                                )}
                                              </span>
                                              <h3>{plans.credit_rating}</h3>
                                            </li>
                                          ) : (
                                            <li className="value-list-item">
                                              <span className="list-label">
                                                {t(
                                                  "plan_listing.credit_rating"
                                                )}
                                                <OverlayTrigger
                                                  trigger={["hover", "focus"]}
                                                  placement="right"
                                                  overlay={returnPopOver(
                                                    "popover-basic",
                                                    plans.carrier_name ===
                                                      "Assurity" ? (
                                                      <div>
                                                        {/* <strong>
                                                          A.M. Best rating and
                                                          other statements about
                                                          Assurity
                                                        </strong>
                                                        <p>
                                                          If Assurity’s A.M.
                                                          Best Rating is
                                                          provided, regardless
                                                          of form or media, it
                                                          must be provided
                                                          exactly as follows:
                                                        </p> */}
                                                        <p>
                                                          Assurity is rated A-
                                                          (Excellent). A.M. Best
                                                          ratings for insurers’
                                                          financial strength
                                                          range from A++
                                                          (Superior) to F (In
                                                          liquidation).
                                                        </p>
                                                      </div>
                                                    ) : (
                                                      "Credit ratings indicate financial strength and stability of insurance carriers. Rated by A.M. Best."
                                                    )
                                                  )}
                                                >
                                                  <span className="list-info">
                                                    <svg
                                                      width="16px"
                                                      height="16px"
                                                      viewBox="0 0 16 16"
                                                      version="1.1"
                                                    >
                                                      <g
                                                        stroke="none"
                                                        strokeWidth="1"
                                                        fill="none"
                                                        fillRule="evenodd"
                                                      >
                                                        <g
                                                          transform="translate(-4.000000, -4.000000)"
                                                          fill="#CDD7D9"
                                                        >
                                                          <path d="M11.2,16 L12.8,16 L12.8,11.2 L11.2,11.2 L11.2,16 L11.2,16 Z M12,4 C7.584,4 4,7.584 4,12 C4,16.416 7.584,20 12,20 C16.416,20 20,16.416 20,12 C20,7.584 16.416,4 12,4 L12,4 Z M12,18.4 C8.472,18.4 5.6,15.528 5.6,12 C5.6,8.472 8.472,5.6 12,5.6 C15.528,5.6 18.4,8.472 18.4,12 C18.4,15.528 15.528,18.4 12,18.4 L12,18.4 Z M11.2,9.6 L12.8,9.6 L12.8,8 L11.2,8 L11.2,9.6 L11.2,9.6 Z"></path>
                                                        </g>
                                                      </g>
                                                    </svg>
                                                  </span>
                                                </OverlayTrigger>
                                              </span>
                                              <h3>{plans.credit_rating}</h3>
                                            </li>
                                          )}

                                          {yourGoalType.value === "INSURE" &&
                                          yourPolicyType.value ===
                                            "TERM_LIFE_INSURANCE" ? (
                                            <li className="value-list-item">
                                              <span className="list-label">
                                                {t(
                                                  "plan_listing.term_coverage"
                                                )}
                                                <OverlayTrigger
                                                  trigger={["hover", "focus"]}
                                                  placement="right"
                                                  overlay={returnPopOver(
                                                    "popover-basic",
                                                    "Term Coverage"
                                                  )}
                                                >
                                                  <span className="list-info">
                                                    <svg
                                                      width="16px"
                                                      height="16px"
                                                      viewBox="0 0 16 16"
                                                      version="1.1"
                                                    >
                                                      <g
                                                        stroke="none"
                                                        strokeWidth="1"
                                                        fill="none"
                                                        fillRule="evenodd"
                                                      >
                                                        <g
                                                          transform="translate(-4.000000, -4.000000)"
                                                          fill="#CDD7D9"
                                                        >
                                                          <path d="M11.2,16 L12.8,16 L12.8,11.2 L11.2,11.2 L11.2,16 L11.2,16 Z M12,4 C7.584,4 4,7.584 4,12 C4,16.416 7.584,20 12,20 C16.416,20 20,16.416 20,12 C20,7.584 16.416,4 12,4 L12,4 Z M12,18.4 C8.472,18.4 5.6,15.528 5.6,12 C5.6,8.472 8.472,5.6 12,5.6 C15.528,5.6 18.4,8.472 18.4,12 C18.4,15.528 15.528,18.4 12,18.4 L12,18.4 Z M11.2,9.6 L12.8,9.6 L12.8,8 L11.2,8 L11.2,9.6 L11.2,9.6 Z"></path>
                                                        </g>
                                                      </g>
                                                    </svg>
                                                  </span>
                                                </OverlayTrigger>
                                              </span>
                                              <h3>
                                                {NumberFormatter(
                                                  plans.illustration_data.age10.gauranteed_death_benefit.toString(),
                                                  "$",
                                                  ""
                                                )}
                                              </h3>
                                            </li>
                                          ) : yourGoalType.value === "INSURE" &&
                                            yourPolicyType.value ===
                                              "FINAL_EXPENSE_INSURANCE" ? (
                                            <li className="value-list-item">
                                              <span className="list-label">
                                                {t(
                                                  "plan_listing.death_benefit"
                                                )}
                                              </span>
                                              <h3>
                                                {NumberFormatter(
                                                  plans.coverage.toString(),
                                                  "$",
                                                  ""
                                                )}
                                              </h3>
                                            </li>
                                          ) : (
                                            <React.Fragment>
                                              {plans.carrier_id === 3 ||
                                              plans.carrier_id === 4 ||
                                              plans.carrier_name ===
                                                "Lincoln Financial" ? (
                                                <li className="value-list-item">
                                                  <span className="list-label">
                                                    {t(
                                                      "plan_listing.product_type"
                                                    )}
                                                    {/* <OverlayTrigger
                                                    trigger={["hover", "focus"]}
                                                    placement="right"
                                                    overlay={returnPopOver(
                                                      "popover-basic",
                                                      "The average return used for performance calculations"
                                                    )}
                                                  >
                                                    <span className="list-info">
                                                      <svg
                                                        width="16px"
                                                        height="16px"
                                                        viewBox="0 0 16 16"
                                                        version="1.1"
                                                      >
                                                        <g
                                                          stroke="none"
                                                          strokeWidth="1"
                                                          fill="none"
                                                          fillRule="evenodd"
                                                        >
                                                          <g
                                                            transform="translate(-4.000000, -4.000000)"
                                                            fill="#CDD7D9"
                                                          >
                                                            <path d="M11.2,16 L12.8,16 L12.8,11.2 L11.2,11.2 L11.2,16 L11.2,16 Z M12,4 C7.584,4 4,7.584 4,12 C4,16.416 7.584,20 12,20 C16.416,20 20,16.416 20,12 C20,7.584 16.416,4 12,4 L12,4 Z M12,18.4 C8.472,18.4 5.6,15.528 5.6,12 C5.6,8.472 8.472,5.6 12,5.6 C15.528,5.6 18.4,8.472 18.4,12 C18.4,15.528 15.528,18.4 12,18.4 L12,18.4 Z M11.2,9.6 L12.8,9.6 L12.8,8 L11.2,8 L11.2,9.6 L11.2,9.6 Z"></path>
                                                          </g>
                                                        </g>
                                                      </svg>
                                                    </span>
                                                  </OverlayTrigger> */}
                                                  </span>
                                                  {/* <h4 className="text-dark mb-0"> */}

                                                  {plans.carrier_name ==
                                                  "Lincoln Financial" ? (
                                                    <h4>
                                                      {t(
                                                        "plan_listing.universal_life_gurantee"
                                                      )}
                                                    </h4>
                                                  ) : plans.carrier_name ===
                                                      "John Hancock" &&
                                                    plans.productName ==
                                                      "Protection UL" ? (
                                                    <h4>
                                                      {t(
                                                        "plan_listing.universal_life"
                                                      )}
                                                    </h4>
                                                  ) : (
                                                    <h4>
                                                      {t(
                                                        "plan_listing.indexed_universal_life"
                                                      )}
                                                    </h4>
                                                  )}
                                                </li>
                                              ) : (
                                                <li className="value-list-item">
                                                  <span className="list-label">
                                                    {t(
                                                      "plan_listing.minimum_interest_rate"
                                                    )}
                                                    <OverlayTrigger
                                                      trigger={[
                                                        "hover",
                                                        "focus",
                                                      ]}
                                                      placement="right"
                                                      overlay={returnPopOver(
                                                        "popover-basic",
                                                        "The minimum interest rate insurance companies are legally guaranteed to apply to your cash value each year"
                                                      )}
                                                    >
                                                      <span className="list-info">
                                                        <svg
                                                          width="16px"
                                                          height="16px"
                                                          viewBox="0 0 16 16"
                                                          version="1.1"
                                                        >
                                                          <g
                                                            stroke="none"
                                                            strokeWidth="1"
                                                            fill="none"
                                                            fillRule="evenodd"
                                                          >
                                                            <g
                                                              transform="translate(-4.000000, -4.000000)"
                                                              fill="#CDD7D9"
                                                            >
                                                              <path d="M11.2,16 L12.8,16 L12.8,11.2 L11.2,11.2 L11.2,16 L11.2,16 Z M12,4 C7.584,4 4,7.584 4,12 C4,16.416 7.584,20 12,20 C16.416,20 20,16.416 20,12 C20,7.584 16.416,4 12,4 L12,4 Z M12,18.4 C8.472,18.4 5.6,15.528 5.6,12 C5.6,8.472 8.472,5.6 12,5.6 C15.528,5.6 18.4,8.472 18.4,12 C18.4,15.528 15.528,18.4 12,18.4 L12,18.4 Z M11.2,9.6 L12.8,9.6 L12.8,8 L11.2,8 L11.2,9.6 L11.2,9.6 Z"></path>
                                                            </g>
                                                          </g>
                                                        </svg>
                                                      </span>
                                                    </OverlayTrigger>
                                                  </span>
                                                  <h3>
                                                    {NumberFormatter(
                                                      plans.min_interest_rate.toString(),
                                                      "",
                                                      "%"
                                                    )}
                                                  </h3>
                                                </li>
                                              )}
                                            </React.Fragment>
                                          )}
                                          {yourGoalType.value === "INSURE" &&
                                          yourPolicyType.value ===
                                            "TERM_LIFE_INSURANCE" ? (
                                            <li className="value-list-item">
                                              <span className="list-label">
                                                {t("plan_listing.term_length")}
                                                <OverlayTrigger
                                                  trigger={["hover", "focus"]}
                                                  placement="right"
                                                  overlay={returnPopOver(
                                                    "popover-basic",
                                                    "Term Length"
                                                  )}
                                                >
                                                  <span className="list-info">
                                                    <svg
                                                      width="16px"
                                                      height="16px"
                                                      viewBox="0 0 16 16"
                                                      version="1.1"
                                                    >
                                                      <g
                                                        stroke="none"
                                                        strokeWidth="1"
                                                        fill="none"
                                                        fillRule="evenodd"
                                                      >
                                                        <g
                                                          transform="translate(-4.000000, -4.000000)"
                                                          fill="#CDD7D9"
                                                        >
                                                          <path d="M11.2,16 L12.8,16 L12.8,11.2 L11.2,11.2 L11.2,16 L11.2,16 Z M12,4 C7.584,4 4,7.584 4,12 C4,16.416 7.584,20 12,20 C16.416,20 20,16.416 20,12 C20,7.584 16.416,4 12,4 L12,4 Z M12,18.4 C8.472,18.4 5.6,15.528 5.6,12 C5.6,8.472 8.472,5.6 12,5.6 C15.528,5.6 18.4,8.472 18.4,12 C18.4,15.528 15.528,18.4 12,18.4 L12,18.4 Z M11.2,9.6 L12.8,9.6 L12.8,8 L11.2,8 L11.2,9.6 L11.2,9.6 Z"></path>
                                                        </g>
                                                      </g>
                                                    </svg>
                                                  </span>
                                                </OverlayTrigger>
                                              </span>
                                              <h3>
                                                {plans.pay
                                                  ? plans.pay + " Years"
                                                  : "N/A"}
                                              </h3>
                                            </li>
                                          ) : yourGoalType.value === "INSURE" &&
                                            yourPolicyType.value ===
                                              "FINAL_EXPENSE_INSURANCE" ? (
                                            <React.Fragment>
                                              <li className="value-list-item">
                                                <span className="list-label">
                                                  {t(
                                                    "plan_listing.annual_premium"
                                                  )}
                                                </span>
                                                <h3>
                                                  {NumberFormatter(
                                                    plans.premium.toString(),
                                                    "$",
                                                    ""
                                                  )}
                                                </h3>
                                              </li>
                                              <li className="value-list-item">
                                                <span className="list-label">
                                                  {t(
                                                    "plan_listing.premium_contribution"
                                                  )}
                                                </span>
                                                <h3>
                                                  {plans.contributionPeriod !==
                                                  null
                                                    ? plans.contributionPeriod
                                                    : "N/A"}
                                                </h3>
                                              </li>
                                            </React.Fragment>
                                          ) : (
                                            <React.Fragment>
                                              {plans.carrier_id === 3 ||
                                              (plans.carrier_name ===
                                                "John Hancock" &&
                                                plans.productName !=
                                                  "Protection UL") ? (
                                                <li className="value-list-item">
                                                  <span className="list-label">
                                                    {t(
                                                      "plan_listing.crediting_rate_history"
                                                    )}
                                                    <OverlayTrigger
                                                      trigger={[
                                                        "hover",
                                                        "focus",
                                                      ]}
                                                      placement="right"
                                                      overlay={returnPopOver(
                                                        "popover-basic",
                                                        "The average return used for performance calculations"
                                                      )}
                                                    >
                                                      <span className="list-info">
                                                        <svg
                                                          width="16px"
                                                          height="16px"
                                                          viewBox="0 0 16 16"
                                                          version="1.1"
                                                        >
                                                          <g
                                                            stroke="none"
                                                            strokeWidth="1"
                                                            fill="none"
                                                            fillRule="evenodd"
                                                          >
                                                            <g
                                                              transform="translate(-4.000000, -4.000000)"
                                                              fill="#CDD7D9"
                                                            >
                                                              <path d="M11.2,16 L12.8,16 L12.8,11.2 L11.2,11.2 L11.2,16 L11.2,16 Z M12,4 C7.584,4 4,7.584 4,12 C4,16.416 7.584,20 12,20 C16.416,20 20,16.416 20,12 C20,7.584 16.416,4 12,4 L12,4 Z M12,18.4 C8.472,18.4 5.6,15.528 5.6,12 C5.6,8.472 8.472,5.6 12,5.6 C15.528,5.6 18.4,8.472 18.4,12 C18.4,15.528 15.528,18.4 12,18.4 L12,18.4 Z M11.2,9.6 L12.8,9.6 L12.8,8 L11.2,8 L11.2,9.6 L11.2,9.6 Z"></path>
                                                            </g>
                                                          </g>
                                                        </svg>
                                                      </span>
                                                    </OverlayTrigger>
                                                  </span>
                                                  {/* <h3>-</h3> */}
                                                  <button
                                                    className="btn btn-link"
                                                    onClick={() =>
                                                      setDividendModel(
                                                        plans.carrier_id,
                                                        plans.carrier_name,
                                                        isDividendSelected
                                                      )
                                                    }
                                                  >
                                                    <span>View </span>
                                                    <span className="material-icons">
                                                      bar_chart
                                                    </span>
                                                  </button>
                                                </li>
                                              ) : yourGoalType.value ===
                                                  "INSURE" &&
                                                yourPolicyType.value ===
                                                  "FINAL_EXPENSE_INSURANCE" ? (
                                                " "
                                              ) : (
                                                <li className="value-list-item">
                                                  <span className="list-label">
                                                    {plans.carrier_name ==
                                                    "Lincoln Financial" ? (
                                                      <>
                                                        {t(
                                                          "plan_listing.guaranteed_return"
                                                        )}
                                                      </>
                                                    ) : plans.carrier_name ===
                                                        "John Hancock" &&
                                                      plans.productName ==
                                                        "Protection UL" ? (
                                                      <>
                                                        {t(
                                                          "plan_listing.minimum_interest_rate"
                                                        )}
                                                      </>
                                                    ) : (
                                                      <>
                                                        {t(
                                                          "plan_listing.cash_value_dividend"
                                                        )}
                                                        <OverlayTrigger
                                                          trigger={[
                                                            "hover",
                                                            "focus",
                                                          ]}
                                                          placement="right"
                                                          overlay={returnPopOver(
                                                            "popover-basic",
                                                            "Cash value dividends are the portion of the insurance company's profits that are paid to policyholders"
                                                          )}
                                                        >
                                                          <span className="list-info">
                                                            <svg
                                                              width="16px"
                                                              height="16px"
                                                              viewBox="0 0 16 16"
                                                              version="1.1"
                                                            >
                                                              <g
                                                                stroke="none"
                                                                strokeWidth="1"
                                                                fill="none"
                                                                fillRule="evenodd"
                                                              >
                                                                <g
                                                                  transform="translate(-4.000000, -4.000000)"
                                                                  fill="#CDD7D9"
                                                                >
                                                                  <path d="M11.2,16 L12.8,16 L12.8,11.2 L11.2,11.2 L11.2,16 L11.2,16 Z M12,4 C7.584,4 4,7.584 4,12 C4,16.416 7.584,20 12,20 C16.416,20 20,16.416 20,12 C20,7.584 16.416,4 12,4 L12,4 Z M12,18.4 C8.472,18.4 5.6,15.528 5.6,12 C5.6,8.472 8.472,5.6 12,5.6 C15.528,5.6 18.4,8.472 18.4,12 C18.4,15.528 15.528,18.4 12,18.4 L12,18.4 Z M11.2,9.6 L12.8,9.6 L12.8,8 L11.2,8 L11.2,9.6 L11.2,9.6 Z"></path>
                                                                </g>
                                                              </g>
                                                            </svg>
                                                          </span>
                                                        </OverlayTrigger>
                                                      </>
                                                    )}
                                                  </span>
                                                  {plans.carrier_name ==
                                                    "Lincoln Financial" ||
                                                  (plans.carrier_name ===
                                                    "John Hancock" &&
                                                    plans.productName ==
                                                      "Protection UL") ? (
                                                    <div
                                                      style={{ color: "#000" }}
                                                    >
                                                      2%
                                                    </div>
                                                  ) : (
                                                    <button
                                                      className="btn btn-link"
                                                      onClick={() =>
                                                        setDividendModel(
                                                          plans.carrier_id,
                                                          plans.carrier_name,
                                                          isDividendSelected
                                                        )
                                                      }
                                                    >
                                                      <span>View </span>
                                                      <span className="material-icons">
                                                        bar_chart
                                                      </span>
                                                    </button>
                                                  )}
                                                </li>
                                              )}
                                            </React.Fragment>
                                          )}
                                          {yourGoalType.value === "INSURE" &&
                                            yourPolicyType.value ===
                                              "FINAL_EXPENSE_INSURANCE" &&
                                            plans.descriptions !== "" && (
                                              <li className="value-list-item">
                                                <span className="list-label">
                                                  {plans.descriptions}
                                                  <OverlayTrigger
                                                    placement="bottom-start"
                                                    trigger={["hover", "focus"]}
                                                    overlay={renderDescription(
                                                      "popover-basic",
                                                      plans.disclaimer
                                                    )}
                                                  >
                                                    <sup className="material-icons">
                                                      help_outline
                                                    </sup>
                                                  </OverlayTrigger>
                                                </span>
                                              </li>
                                            )}
                                        </ul>

                                        {/* <p className="card-text">Historically performs 4% above S&P</p> */}
                                        {(yourGoalType.value === "INSURE" &&
                                          yourPolicyType.value ===
                                            "TERM_LIFE_INSURANCE") ||
                                        (yourGoalType.value === "INSURE" &&
                                          yourPolicyType.value ===
                                            "FINAL_EXPENSE_INSURANCE") ? (
                                          ""
                                        ) : (
                                          <div className="plan-modals-wrapper ">
                                            <Button
                                              variant="light"
                                              onClick={() =>
                                                handleIllustration(
                                                  plans.illustration_id
                                                )
                                              }
                                              disabled={
                                                (yourGoalType.value ===
                                                  "INSURE" &&
                                                  yourPolicyType.value ===
                                                    "TERM_LIFE_INSURANCE") ||
                                                (yourGoalType.value ===
                                                  "INSURE" &&
                                                  yourPolicyType.value ===
                                                    "FINAL_EXPENSE_INSURANCE")
                                                  ? true
                                                  : false
                                              }
                                            >
                                              {t(
                                                "plan_listing.view_illustration"
                                              )}
                                            </Button>
                                            <Button
                                              variant="light"
                                              onClick={() =>
                                                getAnalytics(
                                                  plans.carrier_id,
                                                  plans.illustration_id,
                                                  plans.carrier_name,
                                                  plans.productName
                                                )
                                              }
                                              disabled={
                                                (yourGoalType.value ===
                                                  "INSURE" &&
                                                  yourPolicyType.value ===
                                                    "TERM_LIFE_INSURANCE") ||
                                                (yourGoalType.value ===
                                                  "INSURE" &&
                                                  yourPolicyType.value ===
                                                    "FINAL_EXPENSE_INSURANCE") ||
                                                plans.carrier_name ==
                                                  "Lincoln Financial" ||
                                                (plans.carrier_name ===
                                                  "John Hancock" &&
                                                  plans.productName ==
                                                    "Protection UL")
                                                  ? true
                                                  : false
                                              }
                                            >
                                              {t(
                                                "plan_listing.advanced_analytics"
                                              )}
                                            </Button>
                                          </div>
                                        )}

                                        <div className="boxmodal-radiobtn">
                                          <span></span>
                                        </div>
                                        {(yourGoalType.value === "INSURE" &&
                                          yourPolicyType.value ===
                                            "TERM_LIFE_INSURANCE") ||
                                        (yourGoalType.value === "INSURE" &&
                                          yourPolicyType.value ===
                                            "FINAL_EXPENSE_INSURANCE") ? (
                                          ""
                                        ) : (
                                          <div className="form-group d-flex justify-content-center">
                                            <label
                                              style={{
                                                order: 1,
                                                cursor: "pointer",
                                              }}
                                              className="mb-0 ml-2"
                                              htmlFor={
                                                "checkComparePlan" + index
                                              }
                                            >
                                              Compare
                                            </label>{" "}
                                            <input
                                              type="checkbox"
                                              name="comparePlansWith"
                                              style={{
                                                display: "inline-block",
                                              }}
                                              id={"checkComparePlan" + index}
                                              checked={
                                                isCheckedPlan ? true : false
                                              }
                                              value={plans.illustration_id}
                                              onChange={(e) =>
                                                handleComparePlans(
                                                  e,
                                                  plans,
                                                  true
                                                )
                                              }
                                            />
                                          </div>
                                        )}
                                      </div>
                                    </label>
                                  </div>
                                </div>
                              );
                            }
                          })}
                    </React.Fragment>
                  )}
                  {numberOfitemsShown < suggestions?.length && !isSbli && (
                    <div className="show-more mb-3" onClick={showMore}>
                      Show more options
                    </div>
                  )}
                </div>
                {(yourGoalType &&
                  yourGoalType.value === "INSURE" &&
                  yourPolicyType.value === "TERM_LIFE_INSURANCE") ||
                (yourGoalType &&
                  yourGoalType.value === "INSURE" &&
                  yourPolicyType.value === "FINAL_EXPENSE_INSURANCE") ? (
                  ""
                ) : accountOptions.length > 0 ? (
                  <div className="col-12">
                    <Animated
                      animationIn="fadeInRight"
                      animationOut="fadeOutLeft"
                      animationInDuration={800}
                      animationOutDuration={800}
                      isVisible={true}
                    >
                      <div className="plan-block comparison-block">
                        <div className="card-inner">
                          <span className="card-title-fixed">
                            {t("plan_listing.comparison_with")}
                          </span>
                          <div className="compare-bank-acc">
                            <div className="default-select no-shadow">
                              <Select
                                value={account}
                                onChange={(e) =>
                                  handleComparision(e, "compareWithBox")
                                }
                                options={accountOptions}
                                className={"custom-select-menu"}
                                classNamePrefix={"custom-select"}
                                isSearchable={false}
                                // menuIsOpen={true}
                              />
                            </div>
                          </div>
                          <ul className="card-value-list">
                            <li className="value-list-item">
                              <span className="list-label">
                                {coparisionData.creaditRating.key}
                                <OverlayTrigger
                                  trigger={["hover", "focus"]}
                                  placement="auto"
                                  overlay={returnPopOver(
                                    "popover-basic2",
                                    coparisionData.creaditRating.toolTip
                                  )}
                                >
                                  <span className="list-info">
                                    <svg
                                      width="16px"
                                      height="16px"
                                      viewBox="0 0 16 16"
                                      version="1.1"
                                    >
                                      <g
                                        stroke="none"
                                        strokeWidth="1"
                                        fill="none"
                                        fillRule="evenodd"
                                      >
                                        <g
                                          transform="translate(-4.000000, -4.000000)"
                                          fill="#CDD7D9"
                                        >
                                          <path d="M11.2,16 L12.8,16 L12.8,11.2 L11.2,11.2 L11.2,16 L11.2,16 Z M12,4 C7.584,4 4,7.584 4,12 C4,16.416 7.584,20 12,20 C16.416,20 20,16.416 20,12 C20,7.584 16.416,4 12,4 L12,4 Z M12,18.4 C8.472,18.4 5.6,15.528 5.6,12 C5.6,8.472 8.472,5.6 12,5.6 C15.528,5.6 18.4,8.472 18.4,12 C18.4,15.528 15.528,18.4 12,18.4 L12,18.4 Z M11.2,9.6 L12.8,9.6 L12.8,8 L11.2,8 L11.2,9.6 L11.2,9.6 Z"></path>
                                        </g>
                                      </g>
                                    </svg>
                                  </span>
                                </OverlayTrigger>
                              </span>
                              <h3>
                                {coparisionData.creaditRating.value
                                  ? coparisionData.creaditRating.value
                                  : "N/A"}
                              </h3>
                            </li>
                            <li className="value-list-item">
                              <span className="list-label">
                                {coparisionData.currentYield.key}
                                <OverlayTrigger
                                  trigger={["hover", "focus"]}
                                  placement="auto"
                                  overlay={returnPopOver(
                                    "popover-basic2",
                                    coparisionData.currentYield.toolTip
                                  )}
                                >
                                  <span className="list-info">
                                    <svg
                                      width="16px"
                                      height="16px"
                                      viewBox="0 0 16 16"
                                      version="1.1"
                                    >
                                      <g
                                        stroke="none"
                                        strokeWidth="1"
                                        fill="none"
                                        fillRule="evenodd"
                                      >
                                        <g
                                          transform="translate(-4.000000, -4.000000)"
                                          fill="#CDD7D9"
                                        >
                                          <path d="M11.2,16 L12.8,16 L12.8,11.2 L11.2,11.2 L11.2,16 L11.2,16 Z M12,4 C7.584,4 4,7.584 4,12 C4,16.416 7.584,20 12,20 C16.416,20 20,16.416 20,12 C20,7.584 16.416,4 12,4 L12,4 Z M12,18.4 C8.472,18.4 5.6,15.528 5.6,12 C5.6,8.472 8.472,5.6 12,5.6 C15.528,5.6 18.4,8.472 18.4,12 C18.4,15.528 15.528,18.4 12,18.4 L12,18.4 Z M11.2,9.6 L12.8,9.6 L12.8,8 L11.2,8 L11.2,9.6 L11.2,9.6 Z"></path>
                                        </g>
                                      </g>
                                    </svg>
                                  </span>
                                </OverlayTrigger>
                              </span>
                              <h3>
                                {coparisionData.currentYield.value
                                  ? coparisionData.currentYield.value
                                  : "N/A"}
                              </h3>
                            </li>
                            <li className="value-list-item">
                              <span className="list-label">
                                {coparisionData.afterTaxReturns.key}
                                <OverlayTrigger
                                  trigger={["hover", "focus"]}
                                  placement="auto"
                                  overlay={returnPopOver(
                                    "popover-basic2",
                                    coparisionData.afterTaxReturns.toolTip
                                  )}
                                >
                                  <span className="list-info">
                                    <svg
                                      width="16px"
                                      height="16px"
                                      viewBox="0 0 16 16"
                                      version="1.1"
                                    >
                                      <g
                                        stroke="none"
                                        strokeWidth="1"
                                        fill="none"
                                        fillRule="evenodd"
                                      >
                                        <g
                                          transform="translate(-4.000000, -4.000000)"
                                          fill="#CDD7D9"
                                        >
                                          <path d="M11.2,16 L12.8,16 L12.8,11.2 L11.2,11.2 L11.2,16 L11.2,16 Z M12,4 C7.584,4 4,7.584 4,12 C4,16.416 7.584,20 12,20 C16.416,20 20,16.416 20,12 C20,7.584 16.416,4 12,4 L12,4 Z M12,18.4 C8.472,18.4 5.6,15.528 5.6,12 C5.6,8.472 8.472,5.6 12,5.6 C15.528,5.6 18.4,8.472 18.4,12 C18.4,15.528 15.528,18.4 12,18.4 L12,18.4 Z M11.2,9.6 L12.8,9.6 L12.8,8 L11.2,8 L11.2,9.6 L11.2,9.6 Z"></path>
                                        </g>
                                      </g>
                                    </svg>
                                  </span>
                                </OverlayTrigger>
                              </span>
                              <h3>
                                {coparisionData.afterTaxReturns.value
                                  ? coparisionData.afterTaxReturns.value
                                  : "N/A"}
                              </h3>
                            </li>
                            <li className="value-list-item">
                              <span className="list-label">
                                {coparisionData.minimumInterestRate.key}
                                <OverlayTrigger
                                  trigger={["hover", "focus"]}
                                  placement="auto"
                                  overlay={returnPopOver(
                                    "popover-basic2",
                                    coparisionData.minimumInterestRate.toolTip
                                  )}
                                >
                                  <span className="list-info">
                                    <svg
                                      width="16px"
                                      height="16px"
                                      viewBox="0 0 16 16"
                                      version="1.1"
                                    >
                                      <g
                                        stroke="none"
                                        strokeWidth="1"
                                        fill="none"
                                        fillRule="evenodd"
                                      >
                                        <g
                                          transform="translate(-4.000000, -4.000000)"
                                          fill="#CDD7D9"
                                        >
                                          <path d="M11.2,16 L12.8,16 L12.8,11.2 L11.2,11.2 L11.2,16 L11.2,16 Z M12,4 C7.584,4 4,7.584 4,12 C4,16.416 7.584,20 12,20 C16.416,20 20,16.416 20,12 C20,7.584 16.416,4 12,4 L12,4 Z M12,18.4 C8.472,18.4 5.6,15.528 5.6,12 C5.6,8.472 8.472,5.6 12,5.6 C15.528,5.6 18.4,8.472 18.4,12 C18.4,15.528 15.528,18.4 12,18.4 L12,18.4 Z M11.2,9.6 L12.8,9.6 L12.8,8 L11.2,8 L11.2,9.6 L11.2,9.6 Z"></path>
                                        </g>
                                      </g>
                                    </svg>
                                  </span>
                                </OverlayTrigger>
                              </span>
                              <h3>
                                {coparisionData.minimumInterestRate.value
                                  ? NumberFormatter(
                                      coparisionData.minimumInterestRate.value.toString(),
                                      "",
                                      "%"
                                    )
                                  : "N/A"}
                              </h3>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </Animated>
                  </div>
                ) : (
                  ""
                )}
                {/* End: Suggested Plan */}
              </React.Fragment>
            )}
            <div className="col-12 text-center mt-3">
              <div className="plan-btn-block">
                {domainName.includes("whiteswan") ||
                domainName.includes("surevested") ? null : (
                  <button
                    className="btn btn-primary-outline"
                    onClick={() => onBackStep()}
                    disabled={applyLoading}
                  >
                    {" "}
                    {/* Check for approval{" "} */}
                    {t("back")}{" "}
                  </button>
                )}
                {(yourGoalType &&
                  yourPolicyType &&
                  yourGoalType.value === "INSURE" &&
                  yourPolicyType.value === "TERM_LIFE_INSURANCE") ||
                (yourPolicyType &&
                  yourGoalType.value === "INSURE" &&
                  yourPolicyType.value === "FINAL_EXPENSE_INSURANCE") ? (
                  ""
                ) : (
                  <button
                    className="btn btn-primary-outline"
                    disabled={comparisonPlans.length === 2 ? false : true}
                    onClick={() => handleModel("comparePlansModal", true)}
                  >
                    {t("plan_listing.compare_plans")}
                  </button>
                )}

                <button
                  className="btn btn-primary"
                  disabled={comparedPlans.length < 1 ? true : false}
                  onClick={() => nextStep()}
                >
                  {isProcessing
                    ? "Processing"
                    : comparedPlans.length === 0 ||
                      comparedPlans[0].carrierType === "online"
                    ? "Next"
                    : t("apply_now")}
                </button>
              </div>
            </div>
            {/* {!domainName.includes("whiteswan") && (
        <div className="col-12 text-center mt-3">
          <Link
            to={UnderWritingRoutes.SOMKING_HABIT}
            className="btn btn-link"
            title="Back"
          >
            {t("back")}
          </Link>
        </div>
      )} */}
          </div>
        </div>
      </div>
      {numberOfitemsShown < suggestions?.length &&
      showOnlineCarrier === "offline" &&
      isSbli ? (
        <div className="show-more mb-3 pt-lg-5 mt-lg-5 mt-5" onClick={showMore}>
          Show more options
        </div>
      ) : null}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  company_information: state.underwriting.companyInformation,
});
export default connect(mapStateToProps, null)(PlanListing);
