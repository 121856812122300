export const CONSUMER_DETAILS = "consumer_details";
export const CONSUMER_LOGOUT = "consumer_logout";
export const SESSION_LOGOUT = "session_logout";
export const UNDERWRITING_DETAILS = "underwriting_details";
export const COMPANY_INFORMATION = "company_information";
export const EMPTY_UNDERWRITING_DETAILS = "empty_underwriting_details";
export const ADD_UNDERWRITING_APP = "add_underwriting_app";
export const LANGUAGE = "language";
export const CHANGE_PASSWORD = " change_password";
export const HOMEPAGE_DETAILS = " home_page_details";
export const EDIT_DETAILS = "edit_details";
export const ADMIN_LOGIN = "admin_login";
export const EDITADMIN_PROFILE = "editadmin_profile";
export const LOG_OUT = "log_out";
export const FORGOT_PASSWORD = "forgot_password";
export const PROFILE_PIC = "profile_pic";
export const DIRECT_LINK = "direct_link";
export const AGE = "age";
export const QUESTIONARIES = "questionaries";
export const ASSIGNED_AGENT = "assignedAggent";
export const FACT_FINDER_QUESTION = "factFinderQuestion";
export const APPLICATION_CARRIERS_DETAILS = "application_carriers_details";
export const SBLI_PROGRESS_BAR = "sbli_progress_bar";
export const SBLI_PROGRESS_COUNT = "sbli_progress_count";
export const ASSURITY_PROGRESS_COUNT = "assurity_progress_count";
export const SBLI_ARC_ID = "sbli_arc_id";
