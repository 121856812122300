import React, { useEffect, useState } from "react";

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  commonApiCall,
  getJwt,
  setSbliProgressCount,
} from "../../redux/actions";
import { API_URL_UNDERWRITING } from "../../config/configs";
import { decryptme, showErrorToast, UnderWritingRoutes } from "../../utils";

const SBLIPayment = ({
  underwriting,
  commonApiCall,
  setSbliProgressCount,
  progressCount,
  ...props
}) => {
  const [policyLoading, setPolicyLoading] = useState(false);
  const [policyData, setPolicyData] = useState(null);

  useEffect(() => {
    getPolicy(false);
  }, []);

  const loadPaymentUrl = (url, callback) => {
    const existingScript = document.getElementById("oneInkPaymentScript");
    if (!existingScript) {
      const script = document.createElement("script");
      script.defer = true;
      script.async = true;
      script.src = url;
      script.id = "oneInkPaymentScript";
      document.body.appendChild(script);
      script.onload = () => {
        if (callback) callback();
      };
    }
    if (existingScript && callback) callback();
  };

  const getPolicy = async (expired) => {
    setPolicyLoading(true);
    try {
      let response = await commonApiCall(
        `${API_URL_UNDERWRITING}getPOLICYNUMBER?applicationId=${underwriting?.applicationId}` +
          (expired ? "&isExpired=true" : ""),
        "get",
        {},
        ""
      );
      if (response.status.code === 200) {
        if (expired) {
          window.location.reload();
        } else {
          setPolicyData(response.data.policyNumber);
          loadPaymentUrl(response.data.sbliPaymentUrl, () => {
            handleSubmit(response.data.policyNumber);
          });
        }
      } else {
        showErrorToast(response.status.message);
      }
    } catch (err) {
      console.error(err);
      showErrorToast(err.toJSON().message);
    }
    setPolicyLoading(false);
  };

  const initEventHandlers = (container) => {
    container.off();

    container.on("portalOne.load", function () {
      console.log("portalOne.load event raised");
    });

    container.on("portalOne.paymentComplete", function (e, data) {
      onPaymentComplete(data);
    });

    container.on("portalOne.closed", function () {
      console.log("close container");
    });

    container.on("portalOne.error", function (e, data) {
      getPolicy(true);
    });
  };

  const onPaymentComplete = async (data) => {
    try {
      data.token = getJwt();
      data.applicationId = underwriting?.applicationId;
      const isAuthorized = getJwt() ? true : false;
      const response = await commonApiCall(
        API_URL_UNDERWRITING + "submitPaymentCC",
        "post",
        data,
        "",
        isAuthorized
      );
      if (response.status.code === 200) {
        setSbliProgressCount(progressCount + 1);
        setTimeout(() => {
          props.history.push(UnderWritingRoutes.SBLI_DOC);
        }, 2000);
      } else {
        showErrorToast(response.status.message);
      }
    } catch (err) {
      showErrorToast(err);
    }
  };

  const handleSubmit = (data) => {
    let container = window.$("#portalOneContainer");
    container.portalOne();
    initEventHandlers(container);
    let dialog = container.data("portalOne");
    //This information would come from carrier's system
    dialog.makePayment({
      paymentCategory: "CreditCard",
      feeContext: "PaymentWithNoFee",
      convenienceFeeType: "Renewal",
      amountContext: "AmountDueOnly",
      minAmountDue: data.offeredPremium,

      //   accountBalance: "120.00",
      // billingZip: "95630",
      // billingAddressStreet: "602 Coolidge Dr., Folsom, CA",
      policyHolderName:
        decryptme(underwriting?.firstName) +
        " " +
        decryptme(underwriting?.lastName),
      clientReferenceData1: data.policyNumber,
      confirmationDisplay: "true",
      saveOption: "Save",
      accountGroupCode: "FallsLake",
      acknowledgmentRequired: "true",
      customerId: data.clientNumber,
      sessionId: data.sessionId,
    });
  };

  return (
    <div className="sbli-payment-container">
      <div className="container">
        {policyLoading ? (
          <div className="payment-loading">
            <h3>Please wait...</h3>
            <p>We're processing your data</p>
          </div>
        ) : (
          <p>
            {" "}
            If you don’t see a payment popup, please refresh the page or
            <a href={window.location.href}> click here</a>{" "}
          </p>
        )}
        <div id="portalOneContainer"></div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  underwriting: state.underwriting.underwriting,
  company_information: state.underwriting.companyInformation,
  direct_link: state.underwriting.direct_link,
  progressCount: state.underwriting.progressCount,
});

export default connect(mapStateToProps, {
  commonApiCall,
  setSbliProgressCount,
})(withRouter(SBLIPayment));
