import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { Animated } from "react-animated-css";
import NumberFormat from "react-number-format";
import { isEmpty } from "lodash";
import {
  decryptme,
  // UnderWritingRoutes,
  encryptme,
  NumberFormatter,
  showErrorToast,
  setProcessStep,
  getCookieValue,
} from "../../utils/index";
import PrivateChat from "../../components/privateChat/index";
import { ApplicationStatusLoader } from "../../components/skeletonLoaders/";
import {
  commonApiCall,
  commonApiCallHeader,
  getJwt,
  UNDERWRITING_DETAILS,
} from "../../redux/actions/index";
import { API_URL_CLIENT, API_URL_UNDERWRITING } from "../../config/configs";
import { IllustrationModel } from "../../components/underWritingModels";
import PaymentPage from "./paymentPage";

const ApplicationStatus = ({
  commonApiCall,
  commonApiCallHeader,
  sbli = false,
  assurity = false,
  underwriting,
  consumer,
  t,
  company_information,
  ...props
}) => {
  const {
    applicationId,
    applicationType,
    goalType,
    policyType,
    frequency,
    productSelection,
    mobile,
    email,
    clientUserId,
    postalCode,
    height,
  } = underwriting;
  const [formData, setFormData] = useState({
    applicationId: applicationId ? applicationId : "",
    yourFrequency: frequency ? frequency : "",
    goalType: goalType,
    applicationType: applicationType ? applicationType : "Web",
    illustrationsModel: false,
    illustrationPdf: "",
    apiResponse: false,
    frequency1: "",
    policyType: "",
    suggestions: [],
    textmsg: "",
    policyDetails: {},
    isSigned: false,
    chatModel: false,
  });
  const [isApplicationCarrierId, setisApplicationCarrierId] = useState("");
  const [callAgentModal, setCallAgentModal] = useState(false);
  const [radioSelection, setRadioSelection] = useState({
    value: "Call",
  });
  const [preferencesopt, setPreferencesopt] = useState(false);
  const [agentMob, setAgentMob] = useState(null);
  const [productName, setProductName] = useState("");
  const [emailQuestionsModal, setEmailQuestionsModal] = useState(false);
  const [recordId, setRecordId] = useState("");
  const [userMessage, setUserMessage] = useState({
    title: "",
    message: "",
    error: {
      title: "",
      message: "",
    },
  });

  const handleShow = (modalName, id, productName) => {
    if (modalName === "callAgentModal") {
      setCallAgentModal(true);
      setisApplicationCarrierId(id);
    }
    if (modalName === "emailQuestionsModal") {
      setEmailQuestionsModal(true);
      let res = productName.concat(id);
      setisApplicationCarrierId(res);
    }
    radioSelection.value = "Call";
    userMessage.title = "";
    userMessage.message = "";
    userMessage.error.title = "";
    userMessage.error.message = "";
    setUserMessage({
      ...userMessage,
      userMessage,
    });
    setRadioSelection({
      ...radioSelection,
      radioSelection,
    });
    setRecordId(id);
    if (productName) {
      setProductName(productName);
    }
    setAgentMob(null);
    setPreferencesopt(false);
  };

  const handleClose = (modalName) => {
    if (modalName === "callAgentModal") {
      setCallAgentModal(false);
      setisApplicationCarrierId("");
    }
    if (modalName === "emailQuestionsModal") {
      setEmailQuestionsModal(false);
      setisApplicationCarrierId("");
    }
    setPreferencesopt(false);
  };

  useEffect(() => {
    async function doSubmit() {
      if (applicationId !== "") {
        const splitpath = window.location.pathname.split("/");
        const isAuthorized = getJwt() ? true : false;
        var requestParams = {
          applicationId: applicationId,
          clientUserId: clientUserId,
          applicationType,
          goalType,
          resumeProcess: setProcessStep(splitpath[1], policyType, goalType),
        };
        let response = await commonApiCall(
          API_URL_UNDERWRITING + `saveUnderWriting`,
          "post",
          requestParams,
          UNDERWRITING_DETAILS,
          isAuthorized
        );
        if (response.status.code === 200) {
          let headers = { cje: getCookieValue("cje") };
          headers.clickid = getCookieValue("clickid");
          let response = await commonApiCallHeader(
            API_URL_UNDERWRITING +
              "getPolicyStatus?applicationId=" +
              applicationId,
            "get",
            "",
            "",
            isAuthorized,
            false,
            headers
          );
          if (response.status.code === 200) {
            setFormData({
              ...formData,
              suggestions: response.data,
              apiResponse: true,
            });
          } else if (response.status.code === 404) {
            showErrorToast(response.status.message, {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });
          }
        }
      }
    }
    doSubmit();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleIllustration = (illustrationPdfName) => {
    try {
      if (illustrationPdfName) {
        const illustrationPdfURL =
          API_URL_CLIENT + "getPdfFile?path=" + illustrationPdfName;
        setFormData({
          ...formData,
          illustrationPdf: illustrationPdfURL,
          illustrationsModel: true,
        });
      } else {
        showErrorToast("Illustration data not found.");
      }
    } catch (ex) {
      showErrorToast(t("application_status.something_went_wrong"));
    }
  };
  const handleModel = (name, isHide) => {
    setFormData({ ...formData, [name]: isHide });
    if (!isHide && name === "chatModel") setisApplicationCarrierId("");
  };

  const errorImageReplace = (e, id) => {
    document.getElementById(id).src = "/assets/images/user-dp-demo.jpg";
  };

  const getSubmitDetails = async () => {
    if (radioSelection.value === "Call") {
      setPreferencesopt("Call");
      setAgentMob(
        await decryptme(suggestions[recordId].agentResponse?.agentMobile)
      );
    }
    if (radioSelection.value === "Get-Call") {
      try {
        const request = {
          clientFirstName: suggestions[recordId].firstName,
          clientLastName: suggestions[recordId].lastName,
          agentEmail: suggestions[recordId].agentResponse.agentEmail,
          agentFirstName: suggestions[recordId].agentResponse.firstName,
          agentUserId: suggestions[recordId].agentResponse.agentId,
          applicationId: applicationId,
          carrierName: suggestions[recordId].carrierName,
          clientCountryCode: await encryptme("+1"),
          clientMobile: mobile,
        };

        const isAuthorized = getJwt() ? true : false;
        const apiEndPoint = API_URL_CLIENT + "notifyToAgent";
        const response = await commonApiCall(
          apiEndPoint,
          "post",
          request,
          "",
          isAuthorized
        );

        if (response.status.code === 200) {
          setPreferencesopt("Get-Call");
        } else {
          showErrorToast(response.status.message);
        }
      } catch (err) {
        showErrorToast(t("application_status.something_went_wrong"));
      }
    }
  };

  const submitMessage = async () => {
    try {
      const request = {
        agentEmail: suggestions[recordId].agentResponse.agentEmail,
        agentFirstName: suggestions[recordId].agentResponse.firstName,
        agentLastName: suggestions[recordId].agentResponse.lastName,
        agentUserId: suggestions[recordId].agentResponse.agentId,
        applicationId: applicationId,
        carrierName: suggestions[recordId].carrierName,
        clientFirstName: suggestions[recordId].firstName,
        clientLastName: suggestions[recordId].lastName,
        clientEmail: underwriting?.email,
        clientMobile: underwriting?.mobile,
        message: userMessage.message,
        title: userMessage.title,
        productName: productName.length > 0 ? productName : undefined,
      };
      if (validateMessage()) {
        const isAuthorized = getJwt() ? true : false;
        const apiEndPoint = API_URL_CLIENT + "sendEmailToAgent";
        const response = await commonApiCall(
          apiEndPoint,
          "post",
          request,
          "",
          isAuthorized
        );

        if (response.status.code === 200) {
          setPreferencesopt("Get-Call");
        } else {
          showErrorToast(response.status.message);
        }
      }
    } catch (err) {
      showErrorToast(t("application_status.something_went_wrong"));
    }
  };

  const validateMessage = () => {
    let valid = true;
    if (userMessage.title === "") {
      userMessage.error.title = t("application_status.please_enter_title");
      valid = false;
    }
    if (userMessage.message === "") {
      userMessage.error.message = t("application_status.please_enter_message");
      valid = false;
    }
    setUserMessage({
      ...userMessage,
      userMessage,
    });
    return valid;
  };

  let {
    illustrationsModel,
    illustrationPdf,
    apiResponse,
    suggestions,
    policyDetails,
    chatModel,
  } = formData;

  let agentMobile =
    company_information?.accountType === "ambassador" && agentMob
      ? agentMob
      : company_information && company_information?.phoneNumber
      ? decryptme(company_information?.phoneNumber)
      : agentMob;
  return (
    <div className="steps">
      <div className="title-header">
        {apiResponse &&
          !isEmpty(suggestions[0].agentResponse) &&
          suggestions[0]?.carrierName != "Banner Life" &&
          !sbli && (
            <h2 className="page-title assign-header-title">
              {t("application_status.agent_assign_header")}
            </h2>
          )}
        {apiResponse && suggestions[0]?.carrierName == "Banner Life" && (
          <h2 className="page-title assign-header-title">
            Congratulations! You're on your way.
          </h2>
        )}
        {apiResponse && suggestions[0]?.carrierName == "Banner Life" && (
          <h3 className="font-size-18">
            Please look for an email from Banner Life inviting you to complete
            an online application. please <br /> contact me with any questions
            you may have!
          </h3>
        )}
        {apiResponse &&
          !isEmpty(suggestions[0].agentResponse) &&
          suggestions[0]?.carrierName != "Banner Life" &&
          !sbli && <h3>{t("application_status.agent_assign_sub_header")}</h3>}
        {apiResponse && suggestions[0].agentResponse == null && (
          <h2 className="page-title">
            {t("application_status.your_selection")}
          </h2>
        )}
        {apiResponse && suggestions[0].agentResponse == null && (
          <h3>Your specialized agent will reach out to you soon.</h3>
        )}
      </div>
      {apiResponse === false ? (
        <div className="row justify-content-center">
          <div className="col-md-6 col-sm-12 mb-5">
            <div className="card qualification-status">
              <ApplicationStatusLoader />
            </div>
          </div>
          <div className="col-md-6 col-sm-12 mb-5">
            <div className="card qualification-status">
              <ApplicationStatusLoader />
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="row justify-content-center">
            {suggestions &&
              suggestions.map((each, id) => {
                return (
                  <div
                    className={
                      " col-sm-12 mb-5 " +
                      (assurity ? "col-md-12" : sbli ? "col-md-8" : "col-md-6")
                    }
                    key={id}
                  >
                    <Animated
                      animationIn="fadeInRight"
                      animationOut="fadeInLeft"
                      animationInDuration={1000}
                      animationOutDuration={1000}
                      isVisible={true}
                    >
                      <div
                        className={
                          "card qualification-status " +
                          (assurity ? "shadow-none p-0" : "")
                        }
                      >
                        <div className="card-body p-0">
                          <div className="guardian-outer">
                            {!sbli && (
                              <div className="guardian-details">
                                <div className="guardian-img">
                                  <img
                                    src={each.carrierLogo}
                                    alt="Carrier Logo"
                                    title={each.carrier_name}
                                    width="32"
                                    height="32"
                                  />
                                </div>
                                <div className="guardian-name">
                                  <h2>
                                    <span>{each.carrierName}</span>
                                  </h2>
                                </div>
                              </div>
                            )}

                            {!sbli && (
                              <div className="policy-outer">
                                <div className="policy-name">
                                  <h3>
                                    {each.productName &&
                                      each.productName.replace("15", each.pay)}
                                  </h3>
                                </div>
                                <h4>
                                  {each.productDescription
                                    ? each.productDescription
                                    : "N/A"}
                                </h4>
                                <div className="chips-outer">
                                  <span className="chips-label">
                                    {each.frequency}
                                  </span>
                                  <span className="chips-label">
                                    {each.premium &&
                                      NumberFormatter(
                                        each.premium.toString(),
                                        "$"
                                      )}
                                  </span>
                                </div>
                                {(goalType === "INSURE" &&
                                  policyType === "TERM_LIFE_INSURANCE") ||
                                (goalType === "INSURE" &&
                                  policyType === "FINAL_EXPENSE_INSURANCE") ||
                                (goalType === "MEDICARE" &&
                                  policyType ===
                                    "MEDICARE_INSURANCE") ? null : (
                                  <div className="mt-3">
                                    <button
                                      className="btn btn-primary-outline"
                                      onClick={() =>
                                        handleIllustration(
                                          each.viewIllustration
                                        )
                                      }
                                    >
                                      {t(
                                        "application_status.view_illustration"
                                      )}
                                    </button>
                                  </div>
                                )}
                              </div>
                            )}
                            {each.agentResponse ? (
                              <div className="agent-outer agent-shortchat">
                                <div className="agent-details">
                                  <div className="agent-img">
                                    <img
                                      id={"img" + id}
                                      src={
                                        each &&
                                        each.agentResponse.aboutPageProfilePic
                                          ? each.agentResponse
                                              .aboutPageProfilePic
                                          : "/assets/images/user-dp-demo.jpg"
                                      }
                                      onError={(e) =>
                                        errorImageReplace(e, "img" + id)
                                      }
                                      alt={t("application_status.profile")}
                                      title={t("application_status.profile")}
                                    ></img>
                                  </div>
                                  <div className="agent-help text-left">
                                    <h4>
                                      {company_information &&
                                        company_information.firstName !==
                                          undefined &&
                                        decryptme(
                                          company_information?.firstName
                                        )}
                                      {company_information &&
                                        company_information.lastName !==
                                          undefined &&
                                        " " +
                                          decryptme(
                                            company_information?.lastName
                                          )}
                                    </h4>
                                    <h5 className="color-grey">
                                      <b>
                                        {" "}
                                        {!sbli &&
                                          t(
                                            "application_status.what_happens_next"
                                          )}
                                        {!sbli &&
                                          t(
                                            "application_status.your_licensed_professional_is_ready_to_answer_your_questions_and_will_validate_your_information_to_provide_you_with_an_e_app_link_to_apply"
                                          )}
                                      </b>
                                      {sbli && (
                                        <span>
                                          {" "}
                                          If you would like personal help, your
                                          agent is available to help you through
                                          the entire process.
                                        </span>
                                      )}
                                    </h5>
                                  </div>
                                </div>
                                <div className="chat-icons">
                                  <ul>
                                    {/* <li>
                                      <Link
                                        to="#"
                                        onClick={() => {
                                          setFormData({
                                            ...formData,
                                            policyDetails: each,
                                            chatModel: true,
                                          });
                                          setisApplicationCarrierId(
                                            each.applicationCarrierId
                                          );
                                        }}
                                        className={
                                          each.applicationCarrierId ===
                                          isApplicationCarrierId
                                            ? "btn btn-primary btn-with-icon focusOnChatICon"
                                            : "btn btn-primary btn-with-icon "
                                        }
                                      >
                                        <svg
                                          width="20px"
                                          height="20px"
                                          viewBox="0 0 20 20"
                                          version="1.1"
                                          xmlns="http://www.w3.org/2000/svg"
                                          xlink="http://www.w3.org/1999/xlink"
                                        >
                                          <title>
                                            {t(
                                              "application_status.live_chat_or_Leave_a_message"
                                            )}
                                          </title>
                                          <desc>
                                            {t(
                                              "application_status.created_with_sketch"
                                            )}
                                          </desc>
                                          <g
                                            id="Client"
                                            stroke="none"
                                            strokeWidth="1"
                                            fill="none"
                                            fillRule="evenodd"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          >
                                            <g
                                              id="message-square"
                                              transform="translate(1.000000, 1.000000)"
                                              stroke="#2BA84A"
                                              strokeWidth="2"
                                            >
                                              <path
                                                d="M18,12 C18,13.1045695 17.1045695,14 16,14 L4,14 L0,18 L0,2 C0,0.8954305 0.8954305,0 2,0 L16,0 C17.1045695,0 18,0.8954305 18,2 L18,12 Z"
                                                id="Shape"
                                              ></path>
                                            </g>
                                          </g>
                                        </svg>
                                        {t("application_status.chat")}
                                      </Link>
                                    </li> */}
                                    <li>
                                      <Link
                                        to="#"
                                        onClick={() =>
                                          handleShow("callAgentModal", id)
                                        }
                                        className={
                                          isApplicationCarrierId == id
                                            ? "btn btn-primary btn-with-icon"
                                            : "btn btn-primary btn-with-icon "
                                        }
                                      >
                                        <svg
                                          width="22px"
                                          height="22px"
                                          viewBox="0 0 22 22"
                                          version="1.1"
                                          xmlns="http://www.w3.org/2000/svg"
                                          xlink="http://www.w3.org/1999/xlink"
                                        >
                                          <title>
                                            {t(
                                              "application_status.call_agent_or_get_a_call"
                                            )}
                                          </title>
                                          <desc>Created with Sketch.</desc>
                                          <g
                                            id="Client"
                                            stroke="none"
                                            strokeWidth="1"
                                            fill="none"
                                            fillRule="evenodd"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          >
                                            <g
                                              id="phone"
                                              transform="translate(1.000000, 1.000000)"
                                              stroke="#2BA84A"
                                              strokeWidth="2"
                                            >
                                              <path
                                                d="M20,14.92 L20,17.92 C20.0022858,18.4831451 19.767048,19.0211584 19.3520779,19.4018649 C18.9371078,19.7825714 18.3808639,19.9706881 17.82,19.92 C14.7428339,19.5856408 11.7869992,18.5341445 9.19,16.85 C6.77382812,15.3146639 4.72533615,13.2661719 3.19,10.85 C1.49997806,8.24120398 0.448243002,5.27099456 0.12,2.18 C0.0694732103,1.62087233 0.256272008,1.06625242 0.63476578,0.651621056 C1.01325955,0.236989688 1.54859424,0.000528690239 2.11,-9.8318943e-05 L5.11,-9.8318943e-05 C6.11386406,-0.00988015716 6.96950085,0.725967482 7.11,1.72 C7.23662301,2.68006645 7.47144946,3.62272688 7.81,4.53 C8.084735,5.26087503 7.90901507,6.08480626 7.36,6.64 L6.09,7.91 C7.51355547,10.4135458 9.58645417,12.4864445 12.09,13.91 L13.36,12.64 C13.9151937,12.0909849 14.739125,11.915265 15.47,12.19 C16.3772731,12.5285505 17.3199335,12.763377 18.28,12.89 C19.2855627,13.0318603 20.02519,13.9047926 20,14.92 Z"
                                                id="Shape"
                                              ></path>
                                            </g>
                                          </g>
                                        </svg>
                                        {t("application_status.call")}
                                      </Link>
                                    </li>
                                    {email && email !== null ? (
                                      <li className="email-icon">
                                        <Link
                                          to="#"
                                          onClick={() =>
                                            handleShow(
                                              "emailQuestionsModal",
                                              id,
                                              each.productName
                                            )
                                          }
                                          className={
                                            isApplicationCarrierId ===
                                              each.productName &&
                                            each.productName.concat(id)
                                              ? "btn btn-primary btn-with-icon focusOnChatICon"
                                              : "btn btn-primary btn-with-icon "
                                          }
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            xlink="http://www.w3.org/1999/xlink"
                                            x="0px"
                                            y="0px"
                                            viewBox="0 0 512 512"
                                          >
                                            <title>
                                              {t(
                                                "application_status.email_your_questions"
                                              )}{" "}
                                            </title>
                                            <g>
                                              <g>
                                                <path
                                                  d="M467,61H45C20.218,61,0,81.196,0,106v300c0,24.72,20.128,45,45,45h422c24.72,0,45-20.128,45-45V106
                                                                            C512,81.28,491.872,61,467,61z M460.786,91L256.954,294.833L51.359,91H460.786z M30,399.788V112.069l144.479,143.24L30,399.788z
                                                                            M51.213,421l144.57-144.57l50.657,50.222c5.864,5.814,15.327,5.795,21.167-0.046L317,277.213L460.787,421H51.213z M482,399.787
                                                                            L338.213,256L482,112.212V399.787z"
                                                />
                                              </g>
                                            </g>
                                          </svg>
                                          {t("application_status.mail")}
                                        </Link>
                                      </li>
                                    ) : (
                                      ""
                                    )}
                                  </ul>
                                </div>
                              </div>
                            ) : (
                              <div className="w-100 text-center mt-2 mb-3">
                                <h4>
                                  <b>
                                    {" "}
                                    {t("application_status.what_happens_next")}
                                  </b>
                                  {t(
                                    "application_status.you_will_be_assigned_an_agent_as_soon_as_possible_your_agent_will_validate_your_information_and_then_provide_you_with_an_e_app_link_to_apply"
                                  )}
                                </h4>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </Animated>
                  </div>
                );
              })}
            {!sbli && <PaymentPage t={t} applicationId={applicationId} />}
            <Modal
              className="call-agent-modal"
              show={callAgentModal}
              onHide={() => handleClose("callAgentModal")}
              centered
            >
              {preferencesopt === false ? (
                <Modal.Header closeButton>
                  <Modal.Title>
                    {t("application_status.please_select_your_preference")}
                  </Modal.Title>
                </Modal.Header>
              ) : (
                <Modal.Header closeButton>
                  <Modal.Title>{t("application_status.thank_you")}</Modal.Title>
                </Modal.Header>
              )}

              <Modal.Body>
                {preferencesopt === false ? (
                  <React.Fragment>
                    <div className="custom-radio">
                      <label htmlFor="call-option1">
                        <input
                          id="call-option1"
                          type="radio"
                          name="call-option"
                          value="Call"
                          onChange={(e) => {
                            radioSelection.value = e.target.value;
                            setRadioSelection({
                              ...radioSelection,
                              radioSelection,
                            });
                          }}
                          defaultChecked
                        />
                        <span></span>
                        <span className="emailcheck">
                          {t(
                            "application_status.do_you_want_to_call_a_professional?"
                          )}
                        </span>
                      </label>
                    </div>

                    {mobile && mobile !== null ? (
                      <div className="custom-radio">
                        <label htmlFor="call-option2">
                          <input
                            id="call-option2"
                            type="radio"
                            name="call-option"
                            value="Get-Call"
                            onChange={(e) => {
                              radioSelection.value = e.target.value;
                              setRadioSelection({
                                ...radioSelection,
                                radioSelection,
                              });
                            }}
                          />
                          <span></span>
                          <span className="emailcheck">
                            {t(
                              "application_status.get_a_call_back_from_a_professional?"
                            )}
                          </span>
                        </label>
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="text-center mt-3 mb-1">
                      <button
                        className="btn btn-primary-outline"
                        onClick={() => getSubmitDetails()}
                      >
                        {t("application_status.submit")}
                      </button>
                    </div>
                  </React.Fragment>
                ) : null}
                {preferencesopt.toString() === "Call" ? (
                  <div className="msg-sent">
                    {t(
                      "application_status.you_can_reach_a_professional_on_below_number"
                    )}
                    <br />
                    <a href={"tel:" + agentMobile}>
                      <NumberFormat
                        format="(###) ###-####"
                        value={agentMobile}
                        displayType={"text"}
                        renderText={(value) => <span>{value}</span>}
                      />
                    </a>
                  </div>
                ) : null}
                {preferencesopt.toString() === "Get-Call" ? (
                  <div className="msg-sent">
                    {t(
                      "application_status.someone_will_get_in_touch_with_you_shortly"
                    )}
                  </div>
                ) : null}
              </Modal.Body>
            </Modal>
            {/* {productSelection === "professionalHelp" && !height ? (
              <div className="col-12 text-center mt-3">
                <Link
                  className="btn btn-primary"
                  to={
                    getJwt()
                      ? UnderWritingRoutes.MANUAL_FORM
                      : UnderWritingRoutes.REGISTER_MANUALFORM
                  }
                >
                  {t("application_status.continue_with_online_application")}
                </Link>
              </div>
            ) : (
              <React.Fragment>

                <PaymentPage t={t} applicationId={applicationId} />

              </React.Fragment>
            )} */}
            {/* <div className="col-12 text-center mt-3">
              <Link className="btn btn-link" title="Back">
                {t("application_status.back")}
              </Link>



            {/* Email your questions Modal */}
            <Modal
              className="call-agent-modal"
              show={emailQuestionsModal}
              onHide={() => handleClose("emailQuestionsModal")}
              centered
            >
              {preferencesopt.toString() !== "Get-Call" ? (
                <Modal.Header closeButton>
                  <Modal.Title>
                    {t("application_status.describe_your_message")}
                  </Modal.Title>
                </Modal.Header>
              ) : (
                <Modal.Header closeButton>
                  <Modal.Title>{t("application_status.thank_you")}</Modal.Title>
                </Modal.Header>
              )}
              <Modal.Body>
                {preferencesopt.toString() !== "Get-Call" ? (
                  <React.Fragment>
                    <div className="form-group">
                      <div className="input-effect">
                        <input
                          id="title-input"
                          type="text"
                          placeholder="Title"
                          className="form-control"
                          onChange={(e) => {
                            userMessage.title = e.target.value;
                            userMessage.error.title = "";
                            setUserMessage({
                              ...userMessage,
                              userMessage,
                            });
                          }}
                        />
                        <label for="title-input">
                          {t("application_status.title")}
                        </label>
                        <span className="text-danger ">
                          {userMessage.error.title}
                        </span>
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="input-effect">
                        <textarea
                          id="message-input"
                          placeholder="Message"
                          className="form-control"
                          onChange={(e) => {
                            userMessage.message = e.target.value;
                            userMessage.error.message = "";
                            setUserMessage({
                              ...userMessage,
                              userMessage,
                            });
                          }}
                        ></textarea>
                        <label for="message-input">
                          {t("application_status.message")}
                        </label>
                        <span className="text-danger ">
                          {userMessage.error.message}
                        </span>
                      </div>
                    </div>
                    <div className="text-center mt-4 mb-1">
                      <button
                        className="btn btn-primary-outline"
                        onClick={() => submitMessage()}
                      >
                        {t("application_status.submit")}
                      </button>
                    </div>
                  </React.Fragment>
                ) : (
                  ""
                )}

                {preferencesopt.toString() === "Get-Call" ? (
                  <div className="msg-sent">
                    {t(
                      "application_status.your_message_has_been_sent_to_a_professional_and_he/she_will_get_back_to_you_shortly"
                    )}
                  </div>
                ) : null}
              </Modal.Body>
            </Modal>
          </div>
          <div
            className={
              chatModel === false
                ? "conversation-popup"
                : "conversation-popup active"
            }
          >
            {chatModel && (
              <PrivateChat
                conversationDetails={policyDetails}
                handleModel={handleModel}
                chatModel={chatModel}
                t={t}
              />
            )}
          </div>
          <div
            className={
              chatModel === false ? "conv-overlay" : "conv-overlay active"
            }
          ></div>

          {illustrationsModel && (
            <IllustrationModel
              illustrationsModel={illustrationsModel}
              t={t}
              illustrationPdf={illustrationPdf}
              handleModel={handleModel}
            ></IllustrationModel>
          )}
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  underwriting: state.underwriting.underwriting,
  consumer: state.consumer.consumerdetails,
  company_information: state.underwriting.companyInformation,
});
export default connect(mapStateToProps, { commonApiCall, commonApiCallHeader })(
  withRouter(ApplicationStatus)
);