import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { Button, Modal, Popover } from "react-bootstrap";
import { Animated } from "react-animated-css";
import {
  encryptme,
  decryptme,
  setProcessStep,
  UnderWritingRoutes,
  ApprovalStatus,
  customFilter,
  showErrorToast,
  showSuccessToast,
  removeItem,
  getState,
  getCookieValue,
} from "../../utils/index";
// import DatePicker from "react-datepicker";
import {
  commonApiCall,
  commonApiCallHeader,
  UNDERWRITING_DETAILS,
  validationMessages,
  getJwt,
  // consoleLog,
} from "../../redux/actions/index";

import { API_URL_CLIENT, API_URL_UNDERWRITING } from "../../config/configs";
import NumberFormat from "react-number-format";
import Select from "react-select";
import moment from "moment";
import OtpInput from "react-otp-input";
import { isEmpty, upperFirst, has } from "lodash";

const ClientForm = ({
  commonApiCall,
  commonApiCallHeader,
  underwriting,
  person_age,
  consumer,
  t,
  ...props
}) => {
  if (!isEmpty(underwriting)) {
    var {
      applicationId,
      applicationType,
      goalType,
      amount,
      age,
      smoker,
      frequency,
      policyType,
      healthRating,
      insureBasedOn,
      carrierWiseLeads,
    } = underwriting;
  } else {
    if (applicationId === "" || applicationId == null) {
      props.history.push(UnderWritingRoutes.HOME);
    }
  }
  const [formData, setFormData] = useState({
    isDoSubmitExicute: true,
    applicationId: applicationId ? applicationId : "",
    yourFrequency: frequency ? frequency : "",
    goalType: goalType,
    healthRating: healthRating ? healthRating : "",
    policyType: policyType ? policyType : "",
    insureBasedOn: insureBasedOn ? insureBasedOn : "",
    applicationType: applicationType ? applicationType : "Web",
    amount: amount ? amount : "",
    age: age ? age : "",
    smoker: smoker ? smoker : "",
    firstName: "",
    firstName_: "",
    lastName: "",
    lastName_: "",
    stateWarningModal: false,
    setExecute: false,
    gender: "",
    email: "",
    heightFt: "",
    heightInch: "",
    weightLb: "",
    ssn: underwriting?.ssn ? decryptme(underwriting?.ssn) : "",
    street: "",
    cityId: "",
    cityId_: "",
    city: "",
    stateId: "",
    postalCode: "",
    howLongyears: "",
    howLongmonths: "",
    countryCode: "+1",
    mobile: "",
    isAgree: true,
    otp: "",
    errors: {},
    verificationModal: false,
    ageWarningModal: false,
    consumer_id: consumer.id ? consumer.id : "",
    consumer_mobile: "",
    underWritingMobile: "",
    noOfYears: "",
    userId: "",
    isProcessing: false,
    isStateDisable: underwriting && !isEmpty(underwriting.state) ? true : false,
  });
  const [stateDropdown, setStateDropdown] = useState([]);
  const [drivingStateDropdown, setDrivingStateDropdown] = useState([]);
  const [cityDropdown, setCityDropdown] = useState([]);
  const [profileDatails, setProfileDetails] = useState(true);
  useEffect(() => window.scrollTo(0, 0), []);
  useEffect(() => {
    async function doSubmit() {
      const { applicationId, goalType, applicationType } = formData;
      try {
        if (applicationId !== "") {
          const isAuthorized = getJwt() ? true : false;
          var requestParams = {
            applicationId: applicationId,
            applicationType,
            stateId: underwriting?.state?.id ? underwriting?.state?.id : null,
          };
          let headers = { cje: getCookieValue("cje") };
          headers.clickid = getCookieValue("clickid");

          let response = await commonApiCallHeader(
            API_URL_UNDERWRITING + `saveUnderWriting`,
            "post",
            requestParams,
            UNDERWRITING_DETAILS,
            isAuthorized,
            false,
            headers
          );
          if (response.status.code === 200) {
            let {
              firstName,
              lastName,
              email,
              gender,
              dob,
              height,
              weight,
              addressLine1,
              city,
              state,
              postalCode,
              mobile,
              drivingLicenseState,
              howLongYear,
              howLongMonth,
            } = response.data;
            let gender_ = {},
              state_ = {},
              city_ = {},
              drivingLicensestaeid = {};
            if (gender != null && decryptme(gender) === "MALE") {
              gender_.value = "MALE";
              gender_.label = "Male";
            } else if (gender != null && decryptme(gender) === "FEMALE") {
              gender_.value = "FEMALE";
              gender_.label = "Female";
            }
            if (!isEmpty(state)) {
              state_.value = state.id;
              state_.label = state.name;
            }
            if (!isEmpty(drivingLicenseState)) {
              drivingLicensestaeid.value = drivingLicenseState.id;
              drivingLicensestaeid.label = drivingLicenseState.name;
            }

            setFormData({
              ...formData,
              firstName: firstName ? await decryptme(firstName) : "",
              lastName: lastName ? await decryptme(lastName) : "",
              email: email ? await decryptme(email) : "",
              city: city ? await decryptme(city) : "",
              gender: gender_,
              dob: dob
                ? new Date(await decryptme(dob))
                : new Date(underwriting.dob),
              heightFt: height ? await decryptme(height).split(".")[0] : "",
              heightInch: height ? await decryptme(height).split(".")[1] : "",
              weightLb: weight ? await decryptme(weight) : "",
              street: addressLine1 ? await decryptme(addressLine1) : "",
              stateId: state_ ? await state_ : "",
              postalCode: postalCode ? postalCode : "",
              mobile: mobile ? await decryptme(mobile) : "",
              underWritingMobile: mobile ? await decryptme(mobile) : "",
              setExecute: true,
              howLongyears: howLongYear ? howLongYear : "",
              howLongmonths: howLongMonth ? howLongMonth : "",
              isDoSubmitExicute: false,
            });
          }
        }
      } catch (err) {
        showErrorToast(t("validation_message.SOMETHING_WENT_WRONG"));
      }
    }
    if (formData.isDoSubmitExicute === true) doSubmit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commonApiCall, formData, person_age]);

  useEffect(() => {
    async function fetchUserDetails() {
      const isAuthorized = getJwt() ? true : false;
      try {
        let response = await commonApiCall(
          API_URL_CLIENT + "getUsersProfile",
          "",
          "get",
          "",
          isAuthorized
        );
        if (response.status.code === 200) {
          let {
            firstName,
            lastName,
            email,
            id,
            dob,
            height,
            weight,
            address1,
            city,
            state,
            postalCode,
            mobile,
            drivingLicenseState,
            howLongYear,
            howLongMonth,
          } = response.data;
          const isExist = has(
            response.data,
            "gender",
            "dob",
            "height",
            "weight",
            "address1",
            "postalCode"
          );
          setProfileDetails(isExist);
          let gender_ = {},
            state_ = {},
            city_ = {},
            drivingLicensestaeid = {};
          if (decryptme(underwriting.gender) === "FEMALE") {
            gender_.value = "FEMALE";
            gender_.label = "Female";
          } else {
            gender_.value = "MALE";
            gender_.label = "Male";
          }

          if (!isEmpty(state)) {
            state_.value = state.stateId;
            state_.label = state.stateName;
          }
          if (!isEmpty(city)) {
            city_.value = city.cityId;
            city_.label = city.cityName;
          }

          if (!isEmpty(drivingLicenseState)) {
            drivingLicensestaeid.value = drivingLicenseState.stateId;
            drivingLicensestaeid.label = drivingLicenseState.stateName;
          }
          setFormData({
            ...formData,
            firstName:
              formData.firstName === ""
                ? await decryptme(firstName)
                : formData.firstName,
            firstName_:
              formData.firstName === ""
                ? await decryptme(firstName)
                : formData.firstName,
            lastName:
              formData.lastName === ""
                ? await decryptme(lastName)
                : formData.lastName,
            lastName_:
              formData.lastName === ""
                ? await decryptme(lastName)
                : formData.lastName,
            email:
              formData.email === "" ? await decryptme(email) : formData.email,
            gender: gender_,
            heightFt: height ? await decryptme(height).split(".")[0] : "",
            heightInch: height ? await decryptme(height).split(".")[1] : "",
            weightLb: weight ? await decryptme(weight) : "",
            street: address1 ? await decryptme(address1) : "",
            city: city ? await decryptme(city) : "",
            stateId: isEmpty(formData.stateId)
              ? await state_
              : formData.stateId,
            postalCode: underwriting.postalCode
              ? underwriting.postalCode
              : postalCode
              ? await decryptme(postalCode)
              : "",
            mobile:
              formData.mobile === ""
                ? await decryptme(mobile)
                : formData.mobile,
            userId: id,
            consumer_mobile: mobile ? await decryptme(mobile) : "",
            setExecute: false,
            howLongyears: howLongYear ? howLongYear : "",
            howLongmonths: howLongMonth ? howLongMonth : "",
          });
        }
      } catch (err) {
        showErrorToast(t("validation_message.SOMETHING_WENT_WRONG"));
      }
    }
    if (
      formData.setExecute === true &&
      (underwriting.height === null || "") &&
      (underwriting.weight === null || "") &&
      (underwriting.addressLine1 === null || "")
    ) {
      fetchUserDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData, underwriting, commonApiCall]);

  //handle change
  const handleChange = (e) => {
    if (e.target.value) {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
        errors: Object.assign(formData.errors, { [e.target.name]: "" }),
      });
    } else
      setFormData({
        ...formData,
        [e.target.name]: "",
      });
  };

  // const handleGender = (e) => {
  //   setFormData({
  //     ...formData,
  //     gender: e,
  //     errors: Object.assign(formData.errors, { gender: "" }),
  //   });
  // };

  const handleState = async (whichState) => {
    let response = await commonApiCall(
      API_URL_CLIENT + "getState?countryId=" + 1,
      "",
      "get"
    );
    let new_options = [];
    response.data &&
      response.data.map((element) => {
        return new_options.push({
          value: element.id,
          label: element.name,
        });
      });
    if (whichState === "drivingState") setDrivingStateDropdown(new_options);
    else setStateDropdown(new_options);
    return new_options;
  };

  // const handleCity = async (each) => {
  //   if ((stateId && stateId.value) || each) {
  //     let response = "";
  //     if (stateId.value === undefined) {
  //       response = await commonApiCall(
  //         API_URL_CLIENT + "getCity?stateId=" + each.value,
  //         "",
  //         "get"
  //       );
  //     } else {
  //       response = await commonApiCall(
  //         API_URL_CLIENT + "getCity?stateId=" + stateId.value,
  //         "",
  //         "get"
  //       );
  //     }
  //     let new_options1 = [];
  //     if (response && response.status.code === 200) {
  //       response &&
  //         response.data.map((element) => {
  //           return new_options1.push({
  //             value: element.id,
  //             label: element.name,
  //           });
  //         });
  //     }
  //     setCityDropdown(new_options1);
  //     return response && response.data;
  //   }
  // };

  //validation
  const validateForm = () => {
    const {
      // drivingLicense,
      firstName,
      lastName,
      dob,
      gender,
      email,
      heightFt,
      heightInch,
      weightLb,
      ssn,
      street,
      cityId,
      city,
      stateId,
      postalCode,
      mobile,
      howLongyears,
      howLongmonths,
    } = formData;
    let errors = {},
      isValid = true;
    var pattern = new RegExp(
      /^(([^<>()+*[\]\\.,;:\s@"]+(\.[^<>()+*[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
    var postral = new RegExp(/^.{5,6}$/);

    // if (!firstName || firstName.trim() === "") {
    //   isValid = false;
    //   errors["firstName"] = t("my_profile.enter_first_name");
    // }
    // if (!lastName || lastName.trim() === "") {
    //   isValid = false;
    //   errors["lastName"] = t("my_profile.enter_last_name");
    // }
    // if (isEmpty(gender)) {
    //   isValid = false;
    //   errors["gender"] = t("my_profile.select_gender");
    // }
    // if (!pattern.test(email)) {
    //   isValid = false;
    //   if (!email || email.trim() === "") {
    //     errors["email"] = t("my_profile.enter_email");
    //   } else errors["email"] = t("my_profile.enter_a_valid_email");
    // }
    if (!heightFt) {
      isValid = false;
      errors["heightFt"] = t("my_profile.enter_height_feet");
    }
    if (!heightInch) {
      isValid = false;
      errors["heightInch"] = t("my_profile.enter_height_inch");
    }
    if (!weightLb) {
      isValid = false;
      errors["weightLb"] = t("my_profile.enter_Weight_lb");
    }
    if (
      !ssn &&
      (underwriting?.carrierWiseLeads[0]?.carrierName == "SBLI" ||
        underwriting?.carrierWiseLeads[0]?.carrierName == "Centrian")
    ) {
      isValid = false;
      errors["ssn"] = "Please enter your SSN number";
    }
    if (!street || street.trim() === "") {
      isValid = false;
      errors["street"] = t("my_profile.enter_street_address");
    }

    if (!city || city.trim() === "") {
      isValid = false;
      errors["city"] = t("my_profile.enter_city");
    }

    if (!postral.test(postalCode)) {
      isValid = false;
      if (!postalCode || postalCode.trim() === "") {
        errors["postalCode"] = t("my_profile.enter_postal/zip_code");
      } else errors["postalCode"] = t("my_profile.enter_valid_postal/zip_code");
    }

    if (postalCode.trim() !== "" && !getState(postalCode)) {
      isValid = false;
      errors["postalCode"] = t("my_profile.enter_valid_postal/zip_code");
    }
    // if (!dob) {
    //   isValid = false;
    //   errors["dob"] = t("my_profile.select_date_of_birth");
    // }

    // let dobyearsdiff = moment().diff(dob, "years");

    // let dobmonthsdiff = moment().diff(dob, "months");
    // if (howLongmonths && dobyearsdiff <= howLongyears) {
    //   if (dobmonthsdiff % 12 < howLongmonths) {
    //     isValid = false;
    //     errors["howLongmonths"] = t(
    //       "validation_message.VALID_DURATION_HOWLONG_MONTHS"
    //     );
    //   }
    // }

    // if (howLongmonths > 11) {
    //   isValid = false;
    //   errors["howLongmonths"] = t("validation_message.VALID_HOWLONG_MONTHS");
    // }

    // if (mobile.toString().length !== 10) {
    //   isValid = false;
    //   if (!mobile) {
    //     errors["mobile"] = t("my_profile.enter_mobile");
    //   } else errors["mobile"] = t("my_profile.enter_10_digits_mobile_number");
    // }

    setFormData({ ...formData, errors: errors });
    return isValid;
  };

  const compareAgetoDob = () => {
    const { age, dob } = formData;
    const dobDiff = moment().diff(moment(dob), "years");
    if (validateForm()) {
      if (dobDiff === age) {
        compareStatetoState();
      } else {
        setFormData({ ...formData, ageWarningModal: true });
      }
    }
  };

  const compareStatetoState = () => {
    setFormData({
      ...formData,
      ageWarningModal: false,
    });
    if (underwriting?.postalCode != formData.postalCode) {
      setFormData({ ...formData, stateWarningModal: true });
    } else {
      continueNext();
    }
  };

  const continueNext = async () => {
    setFormData({ ...formData, otp: "" });
    const {
      smoker,
      amount,
      age,
      policyType,
      goalType,
      underWritingMobile,
      consumer_mobile,
      insureBasedOn,
      healthRating,
      frequency,
      applicationId,
      applicationType,
      cityId_,
      firstName,
      lastName,
      dob,
      email,
      city,
      gender,
      heightFt,
      heightInch,
      weightLb,
      ssn,
      street,
      cityId,
      stateId,
      postalCode,
      mobile,
    } = formData;
    const splitpath = window.location.pathname.split("/");
    if (validateForm()) {
      !isEmpty(carrierWiseLeads) &&
        carrierWiseLeads.map((carrierWiseLeadsOptions, i) => {
          if (!carrierWiseLeadsOptions.agentAssigned) {
            carrierWiseLeadsOptions.leadLevel = "Level-1";
          }
        });

      const request = {
        dob: dob ? await encryptme(moment(dob).format("L").toString()) : null,
        applicationId: applicationId,
        goalType: goalType,
        city: await encryptme(city),
        firstName: await encryptme(upperFirst(firstName)),
        gender: await encryptme(gender.value),
        email: await encryptme(email),
        weight: await encryptme(weightLb),
        ssn: ssn ? await encryptme(ssn) : null,
        postalCode: postalCode,
        howLongYear: howLongyears,
        howLongMonth: howLongmonths,
        countryId: cityId_,
        stateId: getState(postalCode).id,
        addressLine1: await encryptme(street),
        lastName: await encryptme(upperFirst(lastName)),
        mobile: await encryptme(mobile),
        applicationType: applicationType,
        policyType: policyType,
        frequency: frequency,
        healthRating: healthRating,
        countryCode: await encryptme("+1"),
        age: age,
        otp: 0,
        amount: amount,
        height: await encryptme(heightFt + "." + heightInch),
        smoker: smoker,
        resumeProcess: setProcessStep(splitpath[1], policyType, goalType),
        carrierWiseLeads: carrierWiseLeads,
      };

      if (insureBasedOn !== "") {
        request.insureBasedOn = insureBasedOn;
      }

      setFormData({ ...formData, isProcessing: true });
      const apiEndPoint = API_URL_UNDERWRITING + "saveUnderWriting";
      const isAuthorized = getJwt() ? true : false;
      const response = await commonApiCall(
        apiEndPoint,
        "post",
        request,
        UNDERWRITING_DETAILS,
        isAuthorized
      );
      if (response.status && response.status.code === 200) {
        removeItem("isFromUnderwritingProcess");
        if (response.data && response.data.otp !== "") {
          if (!profileDatails) {
            updateProfile();
          }
          // if (
          //   (response.data.otp === null &&
          //     (underwriting?.carrierType != "online" ||  underwriting?.carrierWiseLeads[0]?.carrierName!="Banner Life") ) ||
          //   (consumer_mobile &&
          //     consumer_mobile === mobile &&
          //     (underwriting?.carrierType != "online" ||  underwriting?.carrierWiseLeads[0]?.carrierName!="Banner Life")) ||
          //   (underWritingMobile &&
          //     underWritingMobile === mobile &&
          //     (underwriting?.carrierType != "online" ||  underwriting?.carrierWiseLeads[0]?.carrierName!="Banner Life"))
          // ) {
          //   const timer = setTimeout(() => {
          //     props.history.push(UnderWritingRoutes.FACT_FINDER);
          //   }, 1000);
          //   return () => clearTimeout(timer);
          // } else
          if (
            (response.data.otp === null &&
              (underwriting?.carrierType == "online" ||
                underwriting?.carrierWiseLeads[0]?.carrierName ==
                  "Banner Life")) ||
            (consumer_mobile &&
              consumer_mobile === mobile &&
              (underwriting?.carrierType == "online" ||
                underwriting?.carrierWiseLeads[0]?.carrierName ==
                  "Banner Life")) ||
            (underWritingMobile &&
              underWritingMobile === mobile &&
              (underwriting?.carrierType == "online" ||
                underwriting?.carrierWiseLeads[0]?.carrierName ==
                  "Banner Life"))
          ) {
            const timer = setTimeout(() => {
              showSuccessToast(response?.data?.applicationMessage);
              if (response?.data?.nextStep?.toUpperCase() == "UWQ") {
                props.history.push(UnderWritingRoutes.SBLI_PAGE);
              } else if (response?.data?.nextStep?.toUpperCase() == "BENE") {
                props.history.push(UnderWritingRoutes.BENEFICIARY);
              } else {
                props.history.push(ApprovalStatus.APPLICATION_STATUS);
              }
              // props.history.push(ApprovalStatus.APPLICATION_STATUS);
            }, 1000);
            return () => clearTimeout(timer);
          }
        }
      } else {
        setFormData({ ...formData, isProcessing: false });
      }
    }
  };

  const updateProfile = async () => {
    const {
      userId,
      firstName_,
      lastName_,
      dob,
      gender,
      heightFt,
      heightInch,
      weightLb,
      street,
      city,
      stateId,
      postalCode,
    } = formData;
    if (validateForm()) {
      const request = {
        dob: dob ? await encryptme(moment(dob).format("L").toString()) : null,
        id: userId,
        city: await encryptme(city),
        stateId: stateId && stateId.value,
        firstName: await encryptme(upperFirst(firstName_)),
        gender: await encryptme(gender.value),
        weight: await encryptme(weightLb),
        postalCode: await encryptme(postalCode),
        address1: await encryptme(street),
        lastName: await encryptme(upperFirst(lastName_)),
        countryCode: await encryptme("+1"),
        height: await encryptme(heightFt + "." + heightInch),
      };
      const authorization = true;
      const response = await commonApiCall(
        API_URL_CLIENT + "saveUserClientProfile",
        "put",
        request,
        "",
        authorization
      );
      if (response.status && response.status.code === 200) {
        return true;
      } else if (response.status && response.status.code === 409) {
        showErrorToast(response.status.message);
      }
    }
  };
  //OTP
  const verifyOtp = async (type) => {
    const isAuthorized = getJwt() ? true : false;

    let { mobile, otp } = formData;
    if (type === "verify") {
      if (otp.toString().length === 6) {
        let requestData = {
          applicationId: applicationId,
          countryCode: await encryptme("+1"),
          mobile: await encryptme(mobile),
          otp: otp,
        };
        let response = await commonApiCall(
          API_URL_UNDERWRITING + "verifyOtp",
          "post",
          requestData,
          "",
          isAuthorized
        );
        if (response.status.code === 200) {
          setFormData({ ...formData, otp: "" });
          props.history.push(UnderWritingRoutes.FACT_FINDER);
        } else if (response.status.code === 400) {
          showErrorToast(t("validation_message.otp"));
        }
      } else {
        showErrorToast(t("validation_message.validOtp"));
      }
    } else {
      let requestData = {
        applicationId: applicationId,
        countryCode: await encryptme("+1"),
        mobile: await encryptme(mobile),
      };
      let response = await commonApiCall(
        API_URL_UNDERWRITING + "reSendOtpUserApplication",
        "post",
        requestData,
        "",
        isAuthorized
      );
      if (response.status.code === 200) {
        showSuccessToast(
          "Please use this code to verify your number:" +
            response.data.otp +
            " !"
        );
      }
    }
  };

  let {
    firstName,
    lastName,
    dob,
    email,
    gender,
    heightFt,
    heightInch,
    weightLb,
    ssn,
    street,
    verificationModal,
    ageWarningModal,
    cityId,
    city,
    stateId,
    postalCode,
    mobile,
    isAgree,
    errors,
    otp,
    howLongyears,
    howLongmonths,
    isProcessing,
    isStateDisable,
  } = formData;

  return (
    <React.Fragment>
      <div className="more-info">
        <div className="row justify-content-center">
          <div className="col-lg-10">
            <div className="title-header">
              <h2>Final questions</h2>
              <p className="pb-0 px-md-5">
                After you complete the information below, you will receive an
                email from <br />
                {underwriting &&
                  underwriting?.carrierWiseLeads &&
                  underwriting?.carrierWiseLeads.length > 0 &&
                  carrierWiseLeads[0]?.carrierName}{" "}
                asking you to complete your online application.
              </p>
            </div>
            <div className="more-info-wrapper">
              <Animated
                animationIn="fadeInRight"
                animationOut="fadeOutLeft"
                animationInDuration={800}
                animationOutDuration={800}
                isVisible={true}
              >
                <div className="form-wrapper">
                  {/* <h3 className="text-medium mb-5 pb-md-3 text-center">
                    Contact Information
                  </h3>
                  <form className="row align-items-start" id="manual-form">
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        <div className="input-effect">
                          <input
                            className="form-control"
                            placeholder={t("manual_form.first_name")}
                            name="firstName"
                            id="firstName"
                            type="text"
                            value={firstName}
                            onChange={(e) => handleChange(e)}
                          />
                          <label htmlFor="firstName">
                            {t("manual_form.first_name")}
                          </label>
                          <em className="input-icon"></em>
                          <span className="text-danger ">
                            {errors.firstName}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        <div className="input-effect">
                          <input
                            className="form-control"
                            placeholder={t("manual_form.last_name")}
                            name="lastName"
                            id="lastName"
                            type="text"
                            value={lastName}
                            onChange={(e) => handleChange(e)}
                          />
                          <label htmlFor="lastName">
                            {t("manual_form.last_name")}
                          </label>
                          <em className="input-icon"></em>
                          <span className="text-danger ">
                            {errors.lastName}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        <div className="input-effect">
                          <input
                            className="form-control"
                            placeholder={t("manual_form.email")}
                            name="email"
                            id="email"
                            type="mail"
                            value={email}
                            onChange={(e) => handleChange(e)}
                          />
                          <label htmlFor="email">
                            {t("manual_form.email")}
                          </label>
                          <em className="input-icon"></em>
                          <span className="text-danger ">{errors.email}</span>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        <div className="input-effect">
                          <NumberFormat
                            name="mobile"
                            id="mobile"
                            className="form-control"
                            placeholder={t("manual_form.mobile")}
                            format="(###) ###-####"
                            value={mobile}
                            onValueChange={(e) =>
                              handleChange({
                                target: { name: "mobile", value: e.value },
                              })
                            }
                          />
                          <label htmlFor="mobile">
                            {t("manual_form.mobile")}
                          </label>
                          <em className="input-icon"></em>
                          <span className="text-danger ">{errors.mobile}</span>
                        </div>
                      </div>
                    </div>
                  </form> */}

                  <h3 className="text-medium mb-5 pb-md-3r">Address</h3>
                  <form className="row">
                    <div className="col-lg-12 col-md-12">
                      <div className="form-group">
                        <div className="input-effect">
                          <input
                            className="form-control"
                            placeholder={t("manual_form.street_address")}
                            name="street"
                            id="addressLine2"
                            type="text"
                            value={street}
                            onChange={(e) => handleChange(e)}
                          />
                          <label>{t("manual_form.street_address")}</label>
                          <em className="input-icon"></em>
                          <span className="text-danger ">{errors.street}</span>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group fixed-floating-label mb-4">
                        <div className="input-effect">
                          <input
                            className="form-control"
                            placeholder={t("manual_form.city")}
                            name="city"
                            id="city"
                            type="text"
                            value={city}
                            onChange={(e) => handleChange(e)}
                          />
                          <label>{t("manual_form.city")}</label>
                          <em className="input-icon"></em>
                          <span className="text-danger ">{errors.city}</span>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-3">
                      <div className="form-group">
                        <div className="input-effect">
                          <input
                            className="form-control"
                            placeholder={t("manual_form.postal_zip_code")}
                            name="postalCode"
                            id="Postal/Zip Code"
                            type="text"
                            maxLength="5"
                            value={postalCode}
                            onChange={(e) => handleChange(e)}
                            disabled={
                              underwriting?.productSelection ===
                                "professionalHelp" && underwriting?.postalCode
                                ? true
                                : false
                            }
                          />
                          <label>{t("manual_form.postal_zip_code")}</label>
                          <em className="input-icon"></em>
                          <span className="text-danger ">
                            {errors.postalCode}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-3">
                      <div className="form-group fixed-floating-label mb-4">
                        <div className="default-select with-border">
                          <Select
                            options={stateDropdown}
                            className={"custom-select-menu"}
                            isSearchable={true}
                            filterOption={(e, searchWith) =>
                              customFilter(e, searchWith)
                            }
                            onFocus={() => handleState("state")}
                            onChange={(value) => {
                              setFormData({
                                ...formData,
                                stateId: value,
                                cityId: {},
                                errors: Object.assign(formData.errors, {
                                  stateId: "",
                                }),
                              });
                            }}
                            classNamePrefix={"custom-select"}
                            value={
                              postalCode != ""
                                ? {
                                    value: getState(postalCode)?.id,
                                    label: getState(postalCode)?.state,
                                  }
                                : ""
                            }
                            isDisabled={true}
                          />
                          <span className="fixed-label">
                            {t("manual_form.state")}
                          </span>
                          <em className="input-icon"></em>
                          <span className="text-danger ">{errors.stateId}</span>
                        </div>
                      </div>
                    </div>
                  </form>

                  <h3 className="text-medium mb-5 pb-md-3">
                    Personal Information
                  </h3>
                  <form className="row">
                    {/* <div
                      className={
                        underwriting?.carrierWiseLeads[0]?.carrierName == "SBLI"
                          ? "col-lg-6 col-md-6"
                          : "col-lg-3 col-md-3"
                      }
                    >
                      <div className="form-group custom-datepicker">
                        <DatePicker
                          selected={dob}
                          maxDate={new Date()}
                          onChange={(date) =>
                            setFormData({
                              ...formData,
                              dob: date,
                              howLongmonths: "",
                              howLongyears: "",
                            })
                          }
                          placeholderText={"MM/DD/YYYY"}
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          dateFormatCalendar="MMMM"
                        />
                        <span className="datepicker-label">
                          {t("manual_form.date_of_birth")}
                        </span>
                        <span className="text-danger ">{errors.dob}</span>
                      </div>
                    </div> */}
                    {underwriting &&
                      underwriting?.carrierWiseLeads &&
                      underwriting?.carrierWiseLeads.length > 0 &&
                      (carrierWiseLeads[0]?.carrierName == "SBLI" ||
                        carrierWiseLeads[0]?.carrierName == "Centrian") && (
                        <div className="col-lg-6 col-md-6">
                          <div className="form-group">
                            <div className="input-effect ">
                              <NumberFormat
                                name="ssn"
                                id="ssn"
                                className="form-control"
                                placeholder={"Social Security Number (SSN#)"}
                                format="###-##-####"
                                value={ssn}
                                onValueChange={(e) =>
                                  handleChange({
                                    target: { name: "ssn", value: e.value },
                                  })
                                }
                              />
                              <label htmlFor="SSN">
                                Social Security Number (SSN#)
                              </label>
                              <span className="text-danger">{errors.ssn}</span>
                            </div>
                          </div>
                        </div>
                      )}
                    <div
                      className={
                        underwriting &&
                        underwriting?.carrierWiseLeads &&
                        underwriting?.carrierWiseLeads.length > 0 &&
                        (carrierWiseLeads[0]?.carrierName == "SBLI" ||
                          carrierWiseLeads[0]?.carrierName == "Centrian")
                          ? "col-lg-4 col-md-4"
                          : "col-lg-3 col-md-3"
                      }
                    >
                      <div className="form-group">
                        <div className="input-effect">
                          <NumberFormat
                            className="form-control"
                            placeholder={t("manual_form.weight_lbs")}
                            aria-describedby="basic-addon2"
                            format="###"
                            value={weightLb}
                            isAllowed={(values) => {
                              const { formattedValue, floatValue } = values;
                              return formattedValue === "" || floatValue <= 500;
                            }}
                            onValueChange={(e) =>
                              handleChange({
                                target: { name: "weightLb", value: e.value },
                              })
                            }
                          />
                          <label>{t("manual_form.weight_lbs")}</label>
                          <em className="input-icon"></em>
                          <span className="text-danger ">
                            {errors.weightLb}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div
                      className={
                        underwriting &&
                        underwriting?.carrierWiseLeads &&
                        underwriting?.carrierWiseLeads.length > 0 &&
                        (carrierWiseLeads[0]?.carrierName == "SBLI" ||
                          carrierWiseLeads[0]?.carrierName == "Centrian")
                          ? "col-lg-4 col-md-4"
                          : "col-lg-3 col-md-3"
                      }
                    >
                      <div className="form-group">
                        <div className="input-effect">
                          <NumberFormat
                            className="form-control"
                            placeholder={t("manual_form.height_feet")}
                            aria-describedby="basic-addon2"
                            format="#"
                            value={heightFt}
                            isAllowed={(values) => {
                              const { formattedValue, floatValue } = values;
                              return formattedValue === "" || floatValue <= 7;
                            }}
                            onValueChange={(e) =>
                              handleChange({
                                target: { name: "heightFt", value: e.value },
                              })
                            }
                          />
                          <label>{t("manual_form.height_feet")}</label>
                          <em className="input-icon"></em>
                          <span className="text-danger ">
                            {errors.heightFt}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div
                      className={
                        underwriting &&
                        underwriting?.carrierWiseLeads &&
                        underwriting?.carrierWiseLeads.length > 0 &&
                        (carrierWiseLeads[0]?.carrierName == "SBLI" ||
                          carrierWiseLeads[0]?.carrierName == "Centrian")
                          ? "col-lg-4 col-md-4"
                          : "col-lg-3 col-md-3"
                      }
                    >
                      <div className="form-group">
                        <div className="input-effect">
                          <NumberFormat
                            className="form-control"
                            placeholder={t("manual_form.height_inch")}
                            aria-describedby="basic-addon2"
                            format="##"
                            value={heightInch}
                            isAllowed={(values) => {
                              const { formattedValue, floatValue } = values;
                              return formattedValue === "" || floatValue <= 11;
                            }}
                            onValueChange={(e) =>
                              handleChange({
                                target: { name: "heightInch", value: e.value },
                              })
                            }
                          />
                          <label>{t("manual_form.height_inch")}</label>
                          <em className="input-icon"></em>
                          <span className="text-danger ">
                            {errors.heightInch}
                          </span>
                        </div>
                      </div>
                    </div>
                  </form>
                  <div className="col-12">
                    <div className="text-center">
                      {isProcessing ? (
                        <button className="btn btn-gradient px-5 py-3">
                          {t("manual_form.processing")}
                        </button>
                      ) : (
                        <button
                          className="btn btn-gradient px-5 py-3"
                          onClick={() => compareAgetoDob()}
                          disabled={isAgree ? false : true}
                          type="submit"
                        >
                          FINISH
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </Animated>
            </div>
            <Modal
              className="plan-modal verification-modal"
              show={verificationModal}
              onHide={() =>
                setFormData({ ...formData, verificationModal: false, otp: "" })
              }
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title>
                  {t("manual_form.verify_your_mobile_number")}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="signup-otp">
                  <Animated
                    animationIn="fadeInRight"
                    animationOut="fadeOutLeft"
                    animationInDuration={800}
                    animationOutDuration={800}
                    isVisible={true}
                  >
                    <div className="form-wrapper">
                      <div className="form-text">
                        <h3 className="card-text mb-5 text-center">
                          {t("manual_form.weve_sent_an_otp_to")}{" "}
                          <span className="text-link">
                            {" "}
                            +1{" "}
                            {mobile
                              ? [
                                  mobile.slice(0, 3),
                                  " ",
                                  mobile.slice(3, 6),
                                  " ",
                                  mobile.slice(6),
                                ].join("")
                              : null}{" "}
                          </span>{" "}
                          {t(
                            "manual_form.to_verify_your_account_please_enter_six_digit_code_below"
                          )}
                        </h3>
                      </div>
                      <form>
                        <div className="otp-input-wrapper">
                          <OtpInput
                            onChange={(otp) =>
                              setFormData({ ...formData, otp: otp })
                            }
                            numInputs={6}
                            inputStyle={"otp-input"}
                            value={otp}
                            isInputNum={true}
                          />
                        </div>
                        <div className="form-group">
                          <p className="text-center pb-0">
                            {t("manual_form.didnt_receive_code_yet")}
                            <button
                              className="btn btn-link text-primary"
                              type="button"
                              onClick={() => verifyOtp("resend")}
                              style={{ textTransform: "none" }}
                            >
                              {t("manual_form.resend_otp")}
                            </button>
                          </p>
                        </div>
                        <div className="text-center">
                          <button
                            className="btn btn-gradient px-5 py-3"
                            type="button"
                            onClick={() => verifyOtp("verify")}
                          >
                            {" "}
                            {t("submit")}{" "}
                          </button>
                        </div>
                      </form>
                    </div>
                  </Animated>
                </div>
              </Modal.Body>
            </Modal>

            <Modal
              className="plan-modal verification-modal"
              show={ageWarningModal}
              onHide={() =>
                setFormData({ ...formData, ageWarningModal: false })
              }
              centered
            >
              <Modal.Header closeButton></Modal.Header>
              <Modal.Body>
                <div className="signup-otp">
                  <Animated
                    animationIn="fadeInRight"
                    animationOut="fadeOutLeft"
                    animationInDuration={800}
                    animationOutDuration={800}
                    isVisible={true}
                  >
                    <div className="form-wrapper">
                      <div className="form-text">
                        <h3 className="card-text mb-5 text-center">
                          {t(
                            "manual_form.this_age_is_different_from_the_policy_age_selected_during_the_product_search_therefore_premium_amounts_may_differ_as_a_result"
                          )}
                        </h3>
                      </div>
                      <div className="text-center">
                        <button
                          className="btn btn-link"
                          type="button"
                          onClick={() =>
                            setFormData({ ...formData, ageWarningModal: false })
                          }
                        >
                          {t("manual_form.cancel")}
                        </button>
                        {isProcessing ? (
                          <button
                            className="btn btn-gradient px-5 py-3"
                            type="button"
                          >
                            {t("manual_form.processing")}
                          </button>
                        ) : (
                          <button
                            className="btn btn-gradient px-5 py-3"
                            type="button"
                            onClick={() => {
                              compareStatetoState();
                            }}
                          >
                            {t("continue")}
                          </button>
                        )}
                      </div>
                    </div>
                  </Animated>
                </div>
              </Modal.Body>
            </Modal>

            {/* State warning Modal */}
            <Modal
              className="plan-modal verification-modal"
              show={formData.stateWarningModal}
              onHide={() =>
                setFormData({ ...formData, stateWarningModal: false })
              }
              centered
            >
              <Modal.Header closeButton></Modal.Header>
              <Modal.Body>
                <div className="signup-otp">
                  <Animated
                    animationIn="fadeInRight"
                    animationOut="fadeOutLeft"
                    animationInDuration={800}
                    animationOutDuration={800}
                    isVisible={true}
                  >
                    <div className="form-wrapper">
                      <div className="form-text">
                        <h3 className="card-text mb-5 text-center">
                          {t("manual_form.diff_state_msg")}
                        </h3>
                      </div>
                      <div className="text-center">
                        <button
                          className="btn btn-link"
                          type="button"
                          onClick={() =>
                            setFormData({
                              ...formData,
                              stateWarningModal: false,
                            })
                          }
                        >
                          {t("manual_form.cancel")}
                        </button>
                        {isProcessing ? (
                          <button
                            className="btn btn-gradient px-5 py-3"
                            type="button"
                          >
                            {t("manual_form.processing")}
                          </button>
                        ) : (
                          <button
                            className="btn btn-gradient px-5 py-3"
                            type="button"
                            onClick={() => continueNext()}
                          >
                            {t("continue")}
                          </button>
                        )}
                      </div>
                    </div>
                  </Animated>
                </div>
              </Modal.Body>
            </Modal>
            {/* <div className="col-12 text-center mt-3">
              <Link
                to={UnderWritingRoutes.GUEST_BASIC_DETAILS}
                className="btn btn-link"
                title="Back"
              >
                {t("back")}
              </Link>
            </div> */}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  underwriting: state.underwriting.underwriting,
  consumer: state.consumer.consumerdetails,
  person_age: state.underwriting.person_age,
});

export default connect(mapStateToProps, {
  commonApiCall,
  commonApiCallHeader,
  validationMessages,
})(withRouter(ClientForm));
