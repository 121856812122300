import React from "react";
import { Link } from "react-router-dom";
import { UnderWritingRoutes } from "../utils";

const DisclaimerFooter = ({
  t,
  underwriting,
  showApplicationId,
  isSbliFooter,
  isAssurityFooter,
  ...props
}) => {
  return (
    <footer className="overflow-hidden">
      <div className="container-fluid pb-0">
        <div className="disclaimer-block">
          <div className="disclaimer">
            {props?.disclaimer?.length > 0 && (
              <p className="pb-0">
                <strong>{t("disclaimer_footer.disclaimer")}: </strong>
                {props.disclaimer}
              </p>
            )}
          </div>
        </div>
      </div>
      {isAssurityFooter && (
        <div className="sbli-main-footer">
          <img src="assets/images/assurity-icon.png" style={{ width: 60 }} />
          <p>
            POLICY NOT AVAILABLE FOR RESIDENTS OF NEW YORK. <br />
            <br />
            This policy may contain reductions of benefits, limitations and
            exclusions. Click here for standard{" "}
            <Link
              target={"_blank"}
              style={{ textDecoration: "underline!important" }}
              className="text-decoration-underline"
              to={UnderWritingRoutes.ASSURITY_LIMITATION}
            >
              Limitations and Exclusions
            </Link>
            . Product availability, features, rates, limitations and exclusions
            may vary by state. For costs and complete details of the coverage,
            please contact your insurance representative or Assurity to review
            the policy for more information. The state specific policy form is
            the ultimate authority for any questions about this product.
            <br /> <br /> Policy Form No. I L1702 and if made available,
            associated Form Nos. R I1506, R I1703, R I1705, R I1706, R I0762, R
            I0825-T and R I0827-T underwritten by Assurity Life Insurance
            Company, Lincoln, Nebraska.
            <br /> <br /> Assurity reserves the right to order, at the company’s
            expense, evidence of insurability which the company feels is
            necessary for the prudent evaluation of the risk. <br /> <br />{" "}
            Assurity is a marketing name for the mutual holding company Assurity
            Group, Inc. and its subsidiaries. Those subsidiaries include but are
            not limited to: Assurity Life Insurance Company and Assurity Life
            Insurance Company of New York. Insurance products and services are
            offered by Assurity Life Insurance Company in all states except New
            York. In New York, insurance products and services are offered by
            Assurity Life Insurance Company of New York, Albany, NY.
          </p>
        </div>
      )}
      {isSbliFooter && (
        <div className="container-fluid pb-0">
          <div className="sbli-main-footer">
            {underwriting?.carrierWiseLeads[0]?.carrierName === "SBLI" ? (
              <img src="assets/images/sbli.png" />
            ) : (
              <img src="assets/images/centrain.png" />
            )}
            <p>
              Policies are issued by The Savings Bank Mutual Life Insurance
              Company of Massachusetts ("SBLI*") on policy form series 20-P-SIT.
              Centrian Life Insurance is a registered trade name and Connecticut
              d/b/a of SBLI. Policies sold in the state of Connecticut will be
              issued under Centrian Life Insurance ("Centrian*")
            </p>
          </div>
        </div>
      )}
    </footer>
  );
};

export default DisclaimerFooter;
