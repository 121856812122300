import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Button, Popover, OverlayTrigger } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import moment from "moment";
import * as _ from "lodash";
import { showErrorToast } from "../../utils/index";
import { dateFormate } from "../../hooks/index";
import {
  decryptme,
  getAttchmentHtml,
  getAttachmentName,
  truncate,
} from "../../utils/index";
import { commonApiCall, getJwt, consoleLog } from "../../redux/actions/index";
import { API_URL_CHATSERVICE } from "../../config/configs";
import { ChatLoader } from "../skeletonLoaders/index";
import SendMessage from "./sendMessage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileWord } from "@fortawesome/free-solid-svg-icons";
import { faFileLines } from "@fortawesome/free-solid-svg-icons";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import { faFileExcel } from "@fortawesome/free-solid-svg-icons";

let stompClient, SockJS;
const AnonymousChat = ({
  consumer,
  underwriting,
  anonymousChatDetail,
  commonApiCall,
  handleChatModel,
  isChat,
  t,
  showChat,
  ...props
}) => {
  const [image, setImage] = useState(null);
  const [preview, setPreview] = useState();
  const [loading, setLoading] = useState(false);
  const [footerData, setFooterData] = useState({
    isToggle: false,
    chatModel: isChat,
  });
  const [messageListing, setMessageListing] = useState({});
  const [unReadNotificationCount, setUnReadNotificationCount] = useState(
    underwriting && underwriting.anonymousChatDetail?.unreadMessageCount
      ? underwriting.anonymousChatDetail?.unreadMessageCount
      : 0
  );
  const currentChatRef = useRef(null);
  const [showMessage, setShowMessage] = useState(false);
  const [attachmentListing, setAttachmentListing] = useState([]);
  let isDatePrinted = [];

  const closeSidebar = () => {
    document.body.classList.remove("sidebar-open");
    setFooterData({ ...footerData, isToggle: false });
  };

  const handleModel = (name, isHide) => {
    if (isHide) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    setFormData({
      ...formData,
      totalRecords: 0,
      currentPage: 0,
    });
    setMessageListing({});
    setFooterData({ ...footerData, [name]: isHide });
    if (isChat) handleChatModel("isAnonymousChat", isHide);
  };

  const [formData, setFormData] = useState({
    totalRecords: 0,
    currentPage: 0,
    textMessage: "",
    textErr: "",
    channelId:
      anonymousChatDetail && anonymousChatDetail.channelId
        ? anonymousChatDetail.channelId
        : underwriting.anonymousChatDetail?.channelId,
    isChatLoader: true,
  });

  const [chatStatus, setChatStatus] = useState(underwriting.agentDetail.status);
  const connect = async (channelId) => {
    let clientId =
      consumer && consumer.id ? consumer.id : underwriting?.clientUserId;
    try {
      removeConnection();
      const { chatModel } = footerData;
      const Stomp = require("stompjs");
      SockJS = require("sockjs-client");
      SockJS = new SockJS(API_URL_CHATSERVICE + "ws");
      stompClient = Stomp.over(SockJS);
      stompClient.debug = null;
      let request = {
        type: chatModel
          ? "ANONYMOUS_CHATSCREEN"
          : "ANONYMOUS_CONVERSATION_SCREEN",
      };
      if (clientId) request.clientUserId = clientId;
      else request.applicationId = underwriting.applicationId;

      stompClient.connect(
        request,
        function () {
          if (chatModel) {
            getMessagesList(channelId);
            getAttachments(channelId);
          }
          onConnected(channelId);
        },
        function () {
          onError();
        }
      );
    } catch (err) {
      showErrorToast(t("validation_message.SOMETHING_WENT_WRONG"));
    }
  };

  const onConnected = (channelId) => {
    try {
      if (stompClient.connected) {
        const _channelId = channelId ? channelId : formData.channelId;
        //Subscribing to the private channel room
        stompClient.subscribe(
          "/user/" + _channelId + "/reply",
          function (responsePayload) {
            onMessageReceived(responsePayload);
          }
        );
        stompClient.subscribe(
          "/user/" + "client-" + underwriting.applicationId + "/chatStatus",
          function (responsePayload) {
            const payload = JSON.parse(responsePayload.body);
            setChatStatus(payload.status);
          }
        );
        // Registering user to server as a private chat user
        stompClient.send(
          "/addUser",
          {},
          JSON.stringify({ channelId: _channelId, type: "JOIN" })
        );
      }
    } catch (err) {
      showErrorToast(t("validation_message.SOMETHING_WENT_WRONG"));
    }
  };

  const onError = (error) => {
    consoleLog(
      t(
        "private_chat.could_not_connect_you_to_the_chat_room_server_please_refresh_this_page_and_try_again"
      )
    );
  };

  const sendMessage = (isEnqform, requestPayload) => {
    let clientId =
      consumer && consumer.id ? consumer.id : underwriting?.clientUserId;
    try {
      const { textMessage, channelId } = formData;

      if (stompClient) {
        const messageLength = isEnqform
          ? requestPayload.message.replace(/\s/g, "").length
          : textMessage.replace(/\s/g, "").length;
        if (messageLength) {
          let request = {
            message: isEnqform ? requestPayload.message : textMessage,
            agentUserId: "",
            channelId: channelId,
          };
          if (isEnqform) {
            request.clientEnquiryInfo = {
              name: requestPayload.name,
              email: requestPayload.email,
              mobile: requestPayload.mobile,
            };
          }
          if (clientId) request.clientUserId = clientId;
          else request.applicationId = underwriting.applicationId;
          const chatMessage = JSON.stringify(request);
          stompClient.send("/sendMessage", {}, chatMessage);
          setShowMessage(true);

          setFormData({ ...formData, textMessage: "" });
        } else {
          setFormData({ ...formData, textMessage: "" });
        }
      }
    } catch (err) {
      showErrorToast(t("validation_message.SOMETHING_WENT_WRONG"));
    }
  };

  const onMessageReceived = (payload) => {
    try {
      if (payload) {
        const message = JSON.parse(payload.body);
        if (message) {
          setChatStatus(message.anonymousStatus);
        }
        if (message) {
          const { chatModel } = footerData;
          if (chatModel) {
            let chatConversationArray = [];

            chatConversationArray.push({
              channelId: message.channelId,
              agentUserId: message.agentUserId,
              clientUserId: message.clientUserId,
              senderName: message.senderName,
              message: message.message ? message.message : "",
              carrierSupportDocUrl: message.carrierSupportDocUrl
                ? message.carrierSupportDocUrl
                : "",
              carrierSupportDocName: message.carrierSupportDocName
                ? message.carrierSupportDocName
                : "",
              uploadedDoc: message.uploadedDoc ? message.uploadedDoc : "",
              uploadedDocName: message.uploadedDocName
                ? message.uploadedDocName
                : "",
              createdDate: message.createdDate,
            });
            setMessageListing((prevMessageListing) => [
              ...prevMessageListing,
              ...chatConversationArray,
            ]);
            if (message.uploadedDoc || message.carrierSupportDocUrl) {
              let attachmentListArray = [];
              attachmentListArray.push({
                uploadedDoc: message.uploadedDoc
                  ? message.uploadedDoc
                  : message.carrierSupportDocUrl
                  ? message.carrierSupportDocUrl
                  : "",
                uploadedDocName: message.uploadedDocName
                  ? message.uploadedDocName
                  : message.carrierSupportDocName
                  ? message.carrierSupportDocName
                  : "",
              });
              setAttachmentListing((prevAttachmentListing) => {
                let returnAttachmentListArray = [];
                if (prevAttachmentListing.length >= 15) {
                  prevAttachmentListing.pop();
                  returnAttachmentListArray = [
                    ...attachmentListArray,
                    ...prevAttachmentListing,
                  ];
                } else {
                  returnAttachmentListArray = [
                    ...attachmentListArray,
                    ...prevAttachmentListing,
                  ];
                }
                return returnAttachmentListArray;
              });
            }
            scrollToEnd();
          } else {
            setUnReadNotificationCount(
              (prevUnReadNotificationCount) => prevUnReadNotificationCount + 1
            );
          }
        }
      }
    } catch (err) {
      showErrorToast(t("validation_message.SOMETHING_WENT_WRONG"));
    }
  };

  useEffect(() => {
    const _channelId =
      anonymousChatDetail && anonymousChatDetail.channelId
        ? anonymousChatDetail.channelId
        : underwriting.anonymousChatDetail?.channelId;
    connect(_channelId);
    if (footerData["chatModel"]) setUnReadNotificationCount(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [footerData["chatModel"]]);

  const getMessagesList = async (channelId) => {
    let clientId =
      consumer && consumer.id ? consumer.id : underwriting?.clientUserId;
    try {
      const _channelId = channelId ? channelId : formData.channelId;
      const request = {
        channelId: _channelId,
        pageNo: formData.currentPage,
        pageSize: 100,
      };

      if (clientId) request.clientUserId = clientId;
      else request.applicationId = underwriting.applicationId;
      const apiEndPoint = API_URL_CHATSERVICE + "getChatConversation";
      const isAuthorized = getJwt() ? true : false;
      const response = await commonApiCall(
        apiEndPoint,
        "post",
        request,
        false,
        isAuthorized
      );

      if (response.status.code === 200) {
        if (!_.isEmpty(messageListing)) {
          const conversationMessagesList = [
            ...response.data.chatConversation,
            ...messageListing,
          ];
          setMessageListing(conversationMessagesList);
        } else {
          setMessageListing(response.data.chatConversation);
        }
        setFormData({
          ...formData,
          totalRecords: response.data.totalRecord,
          currentPage: formData.currentPage + 1,
          channelId: _channelId,
          isChatLoader: false,
        });
        scrollToEnd();
      } else {
        showErrorToast(response.status.message);
      }
    } catch (err) {}
  };

  useEffect(() => {
    // create the preview
    if (image) {
      const objectUrl = URL.createObjectURL(image);
      setPreview(objectUrl);
      return () => URL.revokeObjectURL(objectUrl);
    }
  }, [image]);

  const renderIcon = () => {
    const name = image?.name?.split(".")[1];
    if (
      name == "jpg" ||
      name == "jpeg" ||
      name == "png" ||
      name == "JPG" ||
      name == "JPEG" ||
      name == "PNG"
    ) {
      return (
        <img width={50} height={50} className='img-fluid' src={preview}></img>
      );
    } else if (
      name == "xls" ||
      name === "xlsx" ||
      name == "XLS" ||
      name == "XLSX"
    ) {
      return (
        <FontAwesomeIcon className='font-24 flex-shrink-0' icon={faFileExcel} />
      );
    } else if (
      name === "doc" ||
      name === "docx" ||
      name === "DOC" ||
      name === "DOCX"
    ) {
      return (
        <FontAwesomeIcon className='font-24 flex-shrink-0' icon={faFileWord} />
      );
    } else if (name === "pdf" || name == "PDF") {
      return (
        <FontAwesomeIcon className='font-24 flex-shrink-0' icon={faFilePdf} />
      );
    } else {
      return (
        <FontAwesomeIcon className='font-24 flex-shrink-0' icon={faFileLines} />
      );
    }
  };

  const handleUpload = (e) => {
    const name = e.target.files[0]?.name?.split(".")[1];
    let fileSize = Math.round(e.target.files[0].size / 1024);
    let fileExtension = [
      "JPG",
      "jpg",
      "jpeg",
      "JPEG",
      "png",
      "PNG",
      "doc",
      "DOC",
      "DOCX",
      "docx",
      "pdf",
      "PDF",
      "ppt",
      "PPT",
      "pptx",
      "xls",
      "XLS",
      "XLSX",
      "xlsx",
    ];
    if (fileExtension.includes(name)) {
      if (!e.target.files[0]) {
        showErrorToast(t("validation_message.SELECT_FILE"));
      } else if (e.target.files[0] && fileSize > 10 * 1024) {
        showErrorToast(t("validation_message.SINGLE_FILE_VALIDATION"));
      } else {
        setImage(e.target.files[0]);
      }
    } else {
      showErrorToast("Please upload only .jpeg .jpg .pdf .doc .docx file");
    }
  };

  const sendAttachments = async () => {
    let clientId =
      consumer && consumer.id ? consumer.id : underwriting?.clientUserId;
    try {
      const { channelId } = formData;
      let renamedFile;
      const file = image;
      const splitName = file?.name?.split(".");
      const loweCase = splitName[1].toLowerCase();
      const finalString = splitName[0] + "." + loweCase;
      renamedFile = new File([file], finalString, { type: file.type });
      setLoading(true);
      let requestFormData = new FormData();
      //let attachmentFile = document.querySelector("#file");

      if (checkAllowedAttachment(renamedFile)) {
        requestFormData.append("document", renamedFile);
        requestFormData.append("agentUserId", "");
        if (clientId) requestFormData.append("clientUserId", clientId);
        else
          requestFormData.append("applicationId", underwriting.applicationId);
        requestFormData.append("channelId", channelId);
        const apiEndPoint = API_URL_CHATSERVICE + "fileUpload";

        const isAuthorized = getJwt() ? true : false;
        const response = await commonApiCall(
          apiEndPoint,
          "post",
          requestFormData,
          false,
          isAuthorized
        );
        // getMessagesList();
        if (response.status.code === 200) {
          formData.textMessage && sendMessage();
          let conversationList = [...messageListing, response.data];
          setFormData({
            ...formData,
            totalRecords: formData.totalRecords + 1,
            textMessage: "",
          });
          setMessageListing(conversationList);
          getAttachments(channelId);
          scrollToEnd();
          setImage(null);
        } else {
          showErrorToast(response.status.message);
          setImage(null);
        }
      } else {
        showErrorToast(t("validation_message.VALID_ATTACHMENT"));
        setImage(null);
      }
    } catch (err) {
      setImage(null);
    }
  };

  const getAttachments = async (channelId) => {
    try {
      const request = {};

      const apiEndPoint =
        API_URL_CHATSERVICE +
        "getDocuments?channelId=" +
        channelId +
        "&pageNo=0&pageSize=15";
      const isAuthorized = getJwt() ? true : false;
      const response = await commonApiCall(
        apiEndPoint,
        "post",
        request,
        false,
        isAuthorized
      );
      if (response && response.status.code === 200) {
        if (response.data && response.data.chatConversation) {
          setAttachmentListing(response.data.chatConversation);
        }
      }
    } catch (err) {
      showErrorToast(t("validation_message.SOMETHING_WENT_WRONG"));
    }
  };

  const handleKeyPress = (evt) => {
    if (evt.key === "Enter" && !evt.shiftKey) {
      if (evt.type === "keypress") {
        sendMessage();
      }
      evt.preventDefault();
    }
  };

  const checkAllowedAttachment = (fileVal) => {
    switch (fileVal.type) {
      case "application/msword":
        return true;

      case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        return true;

      case "application/pdf":
        return true;

      case "image/png":
        return true;

      case "image/jpeg":
        return true;

      case "image/jpg":
        return true;

      default:
        return false;
    }
  };

  const handleScroll = (container) => {
    const { totalRecords } = formData;
    if (messageListing.length === totalRecords) {
      return false;
    } else if (
      container.scrollTop === 0 &&
      messageListing.length <= totalRecords
    ) {
      getMessagesList();
    }
  };

  const scrollToEnd = () => {
    // const { currentPage } = formData;
    // let pageOffset = currentPage + 1;
    // const container = document.getElementById("chat-area");
    // container.getElementsByClassName("scrollbar-container")[0].scrollTop =
    //   container.getElementsByClassName("scrollbar-container")[0].scrollHeight /
    //   pageOffset;
    // const { currentPage } = formData;
    //   let pageOffset = currentPage + 1;
    //   const container = document.getElementById("chat-area");
    //   if (container !== null) {
    //     container.getElementsByClassName("scrollbar-container")[0].scrollTop =
    //       container.getElementsByClassName("scrollbar-container")[0]
    //         .scrollHeight / pageOffset;
    //   }
    // const scrollbar = messageEl.current;
    // if (scrollbar) {
    //   scrollbar.scrollTop = scrollbar.scrollHeight;
    // }
    // window.scrollTo(0, scrollbar.scrollHeight);
  };

  useEffect(() => {
    currentChatRef &&
      currentChatRef.current &&
      currentChatRef.current.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
  }, [messageListing]);

  useEffect(() => {
    return () => {
      removeConnection();
      document.body.classList.remove("modal-open");
    };
  }, []);

  const removeConnection = () => {
    if (stompClient && stompClient.connected) {
      stompClient.disconnect(function () {
        console.log("Disconnected. See you next time!");
      });
    }
  };

  const returnPopOver = (id, content) => {
    return (
      <Popover id={id}>
        <Popover.Content className='popover-content'>{content}</Popover.Content>
      </Popover>
    );
  };

  const { textMessage, isChatLoader, channelId } = formData;
  const { chatModel } = footerData;
  const { agentDetail } = underwriting;
  return (
    <div className='container pb-0'>
      {!isChat && !chatModel && (
        <Link
          to='#'
          title='Facebook'
          onClick={() => handleModel("chatModel", !chatModel)}
        >
          <span className='chat-icon' title='Chat'>
            {unReadNotificationCount !== 0 && (
              <span className='chat-counter'>{unReadNotificationCount}</span>
            )}
            <svg viewBox='0 0 32 30' version='1.1'>
              <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
                <g
                  transform='translate(-1832.000000, -820.000000)'
                  fill='#FFFFFF'
                  fillRule='nonzero'
                >
                  <g transform='translate(1816.000000, 803.000000)'>
                    <path d='M19.2,17.3333333 C17.44,17.3333333 16,18.7764409 16,20.5402391 L16,37.8462564 C16,38.5597929 16.861625,38.9181887 17.365625,38.413101 L20.8,34.9713151 L35.2,34.9713151 C36.96,34.9713151 38.4,33.5282075 38.4,31.7644093 L38.4,20.5402391 C38.4,18.7764409 36.96,17.3333333 35.2,17.3333333 L19.2,17.3333333 Z M41.6,25.3505978 L41.6,31.7644093 C41.6,35.3064367 38.7344,38.1782208 35.2,38.1782208 L25.6,38.1782208 L25.6,39.7816737 C25.6,41.5454719 27.04,42.9885795 28.8,42.9885795 L43.2,42.9885795 L46.6343744,46.4303654 C47.1383744,46.9354531 48,46.5770573 48,45.8635208 L48,28.5575035 C48,26.7937054 46.56,25.3505978 44.8,25.3505978 L41.6,25.3505978 Z'></path>
                  </g>
                </g>
              </g>
            </svg>
          </span>
        </Link>
      )}
      <div className='sidebar-overlay' onClick={closeSidebar}></div>
      <div
        className={
          chatModel === false
            ? "conversation-popup small-size"
            : "conversation-popup small-size active"
        }
      >
        <React.Fragment>
          <span>
            {stompClient && stompClient.connected && (
              <Link
                to='#'
                onClick={() => {
                  handleModel("chatModel", !chatModel);
                  setShowMessage(false);
                }}
                className='close-btn'
                title='Close'
              >
                <span className='material-icons'>close</span>
              </Link>
            )}
          </span>
          <div className='chat-screen'>
            <div className='converexp'>
              <div className='conexp-left'>
                <div className='chat-component-header'></div>
                <div className='chat-box-wrapper'>
                  <div className='card-outer p-0 mb-0'>
                    <div className='chat-box-header'>
                      <h3 className='mb-0'>
                        {t("private_chat.youre_chatting_with")}{" "}
                        <span>
                          {anonymousChatDetail
                            ? anonymousChatDetail?.senderDetail?.firstName +
                              " " +
                              anonymousChatDetail?.senderDetail?.lastName
                            : decryptme(agentDetail && agentDetail.firstName) +
                              " " +
                              decryptme(agentDetail && agentDetail.lastName)}
                        </span>
                      </h3>

                      {chatStatus === "OFF" && (
                        <SendMessage
                          onMessageReceived={showMessage}
                          sendMessage={sendMessage}
                        />
                      )}
                    </div>

                    {chatStatus !== "OFF" && (
                      <>
                        <div className='chat-box'>
                          {messageListing && messageListing.length <= 0 ? (
                            <h4 className='no-conve'>
                              {t("private_chat.no_conversations_found")}
                            </h4>
                          ) : null}
                          <ul className='chat-area' id='chat-area'>
                            {isChatLoader && <ChatLoader></ChatLoader>}

                            {/* <PerfectScrollbar
                              onScrollY={(e) => handleScroll(e)}
                            > */}
                            {Array.isArray(messageListing) &&
                              messageListing.map((message, key) => {
                                return (
                                  <React.Fragment key={key}>
                                    {_.includes(
                                      isDatePrinted,
                                      dateFormate(
                                        message.createdDate,
                                        "MM/DD/YYYY"
                                      )
                                    ) === false && (
                                      <React.Fragment>
                                        <li className='chat-msg-item chat-date'>
                                          <span className='chat-date-span'>
                                            {dateFormate(
                                              message.createdDate,
                                              "MM/DD/YYYY"
                                            )}
                                          </span>
                                        </li>
                                        <li className='d-none'>
                                          {isDatePrinted.push(
                                            dateFormate(
                                              message.createdDate,
                                              "MM/DD/YYYY"
                                            )
                                          )}
                                        </li>
                                      </React.Fragment>
                                    )}
                                    <li
                                      className={
                                        message.clientUserId === ""
                                          ? "chat-msg-item "
                                          : "chat-msg-item sent-msg"
                                      }
                                      ref={
                                        key === messageListing.length - 1
                                          ? currentChatRef
                                          : null
                                      }
                                    >
                                      <div
                                        className={
                                          message.uploadedDoc ||
                                          message.carrierSupportDocUrl
                                            ? "chat-msg no-padding"
                                            : "chat-msg"
                                        }
                                      >
                                        <p>
                                          {message.message
                                            ? message.message
                                            : ""}
                                        </p>
                                        <span className='msg-time'>
                                          {message.agentUserId !== "" && (
                                            <React.Fragment>
                                              <span>{message.senderName}</span>
                                              <br />
                                            </React.Fragment>
                                          )}
                                          {moment(message.createdDate).format(
                                            "hh:mm a"
                                          )}
                                        </span>
                                      </div>
                                      <div className='document document-row'>
                                        <div className='chat-image'>
                                          {message.uploadedDoc &&
                                            getAttchmentHtml(
                                              message.uploadedDoc
                                            )}
                                          {message.carrierSupportDocUrl &&
                                            getAttchmentHtml(
                                              message.carrierSupportDocUrl
                                            )}
                                        </div>
                                        <div className='document-name'>
                                          <span
                                            className='list-label'
                                            style={{ cursor: "pointer" }}
                                          >
                                            <OverlayTrigger
                                              trigger={["hover", "focus"]}
                                              overlay={returnPopOver(
                                                "popover-basic2",
                                                message.uploadedDocName
                                                  ? message.uploadedDocName
                                                  : message.carrierSupportDocName
                                                  ? message.carrierSupportDocName
                                                  : ""
                                              )}
                                            >
                                              <span>
                                                {message.uploadedDoc &&
                                                message.uploadedDocName &&
                                                getAttachmentName(
                                                  message.uploadedDoc
                                                )
                                                  ? truncate(
                                                      message.uploadedDocName,
                                                      15
                                                    )
                                                  : ""}
                                                {message.carrierSupportDocUrl &&
                                                  message.carrierSupportDocName && (
                                                    <p>
                                                      {getAttachmentName(
                                                        message.carrierSupportDocUrl
                                                      )
                                                        ? truncate(
                                                            message.carrierSupportDocName,
                                                            15
                                                          )
                                                        : ""}
                                                    </p>
                                                  )}
                                              </span>
                                            </OverlayTrigger>
                                          </span>
                                        </div>
                                      </div>
                                    </li>
                                  </React.Fragment>
                                );
                              })}
                            {/* {chatStatus === "OFF" && (
                            <li className={"chat-msg-item "}>
                              <p>
                                {anonymousChatDetail
                                  ? anonymousChatDetail?.senderDetail
                                      ?.firstName +
                                    " " +
                                    anonymousChatDetail?.senderDetail?.lastName
                                  : decryptme(
                                      agentDetail && agentDetail.firstName
                                    ) +
                                    " " +
                                    decryptme(
                                      agentDetail && agentDetail.lastName
                                    )}{" "}
                                is offline now, and will answer your questions
                                as soon as comes back!
                              </p>
                            </li>
                          )} */}
                            {/* </PerfectScrollbar> */}
                          </ul>
                        </div>
                        <div className='chat-buttons'>
                          {image && (
                            <>
                              <div className='white-box p-2 border mb-2 d-flex'>
                                {renderIcon()}
                                <span className='ml-2 line-count'>
                                  {image.name}
                                </span>
                                <span
                                  onClick={() => setImage(null)}
                                  class='material-icons cursor-pointer ml-auto'
                                >
                                  close
                                </span>
                              </div>
                            </>
                          )}
                          <div className='chat-button-inner'>
                            <div className='form-group mb-0 chat-input'>
                              <textarea
                                type='text'
                                name='textmsg'
                                value={textMessage}
                                onChange={(e) =>
                                  setFormData({
                                    ...formData,
                                    textMessage: e.target.value,
                                    textErr: "",
                                  })
                                }
                                onKeyPress={(e) => handleKeyPress(e)}
                                placeholder={t(
                                  "private_chat.type_message_here"
                                )}
                                className='form-control'
                                disabled={isChatLoader ? true : false}
                              />
                              <div className='choose-file'>
                                <input
                                  className='profile-input'
                                  type='file'
                                  style={{ display: "block" }}
                                  name='image'
                                  value={""}
                                  id='file'
                                  accept='.xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf'
                                  onChange={(e) => handleUpload(e)}
                                />
                                <label
                                  className='material-icons add-file'
                                  htmlFor='file'
                                >
                                  add_photo_alternate
                                </label>
                              </div>
                            </div>
                            <div className='form-group mb-0'>
                              <Button
                                type='button'
                                value='Submit'
                                className='btn-sm'
                                disabled={loading}
                                onClick={() =>
                                  image ? sendAttachments() : sendMessage()
                                }
                              >
                                {t("private_chat.send")}
                              </Button>
                            </div>
                          </div>
                          <span className='text-danger '>
                            {formData.textErr}
                          </span>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      </div>
      <div
        className={chatModel === false ? "conv-overlay" : "conv-overlay active"}
      ></div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  consumer: state.consumer.consumerdetails,
  underwriting: state.underwriting.underwriting,
});
export default connect(mapStateToProps, { commonApiCall })(AnonymousChat);
