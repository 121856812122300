export const UnderWritingRoutes = {
  LOGIN_EMAIL: "/login-email",
  LOGIN: "/login",
  REGISTER: "/register",
  LOGIN_EMAIL_MANUALFORM: "/login-email/myplans",
  LOGIN_MANUALFORM: "/login/myplans",
  REGISTER_MANUALFORM: "/register/myplans",
  HOME: "/",
  GET_START: "/get-start",
  INVESTMENT_TYPE: "/investment-type",
  POLICY_TERM: "/policy-term",
  INSURE_TYPE: "/insure-type",
  INSURENCE: "/insurance",
  TERM: "/term",
  PERMANENT: "/permanent",
  FINAL_EXPENSE: "/final-expense",
  FINANCIAL: "/financial",
  FINACIAL: "/finacial",
  DIVIDEND: "/dividend",
  INDEX: "/index",
  YOUR_FREQUENCY: "/your-frequency",
  POLICY_TYPE: "/policy-type",
  YOUR_DEPOSIT: "/your-deposit",
  YOUR_GENDER: "/your-gender",
  YOUR_AGE: "/your-age",
  YOUR_HEALTH: "/your-health",
  SOMKING_HABIT: "/smoking-habit",
  MEDICARE_ENROLL: "/medicare-enroll",
  MEDICARE: "/medicare",
  MEDICARE_PLAN_LIST: "/medicare-plan-lists",
  PLANS: "/plans",
  MANUAL_FORM: "/manual-form",
  CLIENT_FORM: "/client-form",
  FACT_FINDER: "/fact-finder",
  FACT_FINDER_2: "/fact-finder2",
  FACT_FINDER_3: "/fact-finder3",
  INFORMATION_OPTION: "/information-option",
  APPLICATIONLOGIN: "/applicationLogin",
  LOGIN_AGENT_CHAT: "/login-agent-chat",
  AFFILIATE_BANNER_FLOW: "/bannerLife-decision",
  GUEST_BASIC_DETAILS: "/user-details",
  UNDERWRITING_WELCOME: "/welcome",
  ZIPCODE: "/zipcode",
  CITIZENSHIP: "/citizenship",
  SINGLE_PAGE: "/single-page",
  SBLI_PAGE: "/sbli-page",
  SBLI_USER_DETAIL: "/sbli-user-detail",
  SBLI_TERMS: "/sbli-terms",
  SBLI_THIRD_PARTY: "/sbli-third-party",
  SBLI_COVERAGE: "/sbli-coverage",
  SBLI_DOC: "/sbli-document",
  SBLI_PAY: "/sbli-payment",
  SBLI_CONGRATULATIONS: "/sbli-congratulations",
  BENEFICIARY: "/beneficiary",
  PAYMENT: "/payment",
  SBLI_DENIAL: "/not-found",
  ASSURITY_DESIGN_1: "/assurity-design-1",
  ASSURITY_DESIGN_2: "/assurity-design-2",
  ASSURITY_DESIGN_3: "/assurity-design-3",
  ASSURITY_USER_DETAIL: "/assurity-user-detail",
  ASSURITY_PRE_APPROVAL: "/assurity-pre-approval",
  ASSURITY_PRE_DENIED: "/assurity-deny",
  ASSURITY_LAYOUT: "/assurity",
  PRE_QUESTIONS: "/pre-questions",
  ASSURITY_UNDERWRITING: "/assurity-underwriting",
  ASSURITY_BENE: "/assurity-beneficiary",
  ASSURITY_PAY: "/assurity-payment",
  ASSURITY_NOTICE: "/assurity-notice",
  ASSURITY_PREVIEW: "/assurity-preview",
  ASSURITY_PHYSICIAN_REQUEST: "/assurity-physician-request",
  ASSURITY_DOCUMENTS: "/assurity-documents",
  ASSURITY_WITH_CHANGES: "/assurity-changes",
  ASSURITY_LIMITATION: "/assurity-limitation",
  ASSURITY_POLICY: "/assurity-policy",
  ASSURITY_POLICY_DECLINE: "/assurity-policy-decline",
  ASSURITY_TOKEN_DOMAIN: "/tokenGenerate",
};

export const CmsPageRoutes = {
  ABOUT_US: "/about-us",
  CONTACT_US: "/contact-us",
  FAQ: "/faq",
  NOTFOUND: "/404",
};

export const DashboardRoute = {
  DASHBOARD: "/dashboard",
  MYPROFILE: "/myProfile",
  MYPLANS: "/myPlans",
  UNDERPROCESS: "/underProcess",
  NOTIFICATIONS: "/notifications",
  PLOICYINFORMATION: "/policyInformation",
  POLICYDOCUMENTS: "/policydocuments",
  CONVERSATIONS: "/conversations",
  BASICDETAILSDASHBOARD: "/basicDetailDashboard",
  CONVERSATION_DOCUMENTS: "/conversation-documents",
  HELP: "/help",
  SETTING: "/setting",
};

export const ApprovalStatus = {
  APPLICATION_STATUS: "/application-status",
  APPROVAL_PROCESS: "/approval-process",
  THANKYOU: "/thank-you",
};
