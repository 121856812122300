import React, { useEffect, useState } from "react";
import CustomFormBuilder from "./custom-form-builder";
import afficiencyQA from "../underWriting/afficiencyQA.json";
import { API_URL_UNDERWRITING } from "../../config/configs";
import {
  setProcessStep,
  showErrorToast,
  UnderWritingRoutes,
} from "../../utils";
import {
  commonApiCall,
  getJwt,
  setArcId,
  setSbliProgressCount,
  UNDERWRITING_DETAILS,
} from "../../redux/actions";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

const SBLIForm = ({
  commonApiCall,
  underwriting,
  setSbliProgressCount,
  setArcId,
  ...props
}) => {
  const { applicationId, applicationType, goalType, policyType } = underwriting;
  const [answers, setAnswers] = useState([]);
  const [questionData, setQuestionData] = useState(null);
  const [questionLoading, setQuestionLoading] = useState(false);
  const [loading, setLoading] = useState(false);

  const getQuestions = async () => {
    try {
      setQuestionLoading(true);
      const response = await commonApiCall(
        `${API_URL_UNDERWRITING}getappq?applicationId=${applicationId}`, //underwriting?.applicationId
        "get",
        {},
        ""
      );

      if (response.status.code === 200) {
        if (response.data.appQJson) {
          let data = response.data;
          data.appQJson = JSON.parse(data.appQJson);
          data.appQJson = JSON.parse(data.appQJson[0].uwqs);
          setArcId(data.arcId);
          setQuestionData(data);
        } else {
          setQuestionData(null);
        }
      } else {
        showErrorToast(response.status.message);
      }
      setQuestionLoading(false);
    } catch (err) {
      console.log(err);
      showErrorToast("something went wrong");
    }
  };

  const submitForm = async () => {
    setLoading(true);
    try {
      const request = {
        applicationId: applicationId,
        answers: answers,
      };
      const isAuthorized = getJwt() ? true : false;
      const apiEndPoint = API_URL_UNDERWRITING + "submitAPPQA";
      const response = await commonApiCall(
        apiEndPoint,
        "post",
        request,
        null,
        isAuthorized
      );
      if (
        response.status.code === 200 ||
        response.data.msgCode === "ARER-10013"
      ) {
        if (response.data.statusCode !== 200) {
          if (response.data.msgCode === "ARER-10013") {
            saveUnderwriting();
          } else {
            showErrorToast(response.data.msgCode);
            setLoading(false);
          }
        } else {
          saveUnderwriting();
        }
        // if (
        //   response.data.statusCode !== 200 &&
        //   response.data.msgCode !== "ARER-10013"
        // ) {
        //   showErrorToast(response.data.msgCode);
        //   setLoading(false);
        // } else {
        //   saveUnderwriting();
        // }
      } else {
        setLoading(false);
        showErrorToast(response.status.message);
      }
    } catch (err) {
      setLoading(false);
      showErrorToast("something went wrong");
    }
  };

  const saveUnderwriting = async () => {
    try {
      const path = window.location.pathname;
      const request = {
        applicationId: applicationId,
        applicationType: applicationType,
        goalType: goalType,
        resumeProcess: setProcessStep(path, policyType, goalType, "online"),
      };

      const isAuthorized = getJwt() ? true : false;
      const apiEndPoint = API_URL_UNDERWRITING + "saveUnderWriting";
      const response = await commonApiCall(
        apiEndPoint,
        "post",
        request,
        UNDERWRITING_DETAILS,
        isAuthorized
      );
      if (response.status.code === 200) {
        props.history.push(UnderWritingRoutes.SBLI_USER_DETAIL);
        // if (response?.data?.nextStep === "UWQ") {
        //   props.history.push(UnderWritingRoutes.SINGLE_PAGE);
        // } else if (response?.data?.nextStep === "BENE") {
        //   onPageComplete();
        // } else {
        //   props.history.push(ApprovalStatus.APPLICATION_STATUS);
        // }
      } else {
        showErrorToast(response.status.message);
      }
    } catch (err) {
      console.log(err);
      showErrorToast("Something went wrong");
    }
  };

  useEffect(() => {
    setSbliProgressCount(0);
    getQuestions();
  }, []);

  const handleAnswerChange = (ans) => {
    setAnswers(ans);
  };

  return (
    <div className="sbli-main-question mt-5">
      {questionLoading ? (
        <h2 className="text-center"> Please wait while we're fetching data</h2>
      ) : (
        <CustomFormBuilder
          answers={answers}
          onSubmit={submitForm}
          question_data={questionData ? questionData.appQJson : []} //questionData ? questionData.appQJson : []
          onAnswerChange={handleAnswerChange}
          loading={loading}
        />
      )}
    </div>
  );
};
const mapStateToProps = (state) => ({
  underwriting: state.underwriting.underwriting,
});

export default connect(mapStateToProps, {
  commonApiCall,
  setArcId,
  setSbliProgressCount,
})(withRouter(SBLIForm));
