import React from "react";
import { withRouter, Link } from "react-router-dom";
import { Animated } from "react-animated-css";
import {
  UnderWritingRoutes,
  setProcessStep,
  showErrorToast,
  segmentTracking,
} from "../../utils/index";
import {
  UNDERWRITING_DETAILS,
  commonApiCall,
  getJwt,
} from "../../redux/actions";
import { API_URL_UNDERWRITING } from "../../config/configs";
import { connect } from "react-redux";
import AgentBannerImg from "../../assets/images/Agent.jpeg";
import ApplyOnlineBanner from "../../assets/images/Apply-online.jpeg";

const AffiliateBannerLifePage = ({
  underwriting,
  applicationCarrierInfo,
  commonApiCall,
  t,
  ...props
}) => {
  const { applicationId, applicationType, goalType, policyType } =
    underwriting !== null;

  return (
    <React.Fragment>
      <div className="modal-part option-selection">
        <div className="title-header">
          <h2 className="mb-5 pb-md-3"> How would you like to proceed?</h2>
        </div>

        <div className="row justify-content-center">
          <div className=" col-sm-6">
            <Animated
              animationIn="fadeInRight"
              animationOut="zoomOut"
              animationInDuration={800}
              animationOutDuration={800}
              isVisible={true}
            >
              <div className="process">
                <div className="img-block blue-shadow ">
                  <img width="1287" height="858" src={ApplyOnlineBanner} />
                </div>
                <p className="process-desc">
                  Apply online and get a fast decision on your life <br />{" "}
                  insurance policy.
                </p>

                <button className="btn btn-gradient btn-lg">
                  Apply Online
                </button>
              </div>
            </Animated>
          </div>
          <div className="col-sm-6">
            <Animated
              animationIn="fadeInRight"
              animationOut="zoomOut"
              animationInDuration={800}
              animationOutDuration={800}
              isVisible={true}
            >
              <div className="process">
                <div className="img-block green-shadow ">
                  <img width="1287" height="858" src={AgentBannerImg} alt="" />
                </div>
                <p className="process-desc">
                  Get free advice from a licensed agent and personal <br />
                  support with your next steps.
                </p>

                <button className="btn btn-gradient btn-lg">
                  Work With an Agent
                </button>
              </div>
            </Animated>
          </div>
          <div className="col-12 text-center mt-3">
            <Link
              to={UnderWritingRoutes.PLANS}
              className="btn btn-link"
              title="Back"
            >
              {t("back")}
            </Link>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
const mapStateToProps = (state) => ({
  underwriting: state.underwriting.underwriting,
  applicationCarrierInfo: state.underwriting.applicationCarrierInfo,
});

export default connect(mapStateToProps, {
  commonApiCall,
})(withRouter(AffiliateBannerLifePage));
