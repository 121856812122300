import axios from "axios";
import { setItem, UnderWritingRoutes } from "../../utils";

axios.interceptors.request.use((config) => {
  return config;
});

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const path =
      window.location.pathname === UnderWritingRoutes.GET_START
        ? window.location.pathname + window.location.search
        : window.location.pathname;
    if (error && error.response && 400 === error.response.status) {
      if (error.response !== "") return Promise.resolve(error.response);
    } else if (error && error.response && 404 === error.response.status) {
      if (error.response !== "") return Promise.resolve(error.response);
    } else if (error && error.response && 500 === error.response.status) {
      if (error.response !== "") return Promise.resolve(error.response);
    } else if (error && error.response && 403 === error.response.status) {
      if (error.response !== "") return Promise.resolve(error.response);
    } else if (error && error.response && 401 === error.response.status) {
      setItem("lastScreen", path);
      return Promise.resolve(error.response);
    } else {
      return Promise.reject(error);
    }
  }
);

axios.defaults.headers.common["Vendortoken"] = "ilifein1";

export const setJwtAuthorization = (jwt) => {
  axios.defaults.headers.common["Authorization"] = "Bearer " + jwt;
};

export const removeJwtAuthorization = () => {
  delete axios.defaults.headers.common["Authorization"];
};

export const setMultipart = () => {
  axios.defaults.headers.common["Type"] = "multipart/form-data";
};

export default {
  axios: axios,
  setJwtAuthorization,
  removeJwtAuthorization,
  setMultipart,
};
