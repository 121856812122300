import React from "react";
import { useHistory } from "react-router-dom";
import { getJwt } from "../redux/actions";
import { DashboardRoute } from "../utils";
import NavBar from "../components/navBar";

const NotFound = ({ t, ...props }) => {
  const history = useHistory();
  const authToken = getJwt();

  return (
    <React.Fragment>
      <div className={authToken && "row"}>
        {authToken && (
          <div className="col-3 sidebar-part">
            <NavBar t={t}></NavBar>
          </div>
        )}

        <div
          className={`error-page position-static mt-0 ${
            authToken && "col-lg-9"
          }`}
        >
          <div className="content">
            <div className="position-relative overflow-visible ">
              <img
                alt=""
                className="img-fluid mb-3 mt-md-5 py-5"
                width={415}
                height={265}
                src="/assets/images/404Graphic-NoShadow.png"
              />
            </div>
            <h2 className="mb-3 font-28"> {t("not_found.page_not_found")}</h2>
            <p className="txt-gray font-20 font-weight-medium">
              {t("not_found.page_not_found_page_description")}
            </p>
            {authToken && (
              <div className="btns btn-blue btn-sm-radius ">
                <button onClick={() => history.push(DashboardRoute.DASHBOARD)}>
                  {t("not_found.go_back")}
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default NotFound;
