import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import {} from "react-bootstrap";
import { Animated } from "react-animated-css";
import Slider from "react-rangeslider";
import NumberFormat from "react-number-format";
import { isEmpty } from "lodash";

import {
  commonApiCall,
  UNDERWRITING_DETAILS,
  getJwt,
  validationMessages,
} from "../../../redux/actions/index";
import {
  setProcessStep,
  getProcessStep,
  getSliderVariables,
  UnderWritingRoutes,
  showErrorToast,
  segmentTracking,
} from "../../../utils/index";
import {
  API_URL_UNDERWRITING,
  CONSUMER_PORTAL_URL,
} from "../../../config/configs";
import {} from "../../../redux/actions/validationMessages";

const Deposit = ({
  commonApiCall,
  underwriting,
  company_information,
  direct_link,
  t,
  ...props
}) => {
  // if(underwriting){
  //   const {
  //     applicationId,
  //     applicationType,
  //     goalType,
  //     insureBasedOn,
  //     policyType,
  //     frequency,
  //     amount,
  //   } = underwriting;
  // }

  useEffect(() => {
    if (!underwriting?.applicationId || !underwriting?.goalType) {
      window.location.replace(CONSUMER_PORTAL_URL + "404");
    }

    if (underwriting && !isEmpty(underwriting.carrierWiseLeads)) {
      const whereToRoute = getProcessStep(
        underwriting.resumeProcess,
        underwriting.goalType,
        underwriting.policyType,
        underwriting.productSelection
      );
      props.history.push(whereToRoute);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const slideLabels =
    underwriting?.goalType === "INSURE"
      ? getSliderVariables(
          underwriting?.goalType,
          underwriting?.amount,
          underwriting?.policyType
        )
      : getSliderVariables(
          underwriting?.frequency,
          underwriting?.amount,
          underwriting?.policyType
        );

  const [formData, setFormData] = useState({
    goalType: underwriting?.goalType,
    applicationId: underwriting?.applicationId,
    depositAmount: slideLabels.defaultValue,
    applicationType: underwriting?.applicationType
      ? underwriting?.applicationType
      : "Web",
    value_type: true,
  });

  const horizontalLabels = slideLabels.labels;

  const doSubmit = async () => {
    try {
      const { depositAmount, applicationId, applicationType, goalType } =
        formData;
      if (depositAmount) {
        const splitpath = window.location.pathname.split("/");
        const request = {
          applicationId: applicationId
            ? applicationId
            : underwriting?.applicationId,
          goalType: goalType ? goalType : underwriting?.goalType,
          applicationType: applicationType,
          amount: depositAmount,
          resumeProcess: setProcessStep(
            splitpath[1],
            underwriting?.policyType,
            underwriting?.goalType
          ),
        };

        const isAuthorized = getJwt() ? true : false;
        const apiEndPoint = API_URL_UNDERWRITING + "saveUnderWriting";
        const response = await commonApiCall(
          apiEndPoint,
          "post",
          request,
          UNDERWRITING_DETAILS,
          isAuthorized
        );
        if (response.status.code === 200) {
          const eventObject = {
            agent_id: response?.data?.agentDetail?.agentId,
            application_id: applicationId,
            param_name: "Premium Payment",
            param_value: response.data.amount,
          };
          segmentTracking("application_set_param", eventObject);
          if (
            underwriting?.goalType == "INSURE" &&
            underwriting?.policyType == "TERM_LIFE_INSURANCE"
          ) {
            props.history.push(UnderWritingRoutes.POLICY_TERM);
          } else {
            props.history.push(UnderWritingRoutes.PLANS);
          }
        } else {
          showErrorToast(response.status.message);
        }
        return;
      }
    } catch (err) {
      showErrorToast(t("validation_message.SOMETHING_WENT_WRONG"));
    }
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleFormData = (depositAmount, valueType) => {
    setFormData({
      ...formData,
      value_type: valueType,
      depositAmount: depositAmount,
    });
  };

  const handleValue = () => {
    const whichAmount =
      formData.depositAmount < slideLabels.min
        ? slideLabels.defaultValue
        : formData.depositAmount;

    setFormData({ ...formData, value_type: true, depositAmount: whichAmount });
  };

  const { depositAmount, value_type } = formData;
  const dollar = (value) => "$" + Number(value).toLocaleString();
  return (
    <React.Fragment>
      <div className="steps">
        <div className="title-header">
          <h2 className="page-title">
            {underwriting?.goalType === "INSURE" ? (
              <React.Fragment>
                {t("your_deposit.how_much_insurance_coverage_would_you_like")}
              </React.Fragment>
            ) : (
              <React.Fragment>
                {t("your_deposit.what_is_your_desired_premium_payment_amount")}
              </React.Fragment>
            )}
          </h2>
          <h4>
            {underwriting?.goalType === "INSURE" &&
            underwriting?.policyType === "PERMANENT_LIFE_INSURANCE" ? (
              <React.Fragment>
                {t("your_deposit.permanent_life_insurance_subtitle_1")}
                <br />
                {t("your_deposit.permanent_life_insurance_subtitle_2")}
              </React.Fragment>
            ) : (
              <React.Fragment>
                {underwriting?.goalType === "INSURE" &&
                underwriting?.policyType === "TERM_LIFE_INSURANCE" ? (
                  <React.Fragment>
                    {t("your_deposit.term_life_insurance_subtitle_1")}
                    <br />
                    {t("your_deposit.term_life_insurance_subtitle_2")}
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    {underwriting?.goalType === "INSURE" &&
                    underwriting?.policyType === "FINAL_EXPENSE_INSURANCE" ? (
                      <React.Fragment>
                        {t("your_deposit.final_expense_subtitle")}
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        {t(
                          "your_deposit.your_premium_is_the_amount_you_pay_and_contribute_monthly_for_insurance"
                        )}
                        <br />
                        {t(
                          "your_deposit.in_cash_value_accumulation_plans_your_contribution_amount_will_be_reflected_in_cash_values"
                        )}
                      </React.Fragment>
                    )}
                  </React.Fragment>
                )}
              </React.Fragment>
            )}
          </h4>
        </div>
        <div className="row justify-content-center">
          <div className="col-12">
            <Animated
              animationIn="fadeInRight"
              animationOut="zoomOut"
              animationInDuration={800}
              animationOutDuration={800}
              isVisible={true}
            >
              <div className="white-box">
                <div className="custom-slider">
                  {/* {underwriting?.goalType === "INSURE" ? (
                    <div className="value">
                      <h2>{dollar(depositAmount)}</h2>
                    </div>
                  ) : (

                  )} */}
                  <div className="value">
                    {value_type ? (
                      <h2>
                        {dollar(depositAmount)}
                        <Link
                          to="#"
                          className="edit-icon material-icons"
                          title="Edit"
                          onClick={() => handleFormData(depositAmount, false)}
                        >
                          edit
                        </Link>
                        {underwriting?.frequency && (
                          <span>
                            {underwriting?.frequency.charAt(0).toUpperCase() +
                              underwriting?.frequency.slice(1).toLowerCase()}
                          </span>
                        )}
                      </h2>
                    ) : (
                      <h2>
                        <NumberFormat
                          autoFocus
                          prefix={"$"}
                          thousandSeparator={true}
                          name="number"
                          id="depositAmount"
                          className="form-control"
                          inputMode="numeric"
                          allowNegative={false}
                          value={depositAmount}
                          isAllowed={(values) => {
                            const { formattedValue, floatValue } = values;
                            return (
                              formattedValue === "" ||
                              floatValue <= slideLabels.max
                            );
                          }}
                          onBlur={(e) => handleValue()}
                          onValueChange={(e) =>
                            handleChange({
                              target: {
                                name: "depositAmount",
                                value: e.value,
                              },
                            })
                          }
                        />
                        {underwriting?.frequency && (
                          <span>
                            {underwriting?.frequency.charAt(0).toUpperCase() +
                              underwriting?.frequency.slice(1).toLowerCase()}
                          </span>
                        )}
                      </h2>
                    )}
                  </div>
                  <Slider
                    min={slideLabels.min}
                    max={slideLabels.max}
                    step={slideLabels.stepAmount ? slideLabels.stepAmount : 1}
                    value={Number(depositAmount)}
                    labels={horizontalLabels}
                    format={dollar}
                    handleLabel={String(depositAmount)}
                    onChange={(e) =>
                      handleChange({
                        target: { name: "depositAmount", value: e },
                      })
                    }
                    onBlur={() => () => handleFormData(depositAmount, true)}
                    className="deposit-range-slider"
                    tooltip={false}
                  />
                </div>
              </div>
            </Animated>
          </div>

          <div className="col-12 text-center my-3">
            <button
              className="btn btn-primary"
              type="button"
              onClick={doSubmit}
            >
              {t("next")}
            </button>
          </div>

          <div className="col-12 text-center mt-3">
            {underwriting?.goalType === "SAVE" ||
            underwriting?.goalType === "INVEST" ? (
              <Link
                to={UnderWritingRoutes.YOUR_FREQUENCY}
                className="btn btn-link"
              >
                {t("back")}
              </Link>
            ) : (
              <Link to={UnderWritingRoutes.ZIPCODE} className="btn btn-link">
                {t("back")}
              </Link>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  underwriting: state.underwriting.underwriting,
  company_information: state.underwriting.companyInformation,
  direct_link: state.underwriting.direct_link,
});

export default connect(mapStateToProps, { commonApiCall })(withRouter(Deposit));
