import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { showErrorToast, UnderWritingRoutes } from "../../../utils";
import bgCongrats from "../../../assets/images/bg-congratulation.png";
import { API_URL_UNDERWRITING } from "../../../config/configs";
import {
  commonApiCall,
  getJwt,
  UNDERWRITING_DETAILS,
} from "../../../redux/actions";

const AssurityWithChanges = ({ underwriting, commonApiCall, ...props }) => {
  const dollar = (value) => "$" + Number(value).toLocaleString();
  const getChanges = new URLSearchParams(props.location.search).get("status");

  const saveUnderwriteDetails = async () => {
    try {
      const request = {
        applicationId: underwriting?.applicationId,
        applicationType: "Web",
        nextStep: "24,ASSURITY_BENE",
      };

      const isAuthorized = getJwt() ? true : false;
      const apiEndPoint = API_URL_UNDERWRITING + "saveUnderWriting";
      const response = await commonApiCall(
        apiEndPoint,
        "post",
        request,
        UNDERWRITING_DETAILS,
        isAuthorized
      );

      if (response.status.code === 200) {
        props.history.push(UnderWritingRoutes.ASSURITY_BENE);
      }
    } catch (err) {
      showErrorToast(err);
    }
  };

  useEffect(() => {
    document.getElementsByClassName("page-wrapper")[0].style.padding = "120px";
  }, []);

  return (
    <div className="assurity">
      <div className="container-fluid ">
        <div className="heading-block">
          <h2 className="font-weight-bold">
            {getChanges !== "approved"
              ? "Approved with Changes"
              : "Congratulations!"}
          </h2>
        </div>
        <div className="pre-bg">
          <div className="pre-approval-box">
            <div className="pre-approval-bg">
              <span>Benefit Amount</span>
              <h2 className="font-weight-bold">
                {underwriting?.offerCoverage
                  ? dollar(underwriting?.offerCoverage)
                  : dollar(underwriting?.amount)}
              </h2>
            </div>
            <div className="">
              <h2>
                <b>
                  $
                  {
                    String(
                      underwriting?.offerPremium
                        ? underwriting?.offerPremium.toFixed(2)
                        : underwriting?.premium.toFixed(2)
                    ).split(".")[0]
                  }
                  .
                </b>
                <sup>
                  <b>
                    {
                      String(
                        underwriting?.offerPremium
                        ? underwriting?.offerPremium.toFixed(2)
                        : underwriting?.premium.toFixed(2)
                      ).split(".")[1] ?? "00"
                    }
                  </b>{" "}
                  Monthly
                </sup>
              </h2>
            </div>
          </div>
        </div>
        <p className="mt-4 text-center">
          {getChanges !== "approved"
            ? `Based on the preliminary information provided, the coverage changes
          outlined below are required to get your application on a path for
          approval. Additional third-party data will be gathered upon
          application submission to make a final decision.`
            : `Based on the preliminary information provided, it looks like your application is on a path for approval. Additional third-party data will be gathered upon application submission to make a final decision.`}
        </p>
        {getChanges !== "approved" && (
          <p className="text-center">
            The risk class has been adjusted to{" "}
            {
              JSON.parse(localStorage.getItem("persist::ass::bene")).product
                ?.primaryInsured?.person?.riskClass?.underwritingValue
            }
          </p>
        )}
        <div className="w-100 my-4 text-center">
          <button
            className="btn btn-default px-5"
            style={{ textTransform: "none" }}
            onClick={() => saveUnderwriteDetails()}
          >
            Accept and Proceed
          </button>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  underwriting: state.underwriting.underwriting,
});
export default connect(mapStateToProps, {
  commonApiCall,
})(withRouter(AssurityWithChanges));
