import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { Animated } from "react-animated-css";
import NumberFormat from "react-number-format";
import { isEmpty } from "lodash";
import { Form } from "react-bootstrap";
import {
  commonApiCall,
  CONSUMER_DETAILS,
  profilePicture,
  setJwt,
  getJwt,
  logout,
} from "../redux/actions/index";
import {
  encryptme,
  validateMobileNumber,
  validateOtp,
  decryptme,
  UnderWritingRoutes,
  ApprovalStatus,
  DashboardRoute,
  requireAuth,
  showErrorToast,
  showSuccessToast,
  getItem,
  setItem,
  removeItem,
  segmentTracking,
} from "../utils/index";
import { UseInterval } from "../hooks/index";
import { API_URL_CLIENT, API_URL_UNDERWRITING } from "../config/configs";

function Login({
  commonApiCall,
  underwriting,
  applicationCarrierInfo,
  profilePicture,
  company_information,
  t,
  logout,
  ...props
}) {
  let splitpath = new URLSearchParams(window.location.search).get("mobile");
  let redirectPath = new URLSearchParams(window.location.search).get(
    "redirect"
  );
  const path = window.location.pathname;
  const router = props.match.params;
  const [formData, setFormData] = useState({
    mobileNumber: "",
    verificationCode: "",
    isHavingTrblVisable: false,
    counter: 0,
    errors: {},
    havingTrouble: false,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isMobileEntered, setIsMobileEntered] = useState(false);

  if (requireAuth() && !isEmpty(router.name)) {
    props.history.push(UnderWritingRoutes.PLANS);
  } else {
    if (requireAuth() && redirectPath) {
      props.history.push(DashboardRoute[redirectPath]);
    } else if (requireAuth() && !splitpath) {
      props.history.push(UnderWritingRoutes.HOME);
    }
  }

  UseInterval(
    () => {
      setFormData({ ...formData, counter: formData.counter - 1 });
    },
    formData.counter > 0 ? 1000 : null
  );

  const handleChange = (e) => {
    if (e.target.value) {
      setFormData({
        ...formData,
        errors: Object.assign(formData.errors, { [e.target.name]: "" }),
      });
    }
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const sendOtp = async (e) => {
    e.preventDefault();
    const { mobileNumber } = formData;
    if (validation(false)) {
      const request = {
        countryCode: await encryptme("+1"),
        mobile: await encryptme(mobileNumber),
        role: "ROLE_CLIENT",
      };
      const apiEndPoint = API_URL_CLIENT + "sendOtpViaSms";
      const response = await commonApiCall(apiEndPoint, "post", request, null);
      if (response.status.code === 200) {
        setFormData({
          ...formData,
          isHavingTrblVisable: true,
          counter: 30,
          havingTrouble: true,
        });
        if (response.data) {
          if (response.data && response.data.otp && response.data.otp !== "") {
            showSuccessToast(
              "Please use this code to log in:" + response.data.otp + " !"
            );
            setIsMobileEntered(true);
          } else {
            setIsMobileEntered(true);
            showSuccessToast(t("validation_message.successOtp"));
          }
        } else {
          showErrorToast(response.status.message);
        }
      } else {
        showErrorToast(response.status.message);
      }
    }
  };

  const getUnderWriting = async (applicationId) => {
    try {
      if (applicationId) {
        const request = {
          applicationId: applicationId,
        };

        const apiEndPoint = API_URL_UNDERWRITING + "getUnderWriting";
        const isAuthorized = getJwt() ? true : false;

        await commonApiCall(apiEndPoint, "post", request, null, isAuthorized);
        return;
      } else {
        showErrorToast(t("validation_message.SOMETHING_WENT_WRONG"));
      }
    } catch (err) {
      showErrorToast(t("validation_message.SOMETHING_WENT_WRONG"));
    }
  };

  const doSubmit = async (e) => {
    e.preventDefault();
    const { mobileNumber, verificationCode } = formData;
    const validateSubmit =
      underwriting?.referralCode && !company_information?.smsVerify
        ? false
        : true;
    if (validation(validateSubmit)) {
      setIsLoading(true);
      const request = {
        loginType: "MOBILE",
        countryCode: await encryptme("+1"),
        value: await encryptme(mobileNumber),
        otp: verificationCode,
        role: "ROLE_CLIENT",
        smsVerify: company_information ? company_information?.smsVerify : true,
      };

      const apiEndPoint = API_URL_CLIENT + "signIn";
      const response = await commonApiCall(
        apiEndPoint,
        "post",
        request,
        CONSUMER_DETAILS
      );
      if (response.status.code === 200) {
        localStorage.setItem("clDetail", JSON.stringify(response?.data));
        setJwt(response.data.token);
        const reduxStore = {
          profilePic: response.data.profilePic,
          email: await decryptme(response.data.email),
          firstName: await decryptme(response.data.firstName),
          lastName: await decryptme(response.data.lastName),
        };
        await profilePicture(reduxStore);
        if (applicationCarrierInfo?.selectedPolicies && response.data.id) {
          let filterLists = [];
          let liaison_type =
            underwriting?.productSelection === "appliedOnline"
              ? "self"
              : "agent";
          for (
            let i = 0;
            i < applicationCarrierInfo.selectedPolicies.length;
            i++
          ) {
            filterLists.push({
              policy_id:
                applicationCarrierInfo.selectedPolicies[i].applicationCarrierId,
              policy_type:
                applicationCarrierInfo.selectedPolicies[i].policyType,
            });
          }
          const eventObject = {
            agent_id: underwriting?.agentDetail?.agentId,
            application_id: underwriting?.applicationId,
            policy_details: filterLists,
            client_id: response.data.id,
            liaison_type: liaison_type,
          };
          segmentTracking("prospect_create", eventObject);
        }
        if (!isEmpty(router.name)) {
          setItem("isFromUnderwritingProcess", true);
          // props.history.push(UnderWritingRoutes.MANUAL_FORM);
          props.history.push(UnderWritingRoutes.GUEST_BASIC_DETAILS);
        } else {
          const lastScreen = getItem("lastScreen");
          if (lastScreen) {
            const findInUnderwriting = Object.keys(UnderWritingRoutes).find(
              (key) => UnderWritingRoutes[key] === lastScreen
            );
            const findInApproval = Object.keys(ApprovalStatus).find(
              (key) => ApprovalStatus[key] === lastScreen
            );

            const applicationId =
              !isEmpty(underwriting) && underwriting.applicationId
                ? underwriting.applicationId
                : "";

            if ((findInUnderwriting || findInApproval) && applicationId) {
              await getUnderWriting(applicationId);
              removeItem("lastScreen");
              props.history.push(lastScreen);
            } else {
              removeItem("lastScreen");
              props.history.push(lastScreen);
            }
          } else {
            if (redirectPath) {
              props.history.push(DashboardRoute[redirectPath]);
            } else {
              props.history.push(DashboardRoute.DASHBOARD);
            }
          }
        }
      } else {
        showErrorToast(response.status.message);
      }
      setIsLoading(false);
    }
  };

  /*******************  Form validation  ******************/
  const validation = (isGetOtp) => {
    const { mobileNumber, verificationCode } = formData;
    let errors = {};
    // for mobile
    if (formData.hasOwnProperty("mobileNumber")) {
      if (isEmpty(mobileNumber))
        errors.mobileNumber = t("validation_message.PROVIDE_MOBILE_NUMBER");
      else if (!validateMobileNumber(mobileNumber))
        errors.mobileNumber = t(
          "validation_message.PROVIDE_VALID_MOBILE_NUMBER"
        );
      else delete errors.mobileNumber;
    }
    // for otp
    if (isGetOtp) {
      if (formData.hasOwnProperty("verificationCode")) {
        if (isEmpty(verificationCode))
          errors.verificationCode = t(
            "validation_message.PROVIDE_VERIFICATIONCODE_NUMBER"
          );
        else if (!validateOtp(verificationCode))
          errors.verificationCode = t(
            "validation_message.PROVIDE_VALID_VERIFICATIONCODE_NUMBER"
          );
        else delete errors.verificationCode;
      }
    }
    const isValid = Object.keys(errors).length !== 0 ? false : true;
    setFormData({
      ...formData,
      errors: errors,
    });
    return isValid;
  };
  const { mobileNumber, verificationCode, isHavingTrblVisable, errors } =
    formData;

  //logout
  const logOut = async () => {
    setCurrentAccessedScreen("Logout");
  };

  const getLastAccessedScreenName = () => {
    if (path === DashboardRoute.DASHBOARD) return "Dashboard";
    else if (path === DashboardRoute.UNDERPROCESS) return "Processing";
    else if (path === DashboardRoute.CONVERSATIONS) return "Conversations";
    else if (path === DashboardRoute.NOTIFICATIONS) return "Notifications";
    else if (path === DashboardRoute.MYPROFILE) return "My Profile";
    else return undefined;
  };

  const setCurrentAccessedScreen = async (logoutPath = undefined) => {
    if (getLastAccessedScreenName() || logoutPath) {
      const body = {
        lastScreenAccessed: logoutPath
          ? logoutPath
          : getLastAccessedScreenName(),
      };
      const apiEndPoint = API_URL_CLIENT + "clientSettings";
      const isAuthorized = getJwt() ? true : false;

      let response = await commonApiCall(
        apiEndPoint,
        "PATCH",
        body,
        null,
        isAuthorized
      );

      if (response.status.code === 200) {
        if (logoutPath) {
          try {
            let isAuthorized = true;
            let response = await logout(
              API_URL_CLIENT + "signOut",
              "get",
              isAuthorized
            );
            if (response.status.code === 200) {
              setJwt("");

              handleUserSigninFromAdmin(splitpath);
            } else {
              showErrorToast(response.status.message);
            }
          } catch (err) {
            showErrorToast(t("header.something_went_wrong"));
          }
        }
      } else {
        showErrorToast(response?.data?.status?.message);
      }
    }
  };

  const handleUserSigninFromAdmin = async (mobile) => {
    const apiEndPoint = API_URL_CLIENT + "signIn";
    const body = {
      loginType: "MOBILE",
      countryCode: await encryptme("+1"),
      value: await encryptme(mobile),
      role: "ROLE_CLIENT",
      signUpFromAdmin: true,
      smsVerify: true,
    };
    const response = await commonApiCall(
      apiEndPoint,
      "post",
      body,
      CONSUMER_DETAILS
    );

    if (response?.status?.code === 200) {
      splitpath = null;
      localStorage.setItem("clDetail", JSON.stringify(response?.data));
      setJwt(response.data.token);
      const reduxStore = {
        profilePic: response.data.profilePic,
        email: await decryptme(response.data.email),
        firstName: await decryptme(response.data.firstName),
        lastName: await decryptme(response.data.lastName),
      };
      await profilePicture(reduxStore);
      if (applicationCarrierInfo?.selectedPolicies && response.data.id) {
        let filterLists = [];
        let liaison_type =
          underwriting?.productSelection === "appliedOnline" ? "self" : "agent";
        for (
          let i = 0;
          i < applicationCarrierInfo.selectedPolicies.length;
          i++
        ) {
          filterLists.push({
            policy_id:
              applicationCarrierInfo.selectedPolicies[i].applicationCarrierId,
            policy_type: applicationCarrierInfo.selectedPolicies[i].policyType,
          });
        }
        const eventObject = {
          agent_id: underwriting?.agentDetail?.agentId,
          application_id: underwriting?.applicationId,
          policy_details: filterLists,
          client_id: response.data.id,
          liaison_type: liaison_type,
        };
        segmentTracking("prospect_create", eventObject);
      }

      if (!isEmpty(router.name)) {
        setItem("isFromUnderwritingProcess", true);
        // props.history.push(UnderWritingRoutes.MANUAL_FORM);
        props.history.push(UnderWritingRoutes.GUEST_BASIC_DETAILS);
      } else {
        const lastScreen = getItem("lastScreen");
        if (lastScreen) {
          const findInUnderwriting = Object.keys(UnderWritingRoutes).find(
            (key) => UnderWritingRoutes[key] === lastScreen
          );
          const findInApproval = Object.keys(ApprovalStatus).find(
            (key) => ApprovalStatus[key] === lastScreen
          );

          const applicationId =
            !isEmpty(underwriting) && underwriting.applicationId
              ? underwriting.applicationId
              : "";

          if ((findInUnderwriting || findInApproval) && applicationId) {
            await getUnderWriting(applicationId);
            removeItem("lastScreen");
            props.history.push(lastScreen);
          } else {
            removeItem("lastScreen");
            props.history.push(lastScreen);
          }
        } else props.history.push(DashboardRoute.DASHBOARD);
      }
    } else {
      showErrorToast(response.status.message);
    }
  };

  useEffect(() => {
    if (splitpath) {
      if (getJwt()) {
        logOut();
        // setPayment("");
      } else {
        handleUserSigninFromAdmin(splitpath);
        // let token = getJwt();
        // if (token) props.history.push(DashboardRoute.DASHBOARD);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <React.Fragment>
      <div className="login-wrapper">
        <div className="title-header">
          <h2>{t("log_in.log_in")}</h2>
          <p className="pb-0">{t("log_in.nice_to_see_you_again")}</p>
        </div>
        <Animated
          animationIn="fadeInRight"
          animationOut="faderOutLeft"
          animationInDuration={800}
          animationOutDuration={800}
          isVisible={true}
        >
          {!isMobileEntered ? (
            <div className="form-wrapper">
              <Form
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    sendOtp(e);
                  }
                }}
              >
                <div className="form-group">
                  <div className="input-effect otp-inputs">
                    <NumberFormat
                      name="mobileNumber"
                      id="mobileNumber"
                      className="form-control"
                      placeholder="Enter Your Mobile Phone Number"
                      format="(###) ###-####"
                      value={mobileNumber}
                      onValueChange={(e) =>
                        handleChange({
                          target: { name: "mobileNumber", value: e.value },
                        })
                      }
                    />
                    <label htmlFor="phoneNumber">
                      {t("log_in.mobile_number")}
                    </label>
                    <em className="input-icon"></em>
                    <span className="text-danger">{errors.mobileNumber}</span>
                  </div>
                  <div className="form-group forgot-part mb-0 mt-2">
                    <span className="custom-checkbox"></span>
                    {isHavingTrblVisable ? (
                      <Link
                        to={UnderWritingRoutes.LOGIN_EMAIL}
                        className="text-link"
                        title="Forgot login detail?"
                      >
                        {t("log_in.having_trouble_logging_in")}
                      </Link>
                    ) : null}
                  </div>
                </div>
                <div className="form-group text-center mb-0">
                  <button
                    className="btn btn-green btn-lg"
                    title="Log In"
                    type="button"
                    disabled={isLoading}
                    onClick={(e) => {
                      if (
                        underwriting?.referralCode &&
                        !company_information?.smsVerify
                      ) {
                        doSubmit(e);
                      } else {
                        sendOtp(e);
                      }
                    }}
                  >
                    {t("log_in.code_button")}
                  </button>
                </div>
              </Form>
            </div>
          ) : (
            <div className="form-wrapper">
              <Form
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    doSubmit(e);
                  }
                }}
              >
                <div className="form-group mb-1">
                  <div className="input-effect">
                    <NumberFormat
                      className="form-control"
                      placeholder="Enter your login code"
                      name="verificationCode"
                      format="######"
                      value={verificationCode}
                      onValueChange={(e) =>
                        handleChange({
                          target: { name: "verificationCode", value: e.value },
                        })
                      }
                      id="verifiationCode"
                    />
                    <label htmlFor="verifiationCode">
                      {t("log_in.get_otp")}
                    </label>
                    <span className="text-danger">
                      {errors.verificationCode}
                    </span>
                  </div>
                </div>
                {isHavingTrblVisable ? (
                  <div className="form-group text-right">
                    Having trouble logging in? Contact us at{" "}
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="mailto:support@ilife.tech"
                    >
                      support@ilife.tech
                    </a>
                  </div>
                ) : null}
                <div className="form-group text-center mb-0">
                  <button
                    className="btn btn-green btn-lg"
                    title="Log In"
                    type="button"
                    disabled={isLoading}
                    onClick={doSubmit}
                  >
                    {t("log_in.login_button")}
                  </button>
                </div>
                <div className="form-group not-receive-code mt-md-4 mt-3 text-center mb-0">
                  <p className="pb-0">
                    {" "}
                    Didn't receive the code?&nbsp;
                    <Link
                      to="#"
                      className="text-link"
                      title="Resend"
                      onClick={() => {
                        setIsMobileEntered(false);
                        setFormData({
                          ...formData,
                          isHavingTrblVisable: false,
                          otp: "",
                          errors: {},
                        });
                      }}
                    >
                      Resend
                    </Link>
                  </p>
                </div>
              </Form>
            </div>
          )}
        </Animated>

        <div className="form-group not-member">
          <div className="login-password-info">
            <h5>
              Who likes passwords? With iLife you don't need one. Just enter
              your mobile number and we'll text you a new code each time you log
              in.
            </h5>
          </div>
          <p className="pb-0">
            Not a member yet?&nbsp;
            {!isEmpty(router.name) ? (
              <Link
                to={UnderWritingRoutes.REGISTER + "/myplans"}
                className="text-link"
                title="Sign Up"
              >
                {t("log_in.sign_up")}
              </Link>
            ) : (
              <Link
                to={UnderWritingRoutes.REGISTER}
                className="text-link"
                title="Sign Up"
              >
                {t("log_in.sign_up")}
              </Link>
            )}
          </p>
        </div>
        {!isEmpty(router.name) && (
          <div className="col-12 text-center mt-3">
            <Link
              // to={UnderWritingRoutes.LOGIN_AGENT_CHAT}
              to={UnderWritingRoutes.GUEST_BASIC_DETAILS}
              className="btn btn-link"
              title="Back"
            >
              Back
            </Link>
          </div>
        )}
      </div>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  underwriting: state.underwriting.underwriting,
  applicationCarrierInfo: state.underwriting.applicationCarrierInfo,
  company_information: state.underwriting.companyInformation,
});
export default connect(mapStateToProps, {
  commonApiCall,
  logout,
  profilePicture,
})(withRouter(Login));
