import React, { useState } from "react";
import { Animated } from "react-animated-css";

const MedicalCare = () => {
  const [openSearch, setOpenSearch] = useState(false);
  return (
    <div className="modal-part">
      <div className="title-header">
        <h3 className="page-title">
          Where have you received medical care in the last 5 years?
        </h3>
        <h4>
          Please select anyone hospital in order to proceed further with the
          process.
        </h4>
      </div>
      <p className="powered-by position-top">
        Powered by{" "}
        <span>
          <img
            src="/assets/images/icon/human-api.png"
            alt="HumanApi"
            title="HumanApi"
          />
        </span>
      </p>
      <div className="search-box">
        <div className="form-group">
          <input type="search" className="form-control" />
          <button className="btn btn-light" onClick={() => setOpenSearch(true)}>
            <span className="material-icons">search</span>
          </button>
        </div>
      </div>
      {!openSearch === true ? (
        <Animated
          animationIn="fadeInRight"
          animationOut="zoomOut"
          animationInDuration={800}
          animationOutDuration={800}
          isVisible={true}
        >
          <div className="hospital-list">
            <label className="boxmodal-radio">
              <input type="radio" name="status" />
              <div className="card-inner with-border">
                <div className="hospital-logo">
                  <img
                    src="/assets/images/hospital-icon/children-omaha.svg"
                    alt="Children Omaha"
                    title="Children Omaha"
                  />
                </div>
                <h4>Children’s Omaha</h4>
              </div>
            </label>
            <label className="boxmodal-radio">
              <input type="radio" name="status" />
              <div className="card-inner with-border">
                <div className="hospital-logo">
                  <img
                    src="/assets/images/hospital-icon/inova-health.svg"
                    alt="Children Omaha"
                    title="Children Omaha"
                  />
                </div>
                <h4>Inova Health System</h4>
              </div>
            </label>
            <label className="boxmodal-radio">
              <input type="radio" name="status" />
              <div className="card-inner with-border">
                <div className="hospital-logo">
                  <img
                    src="/assets/images/hospital-icon/starfleet-medical.svg"
                    alt="Children Omaha"
                    title="Children Omaha"
                  />
                </div>
                <h4>Starfleet Medical</h4>
              </div>
            </label>
            <label className="boxmodal-radio">
              <input type="radio" name="status" />
              <div className="card-inner with-border">
                <div className="hospital-logo">
                  <img
                    src="/assets/images/hospital-icon/riverside-medical.svg"
                    alt="Children Omaha"
                    title="Children Omaha"
                  />
                </div>
                <h4>Riverside Medical Clinic</h4>
              </div>
            </label>
            <label className="boxmodal-radio">
              <input type="radio" name="status" />
              <div className="card-inner with-border">
                <div className="hospital-logo">
                  <img
                    src="/assets/images/hospital-icon/bellin-health.svg"
                    alt="Children Omaha"
                    title="Children Omaha"
                  />
                </div>
                <h4>Bellin Health</h4>
              </div>
            </label>
          </div>
        </Animated>
      ) : null}
      {openSearch === true ? (
        <Animated
          animationIn="fadeInRight"
          animationOut="zoomOut"
          animationInDuration={800}
          animationOutDuration={800}
          isVisible={true}
        >
          <div className="search-result">
            <label className="boxmodal-radio">
              <input type="radio" name="status" />
              <div className="search-result-list">
                <div className="hospital-logo">
                  <img
                    src="/assets/images/icon/hospitals.svg"
                    alt="HospitalLogo"
                    title="HospitalLogo"
                  />
                </div>
                <div className="hospital-details">
                  <h4>Apollo Health</h4>
                  <h5>2142 Nj-70, Manchester Township, New Jersey 08759</h5>
                </div>
              </div>
            </label>
            <label className="boxmodal-radio">
              <input type="radio" name="status" />
              <div className="search-result-list">
                <div className="hospital-logo">
                  <img
                    src="/assets/images/icon/hospitals.svg"
                    alt="HospitalLogo"
                    title="HospitalLogo"
                  />
                </div>
                <div className="hospital-details">
                  <h4>Apollo Healthcare Associates</h4>
                  <h5>2105 Palm Bay Road Northeast, Palm Bay, Florida 32905</h5>
                </div>
              </div>
            </label>
            <label className="boxmodal-radio">
              <input type="radio" name="status" />
              <div className="search-result-list">
                <div className="hospital-logo">
                  <img
                    src="/assets/images/icon/hospitals.svg"
                    alt="HospitalLogo"
                    title="HospitalLogo"
                  />
                </div>
                <div className="hospital-details">
                  <h4>Apollo Family Health Services</h4>
                  <h5>5823 Middlebelt Rd, Garden City, Michigan 48135</h5>
                </div>
              </div>
            </label>
          </div>
        </Animated>
      ) : null}
    </div>
  );
};

export default MedicalCare;
