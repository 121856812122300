import React, { useEffect, useState } from "react";

import { connect } from "react-redux";
import { useLocation, withRouter } from "react-router-dom";
import {
  commonApiCall,
  getJwt,
  setSbliProgressCount,
  UNDERWRITING_DETAILS,
} from "../../../redux/actions";
import { API_URL_UNDERWRITING } from "../../../config/configs";
import {
  ApprovalStatus,
  customDomainTokenGenerate,
  showErrorToast,
  UnderWritingRoutes,
} from "../../../utils";
import { assurityDoc } from "./assurity-doc";
import axios from "axios";

const AssurityDocuments = ({
  t,
  commonApiCall,
  underwriting,

  ...props
}) => {
  const { applicationId } = underwriting;
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [documentLoading, setDocumentLoading] = useState(false);
  const [documentURL, setDocumentURL] = useState(null);
  const [agreeTerms, setAgreeTerm] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(null);
  const [currentDocument, setCurrentDocument] = useState({
    index: 0,
    title: null,
    file: null,
  });

  const [bodyData, setBodyData] = useState([]);

  const location = useLocation();

  const getHashValue = (key) => {
    const matches = location.hash.match(new RegExp(key + "=([^&]*)"));
    return matches ? matches[1] : null;
  };

  function isBase64(str = "") {
    try {
      return btoa(atob(str)) == str;
    } catch (err) {
      return false;
    }
  }
  useEffect(() => {
    if (isBase64(currentDocument.file)) {
      let blob = base64toBlob(currentDocument.file);
      let url = URL.createObjectURL(blob);
      setCurrentDocument({ ...currentDocument, file: url });
    }
  }, [currentDocument]);

  //   const submitDocument = async () => {
  //     setLoading(true);
  //     try {
  //       let body = {
  //         applicationId: applicationId,
  //         documentSign: bodyData,
  //         userInput: agreeTerms ? "agree" : "decline",
  //       };
  //       const isAuthorized = getJwt() ? true : false;
  //       const response = await commonApiCall(
  //         API_URL_UNDERWRITING + "submitDocument",
  //         "post",
  //         body,
  //         "",
  //         isAuthorized
  //       );
  //       if (response.status.code === 200) {
  //         setSbliProgressCount(progressCount + 1);
  //         props.history.push(UnderWritingRoutes.SBLI_CONGRATULATIONS);
  //       } else {
  //         showErrorToast(response.status.message);
  //       }
  //     } catch (err) {
  //       showErrorToast(t("application_status.something_went_wrong"));
  //     }
  //     setLoading(false);
  //   };

  const handleCheckbox = (e) => {
    setAgreeTerm(e.target.checked);
  };

  const onDocumentNext = () => {
    if (currentDocument.index + 1 <= documents.length) {
      setCurrentDocument({
        index: currentDocument.index + 1,
        title: Object.keys(documents[currentDocument.index + 1]).Name,
        file: documents[currentDocument.index + 1].Base64Content,
      });
    }
  };

  const onDocumentBack = () => {
    if (currentDocument.index > 0) {
      setCurrentDocument({
        index: currentDocument.index - 1,
        title: Object.keys(documents[currentDocument.index - 1])[0],
        file: documents[currentDocument.index - 1][
          Object.keys(documents[currentDocument.index - 1])[0]
        ],
      });
    }
  };

  const handlePackageApi = async () => {
    setDocumentLoading(true);

    try {
      let request = JSON.parse(localStorage.getItem("persist::ass::bene"));
      request.product.issueStateAbbreviation =
        request.product.primaryInsured.address.stateAbbreviation;
      const isAuthorized = getJwt() ? true : false;
      const apiEndPoint =
        API_URL_UNDERWRITING +
        "docSign?applicationId=" +
        underwriting?.applicationId +
        // "&basicAuthToken=" +
        // window.btoa(
        //   `${process.env.REACT_APP_DOCUSIGN_INTEGRATION_KEY}:${process.env.REACT_APP_DOCUSIGN_SECRET_KEY}`
        // ) +
        "&code=" +
        getHashValue("access_token");
      const response = await commonApiCall(
        apiEndPoint,
        "post",
        {},
        null,
        isAuthorized
      );

      if (response.status.code === 200) {
        if (response.data.length === 0) {
          setTimeout(() => {
            handlePackageApi();
          }, 20000);
          return;
        }
        setDocumentURL(response.data[0]);
      }
    } catch (err) {
      console.log(err);
      showErrorToast(err);
    }
    setDocumentLoading(false);
  };

  const getDocuments = async () => {
    setDocumentLoading(true);
    try {
      let response = await commonApiCall(
        `${API_URL_UNDERWRITING}getDocument?applicationId=${underwriting?.applicationId}`,
        "get",
        {},
        ""
      );
      if (response.status.code === 200) {
        const data = JSON.parse(response.data.formsJson);
        if (data && data.length > 0) {
          let sign = [...bodyData];
          setDocuments(data);
          setCurrentDocument({
            index: 0,
            title: Object.keys(data[0])[0],
            file: data[0][Object.keys(data[0])[0]],
          });

          const stamp = {
            esign_timestamp: new Date().toUTCString(),
            form_no: Object.keys(data[currentDocument.index])[0],
            doc_full_view_flag: 1,
            esign_flag: 1,
          };
          sign.push(stamp);
          setBodyData(sign);
        }
      } else {
        showErrorToast(response.status.message);
      }
    } catch (err) {
      showErrorToast(err);
    }
    setDocumentLoading(false);
  };

  const saveUnderwriteDetails = async () => {
    try {
      const request = {
        applicationId: underwriting?.applicationId,
        applicationType: "Web",
        nextStep: "24,ASSURITY_PREVIEW",
      };

      const isAuthorized = getJwt() ? true : false;
      const apiEndPoint = API_URL_UNDERWRITING + "saveUnderWriting";
      const response = await commonApiCall(
        apiEndPoint,
        "post",
        request,
        UNDERWRITING_DETAILS,
        isAuthorized
      );

      if (response.status.code === 200) {
        handlePackageApi();
      }
    } catch (err) {
      showErrorToast(err);
    }
  };

  const base64toBlob = (data) => {
    // Cut the prefix `data:application/pdf;base64` from the raw base 64
    const base64WithoutPrefix = data;

    // // .substr(
    //   "data:application/pdf;base64,".length
    // );

    const bytes = atob(base64WithoutPrefix);
    let length = bytes.length;
    let out = new Uint8Array(length);

    while (length--) {
      out[length] = bytes.charCodeAt(length);
    }

    return new Blob([out], { type: "application/pdf" });
  };
  useEffect(() => {
    // if (localStorage.getItem("assurityDomain")) {
    //   window.location.href =
    //     localStorage.getItem("assurityDomain") +
    //     "/assurity-documents#access_token=" +
    //     getHashValue("access_token");
    // } else {

    // }
    saveUnderwriteDetails();
    // setCurrentDocument({
    //   index: 0,
    //   title: documents[0].Name,
    //   file: documents[0].Base64Content,
    // });
  }, []);

  //   const onSavePdf = () => {
  //     if (documents && documents.length > 0) {
  //       documents.forEach((x) => {
  //         let name = Object.keys(x)[0];
  //         console.log(name, x[name]);
  //         downloadPDF(name, x[name]);
  //       });
  //     }
  //   };

  return (
    <div className="sbli-documents-main mt-5" style={{ maxWidth: "100%" }}>
      <div className="container">
        <h3 className="text-center mb-5">
          Review and Sign your Application and Documents
        </h3>
        <div className="pdf-container mt-0 mb-0">
          {documentLoading ? (
            <div className="text-center">
              <h2>Processsing Documents</h2>
              <p>This may take few minutes</p>
            </div>
          ) : documentURL ? (
            <iframe
              src={documentURL}
              width={"100%"}
              height={700}
              title={"Document"}
              onLoad={() => {
                if (count) {
                  props.history.push("/assurity-preview");
                  // customDomainTokenGenerate(
                  //   (window.location.href = `https://account-d.docusign.com/oauth/auth?response_type=token&scope=signature&client_id=e1631711-4f38-4ca4-98c5-e399f204ab58&redirect_uri=${window.location.origin}/assurity-preview`),
                  //   "preview"
                  // );
                } else {
                  setCount(true);
                }
              }}
            ></iframe>
          ) : (
            <h2>No Documents</h2>
          )}
        </div>
        {/* <div className="pdf-container-button py-2">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div className="d-flex align-items-center ml-2">
              {documents.length > 0 && currentDocument.index !== 0 && (
                <img
                  onClick={onDocumentBack}
                  src="/assets/images/next.png"
                  className="mr-2"
                  style={{ width: 15, cursor: "pointer" }}
                />
              )}
              <p className="p-0">
                {currentDocument.index + 1 + "/" + documents.length}
              </p>
            </div>
          </div>
        </div> */}
        {/* <div className="w-100 text-center mt-4">
          <button
            disabled={currentDocument.index + 1 >= documents.length}
            className="btn btn-default"
            onClick={onDocumentNext}
          >
            Next
          </button>
        </div> */}
        {/* <div className="form-group mt-4">
          <span className="custom-checkbox">
            <label className="m-0 text-dark">
              <input
                disabled={documentLoading || bodyData.length < documents.length}
                type="checkbox"
                name="isAgree"
                onChange={(e) => handleCheckbox(e)}
              />
              <span></span> I have reviewed ,understand, and agree to the terms
              of each of the above disclosures
            </label>
          </span>
        </div> */}
        {/* <div className="w-100 text-center">
          <button
            disabled={bodyData.length < documents.length || !agreeTerms}
            className="btn btn-default"
            // onClick={submitDocument}
          >
            {loading ? "Processing..." : "Submit"}
          </button>
        </div> */}
        {/* <div className="w-100 text-center mt-3">
          <button
            className="btn btn-link"
            onClick={() => props.history.push("/sbli-coverage")}
          >
            Back
          </button>
        </div> */}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  underwriting: state.underwriting.underwriting,
  company_information: state.underwriting.companyInformation,
  direct_link: state.underwriting.direct_link,
  progressCount: state.underwriting.progressCount,
});

export default connect(mapStateToProps, {
  commonApiCall,
})(withRouter(AssurityDocuments));
